// code adapted from https://themeteorchef.com/tutorials/reusable-seo-with-react-helmet

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';



const absoluteUrl = path =>  `${process.env.REACT_APP_SITE_PUBLIC_URL}${path}`; 

const seoImageURL = image => {
  let regexFqdn = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return (regexFqdn.test(image)) ? image : `${process.env.REACT_APP_SITE_PUBLIC_URL}${image}`;
}

const getMetaTags = ({
  title, description, url, contentType, published, updated, category, tags, twitterHandle, image,
}) => {
  const metaTags = [
    { itemprop: 'name', content: title },
    { name: 'og:type', content: contentType || "website" },
    { name: 'og:url', content: url },
    // { name: 'og:locale', content: 'en_EN' },
    // { name: 'fb:app_id', content: '<FB App ID>' },
  ];



  if(title){
    metaTags.push({ name: 'og:title', content: `${title}` });
    metaTags.push({ name: 'og:site_name', content:  `${title}` });
  }
  if(description){
    metaTags.push({ itemprop: 'description', content: description });
    metaTags.push({ name: 'description', content: description });
    metaTags.push({ name: 'og:description', content: description });
  }

  if (published) metaTags.push({ name: 'article:published_time', content: published });
  if (updated) metaTags.push({ name: 'article:modified_time', content: updated });
  if (category) metaTags.push({ name: 'article:section', content: category });
  if (tags) metaTags.push({ name: 'article:tag', content: tags });
  if(twitterHandle){
    metaTags.push({ name: 'twitter:card', content: 'summary' });
    metaTags.push({ name: 'twitter:site', content:  twitterHandle });
    metaTags.push({ name: 'twitter:title', content:  `${title}`  });
    metaTags.push({ name: 'twitter:description', content: description });
    metaTags.push({ name: 'twitter:creator', content: twitterHandle });
  }
  
  if (image) {
    if(twitterHandle ){
      metaTags.push({ name: 'twitter:image:src', content: seoImageURL(image) });
      metaTags.push({ name: 'twitter:card', content: 'summary_large_image' });
    }
    metaTags.push({ itemprop: 'image', content: seoImageURL(image) });
    metaTags.push({ name: 'og:image', content: seoImageURL(image) });
    metaTags.push({ name: 'image', property:'og:image', content: seoImageURL(image) });   
  }
  return metaTags;
};

const getHtmlAttributes = ({
  schema
}) => {
  let result = {
    lang: 'en',
  };
  if (schema) {
    result = {
      ...result,
      itemscope: undefined,
      itemtype: `http://schema.org/${schema}`,
    }
  }
  return result;
}

getHtmlAttributes.propTypes = {
  schema: PropTypes.string,
};

const Seo = ({
  schema, title, siteName, description, path, contentType, published, updated, category, tags, twitterHandle , image, 
}) => (
  <Helmet
    htmlAttributes={getHtmlAttributes({
      schema,
    })}
    title={ title }
    link={[
      { rel: 'canonical', href: absoluteUrl(path) },
    ]}
    meta={getMetaTags({
      title,
      description,
      contentType,
      url: absoluteUrl(path),
      published,
      updated,
      category,
      tags,
      twitterHandle ,
      siteName,
      image
    })}
  />
);

Seo.propTypes = {
  schema: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  contentType: PropTypes.string,
  published: PropTypes.string,
  updated: PropTypes.string,
  category: PropTypes.string,
  tags: PropTypes.array,
  twitter: PropTypes.string,
  twitterSite: PropTypes.string,
  image: PropTypes.string,
};

export default Seo;
