import "chartjs-plugin-datalabels";
import { FormatAsDollars } from "../../../utils/Helpers/Format";

const chartOptions = ({
  shouldShowSavingsAmount,
  currentTotal = 0,
  savingsColor = "#57595D",
  monthText = "/mo",
}) => {
  return {
    maintainAspectRatio: true,
    responsive: true,
    tooltips: {
      mode: "index",
      callbacks: {
        label: function (tooltipItem, data) {
          const value = FormatAsDollars(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          );
          const label = data.datasets[tooltipItem.datasetIndex].label || "";
          return ` ${label}: ${value}`;
        },
        title: function (tooltipItems, data) {
          const total = tooltipItems.reduce(function (acc, item) {
            return acc + item.yLabel;
          }, 0);
          return `${tooltipItems[0].xLabel.join(" ")}: ${FormatAsDollars(
            total
          )}`;
        },
      },
    },
    legend: {
      reverse: false,
      position: "top",
      onClick: function (e) {
        e.stopPropagation();
      },
      labels: {
        fontSize: 14,
      },
    },
    layout: {
      padding: {
        top: 20,
      },
    },
    scales: {
      yAxes: [
        {
          display: false,
          stacked: true,
          ticks: {
            callback: function (value) {
              value = FormatAsDollars(value);
              return value;
            },
            fontSize: 14,
          },
          scaleLabel: {
            display: false,
            labelString: "Annual Cost",
            fontSize: 18,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: true,
          ticks: {
            fontSize: 16,
            fontStyle: "bold",
            fontColor: "#000000",
            fonFamily: "'Roboto' 'Open Sans' 'Arial'",
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.datasetIndex !== ctx.chart.data.datasets.length - 1)
            return "";

          const monthlyAmount = ctx.chart.data.datasets.reduce(
            (total, dataset) => total + dataset.data[ctx.dataIndex],
            0
          );

          return FormatAsDollars(monthlyAmount) + monthText;
        },
        align: "end",
        anchor: "end",
        color: savingsColor,
        font: {
          weight: 700,
        },
      },
    },
  };
};

const chartPlugins = [
  {
    beforeInit: (chart, options) => {
      chart.legend.afterFit = function () {
        this.height = this.height + 15;
      };
    },
  },
];

export { chartOptions, chartPlugins };
