import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"

import Select from "../../shared/InputElements/Select"
import {useIntl} from 'react-intl';

const SelectVehicleAgeFilter = ({
  id = "select-vehicle-age-filter",
  vehicles,
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "age", defaultMessage: "Age"}) : "Age"

  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleAgeFilter")}
      label={label}
      optionNames={[
        intl.formatMessage ? intl.formatMessage({ id: "all", defaultMessage: "All"}) : "All", 
        intl.formatMessage ? intl.formatMessage({ id: "vehicle.age.one", defaultMessage: "0-1 years old"}) : "0-1 years old", 
        intl.formatMessage ? intl.formatMessage({ id: "vehicle.age.two", defaultMessage: "2-3 years old"}) : "2-3 years old", 
        intl.formatMessage ? intl.formatMessage({ id: "vehicle.age.four", defaultMessage: "4+ years old"}) : "4+ years old"
      ]}
      optionValues={["All", "0-1", "2-3", "4+"]}
      handler={(e) => userPrefs.set({ vehicleAgeFilter: e.target.value })}
    />
  );
};

export default SelectVehicleAgeFilter;

SelectVehicleAgeFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
