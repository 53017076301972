import {
  GAS_ENERGY_COST_ID,
  EV_ENERGY_COST_ID,
  HOME_ENERGY_COST_ID
} from '../constants/';

const energyCostTypes = [
  {
    id: GAS_ENERGY_COST_ID,
    title: 'Gasoline',
  },
  {
    id: EV_ENERGY_COST_ID,
    title: 'Electricity',
  },
  {
    id: HOME_ENERGY_COST_ID,
    title: 'Home'
  },
];

export default energyCostTypes;
