import React from "react";
import PropTypes from "prop-types";
import Select from "../../shared/InputElements/Select";
import { useIntl} from 'react-intl';

const SelectVehicleYearFilter = ({
  id = "select-vehicle-age-filter",
  vehicles,
  car,
  setUsedEv,
  ...rest
}) => {
  const intl = useIntl()
  const vehicleYearOptions = vehicles.map(ev => ev.model_year).sort()
  const label = intl.formatMessage ? intl.formatMessage({ id: "graph.year", defaultMessage: "Year"}) : "Year"

  const setVehicle = (e) => {
    const selectedVehicle = vehicles.find(ev => ev.model_year === parseInt(e.target.value))
    setUsedEv(selectedVehicle)
  }
  return (
    <Select
      id={id}
      value={car.model_year.toString()}
      label={label}
      optionNames={[...vehicleYearOptions]}
      optionValues={[...vehicleYearOptions]}
      handler={setVehicle}
    />
  );
};

export default SelectVehicleYearFilter;

SelectVehicleYearFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
