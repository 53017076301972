import React from "react";
import PropTypes from "prop-types";

import ChargingStationCard from "../ChargingStationCard/ChargingStationCard";
import ShowHideButton from '../../ShowHideButton/ShowHideButton';

import {useIntl} from 'react-intl';

import "./ChargingStationsList.scss";

const ChargingStationsList = ({ chargingStations }) => {
  const intl = useIntl();
  
  return (
    <div className="ChargingStationsList">
      <ShowHideButton buttonText={intl.formatMessage ? intl.formatMessage({ id: "chargingMap.showFullListOfStations", defaultMessage: "SHOW FULL LIST OF STATIONS"}) : "SHOW FULL LIST OF STATIONS"}>
        <div className="charging-station-cards-container">
          <div className="row">
            <div className="col-sm-6 offset-sm-3">
              {chargingStations.map((station, i) => {
                return (
                  <ChargingStationCard
                    key={station.id}
                    chargingStation={station}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

ChargingStationsList.propTypes = {
  chargingStations: PropTypes.array
};

ChargingStationsList.defaultProps = {
  chargingStations: []
};

export default ChargingStationsList;
