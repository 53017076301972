const hasAllConditions = incentive =>
    incentive &&
    incentive.eligibility &&
    incentive.name &&
    incentive.incentive_type &&
    incentive.grantor &&
    incentive.eligibility_factors;

const isCvrp = incentive => incentive.name === "Clean Vehicle Rebate";

const isAeAreaIncentive = incentive =>
    incentive.incentive_type.toUpperCase() === "REBATE" &&
    incentive.grantor.toUpperCase() === "{process.env.REACT_APP_COMPANY_NAME_ABRV}" &&
    incentive.eligibility_factors.length === 1 &&
    incentive.eligibility_factors[0].toUpperCase() === "ZIPCODE";

const isFedTaxCredit = incentive =>
    incentive.incentive_type.toUpperCase() === "TAX_CREDIT" &&
    incentive.grantor.toUpperCase() === "FEDERAL";

const isCustomPrimary = incentive => {
    // Guard clauses to short circuit the logic
    if (!hasAllConditions(incentive)) {
        return false;
    }
    if (incentive.eligibility === "ineligible") {
        return false;
    }

    return (
        isCvrp(incentive) ||
        isAeAreaIncentive(incentive) ||
        isFedTaxCredit(incentive)
    );
};
export default isCustomPrimary;