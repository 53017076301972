import React from "react";
import PropTypes from "prop-types";

import HowProgramWorks from "./HowProgramWorks";
import EligibleChecklist from "./EligibleChecklist";
import EnrollWithBlurb from "./EnrollWithBlurb";
import ChargerChoice from "./ChargerChoice";
import chargers from "./chargers/chargers";

import GrandTitleParas from "../shared/GrandTitledParas/GrandTitledParas";
import HR from "../shared/HR/HR";

import IconGasPump from "../../assets/images/icons/icon-gas-pump.svg";
import IconHardHat from "../../assets/images/icons/icon-hardhat.svg";
import IconZappyBolt from "../../assets/images/icons/icon-zappy-bolt.svg";
import chargingScheduleImage from "../../assets/images/chargingSchedule.png";
import { FormattedMessage, useIntl } from "react-intl";
import { PaymentAndPricing } from "../../../components/AccelerateAtHome/PaymentAndPricing";

import "./EasyChargingHome.scss";
import { NEW_MEXICO } from "../../../constants";

export const EnrollmentDetails = ({
  stateName,
  enrollURL,
  howProgramWorks,
  price,
}) =>  (
  <>
    <section className="container works-elible-enroll">
      <div className="row">
        <GrandTitleParas
          classNames="how-works"
          colonHeader={
            <FormattedMessage
              id="howTheProgramWorks"
              defaultMessage="How the program works:"
              description="How the program works:"
            />
          }
        >
          <HowProgramWorks
            stateName={stateName}
            text={howProgramWorks.text}
            images={howProgramWorks.images}
            smoothScroll={howProgramWorks.scrollTo}
          />
        </GrandTitleParas>
        <GrandTitleParas
          classNames="eligible-if"
          colonHeader={
            <FormattedMessage
              id="youAreEligible"
              defaultMessage="You're eligible if you:"
              description="You're eligible if you:"
            />
          }
        >
          <EligibleChecklist stateName={stateName} />
        </GrandTitleParas>
        <GrandTitleParas classNames="enroll">
          <EnrollWithBlurb enrollURL={enrollURL} stateName={stateName} />
        </GrandTitleParas>
        {/* {stateName === NEW_MEXICO || stateName === COLORADO ? 
          <GrandTitleParas>
            <p>
              <FormattedMessage
                id={`dueCurrentMarketConditions_NM/CO`}
                defaultMessage="* Due to current market conditions, inventory of chargers is low, and you may encounter delays in scheduling and having your charger installed over the 4-week timeline. We are doing our best to work with manufacturers to secure charging stations."
              />
            </p>
          </GrandTitleParas>
          : 
          null
        } */}
      </div>
    </section>
    <HR />
    <section className="container payment-pricing">
      <div className="row">
        <PaymentAndPricing price={price} stateName={stateName} />
      </div>
    </section>
    <HR />
  </>
);

const ChargerScheduleTitleCopy = ({ stateName }) => {
  switch (stateName) {
    case NEW_MEXICO:
      return (
        <FormattedMessage
          id={`youChooseChargingSchedule-nm`}
          defaultMessage="You Confirm You are Enrolled in Whole-Home Time of Use Rate or Choose an Off-Peak Charging Schedule"
          description="You Confirm You are Enrolled in Whole-Home Time of Use Rate or Choose an Off-Peak Charging Schedule"
        />
      );
    default:
      return (
        <FormattedMessage
          id={`youChooseChargingSchedule`}
          defaultMessage="You Choose Your Off-Peak Charging Schedule"
          description="You Choose Your Off-Peak Charging Schedule"
        />
      );
  }
};

const ChargerOptions = ({ stateName, intl }) => (
  <>
    <section className="container charger-options">
      <div className="row">
        <GrandTitleParas
          classNames="how-works charger-options"
          colonHeader={
            <FormattedMessage
              id="evChargerSetUpThreeSteps"
              defaultMessage="EV Charger Set Up—3 Simple Steps"
              description="EV Charger Set Up—3 Simple Steps"
            />
          }
        >
          <div id="charger-options">
            <p className="h2">
              1.{" "}
              <FormattedMessage
                id="eligibleCharger"
                defaultMessage="Eligible Charger"
                description="Eligible Charger"
              />
            </p>
            <p>
              <FormattedMessage
                id="chargerIsCompatible"
                defaultMessage="{link1} is compatible with Xcel Energy’s charging programs. It is ENERGY STAR®-certified and UL Listed for safety. (Enel X Way chargers are no longer compatible with our programs.)"
                description="ChargePoint Home Flex charger is compatible with Xcel Energy’s charging programs. It is ENERGY STAR®-certified and UL Listed for safety. (Enel X Way chargers are no longer compatible with our programs.)"
                values={{
                   link1: (
                              <a
                                href={intl.formatMessage({
                                  id: "EVAAH_ChargerOptions_link1_URL",
                                  defaultMessage:
                                    "https://ev.xcelenergy.com/ChargePoint_Home_Flex.pdf",
                                })}
                              >
                                <FormattedMessage
                                  id={`EVAAH_ChargerOptions_link1_text`}
                                  defaultMessage="ChargePoint Home Flex"
                                />
                              </a>
                            ),
                }}
             />
            </p>
            <p>
              <FormattedMessage
                id="chargerMustBe"
                defaultMessage="The charger must be hardwired to a 240-volt circuit and cannot be plugged into an outlet. See FAQ for more information. Tesla owners can use this charger with J1772 to NACS adapter. To ensure proper compatibility with the charger, a non-GFCI circuit is required.The charger must be hardwired to a 240-volt circuit and cannot be plugged into an outlet. See FAQ for more information. Tesla owners can use this charger with J1772 to NACS adapter. To ensure proper compatibility with the charger, a non-GFCI circuit is required."
                description="The charger must be hardwired to a 240-volt circuit and cannot be plugged into an outlet. See FAQ for more information. Tesla owners can use this charger with J1772 to NACS adapter. To ensure proper compatibility with the charger, a non-GFCI circuit is required."
              />
            </p>
            {/* <p className="h2">
              1.{" "}
              <FormattedMessage
                id="youChooseYourCharger"
                defaultMessage="You Choose Your Charger"
                description="You Choose Your Charger"
              />
            </p>
            <p>
              <FormattedMessage
                id="youCanRent"
                defaultMessage="You can rent either the"
                description="You can rent either the"
              />
              &nbsp;
              <a
                href={`/ChargePoint_Home_Flex.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                ChargePoint Home Flex
              </a>
              &nbsp;
              <FormattedMessage id="or" defaultMessage="or" description="or" />
              &nbsp;
              <a
                href={`/Enel_X_JuiceBox_48.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Enel X Way Juice Box
              </a>
              .{" "}
              <FormattedMessage
                id="chargersEnergyStarCertified"
                defaultMessage="Both chargers are ENERGY STAR®-certified and UL Listed for safety and will be hardwired to a 240-volt circuit."
                description="Both chargers are ENERGY STAR®-certified and UL Listed for safety and will be hardwired to a 240-volt circuit."
              />
            </p>
            <p>
              <FormattedMessage
                id="chargersMustBeHardwired"
                defaultMessage="All chargers in the program must be hardwired to a 240-volt circuit."
                description="All chargers in the program must be hardwired to a 240-volt circuit."
              />
            </p> 
            <p>
              <FormattedMessage
                id="teslaNeedsAdapter"
                defaultMessage="Tesla owners will need an adapter."
                description="Tesla owners will need an adapter."
              />
        </p> */}
          </div>
        </GrandTitleParas>
        <div id="charger-choices">
        <GrandTitleParas classNames="charger-choices">
          <ChargerChoice chargers={chargers} />
          <p>
            <i>
              <FormattedMessage
                id="noteXcelChargerDisclaimer"
                defaultMessage="(Enel X Way, additional charger provider that used to be compatible with our programs, decided to cease its North American operations as of October 2, 2024. Xcel Energy is actively working on a contingency plan for its current customers. And, as of October 2, 2024, Xcel Energy is no longer accepting Enel X Way chargers for new customer enrollment in its charging programs.)"
                description="(Enel X Way, additional charger provider that used to be compatible with our programs, decided to cease its North American operations as of October 2, 2024. Xcel Energy is actively working on a contingency plan for its current customers. And, as of October 2, 2024, Xcel Energy is no longer accepting Enel X Way chargers for new customer enrollment in its charging programs.)"
              />
            </i>
          </p>
        </GrandTitleParas>
        <GrandTitleParas classNames="charging-options">
          <p className="h2">
            2. <ChargerScheduleTitleCopy stateName={stateName} />
          </p>
          <p>
            <FormattedMessage
              id="agreeOffPeakHours"
              defaultMessage="By agreeing to charge your EV during select off-peak hours through our Optimize Your Charge program, you’re helping us use more renewable energy sources and continue to make the grid more resilient and reliable."
              description="Agree Off Peak Hours"
            />
          </p>
          <p>
            <FormattedMessage
              id="weProvideScheduleBestForGrid"
              defaultMessage="We provide several charging schedule options that are best for the grid; you choose the schedule that works best for your driving needs. If you need to charge outside of the schedule you selected occasionally, that is okay."
              description="Best Grid Schedule"
            />
          </p>
          <p>
            <FormattedMessage
              id="selectFollowingChargingOptions"
              defaultMessage="Select from the following charging options:"
              description="Select from the following charging options:"
            />
          </p>
          <img
            className="charging-schedule-image"
            src={chargingScheduleImage}
            alt="Charging Schedule"
            style={{ minWidth: "100%", maxWidth: "100%" }}
          />
        </GrandTitleParas>
        </div>
        <div id="charger-install">
        <GrandTitleParas>
          <p className="h2">
            3.{" "}
            <FormattedMessage
              id="weInstallYourCharger"
              defaultMessage="We Install Your Charger"
              description="We Install Your Charger"
            />
          </p>
          <p>
            <FormattedMessage
              id="aQualifiedElectricianWillContact"
              defaultMessage="A qualified electrician from our network will contact you within 2 business days of your enrollment confirmation to schedule your charger installation and set-up (typically within 4 weeks). See if you qualify for a"
              description="Electrician will contact"
            />
            &nbsp;
            <a href="/home-wiring-rebate">
              <FormattedMessage
                id="faqRebateCOHomeWiringRebate"
                defaultMessage="Home Wiring Rebate"
                description="Home Wiring Rebate"
              />
            </a>
            &nbsp;
            <FormattedMessage
              id="toOffsetYourCosts"
              defaultMessage="to offset your costs."
              description="to offset your costs."
            />
          </p>
          <p>
            <FormattedMessage
              id="chargerWifiEnabledDisclaimer"
              defaultMessage="Since your charger will be Wi-Fi enabled, please confirm that the installation location has a strong Wi-Fi signal. If your signal strength is weak, you may need to install a Wi-Fi booster before the electrician arrives to install the charger. Be sure to have your Wi-Fi password available for connecting the charger during installation."
              description="Charger Wifi Enabled"
            />
          </p>
        </GrandTitleParas>
        </div>
      </div>
    </section>
  </>
);

const howProgramWorks = (intl) => ({
  images: [IconGasPump, IconHardHat, IconZappyBolt],
  text: [
    intl.formatMessage({
      id: "evaahProgramWorksOne",
      defaultMessage:
        "You select a Level 2 charger from one of our two pre-qualified options",
    }),
    intl.formatMessage({
      id: "evaahProgramWorksTwo",
      defaultMessage: "We install your charger and maintain it",
    }),
    intl.formatMessage({
      id: "evaahProgramWorksThree",
      defaultMessage: "You save with an off-peak charging schedule",
    }),
  ],
  scrollTo: ["charger-options", "charger-choices", "charger-install"],
});

export const EasyChargingHome = ({ stateName, enrollURL, price }) => {
  const intl = useIntl();

  return (
    <div className="easy-charging-home">
      <section className="overview">
        <EnrollmentDetails
          stateName={stateName}
          howProgramWorks={howProgramWorks(intl)}
          enrollURL={enrollURL}
          price={price}
        />
        <ChargerOptions stateName={stateName} intl={intl}/>
      </section>
    </div>
  );
};

EasyChargingHome.propTypes = {
  stateName: PropTypes.string,
  enrollURL: PropTypes.string,
};
