import React from "react";
import PropTypes from "prop-types";
import SelectedChargingStationCard from "../SelectedChargingStationCard/SelectedChargingStationCard";
import ChargingStationsMapLegend from "../ChargingStationsMapLegend/ChargingStationsMapLegend";


import "./MapControlPanel.scss";

const MapControlPanel = ({ chargingStations, children, selectedStation }) => {
  return (
    <div className="MapControlPanel">
      <div className="map-controls">{children}</div>
      {(chargingStations || []).length > 0 && (
        <>
          <SelectedChargingStationCard station={selectedStation} />
          <ChargingStationsMapLegend chargingStations={chargingStations} />
        </>
      )}
    </div>
  );
};

MapControlPanel.propTypes = {
  chargingStations: PropTypes.array,
  children: PropTypes.node,
  selectedStation: PropTypes.object
};

MapControlPanel.defaultProps = {
  chargingStations: []
};

export default MapControlPanel;
