import React from "react";
import SmoothScroll from "../../../utils/Helpers/SmoothScroll";
const renderHowWorksColumn = (img, txt, i, scrollTo, offset=0, w=4) => {
    return (
      <div
        className={`col-md-${w} offset-md-${offset} how-works-column`}
        key={i}
      >
        {img !== undefined && (
          <div className="how-works-column-img">
            <div className="img-absolute">
              {img && (
                <img
                  src={img}
                  alt="How program works icon"
                  className="text-center"
                />
              )}
            </div>
          </div>
        )}
        {i < 2 ? (
          <div
            onClick={(e) => SmoothScroll(scrollTo, i)}
            className="how-works-column-button"
          >
            {txt}
          </div>
        ) : (
          <div className="how-works-column-txt">{txt}</div>
        )}
      </div>
    );
}

const HowProgramWorks = ({ className, text, images, smoothScroll}) => {
    return(
        <div className={`row how-works-graphics text-center ` + className}>
            {text.map((t, i) => {
                return renderHowWorksColumn(images[i], t, i, smoothScroll)
            })}
        </div>
    )
}

export default HowProgramWorks;