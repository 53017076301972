import {
  RENT_CHARGER_ID
} from '../client_customizations/constants/';

class WiringCostCalculator {
  constructor({
    chargingLocation,
    electricPanelLocationRoomType,
    electricPanelLocation,
    panelDistanceToChargerInFeet
  }) {
    this.chargingLocation = chargingLocation;
    this.electricPanelLocation = electricPanelLocation;
    this.electricPanelLocationRoomType = electricPanelLocationRoomType;
    this.panelDistanceToChargerInFeet = panelDistanceToChargerInFeet;
  }

  total(rate) {
    if (!rate.data.includeHomeWiringEstimator) return 0;
    return this._baseCost() +
      this._panelLocationCost() +
      this._roomTypeCost() +
      this._distanceCost();
  }

  _baseCost() {
    return this.chargingLocation.upfrontCost;
  }

  _panelLocationCost() {
    return this.electricPanelLocation.upfrontCost;
  }

  _roomTypeCost() {
    return this.electricPanelLocationRoomType.upfrontCost
  }

  _distanceCost() {
    return parseInt(9.38 * this.panelDistanceToChargerInFeet);
  }
}

class UpfrontChargerCostCalculator {
  constructor ({
    chargingLocation,
    electricPanelLocationRoomType,
    electricPanelLocation,
    panelDistanceToChargerInFeet,
    chargerPaymentType,
  }) {
    this.chargerPaymentType = chargerPaymentType;
    this.wiringCostCalculator = new WiringCostCalculator({
      chargingLocation,
      electricPanelLocationRoomType,
      electricPanelLocation,
      panelDistanceToChargerInFeet
    });
  }

  total(rate) {
    return this.chargerPlusInstall(rate) + this.wiring(rate);
  }

  wiring(rate) {
    return this.wiringCostCalculator.total(rate);
  }

  chargerPlusInstall(rate) {
    return this._buyingCost(rate);
  }

  meter(rate) {
    return rate.data.upfrontMeterCost || 0;
  }

  _charger(rate) {
    return rate.data.upfrontChargerCost || 0;
  }

  _buyingCost(rate) {
    if (rate.data.isBundled && this.chargerPaymentType === RENT_CHARGER_ID) return 0;
    return this._charger(rate) + this.meter(rate);
  }
}

export default UpfrontChargerCostCalculator;
