import React from "react";
import { FormattedMessage } from "react-intl";

const ThePerks = () => {
  return (
    <>
      <p>
        <b>
          <FormattedMessage
            id="perksTextPays"
            defaultMessage="It pays. "
            description="It pays. "
          />
        </b>
        <FormattedMessage
          id="perksTextPays2"
          defaultMessage="Eligible participants receive a $100 incentive for qualifying. Those who keep participating will get at least $50 at the end of the year."
          description="Eligible participants receive a $100 incentive for qualifying. Those who keep participating will get at least $50 at the end of the year."
        />
      </p>

      <p>
        <b>
          <FormattedMessage
            id="perksTextClean"
            defaultMessage="It’s clean. "
            description="It’s clean. "
          />
        </b>
        <FormattedMessage
          id="perksTextClean2"
          defaultMessage="You’ll help use even more renewable energy as we work together to build smarter energy infrastructure."
          description="You’ll help use even more renewable energy as we work together to build smarter energy infrastructure."
        />
      </p>

      <p>
        <b>
          <FormattedMessage
            id="perksTextEasy"
            defaultMessage="It’s easy. "
            description="It’s easy. "
          />
        </b>
        <FormattedMessage
          id="perksTextEasy2"
          defaultMessage="Just plug in at home so Xcel Energy and your automaker or evPulse can do the rest."
          description="Just plug in at home so Xcel Energy and your automaker or evPulse can do the rest."
        />
      </p>
    </>
  );
};

export default ThePerks;
