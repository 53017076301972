import React from "react";
import { FormattedMessage } from 'react-intl';

const CallUs = ({builders}) => {
    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <div className="colon-header">
                    <strong>
                        <FormattedMessage
                            id="MNTimeofDaySeparateMeter_additionalinformation_title"
                            defaultMessage="Additional Information"
                        />
                    </strong>
                </div>
                {builders ?
                <p>
                    <FormattedMessage
                        id="MNTimeofDaySeparateMeter_additionalinformation"
                        defaultMessage="To speak with the Builder’s Call Line, email us at {link1} or call {link2}."
                        values={{
                            link1:  <a href="mailto:Builder.Call.Line@xcelenergy.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                                Builders.Call.Line@xcelenergy.com
                            </a>,
                            link2:  <a href="tel:8006282121">800-628-2121</a>
                        }}
                    />
                </p>
                :
                <p>For more information about the EV Pricing Plan, email us at <a 
                                    href="mailto:Builder.Call.Line@xcelenergy.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >ElectricVehicles@xcelenergy.com</a> or call 800-895-4999 to speak to one of our energy advisors.</p>
                }

            </div>
        </div>
    )
}

export default CallUs;