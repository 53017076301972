import React from "react";
import "./Sidebar.scss";
import { FormattedMessage } from "react-intl";

const Sidebar = () => {
  const onClickRedirect = () => {
    window.open("http://my.xcelenergy.com/s/forms/ev-contact-us", "_blank");
  };

  return (
    <div className="lead-gen-sidebar">
      <button
        type="button"
        className="join-network-button"
        onClick={onClickRedirect}
      >
        <FormattedMessage
          id={"ContactUsSidebar"}
          defaultMessage={"Contact Us"}
        />
      </button>
    </div>
  );
};

export default Sidebar;
