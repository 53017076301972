import { WI_E11_ID } from '../../constants/';

const HOLIDAYS = [
  "2018-01-01",
  "2018-03-30",
  "2018-05-28",
  "2018-07-04",
  "2018-09-03",
  "2018-11-22",
  "2018-12-25",
];

const wi_e11 = {
  name: WI_E11_ID,
  title: "Residential Time of Day",
  separatelyMetered: false,
  isBundled: false,
  includeHomeWiringEstimator: true,
  upfrontChargerCost: 999,
  rateElements: [
    {
      name: "Basic Service Charge",
      rateElementType: "FixedPerMonth",
      rateComponents: [
        {
          charge: 17,
          name: "Per Month",
        },
      ],
    },
    {
      name: "Energy charge per kWh",
      rateElementType: "EnergyTimeOfUse",
      rateComponents: [
        {
          charge: 0.16 + 0.042,
          months: [5, 6, 7, 8],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 9a-9p
          exceptForDays: HOLIDAYS,
          name: "Summer On Peak",
        },
        {
          charge: 0.135 + 0.042,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 9a-9p
          exceptForDays: HOLIDAYS,
          name: "Winter On Peak",
        },
        {
          charge: 0.03575 + 0.042,
          months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // All year
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // All week
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 21, 22, 23], // 9p - 9a
          name: "Off Peak - Overnight",
        },
        {
          charge: 0.03575 + 0.042,
          months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // All year
          daysOfWeek: [0, 6], // M-F
          hourStarts: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 9a-9p
          exceptForDays: HOLIDAYS,
          name: "Weekends",
        },
        {
          charge: 0.03575 + 0.042,
          months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // All year
          hourStarts: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 9a-9p
          onlyOnDays: HOLIDAYS,
          name: "Holidays",
        },
      ],
    },
  ],
};

export default wi_e11;
