import React from "react";
import { FormattedMessage } from "react-intl";
import evChargingPlug from "../../client_customizations/assets/images/hero/evChargingPlug.png";
import ContentCenter from "../../client_customizations/components/PageLayout/BodyTemplates/ContentCenter";
import SavePlusCo from "../../client_customizations/components/SubscriptionServicePilot/SavePlusCo";
import FrequentlyAskedQuestionsSection from "../../client_customizations/components/FrequentlyAskedQuestionsSection/FrequentlyAskedQuestionsSection";
import { COLORADO, NEW_MEXICO } from "../../constants";

export const OptmizeYourCharge = ({ stateName, faqKey }) => {
  let mnSort = ["faqWhyAreOnly2LevelChargers", "faqBenefitsLevelTwoCharging", "termOfConditionOptimizeChargerMN", "faqParticipateIfRentHome", 
  "faqHowToEnrollOwnCharger", "whereFindEVID", "faqWorkWithElectricians", "faqFiftyBillCredit", 
  "scheduleChargingTimeAdjustMn", "participateOptimizeCharger", "participateIfOnlyEnrolled", "participateEvAccelerateAtHome", "enrollBusinessMN", "canIChargeWithRenewableEnergy",
  "canIenrollMN", "faqCancelParticipationMN"]

  let coAndnmSort = ["faqWhyAreOnly2LevelChargers", "faqBenefitsLevelTwoCharging", "termOfConditionOptimizeChargerMN", "faqParticipateIfRentHome", 
  "faqHowToEnrollOwnCharger", "whereFindEVID", "faqWorkWithElectricians", "faqFiftyBillCredit",
  "faqAdjustScheduledChargingTime", "participateOptimizeCharger", "participateIfOnlyEnrolled", "canIChargeWithRenewableEnergy", "canIenrollMN", "faqCancelParticipation"]
  let sortState = (stateName === NEW_MEXICO || stateName === COLORADO) ? coAndnmSort : mnSort

  return (
    <>
      <img
        src={evChargingPlug}
        alt="Hero"
        style={{ minWidth: "100%", maxWidth: "100%" }}
      />
      <ContentCenter
        pageHeaderLead={
          <FormattedMessage
            id="optimizeYourCharge"
            defaultMessage="Optimize Your Charge"
            description="Optimize Your Charge"
          />
        }
        pageHeaderHeadLine={
          <FormattedMessage
            id="saveMoneyOnCharging"
            defaultMessage="Save Money on Charging"
            description="Save Money on Charging"
          />
        }
        sectionStyle={{ backgroundColor: "#F6F8FB" }}
      >
        <SavePlusCo
          stateName={stateName}
        />
      </ContentCenter>
      <ContentCenter
        pageHeaderLead={
          <FormattedMessage
            id="frequentlyAskedQuestions"
            defaultMessage="FREQUENTLY ASKED QUESTIONS"
            description="FREQUENTLY ASKED QUESTIONS"
          />
        }
        sectionStyle={{ backgroundColor: "#FFFFFF" }}
      >
        <FrequentlyAskedQuestionsSection applicableTo={faqKey} region={stateName} sort={sortState}/>
      </ContentCenter>
    </>
  );
};
