import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Left here to document poetential usage of Location map */
/* import MapLegend from "./Map/MapLegend";
import InputWorkingZipcodeForDealerMap from "../InputComponents/InputZipcode/InputZipcodeForChargingMap";
import ShowHideButton from "../ShowHideButton/ShowHideButton"; */

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import InputZipcodeForDealersMap from "../../../components/InputComponents/InputZipcode/InputZipcodeForDealersMap";
import Select from "../../../components/shared/InputElements/Select";
import SelectedCard from "../SelectedCard/SelectedCard";
import { FormattedMessage, useIntl } from "react-intl";
import BaseGoogleMapReact from "../../../components/LocationMap/BaseGoogleMapReact/BaseGoogleMapReact";
import DealerPin from "../../assets/images/icons/dealer-pin.png";
import dealership from "../../assets/images/dealership.png";
import xcelDealerPin from "../../assets/images/icons/xcel-dealer-pin.png";

import "./LocationMap.scss";

function debounce(func, wait) {
     let timeout;
     return function (...args) {
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(this, args), wait);
     };
}

const DealerMarker = ({ name, isSelected, onClick, onMouseOver, type }) => {
     const dealerMarkerObject = {
          Gold: xcelDealerPin,
          Silver: xcelDealerPin,
          default: DealerPin,
     };

     const styles = {
          width: "25px",
          ...(isSelected && {
               filter: "brightness(85%)",
          }),
     };

     const placeStyle = {
          display: "block",
          position: "absolute",
          transform: "translate(-50%, -100%)",
     };

     let dealerType = type;
     if (name.includes("Boulder")) {
          dealerType = "Gold";
     }

     return (
          <span style={placeStyle} onClick={onClick} onMouseOver={onMouseOver}>
               <img
                    src={dealerType ? dealerMarkerObject[dealerType] : dealerMarkerObject["default"]}
                    style={styles}
                    alt="Dealer Pin"
               />
          </span>
     );
};

const SelectOem = ({ dealers, onChange, selectedOem }) => {
     const intl = useIntl();
     const oems = [
          ...[
               intl.formatMessage({
                    id: "dealers.allBrands",
                    defaultMessage: "All Brands",
               }),
          ],
          ...dealers.map((d) => d.oem),
     ];

     const uniqueOems = [...new Set(oems)];
     const filteredOems = uniqueOems.filter((oem) => oem !== null && oem !== undefined);

     const independentDealers = "Independent Dealers";
     const allBrands = intl.formatMessage({
          id: "dealers.allBrands",
          defaultMessage: "All Brands",
     });

     const remainingOems = filteredOems
          .filter((oem) => oem !== independentDealers && oem !== allBrands)
          .sort((a, b) => a.localeCompare(b));

     const sortedOems = [independentDealers, allBrands, ...remainingOems];

     const options = sortedOems.map((oem, index) => ({
          label: oem === independentDealers || oem === allBrands ? oem : `- ${oem}`,
          value: oem,
     }));

     return (
          <div className="form-group">
               <Select
                    id="select-oem"
                    value={selectedOem}
                    handler={(e) => onChange(e.target.value)}
                    label={intl.formatMessage({
                         id: "dealers.brands",
                         defaultMessage: "Brands",
                    })}
                    optionNames={options.map((option) => option.label)}
                    optionValues={options.map((option) => option.value)}
               />
          </div>
     );
};

SelectOem.propTypes = {
     dealers: PropTypes.array,
     onChange: PropTypes.func,
     selectedOem: PropTypes.string,
};

const SelectSpecificDealer = ({ dealers, onChange, selectedDealerId, selectedOem }) => {
     let filteredDealers = new Map();

     dealers.forEach((dealer) => {
          filteredDealers.set(dealer.name, dealer);
     });

     const sortedDealers = Array.from(filteredDealers.values()).sort((a, b) => a.name.localeCompare(b.name));
     const sortedDealerNames = sortedDealers.map((d) => d.name);
     const intl = useIntl();

     return (
          <div className="form-group">
               <Select
                    id="select-dealer"
                    value={selectedDealerId}
                    handler={(e) => onChange(e.target.value)}
                    label={intl.formatMessage({ id: "dealers", defaultMessage: "Dealers" })}
                    optionNames={[
                         intl.formatMessage({
                              id: "dealers.allDealers",
                              defaultMessage: "All Dealers",
                         }),
                         ...sortedDealerNames,
                    ]}
                    optionValues={["All Dealers", ...sortedDealerNames]}
               />
          </div>
     );
};

const DealerMap = ({
     dealerLocations,
     userLocation,
     ignoreDealersOtherThan,
     isVisible = true,
     zipcode,
     electricVehicles,
     usedElectricVehicles,
}) => {
     const [selectedDealerId, setselectedDealerId] = useState("");
     const [selectedOem, setSelectedOem] = useState("All Brands");
     const [xcelDealers, setXcelDealers] = useState(true);
     const [dealers, setDealers] = useState(dealerLocations);
     const [center, setCenter] = useState();

     useEffect(() => {}, [dealers]);

     useEffect(() => {
          if (xcelDealers && dealerLocations) {
               const filteredDealerLocations = dealerLocations.filter(
                    (dealer) => dealer.type === "Gold" || dealer.type === "Silver"
               );
               setDealers(filteredDealerLocations);
          } else {
               setDealers(dealerLocations);
          }
     }, [xcelDealers, dealerLocations]);

     useEffect(() => {
          if (!userLocation) return;
          setCenter({
               lat: parseFloat(userLocation.latitude),
               lng: parseFloat(userLocation.longitude),
          });
     }, [userLocation]);

     if (!dealers) return null;

     const findDealer = () => dealerLocations.find((dealer) => dealer.name === selectedDealerId);

     const changeOem = (oem) => {
          setSelectedOem(oem);
          if (oem === "All Brands") {
               setDealers(dealerLocations);
          } else if (oem === "Independent Dealers") {
               const newDealers = dealerLocations.filter(
                    (dealer) =>
                         dealer.oem === "Boulder Hybrids" ||
                         dealer.oem === "All Brands" ||
                         dealer.name.includes("Boulder")
               );
               setDealers(newDealers);
          } else if (oem === "Boulder Hybrids") {
               const newDealers = dealerLocations.filter(
                    (dealer) =>
                         dealer.oem === "Boulder Hybrids" ||
                         dealer.oem === "All Brands" ||
                         dealer.name.includes("Boulder")
               );
               setDealers(newDealers);
          } else {
               const newDealers = dealerLocations.filter((dealer) => dealer.oem === oem || dealer.oem === "All Brands");
               setDealers(newDealers);
          }
     };

     const changeDealer = (dealerId) => {
          if (dealerId === "All Dealers") {
               setselectedDealerId("");
               setDealers(
                    selectedOem === "All Brands"
                         ? dealerLocations
                         : dealerLocations.filter((dealer) => dealer.oem === selectedOem || dealer.oem === "All Brands")
               );
          } else {
               setselectedDealerId(dealerId);
               const newDealer = dealerLocations.find((dealer) => dealer.name === dealerId);
               setDealers([newDealer]);
          }
     };

     const debouncedOnMouseOver = debounce((dealerName) => {
          setselectedDealerId(dealerName);
     }, 150);

     let renderArr = [];

     const body = !userLocation ? (
          <div className="spinner-container">
               <LoadingSpinner />
          </div>
     ) : (
          <>
               <div className="col-sm-3 left-column">
                    <div className="controls">
                         <SelectOem dealers={dealerLocations} selectedOem={selectedOem} onChange={changeOem} />

                         <SelectSpecificDealer
                              dealers={dealers}
                              selectedDealerId={selectedDealerId}
                              onChange={changeDealer}
                              selectedOem={selectedOem}
                         />

                         <InputZipcodeForDealersMap zipcode={zipcode} />

                         <div className="form-group">
                              <button
                                   type="button"
                                   className="btn-ae btn-default mb-2 btn-block"
                                   onClick={() => setXcelDealers((prevState) => !prevState)}
                              >
                                   Show {xcelDealers ? "All" : "Xcel"} Dealerships
                              </button>
                         </div>
                    </div>
                    {findDealer() && (
                         <div className="selected-dealer-container">
                              <SelectedCard
                                   dealerLocations={dealerLocations}
                                   electricVehicles={electricVehicles}
                                   usedElectricVehicles={usedElectricVehicles}
                                   oemImage={true}
                                   {...findDealer()}
                              />
                         </div>
                    )}
               </div>
               <div className="col-sm-9">
                    <div className="map-container">
                         {isVisible && (
                              <BaseGoogleMapReact zoom={8} center={center}>
                                   {dealers.map((dealer) => {
                                        if (renderArr.includes(dealer.name + dealer.oem)) {
                                             return <></>;
                                        }
                                        renderArr.push(dealer.name + dealer.oem);
                                        return (
                                             <DealerMarker
                                                  key={dealer.name + dealer.oem}
                                                  name={dealer.name}
                                                  lat={dealer.latitude}
                                                  lng={dealer.longitude}
                                                  type={dealer.type}
                                                  isSelected={dealer.name === selectedDealerId}
                                                  onMouseOver={() => {
                                                       debouncedOnMouseOver(dealer.name);
                                                  }}
                                                  onClick={() => {
                                                       setselectedDealerId(dealer.name);
                                                  }}
                                             />
                                        );
                                   })}
                              </BaseGoogleMapReact>
                         )}
                    </div>
               </div>
          </>
     );

     const renderIconTextCard = ({ title, description, key, link }) => {
          return (
               <a href={link} class="ev-faq-card-link">
                    <div key={key} className="ev-faq-card icon-text">
                         <span className="card-title">{title}</span>
                         {description}
                    </div>
               </a>
          );
     };

     const cardsContent = [
          <img key={"dealership"} src={dealership} alt="EV Dealership Charging Vehicle" />,
          renderIconTextCard({
               title: (
                    <FormattedMessage
                         id="compareElectricVehicles"
                         defaultMessage="Compare Electric Vehicles"
                         description="Compare Electric Vehicles"
                    />
               ),
               description: (
                    <p>
                         <FormattedMessage
                              id="dealerCompareEVDescription"
                              defaultMessage="Want to see how the EV you’re interested in stacks up against other makes and models? Use the vehicle comparison tool while you're at the dealership."
                              description="Compare Electric Vehicles Description"
                         />
                    </p>
               ),
               key: "compare electric vehicles",
               link: "https://ev.xcelenergy.com/compare-vehicles",
          }),
          renderIconTextCard({
               title: (
                    <FormattedMessage
                         id="dealersFindSavings"
                         defaultMessage="Find Savings"
                         description="Find Savings"
                    />
               ),
               description: (
                    <p>
                         <FormattedMessage
                              id="dealersFindSavingsDescription"
                              defaultMessage="EV incentives and rebates can be difficult to navigate, but Gold and Silver Status EV Dealerships will help you maximize your savings on a new or used car."
                              description="Find Savings Description"
                         />
                    </p>
               ),
               key: "find savings",
               link: "https://ev.xcelenergy.com/incentives",
          }),
          renderIconTextCard({
               title: (
                    <FormattedMessage
                         id="dealersLearnMoreCharging"
                         defaultMessage="Learn More About Charging"
                         description="Learn More About Charging"
                    />
               ),
               description: (
                    <p>
                         <FormattedMessage
                              id="dealersLearnMoreChargingDescription"
                              defaultMessage="Not sure how you’re going to charge your new EV once you bring it home? Learn about charging and programs that Xcel Energy offers, and enroll in charging programs right at the dealership."
                              description="Learn More About Charging Description"
                         />
                    </p>
               ),
               key: "Learn More About Charging",
               link: "https://ev.xcelenergy.com/ev-charging-programs",
          }),
     ];

     const renderReasonsToGoElectric = (
          <div className="row m-0 flex-column">
               <div className="reason-titles">
                    <p className="reason-subtitle">
                         <FormattedMessage
                              id="dealersReasonToGoElectric"
                              defaultMessage="Dealers in our EV Network will help you find the right EV and explore Xcel Energy programs that provide hassle-free home charging and savings."
                              description="Dealer Reason To Go Electric Header"
                         />
                    </p>
               </div>
               <div className="d-flex flex-wrap pb-4 reason-cards">{cardsContent}</div>
          </div>
     );
     return (
          <div className="DealerMap">
               <div className="row hide-offscreen">
                    <div className="col-sm-12">
                         <h2>
                              <FormattedMessage id="dealers" defaultMessage="Dealers" description="Dealers" />
                         </h2>
                    </div>
               </div>
               <div className="row dealerMap-content">{body}</div>
               <div className="DealersMapLegend">
                    <div className="dealer-legend-title">
                         <b>
                              <FormattedMessage id="dealerLegend" defaultMessage="Legend" description="Legend" />
                         </b>
                    </div>
                    <div className="dealer-type">
                         <img src={xcelDealerPin} alt="Xcel Pin" />
                         <b>
                              <FormattedMessage
                                   id="dealerXcel"
                                   defaultMessage="Xcel Energy EV Dealership"
                                   description="Xcel Dealer"
                              />
                              :&nbsp;
                         </b>
                         <span>
                              <FormattedMessage
                                   id="dealerSilverDescription"
                                   defaultMessage="EV-focused dealer allies, often located in suburban or rural areas"
                                   description="Silver Dealer Description"
                              />
                         </span>
                         <br />
                    </div>

                    <div className="dealer-type">
                         <img src={DealerPin} alt="Default Pin" />
                         {/* <b>
            <FormattedMessage
              id="dealerDefault+"
              defaultMessage="Default"
              description="Default"
            />:
          </b> */}
                         <span>
                              <b>
                                   <FormattedMessage
                                        id="dealerDefaultDescription"
                                        defaultMessage="Dealers unaffiliated with Xcel Energy"
                                        description="Default Dealer Description"
                                   />
                              </b>
                         </span>
                         <br />
                    </div>
               </div>
               {renderReasonsToGoElectric}
          </div>
     );
};

export default DealerMap;

DealerMap.propTypes = {
     chargingStations: PropTypes.array,
     userLocation: PropTypes.object,
     ignoreDealersOtherThan: PropTypes.bool,
};
