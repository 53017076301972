import React, { useContext } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import Range from '../../../../components/shared/InputElements/Range';
import { FormattedMessage } from 'react-intl';

const SlidePublicChargingCost = () => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id="home-charging-advisor-public-charging-cost"
      value={userPrefs.get("publicChargingCostInCents")}
      handler={(e) => userPrefs.set({publicChargingCostInCents: e.target.value})}
      label={<FormattedMessage
        id="hcaPublicChargingCost"
        defaultMessage="Public Charging Cost"
        description="Public Charging Cost"
      />}
      rangeMin={20}
      rangeMax={60}
      rangeStep={20}
      description={val => `$0.${val} / kWh`}
    />

  );
};

export default SlidePublicChargingCost;
