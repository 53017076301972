import { MN_EV_ACCELERATE } from '../../constants/';

const HOLIDAYS = [
  "2019-01-01",
  "2019-04-19",
  "2019-05-27",
  "2019-07-04",
  "2019-09-02",
  "2019-11-28",
  "2019-12-25",
];

const FIXED_CHARGE_WHEN_RENTING = 16.48;
const FIXED_CHARGE_WHEN_BUYING = 6.68;
const mn_ev_accelerate = {
  name: MN_EV_ACCELERATE,
  title: 'EV Accelerate At Home',
  separatelyMetered: true,
  isBundled: true,
  includeHomeWiringEstimator: true,
  upfrontChargerCost: 770,
  monthlyDiscountForBuyingCharger: FIXED_CHARGE_WHEN_BUYING - FIXED_CHARGE_WHEN_RENTING,
  rateElements: [
    {
      name: 'Basic Service Charge',
      rateElementType: 'FixedPerMonth',
      rateComponents: [
        {
          charge: FIXED_CHARGE_WHEN_RENTING,
          name: 'Per Month',
        },
      ],
    },
    {
      name: 'Energy charge per kWh',
      rateElementType: 'EnergyTimeOfUse',
      rateComponents: [
        {
          charge: 0.028,
          months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // All year
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // All week
          hourStarts: [0,1,2,3,4,5],
          name: 'Overnight',
        },
        {
          charge: 0.226,
          months: [5, 6, 7, 8],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [15, 16, 17, 18, 19], // 3p-8p
          exceptForDays: HOLIDAYS,
          name: "Summer On Peak Weekdays",
        },
        {
          charge: 0.193,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [15, 16, 17, 18, 19], // 3p-8p
          exceptForDays: HOLIDAYS,
          name: "Winter On Peak Weekdays",
        },
        {
          charge: 0.09,
          months: [5, 6, 7, 8],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 20, 21, 22, 23], // 6am-3p, 8p-midnight
          exceptForDays: HOLIDAYS,
          name: "Summer Mid Peak Weekdays",
        },
        {
          charge: 0.075,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 20, 21, 22, 23], // 6am-3p, 8p-midnight
          exceptForDays: HOLIDAYS,
          name: "Winter Mid Peak Weekdays",
        },
        {
          charge: 0.09,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 6], // M-F
          hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], // 6am-12am
          exceptForDays: HOLIDAYS,
          name: "Summer Weekends",
        },
        {
          charge: 0.075,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 6], // M-F
          hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], // 6am-12am
          exceptForDays: HOLIDAYS,
          name: "Winter Weekends",
        },
        {
          charge: 0.09,
          months: [5, 6, 7, 8],
          hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], // 6am-12am
          onlyOnDays: HOLIDAYS,
          name: "Summer Holidays",
        },
        {
          charge: 0.075,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], // 6am-12am
          onlyOnDays: HOLIDAYS,
          name: "Winter Holidays",
        },
      ],
    },
  ],
};

export default mn_ev_accelerate;
