import React from "react";

const RenewableEnergy = () => {
    return (
    <>
        <p>Charge your EV with 100% renewable energy by subscribing to our low-cost <a
                        href="https://www.xcelenergy.com/programs_and_rebates/residential_programs_and_rebates/renewable_energy_options_residential/windsource_for_residences"
                        target="_blank"
                        rel="noopener noreferrer"
                    >Windsource</a>® program. When you subscribe, an additional $2.52 will be included in your monthly subscription price during the two-year pilot. Learn more abouot our <a
                    href="https://www.xcelenergy.com/programs_and_rebates/residential_programs_and_rebates/renewable_energy_options_residential/windsource_for_residences"
                    target="_blank"
                    rel="noopener noreferrer"
                >Windsource</a>® renewable energy options.</p>
    </>
    )
}

export default RenewableEnergy;