import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from 'react-intl';
import fetchElectricians from "../../services/fetchElectricians";
import Select from "../../components/shared/InputElements/Select"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import GenericInputZipcode from "../../components/InputComponents/InputZipcode/GenericInputZipcode"
import SelectedCard from "../../components/SelectedCard/SelectedCard"
import DealerPin from "../../client_customizations/assets/images/icons/dealer-pin.png";
import BaseGoogleMapReact from './BaseGoogleMapReact/BaseGoogleMapReact';

const ElectricianMarker = ({ isSelected, onClick, onMouseOver}) => {
  const styles = {
    width: "25px",
    ...(isSelected && {
      filter: "brightness(85%)"
    })
  };

  const placeStyle = {
    display: "block",
    position: "absolute",
    transform: "translate(-50%, -100%)"
  };

  return (
    <span style={placeStyle} onClick={onClick} onMouseOver={onMouseOver}>
      <img src={DealerPin} style={styles} alt="" />
    </span>
  );
};

const ElectriciansMap = ({ userLocation, zipcode, ip, uuid, isVisible }) => {
  const [selectedElectricianName, setSelectedElectricianName] = useState(undefined);
  const [electricianType, setElectricianType] = useState('All')
  const [electricians, setElectricians] = useState([])
  const [center, setCenter] = useState();
  const intl = useIntl()

  useEffect ( () => {
    let ignore = false;

    async function fetchData() {
      const params = {
        postcode: zipcode,
        distance: 40
      }
      try {
        const electricians = await fetchElectricians(params)
        if (!ignore) setElectricians(electricians)
      } catch (e) {
        console.log("Failed to load electricians")
      }
    }

    fetchData()
    document.title = process.env.REACT_APP_PAGES_ELECTRICIANS_TITLE;

    return () => { ignore = true; }

  }, [zipcode])

  useEffect(() => {
    if (!userLocation) return;
    setCenter({
      lat: parseFloat(userLocation.latitude),
      lng: parseFloat(userLocation.longitude)
    });
  }, [userLocation]);

  const findElectrician = () => (electricians || []).find(electrician => electrician.name === selectedElectricianName)

  const deselectElectrician = () => setSelectedElectricianName(undefined);

  const filteredElectricians = (electricians || []).filter(electrician => {
    if (electricianType === "All") {
      return true
    }
    return electrician[electricianType.toLowerCase()] === 1
  })

  const SelectElectricianType = () => {

    return (
      <div className="form-group">
        <Select
          value={electricianType}
          handler={(e) => setElectricianType(e.target.value)}
          label={intl.formatMessage({ id: "electricians.type", defaultMessage: "Electrician Type"})}
          optionNames={[intl.formatMessage({ id: "all", defaultMessage: "All"}), intl.formatMessage({ id: "electricians.residential", defaultMessage: "Residential"}), intl.formatMessage({ id: "electricians.commercial", defaultMessage: "Commercial"}), intl.formatMessage({ id: "electricians.industrial", defaultMessage: "Industrial"})]}
          optionValues={["All", "Residential", "Commercial", "Industrial"]}
        />
      </div>
    );
  };

  const body = !userLocation ? (
    <div className="spinner-container">
      <LoadingSpinner />
    </div>
  ) : (
    <>
        <div className="col-sm-3 left-column">
            <div className="controls">
              <SelectElectricianType />

              <GenericInputZipcode buttonText={intl.formatMessage({ id: "electricians.searchElectricians", defaultMessage: "Search Qualified Electricians"})}/>
            </div>
            {findElectrician() && (
              <div className="selected-dealer-container">
                <SelectedCard
                  {...findElectrician()}
                />
              </div>
            )}
        </div>
        <div className="col-sm-9">
          <div className="map-container">
            {isVisible && (
              <BaseGoogleMapReact
                center={center}
                zoom={10}
              >
                {filteredElectricians.map(electrician => (
                  <ElectricianMarker
                    key={electrician.name}
                    lat={electrician.latitude}
                    lng={electrician.longitude}
                    isSelected={electrician.name === selectedElectricianName}
                    onMouseOver={() => {setSelectedElectricianName(electrician.name)}}
                    onClick={deselectElectrician}
                  />
                ))

                }
              </BaseGoogleMapReact>
            )}
          </div>
        </div>
    </>
  );

  return (
    <div className="DealerMap">
      <div className="row hide-offscreen">
        <div className="col-sm-12">
          <h2>
            <FormattedMessage
              id="electricians"
              defaultMessage="Electricians"
              description="Electricians"
            />
          </h2>
        </div>
      </div>
      <div className="row dealerMap-content">
        {body}
      </div>
    </div>
  );
};

export default ElectriciansMap;

ElectriciansMap.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
