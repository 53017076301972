import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import EvSubscriptionServicePilotIcon from "../../assets/images/icons/EvSubscriptionServicePilotIcon.svg";
import EvAccelerateAtHomeIcon from "../../assets/images/icons/EvAccelerateAtHomeIcon.svg";
import EvChargeWithRenableEnergyIcon from "../../assets/images/icons/EvChargeWithRenableEnergyIcon.svg";
import EvRegularResidentialIcon from "../../assets/images/icons/EvRegularResidentialIcon.svg";
import EvSpotNetworkMnIcon from "../../assets/images/icons/EvSpotNetworkMnIcon.svg";
import EVTimeOfDaySeperateIcon from "../../assets/images/icons/EVTimeOfDaySeperateIcon.svg";
import EVTimeOfDayWholeIcon from "../../assets/images/icons/EVTimeOfDayWholeIcon.svg";
import { FormattedMessage } from "react-intl";

import "./ChargingPrograms.scss";
import { NEW_MEXICO, COLORADO, MINNESOTA } from "../../../constants";


export const EvAccelerateAtHome = (
  { link } = { link: "/ev-accelerate-at-home-co" }

) => ({
  headLine: (
    <span className="headline">
      <FormattedMessage
        id="EVAAHHeader"
        defaultMessage="EV Accelerate At Home – Pay As You Go"
        description="EV Accelerate At Home – Pay As You Go"
      />
    </span>
  ),
  body: (
    <p>
      <FormattedMessage
        id="EVAAHCOSubHeader"
        defaultMessage="With EV Accelerate At Home you can save on charging with a Level 2 charger, and we’ll do all the work to set you up. You can rent the charger from Xcel Energy that we install and maintain, or you can provide your own (Xcel Energy approved) charging station."
        description="EVAAHCOSubHeader"
        values={{
          link: <Link to={link}>EV Accelerate At Home</Link>,
        }}
      />
    </p>
  ),
  image: EvAccelerateAtHomeIcon,
});

export const EvChargeWithRenableEnergy = (
  { link } = {
    link: "https://www.xcelenergy.com/programs_and_rebates/residential_programs_and_rebates/renewable_energy_options_residential/windsource_for_residences",
  }
) => ({
  headLine: (
    <span className="headline">
      <FormattedMessage
        id="chargeWithRenewableEnergy"
        defaultMessage="Charge with Renewable Energy"
        description="Charge with Renewable Energy"
      />
    </span>
  ),
  body: (
    <p>
      <FormattedMessage
        id="chargeWithRenewableEnergySubHeader"
        defaultMessage="As an Xcel Energy customer, you’re already using renewable energy. Solar power, wind energy, and other sources all add up to create a diverse fuel mix to meet your energy needs. And if you’re looking to reduce your carbon footprint even more, you can offset your EV driving by enrolling in one of our renewable energy programs. Explore your options and enroll here."
        description="Charge with Renewable Energy SubHeader"
        values={{
          lineBreak: <br />,
          link: (
            <a rel="noopener noreferrer" target="_blank" href={link}>
              <FormattedMessage
                id="exporeYourOptionsHere"
                defaultMessage="Explore your options and enroll here"
                description="Explore your options and enroll here"
              />
            </a>
          ),
        }}
      />
    </p>
  ),
  image: EvChargeWithRenableEnergyIcon,
});

const EvChargingPrograms = {
  EvSubscriptionServicePilot: {
    headLine: (
      <span className="headline">
        {" "}
        EV Subscription Service Pilot – Flat Bill
      </span>
    ),
    body: (
      <p>
        The{" "}
        <Link to="/subscription-pilot-mn">EV Subscription Service Pilot</Link>{" "}
        program is open to 150 customers on a first-come, first-served basis.
        You select a Level 2 charger from one of our two pre-qualified options,
        we’ll install it and maintain it; you get unlimited charging for one
        low, flat price on your monthly bill when fueling overnight and on
        weekends.
      </p>
    ),
    image: EvSubscriptionServicePilotIcon,
  },
  EvAccelerateAtHome: {
    headLine: (
      <span className="headline">
        {" "}
        <FormattedMessage
          id="EVAAHHeader"
          defaultMessage="EV Accelerate At Home – Pay As You Go"
          description="EV Accelerate At Home – Pay As You Go"
        />
      </span>
    ),
    body: (
      <p>
        With <Link to="/ev-accelerate-at-home-wi">EV Accelerate At Home</Link>{" "}
        you can save on charging with a Level 2 charger, and we’ll do all the
        work to set you up. You select a Level 2 charger from one of our two
        pre-qualified options, we’ll install it and maintain it; you charge for
        less when fueling overnight.
      </p>
    ),
    image: EvAccelerateAtHomeIcon,
  },
  EvAccelerateAtHomeMn: {
    headLine: (
      <span className="headline">
        <FormattedMessage
          id="EVAAHHeader"
          defaultMessage="EV Accelerate At Home – Pay As You Go"
          description="EV Accelerate At Home – Pay As You Go"
        />
      </span>
    ),
    body: (
      <p>
        <FormattedMessage
          id="EVAAH_Description_MN_v2"
          defaultMessage="With {link1} you can save on charging with a Level 2 charger, and we’ll do all the work to set you up. You can rent the charger from Xcel Energy that we install and maintain, or you can provide your own (Xcel Energy approved) charging station. "
          description="Save charging"
          values={{
            link1: <a
              href={"https://ev.xcelenergy.com/ev-accelerate-at-home-mn"} //EVAAH_Description_MN_link_URL
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="EVAAH_Description_MN_link_text"
                defaultMessage="EV Accelerate At Home"
                description="EV Accelerate At Home"
              />
            </a>
          }}
        />
      </p>
    ),
    image: EvAccelerateAtHomeIcon,
  },
  EvAccelerateAtHomeWi: {
    headLine: (
      <span className="headline">
        <FormattedMessage
          id="EVAAHHeader"
          defaultMessage="EV Accelerate At Home – Pay As You Go"
          description="EV Accelerate At Home – Pay As You Go"
        />
      </span>
    ),
    body: (
      <p>
        <FormattedMessage
          id="EVAAH_Description_WI_v2"
          defaultMessage="With {link} you can save on charging with a Level 2 charger, and we’ll do all the work to set you up. You can rent the charger from Xcel Energy that we install and maintain, or you can provide your own (Xcel Energy approved) charging station."
          description="Save charging"
          values={{
            link: <a
              href={"https://ev.xcelenergy.com/ev-accelerate-at-home-wi"} //EVAAH_Description_WI_link_URL
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="EVAAH_Description_WI_link_text"
                defaultMessage="EV Accelerate At Home"
                description="EV Accelerate At Home"
              />
            </a>
          }}
        />
      </p>
    ),
    image: EvAccelerateAtHomeIcon,
  },
  EvAccelerateAtHomeCo: {
    headLine: (
      <span className="headline">
        <FormattedMessage
          id="EVAAHHeader"
          defaultMessage="EV Accelerate At Home – Pay As You Go"
          description="EV Accelerate At Home – Pay As You Go"
        />
      </span>
    ),
    body: (
      <p>
        <FormattedMessage
          id="EVAAHCOSubHeader_v2"
          defaultMessage="With {link} you can save on charging with a Level 2 charger, and we’ll do all the work to set you up. You can rent the charger from Xcel Energy that we install and maintain, or you can provide your own (Xcel Energy approved) charging station. "
          description="EVAAHCOSubHeader"
          values={{
            link: (
              <Link to="/ev-accelerate-at-home-co">EV Accelerate At Home</Link>
            ),
          }}
        />
      </p>
    ),
    image: EvAccelerateAtHomeIcon,
  },

  EvChargeWithRenableEnergy: EvChargeWithRenableEnergy(),
  EvRegularResidential: {
    headLine: (
      <span className="headline">
        {" "}
        <FormattedMessage
          id="regularResidential"
          defaultMessage="Regular Residential"
          description="Regular Residential"
        />
      </span>
    ),
    body: (
      <p>
        <FormattedMessage
          id="regularResidentialSubHeader"
          defaultMessage="If you prefer to charge your vehicle and use energy in your home whenever you want at the same price all day, the Regular Residential Pricing plan is your go-to option. Unless you’ve signed up for a separate pricing plan for your home, you likely already have this standard residential electric pricing."
          description="Regular Residential SubHeader"
          values={{
            link: (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.xcelenergy.com/company/rates_and_regulations/rates/rate_books"
              >
                <FormattedMessage
                  id="regularResidentialPricingPlan"
                  defaultMessage="Regular Residential Pricing plan"
                  description="Regular Residential Pricing plan"
                />
              </a>
            ),
          }}
        />
      </p>
    ),
    image: EvRegularResidentialIcon,
  },
  EvSpotNetworkMn: {
    headLine: (
      <span className="headline">
        <FormattedMessage
          id="EasierThanEverTwinCities_Title_MN"
          defaultMessage="It’s easier than ever to use an electric vehicle in the Twin Cities."
          description="It’s easier than ever to use an electric vehicle in the Twin Cities."
        />
      </span>
    ),
    body: (
      <p>
        <FormattedMessage
          id="EasierThanEverTwinCities_Description_MN"
          defaultMessage="The City of Saint Paul – in partnership with the City of Minneapolis, Xcel Energy, and HOURCAR – has launched the {link1} to support increased access to and use of electric vehicles. The network will include 70 EV Spots that offer public access to both the new all-electric {link2} and electric vehicle charging."
          description="Regular Residential SubHeader"
          values={{
            link1: <a
              href={'https://www.stpaul.gov/departments/public-works/transportation-and-transit/ev-spot-network?utm_medium=email&utm_source=partner&utm_campaign=evnetworklaunch'} //EasierThanEverTwinCities_Description_MN_link1_URL
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="EasierThanEverTwinCities_Description_MN_link1_text"
                defaultMessage="EV Spot Network"
              />
            </a>,
            link2: <a
              href={'https://eviecarshare.com/partner/?utm_medium=email&utm_source=partner&utm_campaign=evielaunch'} //EasierThanEverTwinCities_Description_MN_link2_URL
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="EasierThanEverTwinCities_Description_MN_link2_text"
                defaultMessage="Evie carshare service"
              />
            </a>,
          }}
        />
      </p>
    ),
    image: EvSpotNetworkMnIcon,
  },
  EVTimeOfDaySeperate: {
    headLine: <span className="headline">
        <FormattedMessage
          id="TimeOfDaySeparateMeter_Title_MN"
          defaultMessage="Time of Day – Separate Meter"
          description="Time of Day – Separate Meter"
        />
      </span>,
    body: (
      <p>
        <FormattedMessage
          id="TimeOfDaySeparateMeter_Description_MN"
          defaultMessage="{link1} requires installation of a separate meter that costs an average of $2,000 and is the best option for less than 5% of customers (who often have a detached garage). Pricing for energy is about half the amount of the regular residential rate for EV drivers who charge overnight and on weekends."
          description="The Time of Day – Separate Meter plan requires installation of a separate meter that costs an average of $2,000 and is the best option for less than 5% of customers (who often have a detached garage). Pricing for energy is about half the amount of the regular residential rate for EV drivers who charge overnight and on weekends."
          values={{
            link1: <a href='https://ev.xcelenergy.com/time-of-day-separate-meter-mn'>
              <FormattedMessage
                id="TimeOfDaySeparateMeter_Description_MN_link_text"
                defaultMessage="The Time of Day – Separate Meter plan"
              />
            </a>
          }}
        />
      </p>
    ),
    image: EVTimeOfDaySeperateIcon,
  },
  EVTimeOfDayWhole: {
    headLine: <span className="headline">
        <FormattedMessage
          id="TimeOfDayWholeHome_Title_MN/WI"
          defaultMessage="Time of Day – Whole Home"
          description="Time of Day – Whole Home"
        />
    </span>,
    body: (
      <p>
          <FormattedMessage
            id="TimeOfDayWholeHome_Description_MN/WI"
            defaultMessage="{link1} is for drivers who prefer to charge their vehicle at night and on weekends and can wait to run major appliances in the late evening or overnight. Pricing for energy is about half the amount of the Regular Residential plan for overnight and weekend use."
            description="The Time of Day"
            values={{
              link1: <a
                href={"https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day?_ga=2.88321755.1563902174.1668733223-1188439388.1662760524"} //TimeOfDayWholeHome_Description_MN/WI_link_URL
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="TimeOfDayWholeHome_Description_MN/WI_link_text"
                  defaultMessage="The Time of Day – Whole Home plan"
                />
              </a>
            }}
          />
      </p>
    ),
    image: EVTimeOfDayWholeIcon,
  },
  TimeOfUse: {
    headLine: (
      <span className="headline">
        <FormattedMessage
          id="timeOfUse"
          defaultMessage="Time of Use"
          description="Time of Use"
        />
      </span>
    ),
    body: (
      <p>
        <FormattedMessage
          id="timeOfUseDescription"
          defaultMessage="The Time of Use pilot is for drivers who prefer to charge their vehicle at night and on weekends and can wait to run major appliances in the late evening or overnight. To enroll in the Time of Use rate, please call {link} and one of our customer care agents can help you."
          values={{
            link: (
              <a href={`tel:800-895-4999`}>
                <FormattedMessage
                  id="timeOfUsePhoneNumber"
                  defaultMessage="800-895-4999"
                  description="800-895-4999"
                />
              </a>
            ),
          }}
        />
      </p>
    ),
    image: EVTimeOfDaySeperateIcon,
  },
  SavePlus: ({ stateName } = { stateName: COLORADO }) => ({
    headLine: (
      <span className="headline">
        <FormattedMessage
          id="optimizeYourCharge"
          defaultMessage="Optimize Your Charge"
          description="Optimize Your Charge"
        />
      </span>
    ),
    body: (
      <p>
        <FormattedMessage
          id="optimizeYourChargeSubHeader"
          defaultMessage="Charge your vehicle during an off-peak charging schedule and earn a credit on your bill. Learn more {link}."
          description="Charge your vehicle during an off-peak charging schedule and earn a credit on your bill. Learn more here."
          values={{
            link: (
              <Link
                to={
                  stateName === NEW_MEXICO
                    ? "/optimize-your-charge-nm"
                    :
                  stateName === MINNESOTA 
                    ? "/optimize-your-charge-mn"
                    : "/optimize-your-charge"
                }
              >
                <FormattedMessage
                  id="here"
                  defaultMessage="here"
                  description="here"
                />
              </Link>
            ),
          }}
        />
      </p>
    ),
    image: EvSubscriptionServicePilotIcon,
  }),
};
const ChargingPrograms = ({ ip, uuid, props, chargingProgramToShow }) => {
  useEffect(() => {
    document.title = "EV Charging Programs";
  });

  const renderChargingPrograms = (arrayOfChargingPrograms) => {
    return arrayOfChargingPrograms.map((chargingProgram, i) => {
      let isSavePlus = chargingProgram.image.includes('EvSubscriptionServicePilotIcon');

      return (
        <div
          key={i}
          className="col-md-12 order-md-2"
          style={{ display: "flex" }}
        >
          <div className="chargingProgramContainer row d-flex">
              <img
                src={chargingProgram.image}
                alt="vehicle with station"
                style={{width: isSavePlus ? '151px' : '167px', height: '145px'}}
              />
            <div className={isSavePlus ? 'cardBody col-xs-9' : "cardBody col-xs-9 col-sm-10"} style={{padding: isSavePlus && '0px 30px'}}>
              <div className="cardTitle">{chargingProgram.headLine} </div>
              <div className="cardBodyText">{chargingProgram.body}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div id="ev-charging-programs">
      <div className="row my-5 d-flex">
        {renderChargingPrograms(chargingProgramToShow)}
      </div>
    </div>
  );
};
export default ChargingPrograms;
export { ChargingPrograms, EvChargingPrograms };

ChargingPrograms.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string,
  props: PropTypes.object,
  chargingProgramToShow: PropTypes.array,
};
