import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import level1ChargingIcon from "../../assets/images/Level_1_Charger.svg";
import level2ChargingIcon from "../../assets/images/Level_2_Charger.svg";
import level3ChargingIcon from "../../assets/images/Level_3_Charger.svg";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection"
import { FormattedMessage, useIntl } from 'react-intl';

import "./ChargingFaq.scss"

const ChargingFaq = ({ ip, uuid, props, userLocation }) => {
  const intl = useIntl() 

  useEffect(() => {
      document.title = intl.formatMessage({ id: "evChargingOneZeroOne", defaultMessage: "EV CHARGING 101"});
  });

  const chargerFaqCards = ([
    {
      icon: level1ChargingIcon,
      cardTitle: <FormattedMessage
        id="levelOneCharger"
        defaultMessage="Level 1 Charger"
        description="Level 1 Charger"
      />,
      rowTextArray: [
        <FormattedMessage
          id="levelOneChargerBasic"
          defaultMessage="Basic, slower home charging"
          description="Basic, slower home charging"
        />,
        <FormattedMessage
          id="levelOneChargerIncluded"
          defaultMessage="Included with every new plug-in EV"
          description="Included with every new plug-in EV"
        />,
        <FormattedMessage
          id="levelOneChargerHasCord"
          defaultMessage="Has a cord that plugs into a standard, 120-volt wall outlet"
          description="Has a cord that plugs into a standard, 120-volt wall outlet"
        />,
        <FormattedMessage
          id="levelOneChargerMayNotRequire"
          defaultMessage="May not require an electrician for wiring and installation"
          description="May not require an electrician for wiring and installation"
        />,
        <FormattedMessage
          id="levelOneChargerRange"
          defaultMessage="Can provide 4 - 6 miles of range for every hour of charging*"
          description="Can provide 4 - 6 miles of range for every hour of charging*"
        />
      ]
    },
    {
      icon: level2ChargingIcon,
      cardTitle: <FormattedMessage
        id="levelTwoCharger"
        defaultMessage="Level 2 Charger"
        description="Level 2 Charger"
      />,
      rowTextArray: [
        <FormattedMessage
          id="levelTwoChargerFast"
          defaultMessage="Convenient, faster home charging"
          description="Convenient, faster home charging"
        />,
        <FormattedMessage
          id="levelTwoChargerNotIncluded"
          defaultMessage="Not always included with EV, may need to be purchased separately"
          description="Not always included with EV, may need to be purchased separately"
        />,
        <FormattedMessage
          id="levelTwoChargerNeedsCircuit"
          defaultMessage="Needs a dedicated 240-volt circuit (like an electric clothes dryer)"
          description="Needs a dedicated 240-volt circuit (like an electric clothes dryer)"
        />,
        <FormattedMessage
          id="levelTwoChargerElectricianWiring"
          defaultMessage="An electrician is usually required for wiring and installation, and any necessary service panel upgrades"
          description="An electrician is usually required for wiring and installation, and any necessary service panel upgrades"
        />,
        <FormattedMessage
          id="levelTwoChargerProvide"
          defaultMessage="Can provide 25 - 40 miles of range for every hour of charging*"
          description="Can provide 25 - 40 miles of range for every hour of charging*"
        />,
      ]
    },
    {
      icon: level3ChargingIcon,
      cardTitle: <FormattedMessage
        id="levelThreeCharger"
        defaultMessage="Level 3 Charger"
        description="Level 3 Charger"
      />,
      rowTextArray: [
        <FormattedMessage
          id="levelThreeChargerFast"
          defaultMessage="Fast charging on the road"
          description="Fast charging on the road"
        />,
        <FormattedMessage
          id="levelThreeChargerPublic"
          defaultMessage="Used for public charging"
          description="Used for public charging"
        />,
        <FormattedMessage
          id="levelThreeChargerRequireVolts"
          defaultMessage="Typically requires 480-volt service"
          description="Typically requires 480-volt service"
        />,
        <FormattedMessage
          id="levelThreeChargerProvide"
          defaultMessage="Can provide up to 40 miles of range for every 10 minutes of charging*"
          description="Can provide up to 40 miles of range for every 10 minutes of charging*"
        />,
      ]
    }
  ]);

  const renderFaqCard = chargerFaqCards.map(({ icon, cardTitle, rowTextArray }, i) => {
    return (
      <div key={i} className="col-md-4 order-md-2" style={{ display: "flex" }}>
        <div className="faq-card">
          <img className="cardIcon" src={icon} alt="vehicle with station"></img>
          <div className="cardTitle">{cardTitle} </div>
          <div className="cardBody">
            <div className="cardBodyText">
              {rowTextArray.map((x, i) => <div className="cardBodyTextRow" key={i}>{x}</div>)}
            </div>
          </div>
        </div>
      </div>
    );
  })

  const collapsibleSection = ([
    {
      headLine: <FormattedMessage
        id="whereCanIChargeEV"
        defaultMessage="Where can I charge an EV?"
        description="Where can I charge an EV?"
      />,
      body: <p>
        <FormattedMessage
          id="whereCanIChargeEVAnswer"
          defaultMessage="If you have a garage or driveway, you can charge your electric car at home. More and more charging can be found while you’re on the go. Look for public charging at grocery stores, hotels, parking ramps, parks and more."
          description="If you have a garage or driveway, you can charge your electric car at home. More and more charging can be found while you’re on the go. Look for public charging at grocery stores, hotels, parking ramps, parks and more."
          values={{
            link: <Link to="/charging-stations"><FormattedMessage
              id="lookForPublicCharging"
              defaultMessage="Look for public charging at grocery stores, hotels, parking ramps, parks and more."
              description="Look for public charging at grocery stores, hotels, parking ramps, parks and more."
            /></Link>
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="whatChargingEquipment"
        defaultMessage="What charging equipment do I need?"
        description="What charging equipment do I need?"
      />,
      body: <p>
        <FormattedMessage
          id="whatChargingEquipmentAnswer"
          defaultMessage="Every new EV comes with a 120-volt charger, called a Level 1 charger, that you can plug into a standard outlet and start charging right away. Want faster home charging? Purchase a Level 2 charger that uses a 240-volt circuit which may require an electrician to install."
          description="Every new EV comes with a 120-volt charger, called a Level 1 charger, that you can plug into a standard outlet and start charging right away. Want faster home charging? Purchase a Level 2 charger that uses a 240-volt circuit which may require an electrician to install."
          values={{
            lineBreak: <br />
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="levelTwoChargerInstallCost"
        defaultMessage="How much does it cost to have a Level 2 charger installed?"
        description="How much does it cost to have a Level 2 charger installed?"
      />,
      body: <p>
        <FormattedMessage
          id="levelTwoChargerInstallCostAnswer"
          defaultMessage="Costs depend on your home’s unique electrical setup and wiring. Circuit wiring and panel upgrades may be required to add a new, dedicated 240-volt outlet."
          description="Costs depend on your home’s unique electrical setup and wiring. Circuit wiring and panel upgrades may be required to add a new, dedicated 240-volt outlet."
          values={{
            link: <a
              href="/home-charging-advisor"
            >
              <FormattedMessage
                id="homeUniqueWiring"
                defaultMessage="home’s unique electrical setup and wiring"
                description="home’s unique electrical setup and wiring"
              />
            </a>
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="canChargeRaining"
        defaultMessage="Can I charge an EV when it’s raining?"
        description="Can I charge an EV when it’s raining?"
      />,
      body: <p>
        <FormattedMessage
          id="canChargeRainingAnswer"
          defaultMessage="Yes, EV charging equipment is designed to be safe for use in all weather conditions."
          description="Yes, EV charging equipment is designed to be safe for use in all weather conditions."
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="costToChargeEV"
        defaultMessage="How much does it cost to charge an EV?"
        description="How much does it cost to charge an EV?"
      />,
      body: <p>
        <FormattedMessage
          id="costToChargeEVAnswer"
          defaultMessage="With our low, off-peak prices, driving electric equates to spending about $1 per gallon of gasoline. Explore EV charging programs and rate options."
          description="With our low, off-peak prices, driving electric equates to spending about $1 per gallon of gasoline. Explore EV charging programs and rate options."
          values={{
            lineBreak: <br />,
            link: <Link to="/home-charging-advisor"><FormattedMessage
              id="exploreChargingPrograms"
              defaultMessage="Explore EV charging programs and rate options"
              description="Explore EV charging programs and rate options"
            /></Link>
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="canIChargeWithRenewableEnergy"
        defaultMessage="Can I charge my EV with renewable energy?"
        description="Can I charge my EV with renewable energy?"
      />,
      body: <p>
        <FormattedMessage
          id="canIChargeWithRenewableEnergyOneOOneAnswer"
          defaultMessage="As an Xcel Energy customer, you’re already charging your EV with renewable energy within our energy mix. Additional solar power, wind energy, and other sources all add up to create a diverse fuel mix to meet your energy needs. Want to go further? Explore your renewable program choices to charge with up to 100% renewable energy."
          description="As an Xcel Energy customer, you’re already charging your EV with renewable energy within our energy mix. Additional solar power, wind energy, and other sources all add up to create a diverse fuel mix to meet your energy needs. Want to go further? Explore your renewable program choices to charge with up to 100% renewable energy."
          values={{
            lineBreak: <br />,
            link: <a
              href="https://www.xcelenergy.com/programs_and_rebates/residential_programs_and_rebates/renewable_energy_options_residential"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="exploreRenewableChoices"
                defaultMessage="Explore your renewable program choices to charge with up to 100% renewable energy."
                description="Explore your renewable program choices to charge with up to 100% renewable energy."
              />
            </a>
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="howToChargeMultifamilyBuilding"
        defaultMessage="How can I add charging for my multifamily building?"
        description="How can I add charging for my multifamily building?"
      />,
      body: <p>
        <FormattedMessage
          id="howToChargeMultifamilyBuildingAnswer"
          defaultMessage="We can help! We are here to guide you through the steps of offering EV charging on your property."
          description="We can help! We are here to guide you through the steps of offering EV charging on your property."
          values={{
            link: <a
              href="https://www.xcelenergy.com/programs_and_rebates/residential_programs_and_rebates/electric_vehicles/ev_charging_for_building_owners"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="guideEVChargingProperty"
                defaultMessage="We are here to guide you through the steps of offering EV charging on your property"
                description="We are here to guide you through the steps of offering EV charging on your property"
              />
            </a>
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="evs101.howElectrifyFleet"
        defaultMessage="How do I electrify my fleet?"
        description="How do I electrify my fleet?"
      />,
      body: <p>
        <FormattedMessage
          id="howElectrifyFleetAnswer"
          defaultMessage="We can help! Find more about our EV programs and services for fleets."
          description="We can help! Find more about our EV programs and services for fleets."
          values={{
            link: <a
              href="https://mn.my.xcelenergy.com/s/business/ev/fleet"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="findOutMoreFleets"
                defaultMessage="Find more about our EV programs and services for fleets."
                description="Find more about our EV programs and services for fleets."
              />
            </a>
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="addChargingCommunityBusiness"
        defaultMessage="How can I add charging for my community / business?"
        description="How can I add charging for my community / business?"
      />,
      body: <p>
        <FormattedMessage
          id="addChargingCommunityBusinessAnswer"
          defaultMessage="We can help! Find more about our EV programs and services for public charging."
          description="We can help! Find more about our EV programs and services for public charging."
          values={{
            link: <a
              href="https://mn.my.xcelenergy.com/s/business/ev/fleet"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="findOutPublicCharging"
                defaultMessage="Find more about our EV programs and services for public charging."
                description="Find more about our EV programs and services for public charging."
              />
            </a>
          }}
        />
      </p>
    }
  ]);

     const filteredCollapsibleSection = useMemo(() => {
       if (userLocation && userLocation.region === 'Minnesota') {
         return collapsibleSection.filter((item, index) => {
           return index !== 6 && index !== 7 && index !== 8 && index !== 9;
         });
       } else {
         return collapsibleSection;
       }
     }, [userLocation, collapsibleSection]);

  return (
    <section id="charging101" className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="pageHeaderContainer offset-sm-2 col-sm-8 text-center">
              <div className="pageHeaderLead"><FormattedMessage
                id="evChargingOneZeroOne"
                defaultMessage="EV CHARGING 101"
                description="EV CHARGING 101"
              /></div>
              <div className="pageHeaderHeadLine">
                <FormattedMessage
                  id="evChargingOneZeroOneSubHeader"
                  defaultMessage="Charge at home or on the go."
                  description="Charge at home or on the go."
                />
              </div>
              <div className="pageHeaderBiLine">
                <p>
                  <FormattedMessage
                    id="evChargingOneZeroOneSubSubHeader"
                    defaultMessage="Plugging in to power your electric vehicle is as simple as it sounds. We can help you make sure you're ready to fuel at home or on the road."
                    description="Plugging in to power your electric vehicle is as simple as it sounds. We can help you make sure you're ready to fuel at home or on the road."
                  />
                </p>
              </div>
            </div>
            <div className="row d-flex">
              {renderFaqCard}
            </div>
            <div className="row my-4 d-flex">
              <div className="offset-sm-2 col-sm-8 text-center">
                <div>
                  <FormattedMessage
                    id="evChargingOneZeroOneDisclaimer"
                    defaultMessage="*Range depends on vehicle, speed, cargo weight, and other factors."
                    description="*Range depends on vehicle, speed, cargo weight, and other factors."
                  />
                </div>
              </div>
            </div>
            <div id="faqContainer">
              <div className="row my-5 d-flex" >
                <div className={`col-md-6 offset-md-3 faq`}>
                  <FormattedMessage
                    id="frequentlyAskedQuestions"
                    defaultMessage="FREQUENTLY ASKED QUESTIONS"
                    description="FREQUENTLY ASKED QUESTIONS"
                  /></div>
              </div>
              {filteredCollapsibleSection && filteredCollapsibleSection.map((content, index) => {
                     return (
                     <CollapsibleSection key={index} headLine={content.headLine}>
                     {content.body}
                     </CollapsibleSection>
                     );
              })}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ChargingFaq;

ChargingFaq.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string,
  props: PropTypes.object
};