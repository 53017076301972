import React, { useContext } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import { FormattedMessage } from 'react-intl';
import {
  GAS_ENERGY_COST_ID,
  EV_ENERGY_COST_ID,
  HOME_ENERGY_COST_ID
} from '../../../constants/';

const EnergyCostTypeCheckboxes = () => {
  const userPrefs = useContext(UserPrefsContext);
  const energyCostTypes = [
    {
      id: GAS_ENERGY_COST_ID,
      title: <FormattedMessage
        id="gasoline"
        defaultMessage="Gasoline"
        description="Gasoline"
      />,
    },
    {
      id: EV_ENERGY_COST_ID,
      title: <FormattedMessage
      id="electricity"
      defaultMessage="Electricity"
      description="Electricity"
    />,
    },
    {
      id: HOME_ENERGY_COST_ID,
      title: <FormattedMessage
      id="home"
      defaultMessage="Home"
      description="Home"
    />
    },
  ];
  return (
    <div>
      <div className="mb-2 mt-4">
        <label>
          <FormattedMessage
            id="hcaIncludedEnergyCosts"
            defaultMessage="INCLUDED ENERGY COSTS"
            description="INCLUDED ENERGY COSTS"
          />
        </label>
      </div>
      <div className="row no-gutters">
        {energyCostTypes.map(costType => {
          const isSelected = userPrefs.get('selectedEnergyCostIds').includes(costType.id);
          return (
            <div className="col-sm-4 px-1 mb-2" key={costType.id}>
              <button
                key={costType.id}
                className={`btn btn-default btn-sm btn-block ${isSelected ? 'active' : ''}`}
                type="button"
                onClick={() => {
                  const current = userPrefs.get('selectedEnergyCostIds');
                  const newSelectedEnergyCostIds = current.includes(costType.id) ?
                    current.filter(id => id !== costType.id) :
                    current.concat([costType.id]);

                  userPrefs.set({ selectedEnergyCostIds: newSelectedEnergyCostIds });
                }}
                aria-pressed={userPrefs.get('selectedEnergyCostIds').includes(costType.id)}
              >
                {costType.title}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EnergyCostTypeCheckboxes;
