import React from "react";
import PropTypes from "prop-types";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import { getFAQs } from "./getFAQs";
import "./FrequentlyAskedQuestionsSection.scss";

const FaqSection = ({ content }) => (
  <>
    {content.header ? <div className="header-container">{content.header}</div> : null}
    <CollapsibleSection id={content.id} headLine={content.headLine}>
      {content.body}
    </CollapsibleSection>
  </>
);

const FrequentlyAskedQuestionsSection = ({ applicableTo, region, sort }) => {

  return (
    <div id="faqContainer">
      {sort 
      ? getFAQs(applicableTo, region).sort((a,b) => sort.indexOf(a.id) - sort.indexOf(b.id)).map((content, index) => (
        <> 
        <FaqSection
          content={content}
          key={`${index}-${content.applicableTo.join("-")}`}
        />
        </>
      )) 
      : getFAQs(applicableTo, region).map((content, index) => (
        <> 
        <FaqSection
          content={content}
          key={`${index}-${content.applicableTo.join("-")}`}
        />
        </>
      ))}
    </div>
  );
};

export default FrequentlyAskedQuestionsSection;

FrequentlyAskedQuestionsSection.propTypes = {
  applicableTo: PropTypes.string,
};
