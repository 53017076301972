import React from "react";
import { FormattedMessage } from "react-intl";
import "./AboutChargingPerks.scss";
import threePeople from "../../../client_customizations/assets/images/icons/threePeople.svg";
import clock from "../../../client_customizations/assets/images/icons/Clock.svg";
import customerChoice from "../../../client_customizations/assets/images/icons/CustomerChoice.svg";
import carbonReduction from "../../../client_customizations/assets/images/icons/CarbonReduction.svg";

const AboutChargingPerks = () => {
     return (
          <>
               <p>
                    <FormattedMessage
                         id="perksText"
                         defaultMessage="Charging Perks helps EV drivers charge up during times that help the energy grid operate more efficiently and use more renewable energy."
                         description="Charging Perks helps EV drivers charge up during times that help the energy grid operate more efficiently and use more renewable energy."
                    />
               </p>

               <p>
                    <FormattedMessage
                         id="perksText2"
                         defaultMessage="You can rest assured that your EV is charging at the best time for the energy grid, and your vehicle is ready to go when you need it."
                         description="You can rest assured that your EV is charging at the best time for the energy grid, and your vehicle is ready to go when you need it."
                    />
               </p>
               <div className="charging_perks_container">
                    <h3 style={{ fontSize: "26px" }}>
                         <FormattedMessage
                              id="chargingOptionsPerksImpact_title"
                              defaultMessage="CHARGING PERKS IMPACT FOR 2022-2023"
                              description="CHARGING PERKS IMPACT FOR 2022-2023"
                         />
                    </h3>

                    <div className="charging_perks_container_inner">
                         <div className="charging_perk">
                              <img
                                   src={threePeople}
                                   alt="Three people icon"
                                   style={{ maxWidth: "65px", marginBottom: "20px" }}
                              />
                              <h2>1,400</h2>
                              <p>Charging Perks pilot participants</p>
                         </div>
                         <div className="charging_perk">
                              <img src={clock} alt="Clock icon" style={{ maxWidth: "65px", marginBottom: "20px" }} />
                              <h2>0.40 - 0.49 kW</h2>
                              <p>Average capacity reduction per EV (for some on-peak hours)</p>
                         </div>
                         <div className="charging_perk">
                              <img
                                   src={carbonReduction}
                                   alt="Carbon reduction icon"
                                   style={{ maxWidth: "65px", marginBottom: "20px" }}
                              />
                              <h2>0.20 to 0.245 kg</h2>
                              <p>
                                   C0<sub>2</sub> emissions savings per EV (for scheduled charging)
                              </p>
                         </div>
                         <div className="charging_perk">
                              <img
                                   src={customerChoice}
                                   alt="Customer Choice icon"
                                   style={{ maxWidth: "65px", marginBottom: "20px" }}
                              />
                              <h2>0.41 to 0.55 kW</h2>
                              <p>Increased renewable consumption per EV (for some off-peak hours)</p>
                         </div>
                    </div>
               </div>
          </>
     );
};

export default AboutChargingPerks;
