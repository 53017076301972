import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import unique from "lodash/uniq";
import { FormattedMessage, useIntl } from "react-intl";

import Select from "../shared/InputElements/Select";

const SelectVehicleModelFilter = ({
  id = "select-vehicle-distance-filter",
  vehicles,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();

  let anyDistanceLabel =
    intl && intl.formatMessage
      ? intl.formatMessage({
          id: "inventory-filter.any-distance",
          defaultMessage: "Any Distance",
        })
      : "";

  let distanceOptions = [null, 10, 50, 250];
  let distanceLabels =
    intl && intl.formatMessage
      ? unique(
          (distanceOptions || []).map((d) =>
            d == null
              ? anyDistanceLabel
              : intl.formatMessage(
                  {
                    id: "inventory-filter.distance-option",
                    defaultMessage: "Within {distance} Miles",
                  },
                  {
                    distance: d,
                  }
                )
          )
        )
      : [];

  const label = (
    <FormattedMessage
      id="inventory-filter.distance-label"
      defaultMessage="Distance"
      description="Distance"
    />
  );

  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleDistanceFilter")}
      label={label}
      optionNames={distanceLabels}
      optionValues={distanceOptions}
      handler={(e) => {
        if (e.target.value === anyDistanceLabel) {
          userPrefs.set({ vehicleDistanceFilter: null });
        } else {
          userPrefs.set({ vehicleDistanceFilter: e.target.value });
        }
      }}
    />
  );
};

export default SelectVehicleModelFilter;

SelectVehicleModelFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
