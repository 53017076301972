import React from "react";
import { FormattedMessage } from "react-intl";
import ContentCenter from "../../client_customizations/components/PageLayout/BodyTemplates/ContentCenter";
import FrequentlyAskedQuestionsSection from "../../client_customizations/components/FrequentlyAskedQuestionsSection/FrequentlyAskedQuestionsSection";
import HomeWiringRebateCo from "../../client_customizations/components/HomeWiringRebateCo/HomeWiringRebateCo";
import { NEW_MEXICO } from "../../constants";

export const Subheader = ({ stateName }) => {
  switch (stateName) {
    case NEW_MEXICO:
      return (
        <FormattedMessage
          id="homeWiringRebateHeadlineDetails-nm"
          defaultMessage="Charging your electric vehicle (EV) with a Level 2 charger is a fast, convenient home charging option. Our rebate can help reduce the costs of updating your home’s wiring so you can charge at home. Our rebate can also offset the cost of a Level 2 charger. Eligible customers can save up to $500. Income-Qualified customers can qualify for a $2,500 rebate."
          description="Charging your electric vehicle (EV) with a Level 2 charger is a fast, convenient home charging option. Our rebate can help reduce the costs of updating your home’s wiring so you can charge at home. Our rebate can also offset the cost of a Level 2 charger. Eligible customers can save up to $500. Income-Qualified customers can qualify for a $2,500 rebate."
          values={{
            lineBreak: <br />,
          }}
        />
      );
    default:
      return (
        <FormattedMessage
          id="homeWiringRebateHeadlineDetails"
          defaultMessage="Charging your electric vehicle (EV) with a Level 2 charger is a fast, convenient home charging option. Our rebate can help reduce the costs of updating your home’s wiring so you can charge at home. Our rebate can also offset the cost of a Level 2 charger. Eligible customers can save up to $500. Income-Qualified customers can qualify for a $1,300 rebate."
          description="homeWiringRebateHeadlineDetails"
          values={{
            lineBreak: <br />,
          }}
        />
      );
  }
};

export const HomeWiringRebate = ({ stateName, faqKey }) => {
  return (
    <>
      <ContentCenter
        pageHeaderLead={
          <FormattedMessage
            id="homeWiringRebate"
            defaultMessage="EV CHARGER AND WIRING REBATE"
            description="EV CHARGER AND WIRING REBATE"
          />
        }
        pageHeaderHeadLine={
          <FormattedMessage
            id="homeWiringRebateHeadline"
            defaultMessage="Reduce the Costs of Purchasing and Installing a Charger"
            description="Reduce the Costs of Purchasing and Installing a Charger"
          />
        }
        pageHeaderSubHeading={<Subheader stateName={stateName} />}
        sectionStyle={{ backgroundColor: "#F6F8FB" }}
      >
        <HomeWiringRebateCo stateName={stateName} />
      </ContentCenter>
      <ContentCenter
        pageHeaderLead={
          <FormattedMessage
            id="frequentlyAskedQuestions"
            defaultMessage="FREQUENTLY ASKED QUESTIONS"
            description="FREQUENTLY ASKED QUESTIONS"
          />
        }
        sectionStyle={{ backgroundColor: "#FFFFFF" }}
      >
        <FrequentlyAskedQuestionsSection applicableTo={faqKey} region={stateName} />
      </ContentCenter>
    </>
  );
};
