export const loadInsuranceData = async (vehicle_handle, userPrefs, setInsuranceData, setIsLoading) => {
       const params = {
         postcode: userPrefs.get("zipcode"),
       };
       let url = new URL(
         `${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles/${vehicle_handle}/insurance`
       );
       let searchParams = new URLSearchParams(params);
       url.search = searchParams;
       try {
         const response = await fetch(url, {
           method: "GET",
           headers: {
             "Content-Type": "application/json",
             Accept: "application/json",
             Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
           },
         });
         if (response.status === 200) {
           const insuranceData = await response.json();
           setInsuranceData(insuranceData.yearly_insurances);
           setIsLoading(false);
         }
       } catch (error) {
         console.log(error);
         setIsLoading(false);
       }
};