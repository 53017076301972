import React from "react";
import PropTypes from "prop-types";

const VideoReview = props => {
  let video = props || {};

  return (
    <div className="col-sm-6 VideoReview">
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          className="embed-responsive-item"
          src={video.link}
          title={video.title}
          allowFullScreen
        />
      </div>
      <p>{video.title}</p>
    </div>
  );
};

export default VideoReview;

VideoReview.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string
};
