const CHARGING_SPEEDS_IN_KW = {
  no_charger: 0,
  level_1: 1.5,
  level_2: 7.7,
  dc_fast_charger: 50
};

const calcCharging = {
  speedInKw: function(chargerLevel) {
    return chargerLevel ? CHARGING_SPEEDS_IN_KW[chargerLevel] : 0;
  },

  timeForFullBattery: function(
    batteryCapacityInKwh,
    maxCurrentIntake,
    chargerLevel
  ) {
    if (!batteryCapacityInKwh) return 0;
    const chargeSpeed =
      chargerLevel === "level_2"
        ? Math.min(maxCurrentIntake, this.speedInKw(chargerLevel))
        : this.speedInKw(chargerLevel);

    return batteryCapacityInKwh / chargeSpeed;
  },
  timeForDailyCommute: function(milesDrivenDaily, kWhPer100Mi, chargerLevel) {
    if (!milesDrivenDaily) return 0;
    const chargeSpeed = this.speedInKw(chargerLevel);

    return (kWhPer100Mi * milesDrivenDaily) / (100 * chargeSpeed);
  },
  milesPerHalfHourOfCharge: function(kWhPer100Mi, chargerLevel) {
    if (!kWhPer100Mi) return 0;
    const chargeSpeed = this.speedInKw(chargerLevel);

    const milesPerHourOfCharge = chargeSpeed / (kWhPer100Mi / 100);

    return milesPerHourOfCharge / 2;
  }
};

export default calcCharging;
