export default {
     electricVehicles: "Autos Eléctricos",
     usedElectricVehicles: "Autos Eléctricos Usados",
     compareVehicles: "Comparar Vehículos",
     incentives: "Incentivos",
     chargingStations: "Estaciones de Carga",
     homeChargers: "Cargadores Caseros",
     dealers: "Distribuidores",
     newVehicles: "Autos Nuevos",
     electricians: "Electricistas",
     homeChargingAdvisor: "Consejero de Carga en el Hogar",

     newMexico: "Nuevo Mexico",
     noResults: "No se han encontrado resultados",

     "homeChargingPrograms.subheader":
          "Más del 80 % de la carga de vehículos eléctricos se realiza en casa. Estamos desarrollando soluciones para ahorrarle dinero y facilitar la carga de su EV en casa: envíenos un correo electrónico en {mailTo} lo mantendremos informado cuando haya nuevos programas disponibles en New Mexico",

     MNTimeofDaySeparateMeter_additionalinformation_v2: "Información Adicional",
     "disclaimer.electricRateDollarsKwH.minnesota":
          "La tarifa de electricidad que se muestra es un ejemplo de un conductor de EV en Minnesota con la tarifa de tiempo de uso de EV, cargando fuera de las horas pico, incluidos los cargos por servicio y los ajustes. Esta tarifa está disponible para clientes inscritos en EV Accelerate At Home.",
     "disclaimer.electricRateDollarsKwH.newMexico":
          "La tarifa de electricidad que se muestra es un ejemplo de un conductor de EV en Nuevo México que carga durante el verano en el plan de tarifa residencial regular.",
     "disclaimer.electricRateDollarsKwH.colorado":
          "La tarifa de electricidad que se muestra es un ejemplo de un conductor de EV en Colorado con la tarifa de tiempo de uso, cargando fuera de las horas pico, incluidos los cargos por servicio, pasajeros y ajustes.",
     "disclaimer.chargingMap":
          "Datos cortesía del Centro de Datos de Combustibles Alternativos. Es posible que el mapa no refleje la disponibilidad más reciente de las estaciones de carga.",

     electricity: "Fuente",
     gasoline: "Gasolina",
     total: "Total",
     description: "Descripción",
     detailedCalculations: "detalles del cálculo",
     charging: "Carga",
     emissions: "Emisiones",
     source: "Fuente",
     vehicles: "Vehículos",
     make: "Marca",
     model: "Modelo",
     all: "Todos",
     age: "Años",
     zipcode: "Código Postal",

     personalizeIncentives: "Personalizar Incentivos",

     "vehicle.miles": "Millas",
     "vehicle.trees": "Árboles",
     "vehicle.view": "Ver",
     "vehicle.more": "más",
     "vehicle.fewer": "menos",
     "vehicle.greater": "más",

     "vehicle.plugInHybrid": "Híbrido Eléctrico Enchufable",
     "vehicle.allElectric": "Eléctricos",
     "vehicle.gas": "Gasolina",
     "vehicle.afterIncentives": "Después de incentivos",
     "vehicle.msrp": "MSRP",
     "vehicle.estimatedIncentives": "Insentivos estimados",
     "vehicle.type": "Tipo",
     "vehicle.fuelType": "Tipo de combustible",
     "vehicle.batterySize": "Tamaño de la batería",
     "vehicle.electricRange": "Rango eléctrico",
     "vehicle.timeToChargeLvlTwo": "Tiempo de carga - Nivel 2",
     "vehicle.milesPerThirtyFastCharge": "Millas por 30 minutos de carga rápida",
     "vehicle.coTwoEmissions": "Reducción de emisiones de CO2",
     "vehicle.phev": "Electricidad y gasolina ",
     "vehicle.bev": "Electricidad",
     "vehicle.age.one": "0-1 Años",
     "vehicle.age.two": "2-3 Años",
     "vehicle.age.four": "4+ Años",

     "vehicle.type.sedan": "Sedan",
     "vehicle.type.hatchback": "Cinco puertas",
     "vehicle.type.coupe": "Cupé",
     "vehicle.type.crossover": "Híbrido",
     "vehicle.type.minivan": "Minivan",
     "vehicle.type.suv": "SUV",
     "vehicle.type.wagon": "Camioneta",
     "vehicle.type.truck": "Camión",
     "footer.allRights": "Todos los derechos reservados.",
     "footer.poweredBy": "Patrocinado por Zappyride",

     "homepage.welcome": "Colton Recharged",
     "homepage.welcomeSub":
          "Su guía para autos eléctricos, diseñada por Colton Electric Utility. Obtenga un estimado y compare costos, opciones de ahorro, incentivos y mucho más para VE.",

     "homepage.linkCard.browseElectricVehicles": "VEHICULOS {lineBreak} ELÉCTRICOS",
     "homepage.linkCard.discoverIncentives": "DESCUBRA {lineBreak} INCENTIVOS",
     "homepage.linkCard.locateChargingStations": "ENCUENTRE ESTACIONES {lineBreak} DE CARGA",
     "homepage.linkCard.homeChargers": "CARGADORES PARA {lineBreak} EL HOGAR",

     "homepage.vehicleCarousel.title":
          "Existen {vehicleCount} vehículos eléctricos disponibles a escala nacional. Descubra el suyo.",
     "homepage.vehicleCarousel.subTitle":
          "Elija un VE y compare el costo con un vehículo de gasolina similar. Los VE’s le permiten ahorrar dinero porque {lineBreak} son más económicos y baratos de poseer y mantener a comparación de un competidor de 100% gasolina.",
     "homepage.vehicleCarousel.findEVButton": "Encuentre su vehículo eléctrico",

     "graph.assumptions": "Supuestos",
     "graph.yourEV": "VE seleccionado",
     "graph.similarGasVehicle": "Vehículo comparable a gasolina ",
     "graph.graphValues": "Valores Graficados",
     "graph.show": "Mostrar",
     "graph.hide": "Ocultar",
     "graph.moreExpensive": "mas caro",
     "graph.cheaper": "mas barato",
     "graph.toOwnOver": "de tener por ",
     "graph.years": " Años",
     "graph.year": " Año",

     "graph.costOfOwnership.title": "Costo de Propiedad",
     "graph.costOfOwnership.subTitle": "El {carName} es {style} {costDeltaText} {costDeltaTextEnding}",
     "graph.costOfOwnership.subTitleThreeCars": "El {carName} es el más barato de tener por {yearsOfOwnership} años",
     "graph.costOfOwnership.descriptionRow.vehicle": "Vehículo",
     "graph.costOfOwnership.descriptionRow.maintenance": "Mantenimiento",
     "graph.costOfOwnership.descriptionRow.insurance": "Seguro",
     "graph.costOfOwnership.descriptionRow.electricity": "Electricidad",
     "graph.costOfOwnership.descriptionRow.gasoline": "Gasolina",
     "graph.costOfOwnership.totalRow": "Total",
     "graph.costOfOwnership.description": "Descripción",

     "graph.costOfOwnership.chart.vehicle": "Incentivos para vehículo, Reventa.",
     "graph.costOfOwnership.chart.electricity": "Electricidad",
     "graph.costOfOwnership.chart.gasoline": "Gasolina",
     "graph.costOfOwnership.chart.maintenance": "Mantenimiento",
     "graph.costOfOwnership.chart.insurance": "Seguro",

     "graph.title.costAnalysis": "Análisis de Costos",
     "graph.title.speedOfCharge": "Velocidad de Carga",
     "graph.title.electricRange": "Rango Eléctrico",
     "graph.monthlyCostToFill.title": "Costo de Llenar el Tanque Mensualmente",

     "graph.speedOfCharge.levelTwoChargingSpeed": "Velocidad de carga Nivel 2",
     "graph.speedOfCharge.fastChargingSpeed": "Velocidad de carga rápida",
     "graph.speedOfCharge.mphLevelTwoCharger": "millas por hora al usar un cargador nivel 2 ",
     "graph.speedOfCharge.thirtyFastCharge": "millas por cada 30 minutos de carga rápida",
     "graph.speedOfCharge.subTitle": "El {car} tendrá {number} {costDeltaText} {subTitleText}",
     "graph.speedOfCharge.mphLevelTwoChargerThreeCars":
          "será el que gane más rango de carga tras una hora de uso de un cargador nivel 2",
     "graph.speedOfCharge.thirtyFastChargeThreeCars":
          "será el que gane más rango de carga después de 30 minutos de carga rápida",
     "graph.speedOfCharge.subTitleThreeCars": "El {car} {subTitleText}",
     "graph.speedOfCharge.mphLevelTwoChargerTitle": "Porcentaje de carga por hora en Nivel 2",
     "graph.speedOfCharge.fastChargerTitle": "Cantidad de millas por 30 minutos de carga rápida.",
     "graph.electricRange.subTitleThreeCars": "El {car} tiene el rango eléctrico más amplio de todos",
     "graph.electricRange.subTitle": "El rango eléctrico del {car} tiene {number} millas { costDeltaText} ",
     "graph.electricRange.gasolineRange": "Rango de gasolina",
     "graph.electricRange.assumption": "Rango basado en estimaciones de la EPA",
     "graph.monthlyCostToFill.subTitle": "El {carName} es {style} {costDeltaText} de tanquear mensualmente",
     "graph.monthlyCostToFill.subTitleThreeCars": "El {carName} el más barato de tanquear mensualmente",

     "homepage.incentives.title": "Descubra incentivos y créditos fiscales para VE ",
     "homepage.incentives.subTitle":
          "Descubra que tanto puede ahorrar con un VE, ya sea por compra o alquiler. {lineBreak} Los incentivos son personalizados según su lugar de residencia.",
     "homepage.incentives.exporeIncentives": "DESCUBRA INCENTIVOS",

     "compareVehicles.subTitle": "Ver todos los vehículos >",
     "compareVehicles.selectFuel": "Seleccione el Tipo de Combustible ",
     "compareVehicles.selectMake": "Seleccione la marca",
     "compareVehicles.selectModel": "Seleccione el modelo",
     "compareVehicles.pickTwo": "Elíja al menos 2 vehículos para hacer la comparación",
     "compareVehicles.disclaimer": "No todas las marcas / modelos están disponibles.",

     "evs.welcomeSub":
          "Compara autos eléctricos por su rango, precio, o por características que más se acomoden a tu gusto. {lineBreak} Haz click sobre el VE para obtener detalles como el costo total comparado con vehículos de gasolina.",
     "evs.buttonMatchScoreAndFilters": "Puntaje personalizado y Filtros",
     "evs.matchScoreAndFilters": "Asignar puntaje personalizado y Filtros",
     "evs.matchScoreOptions": "Asignar Puntaje Personalizado",
     "evs.roundTripCommute": "Viaje de ida y vuelta",
     "evs.budgetAfterIncentives": "Presupuesto después de incentivos",
     "evs.minSeats": "Cantidad mínima de asientos",
     "evs.seats": "Asientos",
     "evs.homeChargingAvailability": "Disponibilidad de carga en el hogar",
     "evs.homeChargingAvailabilityTooltip":
          "Más autos eléctricos serán mejores para ti si tienes la posibilidad de cargarlos rapidamente en casa.",
     "evs.noCharging": "Sin carga",
     "evs.levelOne": "Nivel 1",
     "evs.levelTwo": "Nivel 2",
     "evs.helpMeChoose": "Ayúdame a elegir",
     "evs.chargingAvailability": "Disponibilidad de carga",
     "evs.disclaimer":
          "Es posible que los vehículos mostrados en esta página no reflejen la disponibilidad real. {company} no respalda ni recomienda ningún modelo o fabricante de vehiculos específico. ",
     "chargingWizard.carOvernight": "¿En dónde parquear tu Auto en la noche?",
     "chargingWizard.parkingGarage": "Garaje privado o estacionamiento",
     "chargingWizard.onTheStreet": "En la calle",
     "chargingWizard.publicParking": "Estacionamiento público",
     "chargingWizard.electricianInstall": "¿Puede un electricista instalarme una estación de carga?",
     "chargingWizard.overnightParking": "¿Podrías estacionar tu auto en un lugar que cuenta con estación de carga?",
     "chargingWizard.powerOutlet": "¿Existe algún tomacorriente cerca al lugar en donde estacionas tu auto?",
     "chargingWizard.chargingAvailability": " Disponibilidad de carga",
     "chargingWizard.noChargingAvailability": "No disponibilidad de carga",
     "chargingWizard.youWouldHave": "Tendrás {resultText} ",
     "chargingWizard.useThisValue": "Usar este valor",

     evfilter: "Filtro",
     "evfilter.fuel": "Combustible",
     "evfilter.fuelTooltip":
          "Los vehículos eléctricos solo usan electricidad. Los vehículos híbridos pueden funcionar con electricidad y gasolina ",
     "evfilter.type": "Tipo",

     egbBringCharger: "Usaré mi propio cargador de nivel 2",

     yes: "Si",
     no: "No",

     "evCard.electricRange": "Rango eléctrico",
     "evCard.totalRange": "Rango total",
     "evCard.matchScore": "Puntaje personalizado",
     "evCard.seeDetails": "Detalles",
     "evCard.seeElectricVehicle": "Ver Vehículo Eléctrico",

     "evSort.title": "Ordenar por",
     "evSort.matchScore": "Puntaje personalizado",
     "evSort.electricRange": "Rango eléctrico",
     "evSort.priceLtH": "Precio: Menor a mayor",
     "evSort.priceHtL": "Precio: Mayor a menor",
     "evSort.alphabetical": "Orden alfabético",

     "pricePanels.cash": "Efectivo",
     "pricePanels.loan": "Crédito",
     "pricePanels.lease": "Arrendamiento",
     "pricePanels.loanPayment": "Pago del préstamo",
     "pricePanels.downPayment": "Cuota inicial",
     "pricePanels.leasePayment": "Pago de arriendo",
     "pricePanels.perMonth": " / Mes",
     "pricePanels.downPaymentDescription": "10% de MSRP más impuestos",
     "pricePanels.firstLeasePayment": "Primera cuota de la renta",
     "pricePanels.incentivesForLease": "Incentivos para rentar",
     "pricePanels.seeAllIncentives": "Ver todos los incentivos",
     "pricePanels.seeAllCosts": "Ver todos los costos",

     "ev.jumbotron.viewAllCars": "Ver todos los autos",
     "ev.carDetails.fastChargingTooltip": "Velocidad de carga rápida con DC:",
     "ev.carDetails.levelTwoChargerSpeedTooltip": "Velocidad de carga Nivel 2:",
     "ev.carDetails.batterySizeTooltip":
          "Un Kilovatio por hora (kWh) provee suficiente energía para conducir de 1 a 4 millas dependiendo del vehículo. Una batería común para computadora puede contener menos de 1 kWh",
     "ev.carDetails.treesPlanted": "Árboles plantados",
     "ev.carDetails.gasolineSaved": "Gasolina ahorrada",
     "ev.carDetails.compareText":
          "Seleccionamos el {gasVehicle} por su proximidad con el {vehicle} con base en la marca, tamaño y precio.",
     "ev.cardetails.calc.dcRate": "Cargador Rápido (DCFC)",
     "ev.cardetails.calc.EPA": "Calificación de eficiencia EPA",
     "ev.cardetails.calc.chargingRate": "Velocidad de carga",
     "ev.cardetails.calc.vehicleBatteryCapacity": "Capacidad de la batería",
     "ev.cardetails.calc.maxACIntake": "Capacidad maxima de AC",
     "ev.cardetails.calc.lvlTwoChargingRate": "Velocidad de carga Nivel 2",
     "ev.cardetails.calc.minOflvl2AC": "Nivel mínimo de carga y capacidad máxima de absorcion del AC",
     "ev.cardetails.calc.lvl2FullCharge": "Tiempo de carga total en Nivel 2",
     "ev.cardetails.calc.milesDrivenPerYr": "Millas conducidas al año",
     "ev.cardetails.calc.userInput": "Introducción de datos por el usuario",
     "ev.cardetails.calc.milesPerGallon": "Millas por Galón (mpg)",
     "ev.cardetails.calc.mpg": " millas/gal",
     "ev.cardetails.calc.portionElectric": "Porción eléctrica usada",
     "ev.cardetails.calc.portionElectricSource":
          "Introducción de datos por el usuario. Para un vehículo híbrido, estima la porción de millas conducidas usando batería eléctrica en vez de gasolina.",
     "ev.cardetails.calc.gallonsUsed": "Galones Usados",
     "ev.cardetails.calc.gallonsUsedFormula": "Millas manejadas * (1 - Porción eléctrica usada) / MPG",
     "ev.cardetails.calc.gallonsSaved": "Galones Ahorrados",
     "ev.cardetails.calc.gallonsSavedFormula":
          "Galones usados por un vehiculo a gasolina comparable - Galones usados por VE",
     "ev.cardetails.calc.electricEmissions": "Emisiones por consumo eléctrico en Lbs",
     "ev.cardetails.calc.electricEmissionsLbsMwh": "Emisiones de CO2 por consumo eléctrico en lbs/MWh",
     "ev.cardetails.calc.EIA": "Administración de información energética de Estados Unidos",
     "ev.cardetails.calc.gasolineEmissionsYr": "Emisiones por consumo de gasolina en lbs de CO2/yr",
     "ev.cardetails.calc.gasolineEmissions": "Emisiones de CO2 por consumo de gasolina en lbs/gal",
     "ev.cardetails.calc.emissionsReudctions": "Reducción de emisiones",
     "ev.cardetails.calc.lbsCo2PerYr": " lbs de CO2 al año",
     "ev.cardetails.calc.gasolineEmissionsLbs": "Emisiones por consumo de gasolina en lbs",
     "ev.cardetails.calc.arborDayFoundation": "Fundación Arbor Day",
     "ev.cardetails.calc.emissionsPerTree": "Emisiones capturadas por árbol por año",
     "ev.cardetails.calc.electricityEmissions": "Emisiones de CO2 por consumo eléctrico en lbs/MWh",
     "ev.usedCarTitle": "{car} Usado",
     "ev.cardetails.electricRange":
          "To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",
     "ev.carDetails.usedBatterySizeTooltip":
          "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",

     "ev.purchaseMethod": "Forma de pago",
     "ev.purchaseMethodToolTip":
          "En modo renta, usted tiene que devolver el auto al final del periodo de arrendamiento. En el caso de financiación con un crédito, usted se queda con el auto al terminar de pagar el crédito.",
     "ev.cash": "Efectivo",
     "ev.loan": "Crédito",
     "ev.lease": "Renta",
     "ev.milesDrivenAnnually": "Millas conducidas por año",
     "ev.electricPortion": "Parte eléctrica de un PHEV",
     "ev.electricPortionTooltip":
          "A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity.",
     "ev.yearsOwnership": "Compra/renta al año",
     "ev.interestRate": "Tasa de interés",
     "ev.electricityRateCalc":
          "Electricity rate is calculated as a blended rate based off an average household consumption of 700 kWh. To learn more, please click {website}",

     "assumption.salesTax": "Impuesto a las ventas",
     "assumption.gasolinePrice": "Precio de gasolina",
     "assumption.downPayment": "Cuota inicial",
     "assumption.electricityRate": "Rango eléctrico",
     "assumption.downPaymentValue": "10% de MSRP más impuestos",

     "ev.compareGasVehicle": "Compare el {car} con un vehículo similar a gasolina, el {gasCar}.",
     "ev.compareOtherVehicles": "COMPRE OTHER VEHICLES",
     "ev.incentives.title":
          "Hasta {savings} en créditos fiscales y reembolsos están disponibles {lineBreak} para el {car}.",
     "ev.reviews": "Reseñas en la web",

     "chargingMap.updateZipcode": "Actualizar Código Postal",
     "chargingMap.errorZipcode": "Error: {workingZipcode} no es un código postal válido",
     "chargingMap.showFullListOfStations": "lista completa de estaciones",
     "chargingMap.public": "Estaciones Públicas",
     "chargingMap.businessGovernment": "Instaladas por empresas o el gobierno",
     "chargingMap.highPowerStations": "Estaciones de Alta Potencia",
     "chargingMap.DCChargers": "Carga rápida CC o súper-cargadores",
     "chargingMap.otherSttations": "Otros Tipos de Estaciones",
     "chargingMap.privateStations": "Estaciones rivadas",
     "chargingMap.searchDealers": "Buscar Distribuidores Calificados",
     "chargingMap.otherStations": "Otros tipos de estaciones",

     "dealers.brands": "Marcas",
     "dealers.allBrands": "Todos las Marcas",
     "dealers.allDealers": "Todos los Distribuidores",
     "dealers.contact": "CONTACTO",
     "dealers.website": "PAGINA WEB",
     "dealers.findDealers": "Encontrar Distribuidores",

     "incentives.title": "Incentivos para Vehículos Eléctricos",
     "incentives.subTitle":
          "Usted puede ser elegible para un rango de incentivos, incluyendo reembolsos, créditos fiscales y otros beneficios. Los incentivos son personalizados según su lugar de residencia.",
     "incentives.single": "Soltero",
     "incentives.married": "Casado",
     "incentives.headOfHousehold": "Cabeza de hogar",
     "incentives.vehicleType": "Tipo de vehículo",
     "incentives.vehicleTypeTooltip": "Ciertos incentivos dependen de qué carro compres.",
     "incentives.tradeIn": "Intercambio",
     "incentives.income": "Estado de ingresos e impuestos",
     "incentives.incomeTooltip":
          "La elegiblidad para incentivos y la cantidad que puedes recibir depende normalmente de tu nivel de ingresos. Clientes con niveles de ingresos menores son usualmente elegibles para reembolsos mayores.",
     "incentives.clunkerNone":
          "Ciertos incentivos te compensan por retirar un vehículo existente, también conocido como carro viejo.",
     "incentives.clunkertoolTip":
          "Ciertos incentivos te compensan por retirar un vehículo existente, también conocido como carro viejo.",
     "incentives.clunkertoolTipContd":
          " Están disponibles en tu región. Los requisitos para elegibilidad varían por incentivo, por favor revise los detalles del programa.",
     "incentives.householdSize": "Tamaño del Hogar",
     "incentives.householdIncome": "Ingresos del Hogar",
     "incentives.planClunker": "¿Estás planeando intercambiar tu carro viejo?",
     "incentives.location": "Ubicación",
     "incentives.locationTooltip":
          "Muchos incentivos dependen del lugar en el que vives. Por favor ingresa tu código zip para ayudarte a determinar cuáles incentivos están disponibles en tu lugar de residencia.",
     "incentives.taxFilingStatus": "Estado Civil para Efectos de la Declaración",
     "incentives.whichVehicle": "¿Qué vehículo planeas comprar?",
     "incentives.incentiveEligibility": "Actualizar Elegibilidad para Incentivos",

     "homeChargers.subText":
          "Seleccione el vehículo que le interesa para ver los detalles de tiempo de carga total de la bateria.",
     "homeChargers.wallMounted": "Montaje en pared",
     "homeChargers.portable": "Portátil",
     "homeChargers.price": "Precio",
     "homeChargers.cordLength": "Longitud del cable",
     "homeChargers.wifi": "Conectividad Wifi",
     "homeChargers.socket": "enchufe",
     "homeChargers.socketTooltip":
          "Cada modelo de cargador tiene un patrón de enchufe diferente. Estos tipos de patrón corresponden a la forma en la que las puntas encajan en la toma de corriente de la pared. Si no está seguro de cuál es el mejor para su hogar/garaje, consulte con un electricista calificado..",
     "homeChargers.cordLengthLtH": "Longitud del cable: Menor a mayor",
     "homeChargers.cordLengthHtL": "Longitud del cable: Mayor a menor",
     "homeChargersCard.buy": "Detalles y Compra",
     "homeChargersCard.beforeIncentives": "Antes de incentivos",
     "homeChargersCard.socket": "Enchufe {socket}",
     "homeChargers.cable": "Cable de {length} ft.",
     "homeChargers.hardwired": "Cableado Enchufe",
     "homeChargers.toFullCharge": "para carga completa",

     "electricians.residential": "Residencial",
     "electricians.commercial": "Comercial",
     "electricians.industrial": "Industrial",
     "electricians.type": "Tipo de Electricista",
     "electricians.searchElectricians": "Buscar Electricistas Calificados",

     "tabbed-maps-dealer-catalog-tab": "Distribuidores",
     "tabbed-maps-all-stations-tab": "Estaciones de Carga",
     "tabbed-maps-electricians-tab": "Electricistas",
     "tabbed-maps-route-tab": "Buscar una Ruta",

     here: "aquí",
     any: "cualquier",
     new: "Nuevo",
     preOwned: "Seminuevo",
     preOwnedElectricVehicles: "Vehículos Eléctricos Usados",
     "usedEVCard.yearRange": "Rango de Años",
     "usedEVCard.electricRangeAverage": "Promedio de Rango Eléctrico",
     "header.chargingStations": "Carga Pública",
     home: "Hogar",
     feet: "pies",
     month: "mes",
     electricVehicle: "Auto Eléctrico",
     maximum: "máxima",
     details: "Detalles",
     ELIGIBILITY: "Elegibilidad",
     "program-costs-pricing": "Costos/Precios del programa",
     "permit-fees": "Tarifas de permisos",
     "charger-options": "Opciones de cargador",

     youCanUseAnyCharger:
          "Puede usar cualquier cargador que desee, si conduce uno de los siguientes vehículos eléctricos elegibles:",

     "program-charging-hours-rates": "HORAS DE CARGA Y TARIFAS",

     "perksBottomText.priceSignal":
          "Xcel Energy colabora diariamente con su proveedor de servicios de carga inteligente para compartir patrones energéticos y disponibilidad de energía renovable en la red. Este intercambio se produce a través de un {enlace}, que proporciona información valiosa. Por ejemplo, a veces los tiempos de carga óptimos se alinean con la energía eólica durante los días de semana o con la energía solar durante los fines de semana. El objetivo de la señal de precio es alentar a los vehículos eléctricos a cargar durante las horas de menor actividad, cuando son asequibles, cuando abunda la energía renovable. Su proveedor de servicios de carga inteligente utilizará esta información de señal de precio junto con sus preferencias de carga y tarifas eléctricas para crear un programa de carga eficiente.",

     perksBottomText2_v2:
          "Los clientes con una tarifa de tiempo de uso (TOU) pagan precios de electricidad variables durante horas específicas (como se indica en el {enlace}). El objetivo de la señal de precio es alentar a los propietarios de vehículos eléctricos a cargar durante las horas de menor actividad, cuando la energía renovable abunda y las tarifas se reducen. Tenga en cuenta que todas las horas se consideran fuera de las horas pico durante los fines de semana.",

     perksBottomText3:
          "Tenga la seguridad de que este cronograma garantiza que su vehículo esté listo cuando lo necesite, al mismo tiempo que respalda la eficiencia de la red y la utilización de energía renovable. Su proveedor de servicios de carga inteligente enviará automáticamente el programa de carga a su automóvil, simplificando el proceso. Si son necesarios ajustes, le facilitarán el cambio.",
};
