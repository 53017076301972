import React, { useContext } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import Range from '../../../../components/shared/InputElements/Range';
import { FormattedMessage, useIntl } from 'react-intl';

const SlidePublicChargingPercentage = () => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id="home-charging-advisor-public-charging-percentage"
      value={userPrefs.get("publicChargingPercentage")}
      handler={(e) => userPrefs.set({publicChargingPercentage: e.target.value})}
      label={<FormattedMessage
        id="hcaPortionPublicCharging"
        defaultMessage="Portion of Public Charging"
        description="Portion of Public Charging"
      />}
      rangeMin={0}
      rangeMax={100}
      rangeStep={5}
      description={val => `${val}%`}
      tooltip={intl.formatMessage({id:"hcaPortionPublicChargingTooltip", defaultMessage:"EV drivers do almost all of their charging at home."})}
    />
  );
};

export default SlidePublicChargingPercentage;
