import minnesota from "./loadProfiles/home/minnesota";
import wisconsin from "./loadProfiles/home/wisconsin";
import colorado from "./loadProfiles/home/colorado";
import newMexico from "./loadProfiles/home/newMexico";

import nm_r from "./rates/nm_r";

import co_r from "./rates/co_r";
//import co_re_tou from "./rates/co_re_tou";
import co_ev_accelerate_at_home from './rates/co_ev_accelerate_at_home';

import mn_a01 from "./rates/mn_a01";
import mn_a02 from "./rates/mn_a02";
import mn_a08 from "./rates/mn_a08";
import mn_ev_accelerate from "./rates/mn_ev_accelerate";
//import mn_a82 from "./rates/mn_a82";
import wi_e10 from "./rates/wi_e10";
import wi_e11 from "./rates/wi_e11";
import wi_ev_accelerate from "./rates/wi_ev_accelerate";

import {
  AFTER_9PM_BEFORE_9AM,
  AFTER_6PM_BEFORE_9AM,
  AFTER_2PM_BEFORE_6PM,
  AFTER_MIDNIGHT_BEFORE_8AM,
  AFTER_8AM_BEFORE_MIDNIGHT,
  AFTER_12PM_BEFORE_8PM,
  AFTER_10PM_BEFORE_11AM,
  AFTER_11AM_BEFORE_10PM,
  AFTER_9PM_BEFORE_6AM,
  AFTER_1230AM_BEFORE_930AM,
  AFTER_6AM_BEFORE_3PM,
  ALL_HOURS,
  MN_ID,
  WI_ID,
  CO_ID,
  NM_ID,
} from "../constants/";

import chargingPatterns from './chargingPatterns';

const states = [
  {
    id: MN_ID,
    title: "Minnesota",
    loadProfile: minnesota,
    rates: [
      // Removing from the tool per Xcel request
      //{
        //id: mn_a82.name,
        //title: ["EV Subscription", "Pilot"],
        //data: mn_a82,
      //},
      {
        id: mn_ev_accelerate.name,
        title: ["EV Accelerate", "At Home"],
        data: mn_ev_accelerate,
      },
      {
        id: mn_a08.name,
        title: ["Time of Day", "Separate Meter"],
        data: mn_a08,
      },
      {
        id: mn_a02.name,
        title: ["Time of Day", "Whole Home"],
        data: mn_a02,
      },
      {
        id: mn_a01.name,
        title: ["Regular", "Residential"],
        data: mn_a01,
        isDefault: true,
      },
    ],
    chargingPatterns: chargingPatterns.filter(p => {
      return [
        AFTER_9PM_BEFORE_9AM,
        AFTER_6PM_BEFORE_9AM,
        AFTER_2PM_BEFORE_6PM,
        ALL_HOURS,
      ].includes(p.id);
    })
  },
  {
    id: WI_ID,
    title: "Wisconsin",
    loadProfile: wisconsin,
    rates: [
      {
        id: wi_ev_accelerate.name,
        title: ["EV Accelerate", "At Home"],
        data: wi_ev_accelerate,
      },
      {
        id: wi_e11.name,
        title: ["Time Of Day", "Whole Home"],
        data: wi_e11,
      },
      {
        id: wi_e10.name,
        title: ["Regular", "Residential"],
        data: wi_e10,
        isDefault: true,
      },
    ],
    chargingPatterns: chargingPatterns.filter(p => {
      return [
        AFTER_MIDNIGHT_BEFORE_8AM,
        AFTER_8AM_BEFORE_MIDNIGHT,
        AFTER_12PM_BEFORE_8PM,
        ALL_HOURS,
      ].includes(p.id);
    })
  },
  {
    id: CO_ID,
    title: "Colorado",
    loadProfile: colorado,
    rates: [
      // Removing from the tool per Xcel request
      //{
        //id: co_re_tou.name,
        //title: ["Time of Day", "Whole Home"],
        //data: co_re_tou,
      //},
      {
        id: co_r.name,
        title: ["Regular", "Residential"],
        data: co_r,
        isDefault: true,
      },
      {
        id: co_ev_accelerate_at_home.name,
        title: ["EV Accelerate", "At Home"],
        data: co_ev_accelerate_at_home,
      }
    ],
    chargingPatterns: chargingPatterns.filter(p => {
      return [
        AFTER_9PM_BEFORE_6AM,
        AFTER_1230AM_BEFORE_930AM,
        AFTER_6AM_BEFORE_3PM,
        AFTER_10PM_BEFORE_11AM,
        AFTER_11AM_BEFORE_10PM,
        ALL_HOURS,
      ].includes(p.id);
    })
  },
  {
    id: NM_ID,
    title: "New Mexico",
    loadProfile: newMexico,
    rates: [
      {
        id: nm_r.name,
        title: ["Residential"],
        data: nm_r,
        isDefault: true,
      },
    ],
    chargingPatterns: chargingPatterns.filter(p => {
      return [
        AFTER_9PM_BEFORE_9AM,
        AFTER_6PM_BEFORE_9AM,
        ALL_HOURS,
      ].includes(p.id);
    })
  },
];

export default states;
