import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";

import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext"
import MonthlyFuelCostChart from "../../client_customizations/components/CostCharts/MonthlyFuelCostChart";
import FuelRangeChart from "../CostsCharts/FuelRangeChart";
import CostToOwnComparison from "../../client_customizations/components/CostCharts/CostToOwnComparison"
import CostOfOwnershipOptions from "./../CostOfOwnershipOptions/CostOfOwnershipOptions";
import { FormatCarName } from "./../../utils/Helpers/Format";
// import calcTotalCostOfOwnership from "./../../functions/vehicle/CostOfOwnership/calcTotalCostOfOwnership";
// import ShowHideButton from "../ShowHideButton/ShowHideButton";
// import CalcPanels from "./CalcPanels/CalcPanels"
import RenderAssumption from "../RenderAssumption/RenderAssumption";
import {
  SALES_TAX,
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL,
  DOWN_PAYMENT,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH
} from  "../../client_customizations/data/assumptions/ASSUMPTIONS"
import { FormattedMessage, useIntl } from 'react-intl';
import BreakevenChart from "../CostsCharts/BreakevenChart";
import { loadInsuranceData } from "../../functions/vehicle/CostOfOwnership/api/loadInsuranceData";
import { loadMaintenanceData } from "../../functions/vehicle/CostOfOwnership/api/loadMaintenanceData";

const CostOfOwnership = ({ cars, title, comparison, usedEv, userLocation }) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  const [isLoading, setIsLoading] = useState(true);
  const [insuranceData, setInsuranceData] = useState(null);
  const [maintenanceData, setMaintenanceData] = useState(null);

  useEffect(() => {
    if (cars && cars.length > 0) {
      loadInsuranceData(cars[0].handle, userPrefs, setInsuranceData, setIsLoading);
      loadMaintenanceData(cars, userPrefs, setMaintenanceData, setIsLoading);
    }
  }, [cars, userPrefs]);

  if (!cars || cars.length === 0) return null;

  const car = cars[0]
  const equivalentGasVehicle = cars[1];
  let userLocationState
  switch(userLocation && userLocation.region) {
    case "Minnesota":
      userLocationState="Minnesota"
    break;
    case "Wisconsin":
      userLocationState="Wisconsin"
      break;
      case "New Mexico":
        userLocationState="New Mexico"
      break;    
      case "Colorado":
        userLocationState="Colorado"
      break;
      default:
        userLocationState="default"
  }
  SALES_TAX.title = intl.formatMessage({ id: "assumption.salesTax", defaultMessage: "Sales Tax"})
  SALES_TAX.value = userPrefs.get("salesTax");
  SALES_TAX.valueAsFormatted =
    (userPrefs.get("salesTax") * 100).toFixed(2) + "%";
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL.title = intl.formatMessage({ id: "assumption.gasolinePrice", defaultMessage: "Price of Gasoline"})
  DOWN_PAYMENT.title = intl.formatMessage({ id: "assumption.downPayment", defaultMessage: "Down Payment"})
  DOWN_PAYMENT.valueAsFormatted = intl.formatMessage({ id: "assumption.downPaymentValue", defaultMessage: "10% of MSRP + Tax"})
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH[userLocationState].title = intl.formatMessage({ id: "assumption.electricityRate", defaultMessage: "Electricity Rate"})

  const rateDisclaimerTranslations = {
    Colorado: <FormattedMessage
    id="coloradoRateDisclaimer"
    defaultMessage="Electricity Rate shown is an example of an EV driver in Colorado charging off-peak on the Time of Day - Whole Home rate plan."
    description="Colorado Rate Disclaimer"
  />
  }

  return (
    <section className="container-fluid" id="CostOfOwnership">
      <div className="container padded">
        <div className="CostOfOwnership">
          <div className="row">
            <div className="col-sm-12">
              <p className={usedEv ? `h1 p-3` : `h1 text-center pb-3`}>
                {title || (
                  <FormattedMessage
                    id="ev.compareGasVehicle"
                    defaultMessage="Compare the {car} to a similar gas vehicle, the {gasCar}"
                    description="Description of Colton Recharged"
                    values={{
                      car: (
                        <strong style={{ color: "#005f83", fontWeight: 700 }}>
                          {FormatCarName(car)}
                        </strong>
                      ),
                      gasCar: (
                        <strong style={{ color: "#005f83", fontWeight: 700 }}>
                          {FormatCarName(equivalentGasVehicle)}
                        </strong>
                      ),
                    }}
                  />
                )}
              </p>
              {/* {comparison || usedEv ? null : 
                <Link to="/compare-vehicles">
                  <button style={{margin: "1.25rem"}} className="btn-ae">
                    {intl.formatMessage({ id: "ev.compareOtherVehicles", defaultMessage: "COMPARE OTHER VEHICLES"})}
                  </button>
                </Link>
              } */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-lg-3" style={{ textAlign: "left" }}>
            <CostOfOwnershipOptions cars={cars} usedEv={usedEv} />
            <br />
            <div className="input-well d-none d-md-block">
              <p className="RenderItemTitle" style={{ marginBottom: 10 }}>
                <FormattedMessage
                  id="graph.assumptions"
                  defaultMessage="Assumptions"
                  description="Assumptions"
                />
              </p>
              <RenderAssumption assumption={SALES_TAX} />
              {/* <RenderAssumption assumption={GASOLINE_PRICE_IN_DOLLARS_PER_GAL} /> */}
              {userPrefs.get("purchaseMethod") === "cash" || usedEv ? null : (
                <RenderAssumption assumption={DOWN_PAYMENT} />
              )}
              <RenderAssumption
                assumption={
                  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH[userLocationState]
                }
              />
              <p className="legal-disclaimer">
                <small>
                  {userLocationState === "Colorado"
                    ? rateDisclaimerTranslations[userLocationState]
                    : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH[userLocationState]
                        .disclaimer}
                </small>
              </p>
            </div>
          </div>
          <div
            id="costs-chart-areas"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: "absolute", top: "-9999px" }}
          >
            <span>
              Results have been updated as of {new Date().toLocaleString()}.
            </span>
          </div>
          <div className="col-md-8 col-lg-9">
            {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED &&
            !isLoading &&
            insuranceData &&
            maintenanceData ? (
              <MonthlyFuelCostChart
                cars={cars}
                comparison={comparison}
                electricityRateInDollarsPerKWH={
                  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH[userLocationState].value
                }
                insuranceData={insuranceData}
                maintenanceData={maintenanceData}
              />
            ) : null}
            <br />
            {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_OWN_COMPARISON &&
            !isLoading &&
            insuranceData &&
            maintenanceData ? (
              <CostToOwnComparison
                cars={cars}
                hasExtraPadding
                comparison={comparison}
                electricityRateInDollarsPerKWH={
                  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH[userLocationState].value
                }
                insuranceData={insuranceData}
                maintenanceData={maintenanceData}
              />
            ) : null}
            <br />
            {process.env.REACT_APP_PAGES_HOME_GRAPH_BUDGET_ENABLED ? (
              <FuelRangeChart car={car} />
            ) : null}
            {process.env.REACT_APP_PAGES_BREAKEVEN_GRAPH &&
            !isLoading &&
            insuranceData &&
            maintenanceData &&
            !comparison ? (
              <BreakevenChart
                cars={cars}
                electricityRateInDollarsPerKWH={
                  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH[userLocationState].value
                }
                insuranceData={insuranceData}
                maintenanceData={maintenanceData}
              />
            ) : null}
            {!comparison && (
              <p className="text-left">
                <FormattedMessage
                  id="ev.carDetails.compareText"
                  defaultMessage="We selected the {gasVehicle} based on its
                  proximity to the {vehicle} based on brand, size, and price."
                  description="Compare Vehicle Text"
                  values={{
                    gasVehicle: FormatCarName(equivalentGasVehicle),
                    vehicle: FormatCarName(car),
                  }}
                />
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CostOfOwnership;

CostOfOwnership.propTypes = {
  car: PropTypes.object
};
