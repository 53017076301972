import {
  HOME_ENERGY_COST_ID,
  EV_ENERGY_COST_ID,
  GAS_ENERGY_COST_ID,
} from "../client_customizations/constants/";

const rateComparisonChartData = ({
  rates,
  rateTotals,
  selectedEnergyCostIds,
  chartLabels,
}) => {
  return {
    datasets: [
      {
        label: chartLabels.electricHome,
        id: HOME_ENERGY_COST_ID,
        data: rateTotals.map((rateTotal) => rateTotal[HOME_ENERGY_COST_ID]),
        backgroundColor: "#92959B",
        barPercentage: 0.7,
        categoryPercentage: 1,
        borderColor: "#ffffff",
        borderWidth: {
          top: 4,
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
      {
        label: chartLabels.electricVehicle,
        id: EV_ENERGY_COST_ID,
        data: rateTotals.map((rateTotal) => rateTotal[EV_ENERGY_COST_ID]),
        backgroundColor: "#BF591D",
        barPercentage: 0.7,
        categoryPercentage: 1,
        borderColor: "#ffffff",
        borderWidth: {
          top: 4,
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
      {
        label: chartLabels.gasoline,
        id: GAS_ENERGY_COST_ID,
        data: rateTotals.map((rateTotal) => rateTotal[GAS_ENERGY_COST_ID]),
        backgroundColor: "#57595D",
        barPercentage: 0.7,
        categoryPercentage: 1,
      },
    ].filter((dataset) => selectedEnergyCostIds.includes(dataset.id)),
    labels: [
      [chartLabels.currentCosts],
      ...rates
        .filter((rate, i) => i !== 0)
        .map((rate) => {
          const title = rate.data.title;

          if (title === "Regular Residential") {
            return rate.data.translated;
          }

          if (title === "EV Accelerate At Home") {
            return rate.data.translated;
          }

          return rate.title;
        }),
    ],
  };
};

export default rateComparisonChartData;
