import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { RenderByState } from "../../../components/RenderByState";
import { NEW_MEXICO } from "../../../constants";

const EligibleChecklist = ({ stateName, ownCharger }) => {

  return (
    <>
      <h3
        style={{
          textAlign: "left",
          textTransform: "uppercase",
          fontSize: "24px",
        }}
      >
        <FormattedMessage
          id="ELIGIBILITY"
          defaultMessage="ELIGIBILITY"
          description="ELIGIBILITY"
        />
      </h3>
      <ul>
      {stateName === "Wisconsin" && !ownCharger && (
       <>
       <li>
          <FormattedMessage
            id="driveEVOrPHEV"
            defaultMessage="Drive an electric vehicle (all-electric or plug-in hybrid)"
            description="Drive an electric vehicle (all-electric or plug-in hybrid)"
          />
        </li>
        <li>
          <FormattedMessage
            id="liveInResidenceState"
            defaultMessage="Live in a residence that receives electric service from Xcel Energy in {stateName}"
            description="Live in a residence that receives electric service from Xcel Energy in {stateName}"
            values={{
              stateName: stateName,
            }}
          />
        </li>
        <li>
          <FormattedMessage
            id="haveAccessToWifi"
            defaultMessage="Have access to Wi-Fi at the charging location"
            description="Have access to Wi-Fi at the charging location"
          />
        </li>
        </>
        )}
        {stateName === "Wisconsin" && ownCharger && (
              <>
                      <li>
                     <FormattedMessage
                     id="driveEVOrPHEV"
                     defaultMessage="Drive an electric vehicle (all-electric or plug-in hybrid)"
                     description="Drive an electric vehicle (all-electric or plug-in hybrid)"
                     />
                     </li>
                     <li>
                     <FormattedMessage
                     id="liveInResidenceState"
                     defaultMessage="Live in a residence that receives electric service from Xcel Energy in {stateName}"
                     description="Live in a residence that receives electric service from Xcel Energy in {stateName}"
                     values={{
                            stateName: stateName,
                     }}
                     />
                     </li>
              </>
       )}
        {stateName !== "Wisconsin" && (
              <>
                     <li>
                     <FormattedMessage
                     id="driveEVOrPHEV"
                     defaultMessage="Drive an electric vehicle (all-electric or plug-in hybrid)"
                     description="Drive an electric vehicle (all-electric or plug-in hybrid)"
                     />
                     </li>
                     <li>
                     <FormattedMessage
                     id="liveInResidenceState"
                     defaultMessage="Live in a residence that receives electric service from Xcel Energy in {stateName}"
                     description="Live in a residence that receives electric service from Xcel Energy in {stateName}"
                     values={{
                            stateName: stateName,
                     }}
                     />
                     </li>
              </>
        )}
        {stateName === "Minnesota" && (
              <>
                     <li>
                            <FormattedMessage
                            id="haveAccessToWifi"
                            defaultMessage="Have access to Wi-Fi at the charging location"
                            description="Have access to Wi-Fi at the charging location"
                            />
                     </li>
                     <li>
                     <FormattedMessage
                            id="solarInstalled"
                            defaultMessage="If you have Solar installed at your home, you will need to switch your whole home to Time of Use in order to qualify for EV Accelerate At Home"
                            description="If you have Solar installed at your home, you will need to switch your whole home to Time of Use in order to qualify for EV Accelerate At Home"
                     />
                     </li>
          </>
          )}
        {/* <li>
        <FormattedMessage
            id="qualifyingChargepointCharger"
            defaultMessage="Have a qualifying ChargePoint or Enel X Way {link}"
            description="Have a qualifying ChargePoint or Enel X Way {link}"
            values={{
              link: (
                    <>
                <a href="#charger-options">
                  {" "}
                  <FormattedMessage
                    id="clickHere"
                    defaultMessage="Click Here"
                    description="Click Here"
                  />
                </a>
                </>
              ),
            }}
          />
        </li> */}
        {/* <li>
        <FormattedMessage
            id="chargingStationInspected"
            defaultMessage="Charging station will need to be hard-wired and inspected"
            description="Charging station will need to be hard-wired and inspected"
          />
        </li> */}
        {/* <li>
        <FormattedMessage
            id="chargingStationTransmission"
            defaultMessage="Your charging station will be tested to verify data transmission"
            description="Your charging station will be tested to verify data transmission"
          />
        </li> */}
        {/* {stateName === "Wisconsin" ? (
          <li>
            <FormattedMessage
              id="qualifyingCharger"
              defaultMessage="Have a qualifying ChargePoint or Enel X Way charger"
              description="Have a qualifying ChargePoint or Enel X Way charger"
            />
            <sup>1</sup>
          </li>
        ) : null} */}
        {/* {stateName === "Wisconsin" ? (
          <li>
            <FormattedMessage
              id="chargingHardWired"
              defaultMessage="Charging station will need to be hard-wired and inspected"
              description="Charging station will need to be hard-wired and inspected"
            />
          </li>
        ) : null} */}
        {/* {stateName === "Wisconsin" ? (
          <li>
            <FormattedMessage
              id="stationVerify"
              defaultMessage="Your charging station will be tested to verify data transmission"
              description="Your charging station will be tested to verify data transmission"
            />
          </li>
        ) : null} */}
        {/* {stateName === "Minnesota" && !ownCharger ? (
          <li>
            <FormattedMessage
              id="solarInstalled"
              defaultMessage="If you have Solar installed at your home, you will need to switch your whole home to Time of Use in order to qualify for EV Accelerate At Home"
              description="If you have Solar installed at your home, you will need to switch your whole home to Time of Use in order to qualify for EV Accelerate At Home"
            />
          </li>
        ) : null} */}
        {stateName === "Colorado" ? (
              <>
                     <li>
                            <FormattedMessage
                            id="faq_agree_to_charge"
                            defaultMessage="Agree to charge during an off-peak schedule through our {link}"
                            description="Agree to charge during an off-peak schedule through our {link}"
                            values={{
                                   link: (<a href="/optimize-your-charge">
                                          <FormattedMessage
                                          id="optimizeYourChargeProgram"
                                          defaultMessage="Optimize Your Charge Program"
                                          description="Optimize Your Charge Program"
                                          />
                                          </a>
                                   ),
                            }}
                            />
                     </li>
              </>
        ) : null}
        {stateName === "New Mexico" ? (
              <>
                     <li>
                            <FormattedMessage
                            id="faq_agree_to_charge"
                            defaultMessage="Agree to charge during an off-peak schedule through our {link}"
                            description="Agree to charge during an off-peak schedule through our {link}"
                            values={{
                                   link: (
                                   <>
                                          &nbsp;
                                          <a href="/optimize-your-charge">
                                          <FormattedMessage
                                          id="optimizeYourChargeProgram"
                                          defaultMessage="Optimize Your Charge Program"
                                          description="Optimize Your Charge Program"
                                          />
                                          </a>
                                   </>
                                   ),
                            }}
                            />
                     </li>
              </>
        ) : null}
        {ownCharger && (
          <RenderByState
            stateName={stateName}
            options={{
              default: (
                <li>
                  <FormattedMessage
                    id="eligibleownCharger"
                    defaultMessage="Have a qualifying ChargePoint charger {link}"
                    description="Have a qualifying ChargePoint charger {link}"
                    values={{
                      link: (
                            <>
                        <a href="#charger-options">
                          {" "}
                          <FormattedMessage
                            id="clickHere_v2"
                            defaultMessage="Click Here"
                            description="Click Here"
                          />
                        </a>
                        </>
                      ),
                    }}
                  />
                </li>
              ),
              [NEW_MEXICO]: (
                <>
                  <li>
                    <FormattedMessage
                      id={`eligibleownCharger-nm`}
                      defaultMessage="Agree to charge during an off-peak schedule through our {link} or enroll in our Time of Use Rate (enrollment required)"
                      description="Agree to charge during an off-peak schedule through our Optimize Your Charge Program or enroll in our Time of Use Rate (enrollment required)"
                      values={{
                        link: (
                          <a href="/optimize-your-charge-nm">
                            {" "}
                            <FormattedMessage
                              id="optimizeYourChargeProgram"
                              defaultMessage="Optimize Your Charge Program"
                              description="Optimize Your Charge Program"
                            />
                          </a>
                        ),
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="eligibleLevel2"
                      defaultMessage="Purchase an eligible Level 2 charger and/or install wiring to support an eligible Level 2 charger"
                      description="Purchase an eligible Level 2 charger and/or install wiring to support an eligible Level 2 charger"
                    />
                  </li>
                </>
              ),
            }}
          />
        )}
       {stateName === "Wisconsin" && ownCharger && (
              <>
                     <li>
                            <FormattedMessage
                            id="chargingStationInspected"
                            defaultMessage="Charging station will need to be hard-wired and inspected"
                            description="Charging station will need to be hard-wired and inspected"
                            />
                     </li>
                     <li>
                            <FormattedMessage
                            id="haveAccessToWifi"
                            defaultMessage="Have access to Wi-Fi at the charging location"
                            description="Have access to Wi-Fi at the charging location"
                            /><sup>2</sup>
                     </li>
                     <li>
                            <FormattedMessage
                            id="chargingStationTransmission"
                            defaultMessage="Your charging station will be tested to verify data transmission"
                            description="Your charging station will be tested to verify data transmission"
                            />
                     </li>
              </>
       )}
      </ul>
    </>
  );
};

export default EligibleChecklist;

EligibleChecklist.propTypes = {
  stateName: PropTypes.string,
};
