import React, { useContext } from "react";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

import "./EventsFilterControls.scss";

import { FormattedMessage, useIntl } from "react-intl";
import Select from "../shared/InputElements/Select";
import GenericInputZipcode from "../../components/InputComponents/InputZipcode/GenericInputZipcode";

const EventsFilterControls = ({
  eventCategories,
  setEventCategories,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  distance,
  setDistance,
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const eventLocation = userPrefs.get("eventLocation");

  const eventCategoryTranslations = {
    "Ride and Drive": intl.formatMessage
      ? intl.formatMessage({
          id: "events.rideAndDrive",
          defaultMessage: "Ride and Drive",
        })
      : "Ride and Drive",
    Autoshow: intl.formatMessage
      ? intl.formatMessage({
          id: "events.autoshow",
          defaultMessage: "Autoshow",
        })
      : "Autoshow",
    "Education Event": intl.formatMessage
      ? intl.formatMessage({
          id: "events.educationEvent",
          defaultMessage: "Education Event",
        })
      : "Education Event",
    Training: intl.formatMessage
      ? intl.formatMessage({
          id: "events.training",
          defaultMessage: "Training",
        })
      : "Training",
    Webinar: intl.formatMessage
      ? intl.formatMessage({ id: "events.webinar", defaultMessage: "Webinar" })
      : "Webinar",
    Conference: intl.formatMessage
      ? intl.formatMessage({
          id: "events.conference",
          defaultMessage: "Conference",
        })
      : "Conference",
  };

  const renderEventCategories = Object.keys(eventCategories).map((category) => {
    return (
      <button
        className={eventCategories[category] ? "btn active" : "btn"}
        key={category}
        type="button"
        onClick={() => updateEventCategory(category)}
      >
        {eventCategoryTranslations[category]}
      </button>
    );
  });

  const updateEventCategory = (category) => {
    const newEventCategories = {
      ...eventCategories,
      [category]: !eventCategories[category],
    };
    setEventCategories(newEventCategories);
  };

  const minDate = new Date().toISOString().substring(0, 10);

  const changeStartDate = (e) => {
    if (endDate !== "" && endDate < e.target.value) {
      setEndDate(e.target.value);
    }
    setStartDate(e.target.value);
  };

  return (
    <div className="input-well eventsFilterControls">
      <p className="h2">
        <FormattedMessage
          id="eventsFilters"
          defaultMessage="Filters"
          description="Filters"
        />
      </p>
      <form>
        <GenericInputZipcode
          buttonText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: "chargingMap.updateZipcode",
                  defaultMessage: "Update ZIP Code",
                })
              : "Update ZIP Code"
          }
        />
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage
              id="eventsDateRange"
              defaultMessage="Date Range"
              description="Date Range"
            />
          </span>
          <input
            className="inputField inputDate"
            type="date"
            id="eventStartDate"
            title="Earliest event date"
            value={startDate}
            style={{ width: "100%" }}
            onChange={(e) => changeStartDate(e)}
            min={minDate}
          />
          <input
            className="inputField inputDate"
            type="date"
            id="eventEndDate"
            title="Latest event date"
            value={endDate}
            style={{ width: "100%" }}
            onChange={(e) => setEndDate(e.target.value)}
            min={startDate}
          />
        </div>

        <div className="form-group">
          <div className="btn-block-container">
            <Select
              id={"events-distance"}
              value={distance}
              label={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "events.distance",
                      defaultMessage: "Distance",
                    })
                  : "Distance"
              }
              optionNames={
                intl.formatMessage
                  ? [
                      intl.formatMessage(
                        {
                          id: "inventory-filter.distance-option",
                          defaultMessage: "Within {distance} Miles",
                        },
                        { distance: 25 }
                      ),
                      intl.formatMessage(
                        {
                          id: "inventory-filter.distance-option",
                          defaultMessage: "Within {distance} Miles",
                        },
                        { distance: 40 }
                      ),
                      intl.formatMessage(
                        {
                          id: "inventory-filter.distance-option",
                          defaultMessage: "Within {distance} Miles",
                        },
                        { distance: 80 }
                      ),
                      intl.formatMessage({
                        id: "events.nationwide",
                        defaultMessage: "Nationwide",
                      }),
                    ]
                  : ["25 Miles", "40 Miles", "80 Miles", "Nationwide"]
              }
              optionValues={["25", "40", "80", "Nationwide"]}
              ariaControls="events-distance"
              hasSupportingInfoBelow
              handler={(e) => setDistance(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="btn-block-container">
            <Select
              id={"events-location"}
              value={eventLocation}
              label={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "events.inPersonOrVirtual",
                      defaultMessage: "Both",
                    })
                  : "Both"
              }
              optionNames={[
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "events.both",
                      defaultMessage: "In Person and Virtual",
                    })
                  : "In Person and Virtual",
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "events.inPerson",
                      defaultMessage: "In Person",
                    })
                  : "In Person",
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "events.virtual",
                      defaultMessage: "Virtual",
                    })
                  : "Virtual",
              ]}
              optionValues={["All Locations", "In Person", "Virtual"]}
              ariaControls="events-location"
              hasSupportingInfoBelow
              handler={(e) => userPrefs.set({ eventLocation: e.target.value })}
            />
          </div>
        </div>
        {Object.keys(eventCategories).length > 0 && (
          <div className="form-group">
            <span className="label-style">
              <FormattedMessage
                id="evfilter.type"
                defaultMessage="Type"
                description="Type"
              />
            </span>
            <div className="btn-grid-container event-categories">
              {renderEventCategories}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default EventsFilterControls;
