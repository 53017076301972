import React, { useState } from "react";
import PropTypes from "prop-types";

import GrandTitleParas from "../shared/GrandTitledParas/GrandTitledParas";
import HR from "../shared/HR/HR";

import "./SubscriptionServicePilot.scss";
import EnvironmentalAndGridBenefits from "./EnvironmentAndGridBenefits";

import chargingScheduleImage from "../../assets/images/chargingSchedule.png";
import chargingScheduleImageMN from "../../assets/images/chargingScheduleMN.png";
import { FormattedMessage } from "react-intl";
import ChargerCard from "../EasyChargingHome/ChargerCard/ChargerCard";
import chargers from "../EasyChargingHome/chargers/chargers";
import { MINNESOTA } from "../../../constants";

const SavePlusCo = ({ stateName }) => {
     const [ownCharger, setOwnCharger] = useState(false);
     return (
          <section className="subscription-service-pilot overview">
               <section className="container many-words charging-options">
                    <div className="row">
                         <GrandTitleParas
                              classNames="bill-credits"
                              colonHeader="Earn bill credits while charging your EV."
                              customID="oycBillCredits"
                         >
                              <p>
                                   <FormattedMessage
                                        id="oycChargingOffPeakHours"
                                        defaultMessage="Charging your EV during off-peak hours is ideal for a couple of reasons. It’s less expensive and uses more renewable energy, which makes the grid more resilient and reliable."
                                        description="Charging your EV during off-peak hours is ideal for a couple of reasons. It’s less expensive and uses more renewable energy, which makes the grid more resilient and reliable."
                                   />
                              </p>
                              <p>
                                   <FormattedMessage
                                        id="oycRewardOffPeakDrivers"
                                        defaultMessage="We’re rewarding EV drivers who agree to charge during off-peak hours."
                                        description="We’re rewarding EV drivers who agree to charge during off-peak hours."
                                   />
                              </p>
                              <p>
                                   <FormattedMessage
                                        id="oycSavingMoneyParticipate"
                                        defaultMessage="When it comes to saving money on charging, you can choose how you participate."
                                        description="When it comes to saving money on charging, you can choose how you participate."
                                   />
                              </p>
                         </GrandTitleParas>
                         <HR />
                         <GrandTitleParas
                              classNames="how-works"
                              colonHeader={
                                   <FormattedMessage
                                        id="howItWorks"
                                        defaultMessage="How It Works"
                                        description="How It Works"
                                   />
                              }
                         >
                              <EnvironmentalAndGridBenefits
                                   ownCharger={ownCharger}
                                   setOwnCharger={setOwnCharger}
                                   stateName={stateName}
                              />
                         </GrandTitleParas>
                         {ownCharger && (
                              <>
                                   {/* <HR />
              <GrandTitleParas
                classNames="charger-options"
                colonHeader={
                  <FormattedMessage
                    id="confirmChargerEligibility"
                    defaultMessage="Confirm Your Charger is Eligible"
                    description="Confirm Your Charger is Eligible"
                  />
                }
              >
                <div>
                  <p>
                    <FormattedMessage
                      id="chargerMustBe"
                      defaultMessage="The charger you own, or plan to buy, must be one of the following eligible options:"
                      description="The charger you own, or plan to buy, must be one of the following eligible options:"
                    />
                  </p>
                  <p>
                    <a
                      href={`/ChargePoint_Home_Flex.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ChargePoint Home Flex
                    </a>
                  </p>
                  <p>
                    <a
                      href={`/Enel_X_JuiceBox_48.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Enel X Juice Box
                    </a>
                  </p>
                  <p>
                    <FormattedMessage
                      id="chargersEnergyStarCertified"
                      defaultMessage="Both chargers are ENERGY STAR®-certified and UL Listed for safety and will be hardwired to a 240-volt circuit."
                      description="Both chargers are ENERGY STAR®-certified and UL Listed for safety and will be hardwired to a 240-volt circuit."
                    />
                  </p>
                  <p>
                    <i>
                      <FormattedMessage
                        id="noteXcelChargerDisclaimer"
                        defaultMessage="Note: Xcel Energy does not endorse one EV charger over the other."
                        description="Note: Xcel Energy does not endorse one EV charger over the other."
                      />
                    </i>
                  </p>
                  <p>
                    <FormattedMessage
                      id="teslaNeedsAdapter"
                      defaultMessage="Tesla owners will need an adapter."
                      description="Tesla owners will need an adapter."
                    />
                  </p>
                  <ChargerChoice chargers={chargers} />
                  <p>
                    <FormattedMessage
                      id="noOtherChargersCompatible"
                      defaultMessage="At this time, other chargers are not compatible with the program."
                      description="At this time, other chargers are not compatible with the program."
                    />
                  </p>
                </div>
              </GrandTitleParas>
              <HR /> */}
                                   <GrandTitleParas
                                        colonHeader="Choose Your Charging Schedule"
                                        customID="chooseYourChargingSchedule"
                                   >
                                        <p>
                                             {stateName === MINNESOTA ? (
                                                  <FormattedMessage
                                                       id="weProvideScheduleBestForGridMN"
                                                       defaultMessage="We offer two charging options that are best for the grid; you choose the schedule that works best for your driving needs. We reward you with a $50 annual bill credit."
                                                       description="Best Grid Schedule"
                                                  />
                                             ) : (
                                                  <FormattedMessage
                                                       id="weProvideScheduleBestForGrid"
                                                       defaultMessage="We provide several charging schedule options that are best for the grid; you choose the schedule that works best for your driving needs. If you need to charge outside of the schedule you selected occasionally, that is okay."
                                                       description="Best Grid Schedule"
                                                  />
                                             )}
                                        </p>
                                        <img
                                             src={
                                                  stateName === MINNESOTA
                                                       ? chargingScheduleImageMN
                                                       : chargingScheduleImage
                                             }
                                             alt="Charging Schedule"
                                        />
                                        <div className="table-responsive">
                                             <table className="confirmation">
                                                  <tr className="table-title">
                                                       <td colspan="3">
                                                            <GrandTitleParas
                                                                 colonHeader="Next, Confirm That Your Charger or Vehicle is Eligible."
                                                                 classNames="confirmChargerEligible"
                                                                 customID="confirmChargerEligible"
                                                            />
                                                       </td>
                                                  </tr>
                                                  <tr>
                                                       <td className="mobile-left">
                                                            <GrandTitleParas
                                                                 classNames="margin-bottom-remove"
                                                                 colonHeader="Eligible Charger"
                                                                 customID="eligibleCharge"
                                                            />
                                                       </td>
                                                       <td className="">
                                                            <GrandTitleParas
                                                                 classNames="margin-bottom-remove"
                                                                 colonHeader="OR"
                                                                 customID="eligibleChargersOr"
                                                            />
                                                       </td>
                                                       <td className="left-border">
                                                            <GrandTitleParas
                                                                 classNames="margin-bottom-remove"
                                                                 colonHeader="Eligible Electric Vehicles"
                                                                 customID="eligibleElectricVehicles"
                                                            />
                                                       </td>
                                                  </tr>
                                                  <tr>
                                                       <td className="top">
                                                            <div>
                                                                 <div style={{ paddingBottom: "20px" }}>
                                                                      {stateName === MINNESOTA ? (
                                                                           <FormattedMessage
                                                                                id="chargerYouOwnMN"
                                                                                defaultMessage="The charger you own, plan to buy, or receive by enrolling in EV Accelerate At Home must be one of the following eligible options:"
                                                                                description="Owned Charger"
                                                                           />
                                                                      ) : (
                                                                           <FormattedMessage
                                                                                id="chargerMustBeHardWired"
                                                                                defaultMessage="The charger must be hardwired to a 240-volt circuit and cannot be plugged into an outlet. See FAQ for more information. Tesla owners can use this charger with J1772 to NACS adapter. To ensure proper compatibility with the charger, a non-GFCI circuit is required."
                                                                                description="Owned Charger"
                                                                           />
                                                                      )}
                                                                 </div>
                                                                 <div>
                                                                      <a
                                                                           href={`/ChargePoint_Home_Flex.pdf`}
                                                                           target="_blank"
                                                                           rel="noopener noreferrer"
                                                                      >
                                                                           ChargePoint Home Flex
                                                                      </a>
                                                                      <div
                                                                           style={{
                                                                                paddingTop: "20px",
                                                                                paddingBottom: "20px",
                                                                           }}
                                                                      >
                                                                           <ChargerCard charger={chargers[0]} />
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </td>
                                                       <td className=""></td>
                                                       <td className="top">
                                                            <div style={{ textAlign: "left", paddingBottom: "20px" }}>
                                                                 <FormattedMessage
                                                                      id="youCanUseAnyCharger"
                                                                      defaultMessage="You can use any charger you would like, if you drive one of the following eligible EVs:"
                                                                 />
                                                            </div>
                                                            <div className="car-title">Hyundai</div>
                                                            <p className="dashed" style={{ lineHeight: "125%" }}>
                                                                 - Ioniq Plug-In Hybrid | 2018-2022
                                                            </p>
                                                            <p className="dashed">- Ioniq Electric | 2017-2021</p>
                                                            <p className="dashed">- Ioniq 5 | 2022-2025</p>
                                                            <p className="dashed">- Ioniq 6 | 2023-2025</p>
                                                            <p className="dashed">- Kona Electric | 2021-2025</p>
                                                            <p className="dashed">
                                                                 - Sonata Plug-In Hybrid | 2016-2019
                                                            </p>
                                                            <p className="dashed">
                                                                 - Tucson Plug-In Hybrid | 2022-2025
                                                            </p>
                                                            <p className="dashed">
                                                                 - Santa Fe Plug-In Hybrid | 2022-2025
                                                            </p>

                                                            <div className="car-title">Kia</div>
                                                            <p className="dashed">- Niro EV | 2019-2025</p>
                                                            <p className="dashed">- Niro Plug-In Hybrid | 2018-2025</p>
                                                            <p className="dashed">
                                                                 - Optima Plug-In Hybrid | 2017-2025
                                                            </p>
                                                            <p className="dashed">
                                                                 - Sorento Plug-In Hybrid | 2022-2025
                                                            </p>
                                                            <p className="dashed">- Soul EV | 2014-2019</p>
                                                            <p className="dashed">- EV6 | 2022-2025</p>
                                                            <p className="dashed">- EV9 | 2024-2025</p>
                                                            <p className="dashed">- Sportage | 2023-2025</p>

                                                            <div className="car-title">Lexus</div>
                                                            <p className="dashed">- NX 450H+ | 2022-2024</p>
                                                            <p className="dashed">- RX 450H | 2024</p>
                                                            <p className="dashed">- RZ 300e | 2024</p>
                                                            <p className="dashed">- RZ 450e | 2023 - 2024</p>

                                                            <div className="car-title">Tesla</div>
                                                            <p className="dashed">- Cybertruck | 2024-2025</p>
                                                            <p className="dashed">- Model 3 | 2017-2025</p>
                                                            <p className="dashed">- Model S | 2012-2024</p>
                                                            <p className="dashed">- Model X | 2012-2025</p>
                                                            <p className="dashed">- Model Y | 2020-2025</p>

                                                            <div className="car-title">Toyota</div>
                                                            <p className="dashed">
                                                                 - Prius Prime | Advanced | 2017-2022
                                                            </p>
                                                            <p className="dashed" style={{ lineHeight: "125%" }}>
                                                                 - Prius Prime | LE, XLE, Limited | 2020-2024
                                                            </p>
                                                            <p className="dashed">- RAV4 Prime | XSE | 2021-2024</p>
                                                            <p className="dashed">- Prius Prime | 2023</p>
                                                            <p className="dashed">- bZ4X | 2023-2024</p>
                                                            <br />
                                                            <div className="car-title">
                                                                 <FormattedMessage
                                                                      id="dontSeeYourCarOptimize"
                                                                      defaultMessage="Don’t see your car?"
                                                                 />
                                                            </div>
                                                            <p className="dashed">
                                                                 <FormattedMessage
                                                                      id="newVehiclesComingOptimize"
                                                                      defaultMessage="New Vehicles are coming online soon!"
                                                                 />
                                                            </p>
                                                       </td>
                                                  </tr>
                                                  <tr>
                                                       <div className="enroll-box">
                                                            <FormattedMessage
                                                                 id="onceEnrolled"
                                                                 defaultMessage="Once you are enrolled and charging on this schedule, we
                            reward you with a $50 annual bill credit for every year you
                            participate."
                                                                 description="Once Enrolled"
                                                            />
                                                            <div className="text-center enroll-save-plus">
                                                                 <button className="btn btn-ae btn-xcel">
                                                                      <a
                                                                           href={
                                                                                stateName === MINNESOTA
                                                                                     ? "https://my.xcelenergy.com/MyAccount/s/ev?_ga=2.122679563.1218011883.1669135152-1221231883.1665766645"
                                                                                     : "https://my.xcelenergy.com/MyAccount/s/ev"
                                                                           }
                                                                           target="_blank"
                                                                           rel="noopener noreferrer"
                                                                      >
                                                                           <FormattedMessage
                                                                                id="enrollNow"
                                                                                defaultMessage="Enroll Now"
                                                                                description="Enroll Now"
                                                                           />
                                                                      </a>
                                                                 </button>
                                                                 {/* <p>
                                          View Terms & Conditions here. (Missing Link)
                                   </p> */}
                                                            </div>
                                                       </div>
                                                  </tr>
                                             </table>
                                        </div>
                                   </GrandTitleParas>
                              </>
                         )}
                    </div>
               </section>
          </section>
     );
};

export default SavePlusCo;

SavePlusCo.propTypes = {
     stateName: PropTypes.string,
     enrollURL: PropTypes.string,
};
