import React from "react";
import PropTypes from "prop-types";
// import GaTracker from "../../../utils/GaTracker/GaTracker"
import { Link } from "react-router-dom";

const ProgramBenefits = ({enrollURL, stateName}) => {

    // const handleEnrollClick = () => {
    //     GaTracker.trackEvent({
    //         category: "Xcel Domain Links",
    //         action: "Clicked Xcel Domain Link",
    //         label: `Enroll in Subscription Service Pilot ${stateName}`,
    //     });
    // };

    return (
        <>
            <ul>
                <li>Unlimited EV Charging overnight (from 9 p.m. to 9 a.m.) for one, low flat monthly price.</li>
                <li>Hassle-free installation of a level 2 charger for faster, more convenient charging.</li>
            </ul>

            {/* <div className="text-center enroll-button">
                <a 
                    href={enrollURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleEnrollClick}
                >
                    <button className="btn btn-ae btn-xcel">
                        Enroll Today
                    </button>
                </a>
            </div> */}

            <div className="enroll-blurb">
                <p>The EV Subscription Service Pilot program is currently full and we are unable to accept more applicants. Please view our&nbsp;
                    <Link to="/ev-accelerate-at-home-mn">
                        Accelerate at Home charging program
                    </Link>
                .
                </p>
            </div>
        </>
    )
}

export default ProgramBenefits;

ProgramBenefits.propTypes = {
    enrollURL: PropTypes.string
}