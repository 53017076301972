import React from "react";

const ChargingStationClusterMarker = ({
  cluster: {
    id,
    geometry: {
      coordinates: [longitude, latitude]
    },
    properties: {
      point_count: pointCount
    }
  },
  supercluster,
  map,
}) => {
  return (
    <div
      className="google-map-cluster-marker"

      onClick={() => {
        const expansionZoom = Math.min(
          supercluster.getClusterExpansionZoom(id),
          20
        );
        map.setZoom(expansionZoom);
        map.panTo({ lat: latitude, lng: longitude });
      }}
      >
        <span className="text"> {pointCount}</span>
      </div>
  );
};

export default ChargingStationClusterMarker;
