import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import "./Inventory.scss";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import fetchInventory from "../../services/fetchInventory";
import {fetchDealersType} from "../../services/fetchDealers";
import InventoryFilterControls from "../../components/InventoryFilterControls/InventoryFilterControls";
import { FormattedMessage } from "react-intl";

import InventoryDealersFilter from "../../components/InventoryDealersFilter/InventoryDealersFilter";
import InventoryCatalog from "../../components/InventoryCatalog/InventoryCatalog";
import EvSortControls from "../../components/EvSortControls/EvSortControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";

const Inventory = ({ electricVehicles, dealerLocations, ip, uuid }) => {
  const userPrefs = useContext(UserPrefsContext);
  const [inventory, setInventory] = useState(null);

  const zipcode = userPrefs.get("zipcode");
  const distance = userPrefs.get("vehicleDistanceFilter");

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      const params = {
        postcode: zipcode,
      };

      if (distance) {
        params.distance = distance;
      }else{
        params.distance = 2500;
      }

      try {
        const dealersTypes = await fetchDealersType();
        const invent = await fetchInventory(params);

        const dealers = invent.map((ev) => {
          const foundDealer = dealersTypes.find(
            (d) =>
              d.dealerId === ev.dealer.dealer_id ||
              d.dealershipHandle === ev.dealer.dealership_handle
          );

          if (foundDealer) {
            ev.dealer.type = foundDealer.type;
          }

          return ev;
        });

        if (!ignore) setInventory(dealers);
      } catch (e) {
        console.log(e, "Failed to load inventory");
      }
    }

    fetchData();
    document.title = process.env.REACT_APP_PAGES_INVENTORY_TITLE;

    return () => {
      ignore = true;
    };
  }, [zipcode, distance]);

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <InventoryFilterControls vehicles={inventory} zipcode={zipcode}/>
      <br />
      <InventoryDealersFilter inventory={inventory} zipcode={zipcode}/>
    </>
  );

  const modalButtonText = (
    <FormattedMessage
      id="inventory.filters-modal-button-text"
      defaultMessage="Inventory Filters"
      description="Inventory Filters"
    />
  );

  return (
    <section className="container inventory">
      <div className="row mb-3">
        <div className="col-sm-12 text-center">
          <h2>
            <FormattedMessage
              id="browseNearbyVehicles"
              defaultMessage="Browse Nearby Vehicles"
              description="Browse Nearby Vehicles Title"
            />
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="pull-right">
            <EvSortControls inventory />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent
            buttonText={modalButtonText}
            titleText={modalButtonText}
          >
            {renderOptions}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="electric-vehicles-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>Results have been updated as of {time}.</span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <InventoryCatalog vehicles={inventory} />
        </div>
      </div>
    </section>
  );
};

export default Inventory;

Inventory.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
