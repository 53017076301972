import React from "react";
import ServiceSocketOne from "../../../assets/images/socket-service-1.png";
import ServiceSocketTwo from "../../../assets/images/socket-service-2.png";
import ServiceSocketThree from "../../../assets/images/socket-service-3.png";
import { FormattedMessage } from "react-intl";

const MeterDiagrams = () => {
    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <p>
                    <FormattedMessage
                        id="MNTimeofDaySeparateMeter_meterdiagrams_text"
                        defaultMessage="The meter diagrams provide a visual guide for how the additional meter may connect to your current service. We recommend working with your electrician to connect in a way that makes sense for your location."
                      />
                    </p>
                <p className="text-center imgBucket">
                    <img
                        src={ServiceSocketOne}
                        alt="Service Socket Diagram"
                        style={{maxWidth: "100%"}}
                    />
                </p>
                <p className="text-center imgBucket push-left">
                    <img
                        src={ServiceSocketTwo}
                        alt="Service Socket Diagram"
                        style={{maxWidth: "100%"}}
                    />
                </p>
                <p className="text-center imgBucket">
                    <img
                        src={ServiceSocketThree}
                        alt="Service Socket Diagram"
                        style={{maxWidth: "100%"}}
                    />
                </p>
            </div>
        </div>
    )
}

export default MeterDiagrams;