// parameters will be an array of objects. each object will have a field, value, count and sort
// sortFunction is a function to sort the Data with

const getFilteredData = (parameters, data) => {

  let finalData = data

  if(data) {
    finalData = parameters.map(({field, value, count, sortFunction}) => {
      let filteredData = data.filter(element => {
        if (value === true) {
          return element[field] !== undefined
        } else if (value === false) {
          return false
        } else {
          return element[field] === value
        }
      })

      if (sortFunction) {
        sortFunction(filteredData)
      }

      if (count) {
        filteredData.splice(count)
      }
      return filteredData
    })
  }

  return [].concat.apply([], finalData)
}

export default getFilteredData
