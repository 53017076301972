import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./usedEVs.scss";

import UsedEVCatalog from "../../client_customizations/components/UsedEVCatalog/UsedEVCatalog"
import MatchScoreOptions from "../../components/MatchScoreOptions/MatchScoreOptions"
import EvSortControls from "../../components/EvSortControls/EvSortControls"

import EVFilterControls from "../../components/EVFilterControls/EVFilterControls"
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent"
import { NavLink } from "react-router-dom";

import { FormattedMessage, useIntl } from 'react-intl';

const UsedEVs = ({ usedElectricVehicles, ip, uuid, page }) => {
  const intl = useIntl()

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_USED_VEHICLES_TITLE;
  });

  const evToggle = (page) => {
    return (
      <div className="ev-toggle">
        <NavLink
          title={page === "vehicles" ? "Active Page" : null}
          to="/vehicles"
        >
          <span>
            <FormattedMessage
              id="new"
              defaultMessage="New"
              description="Electric Vehicles Toggle"
            />
          </span>
        </NavLink>
        <NavLink
          title={page === "vehicles" ? "Active Page" : null}
          to="/used-vehicles"
        >
          <span>
            <FormattedMessage
              id="preOwned"
              defaultMessage="Pre-Owned"
              description="Electric Vehicles Toggle PreOwned"
            />
          </span>
        </NavLink>
      </div>
    )
  }

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      {evToggle(page)}
      <MatchScoreOptions />
      <br />
      <EVFilterControls vehicles={usedElectricVehicles} usedEvs={true} />
    </>
  );


  return (
    <section className="container-fluid" id="EVs">
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12 text-center">
            <h2>
              <FormattedMessage
                id="preOwnedElectricVehicles"
                defaultMessage="Pre-Owned Electric Vehicles"
                description="Pre-Owned Electric Vehicles"
              />
            </h2>
            <p className="lead">
              <FormattedMessage
                id="preOwnedElectricVehiclesSubHeader"
                defaultMessage="View pre-owned electric vehicles based on your budget, driving habits, and age and type of vehicle. Click on a vehicle to learn about range, charge time, total cost of ownership, and more."
                description="Pre-Owned Electric Vehicles Sub Header"
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 d-block d-lg-none text-center">
            <ModalComponent
              buttonText={intl.formatMessage ? intl.formatMessage({ id: "evs.buttonMatchScoreAndFilters", defaultMessage: "Match Score and Filters" }) : "Match Score and Filters"}
              titleText={intl.formatMessage ? intl.formatMessage({ id: "evs.matchScoreAndFilters", defaultMessage: "Refine Match Score and Filters" }) : "Refine Match Score and Filters"}
            >
              {renderOptions}
            </ModalComponent>
            <br />
          </div>
          <div className="col-xl-3 col-lg-4 d-none d-lg-block">
            {renderOptions}
          </div>
          <div
            id="electric-vehicles-catalog"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: "absolute", top: "-9999px" }}
          >
            <span>Results have been updated as of {time}.</span>
          </div>
          <div
            className="col-xl-9 col-lg-8 col-sm-12"
            style={{ padding: "0px" }}
          >
            <div className="row">
              <div className="col">
                <div className="pull-right">
                  <EvSortControls />
                </div>
              </div>
            </div>
            <UsedEVCatalog
              vehicles={usedElectricVehicles}
              hasEvDetails
              hasIncentivesAndMatchScore
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default UsedEVs;

UsedEVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};