const filterWithinBounds = (things, bounds) => {
  const withinCurrentBounds = (lat, lng) => {
    const {
      nw: { lng: left, lat: top},
      se: { lng: right, lat: bottom }
    } = bounds;

    if (!top || !bottom || !left || !right) {
      return false;
    }
    if (top >= lat && lat >= bottom) {
      if (left <= right && left <= lng && lng <= right) {
        return true;
      } else if (left > right && (left <= lng || lng <= right)) {
        return true;
      }
    }
    return false;
  };

  return (things || []).filter(thing => {
    const lat = thing.latitude || thing.lat;
    const lng = thing.longitude || thing.lng;
    return lat && lng ? withinCurrentBounds(lat, lng) : false;
  });
}

export default filterWithinBounds;
