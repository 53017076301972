import React from "react";
import PropTypes from "prop-types";
import LinkCard from "../../../../components/LinkCard/LinkCard";
import BROWSE_VEHICLES from "../../../assets/images/icons/browse-electric-vehicles.svg"
import DISCOVER_INCENTIVES from "../../../assets/images/icons/discover-incentives.svg";
import LOCATE_CHARGING_STATIONS from "../../../assets/images/icons/locate-charging-stations.svg";
import { FormattedMessage, useIntl } from 'react-intl';


const HomepageBigPromise = ({
  homePageBannerImage,
  userLocation
}) => {
  const activeLocations = ["Colorado", "Minnesota", "New Mexico", "Wisconsin"] 
  const intl = useIntl()
  const linkCards = [
    { title: intl.formatMessage ? intl.formatMessage({ id: "browseElectricVehicles", defaultMessage: "Browse Electric Vehicles" }) : "Browse Electric Vehicles" , image: BROWSE_VEHICLES, alt: "Electric Vehicle", link: "/vehicles", key: "Electric Vehicles" },
    { title: intl.formatMessage ? intl.formatMessage({ id: "discoverIncentivesAndRebates", defaultMessage: "Discover Incentives and Rebates" }) : "Discover Incentives and Rebates", image: DISCOVER_INCENTIVES, alt: "Money Bag", link: "/incentives", key: "Incentives" },
    activeLocations.includes(userLocation?.region) 
      ? { title: intl.formatMessage ? intl.formatMessage({ id: "header.EVChargingProgram", defaultMessage: "EV Charging Programs" }) : "EV Charging Programs", image: LOCATE_CHARGING_STATIONS, alt: "Charging Station", link: "/ev-charging-programs", key: "Charging Programs" }
      : { title: intl.formatMessage ? intl.formatMessage({ id: "publicCharging", defaultMessage: "Public Charging" }) : "Public Charging", image: LOCATE_CHARGING_STATIONS, alt: "Charging Station", link: "/charging-stations", key: "Public Charging" }
    ]

  const renderLinkCards = linkCards.map(lc => <LinkCard title={lc.title} image={lc.image} alt={lc.alt} scrollTo={lc.scrollTo} link={lc.link} key={lc.key} />)

  return (
    <section
      className="container-fluid homepage-jumbotron"
      style={{
        backgroundImage: "url(" + homePageBannerImage + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
        padding: 0,
        textAlign: "center"
      }}
    >
      <div className="big-promise">
        <h1 className="mt-4">
          <FormattedMessage
            id="discoverHowEasy"
            defaultMessage="Discover how easy"
            description="Discover how easy"
          />
          <br />
          <FormattedMessage
            id="itIsToDriveElectric"
            defaultMessage="it is to drive electric"
            description="it is to drive electric"
          />
        </h1>
      </div>
      <div id="link-card-buttons">
        <div className="row">
          <div className="col">
            <div className="link-card-container">
              {renderLinkCards}
            </div>
          </div>
        </div>
      </div>
    </section>

    // </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
  userLocation: PropTypes.string
}
