import React from "react";
import PropTypes from "prop-types";

import ProgramBenefits from "./ProgramBenefits";
import EligibleEnroll from "./EligibleEnroll";
import HowWorks from "./HowWorks";
import ChargerOptions from "./ChargerOptions";
import ChargerApp from "./ChargerApp";
import PaymentPricing from "./PaymentPricing";
import RenewableEnergy from "./RenewableEnergy";

import GrandTitleParas from "../shared/GrandTitledParas/GrandTitledParas"
import HR from "../shared/HR/HR";

import "./SubscriptionServicePilot.scss";

const renderEnrollmentDetails = (stateName, enrollURL) => {
    return (
        <section className="container many-words">
            <div className="row">
                <GrandTitleParas
                    classNames="program-benefits"
                    colonHeader="Program Benefits:"
                >
                    <ProgramBenefits
                        enrollURL={enrollURL}
                        stateName={stateName}
                    />
                </GrandTitleParas>
                <HR/>
                <GrandTitleParas
                    classNames="eligible-enroll"
                    colonHeader="To be eligible to participate in the Subscription Service pilot, you:"
                >
                    <EligibleEnroll 
                        stateName={stateName}
                    />
                </GrandTitleParas>
                <HR/>
                <GrandTitleParas
                    classNames="how-works"
                    colonHeader="How it Works"
                >
                    <HowWorks />
                </GrandTitleParas>
                <HR/>
                <GrandTitleParas
                    classNames="charger-options"
                    colonHeader="Charger Options"
                >
                    <ChargerOptions />
                </GrandTitleParas>
                <HR/>
                <GrandTitleParas
                    classNames="charger-mobile-app"
                    colonHeader="Charger Mobile App"
                >
                    <ChargerApp />
                </GrandTitleParas>
                <HR/>
                <GrandTitleParas
                    classNames="payment-pricing"
                    colonHeader="Payment and Pricing"
                >
                    <PaymentPricing />
                </GrandTitleParas>
                <HR/>
                <GrandTitleParas
                    classNames="renewable-energy"
                    colonHeader="Charge with 100% Renewable Energy"
                >
                    <RenewableEnergy />
                </GrandTitleParas>
                <HR />
            </div>
        </section>
    )
}

const SubscriptionServicePilot = ({stateName, enrollURL}) => {
    return (
        <section className="subscription-service-pilot overview">
            {renderEnrollmentDetails(stateName, enrollURL)}
        </section>
    )
}

export default SubscriptionServicePilot;

SubscriptionServicePilot.propTypes = {
    stateName: PropTypes.string,
    enrollURL: PropTypes.string
}