import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

import EventCard from "../EventCard/EventCard"
import sortEvents from "../../functions/events/Sort/sortEvents"
import filterEvents from "../../functions/events/Filter/filterEvents"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { FormattedMessage } from 'react-intl';

const EventCatalog
  = ({
    events,
    eventCategories
  }) => {
    const userPrefs = useContext(UserPrefsContext);

    if (!events) return <LoadingSpinner />;


    // Filter Events Here
    const filteredEvents = filterEvents(events, {
      eventLocation: userPrefs.get("eventLocation"),
      eventCategories: eventCategories
    });


    if (filteredEvents.length === 0) {
      return (
        <div className="available-nearby">
          <div className="no-matches text-center">
            <h2>
              <FormattedMessage
                id="noMatchesFound"
                defaultMessage="We didn't find any matches."
                description="We didn't find any matches."
              />
              </h2>
            <p className="h4">
            <FormattedMessage
                id="noEventsFound"
                defaultMessage="No events matching your current search criteria can be found. Try changing your search criteria"
                description="No Events Found Matching Search Criteria"
              />
              </p>
          </div>
        </div>
      )
    }

    const filteredAndSortedEvents = sortEvents(
      filteredEvents,
      userPrefs.get("eventsSortType"),
    );

    const renderCards = filteredAndSortedEvents.map((event, index) => {
      return (
        <EventCard
          event={event}
          key={index}
        />
      );
    });

    return <div className="render-cards-container">{renderCards}</div>;
  };

export default EventCatalog
  ;

EventCatalog
  .propTypes = {
  events: PropTypes.array,
};
