import React from "react";

import OneTenOne from "../OneTenOne/OneTenOne";

const HR = () => {
    return(
        <section className="container no-pad">
            <OneTenOne
                className="wthin-major-section-hr"
            >                
                <hr />
            </OneTenOne>
        </section>
    )
}

export default HR