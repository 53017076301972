import React from "react";
import PropTypes from "prop-types";

import HowToGetStarted from "./HowToGetStarted"
import AboutChargingPerks from "./AboutChargingPerks"
import ThePerks from "./ThePerks"
import HowItWorks from "./HowItWorks"

import GrandTitleParas from "../shared/GrandTitledParas/GrandTitledParas"
import HR from "../shared/HR/HR";

import "./ChargingPerksPilot.scss";

const renderEnrollmentDetails = (stateName, enrollURL) => {
    return (
        <section className="container many-words">
            <div className="row">
                <GrandTitleParas
                    classNames="how-to-get-started"
                    colonHeader="How To Get Started"
                >
                    <HowToGetStarted />
                </GrandTitleParas>
                <HR/>
                <GrandTitleParas
                    classNames="about-charging-perks"
                    colonHeader="About the Charging Perks Pilot"
                >
                    <AboutChargingPerks />
                </GrandTitleParas>
                <HR/>
                <GrandTitleParas
                    classNames="the-perks"
                    colonHeader="The Perks"
                >
                    <ThePerks />
                </GrandTitleParas>
                <HR/>
                <GrandTitleParas
                    classNames="how-it-works"
                    colonHeader="How it Works"
                >
                    <HowItWorks />
                </GrandTitleParas>
            </div>
        </section>
    )
}

const ChargingPerksPilot = () => {
    return (
        <section className="charging-perks">
            {renderEnrollmentDetails()}
        </section>
    )
}

export default ChargingPerksPilot;

ChargingPerksPilot.propTypes = {
    stateName: PropTypes.string,
    enrollURL: PropTypes.string
}