import React, { useContext } from "react";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import SelectVehicleMakeFilter from "../InputComponents/SelectVehicleMakeFilter/SelectVehicleMakeFilter";
import SelectVehicleModelFilter from "../SelectVehicleModelFilter/SelectVehicleModelFilter";
import SelectVehicleModelYearFilter from "../SelectVehicleModelYearFilter/SelectVehicleModelYearFilter";
import SelectVehicleDistanceFilter from "../SelectVehicleDistanceFilter/SelectVehicleDistanceFilter";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';

import "./InventoryFilterControls.scss";
import InputZipcodeForDealersMap from "../InputComponents/InputZipcode/InputZipcodeForDealersMap";

const InventoryFilterControls = ({ vehicles, zipcode }) => {
  const userPrefs = useContext(UserPrefsContext);

  const vehicleCondition = userPrefs.get("vehicleCondition") || "All";

  const vehicleConditionsValues = ["All", "New", "Used"];
  const vehicleConditionsLabels = [
    <FormattedMessage
      id="inventory-filter.all"
      defaultMessage="All"
      description="All"
    />,
    <FormattedMessage
      id="inventory-filter.new"
      defaultMessage="New"
      description="New"
    />,
    <FormattedMessage
      id="inventory-filter.used"
      defaultMessage="Used"
      description="Used"
    />,
  ];

  const renderVehicleConditions = vehicleConditionsValues.map((vc, idx) => {
    return (
      <button
        className={`ev-condition-button ${
          vehicleCondition === vc ? "active" : null
        }`}
        key={vc}
        type="button"
        value={vehicleConditionsLabels[idx]}
        onClick={(e) => {
          userPrefs.set({ vehicleCondition: vc, vehicleAgeFilter: "All" });
        }}
      >
       { vehicleConditionsLabels[idx]}
      </button>
    );
  });

  return (
    <div className="input-well inventory-filter-controls">
      <p className="h2">
        <FormattedMessage
          id="inventory-filter.filter-vehicles"
          defaultMessage="Filter Vehicles"
          description="Filter Vehicles"
        />
      </p>
      <form>
        <div className="d-flex justify-content-between button-group">
          {renderVehicleConditions}
        </div>

        <div className="form-group">
          <SelectVehicleMakeFilter vehicles={vehicles} />
          <SelectVehicleModelFilter vehicles={vehicles} />
          <SelectVehicleModelYearFilter vehicles={vehicles} />
          <SelectVehicleDistanceFilter vehicles={vehicles} />
          <InputZipcodeForDealersMap zipcode={zipcode} />
        </div>
        {/* <div className="form-group">
          <SelectVehicleAgeFilter />
        </div> */}
      </form>
    </div>
  );
};

export default InventoryFilterControls;

InventoryFilterControls.propTypes = {
  vehicles: PropTypes.array,
};
