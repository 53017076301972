import React from 'react';
import { FormatAsDollars } from '../../../utils/Helpers/Format';
import ChargerCostTooltip from './ChargerCostTooltip';
import { FormattedMessage } from 'react-intl';

const TotalRow = ({ children, className }) => {
  return <div className={`d-flex justify-content-between ${className}`}>{children}</div>
};

const TotalCol = ({ columns, children, backgroundColor = "#f6f8fb" }) => {
  const width = parseInt(100 / columns);
  // We have to calculate the padding dynamically to ensure that the columns
  // line up with the ChartJS generated bars. textAlign and backgroundColor
  // are also defined here instead of in the SCSS so that a single element
  // doesn't have its styling split between two files.
  const style = {
    width: `${width}%`,
    margin: `0 ${width * 0.15}%`,
    textAlign: "center",
    backgroundColor,
    padding: "0.5rem 0.25rem",
  };

  return <div style={style}>{children}</div>
};

const RateTotals = ({ rateTotals }) => {
  const minUpfrontChargerCost = Math.min(
    ...rateTotals
      .filter(t => t.canDetermineUpfrontCosts)
      .map(t => t.upfront.chargerPlusInstall)
  );

  return <div className="RateTotals">
    <TotalRow>
      {rateTotals.map((rateTotal, idx) => <TotalCol key={rateTotal.id} columns={rateTotals.length} backgroundColor={idx === 0 ? 'none' : undefined}>
        {rateTotal.canDetermineUpfrontCosts && (
          <>
            <div className="title">
              <FormattedMessage
                id="charger"
                defaultMessage="CHARGER"
                description="CHARGER"
              />&nbsp;
              +
              <br />
              <FormattedMessage
                id="install"
                defaultMessage="INSTALL"
                description="INSTALL"
              />
              {!rateTotal.isBundled && (
                <ChargerCostTooltip id={`charger-tooltip-${rateTotal.id}`} meterCost={rateTotal.upfront.meter} />
              )}
            </div>
            <div className={`h2 mb-0${rateTotal.upfront.chargerPlusInstall === minUpfrontChargerCost ? " lowest" : ""}`}>
              {FormatAsDollars(rateTotal.upfront.chargerPlusInstall)}
            </div>
          </>
        )}
      </TotalCol>)}
    </TotalRow>
    <TotalRow className="mb-3">
      {rateTotals.map((rateTotal, idx) => <TotalCol key={rateTotal.id} columns={rateTotals.length} backgroundColor={idx === 0 ? 'none' : undefined}>
        {rateTotal.canDetermineUpfrontCosts && (
          <>
          </>
        )}
      </TotalCol>)}
    </TotalRow>

    <TotalRow>
      {rateTotals.map(rateTotal => <TotalCol key={rateTotal.id} columns={rateTotals.length}>
        <div className="title">
          <FormattedMessage
            id="overTenYears"
            defaultMessage="OVER 10 YEARS"
            description="OVER 10 YEARS"
          />
          
        </div>
        <div className="h2 mb-0">{FormatAsDollars(rateTotal.tenYear)}</div>
      </TotalCol>)}
    </TotalRow>
  </div>
};

export default RateTotals;
