import { times } from "lodash";
import {
  AFTER_9PM_BEFORE_9AM,
  AFTER_6PM_BEFORE_9AM,
  AFTER_2PM_BEFORE_6PM,
  AFTER_MIDNIGHT_BEFORE_8AM,
  AFTER_8AM_BEFORE_MIDNIGHT,
  AFTER_12PM_BEFORE_8PM,
  AFTER_10PM_BEFORE_11AM,
  AFTER_11AM_BEFORE_10PM,
  AFTER_9PM_BEFORE_6AM,
  AFTER_1230AM_BEFORE_930AM,
  AFTER_6AM_BEFORE_3PM,
  ALL_HOURS
} from '../constants/';

const DST = {
  2018: {
    startHour: 1658,
    endHour: 7370,
  },
  2019: {
    startHour: 1634,
    endHour: 7346,
  },
};

const dstAdjustedHour = (hour, year) => {
  const { startHour, endHour } = DST[year];
  const isWithinDST = hour >= startHour && hour <= endHour;
  return isWithinDST ?
    (hour + 1) % 24 :
    hour % 24;
}

const chargingPatterns = [
  {
    id: AFTER_6AM_BEFORE_3PM,
    title: "After 6am; before 3pm",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        return hour >= 6 && hour < 15 ? 1.1 : 0;
      }))
    },
    isOptimizingCharge: true,
  },
  {
    id: AFTER_8AM_BEFORE_MIDNIGHT,
    title: "After 8am; before midnight",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        return hour >= 8 ? 1.1 : 0;
      })),
    },
  },
  {
    id: AFTER_11AM_BEFORE_10PM,
    title: "After 11am; before 10pm",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        return hour >= 11 && hour < 22 ? 1.1 : 0;
      })),
    },
  },
  {
    id: AFTER_12PM_BEFORE_8PM,
    title: "After 12p; before 8p",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        return hour >= 12 && hour < 20 ? 1.1 : 0;
      })),
    },
  },
  {
    id: AFTER_2PM_BEFORE_6PM,
    title: "After 2pm; before 6pm",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        return hour >= 14 && hour < 18 ? 1.1 : 0;
      })),
    },
  },
  {
    id: AFTER_6PM_BEFORE_9AM,
    title: "After 6pm; before 9pm",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        return hour >= 18 || hour < 9 ? 1.1 : 0;
      })),
    },
  },
  {
    id: AFTER_9PM_BEFORE_6AM,
    title: "After 9pm; before 6am",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        return hour >= 21 || hour < 6 ? 1.1 : 0;
      }))
    },
    isOptimizingCharge: true,
  },
  {
    id: AFTER_9PM_BEFORE_9AM,
    title: "After 9pm; before 9am",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        return hour >= 21 || hour < 9 ? 1.1 : 0;
      })),
    },
  },
  {
    id: AFTER_10PM_BEFORE_11AM,
    title: "After 10pm; before 11am",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        return hour >= 22 || hour < 11 ? 1.1 : 0;
      })),
    },
  },
  {
    id: AFTER_MIDNIGHT_BEFORE_8AM,
    title: "After midnight; before 8am",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        return hour < 8 ? 1.1 : 0;
      })),
    },
  },
  {
    id: AFTER_1230AM_BEFORE_930AM,
    title: "After 12:30am; before 9:30am",
    loadProfile: {
      data: (year) => (times(8760, (i) => {
        const hour = dstAdjustedHour(i, year);
        if (hour === 0) return 1.1 / 2;
        if (hour === 9) return 1.1 / 2;
        return hour >= 1 && hour < 9 ? 1.1 : 0;
      }))
    },
    isOptimizingCharge: true,
  },
  {
    id: ALL_HOURS,
    title: "All hours (unpredictable)",
    loadProfile: {
      data: () => times(8760, (_) => 1.1),
    },
  },
];

export default chargingPatterns;
