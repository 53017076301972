import React from "react";

import PropTypes from "prop-types";
import "./ContentCenter.scss";
import { FormattedMessage } from "react-intl";

const ContentCenter = ({
  children,
  section,
  pageHeaderLead,
  pageHeaderLeadId,
  pageHeaderHeadLine,
  pageHeaderHeadLineId,
  pageHeaderSubHeading,
  pageHeaderSubHeadingId,
  pageHeaderSubHeadingTwo,
  pageHeaderSubHeadingIdTwo,
  sectionStyle,
  disclaimer,
}) => {
  return (
    <section id={section} className="container-fluid" style={sectionStyle}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="pageHeaderContainer text-center">
              <div className="pageHeaderLead">
                {pageHeaderLeadId ? (
                  <FormattedMessage
                    id={pageHeaderLeadId}
                    defaultMessage={pageHeaderLead}
                    description={pageHeaderLead}
                  />
                ) : (
                  pageHeaderLead
                )}
              </div>
              <div className="pageHeaderHeadLine">
                {pageHeaderHeadLineId ? (
                  <FormattedMessage
                    id={pageHeaderHeadLineId}
                    defaultMessage={pageHeaderHeadLine}
                    description={pageHeaderHeadLine}
                  />
                ) : (
                  pageHeaderHeadLine
                )}
              </div>
              <div className="pageHeaderSubHeading offset-sm-1 col-sm-offset-1 col-sm-10">
                {pageHeaderSubHeadingId ? (
                  <FormattedMessage
                    id={pageHeaderSubHeadingId}
                    defaultMessage={pageHeaderSubHeading}
                    description={pageHeaderSubHeading}
                  />
                ) : (
                  pageHeaderSubHeading
                )}
              </div>


              {pageHeaderSubHeadingIdTwo && (
                     <div className="pageHeaderSubHeading offset-sm-1 col-sm-offset-1 col-sm-10">
                            <FormattedMessage
                            id={pageHeaderSubHeadingIdTwo}
                            defaultMessage={pageHeaderSubHeadingTwo}
                            description={pageHeaderSubHeadingTwo}
                            values={{
                              lineBreak: <p style={{lineHeight: "22px"}}></p>
                            }}
                            />
                     </div>
            )}
       </div>

            <div className="row my-4 d-flex">
              <div className="col-sm-12">{children}</div>
            </div>
            <div className="text-center">{disclaimer}</div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContentCenter;

ContentCenter.propTypes = {
  children: PropTypes.node.isRequired,
  section: PropTypes.string,
  sectionStyle: PropTypes.object,
  pageHeaderLead: PropTypes.node,
  pageHeaderHeadLine: PropTypes.node,
  pageHeaderSubHeading: PropTypes.node,
  disclaimer: PropTypes.node,
};
