import React from "react";

// import RateTable from "../../assets/images/graphs/rate-table.svg";

const PaymentPricing = () => {
    return (
        <>
            <p>
            Your <span className="bold-span">flat monthly charge of $42.50</span> is a low-cost option that includes rental, installation and maintenance of the charger, data and service fees, energy charges for overnight and weekend charging, and riders.
            </p>
            <p>
            Prefer to own the charger yourself by prepaying for the equipment and installation?
            </p>
            <p>
            You can opt to prepay for the charger and installation in a lump-sum amount. If you choose this option, we’ll issue a separate bill for the <span className="bold-span">upfront cost of $770</span> for the charger and installation. <span className="bold-span">Your monthly charge of $32.65</span> includes the maintenance of the charger, data and service fees, energy charges for overnight and weekend charging, and utility rider and fees. Standard city and state fees are excluded but will be applied to your bill.
            </p>

            <table>
                <thead>
                    <tr>
                        <th>
                            Costs
                        </th>
                        <th>
                            Rent
                        </th>
                        <th>
                            Own
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Flat Monthly Rrice
                        </td>
                        <td>
                            $42.50
                        </td>
                        <td>
                            $32.65
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Upfront Charger + Installation
                        </td>
                        <td>
                            N/A
                        </td>
                        <td>
                            $770.00
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Additional 240 Volt Circuit Wiring*
                        </td>
                        <td colSpan="2">
                            Varies
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <p>
            Note: your home may require additional 240 volt circuit wiring that is not included in installation and are unique to each home. See FAQ (How much does additional circuit wiring or panel upgrades typically cost?) for more information.
            </p>
            <p>
            Once your charging station is installed, you’ll pay the flat subscription price every month for overnight EV charging no matter how much electricity your EV needs. Overnight, off-peak hours are between 9 p.m. and 9 a.m. Your home’s energy use will continue to be billed at its current rate.
            </p>
            <p>
            Weekends and the following holidays are considered off-peak, as well: New Year’s Day, Good Friday, Memorial Day, Independence Day, Labor Day, Thanksgiving, and Christmas Day.
            </p>
            <p>
            Need the option to occasionally charge during the day on weekdays (9 a.m. to 9 p.m.)? No problem. In addition to your flat monthly subscription price, on-peak use will be charged at 16.508¢ per kWh during winter months (Oct. through May) and 20.497¢ per kWh in summer months (June through Sept).
            </p>
        </>
    )
}

export default PaymentPricing;