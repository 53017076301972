import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import getFilteredData from "../../../../utils/Helpers/getFilteredData"
import IncentiveCatalog from "../../../../components/IncentiveCatalog/IncentiveCatalog";
import { FormattedMessage } from 'react-intl';

const HomepageIncentives = ({ incentives }) => {

  incentives = getFilteredData([
    { field: "grantor", value: "Federal", count: 1 },
    { field: "grantor_type", value: "State", count: 2 },
    { field: "grantor_type", value: "Power Supplier", count: 3 }
  ], incentives)

  return (
    <section className="container-fluid pb-0" id="HomepageIncentives">
      <div className="container">

        <div className="row">
          <div className="col-sm-12 text-center title">
            <h2>
              <FormattedMessage
                id="homepage.incentives.title"
                defaultMessage="Explore potential EV incentives and tax credits"
                description="Homepage Incentives Title"
              />
            </h2>
            <p className="lead">
              <FormattedMessage
                id="homepage.incentives.subTitle"
                defaultMessage="See how much you could save getting behind the wheel of an EV,
                  whether you are buying or leasing. {lineBreak} Incentives are personalized for
                  where you live."
                description="Homepage Incentives Sub Title"
                values={{
                  lineBreak: <br />,
                }}
              />
            </p>
            <Link
              to="/incentives"
              style={{ margin: "12px", textDecoration: "none" }}
              className="btn btn-xcel"
              role="button"
            >
              <FormattedMessage
                id="personalizeIncentives"
                defaultMessage="Personalize Incentives"
                description="Personalize Incentives"
              />
            </Link>
          </div>
        </div>
        <IncentiveCatalog incentives={incentives} category="all" />
        <hr className="mt-5" />
        </div>
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array
};
