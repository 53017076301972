import { times } from "lodash";
import { CO_EV_ACCELERATE } from '../../constants/';

// Source: https://xcel.ev.d.zappyride.com/ev-accelerate-at-home-co

const FIXED_CHARGE = 7.77;
const CHARGER_RENTAL_COST = 13.29;

const co_ev_accelerate_at_home = {
  name: CO_EV_ACCELERATE,
  title: "EV Accelerate At Home",
  separatelyMetered: true,
  isBundled: false,
  includeHomeWiringEstimator: true,
  upfrontChargerCost: 0,
  monthlyDiscountForOptimizingCharge: -4.17,
  rateElements: [
    {
      name: "Basic Service Charge",
      rateElementType: "FixedPerMonth",
      rateComponents: [
        {
          charge: times(12, (_) => FIXED_CHARGE + CHARGER_RENTAL_COST),
          name: "Per Month",
        },
      ],
    },
    {
      name: "Energy Charge",
      rateElementType: "EnergyTimeOfUse",
      rateComponents: [
        {
          charge: 0.06237,
          months: [5, 6, 7, 8],
          name: "June through September",
        },
        {
          charge: 0.05461,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: "October through May",
        },
      ],
    },
    {
      name: "Tiered Energy Charges",
      rateElementType: "BlockedTiersInMonths",
      rateComponents: [
        {
          charge: 0.05461,
          min: times(12, (_) => 0),
          max: times(12, (_) => 500),
          name: "First 500 kWh",
        },
        {
          charge: 0.09902,
          min: times(12, (_) => 500),
          max: times(12, (_) => Infinity),
          name: "All over 500 kWh",
        },
      ],
    },
  ],
};

export default co_ev_accelerate_at_home;
