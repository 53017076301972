import React, { useContext } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import rateTypes from '../../../data/rateTypes';
import Tooltip from '../../../../components/shared/ToolTip/ToolTip';

const RateTypeCheckboxes = ({ className = "" }) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <div className={className}>
      <div className="mb-2">
        <label>
          Bundles vs DIY
          {" "}
          <Tooltip
            id="bundle-vs-diy-tooltip"
            message="Our bundle programs make it easy. We provide the charger and handle the installation and maintenance."
          />
        </label>
      </div>
      <div className="row no-gutters">
        {rateTypes.map(rateType => {
          const isSelected = userPrefs.get('selectedRateTypeIds').includes(rateType.id);
          return (
            <div className="col px-1" key={rateType.id}>
              <button
                className={`btn btn-default btn-sm btn-block ${isSelected ? 'active' : ''}`}
                type="button"
                onClick={() => {
                  const current = userPrefs.get('selectedRateTypeIds');
                  const newSelectedRateTypeIds = current.includes(rateType.id) ?
                    current.filter(id => id !== rateType.id) :
                    current.concat([rateType.id]);

                  userPrefs.set({ selectedRateTypeIds: newSelectedRateTypeIds });
                }}
                  aria-pressed={userPrefs.get('selectedRateTypeIds').includes(rateType.id)}
                >
                  {rateType.title}
                </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RateTypeCheckboxes;
