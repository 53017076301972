import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage } from "react-intl";

import sortEVs from "../../functions/vehicle/Sort/sortEVs";
// import filterEVs from "../../../functions/vehicle/Filter/filterEVs";
import filterInventory from "../../functions/vehicle/Filter/filterInventory.js";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import InventoryEVCard from "../InventoryEVCard/InventoryEVCard";

const InventoryCatalog = ({ vehicles }) => {
  const userPrefs = useContext(UserPrefsContext);
  if (!vehicles) return <LoadingSpinner />;

  const filteredElectricVehicles = filterInventory(vehicles, {
    vehicleMakeFilter: userPrefs.get("vehicleMakeFilter"),
    vehicleModelFilter: userPrefs.get("vehicleModelFilter"),
    inventoryDealers: userPrefs.get("inventoryDealers"),
    vehicleCondition: userPrefs.get("vehicleCondition"),
    vehicleAgeFilter: userPrefs.get("vehicleAgeFilter"),
    vehicleYearStartFilter: userPrefs.get("vehicleYearStartFilter"),
    vehicleYearEndFilter: userPrefs.get("vehicleYearEndFilter"),
  });

  const filteredAndSortedEvs = sortEVs(
    filteredElectricVehicles,
    userPrefs.get("vehicleSortType"),
    userPrefs.get("vehicleSortDirection"),
    userPrefs.get("milesDrivenDaily"),
    userPrefs.get("chargerLevel"),
    userPrefs.get("maxBudget"),
    userPrefs.get("minSeats"),
    null,
    null,
    null,
    "vehicle_dealership_price"
  );

  const renderCards = () => {
    const evs = filteredAndSortedEvs.length ? filteredAndSortedEvs : vehicles;
    return evs.map((ev, index) => {
      return <InventoryEVCard key={index} ev={ev} />;
    });
  };

  return (
    <>
      {!vehicles.length ? (
        <div className="">
          <div className="no-matches text-center">
            <h2>
              <FormattedMessage
                id="inventory.no-matches"
                defaultMessage="We didn't find any exact matches."
                description="We didn't find any exact matches."
              />
            </h2>
            <p
              style={{
                marginTop: "20px",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "center",
              }}
            >
              <FormattedMessage
                id="inventory.no-vehicles-found"
                defaultMessage="No vehicles matching your current search criteria can be found in local {lineBreak} inventory. Try changing your search criteria"
                description="Inventory no vehicles found"
                values={{
                  lineBreak: <br />,
                }}
              />
            </p>
          </div>
        </div>
      ) : filteredAndSortedEvs.length === 0 ? (
        <div className="available-nearby">
          <div className="no-matches text-center">
            <h2>
              <FormattedMessage
                id="inventory.no-matches"
                defaultMessage="We didn't find any exact matches."
                description="We didn't find any exact matches."
              />
            </h2>
            <p
              style={{
                marginTop: "20px",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "center",
              }}
            >
              <FormattedMessage
                id="inventory.no-vehicles-found"
                defaultMessage="No vehicles matching your current search criteria can be found in local {lineBreak} inventory. Try changing your search criteria"
                description="Inventory no vehicles found"
                values={{
                  lineBreak: <br />,
                }}
              />
            </p>
          </div>
          <p
            style={{
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "50px",
              color: "#2B2B2B",
              marginBottom: "0px",
            }}
          >
            <FormattedMessage
              id="inventory.check-other-vehicles"
              defaultMessage="Check out these other EVs available near you!"
              description="Check out these other EVs available near you!"
              values={{
                lineBreak: <br />,
              }}
            />
          </p>
        </div>
      ) : null}
      <div className="render-cards-container flex-column  ev-max-width">
        {renderCards()}
      </div>
    </>
  );
};

export default InventoryCatalog;

InventoryCatalog.propTypes = {
  vehicles: PropTypes.array,
  hasEvDetails: PropTypes.bool,
  hasIncentivesAndMatchScore: PropTypes.bool,
  hasLinktoEv: PropTypes.bool,
};
