import React from "react";
import PropTypes from "prop-types";

import ChargerCard from "./ChargerCard/ChargerCard";
import { FormattedMessage } from "react-intl";

const ChargerChoice = ({ chargers }) => {
  return (
    <>
      <div className="row charger-choices" style={chargers.length === 1 ? {justifyContent: "center"} :{}}>
        <div className="charger-choice col-md-6">
          <ChargerCard charger={chargers[0]} />
        </div>
        {chargers.length > 1 ? 
        <div className="charger-choice col-md-6">
          <ChargerCard charger={chargers[1]} />
        </div> : null}
      </div>
      <p>
        <FormattedMessage
          id={`chargerChoiceCapt`}
          defaultMessage="To ensure compatibility with eligible level 2 chargers, a non-GFCI circuit is required."
        />
      </p>
    </>
  );
};

export default ChargerChoice;

ChargerChoice.propTypes = {
  chargers: PropTypes.array,
};
