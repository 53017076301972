import React from "react";
import GrandTitledParas from "../../../client_customizations/components/shared/GrandTitledParas/GrandTitledParas";
import { FormattedMessage } from "react-intl";

export const PricingTable = ({ price, stateName }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="warranty"
              defaultMessage="Warranty"
              description="Warranty"
            />
          </th>
          <th>
            <FormattedMessage
              id="evaahServices"
              defaultMessage="Services"
              description="Services"
            />
          </th>
          <th>
            <FormattedMessage
              id="monthlyPayment"
              defaultMessage="Monthly Payment"
              description="Monthly Payment"
            />
          </th>
          <th>
            <FormattedMessage
              id="evaahWiringRequirements"
              defaultMessage="Wiring Requirements"
              description="Wiring Requirements"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <FormattedMessage
              id="unlimitedLifeTimeWarranty"
              defaultMessage="Unlimited, lifetime warranty"
              description="Unlimited, lifetime warranty"
            />
          </td>
          <td>
            <ul>
              <li>
                <FormattedMessage
                  id="levelTwoCharger"
                  defaultMessage="Level 2 Charger"
                  description="Level 2 Charger"
                />
              </li>
              <li>
                <FormattedMessage
                  id="evaahChargerSetUp"
                  defaultMessage="Charger set-up and hardwiring*"
                  description="Charger set-up and hardwiring*"
                />
              </li>
              <li>
                <FormattedMessage
                  id="evaahUnlimitedMaintenance"
                  defaultMessage="Unlimited maintenance as long as you participate"
                  description="Unlimited maintenance as long as you participate"
                />
              </li>
            </ul>
          </td>
          <td>
            <FormattedMessage
              id={`evaahMonthlyPayment-web`}
              defaultMessage="{price} – Applied directly to your Xcel Energy bill"
              values={{ price }}
            />
          </td>
          <td>
            <p>
              <FormattedMessage
                id="evaahCircuitWiring"
                defaultMessage="240-volt circuit wiring from panel to charger location"
                description="240-volt circuit wiring from panel to charger location"
              />
            </p>

            <p>
              <FormattedMessage
                id="evaahHelpYouSave"
                defaultMessage="Our qualified electricians can help you save with our"
                description="Our qualified electricians can help you save with our"
              />
              &nbsp;
              <a href="/home-wiring-rebate">
                <FormattedMessage
                  id="faqRebateCOHomeWiringRebate"
                  defaultMessage="Home Wiring Rebate"
                  description="Home Wiring Rebate"
                />
              </a>
              .
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export const MobilePricing = ({ price, stateName }) => {
  return (
    <div className="d-lg-none payment-pricing-mobile">
      <ul>
        <li>
          <FormattedMessage
            id="evaahWarranty"
            defaultMessage="Warranty: Unlimited, lifetime warranty"
            description="Warranty: Unlimited, lifetime warranty"
          />
        </li>
        <li>
          <FormattedMessage
            id="evaahServices"
            defaultMessage="Services"
            description="Services"
          />
        </li>
        <ul>
          <li>
            <FormattedMessage
              id="levelTwoCharger"
              defaultMessage="Level 2 Charger"
              description="Level 2 Charger"
            />
          </li>
          <li>
            <FormattedMessage
              id="evaahChargerSetUp"
              defaultMessage="Charger set-up and hardwiring"
              description="Charger set-up and hardwiring"
            />
          </li>
          <li>
            <FormattedMessage
              id="evaahUnlimitedMaintenance"
              defaultMessage="Unlimited maintenance as long as you participate"
              description="Unlimited maintenance as long as you participate"
            />
          </li>
        </ul>
        <li>
          <FormattedMessage
            id={`mobile-evaahMonthlyPayment-${stateName}`}
            defaultMessage="Monthly Payment: {price} – Applied directly to your Xcel Energy bill"
            values={{ price }}
          />
        </li>
        <li>
          <FormattedMessage
            id="evaahWiringRequirements"
            defaultMessage="Wiring Requirements"
            description="Wiring Requirements"
          />
          <ul>
            <li>
              <FormattedMessage
                id="evaahCircuitWiring"
                defaultMessage="240-volt circuit wiring from panel to charger location"
                description="240-volt circuit wiring from panel to charger location"
              />
            </li>
            <li>
              <FormattedMessage
                id="evaahHelpYouSave"
                defaultMessage="Our qualified electricians can help you save with our"
                description="Our qualified electricians can help you save with our"
              />
              &nbsp;
              <a href="/home-wiring-rebate">
                <FormattedMessage
                  id="faqRebateCOHomeWiringRebate"
                  defaultMessage="Home Wiring Rebate"
                  description="Home Wiring Rebate"
                />
              </a>
              .
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export const PaymentAndPricing = ({ price, stateName }) => {
  return (
    <GrandTitledParas
      classNames="how-works payment-and-price"
      colonHeader="Charger Payment and Pricing"
      customID={"chargerPaymentPricing"}
    >
      <MobilePricing price={price} stateName={stateName} />
      <div className="pricing-options d-none d-lg-block">
        <PricingTable price={price} stateName={stateName} />
        <p>
          <FormattedMessage
            id={`evaahPermitFees`}
            defaultMessage="* Permit Fees are not included in the monthly or upfront pricing. Your electrician will pull all necessary permits for the work that needs to be completed and you will pay the electrician directly for the permits."
            description="* Permit Fees are not included in the monthly or upfront pricing. Your electrician will pull all necessary permits for the work that needs to be completed and you will pay the electrician directly for the permits."
          />
        </p>
        <p>
          <FormattedMessage
            id="evaahFeesDisclaimer"
            defaultMessage="Fuel cost charges, resource adjustments and local taxes and fees are not included in the monthly or upfront prices and will be applied upon billing."
            description="Fees Disclaimer"
          />
        </p>
      </div>
    </GrandTitledParas>
  );
};
