import React, { Component } from "react";
import "url-search-params-polyfill";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./Root.scss";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import PageLayout from "../../components/PageLayout/PageLayout";
import banner from "../../assets/images/banner.png";

import calculatorPreviewImage from "../../assets/images/calculator-preview.jpeg";
import experiencePreviewImage from "../../assets/images/experience-preview.png";
import metaImage from "../../assets/images/metaImage.png";
import eventsScreenshot from "../../assets/images/metaImage.png";

// Pages
import Home from "../../components/HomePage/Homepage";
import EVs from "../../../pages/EVs/EVs";
import EVsGraph from "../../../pages/EVs/EVsGraph";
import EV from "../../../pages/EV/EV";
import UsedEV from "../../../pages/UsedEV/UsedEV";
import UsedEVs from "../../../pages/UsedEVs/UsedEVs";
import Incentives from "../../components/Incentives/Incentives";
import MapPage from "../../pages/MapPage/MapPage";
import FAQ from "../../../pages/FAQ/FAQ";
import FourOhFour from "../../../pages/FourOhFour/FourOhFour";
import CompareVehicles from "../../components/CompareVehicles/CompareVehicles";
import HomeChargers from "../../../pages/HomeChargers/HomeChargers";
import Inventory from "../../../pages/Inventory/Inventory";
// import Electricians from "../../../pages/Electricians/Electricians"
import ChargingFaq from "../../../client_customizations/components/ChargingFaq/ChargingFaq";
import EvsFaq from "../../../client_customizations/components/EvsFaq/EvsFaq";
import {
     ChargingPrograms,
     EvChargingPrograms,
     EvChargeWithRenableEnergy,
     EvAccelerateAtHome,
} from "../../../client_customizations/components/ChargingPrograms/ChargingPrograms";
import EasyChargingHomeWI from "../../components/EasyChargingHome/EasyChargingHomeWI";
import EasyChargingHomeMN from "../../components/EasyChargingHome/EasyChargingHomeMN";
import { EasyChargingHome } from "../../components/EasyChargingHome";
import SubscriptionServicePilot from "../../components/SubscriptionServicePilot/SubscriptionServicePilotMN";
import ChargeOvernight from "../../components/ChargeOvernight/ChargeOvernight";
import MeterDiagrams from "../../components/ChargeOvernight/MeterDiagrams/MeterDiagrams";
import CallUs from "../../components/ChargeOvernight/MeterDiagrams/CallUs";
import RateComparison from "../../pages/RateComparison/RateComparison";
import Events from "../../../pages/Events/Events";
import CPAdditionalInfo from "../../components/ChargingPerksPilot/CPAdditionalInfo";

// Services
import fetchElectricVehicles from "../../../services/fetchElectricVehicles";
import fetchGasolineVehicles from "../../../services/fetchGasolineVehicles";
import fetchIncentives from "../../../services/fetchIncentives";
import fetchDealers, { fetchDealersType } from "../../../services/fetchDealers";
import fetchVehicleIncentivesWithHandle from "../../../services/fetchVehicleIncentivesWithHandle";
import fetchHomeChargers from "../../../services/fetchHomeChargers";

// Utilities
import Uuid from "../../../utils/Uuid/Uuid";
import { loadState, persistState } from "../../../utils/LocalStorage/LocalStorage";
import loadUserPrefs from "../../../context/UserPrefs/loadUserPrefs";
import getUserPref from "../../../context/UserPrefs/getUserPref";
import USER_PREF_PRESETS from "../../../context/UserPrefs/USER_PREF_PRESETS";
import GaTracker from "../../../utils/GaTracker/GaTracker";
import isIE from "../../../utils/isIE";
import { MAP_TABS } from "../../../constants/mapTabs";

//Data

// import { DEALERS } from "../../data/dealers/DEALERS";

//Layouts
import ContentCenter from "../../components/PageLayout/BodyTemplates/ContentCenter";
import FrequentlyAskedQuestionsSection from "../../components/FrequentlyAskedQuestionsSection/FrequentlyAskedQuestionsSection";

//Hero Images
import driverWithDog from "../../assets/images/hero/driverWithDog.png";
import evChargingPlug from "../../assets/images/hero/evChargingPlug.png";
import chargingPerks from "../../assets/images/hero/chargingPerks.png";
import ChargingPerksPilot from "../../components/ChargingPerksPilot/ChargingPerksPilot";
import incomeQualifiedHero from "../../assets/images/hero/incomeQualified.jpg";
import IncomeQualified from "../../components/IncomeQualified/IncomeQualified";

import { FormattedMessage } from "react-intl";
import { AccelerateAtHome } from "../../../components/AccelerateAtHome";
import { HomeWiringRebate } from "../../../components/HomeWiringRebate";
import {
     NEW_MEXICO,
     COLORADO,
     EV_ACCELERATE_AT_HOME_MINNESOTA,
     EV_ACCELERATE_AT_HOME_WISCONSIN,
     OPTIMIZE_YOUR_CHARGE_COLORADO,
     HOME_WIRING_REBATE_COLORADO,
     EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
     CHANGING_PERKS_PILOT_COLORADO,
     INCOME_QUALIFIED_COLORADO,
     EV_ACCELERATE_AT_HOME_COLORADO,
     EV_ACCELERATE_AT_HOME_NEW_MEXICO,
     HOME_WIRING_REBATE_NEW_MEXICO,
     OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
     MINNESOTA,
     WISCONSIN,
     OPTIMIZE_YOUR_CHARGE_MINNESOTA,
     TIME_OF_DAY_SEPARATE_METER_MINNESOTA,
} from "../../../constants";
import { OptmizeYourCharge } from "../../../components/OptmizeYourCharge";
import { getByState, RenderByState } from "../../../components/RenderByState";

const ColoradoPrograms = [
     EvChargingPrograms.EvAccelerateAtHomeCo,
     EvChargingPrograms.SavePlus({ stateName: COLORADO }),
     EvChargingPrograms.EvRegularResidential,
     EvChargingPrograms.EvChargeWithRenableEnergy,
];

const NewMexicoPrograms = [
     EvAccelerateAtHome({ link: "/ev-accelerate-at-home-nm" }),
     EvChargingPrograms.SavePlus({ stateName: NEW_MEXICO }),
     EvChargingPrograms.TimeOfUse,
     EvChargingPrograms.EvRegularResidential,
     EvChargeWithRenableEnergy({
          link: "https://nm.my.xcelenergy.com/s/renewable",
     }),
];

class Root extends Component {
     constructor(props) {
          super(props);

          const existingState = loadState() || {};
          const savedPrefs = existingState && existingState.userPreferences ? existingState.userPreferences : {};

          if (!savedPrefs.vehicleFormFactorFilters) savedPrefs.vehicleFormFactorFilters = {};
          if (!savedPrefs.vehicleFuelTypeFilters) savedPrefs.vehicleFuelTypeFilters = {};
          if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};
          if (!savedPrefs.chargerFormFactorFilters) savedPrefs.chargerFormFactorFilters = {};
          if (!savedPrefs.chargerWifiFilter) savedPrefs.chargerWifiFilter = {};
          if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};

          this.state = {
               uuid: existingState.uuid || Uuid(),
               ipData: existingState.ipData || null,
               electricVehicles: null,
               usedElectricVehicles: null,
               gasolineVehicles: null,
               incentives: null,
               incentivePrefsModalIsOpen: false,
               userLocation: null,
               userLocationNotFound: false,
               userLocationDealersNotFound: false,
               zipcodeUpdating: false,
               homeChargers: null,

               userPreferences: loadUserPrefs(savedPrefs),
          };
          // Last resort to ensure that the user has a UUID
          if (!this.state.uuid) this.state.uuid = Uuid();

          this.loadElectricVehicleData = this.loadElectricVehicleData.bind(this);
          this.loadGasolineVehicleData = this.loadGasolineVehicleData.bind(this);
          this.loadIncentivesData = this.loadIncentivesData.bind(this);
          this.updateUserPreferences = this.updateUserPreferences.bind(this);
          this.getUserZip = this.getUserZip.bind(this);
          this.incentivePrefsModalToggle = this.incentivePrefsModalToggle.bind(this);
          this.loadDealers = this.loadDealers.bind(this);
     }

     componentDidMount() {
          this.getUserZip();

          this.loadElectricVehicleData();
          this.loadGasolineVehicleData();
          this.loadZipcodeData();
          this.loadIncentivesData();
          this.loadDealers();
          this.loadHomeChargers();

          GaTracker.initialize();
          const page = this.props.location.pathname + this.props.location.search;
          GaTracker.trackPage(page, { userId: this.state.uuid });
     }

     // TODO: this should be cleaner
     componentDidUpdate(prevProps, prevState, snapshot) {
          if (
               this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode ||
               this.state.userPreferences.householdSize !== prevState.userPreferences.householdSize ||
               this.state.userPreferences.householdIncome !== prevState.userPreferences.householdIncome ||
               this.state.userPreferences.canTurnInClunker !== prevState.userPreferences.canTurnInClunker ||
               this.state.userPreferences.taxFilingStatus !== prevState.userPreferences.taxFilingStatus
          ) {
               this.loadElectricVehicleData();
               this.loadIncentivesData();
          } else if (
               this.state.userPreferences.vehicleIdForIncentives !== prevState.userPreferences.vehicleIdForIncentives ||
               this.state.userPreferences.vehicleHandleForIncentives !==
                    prevState.userPreferences.vehicleHandleForIncentives
          ) {
               this.loadIncentivesData();
          }

          if (this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode) {
               this.loadZipcodeData();
               this.loadDealers();
          }

          let prevZip = prevState.userLocation ? prevState.userLocation.zip : null;
          if (this.state.userLocation && this.state.userLocation.zip !== prevZip) {
               this.loadDealers();
          }

          if (!this.state.userLocationNotFound && !this.state.zipcodeUpdating) {
               const { uuid, userPreferences, ipData } = this.state;
               persistState({ uuid, userPreferences, ipData });
          }

          const currentPage = prevProps.location.pathname + prevProps.location.search;
          const nextPage = this.props.location.pathname + this.props.location.search;

          if (currentPage !== nextPage) {
               GaTracker.trackPage(nextPage, { userId: this.state.uuid });
          }

          if (this.props.language !== prevProps.language) {
               this.loadIncentivesData();
               this.loadElectricVehicleData();

               const interval = setInterval(() => {
                    const feedbackElement = document.querySelector(".kampyle_button-text");
                    if (feedbackElement) {
                         if (this.props.language === "EN") {
                              feedbackElement.textContent = "Feedback";
                              clearInterval(interval);
                         } else {
                              feedbackElement.textContent = "Comentario";
                              clearInterval(interval);
                         }
                    }
               }, 1000);
          }

          if (
               this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode ||
               this.state.userPreferences.chargerWifiFilter !== prevState.userPreferences.chargerWifiFilter
          ) {
               this.loadHomeChargers();
          }
     }

     async loadElectricVehicleData() {
          let params = {
               postcode: getUserPref("zipcode", this.state.userPreferences),
               household_size: getUserPref("householdSize", this.state.userPreferences),
               household_income: getUserPref("householdIncome", this.state.userPreferences),
               lang: this.props.language === "EN" ? null : this.props.language,
          };

          try {
               const electricVehicles = await fetchElectricVehicles(params);
               if (!electricVehicles) return;
               this.setState({
                    electricVehicles: electricVehicles.newElectricVehicles,
                    usedElectricVehicles: electricVehicles.usedElectricVehicles,
               });
          } catch (e) {
               // TODO: handle errors here
          }
     }

     async loadGasolineVehicleData() {
          let params = {
               fuel_type: "gas",
               postcode: getUserPref("zipcode", this.state.userPreferences),
               lang: this.props.language === "EN" ? null : this.props.language,
          };

          try {
               const gasolineVehicles = await fetchGasolineVehicles(params);
               if (!gasolineVehicles) return;
               this.setState({ gasolineVehicles });
          } catch (e) {}
     }

     async loadIncentivesData() {
          let params = {
               postcode: getUserPref("zipcode", this.state.userPreferences),
               vehicle_handle: getUserPref("vehicleHandleForIncentives", this.state.userPreferences),
               household_size: getUserPref("householdSize", this.state.userPreferences),
               household_income: getUserPref("householdIncome", this.state.userPreferences),
               turn_in_clunker: getUserPref("canTurnInClunker", this.state.userPreferences) ? true : null,
               tax_filing_type: getUserPref("taxFilingStatus", this.state.userPreferences),
               lang: this.props.language === "EN" ? null : this.props.language,
          };

          try {
               const incentives =
                    params["vehicle_handle"] !== ""
                         ? await fetchVehicleIncentivesWithHandle(params)
                         : await fetchIncentives(params);
               if (!incentives) return;
               this.setState({ incentives });
          } catch (e) {
               // TODO: handle error
          }
     }

     async loadHomeChargers() {
          let params = {
               postcode: getUserPref("zipcode", this.state.userPreferences),
          };

          const wifiPrefs = getUserPref("chargerWifiFilter", this.state.userPreferences);
          if (wifiPrefs.yes) {
               params.wifi = "true";
          } else if (wifiPrefs.no) {
               params.wifi = "false";
          }

          try {
               const homeChargers = await fetchHomeChargers(params);
               if (!homeChargers) return;
               this.setState({ homeChargers });
          } catch (e) {}
     }

     async loadDealers() {
          if (!process.env.REACT_APP_PAGES_DEALERS_ENABLED) {
               return;
          }

          const zipcode = getUserPref("zipcode", this.state.userPreferences);
          const distance = getUserPref("vehicleDistanceFilter", this.state.userPreferences);

          const params = {
               postcode: zipcode,
               include_non_oem: true,
          };

          if (distance) {
               params.distance = distance;
          } else {
               params.distance = 2500;
          }

          const dealers = await fetchDealers(params);
          const dealersTypes = await fetchDealersType();

          const enhancedDealers = dealers.flatMap((dealer) => {
               const foundDealers = dealersTypes.filter(
                    (d) =>
                         (d.dealerId === dealer.dealer_id || d.dealershipHandle === dealer.dealership_handle) &&
                         d.oem === dealer.oem
               );

               if (!foundDealers || foundDealers.length === 0) {
                    return [dealer];
               } else {
                    if (foundDealers.length >= 1) {
                         return foundDealers.map((foundDealer) => {
                              return {
                                   ...dealer,
                                   type: foundDealer.type,
                                   oem: foundDealer.oem,
                              };
                         });
                    }
               }
          });

          this.setState({ dealerLocations: enhancedDealers });
     }

     loadZipcodeData() {
          const params = {
               postcode: getUserPref("zipcode", this.state.userPreferences),
               nearby_distance: isIE() ? 25 : 100,
          };

          let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/location`);

          let searchParams = new URLSearchParams(params);

          url.search = searchParams;

          window
               .fetch(url, {
                    method: "GET",
                    headers: {
                         "Content-Type": "application/json",
                         Accept: "application/json",
                         Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
                    },
               })
               .then((response) => {
                    if (response.status === 400) {
                         this.setState({
                              userLocationNotFound: true,
                              zipcodeUpdating: false,
                         });
                    }
                    return response;
               })
               .then((response) => response.json())
               .then((data) => {
                    if (data.location) {
                         if (data.location.region !== "Colorado") {
                              this.props.changeLanguage("EN");
                         }
                         this.setState({
                              userLocation: data.location,
                              userLocationNotFound: false,
                              zipcodeUpdating: false,
                         });
                         this.incentivePrefsModalToggle(false);
                         this.updateUserPreferences({
                              gasolinePriceInCentsPerGal: data.location.regional_fuel_cost[0].gasoline * 100,
                              salesTax: data?.location?.regional_financial_references?.[0]?.sales_tax?.region / 100.0,
                         });
                    }
               });
     }

     inServiceAreas(zip) {
          const stateZipRanges = {
               Wisconsin: [53001, 54990],
               NewMexico: [87001, 88441],
               Minnesota: [55001, 56763],
               Colorado: [80001, 81658],
          };
          if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)) {
               return false;
          }
          const zipCode = Number(zip.split("-")[0]);
          for (const state in stateZipRanges) {
               const [minZip, maxZip] = stateZipRanges[state];
               if (zipCode >= minZip && zipCode <= maxZip) {
                    return true;
               }
          }
          return false;
     }

     getUserZip() {
          let target = "post-code";
          var match = document.cookie.match(new RegExp("(^| )" + target + "=([^;]+)"));
          if (this.state.ipData === null && this.inServiceAreas(match?.[2])) {
               this.updateUserPreferences({ zipcode: match[2] });
          }
          this.setState({ ipData: match });
     }

     updateUserPreferences(newPrefs) {
          let prefs = Object.assign({}, this.state.userPreferences, newPrefs);
          let newState = {
               userPreferences: prefs,
          };
          if (newPrefs.zipcode && this.state.userPreferences.zipcode !== newPrefs.zipcode)
               newState.zipcodeUpdating = true;
          this.setState(newState);
     }

     incentivePrefsModalToggle(override) {
          if (typeof override === "boolean") {
               this.setState({ incentivePrefsModalIsOpen: override });
          } else {
               this.setState({
                    incentivePrefsModalIsOpen: !this.state.incentivePrefsModalIsOpen,
               });
          }
     }

     render() {
          const ip = this.state.ipData ? this.state.ipData.ip : null;
          const uuid = this.state.uuid;
          const language = this.props.language;
          const changeLanguage = this.props.changeLanguage;
          const region = this.state.userLocation?.region;

          const userPrefs = {
               get: (key) => getUserPref(key, this.state.userPreferences),
               getPreset: (key) => USER_PREF_PRESETS[key],
               set: this.updateUserPreferences,
               zipcodeIsNotFound: this.state.userLocationNotFound,
               zipcodeIsUpdating: this.state.zipcodeUpdating,
               showIncentivePrefsModal: this.state.incentivePrefsModalIsOpen,
               toggleIncentivePrefsModal: this.incentivePrefsModalToggle,
               syncWorkingZipcode: () =>
                    this.updateUserPreferences({
                         zipcode: getUserPref("workingZipcode", this.state.userPreferences),
                    }),
          };

          const getEVChargingProgramByState = function (props, currentState) {
               if (!currentState.userLocation) {
                    return null;
               }

               const regionPrograms = {
                    [MINNESOTA]: [
                         EvChargingPrograms.EvAccelerateAtHomeMn,
                         EvChargingPrograms.SavePlus({ stateName: MINNESOTA }),
                         EvChargingPrograms.EVTimeOfDaySeperate,
                         EvChargingPrograms.EVTimeOfDayWhole,
                         EvChargingPrograms.EvRegularResidential,
                         EvChargingPrograms.EvSpotNetworkMn,
                         EvChargingPrograms.EvChargeWithRenableEnergy,
                    ],
                    [COLORADO]: ColoradoPrograms,
                    [NEW_MEXICO]: NewMexicoPrograms,
                    [WISCONSIN]: [
                         EvChargingPrograms.EvAccelerateAtHomeWi,
                         EvChargingPrograms.EVTimeOfDayWhole,
                         EvChargingPrograms.EvRegularResidential,
                         EvChargingPrograms.EvChargeWithRenableEnergy,
                    ],
               };

               const selectedPrograms = regionPrograms[region ? region : MINNESOTA];

               const subHeading = (
                    <p>
                         <RenderByState
                              stateName={region}
                              options={{
                                   default: (
                                        <FormattedMessage
                                             id="EVChargingProgramsSubHeader"
                                             defaultMessage="Over 80% of EV charging happens at home. Find the charging program and electric pricing plan that’s best for you."
                                             description="Over 80% of EV charging happens at home. Find the charging program and electric pricing plan that’s best for you."
                                        />
                                   ),
                                   [NEW_MEXICO]: (
                                        <FormattedMessage
                                             id="EVChargingProgramsSubHeader"
                                             defaultMessage="Over 80% of EV charging happens at home. Find the charging program and electric pricing plan that’s best for you."
                                             description="Over 80% of EV charging happens at home. Find the charging program and electric pricing plan that’s best for you."
                                        />
                                   ),
                                   [COLORADO]: (
                                        <FormattedMessage
                                             id="EVChargingProgramsSubHeader"
                                             defaultMessage="Over 80% of EV charging happens at home. Find the charging program and electric pricing plan that’s best for you."
                                             description="Over 80% of EV charging happens at home. Find the charging program and electric pricing plan that’s best for you."
                                        />
                                   ),
                              }}
                         />
                    </p>
               );

               const disclaimer = (
                    <p>
                         <RenderByState
                              stateName={region}
                              options={{
                                   [MINNESOTA]: (
                                        <span className="big-disclaimer-paragraph">
                                             <FormattedMessage
                                                  id="viewElectriciansMN"
                                                  defaultMessage="{link} we work with in Minnesota."
                                                  description="{link} we work with in Minnesota."
                                                  values={{
                                                       link: (
                                                            <a
                                                                 href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/MN-EV-Electricians-Information-Sheet.pdf?_ga=2.104283264.107638907.1667422966-367489819.1647884872" //viewElectriciansMN_link_URL
                                                                 target="_blank"
                                                                 rel="noopener noreferrer"
                                                            >
                                                                 <FormattedMessage
                                                                      id="viewElectriciansMN_link_text"
                                                                      defaultMessage="View electricians"
                                                                 />
                                                            </a>
                                                       ),
                                                  }}
                                             />

                                             <br />
                                             <FormattedMessage
                                                  id="joinElectricianNetwork"
                                                  defaultMessage="If you would like to be considered for our electrician network, email us at"
                                             />
                                             <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                  ElectricVehicles@xcelenergy.com
                                             </a>
                                        </span>
                                   ),
                                   [WISCONSIN]: (
                                        <span className="big-disclaimer-paragraph">
                                             <FormattedMessage
                                                  id="ViewElectricians_WI"
                                                  defaultMessage="{link} we work with in Wisconsin"
                                                  description="{link} we work with in Wisconsin"
                                                  values={{
                                                       link: (
                                                            <a
                                                                 href="https://www.xcelenergy.com/staticfiles/xe/PDF/WI-EV-Electricians-Info-Sheet.pdf?_ga=2.100435741.266643976.1670513339-1017785971.1670511917" //ViewElectricians_WI_link_URL
                                                                 target="_blank"
                                                                 rel="noopener noreferrer"
                                                            >
                                                                 <FormattedMessage
                                                                      id="ViewElectricians_WI_link_text"
                                                                      defaultMessage="View electricians"
                                                                 />
                                                            </a>
                                                       ),
                                                  }}
                                             />

                                             <br />
                                             <FormattedMessage
                                                  id="joinElectricianNetwork"
                                                  defaultMessage="If you would like to be considered for our electrician network, email us at"
                                             />
                                             <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                  ElectricVehicles@xcelenergy.com
                                             </a>
                                        </span>
                                   ),
                                   [COLORADO]: (
                                        <span className="big-disclaimer-paragraph">
                                             <a
                                                  href="https://www.xcelenergy.com/staticfiles/xe-responsive/Programs%20and%20Rebates/Residential/22-03-628%20CO%20EV%20Electrician%20Info%20Sheet%20P1.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                             >
                                                  <FormattedMessage
                                                       id="viewElectriciansCO"
                                                       defaultMessage="View electricians we work with in Colorado."
                                                       description="View electricians we work with in Colorado."
                                                  />
                                             </a>
                                             <br />
                                             <FormattedMessage
                                                  id="joinElectricianNetwork"
                                                  defaultMessage="If you would like to be considered for our electrician network, email us atasdasd "
                                                  description="If you would like to be considered for our electrician network, email us atasdasdsad "
                                             />
                                             <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                  ElectricVehicles@xcelenergy.com
                                             </a>
                                        </span>
                                   ),
                              }}
                         />
                    </p>
               );

               return (
                    <PageLayout
                         language={language}
                         changeLanguage={changeLanguage}
                         props={props}
                         ip={ip}
                         uuid={uuid}
                         page="EV Charging Programs | Xcel EV Shopping Advisor"
                         title="EV Charging Programs | Xcel EV Shopping Advisor"
                         zipcode={userPrefs.get("zipcode")}
                         activePage="map"
                         userLocation={currentState.userLocation}
                         electricVehicles={currentState.electricVehicles}
                         usedElectricVehicles={currentState.usedElectricVehicles}
                    >
                         <ContentCenter
                              pageHeaderHeadLine={
                                   <FormattedMessage
                                        id="EVChargingPrograms"
                                        defaultMessage="Electric Vehicle Charging Programs"
                                        description="Electric Vehicle Charging Programs"
                                   />
                              }
                              pageHeaderSubHeading={subHeading}
                              disclaimer={disclaimer}
                         >
                              <ChargingPrograms
                                   ip={currentState.ipData ? currentState.ipData.ip : null}
                                   uuid={currentState.uuid}
                                   chargingProgramToShow={selectedPrograms}
                              />
                         </ContentCenter>
                    </PageLayout>
               );
          };

          const getAccelerateAtHomeByState = (props, currentState) => {
               if (!currentState.userLocation) {
                    return null;
               }

               const regionAccelerateHome = {
                    Minnesota: (
                         <EasyChargingHomeMN
                              stateName={MINNESOTA}
                              enrollURL="https://ev.xcelenergy.com/ev-accelerate-at-home-mn"
                         />
                    ),
                    Wisconsin: (
                         <EasyChargingHomeWI
                              stateName={WISCONSIN}
                              enrollURL="https://ev.xcelenergy.com/ev-accelerate-at-home-wi"
                         />
                    ),
                    Colorado: (
                         <EasyChargingHome
                              stateName={COLORADO}
                              enrollURL={getByState({
                                   COLORADO,
                                   options: {
                                        default: "https://my.xcelenergy.com/MyAccount/s/ev",
                                        [COLORADO]: "https://my.xcelenergy.com/MyAccount/s/ev",
                                   },
                              })}
                              price={"$13.29"}
                         />
                    ),
                    "New Mexico": (
                         <EasyChargingHome
                              stateName={NEW_MEXICO}
                              enrollURL={getByState({
                                   NEW_MEXICO,
                                   options: {
                                        default: "https://my.xcelenergy.com/MyAccount/s/ev",
                                        [NEW_MEXICO]: "https://my.xcelenergy.com/MyAccount/s/ev",
                                   },
                              })}
                              price={"$12.00"}
                         />
                    ),
               };

               const selectedAccel = regionAccelerateHome[region ? region : WISCONSIN];

               const handleFaqKey = () => {
                    switch (region) {
                         case NEW_MEXICO:
                              return EV_ACCELERATE_AT_HOME_NEW_MEXICO;
                         case MINNESOTA:
                              return EV_ACCELERATE_AT_HOME_MINNESOTA;
                         case COLORADO:
                              return EV_ACCELERATE_AT_HOME_COLORADO;
                         case WISCONSIN:
                              return EV_ACCELERATE_AT_HOME_WISCONSIN;

                         default:
                              return OPTIMIZE_YOUR_CHARGE_COLORADO;
                    }
               };

               return (
                    <PageLayout
                         language={language}
                         changeLanguage={changeLanguage}
                         props={props}
                         ip={ip}
                         uuid={uuid}
                         page="EV Accelerate at Home"
                         title="EV Accelerate at Home"
                         zipcode={userPrefs.get("zipcode")}
                         userLocation={this.state.userLocation}
                         electricVehicles={this.state.electricVehicles}
                         usedElectricVehicles={this.state.usedElectricVehicles}
                    >
                         <img src={driverWithDog} alt="Hero" style={{ minWidth: "100%", maxWidth: "100%" }} />
                         <ContentCenter
                              pageHeaderLead="EV ACCELERATE AT HOME"
                              pageHeaderHeadLine={
                                   <FormattedMessage
                                        id="evaahHeadline"
                                        defaultMessage="Easy Electric Vehicle Charging at Home."
                                        description="Easy Electric Vehicle Charging at Home."
                                   />
                              }
                              pageHeaderSubHeading={
                                   <FormattedMessage
                                        id="evaahSubHeadline_mn"
                                        defaultMessage="Charging your electric vehicle (EV) at home is convenient, and it just got even easier.  With EV Accelerate At home, you can save on charging by renting a Level 2 charger from Xcel Energy or providing your own pre-approved charger."
                                        description="Easy Electric Vehicle Charging at Home Subheader"
                                   />
                              }
                              sectionStyle={{ backgroundColor: "#F6F8FB" }}
                         >
                              {selectedAccel}
                         </ContentCenter>
                         <ContentCenter
                              pageHeaderLeadId={"frequentlyAskedQuestions"}
                              pageHeaderLead="FREQUENTLY ASKED QUESTIONS"
                              sectionStyle={{ backgroundColor: "#FFFFFF" }}
                         >
                              <FrequentlyAskedQuestionsSection region={region} applicableTo={handleFaqKey()} />
                         </ContentCenter>
                    </PageLayout>
               );
          };

          const handleFaqKey = () => {
               switch (region) {
                    case NEW_MEXICO:
                         return OPTIMIZE_YOUR_CHARGE_NEW_MEXICO;
                    case MINNESOTA:
                         return OPTIMIZE_YOUR_CHARGE_MINNESOTA;
                    case COLORADO:
                         return OPTIMIZE_YOUR_CHARGE_COLORADO;

                    default:
                         return OPTIMIZE_YOUR_CHARGE_COLORADO;
               }
          };

          return (
               <UserPrefsContext.Provider value={userPrefs}>
                    <Switch>
                         <Route
                              exact
                              path={["/", "/DriveElectric"]}
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             zipcode={userPrefs.get("zipcode")}
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             description="Learn more about electric vehicles (EVs) incentives"
                                             disclaimers="EV Savings Calculator provides illustrative estimations throughout and does not guarantee the accuracy of any costs, savings, hardware specifications, or incentives estimates. Please refer to referenced sources and original equipment manufacturers for up to date costs, hardware specifications, and incentives information."
                                             page="Home | Xcel EV Shopping Advisor"
                                             title="Home | Xcel EV Shopping Advisor"
                                             image={metaImage}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <Home
                                                  {...props}
                                                  electricVehicles={this.state.electricVehicles}
                                                  incentives={this.state.incentives}
                                                  userPreferences={this.state.userPreferences}
                                                  userLocation={this.state.userLocation}
                                                  homePageBannerImage={banner}
                                                  ip={ip}
                                                  uuid={uuid}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                              <Route
                                   exact
                                   path="/faq"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  props={props}
                                                  ip={ip}
                                                  uuid={uuid}
                                                  description="Answers to Frequently Asked Questions about electric vehicles (EVs) and their incentives"
                                                  page="FAQ | Xcel EV Shopping Advisor"
                                                  image={calculatorPreviewImage}
                                                  userLocation={this.state.userLocation}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                             >
                                                  <FAQ ip={ip} uuid={uuid} />;
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
                              <Route
                                   exact
                                   path="/vehicles"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  zipcode={userPrefs.get("zipcode")}
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  page="Vehicles | Xcel EV Shopping Advisor"
                                                  title="Vehicles | Xcel EV Shopping Advisor"
                                                  ip={ip}
                                                  uuid={uuid}
                                                  description=""
                                                  activePage="vehicles"
                                                  userLocation={this.state.userLocation}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                             >
                                                  <EVs
                                                       {...props}
                                                       electricVehicles={this.state.electricVehicles}
                                                       ip={ip}
                                                       uuid={uuid}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
                              <Route
                                   exact
                                   path="/used-vehicles"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  zipcode={userPrefs.get("zipcode")}
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  page="vehicles"
                                                  title="Used Vehicles | Xcel EV Shopping Advisor"
                                                  activePage="vehicles"
                                                  ip={ip}
                                                  uuid={uuid}
                                                  userLocation={this.state.userLocation}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                             >
                                                  <UsedEVs
                                                       {...props}
                                                       usedElectricVehicles={this.state.usedElectricVehicles}
                                                       ip={ip}
                                                       uuid={uuid}
                                                       zipcode={userPrefs.get("zipcode")}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
                              <Route
                                   path="/used-vehicles/:evId"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  zipcode={userPrefs.get("zipcode")}
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  page="vehicles"
                                                  activePage="vehicles"
                                                  ip={ip}
                                                  uuid={uuid}
                                                  disclaimers=""
                                                  userLocation={this.state.userLocation}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                             >
                                                  <UsedEV
                                                       {...props}
                                                       electricVehicles={this.state.usedElectricVehicles}
                                                       userLocation={this.state.userLocation}
                                                       ip={ip}
                                                       uuid={uuid}
                                                       zipcode={userPrefs.get("zipcode")}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
                              <Route
                                   exact
                                   path="/incentives"
                                   render={(props) => (
                                        <PageLayout
                                             zipcode={userPrefs.get("zipcode")}
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Incentives | Xcel EV Shopping Advisor"
                                             title="Incentives | Xcel EV Shopping Advisor"
                                             description=""
                                             disclaimers="Listed incentives may not be available at any given time. Listed incentives reflect an illustrative estimation of available incentives. {process.env.REACT_APP_FULL_COMPANY_NAME} does not recommend or endorse any particular automotive or insurance company."
                                             activePage="incentives"
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <Incentives
                                                  {...props}
                                                  electricVehicles={this.state.electricVehicles}
                                                  incentives={this.state.incentives}
                                                  image={experiencePreviewImage}
                                                  ip={ip}
                                                  uuid={uuid}
                                                  zipcode={getUserPref("zipcode", this.state.userPreferences)}
                                             />
                                        </PageLayout>
                                   )}
                              />
                         ) : null}
                         {MAP_TABS.filter((tab) => tab.isEnabled).map((tab) => {
                              return (
                                   <Route
                                        exact
                                        key={tab.id}
                                        path={tab.url}
                                        render={(props) => {
                                             return (
                                                  <PageLayout
                                                       language={language}
                                                       changeLanguage={changeLanguage}
                                                       props={props}
                                                       ip={ip}
                                                       uuid={uuid}
                                                       page="Map | Xcel EV Shopping Advisor"
                                                       description={tab.description}
                                                       zipcode={getUserPref("zipcode", this.state.userPreferences)}
                                                       activePage={tab.activePage}
                                                       userLocation={this.state.userLocation}
                                                       electricVehicles={this.state.electricVehicles}
                                                       usedElectricVehicles={this.state.usedElectricVehicles}
                                                  >
                                                       <MapPage
                                                            {...props}
                                                            tabId={tab.id}
                                                            userLocation={this.state.userLocation}
                                                            ip={ip}
                                                            uuid={uuid}
                                                            dealerLocations={this.state.dealerLocations}
                                                            electricVehicles={this.state.electricVehicles}
                                                            usedElectricVehicles={this.state.usedElectricVehicles}
                                                            title={tab.title}
                                                            zipcode={getUserPref("zipcode", this.state.userPreferences)}
                                                       />
                                                  </PageLayout>
                                             );
                                        }}
                                   />
                              );
                         })}
                         {process.env.REACT_APP_PAGES_VEHICLES_MODEL_ENABLED ? (
                              <Route
                                   path="/vehicles/:evId"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  zipcode={userPrefs.get("zipcode")}
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  page="Vehicles | Xcel EV Shopping Advisor"
                                                  ip={ip}
                                                  uuid={uuid}
                                                  description="Compare Electric Vehicles (EVs) by price, range and features."
                                                  disclaimers=""
                                                  activePage="vehicles"
                                                  userLocation={this.state.userLocation}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                             >
                                                  <EV
                                                       {...props}
                                                       electricVehicles={this.state.electricVehicles}
                                                       userLocation={this.state.userLocation}
                                                       ip={this.state.ipData ? this.state.ipData : null}
                                                       uuid={uuid}
                                                       zipcode={userPrefs.get("zipcode")}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
                              <Route
                                   exact
                                   path="/compare-vehicles"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  zipcode={userPrefs.get("zipcode")}
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  page="Vehicles | Xcel EV Shopping Advisor"
                                                  title="Compare Vehicles | Xcel EV Shopping Advisor"
                                                  ip={ip}
                                                  uuid={uuid}
                                                  activePage="vehicles"
                                                  userLocation={this.state.userLocation}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                             >
                                                  <CompareVehicles
                                                       props={props}
                                                       ip={this.state.ipData ? this.state.ipData.ip : null}
                                                       uuid={this.state.uuid}
                                                       electricVehicles={this.state.electricVehicles}
                                                       gasolineVehicles={this.state.gasolineVehicles}
                                                       userLocation={this.state.userLocation}
                                                       compareUsedVehicles={false}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_COMPARISON_GRAPH_ENABLED ? (
                              <Route
                                   exact
                                   path="/comparison-graph"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  props={props}
                                                  page="cost vs range"
                                                  ip={ip}
                                                  uuid={uuid}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                             >
                                                  <EVsGraph
                                                       {...props}
                                                       electricVehicles={this.state.electricVehicles}
                                                       ip={ip}
                                                       uuid={uuid}
                                                       displayGraph={true}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
                              <Route
                                   exact
                                   path="/home-chargers"
                                   render={(props) => {
                                        return (
                                             <PageLayout
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  ip={ip}
                                                  uuid={uuid}
                                                  page="home chargers"
                                                  userLocation={this.state.userLocation}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                             >
                                                  <HomeChargers
                                                       {...props}
                                                       homeChargers={this.state.homeChargers}
                                                       electricVehicles={this.state.electricVehicles}
                                                       ip={ip}
                                                       uuid={uuid}
                                                  />
                                             </PageLayout>
                                        );
                                   }}
                              />
                         ) : null}
                         <Route
                              exact
                              path="/inventory"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             props={props}
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             ip={ip}
                                             uuid={uuid}
                                             page={process.env.REACT_APP_PAGES_INVENTORY_TITLE}
                                        >
                                             <Inventory
                                                  {...props}
                                                  dealerLocations={this.state.dealerLocations}
                                                  userLocation={this.state.userLocation}
                                                  ip={ip}
                                                  uuid={uuid}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         {/* {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED ? (
            <Route
              exact path="/electricians"
              render={props => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="electricians">
                    <Electricians
                      {...props}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                      zipcode={userPrefs.get("zipcode")}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : (null)} */}
                         <Route
                              exact
                              path="/charging-101"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Charging 101 | Xcel EV Shopping Advisor"
                                             title="Charging 101 | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             activePage="map"
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <ChargingFaq
                                                  ip={this.state.ipData ? this.state.ipData.ip : null}
                                                  uuid={this.state.uuid}
                                                  userLocation={this.state.userLocation}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/ev-101"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="EV 101 | Xcel EV Shopping Advisor"
                                             title="EV 101 | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             activePage="vehicles"
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <EvsFaq
                                                  ip={this.state.ipData ? this.state.ipData.ip : null}
                                                  uuid={this.state.uuid}
                                                  userLocation={this.state.userLocation}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/(ev-charging-programs|programs)/"
                              render={(props) => {
                                   return getEVChargingProgramByState(props, this.state);
                              }}
                         />
                         <Route
                              exact
                              path="/ChargeWI"
                              render={(props) => {
                                   const currentLocation = { region: "Wisconsin" };

                                   return getEVChargingProgramByState(props, {
                                        userLocation: currentLocation,
                                   });
                              }}
                         />
                         <Route
                              exact
                              path="/ChargeMN"
                              render={(props) => {
                                   const currentLocation = { region: "Minnesota" };

                                   return getEVChargingProgramByState(props, {
                                        userLocation: currentLocation,
                                   });
                              }}
                         />
                         <Route
                              exact
                              path="/ev-charging-programs-mn"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Charing Programs | Xcel EV Shopping Advisor"
                                             title="Charing Programs | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             activePage="map"
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <ContentCenter
                                                  pageHeaderHeadLine={
                                                       <FormattedMessage
                                                            id="EVChargingPrograms"
                                                            defaultMessage="Electric Vehicle Charging Programs"
                                                            description="Electric Vehicle Charging Programs"
                                                       />
                                                  }
                                                  pageHeaderSubHeading="Find the charging program and electric pricing plan that’s best for you."
                                                  disclaimer={
                                                       <span className="big-disclaimer-paragraph">
                                                            <FormattedMessage
                                                                 id="viewElectriciansMN"
                                                                 defaultMessage="{link} we work with in Minnesota."
                                                                 description="{link} we work with in Minnesota."
                                                                 values={{
                                                                      link: (
                                                                           <a
                                                                                href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/MN-EV-Electricians-Information-Sheet.pdf?_ga=2.104283264.107638907.1667422966-367489819.1647884872" //viewElectriciansMN_link_URL
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                           >
                                                                                <FormattedMessage
                                                                                     id="viewElectriciansMN_link_text"
                                                                                     defaultMessage="View electricians"
                                                                                />
                                                                           </a>
                                                                      ),
                                                                 }}
                                                            />
                                                            <br />
                                                            <FormattedMessage
                                                                 id="joinElectricianNetwork"
                                                                 defaultMessage="If you would like to be considered for our electrician network, email us at"
                                                            />{" "}
                                                            <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                                 ElectricVehicles@xcelenergy.com
                                                            </a>
                                                       </span>
                                                  }
                                             >
                                                  <ChargingPrograms
                                                       ip={this.state.ipData ? this.state.ipData.ip : null}
                                                       uuid={this.state.uuid}
                                                       chargingProgramToShow={[
                                                            EvChargingPrograms.EvAccelerateAtHomeMn,
                                                            EvChargingPrograms.SavePlus({ stateName: MINNESOTA }),
                                                            EvChargingPrograms.EVTimeOfDaySeperate,
                                                            EvChargingPrograms.EVTimeOfDayWhole,
                                                            EvChargingPrograms.EvRegularResidential,
                                                            EvChargingPrograms.EvSpotNetworkMn,
                                                            EvChargingPrograms.EvChargeWithRenableEnergy,
                                                       ]}
                                                  />
                                             </ContentCenter>
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/ev-charging-programs-co"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Charing Programs | Xcel EV Shopping Advisor"
                                             title="Charing Programs | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             activePage="map"
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <ContentCenter
                                                  pageHeaderHeadLine={
                                                       <FormattedMessage
                                                            id="EVChargingPrograms"
                                                            defaultMessage="Electric Vehicle Charging Programs"
                                                            description="Electric Vehicle Charging Programs"
                                                       />
                                                  }
                                                  pageHeaderSubHeading={
                                                       <p>
                                                            <FormattedMessage
                                                                 id="EVChargingProgramsSubHeader"
                                                                 defaultMessage="Over 80% of EV charging happens at home. Find the charging program and electric pricing plan that’s best for you."
                                                                 description="Over 80% of EV charging happens at home. Find the charging program and electric pricing plan that’s best for you."
                                                            />
                                                       </p>
                                                  }
                                             >
                                                  <ChargingPrograms
                                                       ip={this.state.ipData ? this.state.ipData.ip : null}
                                                       uuid={this.state.uuid}
                                                       chargingProgramToShow={[
                                                            EvChargingPrograms.EvAccelerateAtHomeCo,
                                                            EvChargingPrograms.SavePlus,
                                                            EvChargingPrograms.EvRegularResidential,
                                                            EvChargingPrograms.EvChargeWithRenableEnergy,
                                                       ]}
                                                  />
                                             </ContentCenter>
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/ev-charging-programs-nm"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Charing Programs | Xcel EV Shopping Advisor"
                                             title="Charing Programs | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             activePage="map"
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <ContentCenter
                                                  pageHeaderHeadLine={
                                                       <FormattedMessage
                                                            id="EVChargingPrograms"
                                                            defaultMessage="Electric Vehicle Charging Programs"
                                                            description="Electric Vehicle Charging Programs"
                                                       />
                                                  }
                                                  pageHeaderSubHeading="Find the charging program and electric pricing plan that’s best for you."
                                             >
                                                  <ChargingPrograms
                                                       ip={this.state.ipData ? this.state.ipData.ip : null}
                                                       uuid={this.state.uuid}
                                                       chargingProgramToShow={[
                                                            EvChargingPrograms.EvRegularResidential,
                                                            EvChargingPrograms.EvChargeWithRenableEnergy,
                                                       ]}
                                                  />
                                             </ContentCenter>
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/ev-charging-programs-wi"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Charing Programs | Xcel EV Shopping Advisor"
                                             title="Charing Programs | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             activePage="map"
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <ContentCenter
                                                  pageHeaderHeadLine={
                                                       <FormattedMessage
                                                            id="EVChargingPrograms"
                                                            defaultMessage="Electric Vehicle Charging Programs"
                                                            description="Electric Vehicle Charging Programs"
                                                       />
                                                  }
                                                  pageHeaderSubHeading={
                                                       <FormattedMessage
                                                            id="EVChargingProgramsSubHeader"
                                                            defaultMessage="Over 80% of EV charging happens at home. Find the charging program and electric pricing plan that’s best for you."
                                                            description="Over 80% of EV charging happens at home. Find the charging program and electric pricing plan that’s best for you."
                                                       />
                                                  }
                                                  disclaimer={
                                                       <span className="big-disclaimer-paragraph">
                                                            <FormattedMessage
                                                                 id="ViewElectricians_WI"
                                                                 defaultMessage="{link} we work with in Wisconsin"
                                                                 description="{link} we work with in Wisconsin"
                                                                 values={{
                                                                      link: (
                                                                           <a
                                                                                href="https://www.xcelenergy.com/staticfiles/xe/PDF/WI-EV-Electricians-Info-Sheet.pdf?_ga=2.100435741.266643976.1670513339-1017785971.1670511917" //ViewElectricians_WI_link_URL
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                           >
                                                                                <FormattedMessage
                                                                                     id="ViewElectricians_WI_link_text"
                                                                                     defaultMessage="View electricians"
                                                                                />
                                                                           </a>
                                                                      ),
                                                                 }}
                                                            />
                                                            <br />
                                                            <FormattedMessage
                                                                 id="joinElectricianNetwork"
                                                                 defaultMessage="If you would like to be considered for our electrician network, email us at"
                                                            />{" "}
                                                            <a href="mailto:ElectricVehicles@xcelenergy.com">
                                                                 ElectricVehicles@xcelenergy.com
                                                            </a>
                                                       </span>
                                                  }
                                             >
                                                  <ChargingPrograms
                                                       ip={this.state.ipData ? this.state.ipData.ip : null}
                                                       uuid={this.state.uuid}
                                                       chargingProgramToShow={[
                                                            EvChargingPrograms.EvAccelerateAtHomeWi,
                                                            EvChargingPrograms.EVTimeOfDayWhole,
                                                            EvChargingPrograms.EvRegularResidential,
                                                            EvChargingPrograms.EvChargeWithRenableEnergy,
                                                       ]}
                                                  />
                                             </ContentCenter>
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/ev-accelerate-at-home"
                              render={(props) => {
                                   return getAccelerateAtHomeByState(props, this.state);
                              }}
                         />
                         <Route
                              exact
                              path="/ev-accelerate-at-home-wi"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="EV Accelerate at Home"
                                             title="EV Accelerate at Home | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <img
                                                  src={driverWithDog}
                                                  alt="Hero"
                                                  style={{ minWidth: "100%", maxWidth: "100%" }}
                                             />
                                             <ContentCenter
                                                  pageHeaderLead="EV ACCELERATE AT HOME"
                                                  pageHeaderHeadLine={
                                                       <FormattedMessage
                                                            id="evaahHeadline"
                                                            defaultMessage="Easy Electric Vehicle Charging at Home."
                                                            description="Easy Electric Vehicle Charging at Home."
                                                       />
                                                  }
                                                  pageHeaderSubHeading={
                                                       <FormattedMessage
                                                            id="evaahSubHeadline"
                                                            defaultMessage="Charging your electric vehicle (EV) at home is convenient, and it just got even easier.  With EV Accelerate At home, you can save on charging by renting a Level 2 charger from Xcel Energy or providing your own pre-approved charger."
                                                            description="Easy Electric Vehicle Charging at Home Subheader"
                                                       />
                                                  }
                                                  sectionStyle={{ backgroundColor: "#F6F8FB" }}
                                             >
                                                  <EasyChargingHomeWI
                                                       stateName="Wisconsin"
                                                       enrollURL="https://my.xcelenergy.com/MyAccount/s/ev"
                                                  />
                                             </ContentCenter>
                                             <ContentCenter
                                                  pageHeaderLeadId={"frequentlyAskedQuestions"}
                                                  pageHeaderLead="FREQUENTLY ASKED QUESTIONS"
                                                  sectionStyle={{ backgroundColor: "#FFFFFF" }}
                                             >
                                                  <FrequentlyAskedQuestionsSection
                                                       region={region}
                                                       applicableTo={EV_ACCELERATE_AT_HOME_WISCONSIN}
                                                  />
                                             </ContentCenter>
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/ev-accelerate-at-home-mn"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="EV Accelerate at Home"
                                             title="EV Accelerate at Home | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <img
                                                  src={driverWithDog}
                                                  alt="Hero"
                                                  style={{ minWidth: "100%", maxWidth: "100%" }}
                                             />
                                             <ContentCenter
                                                  pageHeaderLead="EV ACCELERATE AT HOME"
                                                  pageHeaderHeadLine={
                                                       <FormattedMessage
                                                            id="evaahHeadline"
                                                            defaultMessage="Easy Electric Vehicle Charging at Home."
                                                            description="Easy Electric Vehicle Charging at Home."
                                                       />
                                                  }
                                                  pageHeaderSubHeading={
                                                       <FormattedMessage
                                                            id="evaahSubHeadline_mn"
                                                            defaultMessage="Charging your electric vehicle (EV) at home is convenient, and it just got even easier.  With EV Accelerate At home, you can save on charging by renting a Level 2 charger from Xcel Energy or providing your own pre-approved charger."
                                                            description="Easy Electric Vehicle Charging at Home Subheader"
                                                       />
                                                  }
                                                  sectionStyle={{ backgroundColor: "#F6F8FB" }}
                                             >
                                                  <EasyChargingHomeMN
                                                       stateName="Minnesota"
                                                       enrollURL="https://my.xcelenergy.com/MyAccount/s/ev"
                                                  />
                                             </ContentCenter>
                                             <ContentCenter
                                                  pageHeaderLeadId={"frequentlyAskedQuestions"}
                                                  pageHeaderLead="FREQUENTLY ASKED QUESTIONS"
                                                  sectionStyle={{ backgroundColor: "#FFFFFF" }}
                                             >
                                                  <FrequentlyAskedQuestionsSection
                                                       region={region}
                                                       applicableTo={EV_ACCELERATE_AT_HOME_MINNESOTA}
                                                  />
                                             </ContentCenter>
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              path={["/ev-accelerate-at-home-co", "/ChargeCO"]}
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="EV Accelerate at Home"
                                             title="EV Accelerate at Home | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <AccelerateAtHome
                                                  stateName={COLORADO}
                                                  faqKey={EV_ACCELERATE_AT_HOME_COLORADO}
                                                  price="$13.29"
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              path="/ev-accelerate-at-home-nm"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="EV Accelerate at Home"
                                             title="EV Accelerate at Home | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <AccelerateAtHome
                                                  stateName={NEW_MEXICO}
                                                  faqKey={EV_ACCELERATE_AT_HOME_NEW_MEXICO}
                                                  price="$12.00"
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              path="/ChargeNM"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="EV Accelerate at Home"
                                             title="EV Accelerate at Home | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <AccelerateAtHome
                                                  stateName={NEW_MEXICO}
                                                  faqKey={EV_ACCELERATE_AT_HOME_NEW_MEXICO}
                                                  price="$12.00"
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />

                         <Route
                              path={["/optimize-your-charge", "/OptimizeCharge"]}
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Optimize Your Charge"
                                             title="Optimize Your Charge | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <OptmizeYourCharge stateName={region} faqKey={handleFaqKey()} />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/optimize-your-charge-nm"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Optimize Your Charge"
                                             title="Optimize Your Charge | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <OptmizeYourCharge
                                                  stateName={NEW_MEXICO}
                                                  faqKey={OPTIMIZE_YOUR_CHARGE_NEW_MEXICO}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />

                         <Route
                              path={["/optimize-your-charge-mn", "/OptimizeChargeMN"]}
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Optimize Your Charge"
                                             title="Optimize Your Charge | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <OptmizeYourCharge
                                                  stateName={MINNESOTA}
                                                  faqKey={OPTIMIZE_YOUR_CHARGE_MINNESOTA}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />

                         <Route
                              exact
                              path="/OptimizeChargeNM"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Optimize Your Charge"
                                             title="Optimize Your Charge | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <OptmizeYourCharge
                                                  stateName={NEW_MEXICO}
                                                  faqKey={OPTIMIZE_YOUR_CHARGE_NEW_MEXICO}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />

                         <Route exact path="/static-optimization">
                              <Redirect to="/optimize-your-charge" />
                         </Route>
                         <Route
                              path={["/home-wiring-rebate", "/HomeWiring"]}
                              render={(props) => {
                                   if (region === "Minnesota" || region === "Wisconsin") {
                                        return <Redirect to="/" />;
                                   } else {
                                        return (
                                             <PageLayout
                                                  language={language}
                                                  changeLanguage={changeLanguage}
                                                  props={props}
                                                  ip={ip}
                                                  uuid={uuid}
                                                  page="Home Wiring Rebate Colorado"
                                                  title="Home Wiring | Xcel EV Shopping Advisor"
                                                  zipcode={userPrefs.get("zipcode")}
                                                  userLocation={this.state.userLocation}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                             >
                                                  <HomeWiringRebate
                                                       faqKey={
                                                            this.state.userLocation?.region === NEW_MEXICO
                                                                 ? HOME_WIRING_REBATE_NEW_MEXICO
                                                                 : HOME_WIRING_REBATE_COLORADO
                                                       }
                                                       stateName={this.state.userLocation?.region || COLORADO}
                                                  />
                                             </PageLayout>
                                        );
                                   }
                              }}
                         />
                         <Route
                              exact
                              path="/home-wiring-rebate-nm"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Home Wiring Rebate Colorado"
                                             title="Home Wiring | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <HomeWiringRebate
                                                  faqKey={HOME_WIRING_REBATE_NEW_MEXICO}
                                                  stateName={NEW_MEXICO}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/homewiringnm"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Home Wiring Rebate Colorado"
                                             title="Home Wiring | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <HomeWiringRebate
                                                  faqKey={HOME_WIRING_REBATE_NEW_MEXICO}
                                                  stateName={NEW_MEXICO}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/subscription-pilot-mn"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Subscription Service Pilot"
                                             title="Subscription Service Pilot | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <img
                                                  src={evChargingPlug}
                                                  alt="Hero"
                                                  style={{ minWidth: "100%", maxWidth: "100%" }}
                                             />
                                             <ContentCenter
                                                  pageHeaderLead="EV Subscription service pilot"
                                                  pageHeaderHeadLine="All the charging you need for one low, flat monthly price."
                                                  pageHeaderSubHeading="For a limited time, you have the opportunity to join our Electric Vehicle (EV) Subscription Service pilot. If you’re an EV driver in Minnesota,
                      you could be one of 150 customers to participate. We’ll install a Level 2 charger, and you’ll charge overnight for one flat price on your monthly bill."
                                                  sectionStyle={{ backgroundColor: "#F6F8FB" }}
                                             >
                                                  <SubscriptionServicePilot
                                                       stateName="Minnesota"
                                                       enrollURL="https://xcel-energy.secure.force.com/EVSubscription/"
                                                  />
                                             </ContentCenter>
                                             <ContentCenter
                                                  pageHeaderLeadId={"frequentlyAskedQuestions"}
                                                  pageHeaderLead="FREQUENTLY ASKED QUESTIONS"
                                                  sectionStyle={{ backgroundColor: "#FFFFFF" }}
                                             >
                                                  <FrequentlyAskedQuestionsSection
                                                       region={region}
                                                       applicableTo={EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA}
                                                  />
                                             </ContentCenter>
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/time-of-day-separate-meter-mn"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="Pay less to charge your EV overnight"
                                             title="Time of Day Separate Meter | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <ContentCenter
                                                  pageHeaderLead={
                                                       <FormattedMessage
                                                            id="MNTimeofDaySeparateMeter"
                                                            defaultMessage="TIME OF DAY - SEPARATE METER"
                                                       />
                                                  }
                                                  pageHeaderHeadLine={
                                                       <FormattedMessage
                                                            id="MNTimeofDaySeparateMeter_header"
                                                            defaultMessage="Invest in a separate meter and pay less to charge your electric vehicle (EV) overnight."
                                                       />
                                                  }
                                                  pageHeaderSubHeading={
                                                       <>
                                                            <FormattedMessage
                                                                 id="MNTimeofDaySeparateMeter_subheader"
                                                                 defaultMessage="EV drivers have the option to charge at night and on weekends for a reduced electric price. Note - this pricing plan requires investment in a separate meter and is a good option for less than 5% of our customers."
                                                            />
                                                            <br />
                                                            <br />
                                                            <FormattedMessage
                                                                 id="MNTimeofDaySeparateMeter_subheader_three"
                                                                 defaultMessage="Customers with detached garages that do not already have an existing Xcel Energy electric meter on the garage will be required to install two electric meters. This is as listed in the {link1}, as well as the {link2}."
                                                                 values={{
                                                                      link1: (
                                                                           <a
                                                                                href="https://www.xcelenergy.com/staticfiles/xe-responsive/Admin/Managed%20Documents%20&%20PDFs/Xcel-Energy-Standard-For-Electric-Installation-and-Use.pdf"
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                           >
                                                                                <FormattedMessage
                                                                                     id="xcelEnergyStandardLink"
                                                                                     defaultMessage="Xcel Energy Standard for Electric Installation and Use"
                                                                                />
                                                                           </a>
                                                                      ),
                                                                      link2: (
                                                                           <a
                                                                                href="https://www.xcelenergy.com/staticfiles/xe-responsive/Energy%20Portfolio/EV-Electric-Pricing-Plan-Set-Up-Guide%20.pdf"
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                           >
                                                                                <FormattedMessage
                                                                                     id="xcelEnergyContractorLink"
                                                                                     defaultMessage="Electric Vehicle Pricing Plan: Contractor Setup Guide"
                                                                                />
                                                                           </a>
                                                                      ),
                                                                 }}
                                                            />
                                                       </>
                                                  }
                                                  sectionStyle={{ backgroundColor: "#F6F8FB" }}
                                             >
                                                  <h3 className="page-subHeader">
                                                       <FormattedMessage
                                                            id="enrollmentProcessHeader"
                                                            defaultMessage="Enrollment Process"
                                                       />
                                                  </h3>

                                                  <div className="page-orderedListContainer">
                                                       <ol className="page-orderedList">
                                                            <li>
                                                                 <FormattedMessage
                                                                      id="enrollmentProcessList1"
                                                                      defaultMessage="Before continuing, visit our {link} to find the program and pricing that best fits your lifestyle. This program is the best option for less than 5% of our customers."
                                                                      values={{
                                                                           link: (
                                                                                <a
                                                                                     href="https://ev.xcelenergy.com/home-charging-advisor"
                                                                                     target="_blank"
                                                                                     rel="noopener noreferrer"
                                                                                >
                                                                                     <FormattedMessage
                                                                                          id="enrollmentProcessList1Link"
                                                                                          defaultMessage="Home Charging Advisor"
                                                                                     />
                                                                                </a>
                                                                           ),
                                                                      }}
                                                                 />
                                                            </li>
                                                            <li>
                                                                 <FormattedMessage
                                                                      id="enrollmentProcessList2"
                                                                      defaultMessage="Call Xcel Energy at 800.895.4999 so we can help determine which pricing plan will work best."
                                                                 />
                                                            </li>
                                                            <li>
                                                                 <FormattedMessage
                                                                      id="enrollmentProcessList3"
                                                                      defaultMessage="If a fast charger is being installed, customer contacts {email} to check the load increase."
                                                                      values={{
                                                                           email: (
                                                                                <a href="mailto:builders.call.line@xcelenergy.com">
                                                                                     <FormattedMessage
                                                                                          id="enrollmentProcessList3Link"
                                                                                          defaultMessage="builders.call.line@xcelenergy.com"
                                                                                     />
                                                                                </a>
                                                                           ),
                                                                      }}
                                                                 />
                                                            </li>
                                                            <li>
                                                                 <FormattedMessage
                                                                      id="enrollmentProcessList4"
                                                                      defaultMessage="Contact an electrician for an estimate on the meter housing equipment installation. Check out this {link} of EV electricians who are familiar with our EV Pricing Plan."
                                                                      values={{
                                                                           link: (
                                                                                <a
                                                                                     href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/MN-EV-Electricians-Information-Sheet.pdf?_ga=2.227968925.1008861962.1671458046-273790377.1662568995"
                                                                                     target="_blank"
                                                                                     rel="noopener noreferrer"
                                                                                >
                                                                                     <FormattedMessage
                                                                                          id="enrollmentProcessList4Link"
                                                                                          defaultMessage="list"
                                                                                     />
                                                                                </a>
                                                                           ),
                                                                      }}
                                                                 />
                                                            </li>
                                                            <li>
                                                                 <FormattedMessage
                                                                      id="enrollmentProcessList5"
                                                                      defaultMessage="To participate in the EV Pricing Plan, you will need to have an additional meter or multiple meters installed. You'll need to work with a certified electrician to install a meter socket and perform any additional wiring."
                                                                 />
                                                            </li>
                                                            <li>
                                                                 <FormattedMessage
                                                                      id="enrollmentProcessList6"
                                                                      defaultMessage="Sign up using Xcel Energy's Building and Remodeling site {link} to fill out an application for adding a 2nd electric meter (for attached structures) or a 2nd electric service (for detached structures) based on what you would require."
                                                                      values={{
                                                                           link: (
                                                                                <a
                                                                                     href="https://my.xcelenergy.com/s/partner-resources/build-remodel"
                                                                                     target="_blank"
                                                                                     rel="noopener noreferrer"
                                                                                >
                                                                                     <FormattedMessage
                                                                                          id="enrollmentProcessList6Link"
                                                                                          defaultMessage="Xcel Energy"
                                                                                     />
                                                                                </a>
                                                                           ),
                                                                      }}
                                                                 />
                                                            </li>
                                                            <li>
                                                                 <FormattedMessage
                                                                      id="enrollmentProcessList6"
                                                                      defaultMessage="Work with your electrician to send completed inspection document to {email}."
                                                                      values={{
                                                                           email: (
                                                                                <a href="mailto:builders.call.line@xcelenergy.com">
                                                                                     <FormattedMessage
                                                                                          id="enrollmentProcessList3Link"
                                                                                          defaultMessage="builders.call.line@xcelenergy.com"
                                                                                     />
                                                                                </a>
                                                                           ),
                                                                      }}
                                                                 />
                                                            </li>
                                                            <li>
                                                                 <FormattedMessage
                                                                      id="enrollmentProcessList7"
                                                                      defaultMessage="After our teams receive your inspection documents and verify your applications, we will visit your home and install the new meter next to your existing one."
                                                                 />
                                                            </li>
                                                            <li>
                                                                 <FormattedMessage
                                                                      id="enrollmentProcessList8"
                                                                      defaultMessage="As soon as the second meter is installed and the service is energized, you can start charging and saving."
                                                                 />
                                                            </li>
                                                       </ol>
                                                  </div>

                                                  <ChargeOvernight />
                                             </ContentCenter>
                                             <ContentCenter
                                                  pageHeaderLead={
                                                       <FormattedMessage
                                                            id="MNTimeofDaySeparateMeter_meterdiagrams_heading"
                                                            defaultMessage="METER DIAGRAMS"
                                                       />
                                                  }
                                                  sectionStyle={{ backgroundColor: "#FFFFFF" }}
                                             >
                                                  <>
                                                       <MeterDiagrams />
                                                       <ContentCenter
                                                            pageHeaderLeadId={"frequentlyAskedQuestions"}
                                                            pageHeaderLead="FREQUENTLY ASKED QUESTIONS"
                                                            sectionStyle={{ backgroundColor: "#FFFFFF" }}
                                                       >
                                                            <FrequentlyAskedQuestionsSection
                                                                 region={region}
                                                                 applicableTo={TIME_OF_DAY_SEPARATE_METER_MINNESOTA}
                                                            />
                                                       </ContentCenter>
                                                       <hr />
                                                       <br />
                                                       <CallUs builders={true} />
                                                  </>
                                             </ContentCenter>
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/home-charging-advisor"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="home-charging-advisor"
                                             title="Home Charging Advisor | Xcel EV Shopping Advisor"
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <RateComparison
                                                  history={props.history}
                                                  electricVehicles={this.state.electricVehicles}
                                                  usedElectricVehicles={this.state.usedElectricVehicles}
                                                  userLocation={this.state.userLocation}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/events"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="events"
                                             title={process.env.REACT_APP_PAGES_EVENTS_TITLE}
                                             image={eventsScreenshot}
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <Events {...props} ip={ip} uuid={uuid} />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/charging-perks"
                              render={(props) => {
                                   return (
                                     <PageLayout
                                       language={language}
                                       changeLanguage={changeLanguage}
                                       props={props}
                                       ip={ip}
                                       uuid={uuid}
                                       page="charging-perks"
                                       title="Charging Perks | Xcel EV Shopping Advisor"
                                       image={eventsScreenshot}
                                       zipcode={userPrefs.get("zipcode")}
                                       userLocation={this.state.userLocation}
                                       electricVehicles={
                                         this.state.electricVehicles
                                       }
                                       usedElectricVehicles={
                                         this.state.usedElectricVehicles
                                       }
                                     >
                                       <img
                                         src={chargingPerks}
                                         alt="Hero"
                                         style={{
                                           minWidth: "100%",
                                           maxWidth: "100%",
                                         }}
                                       />
                                       <ContentCenter
                                         pageHeaderLead="Charging Perks"
                                         pageHeaderHeadLine="Get rewarded for enabling a cleaner, more efficient energy grid"
                                         pageHeaderSubHeading="Charging Perks from Xcel Energy is a low-effort, high-value solution that rewards electric vehicle (EV) drivers for charging at times when it’s most beneficial to the electric grid. Once you enroll, all you have to do is plug in your EV — and your smart charging service provider and Xcel Energy will do the rest. Plus, you can get a $50 bill credit just for signing up!"
                                         pageHeaderSubHeadingIdTwo="Charging Perks Pilot"
                                         pageHeaderSubHeadingTwo="If you drive a battery electric vehicle (BEV) or plug-in hybrid vehicle (PHEV) from BMW, Ford, Chevrolet (GM), or Tesla and do some charging at home with a Level 2 charger, you can apply to participate through your smart charging provider. {lineBreak} If you charge your electric vehicle with a Level 2 Wallbox charger at home, you can also participate through your smart charging provider. {lineBreak} You must plug in your vehicle weekly to participate in this program. {lineBreak} EVs and charger from the list below and their compatible smart charging service providers are currently eligible for the Charging Perks program. Link to the enrollment page is included."
                                         sectionStyle={{
                                           backgroundColor: "#F6F8FB",
                                         }}
                                       >
                                         <ChargingPerksPilot />
                                       </ContentCenter>
                                       <ContentCenter
                                         pageHeaderLeadId={
                                           "frequentlyAskedQuestions"
                                         }
                                         pageHeaderLead="FREQUENTLY ASKED QUESTIONS"
                                         sectionStyle={{
                                           backgroundColor: "#FFFFFF",
                                         }}
                                       >
                                         <FrequentlyAskedQuestionsSection
                                           region={region}
                                           applicableTo={
                                             CHANGING_PERKS_PILOT_COLORADO
                                           }
                                         />
                                         <CPAdditionalInfo />
                                       </ContentCenter>
                                     </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path={["/ev-rebate-co", "/EVRebateCO"]}
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             ip={ip}
                                             uuid={uuid}
                                             page="IQ Rebate Page"
                                             title="EV Rebate - Colorado | Xcel EV Shopping Advisor"
                                             image={eventsScreenshot}
                                             zipcode={userPrefs.get("zipcode")}
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <img
                                                  src={incomeQualifiedHero}
                                                  alt="Hero"
                                                  style={{ minWidth: "100%", maxWidth: "100%" }}
                                             />
                                             <ContentCenter
                                                  pageHeaderLead={
                                                       <FormattedMessage
                                                            id="evRebateCOHeader"
                                                            defaultMessage="INCOME-QUALIFIED ELECTRIC VEHICLE REBATE"
                                                            description="EV Rebate CO Header"
                                                       />
                                                  }
                                                  pageHeaderHeadLine={
                                                       <FormattedMessage
                                                            id="evRebateCOSubHeader"
                                                            defaultMessage="Save up to $5,500 on an Electric Vehicle (EV)"
                                                            description="EV Rebate CO Sub Header"
                                                       />
                                                  }
                                                  pageHeaderSubHeading={
                                                       <FormattedMessage
                                                            id="evRebateCOSubHeaderDescription"
                                                            defaultMessage="Income-qualified customers can receive $3,000 off a used or $5,500 off a new EV when you buy or lease from a Colorado-based car dealer."
                                                            description="EV Rebate CO Sub Header Description"
                                                       />
                                                  }
                                                  sectionStyle={{ backgroundColor: "#F6F8FB" }}
                                             >
                                                  <p style={{ textAlign: "center", fontSize: "1rem" }}>
                                                       <FormattedMessage
                                                            id="evRebateCONote"
                                                            defaultMessage="Note: Xcel Energy's Electric Vehicle Rebate is stackable with the Colorado State EV Credit, but can be taken if you participate in the Colorado Vehicle Exchange Program."
                                                            description="Note: Xcel Energy's Electric Vehicle Rebate is stackable with the Colorado State EV Credit, but can be taken if you participate in the Colorado Vehicle Exchange Program."
                                                       />
                                                  </p>
                                                  <IncomeQualified />
                                             </ContentCenter>
                                             <ContentCenter
                                                  pageHeaderLead={
                                                       <FormattedMessage
                                                            id="frequentlyAskedQuestions"
                                                            defaultMessage="FREQUENTLY ASKED QUESTIONS"
                                                            description="FREQUENTLY ASKED QUESTIONS"
                                                       />
                                                  }
                                                  sectionStyle={{ backgroundColor: "#FFFFFF" }}
                                             >
                                                  <FrequentlyAskedQuestionsSection
                                                       region={region}
                                                       applicableTo={INCOME_QUALIFIED_COLORADO}
                                                  />
                                             </ContentCenter>
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route
                              exact
                              path="/compare-used-vehicles"
                              render={(props) => {
                                   return (
                                        <PageLayout
                                             zipcode={userPrefs.get("zipcode")}
                                             language={language}
                                             changeLanguage={changeLanguage}
                                             props={props}
                                             page="Vehicles | Xcel EV Shopping Advisor"
                                             title="Compare Used Vehicles | Xcel EV Shopping Advisor"
                                             ip={ip}
                                             uuid={uuid}
                                             activePage="vehicles"
                                             userLocation={this.state.userLocation}
                                             electricVehicles={this.state.electricVehicles}
                                             usedElectricVehicles={this.state.usedElectricVehicles}
                                        >
                                             <CompareVehicles
                                                  props={props}
                                                  ip={this.state.ipData ? this.state.ipData.ip : null}
                                                  uuid={this.state.uuid}
                                                  electricVehicles={this.state.usedElectricVehicles}
                                                  gasolineVehicles={this.state.gasolineVehicles}
                                                  userLocation={this.state.userLocation}
                                                  compareUsedVehicles={true}
                                             />
                                        </PageLayout>
                                   );
                              }}
                         />
                         <Route component={FourOhFour} />
                    </Switch>
               </UserPrefsContext.Provider>
          );
     }
}
export default withRouter(Root);
