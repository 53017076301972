import React from "react";

const ChargerOptions = () => {
  return (
    <>
      <p>
        We evaluated high-quality level 2 chargers to provide you with options.
        You can select either the{" "}
        <a
          href="https://www.chargepoint.com/resources/chargepoint-home-flex-cph50-brochure/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ChargePoint Home Flex
        </a>{" "}
        or{" "}
        <a
          href="https://support-emobility.enelx.com/hc/en-us/articles/360038171892-Next-Gen-JuiceBox-40-Data-Sheet"
          target="_blank"
          rel="noopener noreferrer"
        >
          Enel X Way Juice Box
        </a>
        . Both chargers are ENERGY STAR®-certified, UL Listed for safety, Wi-Fi
        enabled and can easily be managed with the manufacturer’s mobile app.
      </p>

      <p>Note: Xcel Energy does not endorse one EV charger over the other.</p>
    </>
  );
};

export default ChargerOptions;
