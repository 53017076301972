import React, { useContext } from "react";
import getOEMBadge from "../../functions/vehicle/getOEMBadge";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const InventoryDealersFilter = ({ inventory, dealersTypes }) => {
  const userPrefs = useContext(UserPrefsContext);
  const [activeAccordion, setActiveAccordion] = React.useState(false);

  if (!inventory) return null;

  const inventoryDealers = userPrefs.get("inventoryDealers");

  let dealers = {};

  inventory.forEach((ev) => {
    const dealerId = ev.dealer.dealer_id;

    if (ev.dealer.type === "Gold" && dealers[dealerId] === undefined) {
      dealers[dealerId] = ev.dealer;
    }
  });

  const uniqueDealers = Object.values(dealers);

  const updateInventoryDealer = (dealer) => {
    let newFilters = { ...inventoryDealers };
    if (newFilters[dealer.dealer_id] === undefined) {
      newFilters[dealer.dealer_id] = false;
    }
    newFilters[dealer.dealer_id] = !newFilters[dealer.dealer_id];

    userPrefs.set({ inventoryDealers: newFilters });
  };

  const renderDealers = uniqueDealers.map((dealer) => {
    return (
      <button
        key={dealer.dealer_id}
        onClick={() => updateInventoryDealer(dealer)}
        className={`dealer-card ${
          inventoryDealers[dealer.dealer_id] ? "active" : ``
        }`}
      >
        <div className="row py-2 align-items-center">
          <div className="col-3">
            <img alt="All-Electric" height="30" src={getOEMBadge(dealer.oem)} />
          </div>
          <div className="col-9">
            <span>{dealer.name}</span>
          </div>
        </div>
      </button>
    );
  });

  return (
    <div className="input-well inventory-filter-controls">
      <div className={`accordion-header ${activeAccordion ? "expanded" : ""}`}>
        <p className="h2">
          <FormattedMessage
            id="inventory-dealers-filter.shop-dealers"
            defaultMessage="Shop Gold Dealers"
            description="Shop Gold Dealers"
          />
        </p>
        <span
          className={`expand-collapse-icon`}
          onClick={() => setActiveAccordion((v) => !v)}
        />
      </div>
      <div className="accordion-body">
        <p classname="disclaimer">
          <FormattedMessage
            id="inventory-dealers-filter.disclaimer"
            defaultMessage="Gold Dealers are our most experienced and EV-focused allies, often located in and around larger metro area."
            description="Gold Dealers are our most experienced and EV-focused allies, often located in and around larger metro area."
          />
        </p>
        <div className="dealer-grid-container">{renderDealers}</div>
        <div className="arrow-right-wrapper">
          <Link to="/dealers">
            <FormattedMessage
              id="inventory-dealers-filter.see-all"
              defaultMessage="See all dealers"
              description="See all dealers"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InventoryDealersFilter;
