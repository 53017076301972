import React, { useEffect } from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "../../../components/IncentiveCatalog/IncentiveCatalog"
import IncentivePreferences from "../../../components/IncentivesPreferences/IncentivePreferences";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage, useIntl } from 'react-intl';

const Incentives = ({ electricVehicles, incentives, ip, uuid, zipcode }) => {

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });

  const intl = useIntl()

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );

  return (
    <>
      <section className="container-fluid" id="incentives">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center title">
              <h2>
                <FormattedMessage
                  id="incentivesHeader"
                  defaultMessage="Discover Incentives and Rebates"
                  description="Discover Incentives and Rebates"
                />
              </h2>
              <p className="lead">
                <FormattedMessage
                  id="incentivesSubHeader"
                  defaultMessage="You can lower the upfront cost of an EV with potential tax incentives, credits and special rebates for Xcel Energy customers."
                  description="Incentives SubHeader"
                  values={{
                    lineBreak: <br />
                  }}
                />
              </p>
              <IncentivePreferences
                incentives={incentives}
                electricVehicles={electricVehicles}
                titleText="Personalize Incentives"
                btnText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives" }) : 'Personalize Incentives'}
                zipcode={zipcode}
              />
            </div>
          </div>
          <br />
          {renderIncentives}
          <br />
          <p className="legal-disclaimer text-center">
            <FormattedMessage
              id="incentivsDisclaimer"
              defaultMessage="All published rebates, discounts, incentives and other promotional offerings are subject to dealer discretion.  The dealer is responsible for final offerings and content.  See dealer website for details and restrictions."
              description="Incentives Disclaimer"
            />
          </p>
        </div>
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
