const capitalizedInPurchase = vehicle => {
  const incentives = vehicle?.incentives
  const newEv = vehicle?.available_for_purchase_as_new

  let incentivesArray = incentives || [];

  return incentivesArray.reduce((acc, incentive) => {
    let incentiveAmount =
      newEv === incentive.applicable_to_new_vehicles && 
      incentive &&
      incentive.evaluation &&
      incentive.evaluation.applicable_to_ownership &&
      !isNaN(
        parseInt(incentive.evaluation.amount_in_purchase, 10)
      )
        ? parseInt(incentive.evaluation.amount_in_purchase, 10)
        : 0;

    return acc + incentiveAmount;
  }, 0);
};

export default capitalizedInPurchase;
