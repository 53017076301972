const HOLIDAYS = [
  "2019-01-01",
  "2019-04-19",
  "2019-05-27",
  "2019-07-04",
  "2019-09-02",
  "2019-11-28",
  "2019-12-25",
];

const mn_a02 = {
  name: "mn-a02",
  title: 'Time of Day - Whole Home',
  separatelyMetered: false,
  isBundled: false,
  includeHomeWiringEstimator: true,
  upfrontChargerCost: 999,
  rateElements: [
    {
      name: 'Basic Service Charge',
      rateElementType: 'FixedPerMonth',
      rateComponents: [
        {
          charge: 10,
          name: 'Per Month',
        },
      ],
    },
    {
      name: 'Energy charge per kWh',
      rateElementType: 'EnergyTimeOfUse',
      rateComponents: [
        {
          charge: 0.20497,
          months: [5, 6, 7, 8],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 9a-9p
          exceptForDays: HOLIDAYS,
          name: "Summer On Peak",
        },
        {
          charge: 0.16508,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 9a-9p
          exceptForDays: HOLIDAYS,
          name: "Winter On Peak",
        },
        {
          charge: 0.0417,
          months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // All year
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // All week
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 21, 22, 23], // 9p - 9a
          name: "Off Peak - Overnight",
        },
        {
          charge: 0.0417,
          months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // All year
          daysOfWeek: [0, 6], // M-F
          hourStarts: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 9a-9p
          exceptForDays: HOLIDAYS,
          name: "Weekends",
        },
        {
          charge: 0.0417,
          months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // All year
          hourStarts: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], // 9a-9p
          onlyOnDays: HOLIDAYS,
          name: "Holidays",
        },
      ],
    },
  ],
};

export default mn_a02;
