import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { FormattedMessage } from 'react-intl';
import logo from "../../../assets/images/logo.svg"
// import InputZipcode from "../../InputZipcode/InputZipcode"
import FacebookIcon from "../../../assets/images/icons/Facebook.svg"
import TwitterIcon from "../../../assets/images/icons/Twitter.svg"
import InstagramIcon from "../../../assets/images/icons/Instagram.svg"
import LinkedinIcon from "../../../assets/images/icons/Linkedin.svg"
import YoutubeIcon from "../../../assets/images/icons/Youtube.svg"
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
const Footer = ({ language, changeLanguage }) => {

  // const languageOptions = () => {
  //   return (
  //     <>
  //       <option
  //         value={'ES'}
  //         key={'ES'}
  //       >
  //         {'Spanish'}
  //       </option>
  //     </>
  //   )
  // }

  // const onChangeLanguage = e => {
  //   changeLanguage(e.target.value)
  //   SmoothScroll("root")
  // }

  return (
    <footer className="Footer">
      <div className="container pt-5 pb-4">
        <div className="row pb-3 more-info">
          <div className="col-md-12">
            <span>
              <FormattedMessage
                id="footerAdditionalInfo"
                defaultMessage="For additional information about getting set up with charging your EV, email us at"
                description="Additonal Information"
              />
              &nbsp;
              <a
                style={{ color: "white", textTransform: "lowercase" }}
                href={"mailto:ElectricVehicles@xcelenergy.com"}
              >
                ElectricVehicles@xcelenergy.com
              </a>
              &nbsp;
              <FormattedMessage
                id="footerAdditionalInfoTwo"
                defaultMessage="or call us at 800.895.4999 to speak with one of our Electric Vehicle Advisors."
                description="Additonal Information Two"
              />
            </span>
          </div>
          <div className="disclaimer_container">
              <DisclaimerComponent clientName="zappynobackground"/>
        </div>
        </div>
        <div className="row local-links pb-4">
          <div className="col-md-2">
            {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
              <Link to="/vehicles">
                <FormattedMessage
                  id="electricVehicles"
                  defaultMessage="Electric Vehicles"
                  description="Electric Vehicles Footer"
                  values={{
                    electricVehicles: process.env.REACT_APP_PAGES_VEHICLES_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
          </div>
          <div className="col-md-2">
            {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (<>
              <Link to="/compare-vehicles">
                <FormattedMessage
                  id="compareVehicles"
                  defaultMessage="Compare Vehicles"
                  description="Compare Vehicles Footer"
                  values={{
                    compareVehicles: process.env.REACT_APP_PAGES_COMPARE_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
          </div>
          <div className="col-md-2">
            {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (<>
              <Link to="/incentives">
                <FormattedMessage
                  id="incentives"
                  defaultMessage="Incentives"
                  description="Incentives Footer"
                  values={{
                    incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
          </div>
          <div className="col-md-2">
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (<>
              <Link to="/charging-stations">
                <FormattedMessage
                  id="chargingStations"
                  defaultMessage="Charging Stations"
                  description="Charging Stations Footer"
                  values={{
                    chargingStations: process.env.REACT_APP_PAGES_CHARGING_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
          </div>
          <div className="col-md-2">
            {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (<>
              <Link to="/dealers">
                <FormattedMessage
                  id="dealers"
                  defaultMessage="Dealers"
                  description="Dealers Footer"
                  values={{
                    dealers: process.env.REACT_APP_PAGES_DEALERS_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
          </div>
          {process.env.REACT_APP_PAGES_EVENTS_ENABLED ? (<>
            <div className="col-md-2">
              <Link to="/events">
                <FormattedMessage
                  id="events"
                  defaultMessage="Events"
                  description="Events Footer"
                />
              </Link>
            </div>
          </>) : (null)}
          {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (<>
            <Link to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}>
              {process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</Link>
          </>) : (null)}
          {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (<>
            <Link to="/faq">{process.env.REACT_APP_PAGES_FAQ_TITLE}</Link>
          </>) : (null)}
          {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (<>
            <Link to="/home-chargers" className="">
              <FormattedMessage
                id="homeChargers"
                defaultMessage="Home Chargers"
                description="Home Chargers Footer"
                values={{
                  homeChargers: process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE
                }}
              />
            </Link>
          </>) : (null)}
          {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED ? (<>
            <Link to="/electricians" className="">
              <FormattedMessage
                id="electricians"
                defaultMessage="Electricians"
                description="Electricians Footer"
                values={{
                  homeChargers: process.env.REACT_APP_PAGES_ELECTRICIANS_TITLE
                }}
              />
            </Link>
          </>) : (null)}
        </div>
        <div className="row logo-zipcode pb-4">
          <div className="col-md-6">
            {/* <div className="row"> */}
            <Link to="/">
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid"
                style={{ maxWidth: "180px" }}
              />
            </Link>
            {/* </div> */}
          </div>
          <div className="col-md-6">
            <div className="social-media-row">
              <a
                href="https://www.facebook.com/XcelEnergyMN"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="social-media-icon" alt="Facebook Icon" src={FacebookIcon} />
              </a>
              <a
                href="https://twitter.com/XcelEnergyMN"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="social-media-icon" alt="Twitter Icon" src={TwitterIcon} />
              </a>
              <a
                href="https://www.instagram.com/xcelenergy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="social-media-icon" alt="Instagram Icon" src={InstagramIcon} />
              </a>
              <a
                href="https://www.linkedin.com/company/xcel-energy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="social-media-icon" alt="Linkedin Icon" src={LinkedinIcon} />
              </a>
              <a
                href="https://www.youtube.com/c/XcelEnergyInYourCommunity"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="social-media-icon" alt="Youtube Icon" src={YoutubeIcon} />
              </a>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-md-4">
            {/* <div className="row"> */}
            <p style={{ textAlign: "left", marginBottom: 0, color: "#fff", fontSize: "11px" }}>
              <span style={{ fontFamily: "Arial, Helvetica" }}>&copy;</span>
              {(new Date().getFullYear().toString())} {process.env.REACT_APP_FULL_COMPANY_NAME}.                 <FormattedMessage
                id="footer.allRights"
                defaultMessage="All Rights Reserved."
                description="All Rights Reserved. Footer"
              />
            </p>
            {/* </div> */}
           
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-3">
                <a
                  href="https://www.xcelenergy.com/staticfiles/xe-responsive/Admin/My%20Account_Terms_and_Conditions.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="termsAndConditions"
                    defaultMessage="Terms & Conditions"
                    description="Terms & Conditions. Footer"
                  />
                </a>
              </div>
              <div className="col-md-3">
                <a
                  href="https://www.xcelenergy.com/stateselector?stateSelected=true&goto=%2Fbilling_and_payment%2Fcustomer_data_%26_privacy%2Fprivacy_policy_and_customer_data_access"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="privacyPolicy"
                    defaultMessage="Privacy Policy"
                    description="Privacy Policy Footer"
                  />
                </a>
              </div>
              <div className="col-md-3">
                <a
                  href="https://www.xcelenergy.com/stateselector?stateSelected=true&goto=%2Faccessibility"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="accessibility"
                    defaultMessage="Accessibility"
                    description="Accessibility Footer"
                  />
                </a>
              </div>
              <div className="col-md-3">
                <a
                  href="https://stories.xcelenergy.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="connectBlog"
                    defaultMessage="Connect Blog"
                    description="Connect Blog Footer"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
