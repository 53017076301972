import React from "react";
import { FormattedMessage } from "react-intl";

import ChargingTimeOfDay from "../../assets/images/graphs/charging-time-of-day.png";

const HowWorks = () => {
    return (
        <>
            <ul>
                <li>
                    <FormattedMessage
                        id="MNTimeofDaySeparateMeter_ifyoucharge1"
                        defaultMessage="If you charge from 9:00 p.m. to 9:00 a.m. Monday through Friday, and at any time on the weekends, the cost to charge will be about half the normal cost of energy."
                      />
                </li>
                <li>
                    <FormattedMessage
                        id="MNTimeofDaySeparateMeter_ifyoucharge2"
                        defaultMessage="If you charge from 9:00 a.m. to 9:00 p.m. Monday through Friday, the cost to charge will be about double the normal cost of energy. "
                    />
                </li>
            </ul>

            <div className="imgBucket">
                <img 
                    src={ChargingTimeOfDay}
                    alt="Charging costs by time of day."
                    style={{width: "800px", maxWidth: "100%", margin: "0 auto", display: "block"}}
                />
            </div>

            <p>
                <FormattedMessage
                    id="MNTimeofDaySeparateMeter_additionalcharge_v2"
                    defaultMessage="Additional $6.00 monthly service charge for the additional EV meter."
                />
            </p>
        </>
    )
}

export default HowWorks;