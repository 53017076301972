import React from "react";
import PropTypes from "prop-types";

import OneTenOne from "../OneTenOne/OneTenOne";

const GrandTitledParas = (
    {
        children,
        classNames,
        colonHeader,
        customID
    }
) => {
    return (
        <OneTenOne
            customID={customID}
            classNames={`grand-titled-para ${classNames}`}
            colonHeader={colonHeader}
        >
            {children}
        </OneTenOne>
    );
}

export default GrandTitledParas;

GrandTitledParas.propTypes = ({
    children: PropTypes.node.isRequired,
    classNames: PropTypes.string,
    colonHeader: PropTypes.any
})