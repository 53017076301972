import { useEffect, useState } from 'react';

const useSelectedVehicle = (electricVehicles, selectedVehicleId) => {
  // The selectedVehicleId may refer to a vehicle that is no longer in the list of
  // vehicles returned from the API. In that case, we want to use a random vehicle,
  // but we want to use the same random vehicle on subsequent renders. So, we store
  // a randomVehicleId in state once a non-null list of vehicles is passed into
  // this hook.
  const [randomVehicleId, setRandomVehicleId] = useState();

  useEffect(() => {
    if (!randomVehicleId && electricVehicles) {
      const randomIdx = Math.floor(Math.random() * electricVehicles.length);
      setRandomVehicleId(
        electricVehicles[randomIdx].electric_vehicle_id
      );
    }
  }, [electricVehicles, randomVehicleId]);

  const emptyObject = {};
  if (!electricVehicles) return emptyObject;

  const selectedVehicle = electricVehicles.find(ev => ev.electric_vehicle_id === parseInt(selectedVehicleId, 10));
  const persistentRandomVehicle = electricVehicles.find(ev => ev.electric_vehicle_id === randomVehicleId);

  return selectedVehicle || persistentRandomVehicle || emptyObject;
}

export default useSelectedVehicle;
