import { useState } from "react";

const getStation = (chargingStations, id) =>
  (chargingStations || []).find(station => station.id === id);

const useSelectedStation = (chargingStations, initialStationId) => {
  const [selectedStation, setSelectedStation] = useState(
    getStation(chargingStations, initialStationId)
  );
  const selectStation = stationId => {
    setSelectedStation(getStation(chargingStations, stationId));
  };

  const deselectStations = () => setSelectedStation(null);

  return [selectedStation, selectStation, deselectStations];
};

export default useSelectedStation;
