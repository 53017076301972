import GoogleAnalytics from "react-ga4";

const trackers = [];
switch (process.env.REACT_APP_ENVIRONMENT) {
  case "production":
    trackers.push({
      trackingId: process.env.REACT_APP_GA_CLIENT_PRODUCTION_TRACKING_ID,
      gaOptions: {
        name: process.env.REACT_APP_GA_CLIENT_PRODUCTION_TRACKING_OPTIONS_NAME,
      },
    });
    trackers.push({
      trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_PRODUCTION_TRACKING_ID,
      gaOptions: {
        name: process.env
          .REACT_APP_GA_ZAPPYRIDE_PRODUCTION_TRACKING_OPTIONS_NAME,
      },
    });
    break;
  case "staging":
    trackers.push({
      trackingId: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_ID,
      gaOptions: {
        name: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_OPTIONS_NAME,
      },
    });
    trackers.push({
      trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_STAGING_TRACKING_ID,
      gaOptions: {
        name: process.env.REACT_APP_GA_ZAPPYRIDE_STAGING_TRACKING_OPTIONS_NAME,
      },
    });
    break;
  default:
}

const GaTracker = {
  initialize: () => {
    if (trackers.length) {
      GoogleAnalytics.initialize(trackers);
    }
  },
  trackPage: (page, options = {}) => {
    const lastCharIndex = page.length - 1;

    page =
      page.length > 1 && page.charAt(lastCharIndex) === "/"
        ? page.substring(0, lastCharIndex)
        : page;

    if (trackers.length) {
      trackers.forEach((tracker) => {
        GoogleAnalytics.set(
          {
            page,
            ...options,
          },
          [tracker.gaOptions.name]
        );
        GoogleAnalytics.send({ hitType: "pageview", page });
      });
    }
  },
  trackEvent: (event = {}, options = {}) => {
    // These fields are required by Google Analytics for events
    if (!event.category || !event.action) {
      return;
    }
    trackers.forEach((tracker) => {
      if (options.constructor === Object && Object.keys(options).length > 0) {
        GoogleAnalytics.set(
          {
            ...options,
          },
          [tracker.gaOptions.name]
        );
      }
      GoogleAnalytics.event(event, [tracker.gaOptions.name]);
    });
  },
};

export default GaTracker;
