export const getByState = ({ stateName, options }) => {
  if (Boolean(options[stateName])) {
    return options[stateName];
  }

  return options?.default || null;
};

export const RenderByState = ({ stateName, options }) =>
  getByState({ stateName, options });

RenderByState.displayName = "RenderByState";
