import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection"
import gasSavings from "../../assets/images/icons/gas_savings.svg"
import maintenanceSavings from "../../assets/images/icons/maintenance_savings.svg"
import purchaseSavings from "../../assets/images/icons/purchase_savings.svg"

import mask from "../../assets/images/mask.png"
import esMask from "../../assets/images/esMask.png"
import pluggedIn from "../../assets/images/plugged-in.png"
import esPluggedIn from "../../assets/images/esPluggedIn.png"
import electricCharger from "../../assets/images/electric-charger.png"
import street from "../../assets/images/street.png"
import batteryFuelStation from "../../assets/images/battery_fuel_station.svg"
import esBatteryFuelStation from "../../assets/images/es_battery_fuel_station.png"

import "./EvsFaq.scss"
import { FormattedMessage, useIntl } from 'react-intl';


const EvsFaq = ({ ip, uuid, props, zipcode, userLocation }) => {
  const intl = useIntl()

  useEffect(() => {
    document.title = intl.formatMessage({ id: "evs101", defaultMessage: "Electric Vehicles 101" })
  });


  const collapsibleSection = ([
    {
      headLine: <FormattedMessage
        id="costToChargeEV"
        defaultMessage="How much does it cost to charge an EV?"
        description="How much does it cost to charge an EV?"
      />,
      body: <p>
        <FormattedMessage
          id="costToChargeEVAnswer"
          defaultMessage="With our low, off-peak prices, driving electric equates to spending about $1 per gallon of gasoline. Explore EV charging programs and rate options."
          description="With our low, off-peak prices, driving electric equates to spending about $1 per gallon of gasoline. Explore EV charging programs and rate options."
          values={{
            lineBreak: <br />,
            link: <Link to="/home-charging-advisor"><FormattedMessage
              id="exploreChargingPrograms"
              defaultMessage="Explore EV charging programs and rate options"
              description="Explore EV charging programs and rate options"
            /></Link>
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="evs101faqWhatIsEV"
        defaultMessage="What is an electric vehicle?"
        description="What is an electric vehicle?"
      />,
      body: <p>
        <FormattedMessage
          id="evs101faqWhatIsEVAnswer"
          defaultMessage="Every EV has a plug and runs on electricity, but there are two main categories of electric vehicles."
          description="Every EV has a plug and runs on electricity, but there are two main categories of electric vehicles."
        />
        &nbsp;
        <br />
        <img src={intl.locale === "ES" ? esBatteryFuelStation : batteryFuelStation} alt="batteries and station with batteries" />
        <FormattedMessage
          id="evs101faqWhatIsEVAnswerTwo"
          defaultMessage="All-electric vehicles are also known as battery electric vehicles (BEVs). BEVs are powered solely by electricity. When the battery depletes, it is recharged using electricity from a charging station."
          description="All-electric vehicles are also known as battery electric vehicles (BEVs). BEVs are powered solely by electricity. When the battery depletes, it is recharged using electricity from a charging station."
        />
        <br />
        <FormattedMessage
          id="evs101faqWhatIsEVAnswerThree"
          defaultMessage="Plug-in hybrid electric vehicles (PHEVs) can operate on electric battery power as well as on gasoline. When the electric battery depletes, the gas-powered engine turns on."
          description="Plug-in hybrid electric vehicles (PHEVs) can operate on electric battery power as well as on gasoline. When the electric battery depletes, the gas-powered engine turns on."
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="evs101faqHowFar"
        defaultMessage="How far can I drive in an EV?"
        description="How far can I drive in an EV?"
      />,
      body: <p>
        <FormattedMessage
          id="evs101faqHowFarAnswer"
          defaultMessage="Several all-electric vehicles can travel 100-300+ miles on a single charge. But if you, like most people, have a daily commute that’s less than 40 miles round trip, virtually every EV can meet your daily driving needs."
          description="Several all-electric vehicles can travel 100-300+ miles on a single charge. But if you, like most people, have a daily commute that’s less than 40 miles round trip, virtually every EV can meet your daily driving needs."
        />
        <br />
        <FormattedMessage
          id="evs101faqHowFarAnswerTwo"
          defaultMessage="Going on a longer trip? Public charging stations are available in many areas."
          description="Going on a longer trip? Public charging stations are available in many areas."
          values={{
            link: <Link to="/charging-stations">
              <FormattedMessage
                id="publicStationsAvailable"
                defaultMessage="Public charging stations are available in many areas."
                description="Public charging stations are available in many areas."
              />
            </Link>
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="evs101faqTypicalMaintenance"
        defaultMessage="What are the typical maintenance costs for an EV?"
        description="What are the typical maintenance costs for an EV?"
      />,
      body: <p>
        <FormattedMessage
          id="evs101faqTypicalMaintenanceAnswer"
          defaultMessage="Electric vehicles typically have lower maintenance costs because they have fewer moving parts. With a battery electric vehicle, there’s no oil to change, no spark plugs or timing belts to replace, no muffler or catalytic converter to repair. Plug-in hybrid EVs use the gas-powered engine less, so the engine needs less overall maintenance. Brakes also last longer, thanks to a unique EV feature: regenerative braking. When you take your foot off the accelerator, the electric motor slows the car down, so you don’t need to apply the brakes as frequently. Regenerative braking technology also captures this extra energy to charge the battery, extending your range."
          description="What are the typical maintenance costs for an EV Answer"
          values={{
            lineBreak: <br />
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="evs101faqBatteries"
        defaultMessage="How long do EV batteries last?"
        description="How long do EV batteries last?"
      />,
      body: <p>
        <FormattedMessage
          id="evs101faqBatteriesAnswer"
          defaultMessage="Federal regulations mandate that an EV’s battery be covered by the auto manufacturer for at least eight years or 100,000 miles. Some automakers offer longer warranties. In some cases, an EV battery can be repaired instead of needing to be replaced."
          description="How long do EV batteries last answer"
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="evs101faqOldBatteries"
        defaultMessage="What happens to the old batteries when they need replacement?"
        description="What happens to the old batteries when they need replacement?"
      />,
      body: <p>
        <FormattedMessage
          id="evs101faqOldBatteriesAnswer"
          defaultMessage="Batteries used in electric vehicles are recyclable and can have many useful lives. Some businesses are looking into second-life industries for EV batteries, such as back-up power storage for buildings."
          description="What happens to the old batteries when they need replacement answer"
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="evs101faqIceCar"
        defaultMessage="What is an ICE car?"
        description="What is an ICE car?"
      />,
      body: <p>
        <FormattedMessage
          id="evs101faqIceCarAnswer"
          defaultMessage="You may hear or see the term “ICE” referring to a gas-powered vehicle. “ICE” stands for Internal Combustion Engine."
          description="What is an ICE car? answer"
        />
      </p>
    },
    {
      headLine: intl.formatMessage({ id: "evs101HowElectrifyFleet", defaultMessage: "How do I electrify my fleet?" }),
      body: <p>
        <FormattedMessage
          id="weCanHelp"
          defaultMessage="We can help!"
          description="We can help!"
        />&nbsp;
        <a
          href="https://mn.my.xcelenergy.com/s/business/ev/fleet"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            id="findOutMoreFleets"
            defaultMessage="Find more about our EV programs and services for fleets."
            description="Find more about our EV programs and services for fleets."
          />

        </a>
      </p>
    },
    {
      headLine: <FormattedMessage
        id="addChargingCommunityBusiness"
        defaultMessage="How can I add charging for my community / business?"
        description="How can I add charging for my community / business?"
      />,
      body: <p>
        <FormattedMessage
          id="weCanHelp"
          defaultMessage="We can help!"
          description="We can help!"
        />&nbsp;
        <a
          href="https://my.xcelenergy.com/s/business/ev"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            id="findOutPublicCharging"
            defaultMessage="Find more about our EV programs and services for public charging."
            description="Find more about our EV programs and services for public charging."
          />
        </a>
      </p>
    },
    {
      headLine: <FormattedMessage
        id="canIChargeWithRenewableEnergy"
        defaultMessage="Can I charge my EV with renewable energy?"
        description="Can I charge my EV with renewable energy?"
      />,
      body: <p>
        <FormattedMessage
          id="canIChargeWithRenewableEnergyOneOOneAnswer"
          defaultMessage="As an Xcel Energy customer, you’re already charging your EV with renewable energy within our energy mix. Additional solar power, wind energy, and other sources all add up to create a diverse fuel mix to meet your energy needs. Want to go further? Explore your renewable program choices to charge with up to 100% renewable energy."
          description="As an Xcel Energy customer, you’re already charging your EV with renewable energy within our energy mix. Additional solar power, wind energy, and other sources all add up to create a diverse fuel mix to meet your energy needs. Want to go further? Explore your renewable program choices to charge with up to 100% renewable energy."
          values={{
            lineBreak: <br />,
            link: <a
              href="https://www.xcelenergy.com/programs_and_rebates/residential_programs_and_rebates/renewable_energy_options_residential"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="exploreRenewableChoices"
                defaultMessage="Explore your renewable program choices to charge with up to 100% renewable energy."
                description="Explore your renewable program choices to charge with up to 100% renewable energy."
              />
            </a>
          }}
        />
      </p>
    },
    {
      headLine: <FormattedMessage
        id="howToChargeMultifamilyBuilding"
        defaultMessage="How can I add charging for my multifamily building?"
        description="How can I add charging for my multifamily building?"
      />,
      body: <p>
        <FormattedMessage
          id="howToChargeMultifamilyBuildingAnswer"
          defaultMessage="We can help! We are here to guide you through the steps of offering EV charging on your property."
          description="We can help! We are here to guide you through the steps of offering EV charging on your property."
          values={{
            link: <a
              href="https://my.xcelenergy.com/s/business/ev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="guideEVChargingProperty"
                defaultMessage="We are here to guide you through the steps of offering EV charging on your property"
                description="We are here to guide you through the steps of offering EV charging on your property"
              />
            </a>
          }}
        />
      </p>
    }
  ]);

  const filteredCollapsibleSection = useMemo(() => {
       if (userLocation && userLocation.region === 'Minnesota') {
         return collapsibleSection.filter((item, index) => {
              return index !== 7 && index !== 8 && index !== 10;
         });
       } else {
         return collapsibleSection;
       }
     }, [userLocation, collapsibleSection]);

  const renderIconTextCard = (({ icon, title, description, key }) => {
    return (
      <div key={key} className="ev-faq-card icon-text">
        <img src={icon} alt="savings" />
        <span className="card-title">{title}</span>
        <p>{description}</p>
      </div>
    )
  })

  const reasonsToGoElectric = [
    {
      title: <FormattedMessage
        id="evs101CostSavings"
        defaultMessage="Cost savings that add up"
        description="Cost savings that add up"
      />,
      cardsContent: [
        renderIconTextCard({
          icon: gasSavings,
          title: <FormattedMessage
            id="evs101GasSavings"
            defaultMessage="Gas Savings"
            description="Gas Savings"
          />,
          description:
            <FormattedMessage
              id="evs101GasSavingsDescription"
              defaultMessage="With our low, off-peak energy prices, driving electric equates to spending about $1 per gallon of gasoline."
              description="With our low, off-peak energy prices, driving electric equates to spending about $1 per gallon of gasoline."
            />,
          key:"gas savings"
        }),
        (
          <img key={"gasoline chart"} src={intl.locale === "ES" ? esMask: mask} height="360px" alt="regular gasoline and electric gasoline info chart" />
        ),
        renderIconTextCard({
          icon: maintenanceSavings,
          title: <FormattedMessage
            id="evs101MaintenanceSavings"
            defaultMessage="Maintenance Savings"
            description="Maintenance Savings"
          />,
          description: <FormattedMessage
            id="evs101MaintenanceSavingsDescription"
            defaultMessage="EVs don’t need as much standard maintenance, saving you money on things like oil changes and tune-ups."
            description="EVs don’t need as much standard maintenance, saving you money on things like oil changes and tune-ups."
          />,
          key: "mainetenance savings"
        }),
        renderIconTextCard({
          icon: purchaseSavings,
          title: <FormattedMessage
            id="evs101PurchaseSavings"
            defaultMessage="Purchase Savings"
            description="Purchase Savings"
          />,
          description: <FormattedMessage
            id="evs101PurchaseSavingsDescription"
            defaultMessage="With federal and local tax credits, manufacturer rebates, local dealership incentives and a growing inventory of used vehicles, it’s never been more affordable to drive electric."
            description="With federal and local tax credits, manufacturer rebates, local dealership incentives and a growing inventory of used vehicles, it’s never been more affordable to drive electric."
          />,
          key: "purchase savings"
        })
      ]
    },
    {
      title: <FormattedMessage
        id="evs101CleanBreak"
        defaultMessage="A clean break for our environment"
        description="A clean break for our environment"
      />,
      cardsContent: [
        (
          <div key={"faq card"} className="ev-faq-card icon-text divided-text">
            <p>
              <FormattedMessage
                id="evs101FuelingCar"
                defaultMessage="Fueling your car with electricity can reduce emissions by two-thirds, versus a gas powered vehicle."
                description="Fueling your car with electricity can reduce emissions by two-thirds, versus a gas powered vehicle."
              />
            </p>
            <br />
            <p style={{ fontSize: "12px" }}>
              <a
                href="https://afdc.energy.gov/vehicles/electric_emissions.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="evs101AlternativeFuelsDataCenter"
                  defaultMessage="Alternative Fuels Data Center: Emissions from Hybrid and Plug-In Electric Vehicles (energy.gov)"
                  description="Alternative Fuels Data Center: Emissions from Hybrid and Plug-In Electric Vehicles (energy.gov)"
                />
              </a>
            </p>
            <hr />
            <p>
              <FormattedMessage
                id="evs101RenewableEnergyProgram"
                defaultMessage="When you enroll in one of Xcel Energy’s renewable energy programs, you can drive with 100% clean energy."
                description="When you enroll in one of Xcel Energy’s renewable energy programs, you can drive with 100% clean energy."
              />
            </p>
          </div>
        ),
        <img key="bev ice graph" src={intl.locale === "ES" ? esPluggedIn : pluggedIn} alt="bev and ice CO2 Graph" />
      ]
    },
    {
      title: <FormattedMessage
        id="evs101ChargingEasyConvenient"
        defaultMessage="Charging that's easy and convenient."
        description="Charging that's easy and convenient."
      />,
      cardsContent: [
        (
          <div key="Charging faq card" className="ev-faq-card icon-text divided-text">
            <p>
              <FormattedMessage
                id="evs101PlugEV"
                defaultMessage="If you can plug in your smart phone, you already know how to charge an EV."
                description="If you can plug in your smart phone, you already know how to charge an EV."
              />
            </p>
            <hr />
            <p>
              <FormattedMessage
                id="evs101SkipTheTrip"
                defaultMessage="Skip the trip to the gas station and charge your electric car at home while you’re asleep."
                description="Skip the trip to the gas station and charge your electric car at home while you’re asleep."
              />
            </p>
          </div>
        ),
        <img key="electric handle" src={electricCharger} alt="electric handle" />
      ]
    },
    {
      title: <FormattedMessage
        id="evs101DrivingFun"
        defaultMessage="Driving that's both fun and practical"
        description="Driving that's both fun and practical"
      />,
      cardsContent: [
        (
          <div key="Driving faq card" className="ev-faq-card icon-text divided-text">
            <p>
              <FormattedMessage
                id="evs101EVDriverBenefits"
                defaultMessage="EV drivers get high performance, quick acceleration, and a quiet, comfortable ride."
                description="EV drivers get high performance, quick acceleration, and a quiet, comfortable ride."
              />
            </p>
            <hr />
            <p>
              <FormattedMessage
                id="evs101ModelsLifestyles"
                defaultMessage="There are EV models to fit most lifestyles, including minivans and SUVs that can hold all your gear and the whole family. There are also more all-wheel drive options than ever before."
                description="Driving Models Lifestyles"
                values={{
                  link: <Link to="/vehicles">
                    <FormattedMessage
                      id="moreOptionsThanBefore"
                      defaultMessage="There are also more all-wheel drive options than ever before."
                      description="There are also more all-wheel drive options than ever before."
                    /></Link>
                }}
              />
            </p>
          </div>
        ),
        <img key="sun image" src={street} alt="sun street view" />
      ]
    }
  ]

  const renderReasonsToGoElectric = reasonsToGoElectric.map((reason, i) => {
    return (
      <div key={i} className="row m-0 flex-column">
        <span className="reason-title">{reason.title}</span>
        <div className="d-flex flex-wrap pb-4 reason-cards">
          {reason.cardsContent}
        </div>
      </div>
    )
  })



  return (
    <>
      <section id="electricVehicles101" className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pageHeaderContainer offset-sm-2 col-sm-8 text-center">
                <div className="pageHeaderLead">
                  <FormattedMessage
                    id="evs101"
                    defaultMessage="ELECTRIC VEHICLES 101"
                    description="ELECTRIC VEHICLES 101"
                  />
                </div>
                <div className="pageHeaderHeadLine">
                  <FormattedMessage
                    id="evs101Header"
                    defaultMessage="Why should you drive electric?"
                    description="Why should you drive electric?"
                  />
                </div>
                <div className="pageHeaderBiLine">
                  <p>
                    <FormattedMessage
                      id="evs101SubHeader"
                      defaultMessage="Many drivers are making the switch to electric vehicles. Here’s some fuel for thought on why you should consider going electric:"
                      description="Many drivers are making the switch to electric vehicles. Here’s some fuel for thought on why you should consider going electric:"
                    />
                  </p>
                </div>
              </div>
              <div className="row d-flex flex-column m-5 reasons-container">
                {renderReasonsToGoElectric}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faqContainer container-fluid">
        <div className="container">
          <div id="faqContainer">
            <div className="row my-5 d-flex" >
              <div className={`col-md-6 offset-md-3 faq`}>
                <FormattedMessage
                  id="frequentlyAskedQuestions"
                  defaultMessage="FREQUENTLY ASKED QUESTIONS"
                  description="FREQUENTLY ASKED QUESTIONS"
                />
              </div>
            </div>
            {
              filteredCollapsibleSection.map((content, index) => {
                return (
                  <CollapsibleSection key={index} headLine={content.headLine} >
                    {content.body}
                  </CollapsibleSection>
                )
              })
            }
          </div>
        </div>
      </section>
    </>
  );
};
export default EvsFaq;

EvsFaq.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string,
  props: PropTypes.object
};