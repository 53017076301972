import React from "react";
import PropTypes from "prop-types";

import ChargingPinPublic from "./../../../client_customizations/assets/images/icons/charger-pin-public.png";
import ChargingPinHighPower from "./../../../client_customizations/assets/images/icons/charger-pin-high-power.png";
import ChargingPinDefault from "./../../../client_customizations/assets/images/icons/charger-pin-private.png";
import isSuperChargingStation from '../../../utils/predicates/isSuperChargingStation';
import {FormattedMessage} from 'react-intl';

import "./ChargingStationsMapLegend.scss";


const ChargingStationsMapLegend = ({ chargingStations }) => {
  const hasSuperChargerStations =
    (chargingStations || []).some(station => isSuperChargingStation(station));

  return (
    <div className="legend ChargingStationsMapLegend">
      <div className="charger-type">
        <b>
          <FormattedMessage
            id="chargingMap.public"
            defaultMessage="Public Stations"
            description="Public Stations Text"
          />
        </b>
        <br />
        <span>
          <FormattedMessage
            id="chargingMap.businessGovernment"
            defaultMessage="Installed by business or government"
            description="Installed by business or government Text"
          />
        </span>
        <img src={ChargingPinPublic} alt="" />
      </div>
      {hasSuperChargerStations && (
        <div className="charger-type">
          <b>
            <FormattedMessage
              id="chargingMap.highPowerStations"
              defaultMessage="High Power Stations"
              description="High Power Stations Text"
            />
          </b>
          <br />
          <span>
            <FormattedMessage
              id="chargingMap.DCChargers"
              defaultMessage="DC fast charge or superchargers"
              description="DC fast charge or superchargers Text"
            />
          </span>
          <img src={ChargingPinHighPower} alt="" />
        </div>
      )}
      <div className="charger-type">
        <b>
          <FormattedMessage
            id="chargingMap.otherStations"
            defaultMessage="Other Types of Stations"
            description="Other Types of Stations Text"
          />
        </b>
        <br />
        <span>
          <FormattedMessage
            id="chargingMap.privateStations"
            defaultMessage="privateStations"
            description="privateStations Text"
          />
        </span>
        <img src={ChargingPinDefault} alt="" />
      </div>
    </div>
  );
};

ChargingStationsMapLegend.propTypes = {
  chargingStations: PropTypes.array
};

ChargingStationsMapLegend.defaultProps = {
  chargingStations: []
};

export default ChargingStationsMapLegend;
