import React from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import { FormatAsDollars } from "./../../utils/Helpers/Format";
import { useIntl, FormattedMessage } from 'react-intl';
import ReactGA4 from 'react-ga4';

const IncentiveCard = props => {
  const intl = useIntl() 
  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility
  
  if (eligibility === "ineligible") {
    return null
  }

  let incentiveTypes = {
    "Bill Credit": intl.formatMessage ? intl.formatMessage({id:"billCredit", defaultMessage:"Bill Credit"}) : "Bill Credit",
    "Discounted Electric Rate": intl.formatMessage ? intl.formatMessage({id:"discountedElectricRate", defaultMessage:"Discounted Electric Rate"}) : "Discounted Electric Rate",
    "Financing": intl.formatMessage ? intl.formatMessage({id:"financing", defaultMessage:"Financing"}) : "Financing",
    "Free Equipment": intl.formatMessage ?intl.formatMessage({id:"freeEquipment", defaultMessage:"Free Equipment"}) : "Free Equipment",
    "HOV Lane": intl.formatMessage ? intl.formatMessage({id:"hovLane", defaultMessage:"HOV Lane"}) : "HOV Lane",
    "Insurance Discount": intl.formatMessage ? intl.formatMessage({id:"insuranceDiscount", defaultMessage:"Insurance Discount"}) : "Insurance Discount",
    "Parking Exemption": intl.formatMessage ? intl.formatMessage({id:"parkingExemption", defaultMessage:"Parking Exemption"}) : "Parking Exemption",
    "Public Charging": intl.formatMessage ? intl.formatMessage({id:"publicCharging", defaultMessage:"Public Charging"}) : "Public Charging",
    "Rebate": intl.formatMessage ? intl.formatMessage({id:"rebate", defaultMessage:"Rebate"}) : "Rebate",
    "Tax Credit": intl.formatMessage ? intl.formatMessage({id:"taxCredit", defaultMessage:"Tax Credit"}) : "Tax Credit",
    "Tax Exemption": intl.formatMessage ? intl.formatMessage({id:"taxExemption", defaultMessage:"Tax Exemption"}) : "Tax Exemption",
    "Toll Discount": intl.formatMessage ? intl.formatMessage({id:"tollDiscount", defaultMessage:"Toll Discount"}) : "Toll Discount",
    "Vehicle Retirement":intl.formatMessage ? intl.formatMessage({id:"rebate", defaultMessage:"Rebate"}) : "Rebate",
}
  
  let description = incentive.description || "";
  
  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : "";

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;

      const handleClickWebsite = () => {
              ReactGA4.event({
                     category: "Incentives",
                     action: "Clicked on Incentive Card",
                     label: `${incentive.grantor + " - " + incentiveTypes[incentive.type]} - ${incentive.name}`,
              });
       };

  let renderCard = incentive ? (
    <a
      className="evc-card IncentiveCard"
      href={incentive.details_url}
      onClick={handleClickWebsite}
      data-incentive-type={incentive.type}
    >
      <div className={`IncentiveCardTop`}>
        <span>
          {incentive.grantor + " - " + incentiveTypes[incentive.type]}
        </span>
      </div>
      <div className="IncentiveCardBody">
        <p className="h1 card-title">
          {amount ? FormatAsDollars(amount) : amountDescription}
        </p>
        <p className="incentive-title">{incentive.name || ""}</p>
      </div>
      <div className="pl-5 pr-5">
        <hr />
      </div>
      <div className="IncentiveCardBottom">
        {description}
        {incentive.id === 418 && (
          <span>
            <br />
            <br />
            <FormattedMessage
              id="evIncentiveDisclaimer"
              defaultMessage="Xcel Energy’s Electric Vehicle Rebate is stackable with the Colorado State EV Credit and CO Vehicle Exchange program."
              description="EV Incentive Disclaimer"
            />
          </span>
        )}
      </div>
    </a>
  ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool
};
