const totalElectricVehicleKwhPerYear = ({
  kwhPer100Miles,
  milesDrivenAnnually,
  percentageDrivenElectric,
}) => {
  return (
    (kwhPer100Miles / 100) *
    milesDrivenAnnually *
    (percentageDrivenElectric / 100)
  );
};

export default totalElectricVehicleKwhPerYear;
