import React, { useState } from "react";
import PropTypes from "prop-types";

// import HowProgramWorks from "./HowProgramWorks";
import EligibleChecklist from "./EligibleChecklist";
import EnrollWithBlurb from "./EnrollWithBlurb";
import ChargerChoice from "./ChargerChoice";
import chargers from "./chargers/chargers";

import GrandTitleParas from "../shared/GrandTitledParas/GrandTitledParas";
import HR from "../shared/HR/HR";

import pricingOptionsGraph from "../../assets/images/graphs/pricing-options-graph-wi.png";
import IconGasPump from "../../assets/images/icons/icon-gas-pump.svg";
import IconHardHat from "../../assets/images/icons/icon-hardhat.svg";
import IconZappyBolt from "../../assets/images/icons/icon-zappy-bolt.svg";
import "./EasyChargingHome.scss";
import { FormattedMessage, useIntl } from "react-intl";

const howProgramWorksObjt = (intl) => ({
     images: [IconGasPump, IconGasPump],
     text: [
          intl.formatMessage({
               id: "evaahProgramWorksOne",
               defaultMessage: "You select a Level 2 charger from one of our two pre-qualified options",
          }),
          intl.formatMessage({
               id: "evaahProgramWorksTwo",
               defaultMessage: "We install your charger and maintain it",
          }),
     ],
     smoothScroll: ["RentCharger", "OwnApproved"],
});

const renderEnrollmentDetails = (stateName, enrollURL, howProgramWorksObjt, ownCharger, setOwnCharger) => (
     <>
          <section className="container works-elible-enroll">
               <div className="row">
                    <div className="toggle-buttons">
                         <div
                              className={`own-charger-toggle ${ownCharger ? null : "active"}`}
                              onClick={() => setOwnCharger(false)}
                         >
                              <span>
                                   <FormattedMessage
                                        id="egbNeedCharger"
                                        defaultMessage="I need a Level 2 charger"
                                        description="I need a Level 2 charger"
                                   />
                              </span>
                         </div>
                         <div
                              className={`own-charger-toggle ${ownCharger ? "active" : ``}`}
                              onClick={() => setOwnCharger(true)}
                         >
                              <span>
                                   <FormattedMessage
                                        id="egbOwnCharger"
                                        defaultMessage="I will use my own Level 2 charger"
                                        description="I will use my own Level 2 charger"
                                   />
                              </span>
                         </div>
                    </div>

                    <div className="how-works-toggle-content">
                         {ownCharger ? (
                              <GrandTitleParas classNames="charger_options">
                                   <h3 style={{ fontSize: "24px" }}>Customer Owned Charger option</h3>
                                   <p>This option is ideal if you would like to use your own pre-approved charger</p>
                                   <p>
                                        You purchase and install a pre-approved Level 2 charger based on our two
                                        options.
                                   </p>

                                   <div class="row how-works-graphics text-center">
                                        <div class="col-md-4 offset-md-0 how-works-column">
                                             <div class="how-works-column-img">
                                                  <div class="img-absolute">
                                                       <img
                                                            src={IconGasPump}
                                                            alt="How program works icon"
                                                            class="text-center"
                                                       />
                                                  </div>
                                             </div>
                                             <div class="how-works-column-txt">
                                                  Xcel Energy will inspect your station to verify eligibility
                                             </div>
                                        </div>
                                        <div class="col-md-4 offset-md-0 how-works-column">
                                             <div class="how-works-column-img">
                                                  <div class="img-absolute">
                                                       <img
                                                            src={IconHardHat}
                                                            alt="How program works icon"
                                                            class="text-center"
                                                       />
                                                  </div>
                                             </div>
                                             <div class="how-works-column-txt">
                                                  You will be responsible to maintain your own charger
                                             </div>
                                        </div>
                                        <div class="col-md-4 offset-md-0 how-works-column">
                                             <div class="how-works-column-img">
                                                  <div class="img-absolute">
                                                       <img
                                                            src={IconZappyBolt}
                                                            alt="How program works icon"
                                                            class="text-center"
                                                       />
                                                  </div>
                                             </div>
                                             <div class="how-works-column-txt">
                                                  You save with an off-peak charging schedule
                                             </div>
                                        </div>
                                   </div>
                              </GrandTitleParas>
                         ) : (
                              <GrandTitleParas classNames="charger_options">
                                   <h3 style={{ fontSize: "24px" }}>Xcel Energy Provided Charger</h3>
                                   <p>
                                        This option is ideal if you would like to rent an Xcel Energy charger that is
                                        installed and maintained
                                   </p>

                                   <div class="row how-works-graphics text-center">
                                        <div class="col-md-4 offset-md-0 how-works-column">
                                             <div class="how-works-column-img">
                                                  <div class="img-absolute">
                                                       <img
                                                            src={IconGasPump}
                                                            alt="How program works icon"
                                                            class="text-center"
                                                       />
                                                  </div>
                                             </div>
                                             <div class="how-works-column-txt">
                                                  You select a Level 2 charger from one of our two pre-qualified options
                                             </div>
                                        </div>
                                        <div class="col-md-4 offset-md-0 how-works-column">
                                             <div class="how-works-column-img">
                                                  <div class="img-absolute">
                                                       <img
                                                            src={IconHardHat}
                                                            alt="How program works icon"
                                                            class="text-center"
                                                       />
                                                  </div>
                                             </div>
                                             <div class="how-works-column-txt">
                                                  We install your charger and maintain it
                                             </div>
                                        </div>
                                        <div class="col-md-4 offset-md-0 how-works-column">
                                             <div class="how-works-column-img">
                                                  <div class="img-absolute">
                                                       <img
                                                            src={IconZappyBolt}
                                                            alt="How program works icon"
                                                            class="text-center"
                                                       />
                                                  </div>
                                             </div>
                                             <div class="how-works-column-txt">
                                                  You save with an off-peak charging schedule
                                             </div>
                                        </div>
                                   </div>
                              </GrandTitleParas>
                         )}
                    </div>

                    <GrandTitleParas classNames="eligible-if" colonHeader="You're eligible if you:">
                         <EligibleChecklist stateName={stateName} ownCharger={ownCharger} />
                    </GrandTitleParas>
                    <GrandTitleParas classNames="enroll">
                         <EnrollWithBlurb enrollURL={enrollURL} stateName={stateName} ownCharger={ownCharger} />
                    </GrandTitleParas>
               </div>
          </section>
          <HR />
          <section className="container payment-pricing">
               <div className="row">
                    <GrandTitleParas classNames="how-works payment-and-price" colonHeader="Payment and Pricing">
                         <>
                              <div className="d-lg-none payment-pricing-mobile">
                                   <p>
                                        <b>Rent the Charger From Us</b>
                                   </p>
                                   <ul>
                                        <li>Monthly Payment: $7.00</li>
                                        <li>Included in Monthly Payment</li>
                                        <ul>
                                             <li>Data/service fees</li>
                                             <li>Electrician initial visit</li>
                                             <li>Charger Relocation</li>
                                        </ul>
                                        <li>Included in Upfront Payment: N/A</li>
                                        <li>
                                             Not Included in Program: 240-volt circuit wiring from panel to charger
                                             location
                                        </li>
                                        <li>Permit Fees*</li>
                                   </ul>
                              </div>
                              <div className="pricing-options d-none d-lg-block">
                                   <h3 style={{ textTransform: "uppercase", fontSize: "24px" }}>
                                        <FormattedMessage
                                             id="program-costs-pricing"
                                             defaultMessage="Program Costs/Pricing"
                                             description="Program Costs/Pricing"
                                        />
                                   </h3>

                                   {ownCharger ? (
                                        <table>
                                             <thead>
                                                  <tr>
                                                       <th>
                                                            <FormattedMessage
                                                                 id={`EVAAH_Option_MN/WI`}
                                                                 defaultMessage="Option"
                                                                 description="Option"
                                                            />
                                                       </th>
                                                       <th>
                                                            <FormattedMessage
                                                                 id={`EVAAH_MonthlyPayment_MN/WI`}
                                                                 defaultMessage="Monthly Payment"
                                                                 description="Monthly Payment"
                                                            />
                                                       </th>
                                                       <th>
                                                            <FormattedMessage
                                                                 id={`EVAAH_IncludedInMonthlyPayment_MN/WI`}
                                                                 defaultMessage="Included in Monthly Payment"
                                                                 description="Included in Monthly Payment"
                                                            />
                                                       </th>
                                                       <th>
                                                            <FormattedMessage
                                                                 id={`EVAAH_NotIncludedinProgram_MN/WI`}
                                                                 defaultMessage="Not Included in Program"
                                                                 description="Not Included in Program"
                                                            />
                                                       </th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  <tr>
                                                       <td>
                                                            <FormattedMessage
                                                                 id={`EVAAH_CustomerOwnedCharger_MN/WI_2`}
                                                                 defaultMessage="Customer-Owned Charger"
                                                                 description="Customer-Owned Charger"
                                                            />
                                                       </td>
                                                       <td>
                                                            <FormattedMessage
                                                                 id={`EVAAH_RentMonthlyPayment_CustomerOwned_WI`}
                                                                 defaultMessage="$8"
                                                                 description="$8"
                                                            />
                                                       </td>
                                                       <td>
                                                            <ul>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id={`dataServiceFees`}
                                                                           defaultMessage="Data/service fees"
                                                                      />
                                                                 </li>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id={`electricanInitialVisit`}
                                                                           defaultMessage="Electrian Initial Visit"
                                                                      />
                                                                      <sup>1</sup>
                                                                 </li>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id="chargerRelocation"
                                                                           defaultMessage="Charger Relocation  in Xcel Energy territory"
                                                                           description="Charger Relocation in Xcel Energy territory"
                                                                      />
                                                                 </li>
                                                            </ul>
                                                       </td>
                                                       <td>
                                                            <ul>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_240_volt_MN/WI`}
                                                                           defaultMessage="240-volt circuit wiring from panel to charger location"
                                                                           description="240-volt circuit wiring from panel to charger location"
                                                                      />
                                                                      <sup>2</sup>
                                                                 </li>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id={`permit-fees`}
                                                                           defaultMessage="Permit Fees"
                                                                           description="Permit Fees"
                                                                      />
                                                                      <sup>3</sup>
                                                                 </li>
                                                            </ul>
                                                       </td>
                                                  </tr>
                                             </tbody>
                                        </table>
                                   ) : (
                                        <table>
                                             <thead>
                                                  <tr>
                                                       <th>
                                                            <FormattedMessage
                                                                 id={`EVAAH_Option_MN/WI`}
                                                                 defaultMessage="Option"
                                                                 description="Option"
                                                            />
                                                       </th>
                                                       <th>
                                                            <FormattedMessage
                                                                 id={`EVAAH_MonthlyPayment_MN/WI`}
                                                                 defaultMessage="Monthly Payment"
                                                                 description="Monthly Payment"
                                                            />
                                                       </th>
                                                       <th>
                                                            <FormattedMessage
                                                                 id={`EVAAH_Warranty_MN/WI`}
                                                                 defaultMessage="Warranty"
                                                                 description="Warranty"
                                                            />
                                                       </th>
                                                       <th>
                                                            <FormattedMessage
                                                                 id={`EVAAH_IncludedInMonthlyPayment_MN/WI`}
                                                                 defaultMessage="Included in Monthly Payment"
                                                                 description="Included in Monthly Payment"
                                                            />
                                                       </th>
                                                       <th>
                                                            <FormattedMessage
                                                                 id={`EVAAH_NotIncludedinProgram_MN/WI`}
                                                                 defaultMessage="Not Included in Program"
                                                                 description="Not Included in Program"
                                                            />
                                                       </th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  <tr>
                                                       <td>
                                                            <FormattedMessage
                                                                 id={`EVAAH_CustomerOwnedCharger_MN/WI`}
                                                                 defaultMessage="Rent the charger from us"
                                                                 description="Rent the charger from us"
                                                            />
                                                       </td>
                                                       <td>
                                                            <FormattedMessage
                                                                 id={`EVAAH_RentMonthlyPayment_WI`}
                                                                 defaultMessage="$18"
                                                                 description="$18"
                                                            />
                                                       </td>
                                                       <td>
                                                            <FormattedMessage
                                                                 id={`EVAAH_UnlimitedWarranty_MN/WI`}
                                                                 defaultMessage="Unlimited, lifetime warranty"
                                                                 description="Unlimited, lifetime warranty"
                                                            />
                                                       </td>
                                                       <td>
                                                            <ul>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id={`chargerText`}
                                                                           defaultMessage="Charger"
                                                                      />
                                                                 </li>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id={`chargerInstall`}
                                                                           defaultMessage="Charger Install and set-up"
                                                                      />
                                                                      <sup>1</sup>
                                                                 </li>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_IncludedInMonthlyPayment_dataservicefees`}
                                                                           defaultMessage="Data/service fees"
                                                                      />
                                                                 </li>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id="chargerUnlimitedMaintenance"
                                                                           defaultMessage="Unlimited maintenance as long as you participate in the program"
                                                                           description="Unlimited maintenance as long as you participate in the program"
                                                                      />
                                                                      <sup>2</sup>
                                                                 </li>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id="chargerRelocation"
                                                                           defaultMessage="Charger Relocation in Xcel Energy territory"
                                                                           description="Charger Relocation"
                                                                      />
                                                                 </li>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id="chargerRemoval"
                                                                           defaultMessage="Charger Removal"
                                                                           description="Charger Removal"
                                                                      />
                                                                 </li>
                                                            </ul>
                                                       </td>
                                                       <td>
                                                            <ul>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id={`EVAAH_NotIncludedInProgram_Rent_MN/WI`}
                                                                           defaultMessage="240-volt circuit wiring from panel to charger location"
                                                                           description="240-volt circuit wiring from panel to charger location"
                                                                      />
                                                                      <sup>3</sup>
                                                                 </li>
                                                                 <li>
                                                                      <FormattedMessage
                                                                           id={`permit-fees`}
                                                                           defaultMessage="Permit Fees"
                                                                           description="Permit Fees"
                                                                      />
                                                                      <sup>4</sup>
                                                                 </li>
                                                            </ul>
                                                       </td>
                                                  </tr>
                                             </tbody>
                                        </table>
                                   )}
                              </div>

                              <div>
                                   {ownCharger ? (
                                        <>
                                             <p style={{ fontSize: "12px" }}>
                                                  <sup>1</sup> A qualified electrician from Xcel Energy's trusted
                                                  network will contact you within 2 business days of enrollment
                                                  confirmation to schedule the installation of your new charger. If
                                                  adding a new electric service and meter, the current wait time is 6
                                                  weeks.
                                             </p>

                                             <p style={{ fontSize: "12px" }}>
                                                  <sup>2</sup> If you need 240-volt circuit wiring, our qualified
                                                  electricians can perform this work for you at the time of inspection
                                                  and will provide you with a quote prior to that work. Click here for a
                                                  list of our qualified electricians.
                                             </p>

                                             <p style={{ fontSize: "12px" }}>
                                                  <sup>3</sup> Permit fees are not covered in the program pricing. In
                                                  order to qualify for the program, your circuit wiring and charger
                                                  installation must be inspected and you must have a permit. Your
                                                  electrician will pull all necessary permits for the work that needs to
                                                  be completed and you will pay the electrician directly for the
                                                  permits.
                                             </p>
                                        </>
                                   ) : (
                                        <>
                                             <p style={{ fontSize: "12px" }}>
                                                  <sup>1</sup> A qualified electrician from Xcel Energy's trusted
                                                  network will contact you within 2 business days of enrollment
                                                  confirmation to schedule the installation of your new charger. If
                                                  adding a new electric service and meter, the current wait time is 6
                                                  weeks.
                                             </p>

                                             <p style={{ fontSize: "12px" }}>
                                                  <sup>2</sup> Negligence, flood, fire, or other natural disaster are
                                                  not covered.
                                             </p>

                                             <p style={{ fontSize: "12px" }}>
                                                  <sup>3</sup>{" "}
                                                  <b>
                                                       Your home may require additional 240-volt circuit wiring that is
                                                       not included in the charger installation or price of the program
                                                       and is unique to each home.
                                                  </b>{" "}
                                                  Our qualified electricians can perform this work for you at the time
                                                  of installation and will provide you with a quote prior to that work.
                                                  You may hire your own qualified electrician to install the 240 volt
                                                  circuit.
                                             </p>

                                             <p style={{ fontSize: "12px" }}>
                                                  <sup>4</sup> Permit fees are not covered in the program pricing. In
                                                  order to qualify for the program, your circuit wiring and charger
                                                  installation must be inspected and you must have a permit. Your
                                                  electrician will pull all necessary permits for the work that needs to
                                                  be completed and you will pay the electrician directly for the
                                                  permits.
                                             </p>
                                        </>
                                   )}
                              </div>

                              <div>
                                   <h3
                                        style={{
                                             textTransform: "uppercase",
                                             fontSize: "24px",
                                             margin: "16px 0",
                                        }}
                                   >
                                        <FormattedMessage
                                             id="program-charging-hours-rates"
                                             defaultMessage="Charging Rates and Times"
                                             description="Charging Rates and Times"
                                        />
                                   </h3>
                                   <p>
                                        <FormattedMessage
                                             id={`EVAAH_WithEitherOption_WI`}
                                             defaultMessage="EV Accelerate At Home, you have access to electricity pricing that can save you money on charging. Your Level 2 charger will be programmed to charge only during off-peak hours, between midnight and 6 a.m. every day. Your EV will automatically charge during the lowest cost time period, but you can always charge during other times if you need to."
                                             description="with either opts"
                                        />
                                        <ul>
                                             <li>
                                                  <FormattedMessage
                                                       id={`EVAAH_WithEitherOption_MN/WI_bullet1_a`}
                                                       defaultMessage="Fuel cost charges, resource adjustments, and local taxes and fees are not included in the monthly or upfront prices and will be applied upon billing."
                                                       description="Fuel cost charges, resource adjustments, and local taxes and fees are not included in the monthly or upfront prices and will be applied upon billing."
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id={`EVAAH_WithEitherOption_MN/WI_bullet1_b`}
                                                       defaultMessage="Details about cancellation options and costs are found in FAQ."
                                                       description="Details about cancellation options and costs are found in FAQ."
                                                  />
                                             </li>
                                        </ul>
                                   </p>
                                   <p>
                                        {/* <ul className="pricing-options-list">
                  <li>
                    If you choose to buy the charger from us, you must pay the
                    upfront payment prior to charger installation.
                  </li>
                  <li>
                    Fuel cost charges, resource adjustments and local taxes and
                    fees are not included in the monthly or upfront prices and
                    will be applied upon billing.
                  </li>
                  <li>
                    Details about cancellation options and costs are found in
                    FAQ.
                  </li>
                </ul> */}
                                   </p>
                              </div>
                              <div className="pricing-options-graph">
                                   <img src={pricingOptionsGraph} alt="pricing options graph" />
                              </div>
                              <div className="off-peak-def">
                                   <p className="last-para">
                                        <FormattedMessage
                                             id={`EVAAH_OvernightOffPeakHours_WI`}
                                             defaultMessage="Overnight, off-peak hours are between midnight and 8 a.m. for your EV. Your home’s energy use will continue to be billed at its current rate."
                                        />
                                   </p>
                              </div>
                         </>
                    </GrandTitleParas>
               </div>
          </section>
          <HR />
     </>
);

const renderChargerOptions = (intl) => {
     return (
       <>
         <section className="container charger-options" id="charger-options">
           <div className="row">
             <GrandTitleParas
               classNames="how-works charger-options"
               colonHeader="Charger Options"
             >
               <div>
                 <h3 style={{ textTransform: "uppercase", fontSize: "18px" }}>
                   <FormattedMessage
                     id={`eligible-charger`}
                     defaultMessage="Eligible Charger"
                   />
                 </h3>
                 <FormattedMessage
                   id={`EVAAH_ChargerOptions_WI`}
                   defaultMessage="{link1} is compatible with Xcel Energy’s charging programs. It is ENERGY STAR®-certified and UL Listed for safety. (Enel X Way chargers are no longer compatible with our programs.) {lineBreak}"
                   description="Evaah charger opt"
                   values={{
                     link1: (
                       <a
                         href={intl.formatMessage({
                           id: "EVAAH_ChargerOptions_link1_URL",
                           defaultMessage:
                             "https://ev.xcelenergy.com/ChargePoint_Home_Flex.pdf",
                         })}
                         target="_blank"
                         rel="noopener noreferrer"
                       >
                         <FormattedMessage
                           id={`EVAAH_ChargerOptions_link1_text`}
                           defaultMessage="ChargePoint Home Flex"
                         />
                       </a>
                     ),
                     link2: (
                       <a
                         href={intl.formatMessage({
                           id: "EVAAH_ChargerOptions_link2_URL",
                           defaultMessage:
                             "https://ev.xcelenergy.com/Enel_X_JuiceBox_48.pdf",
                         })}
                         target="_blank"
                         rel="noopener noreferrer"
                       >
                         <FormattedMessage
                           id={`EVAAH_ChargerOptions_link2_text`}
                           defaultMessage="Enel X Way Juice Box"
                         />
                       </a>
                     ),
                     lineBreak: (
                       <>
                         <br /> <br />
                       </>
                     ),
                     italics: (
                       <i>
                         <FormattedMessage
                           id={`noteXcelChargerDisclaimer`}
                           defaultMessage="Note: Xcel Energy does not endorse one EV charger over the other."
                         />
                       </i>
                     ),
                   }}
                 />
               </div>
               <p>
                 <FormattedMessage
                   id="chargerMustBeHardWired"
                   defaultMessage="The charger must be hardwired to a 240-volt circuit and cannot be plugged into an outlet. See FAQ for more information. Tesla owners can use this charger with J1772 to NACS adapter. To ensure proper compatibility with the charger, a non-GFCI circuit is required."
                   description="Owned Charger"
                 />
               </p>
             </GrandTitleParas>
             <GrandTitleParas classNames="charger-choices">
               <ChargerChoice chargers={chargers} />
               <i>
                 <FormattedMessage
                   id="noteXcelChargerDisclaimer"
                   defaultMessage="(Enel X Way, additional charger provider that used to be compatible with our programs, decided to cease its North American operations as of October 2, 2024. Xcel Energy is actively working on a contingency plan for its current customers. And, as of October 2, 2024, Xcel Energy is no longer accepting Enel X Way chargers for new customer enrollment in its charging programs.)"
                   description="(Enel X Way, additional charger provider that used to be compatible with our programs, decided to cease its North American operations as of October 2, 2024. Xcel Energy is actively working on a contingency plan for its current customers. And, as of October 2, 2024, Xcel Energy is no longer accepting Enel X Way chargers for new customer enrollment in its charging programs.)"
                 />
               </i>
             </GrandTitleParas>
           </div>
         </section>
       </>
     );
};

const EasyChargingHome = ({ stateName, enrollURL }) => {
     const intl = useIntl();
     const [ownCharger, setOwnCharger] = useState(false);

     return (
          <div className="easy-charging-home">
               <section className="overview">
                    {renderEnrollmentDetails(
                         stateName,
                         enrollURL,
                         howProgramWorksObjt(intl),
                         ownCharger,
                         setOwnCharger
                    )}
                    {renderChargerOptions(intl)}
               </section>
          </div>
     );
};

export default EasyChargingHome;

EasyChargingHome.propTypes = {
     stateName: PropTypes.string,
     enrollURL: PropTypes.string,
};
