function SmoothScroll(elementId, duration = 1000, callback) {
       const element = document.getElementById(elementId);
       if (!element) return;

       const start = window.pageYOffset;
       const target = element.getBoundingClientRect().top + start;
       const startTime = performance.now();

       function easeInOutQuad(t, b, c, d) {
         t /= d / 2;
         if (t < 1) return (c / 2) * t * t + b;
         t--;
         return (-c / 2) * (t * (t - 2) - 1) + b;
       }

       function animation(currentTime) {
         const timeElapsed = currentTime - startTime;
         const progress = timeElapsed / duration;

         if (progress < 1) {
           window.scrollTo(0, easeInOutQuad(timeElapsed, start, target - start, duration));
           requestAnimationFrame(animation);
         } else {
           window.scrollTo(0, target);
           if (callback) {
             callback();
           }
         }
       }

       requestAnimationFrame(animation);
}

export default SmoothScroll;
