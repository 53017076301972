import React from "react";

import HowWorks from "./HowWorks";
import Requirements from "./Requirements";
import BlankComponent from "./BlankComponent";

import GrandTitleParas from "../shared/GrandTitledParas/GrandTitledParas"
import HR from "../shared/HR/HR";

import "./ChargeOvernight.scss";

const contractorsGuide = (
    <a 
        href="https://www.xcelenergy.com/staticfiles/xe-responsive/Energy%20Portfolio/EV-Electric-Pricing-Plan-Set-Up-Guide%20.pdf"
        target="_blank"
        rel="noopener noreferrer"
    >Contractor's Guide</a>
)

const renderChargeOvernight = () => {
    return (
        <section className="container">
            <GrandTitleParas
                classNames="how-works"
                colonHeader="How it Works:"
            >
                <HowWorks />
            </GrandTitleParas>
            <HR/><br/>
            <GrandTitleParas
                classNames="requirements"
                colonHeader="Requirements"
            >
                <Requirements />
            </GrandTitleParas>
            <HR/>
            <GrandTitleParas
                classNames="contractors-guide"
                colonHeader={contractorsGuide}
            >
                <BlankComponent />
            </GrandTitleParas>
        </section>
    )
}

const ChargeOvernight = () => {
    return (
        <>
            <section className="charge-ev-overnight overview">
                { renderChargeOvernight() }
            </section>
        </>
    )
}

export default ChargeOvernight;