export const EV_ACCELERATE_AT_HOME_NEW_MEXICO = "HSPI-NM";
export const EV_ACCELERATE_AT_HOME_MINNESOTA = "HSPI-MN";
export const EV_ACCELERATE_AT_HOME_WISCONSIN = "HSPI-WI";
export const EV_ACCELERATE_AT_HOME_COLORADO = "HSPI-CO";
export const OPTIMIZE_YOUR_CHARGE_COLORADO = "SP-CO";
export const OPTIMIZE_YOUR_CHARGE_NEW_MEXICO = "SP-NM";
export const OPTIMIZE_YOUR_CHARGE_MINNESOTA = "SP-MN";
export const HOME_WIRING_REBATE_COLORADO = "HWR-CO";
export const HOME_WIRING_REBATE_NEW_MEXICO = "HWR-NM";
export const EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA = "SSP-MN";
export const CHANGING_PERKS_PILOT_COLORADO = "CP-CO";
export const INCOME_QUALIFIED_COLORADO = "IQ-CO";
export const TIME_OF_DAY_SEPARATE_METER_MINNESOTA = "MN-TOD";
