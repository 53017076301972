import React from "react";
import PropTypes from "prop-types";

const EligibleEnroll = ({stateName}) => {
    return (
        <>
            <ul>
                <li>Own or lease an electric vehicle, such as an all-electric or plug-in hybrid.</li>
                <li>Own and live in a single-family home that receives electric service from Xcel Energy in {stateName}.</li>
                <li>Have access to Wi-Fi in the charging location.</li>
                <li>Are not participating in Solar*Rewards® or net metering programs.</li>
            </ul>
        </>
    )
}

export default EligibleEnroll;

EligibleEnroll.propTypes = {
    stateName: PropTypes.string
}