export const loadState = function() {
  try {
    // NOTE: localStorage storage is scoped to domain + protocol
    const serializedState = window.localStorage.getItem("state");
    if (serializedState === null) return undefined;
    else return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const persistState = function(state) {
  try {
    const serializedState = JSON.stringify(state);
    window.localStorage.setItem("state", serializedState);
  } catch (err) {
    // Don't care about write errors
  }
};
