import {
  WI_EV_ACCELERATE,
  WI_E11_ID,
  RENT_CHARGER_ID,
  BUY_CHARGER_ID,
} from '../../constants/';

const HOLIDAYS = [
  "2018-01-01",
  "2018-03-30",
  "2018-05-28",
  "2018-07-04",
  "2018-09-03",
  "2018-11-22",
  "2018-12-25",
];

const FIXED_CHARGE_WHEN_RENTING = 17;
const FIXED_CHARGE_WHEN_BUYING = 7
// "[For customers who are currently on WI's Time of Day rate]
// the monthly fee is slightly different:
// $13 if you rent the charger or $3 if you buy the charger up front."
const FIXED_CHARGE_WHEN_RENTING_BUT_SWITCHING_FROM_WI_E11 = 13;
const FIXED_CHARGE_WHEN_BUYING_BUT_SWITCHING_FROM_WI_E11 = 3;

const wi_ev_accelerate = {
  name: WI_EV_ACCELERATE,
  title: "EV Accelerate At Home",
  separatelyMetered: true,
  isBundled: true,
  includeHomeWiringEstimator: true,
  upfrontChargerCost: 780,
  monthlyDiscountForBuyingCharger: FIXED_CHARGE_WHEN_BUYING - FIXED_CHARGE_WHEN_RENTING,
  monthlyDiscountForSwitchingFromRate: {
    [WI_E11_ID]: {
      [RENT_CHARGER_ID]: FIXED_CHARGE_WHEN_RENTING_BUT_SWITCHING_FROM_WI_E11 - FIXED_CHARGE_WHEN_RENTING,
      [BUY_CHARGER_ID]: FIXED_CHARGE_WHEN_BUYING_BUT_SWITCHING_FROM_WI_E11 - FIXED_CHARGE_WHEN_BUYING,
    },
  },
  rateElements: [
    {
      name: "Basic Service Charge",
      rateElementType: "FixedPerMonth",
      rateComponents: [
        {
          charge: FIXED_CHARGE_WHEN_RENTING,
          name: "Per Month",
        },
      ],
    },
    {
      name: "Energy charge per kWh",
      rateElementType: "EnergyTimeOfUse",
      rateComponents: [
        {
          charge: 0.2022,
          months: [5, 6, 7, 8],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [12, 13, 14, 15, 16, 17, 18, 19], // 12p - 8p
          exceptForDays: HOLIDAYS,
          name: "Summer Super Peak",
        },
        {
          charge: 0.1271,
          months: [5, 6, 7, 8],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [8, 9, 10, 11, 20, 21, 22, 23], // 8a - 12p; 8p - 12a
          exceptForDays: HOLIDAYS,
          name: "Summer On Peak - Weekdays",
        },
        {
          charge: 0.1271,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], // 8a - 12a
          exceptForDays: HOLIDAYS,
          name: "Winter On Peak - Weekdays",
        },
        {
          charge: 0.0597,
          daysOfWeek: [1, 2, 3, 4, 5], // M-F
          // All months
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7], // 12 - 8a
          exceptForDays: HOLIDAYS,
          name: "Off Peak - Weekdays",
        },
        {
          charge: 0.0597,
          daysOfWeek: [0, 6], // M-F
          // All months
          // All hours
          exceptForDays: HOLIDAYS,
          name: "Weekends",
        },
        {
          charge: 0.0597,
          // All days
          // All hours
          onlyOnDays: HOLIDAYS,
          name: "Holidays",
        },
      ],
    },
  ],
};

export default wi_ev_accelerate;
