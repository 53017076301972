import cloneDeep from "lodash/cloneDeep";

const Private = {
  alphabeticalSort: function(events, direction) {
    return events.sort((a, b) => {
      switch (direction) {
        case "asc":
          return a.name.localeCompare(b.name);
        case "desc":
          return b.name.localeCompare(a.name);
        default:
          return events;
      }
    });
  },
  dateSort: function(events, direction) {
    return events.sort((a, b) => {
        let getUnixTime = (event) => {
            const startDateTime = new Date(event.date_time_start)
            return startDateTime.getTime()
        }
      switch (direction) {
        case "asc":
          return getUnixTime(a) - getUnixTime(b)
        case "desc":
          return getUnixTime(b) - getUnixTime(a)
        default:
          return events;
      }
    });
  }
};

const sortEvents = (
  events,
  sortType,
) => {
  const clonedEvents = cloneDeep(events);

  switch (sortType) {
    case "date-asc":
      return Private.dateSort(clonedEvents, "asc");
    case "date-desc":
      return Private.dateSort(clonedEvents, "desc");
    case "alphabetical-asc":
        return Private.alphabeticalSort(clonedEvents, "asc");
    default:
      return Private.alphabeticalSort(clonedEvents, "asc");
  }
};

export default sortEvents;
