import React from "react";
import PropTypes from "prop-types";
import ToolTip from "../ToolTip/ToolTip";

// TODO : consider refactoring to a bootstrap dropdown. Make sure to test for ADA first.

const Select = React.forwardRef((props, ref) => {
     const {
          id,
          value,
          handler,
          optionNames,
          optionValues,
          label,
          description,
          disabled,
          tooltip,
          ariaControls,
          hasSupportingInfoBelow,
          isLarge,
          optionDataValues,
     } = props;

     const idAria = id + "-help";

     return (
          <div
               className="form-group"
               style={{
                    marginTop: "8px",
                    marginBottom: hasSupportingInfoBelow ? "4px" : "1rem",
               }}
          >
               {label && (
                    <label htmlFor={id} className={isLarge ? "col-form-label-lg" : ""}>
                         {label}
                         {tooltip && <ToolTip id={id + "_tooltip"} message={tooltip} />}
                    </label>
               )}
               <div className="input-group">
                    <select
                         id={id}
                         value={value}
                         disabled={disabled}
                         className={["form-control", isLarge ? "form-control-lg" : ""].join(" ")}
                         aria-describedby={idAria}
                         aria-controls={ariaControls}
                         onChange={(e) => handler(e)}
                    >
                         {}
                         {optionValues.map((optionValue, i) => {
                              return (
                                   <option
                                        key={optionValue}
                                        value={optionValue}
                                        data={optionDataValues ? optionDataValues[i] : ""}
                                   >
                                        {optionNames ? optionNames[i] : optionValue}
                                   </option>
                              );
                         })}
                    </select>
               </div>
               <small id={idAria} className="form-text text-muted">
                    {description}
               </small>
          </div>
     );
});

export default Select;

Select.propTypes = {
     id: PropTypes.string,
     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
     handler: PropTypes.func,
     optionNames: PropTypes.array,
     optionValues: PropTypes.array,
     description: PropTypes.string,
     disabled: PropTypes.bool,
     tooltip: PropTypes.string,
     isLarge: PropTypes.bool,
     ariaControls: PropTypes.string,
     hasSupportingInfoBelow: PropTypes.bool,
     label: PropTypes.string,
     optionDataValues: PropTypes.array,
};
