import React, { useState } from "react";
import PropTypes from "prop-types";

import "./SelectedCard.scss";
import PhoneIcon from "../../assets/images/icons/icn_phone.svg"
import LinkIcon from "../../assets/images/icons/icn_link.svg"
import defaultDealer from "../../assets/images/icons/default-dealer.png"

import GaTracker from "../../../utils/GaTracker/GaTracker";
import {FormattedMessage} from 'react-intl';

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import * as openpgp from "openpgp";
import publicKey from "../../data/publicKey";

const crypto = require('crypto');
const SelectedCard = ({
  name,
  street,
  city,
  region,
  postcode,
  phone,
  oem,
  website,
  oemImage,
  dealerLocations,
  electricVehicles,
  usedElectricVehicles
}) => {
  const [modal, setModal] = useState(false);
  const [submittedModal, setSubmittedModal] = useState(false)
  const [responseStatus, setResponseStatus] = useState(null)

  if (!dealerLocations || dealerLocations.length === 0) return null

  const evsMapping = {}

  const mapEvs = (vehicles) => {
      vehicles.forEach(vehicle => {
          const evModel = vehicle.model + vehicle.trim
          const evMake = vehicle.make
          if (evsMapping[evMake] === undefined) {
              evsMapping[evMake] = {}
          } 

          if (evsMapping[evMake][evModel] === undefined) {
              evsMapping[evMake][evModel] = []
          } else {
              evsMapping[evMake][evModel].push(vehicle.model_year)
          }
      })
  };

  if (electricVehicles && usedElectricVehicles) {
      mapEvs(electricVehicles.concat(usedElectricVehicles))
  }

  const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="text-input" tabIndex={props.tabIndex} {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };

  const MySelect = ({ label, ...props }) => {
      const [field, meta] = useField(props);
      return (
        <>
          <label htmlFor={props.id || props.name}>{label}</label>
          <select {...field} {...props} tabIndex={props.tabIndex}/>
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </>
      );
    };

  const renderMakes = Object.keys(evsMapping).sort((a, b) => a.localeCompare(b)).map( make => {
          return <option key={make} value={make}>{make}</option>
      })

  const renderModels = (make) => {
      if (make !== "Select a Make") {
          return Object.keys(evsMapping[make]).sort((a, b) => a.localeCompare(b)).map( model => {
              return <option key={model} value={model}>{model}</option>
          })
      }
  }

  const renderYears = (make, model) => {
      if (make !== "Select a Make" && model !== "Select a Model" && make !== "" && model !== "") {
          if (evsMapping[make][model] === undefined) {
              return null
          }
          return evsMapping[make][model].sort((a, b) => a - b).map( year => {
              return <option key={year} value={year}>{year}</option>
          })
      }
  }

  const renderDealers = dealerLocations.map(dealer => {
    return <option key={dealer.name} value={dealer.name}>{dealer.name}</option>
  })


  const secondLineAddress = [city, region, postcode]
    .filter(el => el)
    .join(", ");

  const handleClickWebsite = () => {
    GaTracker.trackEvent({
      category: "Dealers",
      action: "Clicked Website Link",
      label: name
    });
  };

  const handleClickPhone = () => {
    GaTracker.trackEvent({
      category: "Dealers",
      action: "Clicked Phone Link",
      label: name
    });
  };

  return (
    <div className="SelectedCard">
      {oemImage && (
        <img
          src={`https://zappyassets.s3.amazonaws.com/img/oems/${oem}.png`}
          alt={oemImage.name}
          className="oem-image"
          onError={(e) => (e.target.src = defaultDealer)}
        />
      )}
      <h5>{name || ""}</h5>
      <p className="address-container">
        {street || ""}
        <br />
        {secondLineAddress || ""}
      </p>
      <div className="links-container">
        <div className="row">
          {phone && (
            <div className="col-6 contact-dealer">
              <img src={PhoneIcon} alt="" />
              &nbsp;
              <a href={`tel: ${phone}`} onClick={handleClickPhone}>
                <FormattedMessage 
                  id="dealers.contact"
                  defaultMessage="CONTACT"
                  description="Dealer Contact"
                />              
              </a>
            </div>
          )}
          {website && (
            <div className="col-6">
              <img src={LinkIcon} alt="" />
              &nbsp;
              <a
                href={website}
                onClick={handleClickWebsite}
              >
                <FormattedMessage 
                  id="dealers.website"
                  defaultMessage="WEBSITE"
                  description="Dealer Website"
                />    
              </a>
            </div>
          )}
        </div>
      </div>

      <Modal className="dealer-modal" isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)} centered="true">
        </ModalHeader>
          <ModalBody>
          <>
              <p className="title-modal">
                Send Message to Dealer
              </p>
              <p className="modal-subtitle">
                We’ll send it along to the dealership right away. They’ll reach out as soon as they can.
              </p>
              </>
              <Formik
                  initialValues={{ 
                      firstName: '',
                      lastName: '',
                      address: '',    
                      email: '',
                      phone: '',
                      dealership: name,
                      make: '',
                      model: '',
                      year: '',
                      message: '',
                      legal: false,
                  }}
                  validate={values => {
                      const errors = {};
                      if (!values.firstName) {
                        errors.firstName = 'Required';
                      } else if (values.firstName.length > 15) {
                        errors.firstName = 'Must be 15 characters or less';
                      }
                      
                      if (!values.lastName) {
                        errors.lastName = 'Required';
                      } else if (values.lastName.length > 20) {
                        errors.lastName = 'Must be 20 characters or less';
                      }
                      
                      if (!values.email) {
                        errors.email = 'Required';
                      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        errors.email = 'Invalid email address';
                      }

                      if (!values.address) {
                        errors.address = 'Required';
                      }

                      if (!values.message) { 
                        errors.message = 'Required';
                      }

                      if (!values.legal) {
                        errors.legal = 'Required';
                      }
                      
                      return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                      // setTimeout(() => {
                      // alert(JSON.stringify(values, null, 2));
                      // setSubmitting(false);
                      // }, 400);
                      delete values.legal

                      let url = new URL(
                          process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_JOIN_NETWORK_PROD_BACKEND_HOST : process.env.REACT_APP_JOIN_NETWORK_DEV_BACKEND_HOST
                        );
                        const submitForm = async () => {
                          const encrypted = await openpgp.encrypt({
                              message: await openpgp.createMessage({ text: JSON.stringify(values) }),
                              encryptionKeys: await openpgp.readKey({ armoredKey: publicKey })
                            });


                          const payload = Buffer.from(encrypted).toString('base64');

                          const leadGen = {
                              service: process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_JOIN_NETWORK_PROD_SERVICE_ID : process.env.REACT_APP_JOIN_NETWORK_DEV_SERVICE_ID,
                              payload: [ payload ],
                              checksum: "-Sha1-" + crypto.createHash("sha1").update(payload, "utf-8").digest("hex")
                          }

                          const response = await fetch(url, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json"        
                              },
                              body: JSON.stringify(leadGen)
                          }).catch(err => {
                              console.log(err.response.data);
                          });

                          if (response.ok) {
                              setResponseStatus(true)
                          } else {
                              setResponseStatus(false)

                          }
                          setSubmittedModal(true)
                          setModal(false)
                      }
                      submitForm()

                  }}
                  >
                  {({ isSubmitting, values }) => (
                      <Form className="dealer-message">
                        <div className="row">
                          <div className="col-6 input-col">

                            <MyTextInput
                              label="First Name"
                              name="firstName"
                              type="text"
                              tabIndex="1"
                            />

                            <MyTextInput
                              label="Email"
                              name="email"
                              type="text"
                              tabIndex="3"
                            />
                            <MyTextInput
                              label="Address"
                              name="address"
                              type="text"
                              tabIndex="5"
                            />
                          </div>

                          <div className="col-6 input-col">

                            <MyTextInput
                              label="Last Name"
                              name="lastName"
                              type="text"
                              tabIndex="2"
                            />

                            <MyTextInput
                              label="Phone (Optional)"
                              name="phone"
                              type="text"
                              tabIndex="4"

                            />

                              <MySelect label="Dealership" className="xcel-input" name="dealership" tabIndex="6">
                                {renderDealers}
                              </MySelect>
                          
                          </div>
                        </div>

                        <div className="row second-input-row">
                          <div className="col-6 input-col">

                            <MySelect label="Make(Optional)" className="xcel-input" name="make" tabIndex="7">
                              <option>Select a Make</option>
                              {renderMakes}
                            </MySelect>

                            <MySelect label="Model(Optional)" className="xcel-input" name="model" disabled={!evsMapping[values.make]} tabIndex="8">
                                <option>Select a Model</option>
                                {values.make && renderModels(values.make)}
                            </MySelect>

                            <MySelect label="Year(Optional)" className="xcel-input" name="year" disabled={evsMapping[values.make] ? !evsMapping[values.make][values.model] : !false} tabIndex="9">
                              <option>Select a Year</option>
                              {renderYears(values.make, values.model)}
                            </MySelect>

                          </div>

                          <div className="col-6 input-col">

                            <label htmlFor="message">Message</label>
                            <Field id="message" name="message" className="xcel-input" placeholder="" 
                            component="textarea"
                            rows="9"
                            tabIndex="9"
                            />
                            <div className="error">
                              <ErrorMessage name="message" />
                            </div>

                          </div>

                        </div>

                        <div className="row third-input-row">
                          <div className="col-6 input-col">
                            <label className="">
                              <Field  className="legal-checkbox" type="checkbox" name="legal" /> 
                                By checking this box, I acknowledge that Xcel Energy will send the above information to the auto-dealership I have selected.
                            </label>
                            <div className="error">
                                <ErrorMessage name="legal" />
                            </div>
                        </div>
                        <div className="col-6 input-col">
                          <button type="submit" className="btn btn-xcel submit" disabled={isSubmitting} tabIndex="10">
                              Send Message
                          </button>
                        </div>
                        </div>

                      </Form>
                  )}
              </Formik>
          </ModalBody>
      </Modal>
      <Modal 
                isOpen={submittedModal}
                toggle={() => setSubmittedModal(false)}
                className="lead-gen-modal"
            >
                <ModalHeader toggle={() => setSubmittedModal(false)} centered="true">
                </ModalHeader>
                <ModalBody>
                    {responseStatus ? 
                    <>
                        <p className="title-modal">
                          Message sent!
                        </p>
                        <p className="modal-subtitle">
                          Expect to hear from the dealer, shortly.
                        </p>
                    </>
                    :
                    <>
                        <p className="title-modal">
                            Something went wrong.. 
                        </p>
                        <p className="modal-subtitle">
                            Please try again. 
                        </p>
                    </>
                    }
                    <button
                        className="btn btn-xcel submit"
                        onClick={() => setSubmittedModal(false)}
                    >
                        Done
                    </button>
                </ModalBody>
            </Modal>
    </div>
  );
};

SelectedCard.propTypes = {
  name: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  postcode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  phone: PropTypes.string,
  website: PropTypes.string,
  oemImage: PropTypes.bool
};

export default SelectedCard;
