const minnesota = {
  year: 2019,
  data: [
    0.94871086,
    0.90531043,
    0.88807389,
    0.894430471,
    0.873032101,
    0.897648009,
    0.923098911,
    0.931514772,
    0.982346531,
    1.00028038,
    0.978329504,
    0.971665731,
    0.969062399,
    0.981880069,
    0.977849326,
    1.05513149,
    1.156600292,
    1.334726488,
    1.387166904,
    1.410053452,
    1.360612957,
    1.281641316,
    1.117523477,
    1.008628161,
    0.87010667,
    0.836729822,
    0.82524644,
    0.818884445,
    0.791866077,
    0.803956763,
    0.914405141,
    0.996748598,
    0.965459667,
    0.943489816,
    0.906553783,
    0.871513135,
    0.866279054,
    0.864564946,
    0.853784343,
    0.90283661,
    0.977137639,
    1.171964842,
    1.252365065,
    1.262338511,
    1.235473056,
    1.169101853,
    1.027693531,
    0.918262474,
    0.878483427,
    0.845136597,
    0.83457184,
    0.829495732,
    0.799344189,
    0.81107358,
    0.936940158,
    1.036939433,
    1.020332411,
    1.002717149,
    0.958689836,
    0.923790842,
    0.917160857,
    0.91329117,
    0.903153323,
    0.941904025,
    0.985629524,
    1.153189207,
    1.226010111,
    1.232020326,
    1.199529823,
    1.161636621,
    1.049562854,
    0.954618096,
    0.860797451,
    0.820869412,
    0.802165738,
    0.790682656,
    0.741711722,
    0.713598134,
    0.772354958,
    0.89201281,
    0.994923331,
    1.032337632,
    1.009740514,
    0.991801307,
    0.99354329,
    0.992359976,
    0.973199488,
    0.999729326,
    1.027522353,
    1.168636512,
    1.20838706,
    1.201917968,
    1.155965895,
    1.118432026,
    1.005579575,
    0.919359162,
    0.882459212,
    0.837777189,
    0.8184288,
    0.803463921,
    0.75067966,
    0.719701834,
    0.780208416,
    0.903181782,
    1.012021553,
    1.048194271,
    1.044886076,
    1.04725039,
    1.058506857,
    1.062347253,
    1.054790793,
    1.099264191,
    1.152887744,
    1.304960205,
    1.360256542,
    1.356489542,
    1.279607519,
    1.184230784,
    1.018553683,
    0.906254696,
    0.843420374,
    0.807009038,
    0.799816506,
    0.794388124,
    0.769948812,
    0.788750565,
    0.902977536,
    0.983079176,
    0.961378159,
    0.932410898,
    0.907853615,
    0.883348777,
    0.879796246,
    0.86862467,
    0.861694908,
    0.905163278,
    0.986921959,
    1.193464676,
    1.281558329,
    1.286988193,
    1.23900177,
    1.156342766,
    0.995522617,
    0.885902326,
    0.798261615,
    0.766302042,
    0.754669538,
    0.752363574,
    0.728977438,
    0.751839418,
    0.846695687,
    0.908476978,
    0.88975597,
    0.87304517,
    0.846669661,
    0.81684981,
    0.822227318,
    0.821901175,
    0.812654816,
    0.866176006,
    0.946419532,
    1.136884732,
    1.217366933,
    1.219717499,
    1.17697952,
    1.104840696,
    0.951536268,
    0.84895365,
    0.804861409,
    0.772809489,
    0.762374993,
    0.758978409,
    0.735397882,
    0.757671882,
    0.85902758,
    0.921523072,
    0.898018007,
    0.878391131,
    0.843991439,
    0.816680016,
    0.817066374,
    0.812711953,
    0.807585991,
    0.85783338,
    0.938384731,
    1.12944738,
    1.209572074,
    1.21510673,
    1.173703881,
    1.098506839,
    0.949966792,
    0.85243453,
    0.779206237,
    0.748666692,
    0.737420234,
    0.733482659,
    0.71174625,
    0.734237166,
    0.828393929,
    0.891617753,
    0.870662844,
    0.853436328,
    0.818330806,
    0.782634917,
    0.785891929,
    0.785323448,
    0.77989793,
    0.83161351,
    0.902869522,
    1.08551768,
    1.152216118,
    1.151361612,
    1.125602313,
    1.059659688,
    0.921706011,
    0.826242155,
    0.757439003,
    0.727164956,
    0.716406103,
    0.714265319,
    0.690147871,
    0.714952238,
    0.817839862,
    0.89155862,
    0.891061094,
    0.88061412,
    0.839795623,
    0.805763303,
    0.809450944,
    0.807627895,
    0.803876093,
    0.847969744,
    0.888446397,
    1.040457173,
    1.09201254,
    1.085161485,
    1.054693557,
    1.017985736,
    0.910310388,
    0.832294306,
    0.76733875,
    0.732782125,
    0.716526327,
    0.703487765,
    0.657171235,
    0.634624845,
    0.707924417,
    0.833924237,
    0.918962442,
    0.946559067,
    0.920663398,
    0.894833661,
    0.903132391,
    0.897161322,
    0.880948335,
    0.899634159,
    0.918515787,
    1.056561408,
    1.099601621,
    1.081323998,
    1.038297997,
    1.002051655,
    0.895615245,
    0.821436805,
    0.875300052,
    0.832573606,
    0.814401225,
    0.800876574,
    0.751597367,
    0.724797355,
    0.782917106,
    0.903128481,
    1.010419458,
    1.045255416,
    1.040289469,
    1.037733798,
    1.053907357,
    1.057290921,
    1.052594147,
    1.093139755,
    1.143927867,
    1.291835273,
    1.354268187,
    1.345634654,
    1.267352929,
    1.170596076,
    1.004704587,
    0.898861703,
    0.89978318,
    0.865419774,
    0.860813752,
    0.854833007,
    0.831707026,
    0.848190379,
    0.980688858,
    1.078723983,
    1.037259766,
    0.99807282,
    0.967662592,
    0.932992147,
    0.930595893,
    0.91581611,
    0.909047094,
    0.940370454,
    1.017955971,
    1.229426568,
    1.345364417,
    1.350135669,
    1.299318431,
    1.214036806,
    1.05134023,
    0.941647754,
    0.868159797,
    0.838194525,
    0.829377006,
    0.826209082,
    0.803741526,
    0.823132672,
    0.939557198,
    1.0228145,
    0.981454788,
    0.952940126,
    0.919946034,
    0.878957502,
    0.884918628,
    0.88052771,
    0.870946959,
    0.910859006,
    0.986684966,
    1.18370103,
    1.29613831,
    1.298795564,
    1.252907302,
    1.177915851,
    1.022193057,
    0.918262976,
    0.805984905,
    0.776399344,
    0.767751873,
    0.764724903,
    0.743803887,
    0.768643397,
    0.876008167,
    0.943323036,
    0.910675729,
    0.885252139,
    0.847531406,
    0.812859305,
    0.8177451,
    0.81144291,
    0.808344919,
    0.851381742,
    0.927377094,
    1.117218547,
    1.211311293,
    1.212506297,
    1.169973782,
    1.093566256,
    0.944863708,
    0.852649121,
    0.788800722,
    0.76070194,
    0.751390599,
    0.747607059,
    0.728261485,
    0.752602602,
    0.854894982,
    0.925230403,
    0.893281169,
    0.869150411,
    0.830213705,
    0.786462621,
    0.793914687,
    0.791103709,
    0.787382993,
    0.830920246,
    0.897389901,
    1.079864347,
    1.16325844,
    1.158692566,
    1.131630215,
    1.064364642,
    0.925878658,
    0.834936189,
    0.850052571,
    0.821582611,
    0.813990242,
    0.810450114,
    0.786355665,
    0.805563608,
    0.93564977,
    1.036336635,
    1.008782624,
    0.983511977,
    0.934955754,
    0.8884708,
    0.891574887,
    0.885126651,
    0.879897146,
    0.908255847,
    0.943982167,
    1.105036473,
    1.195174451,
    1.191187636,
    1.157365546,
    1.117337291,
    1.005923708,
    0.923928743,
    0.788754282,
    0.754836813,
    0.739334327,
    0.728501638,
    0.685146555,
    0.665679569,
    0.732333416,
    0.852099336,
    0.941269173,
    0.968331552,
    0.941546105,
    0.912476877,
    0.924750851,
    0.920195773,
    0.905766988,
    0.922293608,
    0.940082241,
    1.073197069,
    1.125031308,
    1.107106976,
    1.062409517,
    1.02377025,
    0.913588729,
    0.842533149,
    0.822697113,
    0.783380497,
    0.767036945,
    0.753064173,
    0.705517673,
    0.682439294,
    0.749331671,
    0.870660556,
    0.968293153,
    0.994451331,
    0.987474248,
    0.979941612,
    1.000870365,
    1.002250424,
    0.998724425,
    1.034284867,
    1.078972339,
    1.222556469,
    1.290004705,
    1.277665127,
    1.202100638,
    1.104994921,
    0.940330749,
    0.841310744,
    0.894101545,
    0.861403004,
    0.858177394,
    0.852841126,
    0.831101978,
    0.849250407,
    0.986106179,
    1.082973977,
    1.037416976,
    0.991879841,
    0.960109513,
    0.922290449,
    0.922545266,
    0.907669275,
    0.901022832,
    0.928900318,
    1.003505369,
    1.210863201,
    1.334941786,
    1.341537937,
    1.291902836,
    1.204558068,
    1.039287374,
    0.931908545,
    0.93446671,
    0.905437849,
    0.898997033,
    0.895195311,
    0.871835832,
    0.886145363,
    1.023287484,
    1.12165932,
    1.063079548,
    1.021398099,
    0.98402082,
    0.93691284,
    0.940858832,
    0.93462276,
    0.922043261,
    0.952644082,
    1.02597451,
    1.226436977,
    1.364912123,
    1.376241429,
    1.330082994,
    1.251059205,
    1.089576462,
    0.979913706,
    0.920925147,
    0.891692664,
    0.886059643,
    0.881559178,
    0.858068997,
    0.873114016,
    1.01219977,
    1.105161646,
    1.046830226,
    1.00358297,
    0.959616593,
    0.917237036,
    0.916703825,
    0.907543782,
    0.899130191,
    0.929236472,
    1.003261868,
    1.201436468,
    1.333167845,
    1.348309133,
    1.304723181,
    1.222841696,
    1.06598161,
    0.962228076,
    0.862872569,
    0.83511315,
    0.828087154,
    0.823667847,
    0.802396284,
    0.820043498,
    0.944554562,
    1.029091642,
    0.980806678,
    0.942451817,
    0.899830205,
    0.852026568,
    0.855529017,
    0.851650068,
    0.843399851,
    0.878945869,
    0.944093375,
    1.129564522,
    1.238154219,
    1.246721302,
    1.220370486,
    1.148220604,
    1.002216694,
    0.902555094,
    0.895768837,
    0.867670839,
    0.861874875,
    0.858244657,
    0.832697504,
    0.847428315,
    0.993220434,
    1.100439429,
    1.062826237,
    1.025730903,
    0.975162686,
    0.926925032,
    0.927255982,
    0.920904914,
    0.911758969,
    0.935366535,
    0.970058793,
    1.130538176,
    1.237590564,
    1.245937839,
    1.214001444,
    1.169582872,
    1.051163284,
    0.962551567,
    0.861386351,
    0.825032928,
    0.809368805,
    0.802120017,
    0.759357544,
    0.738573353,
    0.793790989,
    0.899131208,
    1.003389799,
    1.031080391,
    1.006364618,
    0.981379693,
    0.992098078,
    0.993217743,
    0.976511368,
    0.996866118,
    1.019270763,
    1.144937224,
    1.203184319,
    1.200558228,
    1.155334725,
    1.111045033,
    0.989546369,
    0.911635228,
    0.885146628,
    0.843789376,
    0.827431894,
    0.816638574,
    0.769493094,
    0.74524534,
    0.802796153,
    0.910840575,
    1.021641422,
    1.047144862,
    1.042011578,
    1.038144893,
    1.057798238,
    1.064363999,
    1.05849242,
    1.097383669,
    1.145820778,
    1.282124909,
    1.355787263,
    1.358127977,
    1.282657478,
    1.180285373,
    1.004955312,
    0.899659333,
    0.766542209,
    0.735300652,
    0.731222921,
    0.728892187,
    0.709930818,
    0.73865901,
    0.851884011,
    0.912996217,
    0.891798103,
    0.849623909,
    0.82430813,
    0.794698463,
    0.801825987,
    0.792515558,
    0.787734445,
    0.827316038,
    0.900714443,
    1.087845283,
    1.183000963,
    1.190697015,
    1.148184958,
    1.060572311,
    0.893833768,
    0.796301915,
    0.736224812,
    0.709096733,
    0.700722525,
    0.701199512,
    0.682364315,
    0.713402239,
    0.810833814,
    0.856189286,
    0.836012012,
    0.803958807,
    0.776495442,
    0.741670797,
    0.756410258,
    0.757964272,
    0.749629869,
    0.798654138,
    0.870738218,
    1.042955148,
    1.133753193,
    1.141542553,
    1.104615347,
    1.026995805,
    0.866338478,
    0.773261751,
    0.745990958,
    0.71849988,
    0.711284453,
    0.710575217,
    0.690914294,
    0.720624639,
    0.825442121,
    0.871190795,
    0.846620472,
    0.81060954,
    0.775379769,
    0.743870578,
    0.75292151,
    0.750838357,
    0.745786107,
    0.792064483,
    0.864688224,
    1.037332013,
    1.127848244,
    1.141216081,
    1.106296226,
    1.025356617,
    0.868424226,
    0.77892472,
    0.81601898,
    0.789169279,
    0.782508534,
    0.779430434,
    0.758571515,
    0.779667224,
    0.899343837,
    0.968180208,
    0.928186262,
    0.885024776,
    0.844793913,
    0.800331427,
    0.806490026,
    0.805700172,
    0.796289432,
    0.835756343,
    0.899217989,
    1.073837058,
    1.175782898,
    1.191485131,
    1.170255557,
    1.096205451,
    0.945673968,
    0.847822505,
    0.903269307,
    0.875721908,
    0.87111428,
    0.867755956,
    0.84112087,
    0.854525172,
    1.00802799,
    1.112971789,
    1.072837462,
    1.025693784,
    0.974807341,
    0.92668491,
    0.926767846,
    0.921820701,
    0.909555474,
    0.931870092,
    0.964685016,
    1.12057759,
    1.23577087,
    1.255125186,
    1.226857779,
    1.179374251,
    1.054481703,
    0.962354899,
    0.768199224,
    0.735573056,
    0.723024924,
    0.713019741,
    0.675250808,
    0.65843686,
    0.723096594,
    0.837390714,
    0.912127359,
    0.944192684,
    0.912231785,
    0.882280524,
    0.880713723,
    0.87336047,
    0.863860486,
    0.875516191,
    0.885485,
    0.98778706,
    1.065471252,
    1.048468716,
    1.010599411,
    0.979778921,
    0.896796505,
    0.835577,
    0.884411575,
    0.84322595,
    0.828615175,
    0.818405437,
    0.777259279,
    0.755498954,
    0.804198259,
    0.911289467,
    1.010000224,
    1.049179408,
    1.03795869,
    1.031762028,
    1.03690969,
    1.039507305,
    1.040319717,
    1.07421744,
    1.115999198,
    1.227440412,
    1.323932952,
    1.319334637,
    1.247599153,
    1.157780866,
    1.015652314,
    0.92180888,
    0.865675984,
    0.834523176,
    0.834027805,
    0.8287223,
    0.811273091,
    0.83042282,
    0.966215318,
    1.060898517,
    1.002742505,
    0.962373729,
    0.925067384,
    0.88583952,
    0.872830016,
    0.854947206,
    0.852524852,
    0.874147343,
    0.937846557,
    1.112763963,
    1.261972705,
    1.268914801,
    1.226968704,
    1.149540891,
    1.013475362,
    0.916984362,
    0.844077845,
    0.816838428,
    0.812302388,
    0.809515694,
    0.791906029,
    0.812591347,
    0.934967808,
    1.016409638,
    0.957707484,
    0.926205779,
    0.886418805,
    0.841652332,
    0.83563562,
    0.828017239,
    0.821833954,
    0.851821074,
    0.914126748,
    1.075582797,
    1.222823179,
    1.230514156,
    1.193824493,
    1.125948656,
    0.995416029,
    0.902417975,
    0.769778113,
    0.742713847,
    0.738012917,
    0.7355992,
    0.71928564,
    0.74598087,
    0.856392073,
    0.917763812,
    0.871461588,
    0.843658503,
    0.800472955,
    0.763745238,
    0.75686207,
    0.748354773,
    0.74819168,
    0.783495366,
    0.846313533,
    0.998693154,
    1.12336738,
    1.130862679,
    1.098569085,
    1.029463093,
    0.905298112,
    0.823771832,
    0.728430012,
    0.702673047,
    0.696838108,
    0.694128268,
    0.679408481,
    0.707281718,
    0.807157972,
    0.864185885,
    0.824650713,
    0.799795951,
    0.757129599,
    0.714199223,
    0.710529211,
    0.706684606,
    0.706039932,
    0.745144454,
    0.79913583,
    0.941052625,
    1.047691571,
    1.050128059,
    1.034232275,
    0.974333885,
    0.859983667,
    0.780845612,
    0.711379904,
    0.685587387,
    0.680182415,
    0.679050927,
    0.661304188,
    0.690578378,
    0.79999389,
    0.867136057,
    0.847609644,
    0.828063391,
    0.780076049,
    0.739473707,
    0.735522965,
    0.730835501,
    0.730897708,
    0.763082715,
    0.787484531,
    0.899816,
    0.992227324,
    0.991395223,
    0.971653183,
    0.939797611,
    0.853589598,
    0.789700584,
    0.77351932,
    0.740968935,
    0.729137442,
    0.719647602,
    0.680631763,
    0.663316213,
    0.730779061,
    0.839157608,
    0.916225294,
    0.939368778,
    0.907769054,
    0.878705959,
    0.877853831,
    0.872913464,
    0.860368006,
    0.872388906,
    0.88047995,
    0.977237437,
    1.061082553,
    1.055768095,
    1.021873678,
    0.98861895,
    0.898344285,
    0.833727224,
    0.719473201,
    0.685132944,
    0.673759992,
    0.658050254,
    0.61386248,
    0.594457527,
    0.679424227,
    0.798773016,
    0.867585184,
    0.880440644,
    0.866180402,
    0.85460627,
    0.864134886,
    0.860611209,
    0.858490173,
    0.884777078,
    0.912195757,
    1.02035769,
    1.11635199,
    1.10992917,
    1.050873452,
    0.964098807,
    0.82749386,
    0.741324939,
    0.677493104,
    0.648013481,
    0.645608375,
    0.643735126,
    0.62950231,
    0.664212631,
    0.763449755,
    0.808932061,
    0.787164253,
    0.753168872,
    0.724931757,
    0.696707153,
    0.694706403,
    0.6843679,
    0.685134716,
    0.72393404,
    0.784945079,
    0.933825212,
    1.040179979,
    1.045683167,
    1.013598862,
    0.938389409,
    0.801956797,
    0.720275324,
    0.698165405,
    0.672316267,
    0.666505286,
    0.666311737,
    0.650744906,
    0.683276516,
    0.778837027,
    0.821548347,
    0.79083064,
    0.761643018,
    0.728814652,
    0.692533579,
    0.695273385,
    0.693882927,
    0.689370734,
    0.732479858,
    0.79194261,
    0.932265315,
    1.048144134,
    1.057377601,
    1.02941265,
    0.962870576,
    0.830475521,
    0.748150332,
    0.736060606,
    0.709655214,
    0.705379947,
    0.703411114,
    0.686151364,
    0.714869675,
    0.824215844,
    0.874484029,
    0.833861525,
    0.797873509,
    0.75608684,
    0.721245434,
    0.717049274,
    0.711268155,
    0.708830428,
    0.746527688,
    0.806263317,
    0.950396757,
    1.073960335,
    1.090745723,
    1.064050625,
    0.993804387,
    0.863907592,
    0.782169407,
    0.758550222,
    0.733030878,
    0.728584805,
    0.725077829,
    0.707680453,
    0.731978987,
    0.845463603,
    0.907150058,
    0.860603914,
    0.821527432,
    0.777085998,
    0.732266064,
    0.727852093,
    0.724742287,
    0.719558788,
    0.754944463,
    0.805675663,
    0.94671361,
    1.069513435,
    1.085683658,
    1.073632202,
    1.011218531,
    0.888913573,
    0.803348447,
    0.780040549,
    0.754226669,
    0.750787951,
    0.748091691,
    0.726594515,
    0.74890689,
    0.880807946,
    0.962163662,
    0.927965158,
    0.890497118,
    0.838808969,
    0.794018407,
    0.787272629,
    0.782118473,
    0.776258056,
    0.801026813,
    0.822024206,
    0.937956659,
    1.057008562,
    1.0726234,
    1.055688314,
    1.020517149,
    0.924994003,
    0.850906363,
    0.80869355,
    0.774949638,
    0.763324571,
    0.755065088,
    0.714582871,
    0.696111623,
    0.760940537,
    0.860948576,
    0.945974454,
    0.962097201,
    0.931270252,
    0.903829645,
    0.903435392,
    0.902426867,
    0.886468656,
    0.900101929,
    0.907779075,
    0.999167945,
    1.091408047,
    1.100819284,
    1.069821063,
    1.033716873,
    0.933503094,
    0.863152118,
    0.755335426,
    0.719763072,
    0.708576404,
    0.694075762,
    0.648359159,
    0.627766826,
    0.710017066,
    0.821025852,
    0.897921787,
    0.903685166,
    0.890186418,
    0.880210631,
    0.890232683,
    0.890675801,
    0.885098954,
    0.913010211,
    0.939971519,
    1.042838453,
    1.147389459,
    1.155822369,
    1.099682091,
    1.010111109,
    0.863470348,
    0.771474056,
    0.711714351,
    0.679251812,
    0.675421032,
    0.677559667,
    0.663661744,
    0.700999111,
    0.774923083,
    0.793353691,
    0.800319409,
    0.769374809,
    0.745577656,
    0.723967891,
    0.724347605,
    0.722290915,
    0.721386964,
    0.769394153,
    0.833731844,
    0.9705592,
    1.073203526,
    1.094137715,
    1.063899157,
    0.985533709,
    0.836896752,
    0.751130928,
    0.742887868,
    0.71712428,
    0.712872917,
    0.71138635,
    0.692418648,
    0.720005149,
    0.832746921,
    0.884188507,
    0.843564392,
    0.798119404,
    0.762676646,
    0.723415162,
    0.724867259,
    0.723764218,
    0.713996511,
    0.751903578,
    0.807626598,
    0.949138849,
    1.085749444,
    1.110089592,
    1.085823133,
    1.016807411,
    0.875734269,
    0.785332462,
    0.789876939,
    0.76348734,
    0.760899783,
    0.757425287,
    0.736463915,
    0.759425027,
    0.888074,
    0.949399225,
    0.897064693,
    0.843857564,
    0.798985287,
    0.760587057,
    0.754654602,
    0.748877279,
    0.740830605,
    0.772441357,
    0.828340014,
    0.974780953,
    1.121635391,
    1.15421152,
    1.131004515,
    1.058150647,
    0.919228256,
    0.82849239,
    0.765093971,
    0.739882307,
    0.736392712,
    0.732223935,
    0.71229013,
    0.734944601,
    0.856942303,
    0.918181594,
    0.869323957,
    0.817266507,
    0.772049243,
    0.726387122,
    0.722863744,
    0.721234921,
    0.712048656,
    0.745843848,
    0.792712962,
    0.930741147,
    1.064262605,
    1.093058188,
    1.085884311,
    1.022009334,
    0.892249405,
    0.802208124,
    0.76494112,
    0.739562564,
    0.736739838,
    0.733746442,
    0.710213681,
    0.732756342,
    0.868248419,
    0.943939938,
    0.911735404,
    0.863166814,
    0.811748762,
    0.767335434,
    0.762707224,
    0.759710118,
    0.750564107,
    0.775792633,
    0.792873208,
    0.903403837,
    1.027481385,
    1.05430142,
    1.042903286,
    1.006833617,
    0.904557009,
    0.828042249,
    0.82615673,
    0.79184941,
    0.780624531,
    0.772671637,
    0.730088553,
    0.710628767,
    0.776628381,
    0.870860338,
    0.960455354,
    0.966873124,
    0.936300501,
    0.909504629,
    0.91027325,
    0.912301246,
    0.892679998,
    0.906963731,
    0.912327918,
    0.999149701,
    1.099939549,
    1.123105137,
    1.096241138,
    1.058358377,
    0.949281083,
    0.874047043,
    0.822429894,
    0.784307286,
    0.77277349,
    0.760278644,
    0.712902507,
    0.690465068,
    0.764190585,
    0.864253225,
    0.955106968,
    0.956077251,
    0.943794177,
    0.936230279,
    0.946405605,
    0.952371956,
    0.943051174,
    0.973913849,
    1.002091934,
    1.099833777,
    1.215031176,
    1.241238386,
    1.186756114,
    1.093900745,
    0.934612531,
    0.834450232,
    0.708026664,
    0.67904106,
    0.678804714,
    0.675250486,
    0.655448492,
    0.685335395,
    0.805604856,
    0.854551739,
    0.824697409,
    0.763071514,
    0.732405856,
    0.701444792,
    0.700305956,
    0.692380309,
    0.684517137,
    0.718460497,
    0.771670482,
    0.916593246,
    1.049138916,
    1.081098899,
    1.058296776,
    0.979826891,
    0.827881527,
    0.735541531,
    0.680875945,
    0.655911464,
    0.651797031,
    0.651212986,
    0.63177032,
    0.664271626,
    0.769290667,
    0.802718111,
    0.773551228,
    0.721780736,
    0.688930057,
    0.653022223,
    0.658973296,
    0.6614047,
    0.650456982,
    0.693288188,
    0.745441991,
    0.875893459,
    1.003852859,
    1.036324225,
    1.018763293,
    0.949275215,
    0.803165757,
    0.715165589,
    0.741499723,
    0.716018468,
    0.714308004,
    0.712372524,
    0.691645926,
    0.719581471,
    0.842308628,
    0.88670897,
    0.843367745,
    0.78417579,
    0.741379445,
    0.706290506,
    0.703467435,
    0.70063127,
    0.692077795,
    0.72738423,
    0.780785947,
    0.916605384,
    1.05563407,
    1.097181447,
    1.079694385,
    1.004340717,
    0.860362578,
    0.771551278,
    0.810088853,
    0.785385292,
    0.784733233,
    0.781255244,
    0.760205264,
    0.780938129,
    0.91706367,
    0.982010141,
    0.923831783,
    0.858798954,
    0.811210013,
    0.764165967,
    0.758003403,
    0.756229485,
    0.744450521,
    0.773103444,
    0.818460871,
    0.955398323,
    1.103287025,
    1.147330492,
    1.142618224,
    1.072387319,
    0.934847357,
    0.838565168,
    0.790387333,
    0.765842764,
    0.766100989,
    0.765073496,
    0.742017949,
    0.765619476,
    0.909415412,
    0.981719663,
    0.944331633,
    0.886222456,
    0.833557449,
    0.789669801,
    0.782810435,
    0.780108681,
    0.770217616,
    0.792245001,
    0.808950822,
    0.91501969,
    1.045331161,
    1.085967532,
    1.076974849,
    1.033244072,
    0.924306817,
    0.844294232,
    0.751661948,
    0.721893511,
    0.705425049,
    0.696658053,
    0.663291565,
    0.654759225,
    0.72616811,
    0.832425685,
    0.904306173,
    0.917916077,
    0.903295212,
    0.899322207,
    0.891116743,
    0.892831496,
    0.888251122,
    0.919559889,
    0.955179652,
    1.044250092,
    1.152392112,
    1.180886504,
    1.11021633,
    0.99367813,
    0.840377826,
    0.759209843,
    0.734303051,
    0.710933285,
    0.705839133,
    0.710132839,
    0.707961579,
    0.752914343,
    0.85708529,
    0.902072951,
    0.861127429,
    0.818891921,
    0.786098068,
    0.760238515,
    0.736982789,
    0.727574617,
    0.72562963,
    0.762952666,
    0.83302585,
    0.970462642,
    1.101888792,
    1.142911086,
    1.099275002,
    0.992675419,
    0.841677572,
    0.763083906,
    0.744432661,
    0.725326653,
    0.717003748,
    0.724027781,
    0.722263275,
    0.7670456,
    0.862393864,
    0.900120755,
    0.852664736,
    0.818889898,
    0.781967797,
    0.748624592,
    0.730644976,
    0.730577173,
    0.725109133,
    0.76810778,
    0.838068162,
    0.963400737,
    1.099428056,
    1.142089105,
    1.101766509,
    1.002619286,
    0.85795661,
    0.781271639,
    0.695607012,
    0.676848455,
    0.669333051,
    0.67690009,
    0.67717823,
    0.726814887,
    0.814073076,
    0.836481448,
    0.796838473,
    0.766419356,
    0.724573106,
    0.69826441,
    0.677454918,
    0.675078591,
    0.676526,
    0.72231661,
    0.794192287,
    0.911643603,
    1.029611649,
    1.072673117,
    1.034845377,
    0.931850024,
    0.794525567,
    0.728497422,
    0.625251116,
    0.60839533,
    0.599812131,
    0.608280598,
    0.612388547,
    0.66671318,
    0.734491154,
    0.744328423,
    0.717747441,
    0.695104756,
    0.655241316,
    0.624252467,
    0.607973308,
    0.610895448,
    0.614372158,
    0.666729486,
    0.730762812,
    0.832441199,
    0.921994143,
    0.956483171,
    0.935388439,
    0.840808669,
    0.71637433,
    0.656870185,
    0.608185198,
    0.591640809,
    0.584063628,
    0.594900149,
    0.597260659,
    0.6540997,
    0.729880194,
    0.749152995,
    0.744086315,
    0.729181208,
    0.683783219,
    0.655498262,
    0.638546923,
    0.640099242,
    0.645906928,
    0.69089349,
    0.72518355,
    0.794088315,
    0.866632508,
    0.896315586,
    0.869824411,
    0.802866072,
    0.709701926,
    0.667612209,
    0.702012775,
    0.680714434,
    0.669315709,
    0.668013551,
    0.646332685,
    0.648097282,
    0.711800231,
    0.803093876,
    0.86931973,
    0.886348791,
    0.852403878,
    0.828709866,
    0.810949608,
    0.805551459,
    0.796498523,
    0.808206767,
    0.821873023,
    0.884232316,
    0.968049752,
    0.992738953,
    0.950666921,
    0.880254943,
    0.786225602,
    0.740028003,
    0.748336011,
    0.721383933,
    0.709469338,
    0.705990256,
    0.681400913,
    0.680376712,
    0.739842011,
    0.830125847,
    0.907940797,
    0.926917893,
    0.913440367,
    0.912301166,
    0.902956657,
    0.903803703,
    0.907285857,
    0.938914341,
    0.981952807,
    1.053245293,
    1.151602674,
    1.181950046,
    1.106781909,
    0.974747764,
    0.825720195,
    0.751516155,
    0.719805528,
    0.699502514,
    0.698970525,
    0.708486378,
    0.715186999,
    0.768289606,
    0.860244183,
    0.888374428,
    0.853481357,
    0.816592247,
    0.785111017,
    0.762236813,
    0.738262745,
    0.727890044,
    0.734234892,
    0.772167436,
    0.84926329,
    0.968359353,
    1.088164877,
    1.130258521,
    1.082574527,
    0.960759673,
    0.814621582,
    0.743904099,
    0.797173361,
    0.78069989,
    0.77797817,
    0.788981107,
    0.794341347,
    0.841444733,
    0.939952504,
    0.977269802,
    0.922504536,
    0.887878747,
    0.8489466,
    0.814678054,
    0.792303011,
    0.789166606,
    0.789673598,
    0.826860428,
    0.903751788,
    1.018442129,
    1.160815618,
    1.209178056,
    1.162908097,
    1.046997371,
    0.904890287,
    0.829593466,
    0.782082049,
    0.765736609,
    0.764349611,
    0.775278682,
    0.781804217,
    0.83085771,
    0.92897994,
    0.959209488,
    0.905555816,
    0.871176132,
    0.82565013,
    0.796460269,
    0.769407376,
    0.762905463,
    0.769175455,
    0.805930997,
    0.884687363,
    0.995370439,
    1.128665624,
    1.179770439,
    1.13504777,
    1.014504373,
    0.878570714,
    0.8106762,
    0.766094973,
    0.751300461,
    0.749686463,
    0.760512498,
    0.76945376,
    0.81848548,
    0.909559518,
    0.940514875,
    0.889119016,
    0.857503978,
    0.811273397,
    0.774242241,
    0.748745918,
    0.745841027,
    0.752269445,
    0.790399189,
    0.861237166,
    0.962377081,
    1.081778968,
    1.128059037,
    1.098535253,
    0.985155967,
    0.860246785,
    0.793629321,
    0.741193531,
    0.726757478,
    0.726040979,
    0.739394551,
    0.746789817,
    0.799058349,
    0.896322495,
    0.934751296,
    0.906431632,
    0.88327547,
    0.831891724,
    0.798033511,
    0.772286825,
    0.768254491,
    0.777302496,
    0.808806394,
    0.849902165,
    0.917397137,
    1.017625205,
    1.058600701,
    1.023913755,
    0.93832034,
    0.844930712,
    0.796484752,
    0.783313523,
    0.761445745,
    0.753420847,
    0.760453756,
    0.748392962,
    0.756478865,
    0.791555177,
    0.85765788,
    0.945828743,
    0.976766523,
    0.945602804,
    0.927899768,
    0.907163732,
    0.90511759,
    0.904154521,
    0.920102698,
    0.947282912,
    0.990926003,
    1.067957194,
    1.101503097,
    1.050708021,
    0.962015066,
    0.865753671,
    0.820993533,
    0.761681388,
    0.736863683,
    0.729342266,
    0.731826405,
    0.716168088,
    0.72264577,
    0.766806856,
    0.839085814,
    0.92598008,
    0.951985531,
    0.939936557,
    0.942316616,
    0.931431736,
    0.9322192,
    0.94386992,
    0.976558797,
    1.028160428,
    1.081635279,
    1.170434211,
    1.204233734,
    1.123800038,
    0.975631347,
    0.829497455,
    0.761173159,
    0.696562679,
    0.67936034,
    0.683333946,
    0.698325036,
    0.714110353,
    0.776391735,
    0.85400133,
    0.862850803,
    0.835778,
    0.805035273,
    0.775262145,
    0.755965533,
    0.731709461,
    0.72062831,
    0.735681949,
    0.775037992,
    0.859107183,
    0.959025274,
    1.0644253,
    1.10724544,
    1.055836076,
    0.918908308,
    0.777808735,
    0.715816977,
    0.670257655,
    0.657546474,
    0.657756935,
    0.676185285,
    0.693319559,
    0.758686924,
    0.819095055,
    0.8116679,
    0.785335954,
    0.7664102,
    0.734296151,
    0.709665234,
    0.692662592,
    0.692055514,
    0.704883432,
    0.753386947,
    0.837371534,
    0.921071525,
    1.021175235,
    1.063217957,
    1.016178157,
    0.887504885,
    0.753945888,
    0.69738105,
    0.67524281,
    0.662527568,
    0.664385005,
    0.682370868,
    0.70014423,
    0.765732261,
    0.830343081,
    0.820730402,
    0.791520653,
    0.770988502,
    0.731286274,
    0.710569711,
    0.687789772,
    0.683188664,
    0.70109113,
    0.747245286,
    0.833061211,
    0.915068393,
    1.011438584,
    1.057618632,
    1.011564819,
    0.877798241,
    0.749716483,
    0.698614238,
    0.642453069,
    0.631393199,
    0.632772735,
    0.650976632,
    0.671597243,
    0.738653603,
    0.792359914,
    0.779329808,
    0.755719815,
    0.739498752,
    0.699917121,
    0.672346851,
    0.65203734,
    0.651557247,
    0.670209473,
    0.719339975,
    0.797249664,
    0.867817781,
    0.945747933,
    0.985979767,
    0.955611179,
    0.829388081,
    0.712888889,
    0.664683486,
    0.618181908,
    0.607473134,
    0.609764127,
    0.630489096,
    0.649541908,
    0.719799467,
    0.779833655,
    0.774414131,
    0.773756095,
    0.765933371,
    0.721164032,
    0.696735883,
    0.676138797,
    0.674510792,
    0.695766895,
    0.738209995,
    0.786368059,
    0.823385138,
    0.88227647,
    0.917267519,
    0.881726389,
    0.783274007,
    0.69826059,
    0.668163257,
    0.703017672,
    0.686996843,
    0.684793711,
    0.694544815,
    0.690438907,
    0.707744303,
    0.745868321,
    0.803803841,
    0.883306006,
    0.911767683,
    0.880147635,
    0.862581022,
    0.842266533,
    0.835450382,
    0.842793652,
    0.855404759,
    0.884281127,
    0.911455309,
    0.97495654,
    1.004607143,
    0.953352494,
    0.85147598,
    0.765115471,
    0.732343295,
    0.716158829,
    0.695800242,
    0.693586759,
    0.700010371,
    0.692650941,
    0.707773462,
    0.748163018,
    0.808576627,
    0.893379439,
    0.920421954,
    0.908602022,
    0.912390814,
    0.901187767,
    0.898947933,
    0.918874265,
    0.949847846,
    1.005664478,
    1.042246337,
    1.118884746,
    1.151586904,
    1.068937878,
    0.906485017,
    0.767611028,
    0.708997311,
    0.653765846,
    0.63976643,
    0.647978063,
    0.668848102,
    0.694188345,
    0.767493237,
    0.826233324,
    0.810881528,
    0.795523943,
    0.772712862,
    0.745588301,
    0.731052523,
    0.707562002,
    0.696377902,
    0.720863119,
    0.763499222,
    0.854505731,
    0.93316685,
    1.018675619,
    1.061033597,
    1.00651411,
    0.854911752,
    0.719429005,
    0.668032502,
    0.635934636,
    0.626368774,
    0.630947205,
    0.655097088,
    0.681559201,
    0.757218875,
    0.800697748,
    0.771143086,
    0.754841137,
    0.743062848,
    0.713176914,
    0.692814968,
    0.676114249,
    0.67513958,
    0.697105329,
    0.748079571,
    0.838982674,
    0.902413514,
    0.984881709,
    1.027052505,
    0.976668898,
    0.833134298,
    0.704894619,
    0.658102849,
    0.641892263,
    0.632312867,
    0.638552967,
    0.66224244,
    0.689311084,
    0.765113058,
    0.813018419,
    0.781514498,
    0.762140428,
    0.748662787,
    0.71113852,
    0.694636143,
    0.672104344,
    0.667105601,
    0.694111381,
    0.742643125,
    0.83536817,
    0.897237283,
    0.976218918,
    1.022603295,
    0.973184792,
    0.824542301,
    0.701738061,
    0.660310406,
    0.647040978,
    0.63887041,
    0.645209971,
    0.668406202,
    0.697328769,
    0.771288418,
    0.816980603,
    0.7913684,
    0.770050329,
    0.757383504,
    0.718106395,
    0.692536937,
    0.670404584,
    0.668342037,
    0.694777462,
    0.742664341,
    0.827427971,
    0.882208704,
    0.952920151,
    0.995952569,
    0.961147606,
    0.819195434,
    0.706676151,
    0.664478138,
    0.605203506,
    0.597493502,
    0.604474809,
    0.630519407,
    0.658322537,
    0.737019658,
    0.785020769,
    0.762712809,
    0.767837172,
    0.7651841,
    0.721584154,
    0.700182604,
    0.67872158,
    0.676060764,
    0.705706859,
    0.748583091,
    0.803608897,
    0.822852538,
    0.869805714,
    0.906404387,
    0.866932091,
    0.753160733,
    0.67271703,
    0.650317364,
    0.598275052,
    0.589054957,
    0.593038467,
    0.604648623,
    0.608197038,
    0.635175787,
    0.681185704,
    0.733561749,
    0.799728902,
    0.823201651,
    0.790619735,
    0.772303337,
    0.752921049,
    0.740104408,
    0.755769813,
    0.763887737,
    0.792619351,
    0.803789865,
    0.852703021,
    0.876571145,
    0.826146102,
    0.711915034,
    0.637247009,
    0.61802637,
    0.626456806,
    0.612067967,
    0.615612737,
    0.624150299,
    0.624598684,
    0.648440506,
    0.694501305,
    0.74820599,
    0.82229095,
    0.845473447,
    0.832531178,
    0.836332076,
    0.82567026,
    0.817866965,
    0.846321762,
    0.873706693,
    0.930678747,
    0.951253415,
    1.013917176,
    1.042667832,
    0.959995191,
    0.785664852,
    0.657041677,
    0.610502136,
    0.625100378,
    0.613408083,
    0.625370697,
    0.651020043,
    0.685563603,
    0.766463216,
    0.81154488,
    0.776554119,
    0.769746027,
    0.752465123,
    0.725855163,
    0.716056194,
    0.692356316,
    0.679674864,
    0.713854119,
    0.759227095,
    0.857874917,
    0.917456955,
    0.986619218,
    1.031580011,
    0.973255379,
    0.809799208,
    0.678384156,
    0.63461743,
    0.612709657,
    0.604870469,
    0.612799838,
    0.641051126,
    0.676615367,
    0.757858078,
    0.790283087,
    0.743181019,
    0.734105342,
    0.726607672,
    0.695979776,
    0.680587308,
    0.66324037,
    0.659935954,
    0.691936662,
    0.745687173,
    0.844755955,
    0.890044325,
    0.957493642,
    1.004064063,
    0.949492718,
    0.796122813,
    0.671056273,
    0.630278637,
    0.615591822,
    0.614797254,
    0.61764567,
    0.644681922,
    0.681679478,
    0.766207918,
    0.815373646,
    0.797902078,
    0.765774946,
    0.742730406,
    0.712880577,
    0.681191123,
    0.657991563,
    0.661048261,
    0.672643592,
    0.711515135,
    0.795891508,
    0.859595307,
    0.933727223,
    1.010285396,
    0.975811377,
    0.827525909,
    0.69226391,
    0.638074205,
    0.587425348,
    0.587685599,
    0.589655759,
    0.616274171,
    0.655974517,
    0.740117767,
    0.780851635,
    0.761708765,
    0.734051482,
    0.714128603,
    0.683254646,
    0.64521483,
    0.624079769,
    0.630387794,
    0.643091033,
    0.685042884,
    0.762231947,
    0.815560261,
    0.872627873,
    0.945038452,
    0.925497635,
    0.786549321,
    0.661731573,
    0.608938642,
    0.588046171,
    0.587962808,
    0.590717997,
    0.618773867,
    0.656349707,
    0.740000879,
    0.793756407,
    0.788701455,
    0.778312875,
    0.763598437,
    0.725529766,
    0.68966072,
    0.666790135,
    0.670587079,
    0.685484878,
    0.719160938,
    0.767891084,
    0.791679181,
    0.836609723,
    0.906951531,
    0.881477026,
    0.771068468,
    0.675357262,
    0.636777088,
    0.599220703,
    0.595152815,
    0.593107097,
    0.60925095,
    0.624835184,
    0.659092893,
    0.692455341,
    0.745430252,
    0.812400338,
    0.831577693,
    0.806273169,
    0.778211699,
    0.758126031,
    0.755420645,
    0.758500211,
    0.765184261,
    0.792983697,
    0.804137482,
    0.842851906,
    0.904059209,
    0.865951166,
    0.756251256,
    0.662528825,
    0.626008981,
    0.625204193,
    0.615888318,
    0.613185562,
    0.625958647,
    0.638514959,
    0.668889647,
    0.703471554,
    0.758443765,
    0.832567931,
    0.850847418,
    0.844065841,
    0.83771259,
    0.826054069,
    0.827773362,
    0.843549247,
    0.869148391,
    0.924558542,
    0.945098202,
    0.997166961,
    1.06374798,
    0.994239476,
    0.827292607,
    0.680846858,
    0.617257571,
    0.604336865,
    0.599591988,
    0.606412302,
    0.633269544,
    0.67751823,
    0.76126654,
    0.813520807,
    0.796563133,
    0.774811231,
    0.745041808,
    0.722194071,
    0.69858192,
    0.673503723,
    0.666681514,
    0.686840637,
    0.724064024,
    0.815991278,
    0.879148455,
    0.944733764,
    1.025063488,
    0.981008971,
    0.825557545,
    0.67933797,
    0.618650582,
    0.570648438,
    0.56979765,
    0.572364169,
    0.602156499,
    0.647946143,
    0.733925198,
    0.768907491,
    0.734883106,
    0.715226477,
    0.696975535,
    0.671309503,
    0.643500962,
    0.625789696,
    0.628780085,
    0.647542317,
    0.694968839,
    0.787313109,
    0.834110282,
    0.892263045,
    0.972634463,
    0.932852679,
    0.787686794,
    0.648391301,
    0.592712265,
    0.568195765,
    0.56686444,
    0.570450103,
    0.599388694,
    0.646006409,
    0.731358121,
    0.77006772,
    0.732883964,
    0.711642946,
    0.691784327,
    0.658162013,
    0.635099813,
    0.61204086,
    0.610751783,
    0.635182282,
    0.681552622,
    0.776173904,
    0.820827399,
    0.873295802,
    0.958531477,
    0.91970913,
    0.771826176,
    0.637425504,
    0.586714811,
    0.566316471,
    0.565881415,
    0.568987965,
    0.597015874,
    0.645638099,
    0.728315355,
    0.764603879,
    0.732222681,
    0.710224155,
    0.691076421,
    0.655159178,
    0.624188712,
    0.601768516,
    0.60292978,
    0.62751992,
    0.67445044,
    0.761830108,
    0.799119332,
    0.841624439,
    0.924449339,
    0.899821568,
    0.760658852,
    0.635827138,
    0.583991435,
    0.556475832,
    0.555775248,
    0.559505685,
    0.589166529,
    0.635919922,
    0.719027065,
    0.765930859,
    0.745096621,
    0.742445248,
    0.729483411,
    0.686915575,
    0.658701232,
    0.635126222,
    0.634125175,
    0.661235714,
    0.70087699,
    0.75978775,
    0.766352643,
    0.793965151,
    0.873946046,
    0.843690583,
    0.733283965,
    0.637934431,
    0.60133853,
    0.561102062,
    0.55762574,
    0.557494256,
    0.573506729,
    0.597175213,
    0.629034252,
    0.668728939,
    0.716494255,
    0.779089023,
    0.79395428,
    0.762113837,
    0.738783254,
    0.717207439,
    0.70711878,
    0.721431165,
    0.729779124,
    0.765187201,
    0.762770096,
    0.790354655,
    0.860281488,
    0.818358684,
    0.709097115,
    0.61742052,
    0.582459217,
    0.561670576,
    0.551056015,
    0.543396409,
    0.543594115,
    0.543473369,
    0.553408648,
    0.61708804,
    0.692629159,
    0.753008298,
    0.77202458,
    0.760636106,
    0.751560588,
    0.749901715,
    0.743295106,
    0.752895045,
    0.791708999,
    0.842030376,
    0.860179789,
    0.909502682,
    0.9571796,
    0.899281389,
    0.747312537,
    0.613377639,
    0.557351629,
    0.53772654,
    0.529390417,
    0.529407077,
    0.547922516,
    0.581235526,
    0.651414368,
    0.701113492,
    0.678193102,
    0.670955872,
    0.656409868,
    0.634504456,
    0.614902042,
    0.602557492,
    0.593694616,
    0.609710428,
    0.67040073,
    0.761333763,
    0.814216015,
    0.858455926,
    0.920174106,
    0.887236795,
    0.74526331,
    0.608592892,
    0.558555196,
    0.534575138,
    0.529988393,
    0.52654549,
    0.547789545,
    0.580957633,
    0.652303537,
    0.690885319,
    0.657506206,
    0.646464197,
    0.641132973,
    0.615401045,
    0.589656827,
    0.583215451,
    0.583634919,
    0.596728177,
    0.664602752,
    0.754771958,
    0.796240868,
    0.839632943,
    0.903149468,
    0.874615081,
    0.741310554,
    0.610406857,
    0.56300914,
    0.53663358,
    0.531586108,
    0.529556797,
    0.550224604,
    0.58321039,
    0.655130327,
    0.697640925,
    0.661331225,
    0.647977336,
    0.641130041,
    0.607713578,
    0.586290946,
    0.574458476,
    0.570878966,
    0.589019544,
    0.655305648,
    0.746605126,
    0.787907992,
    0.825220298,
    0.893585973,
    0.866879808,
    0.72984433,
    0.603560137,
    0.561269446,
    0.542238699,
    0.537732201,
    0.535284609,
    0.554832145,
    0.588420229,
    0.658474705,
    0.69882583,
    0.668544551,
    0.654458326,
    0.649072231,
    0.614531034,
    0.585713078,
    0.574860591,
    0.575272135,
    0.593082046,
    0.659756498,
    0.742716132,
    0.779161821,
    0.806609219,
    0.870734999,
    0.858870716,
    0.729140224,
    0.611560368,
    0.5672762,
    0.551044849,
    0.544344812,
    0.541447195,
    0.560595434,
    0.589553438,
    0.659881164,
    0.711312589,
    0.696835898,
    0.705361055,
    0.709726285,
    0.672742775,
    0.651521021,
    0.641458989,
    0.646097071,
    0.66758608,
    0.728301911,
    0.781835567,
    0.791798079,
    0.804977519,
    0.858689433,
    0.840774431,
    0.735898146,
    0.644353751,
    0.610612458,
    0.554388682,
    0.547819207,
    0.543015792,
    0.549434066,
    0.554428619,
    0.572064991,
    0.62684873,
    0.689357944,
    0.748093904,
    0.771594627,
    0.741748268,
    0.715393305,
    0.70403321,
    0.69245534,
    0.694058364,
    0.717233527,
    0.744491498,
    0.749867669,
    0.77292479,
    0.819228323,
    0.792739633,
    0.693823546,
    0.610311654,
    0.581302111,
    0.590193493,
    0.578078241,
    0.57239843,
    0.575691337,
    0.576362244,
    0.590573331,
    0.644276742,
    0.708882546,
    0.776409944,
    0.800345908,
    0.790888672,
    0.785912707,
    0.783109422,
    0.777605508,
    0.789836728,
    0.832248199,
    0.886602085,
    0.903498202,
    0.940917443,
    0.989856025,
    0.932759623,
    0.776508356,
    0.639672403,
    0.582986137,
    0.573799972,
    0.564624786,
    0.566933504,
    0.586848984,
    0.61952751,
    0.690905656,
    0.741394242,
    0.718505149,
    0.708710577,
    0.694319594,
    0.673747717,
    0.655372203,
    0.640934691,
    0.632029286,
    0.648140272,
    0.708818102,
    0.802203143,
    0.858053798,
    0.899242729,
    0.959309611,
    0.927699506,
    0.782621342,
    0.643888573,
    0.59128045,
    0.555415355,
    0.550052123,
    0.54819404,
    0.57076769,
    0.603270444,
    0.6762986,
    0.711739311,
    0.675933558,
    0.665810336,
    0.662279881,
    0.639949092,
    0.616003566,
    0.608508736,
    0.61026724,
    0.622462966,
    0.69205944,
    0.784776591,
    0.828003591,
    0.865997517,
    0.92365714,
    0.897125267,
    0.762213054,
    0.629931468,
    0.580946317,
    0.547337347,
    0.541543146,
    0.540468278,
    0.562298784,
    0.594455245,
    0.66809296,
    0.704701469,
    0.664760747,
    0.654787572,
    0.650916306,
    0.622716602,
    0.603366141,
    0.591241844,
    0.590223556,
    0.60629584,
    0.675551578,
    0.769487953,
    0.811921277,
    0.842859232,
    0.901510023,
    0.877336681,
    0.740076546,
    0.61292582,
    0.569538113,
    0.584328826,
    0.574434695,
    0.570113219,
    0.585841577,
    0.615113144,
    0.685200312,
    0.720497733,
    0.693611583,
    0.692328866,
    0.698159516,
    0.676242027,
    0.662906745,
    0.656402889,
    0.672713143,
    0.695403102,
    0.769342105,
    0.855219407,
    0.897095894,
    0.917075065,
    0.9583777,
    0.945585652,
    0.806805232,
    0.680508542,
    0.624511258,
    0.555375918,
    0.549012977,
    0.547336255,
    0.568491244,
    0.59703823,
    0.668571419,
    0.713889157,
    0.695594224,
    0.70530671,
    0.710708273,
    0.678186751,
    0.655399048,
    0.644038186,
    0.648724089,
    0.665063009,
    0.727823765,
    0.783966883,
    0.794646613,
    0.803671105,
    0.848215106,
    0.833845725,
    0.731931149,
    0.641535225,
    0.608716242,
    0.572032991,
    0.564437365,
    0.56012711,
    0.567581091,
    0.571428006,
    0.589858728,
    0.637643495,
    0.697231328,
    0.760525344,
    0.787635519,
    0.763964663,
    0.739570227,
    0.727904778,
    0.719780909,
    0.718140364,
    0.744662181,
    0.775000765,
    0.781977285,
    0.800590523,
    0.835480072,
    0.811362212,
    0.712948758,
    0.628211618,
    0.59723737,
    0.576658135,
    0.564752886,
    0.560000353,
    0.563203486,
    0.562385177,
    0.577847649,
    0.630728035,
    0.695819927,
    0.761982588,
    0.786356498,
    0.782454503,
    0.77827737,
    0.775841375,
    0.772232452,
    0.781206547,
    0.825510419,
    0.880660705,
    0.899589066,
    0.93142205,
    0.9664143,
    0.913270998,
    0.75858061,
    0.622882929,
    0.566248135,
    0.572239372,
    0.562347303,
    0.564975143,
    0.585901811,
    0.617663682,
    0.690955356,
    0.732681347,
    0.704434504,
    0.700679132,
    0.690490732,
    0.676627528,
    0.660724615,
    0.64685594,
    0.641532807,
    0.654779686,
    0.719801343,
    0.815818583,
    0.871890737,
    0.90504776,
    0.952245336,
    0.923684612,
    0.779738177,
    0.640688888,
    0.587740538,
    0.555518936,
    0.549422339,
    0.547878051,
    0.571397407,
    0.602912256,
    0.677634857,
    0.704757528,
    0.664104697,
    0.659667465,
    0.660168237,
    0.644459911,
    0.622864672,
    0.615859839,
    0.621152903,
    0.630381962,
    0.704165182,
    0.799493925,
    0.843164382,
    0.873685385,
    0.918553142,
    0.895023126,
    0.761276487,
    0.62862678,
    0.579117619,
    0.559066791,
    0.552458874,
    0.551843718,
    0.574367072,
    0.605205174,
    0.679446186,
    0.710465231,
    0.668635034,
    0.662012858,
    0.661082295,
    0.638926234,
    0.621221371,
    0.608964662,
    0.611121473,
    0.623778098,
    0.696112037,
    0.792626229,
    0.836862702,
    0.863563061,
    0.910180555,
    0.88867796,
    0.752391975,
    0.624483067,
    0.57942564,
    0.563316046,
    0.557570172,
    0.556383313,
    0.57784595,
    0.609178744,
    0.680866943,
    0.709676028,
    0.674674135,
    0.666664408,
    0.666603722,
    0.643648771,
    0.617172112,
    0.605643176,
    0.611386138,
    0.62195687,
    0.694447315,
    0.782628583,
    0.822228543,
    0.840873595,
    0.881980882,
    0.875735393,
    0.748359004,
    0.629858128,
    0.583140361,
    0.524049319,
    0.515828758,
    0.516120855,
    0.536824575,
    0.564514577,
    0.643072397,
    0.69516502,
    0.679980704,
    0.679101997,
    0.672803466,
    0.644945612,
    0.608325284,
    0.601419078,
    0.602071996,
    0.632957314,
    0.684297666,
    0.747292775,
    0.747994579,
    0.751630788,
    0.806390157,
    0.815011993,
    0.704950571,
    0.601918554,
    0.575760727,
    0.586330741,
    0.569284125,
    0.562738395,
    0.561525138,
    0.559104594,
    0.582988951,
    0.640296395,
    0.71356061,
    0.780855418,
    0.809487452,
    0.801481796,
    0.786265416,
    0.786920213,
    0.796146726,
    0.82268664,
    0.844712452,
    0.881900374,
    0.885492053,
    0.894197321,
    0.920118954,
    0.913200661,
    0.790583324,
    0.68018574,
    0.63894745,
    0.604968072,
    0.582051027,
    0.574205692,
    0.567510994,
    0.559549854,
    0.57993448,
    0.641705391,
    0.721754508,
    0.795545414,
    0.824267491,
    0.837923246,
    0.847015695,
    0.858084414,
    0.875717119,
    0.915139686,
    0.956392242,
    1.018799712,
    1.035402538,
    1.056896969,
    1.0802214,
    1.043014946,
    0.86147974,
    0.697506999,
    0.627301789,
    0.54066964,
    0.526404923,
    0.529113169,
    0.547079141,
    0.576816194,
    0.656484708,
    0.703377542,
    0.678392931,
    0.671080352,
    0.653759566,
    0.647525223,
    0.625425278,
    0.618879871,
    0.615489143,
    0.647867749,
    0.705262377,
    0.808540241,
    0.855023694,
    0.877904708,
    0.930175636,
    0.92315734,
    0.768841937,
    0.613467178,
    0.562898123,
    0.533226538,
    0.52320464,
    0.522124372,
    0.542908534,
    0.572654962,
    0.651414934,
    0.688156146,
    0.654037931,
    0.640831173,
    0.630499586,
    0.619575814,
    0.588431474,
    0.586861317,
    0.590470397,
    0.616987484,
    0.681178063,
    0.784065551,
    0.819403642,
    0.844381434,
    0.898670207,
    0.896801281,
    0.755907236,
    0.607525683,
    0.560185387,
    0.55014725,
    0.537262845,
    0.53567626,
    0.553010779,
    0.580731349,
    0.657377146,
    0.699537955,
    0.667505053,
    0.655785863,
    0.646903237,
    0.631593243,
    0.610486423,
    0.605495219,
    0.610907808,
    0.644583525,
    0.709458626,
    0.813985679,
    0.85180938,
    0.872037651,
    0.924420776,
    0.922861868,
    0.776922626,
    0.62939104,
    0.581328046,
    0.632347035,
    0.608176912,
    0.599251065,
    0.603061067,
    0.6232004,
    0.69356488,
    0.736940032,
    0.727467363,
    0.737871585,
    0.750131211,
    0.75004277,
    0.755450188,
    0.762686646,
    0.803740808,
    0.856149518,
    0.932237481,
    1.029396361,
    1.07262093,
    1.078378529,
    1.098172562,
    1.102481305,
    0.942464538,
    0.783584565,
    0.707064752,
    0.53809224,
    0.527046331,
    0.525813247,
    0.544768165,
    0.570998383,
    0.643718021,
    0.696336191,
    0.683561021,
    0.684420297,
    0.677942123,
    0.652325801,
    0.618926307,
    0.611361072,
    0.612719691,
    0.64165496,
    0.697817652,
    0.765108126,
    0.769251568,
    0.769958837,
    0.817813079,
    0.828157482,
    0.727341907,
    0.620989416,
    0.58882456,
    0.556727991,
    0.543173576,
    0.538398594,
    0.541840943,
    0.542542481,
    0.560886745,
    0.618514685,
    0.686391833,
    0.741603905,
    0.757623115,
    0.741627603,
    0.710071217,
    0.703356928,
    0.69432005,
    0.707558681,
    0.72863417,
    0.770107878,
    0.771887422,
    0.781580241,
    0.81756223,
    0.81740871,
    0.7194688,
    0.616778939,
    0.5835146,
    0.567003543,
    0.548570586,
    0.542900652,
    0.541823863,
    0.537886644,
    0.551972691,
    0.614806339,
    0.688448794,
    0.747210355,
    0.760448939,
    0.763583087,
    0.752671291,
    0.754966661,
    0.750245851,
    0.774328705,
    0.81371165,
    0.880633123,
    0.894582976,
    0.917973833,
    0.955420519,
    0.925851986,
    0.773111217,
    0.618695685,
    0.558678363,
    0.549435269,
    0.532717566,
    0.533818852,
    0.55026735,
    0.579188458,
    0.651037543,
    0.700495878,
    0.67824576,
    0.669858677,
    0.649401274,
    0.642610072,
    0.621111478,
    0.612668389,
    0.60626592,
    0.635355179,
    0.697105468,
    0.805172278,
    0.854869953,
    0.87609723,
    0.92573838,
    0.920900148,
    0.780691289,
    0.622910852,
    0.567120363,
    0.547299302,
    0.533928055,
    0.530718489,
    0.549043788,
    0.577314707,
    0.6481892,
    0.687437788,
    0.657094089,
    0.644902806,
    0.633243911,
    0.623225825,
    0.595473698,
    0.593021715,
    0.596333309,
    0.621163715,
    0.690599884,
    0.798243254,
    0.837478081,
    0.860180152,
    0.909379644,
    0.908985033,
    0.780109548,
    0.627792419,
    0.57330301,
    0.708503201,
    0.670407904,
    0.654761805,
    0.647257845,
    0.658440954,
    0.72253172,
    0.771412063,
    0.771121408,
    0.804761799,
    0.833468423,
    0.85033651,
    0.897643543,
    0.913438256,
    0.979242176,
    1.049339869,
    1.139371683,
    1.249688703,
    1.309537465,
    1.31534199,
    1.310145478,
    1.292971256,
    1.113240462,
    0.924145448,
    0.820628397,
    0.777657244,
    0.73028872,
    0.70838222,
    0.689374162,
    0.694293053,
    0.752599491,
    0.80226083,
    0.822063,
    0.873856464,
    0.92027392,
    0.949637548,
    1.017547583,
    1.043666067,
    1.139744623,
    1.225076565,
    1.324472468,
    1.427234004,
    1.490849081,
    1.483421941,
    1.45019616,
    1.440430276,
    1.250571987,
    1.053583089,
    0.926036783,
    0.589685144,
    0.56969518,
    0.563231082,
    0.57389975,
    0.594625041,
    0.658434602,
    0.714523133,
    0.712949171,
    0.726454608,
    0.728989813,
    0.712666938,
    0.699488299,
    0.696815358,
    0.713962292,
    0.750989123,
    0.817452649,
    0.889531104,
    0.902296335,
    0.897922178,
    0.92656974,
    0.933932177,
    0.832735942,
    0.71279427,
    0.661070036,
    0.570825393,
    0.554050608,
    0.547146181,
    0.548114933,
    0.547174114,
    0.557914901,
    0.617856173,
    0.689735977,
    0.746118707,
    0.761143307,
    0.746432994,
    0.718165911,
    0.710779468,
    0.701903951,
    0.713275164,
    0.739418163,
    0.785298624,
    0.790991914,
    0.798922883,
    0.829125271,
    0.830422563,
    0.743769848,
    0.637329629,
    0.597130568,
    0.580950087,
    0.559354229,
    0.551569216,
    0.548039967,
    0.542435311,
    0.549002707,
    0.614094192,
    0.691764014,
    0.751691129,
    0.763982171,
    0.768464144,
    0.760749357,
    0.762404996,
    0.757901653,
    0.780038421,
    0.824418627,
    0.895652788,
    0.913497828,
    0.935268713,
    0.966822234,
    0.938712877,
    0.797105397,
    0.639026613,
    0.572183736,
    0.592481385,
    0.572472318,
    0.571920218,
    0.585390841,
    0.611860332,
    0.673723396,
    0.732083725,
    0.72106189,
    0.708017937,
    0.683963708,
    0.677138433,
    0.657035057,
    0.646387969,
    0.639344527,
    0.665394154,
    0.729276102,
    0.841522935,
    0.898434971,
    0.926035239,
    0.971670159,
    0.967467075,
    0.837566396,
    0.675217123,
    0.609889734,
    0.578633223,
    0.562105593,
    0.55708359,
    0.572671307,
    0.598767673,
    0.660758024,
    0.706206701,
    0.684278043,
    0.669718816,
    0.655575019,
    0.646152471,
    0.620374,
    0.616381043,
    0.619467666,
    0.641584199,
    0.714178221,
    0.825917312,
    0.871025123,
    0.89714471,
    0.941445456,
    0.942020432,
    0.823549195,
    0.66714646,
    0.604351426,
    0.564632205,
    0.547428434,
    0.542725755,
    0.55723955,
    0.583064346,
    0.645078685,
    0.691399408,
    0.664762211,
    0.651236676,
    0.63679193,
    0.621191288,
    0.600479992,
    0.592162337,
    0.592295832,
    0.618614617,
    0.691659644,
    0.804774382,
    0.848687863,
    0.866685226,
    0.912327044,
    0.915261488,
    0.795929054,
    0.644522277,
    0.587257395,
    0.569015987,
    0.552403965,
    0.546800892,
    0.559854374,
    0.586118469,
    0.644569859,
    0.689704924,
    0.670680232,
    0.655465331,
    0.641394064,
    0.624388573,
    0.59506174,
    0.587423856,
    0.590767641,
    0.61516752,
    0.68856292,
    0.793518512,
    0.833057163,
    0.84346074,
    0.884398758,
    0.902394451,
    0.793186123,
    0.650844967,
    0.591322723,
    0.574277987,
    0.555759798,
    0.54962706,
    0.562387861,
    0.584302394,
    0.642015514,
    0.699226898,
    0.6962541,
    0.702144423,
    0.696306675,
    0.675469928,
    0.652163774,
    0.644749394,
    0.650429981,
    0.677612796,
    0.744741987,
    0.820407984,
    0.833361448,
    0.830502014,
    0.863084948,
    0.875305154,
    0.793386395,
    0.677680449,
    0.629278957,
    0.609693733,
    0.586192323,
    0.57513758,
    0.569780187,
    0.564339686,
    0.567307092,
    0.629917315,
    0.710943824,
    0.776432137,
    0.797796681,
    0.790398402,
    0.77646392,
    0.772531816,
    0.775115839,
    0.790984207,
    0.825239563,
    0.875022074,
    0.887712255,
    0.892664385,
    0.906964921,
    0.906670255,
    0.821870083,
    0.705538401,
    0.650536013,
    0.592545871,
    0.569257059,
    0.558383358,
    0.553184845,
    0.539797882,
    0.527589695,
    0.588167012,
    0.692485988,
    0.775598811,
    0.791896224,
    0.818465041,
    0.804248738,
    0.815381533,
    0.809943773,
    0.824063118,
    0.87026914,
    0.928731494,
    0.943568562,
    0.960653686,
    0.992052285,
    0.959248204,
    0.823613056,
    0.66464286,
    0.589913892,
    0.562196783,
    0.545942734,
    0.538023696,
    0.553926093,
    0.550248302,
    0.548442368,
    0.569704717,
    0.66664524,
    0.768815514,
    0.76808949,
    0.8646745,
    0.800392916,
    0.839344551,
    0.823174374,
    0.817962245,
    0.875037319,
    0.908930927,
    0.931632053,
    0.930990156,
    1.002579473,
    0.970672478,
    0.833911019,
    0.68214799,
    0.606695556,
    0.582141887,
    0.563103035,
    0.556024795,
    0.569582705,
    0.59406884,
    0.650259703,
    0.695423822,
    0.675477981,
    0.663738682,
    0.649885552,
    0.642733811,
    0.619761716,
    0.615903297,
    0.619957185,
    0.639813208,
    0.717475122,
    0.83258957,
    0.880315502,
    0.903753633,
    0.940774609,
    0.943081114,
    0.834267165,
    0.675361425,
    0.607738945,
    0.576608504,
    0.556912654,
    0.550315295,
    0.562673431,
    0.586650748,
    0.642234524,
    0.690198129,
    0.667672551,
    0.655224254,
    0.640316909,
    0.626602409,
    0.608082785,
    0.599458087,
    0.600335403,
    0.624035839,
    0.701218731,
    0.817581753,
    0.865061263,
    0.882851193,
    0.921750327,
    0.926165208,
    0.816072881,
    0.66197165,
    0.599187654,
    0.580746083,
    0.56171868,
    0.554251399,
    0.565188163,
    0.589595471,
    0.641750497,
    0.688461419,
    0.673537552,
    0.659386917,
    0.644902048,
    0.629824969,
    0.602582067,
    0.594670599,
    0.598805523,
    0.620534803,
    0.697965868,
    0.806051409,
    0.849107199,
    0.859440478,
    0.893586308,
    0.91305723,
    0.812862886,
    0.667933241,
    0.603044471,
    0.576877707,
    0.557369235,
    0.550138209,
    0.562199403,
    0.583173622,
    0.635020596,
    0.69347792,
    0.692874387,
    0.697089693,
    0.688498277,
    0.667711751,
    0.642379315,
    0.633394972,
    0.636186372,
    0.658302442,
    0.728134728,
    0.806741545,
    0.822045363,
    0.820006514,
    0.848973501,
    0.863713242,
    0.793382954,
    0.677513408,
    0.626876516,
    0.655549191,
    0.624533678,
    0.608815965,
    0.596051949,
    0.585134803,
    0.580951596,
    0.646023763,
    0.73781958,
    0.814983534,
    0.845358216,
    0.847452096,
    0.851003444,
    0.852055429,
    0.870068165,
    0.892334586,
    0.93520369,
    0.988141948,
    1.008593807,
    1.01079767,
    1.005666944,
    1.002729574,
    0.915511277,
    0.787760623,
    0.716096744,
    0.71973558,
    0.675773597,
    0.654724844,
    0.629079791,
    0.607800601,
    0.597917904,
    0.669617796,
    0.777736215,
    0.875170329,
    0.91759172,
    0.950750997,
    0.999233806,
    1.017567178,
    1.062949366,
    1.110301518,
    1.178750008,
    1.257230573,
    1.296590261,
    1.308266481,
    1.284604704,
    1.24579163,
    1.085918633,
    0.89249609,
    0.776207558,
    0.683653904,
    0.649671414,
    0.632003313,
    0.62941831,
    0.637478315,
    0.678543376,
    0.724944397,
    0.7296629,
    0.750364137,
    0.762921015,
    0.770904461,
    0.770452032,
    0.769818114,
    0.796383556,
    0.852935005,
    0.93781777,
    1.065018961,
    1.127942065,
    1.128862787,
    1.137013155,
    1.130429287,
    0.992171554,
    0.801618239,
    0.712935116,
    0.650817509,
    0.624354171,
    0.605095525,
    0.606474487,
    0.614818623,
    0.655444635,
    0.691028769,
    0.684673899,
    0.696224463,
    0.711059643,
    0.709442464,
    0.695111329,
    0.693615119,
    0.724724205,
    0.773983868,
    0.864079474,
    0.990233565,
    1.043068942,
    1.048780291,
    1.059939315,
    1.061804852,
    0.939099043,
    0.760665965,
    0.678914677,
    0.678886757,
    0.648644844,
    0.627880807,
    0.626337606,
    0.633118927,
    0.67229361,
    0.713095724,
    0.708770174,
    0.721845022,
    0.740247303,
    0.736689945,
    0.731997838,
    0.730835537,
    0.762868239,
    0.817912441,
    0.908324097,
    1.035822031,
    1.088901995,
    1.089324791,
    1.100135129,
    1.10068019,
    0.972418052,
    0.793621997,
    0.711859565,
    0.668136783,
    0.640515655,
    0.620191923,
    0.618185721,
    0.625886698,
    0.661858192,
    0.701110221,
    0.703041954,
    0.71195494,
    0.727630081,
    0.719661921,
    0.702579864,
    0.698843264,
    0.731632521,
    0.783334155,
    0.872811762,
    0.991622725,
    1.040410993,
    1.034624458,
    1.042306013,
    1.060041887,
    0.943803639,
    0.77744291,
    0.696214797,
    0.650767892,
    0.624404301,
    0.605513717,
    0.605539868,
    0.610180182,
    0.64611759,
    0.697072341,
    0.712247314,
    0.737679209,
    0.756532944,
    0.740046169,
    0.721754998,
    0.713976092,
    0.743109471,
    0.794010677,
    0.87467535,
    0.963229629,
    0.983946278,
    0.966812298,
    0.970762198,
    0.98534718,
    0.901216518,
    0.767223843,
    0.702921494,
    0.668783014,
    0.640165917,
    0.617787053,
    0.602172763,
    0.580785716,
    0.561893532,
    0.617684478,
    0.714924126,
    0.795634125,
    0.837582917,
    0.831062311,
    0.814223104,
    0.807443049,
    0.826184516,
    0.860893642,
    0.905443533,
    0.967253811,
    0.985377283,
    0.977935835,
    0.969588486,
    0.973469826,
    0.891385689,
    0.761537672,
    0.696711703,
    0.685609984,
    0.651403661,
    0.627592628,
    0.607067337,
    0.580684672,
    0.557938585,
    0.618862703,
    0.722645323,
    0.808126443,
    0.849049082,
    0.863695089,
    0.869147416,
    0.873345506,
    0.897970505,
    0.944174361,
    1.00748384,
    1.094790298,
    1.124960936,
    1.131374007,
    1.123478786,
    1.096449277,
    0.956556294,
    0.772978847,
    0.680277932,
    0.702467249,
    0.665696984,
    0.645686672,
    0.640851657,
    0.646718863,
    0.683112841,
    0.730457577,
    0.739953154,
    0.763223182,
    0.778135005,
    0.790492347,
    0.793274477,
    0.795056244,
    0.824671408,
    0.879086655,
    0.967481132,
    1.09651096,
    1.16205114,
    1.16342885,
    1.163478013,
    1.156912718,
    1.023296213,
    0.829292525,
    0.735365768,
    0.672813813,
    0.64321125,
    0.62135129,
    0.620316205,
    0.626368287,
    0.662488081,
    0.698988438,
    0.697694926,
    0.712374098,
    0.730385705,
    0.733885153,
    0.72349242,
    0.725249347,
    0.760064343,
    0.80749993,
    0.901225907,
    1.029098938,
    1.084393225,
    1.090370019,
    1.093071771,
    1.09447504,
    0.975563269,
    0.793079464,
    0.70568082,
    0.718989961,
    0.683371499,
    0.658440614,
    0.653371363,
    0.657277226,
    0.691995863,
    0.733891542,
    0.736374948,
    0.755594508,
    0.781262388,
    0.786666898,
    0.79007792,
    0.796404159,
    0.835444672,
    0.890316513,
    0.985557373,
    1.114793408,
    1.16988114,
    1.169095178,
    1.169367219,
    1.167049057,
    1.03945845,
    0.852971263,
    0.76261778,
    0.739350625,
    0.70247237,
    0.675278027,
    0.667807903,
    0.671628448,
    0.703094547,
    0.743795994,
    0.755558809,
    0.775772901,
    0.805650117,
    0.813192059,
    0.811393736,
    0.822341929,
    0.867746032,
    0.922094976,
    1.018541657,
    1.139222139,
    1.189311359,
    1.179723614,
    1.173284043,
    1.184082346,
    1.063409914,
    0.883068547,
    0.788092629,
    0.724193569,
    0.688342096,
    0.662453657,
    0.656972545,
    0.657779959,
    0.689585096,
    0.741949803,
    0.766934259,
    0.804091926,
    0.837801033,
    0.837247644,
    0.834829938,
    0.842412458,
    0.884594463,
    0.938664614,
    1.026263072,
    1.116535728,
    1.13824012,
    1.116959699,
    1.107033721,
    1.114177982,
    1.024589521,
    0.876141646,
    0.797920684,
    0.778237768,
    0.735626245,
    0.703202981,
    0.679941525,
    0.653773603,
    0.631036877,
    0.688664594,
    0.798822644,
    0.897270245,
    0.962169741,
    0.978931314,
    0.986495944,
    1.003505272,
    1.041678759,
    1.08337509,
    1.137198859,
    1.200841023,
    1.21882295,
    1.203773248,
    1.17831748,
    1.169775173,
    1.075772572,
    0.924041993,
    0.839428896,
    0.815560406,
    0.764816369,
    0.729270134,
    0.699939414,
    0.668321709,
    0.642121252,
    0.705095375,
    0.82340064,
    0.93007256,
    0.998636656,
    1.040695054,
    1.075469484,
    1.108343319,
    1.156034728,
    1.211623794,
    1.285429889,
    1.374550015,
    1.403769698,
    1.400468878,
    1.373985189,
    1.331560303,
    1.175683579,
    0.965966918,
    0.850248631,
    0.778078644,
    0.731684715,
    0.704798776,
    0.694753027,
    0.697356611,
    0.73126055,
    0.779840311,
    0.798128703,
    0.833725986,
    0.864585532,
    0.892931693,
    0.912597284,
    0.930903016,
    0.97395464,
    1.033430614,
    1.128127905,
    1.258305599,
    1.32355638,
    1.319552088,
    1.308173252,
    1.292870799,
    1.150615341,
    0.941461936,
    0.83402482,
    0.81513631,
    0.767526731,
    0.733041591,
    0.722685267,
    0.723488583,
    0.757096053,
    0.795679414,
    0.809372618,
    0.847431516,
    0.89618714,
    0.929444621,
    0.951540925,
    0.985212884,
    1.045304549,
    1.104265449,
    1.208886231,
    1.338298582,
    1.391635684,
    1.386216979,
    1.370534518,
    1.354324068,
    1.215755233,
    1.00447007,
    0.892445071,
    0.827204069,
    0.77793629,
    0.743457071,
    0.731361318,
    0.731304953,
    0.764268924,
    0.807710514,
    0.821486831,
    0.858545879,
    0.907681688,
    0.935654847,
    0.963780539,
    0.994460423,
    1.052745866,
    1.116560795,
    1.219988511,
    1.350281998,
    1.403720804,
    1.394190561,
    1.380779344,
    1.364939827,
    1.222035732,
    1.013633103,
    0.904699311,
    0.710460044,
    0.677306268,
    0.652657418,
    0.64699906,
    0.651486291,
    0.682752099,
    0.723047452,
    0.732308098,
    0.747870454,
    0.771390476,
    0.772900434,
    0.764157752,
    0.768450113,
    0.808809473,
    0.860277267,
    0.954745357,
    1.075109349,
    1.125876613,
    1.119047408,
    1.115534626,
    1.13015287,
    1.01420405,
    0.839814065,
    0.749836381,
    0.866567894,
    0.812847162,
    0.774535396,
    0.760029935,
    0.755963988,
    0.786454562,
    0.840695978,
    0.879922315,
    0.940653143,
    1.005628187,
    1.034711737,
    1.065045222,
    1.105080276,
    1.172661822,
    1.239280221,
    1.337180545,
    1.428480988,
    1.447391574,
    1.414203425,
    1.38747168,
    1.376317338,
    1.264961556,
    1.087616151,
    0.985383962,
    1.173315538,
    1.080951468,
    1.013954254,
    0.96559272,
    0.926240347,
    0.900221271,
    0.963125824,
    1.112491191,
    1.276112394,
    1.427680772,
    1.526808652,
    1.625581927,
    1.732625227,
    1.841134782,
    1.917786302,
    2.00018217,
    2.066938668,
    2.077252534,
    2.02889366,
    1.956939059,
    1.897667638,
    1.743359334,
    1.511372921,
    1.359800918,
    0.924446113,
    0.860168123,
    0.815104324,
    0.778870827,
    0.743315264,
    0.716156526,
    0.780404652,
    0.909903278,
    1.034673859,
    1.127357193,
    1.192413383,
    1.251951846,
    1.30979396,
    1.377199625,
    1.441974595,
    1.523552326,
    1.613184511,
    1.640317893,
    1.628477047,
    1.588407919,
    1.53205448,
    1.359245265,
    1.12764733,
    0.993797967,
    0.849283907,
    0.794168863,
    0.761052395,
    0.746496211,
    0.746312678,
    0.779602713,
    0.82886832,
    0.85476619,
    0.902279636,
    0.949101574,
    0.992769103,
    1.02835396,
    1.063120219,
    1.119329585,
    1.184440714,
    1.284127199,
    1.414378187,
    1.478302315,
    1.469232777,
    1.448314697,
    1.423957969,
    1.270371142,
    1.047106613,
    0.92804299,
    0.967361184,
    0.900842294,
    0.853015732,
    0.832992196,
    0.828292605,
    0.860684727,
    0.900997143,
    0.930384191,
    0.993708686,
    1.076249772,
    1.14184177,
    1.198525365,
    1.267164302,
    1.354904067,
    1.426562863,
    1.54199686,
    1.672077704,
    1.722544397,
    1.705362823,
    1.670400795,
    1.634760776,
    1.472420744,
    1.230623215,
    1.093260375,
    0.923084213,
    0.862077662,
    0.819179298,
    0.800995775,
    0.797196792,
    0.829437604,
    0.873765023,
    0.897814127,
    0.950884725,
    1.021562236,
    1.070308011,
    1.119852422,
    1.172742453,
    1.248807529,
    1.320104481,
    1.430210737,
    1.560573643,
    1.612263287,
    1.596039405,
    1.569569386,
    1.541571718,
    1.383336879,
    1.155990725,
    1.031400596,
    0.939348564,
    0.877696736,
    0.832963952,
    0.812754721,
    0.809091369,
    0.838617569,
    0.881557671,
    0.914283932,
    0.967761649,
    1.042023699,
    1.092130319,
    1.135526447,
    1.192366051,
    1.274211475,
    1.344875674,
    1.455616677,
    1.577100034,
    1.623565039,
    1.598885031,
    1.566540476,
    1.551945634,
    1.400326733,
    1.180003633,
    1.051802544,
    0.998753029,
    0.928803893,
    0.878866177,
    0.855956133,
    0.846816527,
    0.876380118,
    0.931870211,
    0.985170715,
    1.067921435,
    1.162559089,
    1.220278626,
    1.28023074,
    1.350869471,
    1.442909326,
    1.51990433,
    1.627017959,
    1.718490016,
    1.735002017,
    1.692484581,
    1.647837904,
    1.619925962,
    1.487477842,
    1.28398404,
    1.160081199,
    1.058871651,
    0.981494839,
    0.924463227,
    0.883378512,
    0.846914941,
    0.821975062,
    0.88267341,
    1.021980848,
    1.167055982,
    1.294391089,
    1.371005885,
    1.442129917,
    1.523711047,
    1.613064605,
    1.677871835,
    1.751552121,
    1.816229204,
    1.828956813,
    1.792632587,
    1.731101158,
    1.686787973,
    1.548754989,
    1.340932529,
    1.209774466,
    0.888252152,
    0.829120215,
    0.787154564,
    0.753217498,
    0.71792974,
    0.691268193,
    0.754319451,
    0.881565086,
    1.000682225,
    1.086343769,
    1.145314317,
    1.195236219,
    1.245488238,
    1.307729729,
    1.367486336,
    1.445974692,
    1.534105131,
    1.562160551,
    1.555879431,
    1.516834542,
    1.465417879,
    1.296872623,
    1.073595605,
    0.946915353,
    0.742365731,
    0.701381216,
    0.677545682,
    0.669780148,
    0.672098094,
    0.706489214,
    0.753521674,
    0.770324173,
    0.800562849,
    0.824966121,
    0.847984699,
    0.857458711,
    0.868601103,
    0.907220071,
    0.960823044,
    1.052246159,
    1.180278264,
    1.246522861,
    1.249298598,
    1.237317124,
    1.22701377,
    1.088325046,
    0.887872112,
    0.788102414,
    0.803134999,
    0.758034876,
    0.724496994,
    0.714905137,
    0.714504777,
    0.748494346,
    0.78572273,
    0.800489123,
    0.837124885,
    0.884785543,
    0.917953537,
    0.93514326,
    0.967176896,
    1.027277098,
    1.08214015,
    1.185110258,
    1.312373533,
    1.366298374,
    1.366094355,
    1.346427799,
    1.332233672,
    1.193388593,
    0.986159945,
    0.877929671,
    0.730009763,
    0.692698546,
    0.668376243,
    0.666775103,
    0.664741378,
    0.679641943,
    0.713695802,
    0.720461108,
    0.740023629,
    0.755244007,
    0.757466916,
    0.767122313,
    0.769071458,
    0.805387345,
    0.874908611,
    0.977734014,
    1.110147521,
    1.168396675,
    1.162126803,
    1.148320155,
    1.133958445,
    1.015870908,
    0.838563032,
    0.753881954,
    0.804072509,
    0.756699386,
    0.724293426,
    0.715987205,
    0.705886742,
    0.715823923,
    0.750784751,
    0.790157931,
    0.829378976,
    0.868281932,
    0.883408145,
    0.898057448,
    0.924549211,
    0.972927015,
    1.041830873,
    1.143418086,
    1.262944734,
    1.301637907,
    1.287660222,
    1.250974092,
    1.241135069,
    1.127200677,
    0.958526965,
    0.865176737,
    0.79183144,
    0.736170595,
    0.699669568,
    0.688685507,
    0.648510284,
    0.650752771,
    0.703293351,
    0.835519688,
    0.9333106,
    1.008659041,
    1.013813096,
    1.017282756,
    1.07334108,
    1.102138243,
    1.148785958,
    1.215622309,
    1.284967418,
    1.238050339,
    1.23573072,
    1.150969073,
    1.133283788,
    1.077570718,
    0.998604001,
    0.941582674,
    0.861173314,
    0.802563364,
    0.761388648,
    0.734928853,
    0.684610694,
    0.639409165,
    0.693066949,
    0.86028749,
    0.994302004,
    1.084064538,
    1.111767033,
    1.135288253,
    1.18483351,
    1.223744511,
    1.273061054,
    1.324584834,
    1.380786403,
    1.365793803,
    1.354697633,
    1.279167091,
    1.246682579,
    1.165722985,
    1.043865797,
    0.965496429,
    0.95922445,
    0.888573758,
    0.840897145,
    0.806357496,
    0.758946316,
    0.71323082,
    0.77020487,
    0.921020548,
    1.05715211,
    1.151989094,
    1.218556913,
    1.28659501,
    1.353417987,
    1.419450831,
    1.495679437,
    1.580890594,
    1.669796645,
    1.686857099,
    1.673686906,
    1.606005526,
    1.53451669,
    1.372906623,
    1.160004678,
    1.033523633,
    0.985185331,
    0.913118241,
    0.869501296,
    0.850618889,
    0.840184035,
    0.853914592,
    0.896189337,
    0.938658635,
    1.006934337,
    1.071896757,
    1.139277348,
    1.209378948,
    1.269080034,
    1.345941959,
    1.436064397,
    1.551811496,
    1.685270641,
    1.749327138,
    1.725568858,
    1.674522421,
    1.62219269,
    1.457180361,
    1.218759148,
    1.085232569,
    0.946521776,
    0.882812431,
    0.838353389,
    0.823890795,
    0.813946768,
    0.828097759,
    0.859539128,
    0.890580227,
    0.949036492,
    1.015195937,
    1.072254523,
    1.127175682,
    1.184930488,
    1.265343666,
    1.347853594,
    1.467639524,
    1.599429272,
    1.653524337,
    1.63540808,
    1.588003409,
    1.544438627,
    1.393956923,
    1.168875008,
    1.043817829,
    0.888154618,
    0.831821905,
    0.793465214,
    0.781768017,
    0.773081997,
    0.78728229,
    0.822264485,
    0.846808854,
    0.892829466,
    0.944263902,
    0.982080107,
    1.026306046,
    1.065420058,
    1.131997552,
    1.212466575,
    1.325900844,
    1.457650426,
    1.513273603,
    1.497815343,
    1.459893659,
    1.425762819,
    1.281424603,
    1.0736182,
    0.96368003,
    0.906924319,
    0.849656882,
    0.809240068,
    0.795387806,
    0.786612737,
    0.798241406,
    0.831820814,
    0.86521558,
    0.912166124,
    0.967872358,
    1.007894007,
    1.046743384,
    1.090472919,
    1.163362033,
    1.2432175,
    1.35741007,
    1.480460895,
    1.531034533,
    1.507544295,
    1.463091538,
    1.441526421,
    1.302895878,
    1.101374526,
    0.987564497,
    0.906981182,
    0.849022197,
    0.808578591,
    0.795754368,
    0.783450339,
    0.795637089,
    0.840415515,
    0.888192341,
    0.953963504,
    1.016922069,
    1.05262865,
    1.094008895,
    1.138122775,
    1.210883516,
    1.291452399,
    1.397929021,
    1.491402329,
    1.514271502,
    1.478866381,
    1.428704682,
    1.401705351,
    1.290044066,
    1.116524908,
    1.016724437,
    0.764608813,
    0.724877298,
    0.69518501,
    0.67765948,
    0.645593173,
    0.607189432,
    0.65413279,
    0.764982962,
    0.85789752,
    0.908707075,
    0.921038835,
    0.926740172,
    0.935988661,
    0.970763344,
    1.021800659,
    1.081364641,
    1.147076375,
    1.170003424,
    1.157779745,
    1.114765678,
    1.097555836,
    1.010356016,
    0.873500543,
    0.801035306,
    0.792358911,
    0.74599275,
    0.71406511,
    0.691326368,
    0.654269769,
    0.613166382,
    0.664919266,
    0.782759367,
    0.882357788,
    0.935293192,
    0.970569286,
    1.000453129,
    1.023468751,
    1.066261325,
    1.129982082,
    1.20785848,
    1.297610243,
    1.331246654,
    1.331349494,
    1.28825089,
    1.239249851,
    1.09258402,
    0.901702303,
    0.801098546,
    1.011744704,
    0.937441154,
    0.891537081,
    0.871248413,
    0.858849411,
    0.874211523,
    0.915383034,
    0.961896556,
    1.035176429,
    1.108101204,
    1.183567798,
    1.258005758,
    1.325489138,
    1.409405506,
    1.499795873,
    1.615971369,
    1.747069974,
    1.810160249,
    1.78795224,
    1.729762323,
    1.673843309,
    1.500326795,
    1.258112888,
    1.122409374,
    1.099978514,
    1.018021177,
    0.96026274,
    0.936461942,
    0.920649921,
    0.935927194,
    0.967970748,
    1.015098478,
    1.099509237,
    1.201565016,
    1.292919331,
    1.381788879,
    1.47639007,
    1.586312239,
    1.681037039,
    1.810222376,
    1.940612175,
    1.990879642,
    1.963018571,
    1.894703317,
    1.830914754,
    1.651792095,
    1.396962621,
    1.248329963,
    1.062872742,
    0.985628708,
    0.932220388,
    0.909979094,
    0.894979521,
    0.91066724,
    0.946507553,
    0.988765903,
    1.064311734,
    1.1564589,
    1.232788125,
    1.316107551,
    1.397096752,
    1.496896984,
    1.5921265,
    1.716262637,
    1.8466518,
    1.897612434,
    1.870128813,
    1.80987219,
    1.752434154,
    1.57533535,
    1.333325302,
    1.196410158,
    0.862950694,
    0.812430971,
    0.776236364,
    0.765902455,
    0.75820244,
    0.773615614,
    0.805014308,
    0.83413099,
    0.874695956,
    0.923414702,
    0.956234799,
    0.983724236,
    1.01975329,
    1.08702091,
    1.162772737,
    1.27156294,
    1.391202995,
    1.440911985,
    1.423638939,
    1.383372742,
    1.366223878,
    1.227146572,
    1.035814932,
    0.932498147,
    0.920778888,
    0.862287571,
    0.821128263,
    0.808309755,
    0.795478617,
    0.811569451,
    0.854928207,
    0.90358439,
    0.972553903,
    1.041339826,
    1.081619362,
    1.125280254,
    1.175044033,
    1.252352541,
    1.334711322,
    1.439716024,
    1.530132379,
    1.550547847,
    1.515879594,
    1.464378421,
    1.433982453,
    1.312128269,
    1.136885561,
    1.037972353,
    0.994176226,
    0.926667181,
    0.877426467,
    0.846277031,
    0.80680093,
    0.770928569,
    0.819383242,
    0.952046165,
    1.083712728,
    1.187693924,
    1.249373228,
    1.307488325,
    1.371624854,
    1.449189761,
    1.521693275,
    1.595268847,
    1.65969222,
    1.675712783,
    1.64548079,
    1.576682379,
    1.528135791,
    1.397360229,
    1.214777685,
    1.106606249,
    1.00619721,
    0.934064315,
    0.884001438,
    0.84869009,
    0.804802105,
    0.766557089,
    0.819572059,
    0.957548306,
    1.093341336,
    1.19608964,
    1.277414701,
    1.356112074,
    1.430523145,
    1.513332691,
    1.597290869,
    1.687938786,
    1.776181621,
    1.803074444,
    1.78642393,
    1.719645608,
    1.641212752,
    1.453009348,
    1.219586278,
    1.086058344,
    1.019583386,
    0.945568029,
    0.899609293,
    0.879899731,
    0.86744718,
    0.887507087,
    0.927078967,
    0.973346251,
    1.048953689,
    1.126684355,
    1.205320565,
    1.280872976,
    1.352953682,
    1.440372684,
    1.532762226,
    1.646676105,
    1.774392137,
    1.834594749,
    1.813187849,
    1.755528771,
    1.696527523,
    1.512438276,
    1.269611571,
    1.13616719,
    1.125538623,
    1.04165088,
    0.982320314,
    0.958021435,
    0.94164528,
    0.961674773,
    0.992331987,
    1.040824728,
    1.130508663,
    1.241328361,
    1.339507458,
    1.433646623,
    1.536961358,
    1.653540144,
    1.752027282,
    1.880140083,
    2.00722325,
    2.054119391,
    2.025442925,
    1.955880844,
    1.886619322,
    1.693889951,
    1.434819361,
    1.285525943,
    1.195819807,
    1.103094353,
    1.038741552,
    1.009221806,
    0.990226537,
    1.010003541,
    1.04590411,
    1.099953545,
    1.198471972,
    1.322964429,
    1.42842305,
    1.541950624,
    1.656174366,
    1.781682718,
    1.890454342,
    2.021209971,
    2.149151429,
    2.194518659,
    2.156918149,
    2.083221907,
    2.006401912,
    1.799039805,
    1.530900791,
    1.375131986,
    1.215445135,
    1.121717813,
    1.055380924,
    1.023854342,
    1.005073139,
    1.023016067,
    1.057463939,
    1.119824185,
    1.219516507,
    1.348708879,
    1.456291728,
    1.564924905,
    1.684233855,
    1.816147487,
    1.925248685,
    2.056484299,
    2.175514311,
    2.215233013,
    2.168929398,
    2.090002119,
    2.025154203,
    1.822115327,
    1.559926829,
    1.400459503,
    1.023705379,
    0.953563128,
    0.904157664,
    0.886066708,
    0.870352774,
    0.891146886,
    0.934100361,
    0.991334193,
    1.078392232,
    1.173099075,
    1.236191226,
    1.303202867,
    1.379524325,
    1.477230829,
    1.570807237,
    1.680073468,
    1.767637741,
    1.782769829,
    1.740348772,
    1.679463563,
    1.633319276,
    1.48511605,
    1.28981192,
    1.177340958,
    1.011749984,
    0.943393175,
    0.893416973,
    0.862470804,
    0.822960301,
    0.792653417,
    0.839505773,
    0.972224543,
    1.107961749,
    1.219251753,
    1.285950485,
    1.347685288,
    1.419028725,
    1.501860517,
    1.578130801,
    1.649692026,
    1.710452967,
    1.722712373,
    1.691933449,
    1.623951549,
    1.570511989,
    1.426046214,
    1.240758005,
    1.133652474,
    1.153558629,
    1.064192063,
    1.002057872,
    0.958742202,
    0.910659014,
    0.877124687,
    0.930297327,
    1.080958273,
    1.242204747,
    1.380736716,
    1.494038114,
    1.606489233,
    1.717717903,
    1.828810288,
    1.92831784,
    2.026275732,
    2.111920604,
    2.132401781,
    2.103975234,
    2.023221154,
    1.923113042,
    1.701184129,
    1.438601803,
    1.28412154,
    0.940915485,
    0.878245512,
    0.840048637,
    0.826755933,
    0.817539819,
    0.844286139,
    0.880888733,
    0.917489811,
    0.981383143,
    1.045489768,
    1.109043428,
    1.166014616,
    1.223541501,
    1.299163736,
    1.387074686,
    1.491827461,
    1.614916166,
    1.672962305,
    1.659034856,
    1.613943424,
    1.562197334,
    1.378437217,
    1.152450586,
    1.036630165,
    0.765689527,
    0.728695031,
    0.701790404,
    0.701836434,
    0.697925114,
    0.727052488,
    0.750857089,
    0.761911302,
    0.793637669,
    0.829351822,
    0.853915742,
    0.864356478,
    0.889191919,
    0.944113053,
    1.013162512,
    1.111646582,
    1.231595656,
    1.2816624,
    1.284444759,
    1.260400523,
    1.234411207,
    1.084455279,
    0.899453609,
    0.815750468,
    0.804753514,
    0.762879365,
    0.733719507,
    0.73057463,
    0.725128656,
    0.754420549,
    0.783020525,
    0.79643358,
    0.831881889,
    0.874496433,
    0.899801369,
    0.922449427,
    0.951155362,
    1.009467393,
    1.086188483,
    1.184972742,
    1.305451101,
    1.354467789,
    1.350887336,
    1.326620005,
    1.296978051,
    1.136846594,
    0.949105158,
    0.864320507,
    0.887988664,
    0.837086167,
    0.800402035,
    0.791248674,
    0.784011566,
    0.811127532,
    0.839130842,
    0.866979995,
    0.914092769,
    0.975388084,
    1.016002611,
    1.048554247,
    1.096886396,
    1.17287829,
    1.255792859,
    1.359591694,
    1.471657271,
    1.51366625,
    1.495818023,
    1.459213759,
    1.433268833,
    1.267511134,
    1.072738794,
    0.973497546,
    0.92148003,
    0.865710566,
    0.826259331,
    0.8162603,
    0.804819391,
    0.833153816,
    0.872740364,
    0.917478142,
    0.98904133,
    1.065204974,
    1.108132151,
    1.151320503,
    1.207987345,
    1.289704043,
    1.377405773,
    1.475489764,
    1.55799715,
    1.570926337,
    1.537547361,
    1.493102479,
    1.456824302,
    1.311393554,
    1.137613038,
    1.047082816,
    1.165400538,
    1.085117928,
    1.021666879,
    0.98349137,
    0.94000625,
    0.904328263,
    0.940347362,
    1.094166232,
    1.248844239,
    1.397064724,
    1.495614297,
    1.58275799,
    1.686458766,
    1.787944348,
    1.848811151,
    1.925228817,
    1.990789388,
    1.994659748,
    1.943630209,
    1.862863007,
    1.80412991,
    1.632829999,
    1.429343292,
    1.309634628,
    0.983162101,
    0.922369108,
    0.874943981,
    0.844525792,
    0.802899844,
    0.765642717,
    0.804689751,
    0.945211623,
    1.071924878,
    1.175705553,
    1.25367371,
    1.317737682,
    1.387869493,
    1.459828965,
    1.516037698,
    1.596488343,
    1.685760005,
    1.704889401,
    1.684755761,
    1.630329346,
    1.56470426,
    1.363043042,
    1.145047225,
    1.031240818,
    0.926463198,
    0.872374275,
    0.835313919,
    0.825666179,
    0.819780359,
    0.844136064,
    0.868876993,
    0.905662415,
    0.956789531,
    1.018121315,
    1.077686203,
    1.122332835,
    1.173797735,
    1.237557885,
    1.297003903,
    1.397042977,
    1.527673227,
    1.583396698,
    1.56412425,
    1.529136088,
    1.492854447,
    1.307099069,
    1.09315923,
    0.991026472,
    1.033619049,
    0.969447291,
    0.918486307,
    0.904052244,
    0.894350646,
    0.918066497,
    0.934204865,
    0.973115959,
    1.038066341,
    1.132843908,
    1.211232044,
    1.274733186,
    1.358038163,
    1.451255628,
    1.517362636,
    1.632182941,
    1.762322324,
    1.804048933,
    1.777329077,
    1.731882923,
    1.685066211,
    1.491724379,
    1.261177199,
    1.142627195,
    1.098222449,
    1.025667508,
    0.970058688,
    0.950473035,
    0.938491887,
    0.961359268,
    0.983813903,
    1.027906323,
    1.100339795,
    1.20696159,
    1.290192831,
    1.372282975,
    1.464825211,
    1.565239332,
    1.642005424,
    1.759038,
    1.890228584,
    1.930516939,
    1.895010302,
    1.847885381,
    1.794012852,
    1.587496442,
    1.348909959,
    1.224670888,
    0.992706502,
    0.934781531,
    0.887944903,
    0.874015948,
    0.866548326,
    0.887810537,
    0.907902667,
    0.948638642,
    1.001273397,
    1.084734402,
    1.143110546,
    1.191092801,
    1.260185465,
    1.344457972,
    1.410932099,
    1.519445607,
    1.640343678,
    1.67710178,
    1.643025264,
    1.606598771,
    1.581305772,
    1.399400817,
    1.19238987,
    1.08523713,
    0.894238245,
    0.847802812,
    0.809542338,
    0.802934775,
    0.79548348,
    0.81824889,
    0.84968831,
    0.89553244,
    0.951712762,
    1.021036651,
    1.053217428,
    1.081698946,
    1.128974647,
    1.195036347,
    1.255064531,
    1.348037414,
    1.436795042,
    1.445517346,
    1.406422409,
    1.378519407,
    1.359298032,
    1.22040317,
    1.062461351,
    0.98673755,
    0.860775891,
    0.818586182,
    0.781350809,
    0.76220997,
    0.730432528,
    0.698609332,
    0.734807697,
    0.858738413,
    0.962727036,
    1.044532833,
    1.075356298,
    1.09377123,
    1.130943965,
    1.177043616,
    1.217546363,
    1.269259486,
    1.32972537,
    1.335818326,
    1.310640476,
    1.279208311,
    1.254640122,
    1.123744434,
    0.980199381,
    0.913175776,
    0.973070036,
    0.913145586,
    0.866156813,
    0.836069729,
    0.79662208,
    0.760981848,
    0.804273273,
    0.943755177,
    1.068253956,
    1.170109505,
    1.241540668,
    1.305206119,
    1.375883902,
    1.444652654,
    1.507007221,
    1.584054719,
    1.67076901,
    1.686303355,
    1.66666401,
    1.627661296,
    1.558268274,
    1.352055721,
    1.133981578,
    1.022736592,
    0.760832441,
    0.727265096,
    0.704287871,
    0.704891629,
    0.706317017,
    0.733635444,
    0.760500958,
    0.780848697,
    0.804177255,
    0.829562965,
    0.850161631,
    0.85842343,
    0.875075851,
    0.908034087,
    0.959896001,
    1.045203406,
    1.171897948,
    1.226943213,
    1.221370097,
    1.220173232,
    1.200013186,
    1.033163436,
    0.850772812,
    0.777726463,
    0.817294242,
    0.780047675,
    0.747632365,
    0.746702223,
    0.746027907,
    0.773216141,
    0.790797998,
    0.808181445,
    0.837001581,
    0.88476003,
    0.913563546,
    0.928992947,
    0.965987262,
    1.019383422,
    1.073546235,
    1.169909008,
    1.295647696,
    1.337580427,
    1.328782574,
    1.323308955,
    1.299036309,
    1.132123966,
    0.943405328,
    0.86261084,
    0.798847135,
    0.763710183,
    0.733944034,
    0.733172502,
    0.732987819,
    0.760093745,
    0.782864674,
    0.797159214,
    0.819806669,
    0.861253991,
    0.877553568,
    0.892384551,
    0.919752775,
    0.965589386,
    1.023155117,
    1.115659595,
    1.241703862,
    1.283712322,
    1.273123495,
    1.275904718,
    1.255157758,
    1.087515904,
    0.90762691,
    0.83532246,
    0.801630233,
    0.767434661,
    0.736975598,
    0.735001306,
    0.735864091,
    0.760605631,
    0.782514171,
    0.803994338,
    0.824674016,
    0.866686999,
    0.880589322,
    0.886474054,
    0.915077213,
    0.963946955,
    1.021067833,
    1.112857136,
    1.229529356,
    1.265810676,
    1.247254037,
    1.248431423,
    1.242265256,
    1.082598532,
    0.912191108,
    0.838564001,
    0.851223792,
    0.809804361,
    0.774973113,
    0.77093175,
    0.767048197,
    0.792215589,
    0.827516397,
    0.868556824,
    0.917156085,
    0.977461293,
    0.995899109,
    1.016659635,
    1.057178738,
    1.114063866,
    1.178137189,
    1.265073863,
    1.350873025,
    1.356152081,
    1.319773404,
    1.312546085,
    1.293346764,
    1.154013301,
    1.002485274,
    0.935105843,
    0.860300721,
    0.817749052,
    0.780238189,
    0.760985977,
    0.731450337,
    0.701790704,
    0.742444428,
    0.8656508,
    0.969068724,
    1.051188404,
    1.077051694,
    1.097663427,
    1.137795558,
    1.182234164,
    1.230743072,
    1.279458599,
    1.337315979,
    1.339048671,
    1.312825921,
    1.297393212,
    1.267300903,
    1.129332369,
    0.983499587,
    0.917609474,
    0.933111065,
    0.877812407,
    0.834028472,
    0.806368756,
    0.770508047,
    0.737444617,
    0.784657288,
    0.919431819,
    1.036873414,
    1.130422102,
    1.188615965,
    1.245383838,
    1.31006833,
    1.370147848,
    1.43713303,
    1.508275925,
    1.59201873,
    1.603869323,
    1.586453109,
    1.568295583,
    1.498371918,
    1.290923104,
    1.07856282,
    0.97520874,
    0.977830926,
    0.91641487,
    0.874154575,
    0.860870905,
    0.857569101,
    0.885514753,
    0.919838825,
    0.960640542,
    1.019204861,
    1.092546854,
    1.1532963,
    1.214272756,
    1.283444827,
    1.353251573,
    1.432866827,
    1.530856463,
    1.656797065,
    1.7030853,
    1.677606013,
    1.66765535,
    1.613876895,
    1.406572813,
    1.177535271,
    1.06865147,
    1.044468867,
    0.978085898,
    0.925517292,
    0.910079541,
    0.904402704,
    0.932232312,
    0.957418642,
    0.996158003,
    1.06189897,
    1.159861669,
    1.230872435,
    1.301429541,
    1.39329065,
    1.485314277,
    1.568287006,
    1.678027912,
    1.803083436,
    1.835976286,
    1.806288864,
    1.791124479,
    1.731851621,
    1.522780705,
    1.285306679,
    1.166973624,
    1.137316318,
    1.058976685,
    0.999328856,
    0.97699817,
    0.968239885,
    0.99519403,
    1.027103551,
    1.073598498,
    1.151490852,
    1.267522516,
    1.34912151,
    1.444928722,
    1.55252323,
    1.656697382,
    1.753165811,
    1.867076852,
    1.993389456,
    2.024115334,
    1.982991673,
    1.963391242,
    1.893273923,
    1.666403336,
    1.41506109,
    1.28629541,
    0.945028146,
    0.892280331,
    0.849048377,
    0.837947404,
    0.836645498,
    0.862880223,
    0.891251653,
    0.925659837,
    0.969495921,
    1.043416426,
    1.081984414,
    1.123684413,
    1.188146806,
    1.260739807,
    1.33946303,
    1.438476391,
    1.553703117,
    1.582405771,
    1.549920392,
    1.551609183,
    1.521063224,
    1.331668292,
    1.129495072,
    1.032658748,
    0.859453505,
    0.816562201,
    0.780819058,
    0.776273277,
    0.774680919,
    0.802507254,
    0.84241739,
    0.883004387,
    0.93252852,
    0.99517541,
    1.010107533,
    1.035405067,
    1.081048579,
    1.137665465,
    1.211392218,
    1.295714774,
    1.378876618,
    1.379120848,
    1.340289244,
    1.349500438,
    1.323218432,
    1.174556155,
    1.018752947,
    0.951213855,
    1.033687111,
    0.968790861,
    0.915920468,
    0.885685364,
    0.853110841,
    0.824881716,
    0.87241639,
    1.011314686,
    1.142844289,
    1.263473626,
    1.320132871,
    1.38360995,
    1.466481246,
    1.539914714,
    1.612973358,
    1.671012003,
    1.727654442,
    1.721071185,
    1.678141929,
    1.66017879,
    1.601720713,
    1.429178215,
    1.245407724,
    1.151260191,
    0.736740288,
    0.705823503,
    0.679090132,
    0.663988283,
    0.637184576,
    0.608581424,
    0.657597237,
    0.771624079,
    0.856343943,
    0.90748934,
    0.919514127,
    0.933494256,
    0.956791183,
    0.98027797,
    1.038883627,
    1.092505729,
    1.171801676,
    1.182436446,
    1.179893788,
    1.202371443,
    1.151520935,
    0.965562616,
    0.790525914,
    0.721913928,
    0.70322919,
    0.67608029,
    0.657742713,
    0.662016436,
    0.67036816,
    0.70347959,
    0.738533453,
    0.750809901,
    0.763789548,
    0.77762325,
    0.775881346,
    0.775984198,
    0.785989113,
    0.805309957,
    0.86967496,
    0.944437381,
    1.065236793,
    1.111755147,
    1.107754195,
    1.147730386,
    1.123014161,
    0.948958009,
    0.77314384,
    0.712352736,
    0.741894467,
    0.713312691,
    0.687135903,
    0.691058104,
    0.69799241,
    0.731290595,
    0.756823302,
    0.764203027,
    0.779766192,
    0.812115486,
    0.814768751,
    0.818013576,
    0.844365185,
    0.880922312,
    0.946263101,
    1.030715422,
    1.150373411,
    1.183969501,
    1.178061759,
    1.216270313,
    1.189594325,
    1.017893896,
    0.839310142,
    0.773860377,
    0.746822658,
    0.717394006,
    0.69185789,
    0.694501589,
    0.701263585,
    0.734464091,
    0.765512564,
    0.771922639,
    0.785179634,
    0.816371303,
    0.811278823,
    0.819439423,
    0.84153794,
    0.874635939,
    0.945727368,
    1.027943422,
    1.148081076,
    1.181148827,
    1.171245181,
    1.2154208,
    1.189138451,
    1.012876945,
    0.838299647,
    0.777416166,
    0.733098224,
    0.706743315,
    0.681901557,
    0.684355308,
    0.692670726,
    0.723508188,
    0.753416488,
    0.765678248,
    0.774245721,
    0.802358108,
    0.791639186,
    0.786882528,
    0.806437708,
    0.8397916,
    0.908667021,
    0.988909538,
    1.099528273,
    1.127272276,
    1.111330326,
    1.155300175,
    1.145721979,
    0.980093883,
    0.818433899,
    0.758977479,
    0.750411278,
    0.721127418,
    0.69434308,
    0.696446719,
    0.700584691,
    0.730937523,
    0.77354988,
    0.804106313,
    0.835187904,
    0.874257136,
    0.862630262,
    0.864288751,
    0.888137728,
    0.924606682,
    0.995345111,
    1.068366,
    1.147915206,
    1.146169056,
    1.118173255,
    1.153702411,
    1.135688684,
    0.996919704,
    0.861261363,
    0.813078599,
    0.792044722,
    0.75773137,
    0.725013647,
    0.709496186,
    0.686431912,
    0.66051756,
    0.708682796,
    0.826531594,
    0.9176942,
    0.985430905,
    0.989285186,
    0.997352155,
    1.027944195,
    1.058570197,
    1.114427574,
    1.151561827,
    1.203538942,
    1.1985117,
    1.18091046,
    1.200625496,
    1.167994857,
    1.027282043,
    0.891244581,
    0.838530132,
    0.852843684,
    0.80751976,
    0.769175691,
    0.745671313,
    0.716177287,
    0.685760956,
    0.740070465,
    0.870249869,
    0.973363725,
    1.049624638,
    1.084606323,
    1.125047518,
    1.177174918,
    1.222149253,
    1.293158897,
    1.351826436,
    1.429672685,
    1.435904303,
    1.431191679,
    1.445711383,
    1.375258853,
    1.168551181,
    0.968992821,
    0.880378649,
    0.884764719,
    0.835160705,
    0.799052435,
    0.790391203,
    0.793370644,
    0.822858761,
    0.863852032,
    0.900760688,
    0.942794139,
    0.995768355,
    1.031950028,
    1.072626233,
    1.126041253,
    1.179317022,
    1.259540044,
    1.344105804,
    1.464128484,
    1.505964649,
    1.497362628,
    1.517672122,
    1.465483035,
    1.262544735,
    1.049481633,
    0.957031356,
    0.822683725,
    0.778428833,
    0.756852202,
    0.759922113,
    0.768831899,
    0.837817749,
    0.870051101,
    0.834356919,
    0.864539385,
    0.914467672,
    0.929475892,
    0.972009039,
    1.015557205,
    1.070102931,
    1.135376549,
    1.245347179,
    1.365352619,
    1.410283038,
    1.396474454,
    1.42397906,
    1.338437258,
    1.157315563,
    0.959294058,
    0.876608196,
    0.807697979,
    0.765336129,
    0.745691313,
    0.748412838,
    0.757256706,
    0.825035902,
    0.862099316,
    0.825534052,
    0.850121633,
    0.894308856,
    0.899025382,
    0.940709567,
    0.975326538,
    1.02386148,
    1.090588811,
    1.196973638,
    1.317374798,
    1.363152382,
    1.350280465,
    1.381828465,
    1.299736743,
    1.118981919,
    0.929457927,
    0.85406175,
    0.94736787,
    0.888825015,
    0.856123052,
    0.848645783,
    0.853589844,
    0.916867698,
    0.954731191,
    0.940456993,
    0.985587161,
    1.060043828,
    1.092169797,
    1.15597178,
    1.222699054,
    1.299275756,
    1.375686793,
    1.491258019,
    1.603837312,
    1.642244203,
    1.612854543,
    1.623269497,
    1.538136298,
    1.345835075,
    1.138147736,
    1.037687176,
    0.864689443,
    0.815883771,
    0.790007402,
    0.788517901,
    0.792658872,
    0.856260967,
    0.905069829,
    0.898783249,
    0.949384666,
    1.012696622,
    1.023272025,
    1.070230041,
    1.118486086,
    1.180465062,
    1.249774758,
    1.351110622,
    1.432685978,
    1.444414077,
    1.411912198,
    1.425663924,
    1.345112456,
    1.193969538,
    1.031760828,
    0.959172673,
    0.788190417,
    0.749284238,
    0.727887935,
    0.716741897,
    0.698314376,
    0.708274779,
    0.760854427,
    0.827764821,
    0.91740935,
    0.982943888,
    0.98424226,
    1.010315367,
    1.038513465,
    1.074118108,
    1.118530523,
    1.176567234,
    1.230379548,
    1.241187602,
    1.228835229,
    1.240796293,
    1.161068552,
    1.025794448,
    0.887022022,
    0.829941399,
    0.746246675,
    0.707822922,
    0.691119209,
    0.670591237,
    0.640438965,
    0.629480823,
    0.685368134,
    0.776271518,
    0.877092451,
    0.936091704,
    0.955473151,
    0.991703983,
    1.00660628,
    1.043601892,
    1.088252726,
    1.151335336,
    1.229800832,
    1.246689714,
    1.25695653,
    1.272624637,
    1.164310759,
    0.976307045,
    0.79493168,
    0.725689484,
    0.740636737,
    0.696298412,
    0.690216276,
    0.654194814,
    0.621604396,
    0.599611805,
    0.637046162,
    0.721644622,
    0.821958921,
    0.879506457,
    0.92792046,
    0.964520976,
    0.927476171,
    0.98746008,
    1.016075393,
    1.067585303,
    1.18841168,
    1.213369843,
    1.228731488,
    1.242442741,
    1.137117558,
    0.942125653,
    0.748919794,
    0.704080132,
    0.630032931,
    0.611231683,
    0.603532562,
    0.616498218,
    0.630741654,
    0.695637258,
    0.72783174,
    0.685468366,
    0.682874395,
    0.689249537,
    0.668136614,
    0.66163505,
    0.662528724,
    0.687070605,
    0.728149571,
    0.820850313,
    0.937161163,
    0.98738516,
    1.009624238,
    1.049530661,
    0.98610715,
    0.837917235,
    0.680497704,
    0.628487132,
    0.648513868,
    0.627381761,
    0.618679586,
    0.629168789,
    0.642261325,
    0.705684148,
    0.743162053,
    0.703235274,
    0.700554765,
    0.708513633,
    0.684085871,
    0.684483553,
    0.684064671,
    0.708544112,
    0.754056687,
    0.845584163,
    0.962579209,
    1.01299725,
    1.033348145,
    1.073386079,
    1.009102094,
    0.856184523,
    0.700451044,
    0.650028603,
    0.728900912,
    0.699084573,
    0.682515248,
    0.686576107,
    0.69770505,
    0.757098668,
    0.794556613,
    0.771109711,
    0.779217974,
    0.804470235,
    0.795134622,
    0.803901702,
    0.822102903,
    0.864125017,
    0.91400993,
    1.010412858,
    1.119067491,
    1.163261825,
    1.172214378,
    1.197983683,
    1.138263944,
    0.982804146,
    0.820981949,
    0.755589209,
    0.893744636,
    0.842389724,
    0.810960108,
    0.805266574,
    0.807532399,
    0.865278257,
    0.917077327,
    0.925968051,
    0.980278271,
    1.048562302,
    1.069176821,
    1.118461917,
    1.174527004,
    1.245854261,
    1.310755536,
    1.410857207,
    1.490659357,
    1.503369431,
    1.488039832,
    1.488369885,
    1.401423547,
    1.249493731,
    1.082750882,
    1.003074474,
    0.651412485,
    0.630918136,
    0.618492392,
    0.613688719,
    0.598792478,
    0.604216374,
    0.657504093,
    0.723304475,
    0.789370654,
    0.823579597,
    0.800433807,
    0.79039073,
    0.788657816,
    0.804216086,
    0.829384148,
    0.874152689,
    0.924746213,
    0.939751651,
    0.958815394,
    0.976600123,
    0.911773323,
    0.800913999,
    0.691378168,
    0.655449315,
    0.656126344,
    0.631819578,
    0.61868297,
    0.609583543,
    0.5902286,
    0.591717963,
    0.650208093,
    0.722669503,
    0.791402001,
    0.822002485,
    0.817791463,
    0.826556571,
    0.83336704,
    0.853248372,
    0.88823843,
    0.949932049,
    1.025138766,
    1.052110264,
    1.088407137,
    1.107408787,
    1.01284672,
    0.846210872,
    0.686119466,
    0.624606685,
    0.667453881,
    0.641486422,
    0.63223175,
    0.638777395,
    0.651567449,
    0.711314386,
    0.756609159,
    0.736709529,
    0.742970521,
    0.746686633,
    0.739583007,
    0.744009642,
    0.747604481,
    0.772081249,
    0.814229552,
    0.899594194,
    1.015532326,
    1.077412329,
    1.111697639,
    1.138551635,
    1.064206091,
    0.9041986,
    0.735012546,
    0.673118456,
    0.685504421,
    0.660829473,
    0.645250773,
    0.652329216,
    0.663847602,
    0.722668568,
    0.758206111,
    0.73367666,
    0.739139454,
    0.756265585,
    0.750728018,
    0.752649931,
    0.767371181,
    0.805983801,
    0.844977367,
    0.938322475,
    1.053080625,
    1.103768258,
    1.141044224,
    1.164389564,
    1.091148211,
    0.938145006,
    0.770821854,
    0.707215848,
    0.739651112,
    0.708140471,
    0.688431311,
    0.690763463,
    0.699970069,
    0.757030567,
    0.798341361,
    0.779768623,
    0.791013276,
    0.817528486,
    0.816102553,
    0.833184588,
    0.854711863,
    0.899593498,
    0.94620067,
    1.040951776,
    1.156702316,
    1.206891928,
    1.239250506,
    1.258569676,
    1.179880686,
    1.016728898,
    0.843831247,
    0.775737133,
    0.61616642,
    0.601737529,
    0.592017107,
    0.600884997,
    0.614770576,
    0.669421338,
    0.707912166,
    0.685790272,
    0.674275908,
    0.673460428,
    0.644725346,
    0.622919068,
    0.616668865,
    0.642948669,
    0.675693807,
    0.760533692,
    0.866167549,
    0.914089736,
    0.952542196,
    0.981263669,
    0.933325717,
    0.798621081,
    0.661150241,
    0.612803747,
    0.610308523,
    0.595914774,
    0.586284217,
    0.596251884,
    0.606835574,
    0.661193129,
    0.711681775,
    0.705129099,
    0.711726747,
    0.716578991,
    0.682291431,
    0.661433844,
    0.65419906,
    0.679520312,
    0.71201325,
    0.788171138,
    0.863464945,
    0.88321615,
    0.91203468,
    0.935135673,
    0.881897424,
    0.776671644,
    0.669041188,
    0.635479863,
    0.630958055,
    0.614191963,
    0.600524988,
    0.594654687,
    0.579507537,
    0.579442752,
    0.63503579,
    0.711259262,
    0.77288521,
    0.80122156,
    0.777881316,
    0.758643816,
    0.753464029,
    0.769652781,
    0.785915809,
    0.825803808,
    0.874175494,
    0.891121164,
    0.931821489,
    0.941815682,
    0.876909294,
    0.772785448,
    0.668813346,
    0.634210274,
    0.634347965,
    0.613937407,
    0.599672222,
    0.589587449,
    0.570023188,
    0.56602786,
    0.626808994,
    0.709575981,
    0.773648786,
    0.79808524,
    0.793407557,
    0.792667235,
    0.795730743,
    0.816011186,
    0.841970816,
    0.89868545,
    0.971656813,
    1.000596597,
    1.058662171,
    1.070017712,
    0.975544273,
    0.815850401,
    0.661593603,
    0.601629555,
    0.613252016,
    0.595325806,
    0.587917931,
    0.595782889,
    0.609717024,
    0.664574892,
    0.71134418,
    0.697936943,
    0.694290988,
    0.685218977,
    0.670970924,
    0.658605143,
    0.650910278,
    0.670008945,
    0.700545047,
    0.778613006,
    0.892343353,
    0.956495237,
    1.014535217,
    1.037453855,
    0.967184495,
    0.81856704,
    0.661933269,
    0.607336024,
    0.613463229,
    0.599713275,
    0.588346535,
    0.598731036,
    0.612813141,
    0.667484883,
    0.704134171,
    0.683234325,
    0.674580263,
    0.675003469,
    0.65771312,
    0.638123534,
    0.635870426,
    0.664990127,
    0.691132011,
    0.775279362,
    0.8884786,
    0.941452114,
    1.003304437,
    1.025419776,
    0.958483058,
    0.818805199,
    0.668686698,
    0.616343266,
    0.610557282,
    0.596809203,
    0.58625427,
    0.595999516,
    0.610412915,
    0.665040413,
    0.704763062,
    0.681870687,
    0.671369816,
    0.671283756,
    0.647487636,
    0.631674424,
    0.623973016,
    0.650033764,
    0.680082639,
    0.762295276,
    0.876879306,
    0.929013378,
    0.987167894,
    1.010979261,
    0.945025583,
    0.802014177,
    0.657542633,
    0.610740246,
    0.612532508,
    0.600139558,
    0.5889577,
    0.597926559,
    0.613744401,
    0.665894475,
    0.703502193,
    0.687664007,
    0.675291296,
    0.676628236,
    0.651795394,
    0.626802581,
    0.619428287,
    0.64905077,
    0.677331726,
    0.758825115,
    0.8652335,
    0.911773664,
    0.963075165,
    0.981579017,
    0.929777323,
    0.794824766,
    0.660545693,
    0.612746656,
    0.606492851,
    0.594286091,
    0.583585355,
    0.594333674,
    0.60766968,
    0.660460926,
    0.7090441,
    0.707022497,
    0.712974882,
    0.721235962,
    0.690987411,
    0.667533109,
    0.658683086,
    0.687230558,
    0.716123361,
    0.789018693,
    0.865936696,
    0.883415792,
    0.922894431,
    0.935664806,
    0.878104534,
    0.771338014,
    0.667249559,
    0.634890058,
    0.622529144,
    0.608660986,
    0.594704126,
    0.59057726,
    0.579148166,
    0.57848824,
    0.631091117,
    0.709652911,
    0.770117757,
    0.802152421,
    0.782065494,
    0.759795867,
    0.75149999,
    0.770011567,
    0.78314482,
    0.819534177,
    0.870343276,
    0.884211175,
    0.933755089,
    0.933821347,
    0.86470362,
    0.758417883,
    0.659240991,
    0.62725024,
    0.630171006,
    0.612248892,
    0.597698425,
    0.589757418,
    0.57458955,
    0.570901264,
    0.62772775,
    0.711507133,
    0.775362078,
    0.805729905,
    0.805363272,
    0.803212119,
    0.803675092,
    0.826945796,
    0.851041174,
    0.904665108,
    0.980966486,
    1.005849139,
    1.070174621,
    1.070975884,
    0.971262481,
    0.807435993,
    0.657423071,
    0.599973581,
    0.616135112,
    0.599855481,
    0.591786442,
    0.601762819,
    0.620300552,
    0.676011147,
    0.718344035,
    0.705528245,
    0.702926924,
    0.702102393,
    0.693663198,
    0.681927881,
    0.672918848,
    0.696153052,
    0.726011749,
    0.801587157,
    0.919191789,
    0.978769709,
    1.040912737,
    1.052504184,
    0.975872718,
    0.821332413,
    0.667649184,
    0.614729412,
    0.672391514,
    0.652251605,
    0.634933135,
    0.643536829,
    0.659143259,
    0.714687808,
    0.747819595,
    0.732325055,
    0.734653932,
    0.755999616,
    0.756061319,
    0.751296241,
    0.76117841,
    0.804495334,
    0.83536616,
    0.922091907,
    1.03875338,
    1.088363169,
    1.145994695,
    1.151472562,
    1.071920515,
    0.919102869,
    0.758967936,
    0.697947837,
    0.621242112,
    0.607249329,
    0.595446528,
    0.606526637,
    0.62284133,
    0.678529158,
    0.716015061,
    0.694315219,
    0.684543368,
    0.692635042,
    0.673894628,
    0.660506991,
    0.652300364,
    0.682387142,
    0.712212974,
    0.793111953,
    0.909759049,
    0.962870264,
    1.020372442,
    1.035886689,
    0.965294314,
    0.818852075,
    0.674051796,
    0.62659721,
    0.543336168,
    0.542422822,
    0.544750187,
    0.560528917,
    0.593802397,
    0.661256529,
    0.706830549,
    0.690249338,
    0.680168515,
    0.673481705,
    0.638054169,
    0.602917718,
    0.588750789,
    0.593751706,
    0.626558989,
    0.697920076,
    0.810234915,
    0.857131812,
    0.922663064,
    0.915062127,
    0.853735971,
    0.716945438,
    0.59648245,
    0.558506766,
    0.576080655,
    0.574410608,
    0.577655911,
    0.59426929,
    0.622799012,
    0.687801779,
    0.753929882,
    0.760401859,
    0.760987108,
    0.757435867,
    0.713969792,
    0.67869734,
    0.661010456,
    0.663388085,
    0.695594686,
    0.755240977,
    0.83689801,
    0.861545302,
    0.922823703,
    0.9136964,
    0.846629703,
    0.738913292,
    0.646342201,
    0.619516737,
    0.557430218,
    0.553617391,
    0.552896058,
    0.554954923,
    0.558221718,
    0.572740536,
    0.63555563,
    0.714241932,
    0.776566483,
    0.799904249,
    0.76771754,
    0.736249602,
    0.72110444,
    0.71413694,
    0.731817555,
    0.758675404,
    0.813174902,
    0.832783153,
    0.892097739,
    0.86939756,
    0.792839841,
    0.68796957,
    0.600344284,
    0.576604209,
    0.62191407,
    0.611133616,
    0.608874162,
    0.608747435,
    0.607523332,
    0.61818489,
    0.675571322,
    0.753801257,
    0.829445491,
    0.85621074,
    0.844200062,
    0.835451038,
    0.827937916,
    0.828120343,
    0.856849635,
    0.90407483,
    0.987314707,
    1.020097127,
    1.094271244,
    1.078417961,
    0.969371247,
    0.806345917,
    0.662144711,
    0.608353506,
    0.547773246,
    0.541070018,
    0.546654659,
    0.562853567,
    0.5940585,
    0.665620286,
    0.720051901,
    0.706236112,
    0.703001041,
    0.690862165,
    0.667451601,
    0.645842341,
    0.628316696,
    0.624094698,
    0.658108745,
    0.724787489,
    0.844726568,
    0.915871494,
    0.984194696,
    0.977248517,
    0.896149228,
    0.746699085,
    0.603233359,
    0.558017197,
    0.566418771,
    0.560638459,
    0.56024201,
    0.577486133,
    0.607015082,
    0.678712147,
    0.723327619,
    0.703048105,
    0.698543713,
    0.700459841,
    0.67772378,
    0.654342612,
    0.647191835,
    0.656457411,
    0.688116458,
    0.76327647,
    0.881977831,
    0.943743168,
    1.010764568,
    1.002035176,
    0.922843003,
    0.780851984,
    0.63865481,
    0.591777305,
    0.55844022,
    0.553357568,
    0.554705727,
    0.571686576,
    0.600550652,
    0.672044993,
    0.72161073,
    0.699329136,
    0.689823045,
    0.687972036,
    0.655378251,
    0.633420516,
    0.617963975,
    0.621630858,
    0.656153029,
    0.72861145,
    0.847722317,
    0.912082671,
    0.975229854,
    0.971505342,
    0.895707203,
    0.753465715,
    0.617875299,
    0.577269488,
    0.554887323,
    0.551424061,
    0.552679135,
    0.569102372,
    0.598376529,
    0.667555432,
    0.715930878,
    0.700086485,
    0.687004074,
    0.684538311,
    0.648577294,
    0.616106103,
    0.599235075,
    0.60476094,
    0.63691344,
    0.708565375,
    0.818503491,
    0.880105172,
    0.934757092,
    0.928260137,
    0.868751881,
    0.737252661,
    0.612236786,
    0.571096741,
    0.548902991,
    0.54517128,
    0.546955348,
    0.565037031,
    0.590631125,
    0.66058921,
    0.721026912,
    0.718979478,
    0.723588402,
    0.727287697,
    0.684727499,
    0.653843481,
    0.635027632,
    0.638745463,
    0.671431602,
    0.734753634,
    0.814113414,
    0.849203086,
    0.889875114,
    0.879513885,
    0.81551742,
    0.714159484,
    0.618710268,
    0.592628519,
    0.567012171,
    0.560917758,
    0.559304769,
    0.562158374,
    0.561582058,
    0.577839729,
    0.643356184,
    0.722511517,
    0.781559208,
    0.808804116,
    0.775721841,
    0.746503131,
    0.728212724,
    0.721432869,
    0.738338637,
    0.765366946,
    0.817576193,
    0.851726201,
    0.900300183,
    0.878830946,
    0.80426119,
    0.704855243,
    0.613144815,
    0.58663298,
    0.576659576,
    0.565796622,
    0.56346856,
    0.562233064,
    0.556664722,
    0.569990874,
    0.64078541,
    0.725255335,
    0.78727096,
    0.812451158,
    0.798474087,
    0.78998434,
    0.780480253,
    0.778099914,
    0.806132965,
    0.850977399,
    0.927999004,
    0.97591894,
    1.037148161,
    1.018094608,
    0.913776693,
    0.757893323,
    0.613797943,
    0.560856514,
    0.559303631,
    0.550340726,
    0.55508327,
    0.572072058,
    0.59948568,
    0.672605223,
    0.730126657,
    0.717192695,
    0.710309733,
    0.701992765,
    0.677616037,
    0.65815552,
    0.637357733,
    0.633253834,
    0.666473877,
    0.733143908,
    0.850903245,
    0.936601742,
    0.994715272,
    0.989040548,
    0.909792786,
    0.765636092,
    0.618110685,
    0.570005502,
    0.566908759,
    0.561434193,
    0.562160794,
    0.581344386,
    0.607559912,
    0.680134234,
    0.729723858,
    0.710618068,
    0.697169674,
    0.697554618,
    0.669098916,
    0.642528781,
    0.626873727,
    0.632292777,
    0.661004857,
    0.73374684,
    0.850137185,
    0.927838933,
    0.989001123,
    0.984546877,
    0.909541747,
    0.775695317,
    0.633364297,
    0.586224115,
    0.612303045,
    0.605854487,
    0.607994202,
    0.625427614,
    0.649574648,
    0.718169257,
    0.78248009,
    0.772778692,
    0.747812651,
    0.743059451,
    0.705020794,
    0.680027628,
    0.656315615,
    0.656808548,
    0.687952228,
    0.754790265,
    0.871698439,
    0.956467285,
    1.024103564,
    1.026171325,
    0.951873367,
    0.815038778,
    0.675644336,
    0.628833765,
    0.621331746,
    0.615539956,
    0.617016509,
    0.633336939,
    0.657577175,
    0.723347263,
    0.787696738,
    0.786376791,
    0.758076078,
    0.753940539,
    0.713864378,
    0.679812429,
    0.656110761,
    0.659633277,
    0.688931847,
    0.755029366,
    0.862889206,
    0.945206332,
    1.005069447,
    1.003783162,
    0.944617905,
    0.817193095,
    0.686723159,
    0.637650049,
    0.622550304,
    0.616240279,
    0.61817502,
    0.635953163,
    0.656375844,
    0.722437053,
    0.800060392,
    0.813991268,
    0.802641462,
    0.804644003,
    0.758111039,
    0.725776377,
    0.700254756,
    0.702129907,
    0.731973508,
    0.789372084,
    0.866730978,
    0.922977662,
    0.970140406,
    0.9650807,
    0.900986059,
    0.803219181,
    0.701808833,
    0.667003129,
    0.650812439,
    0.639602629,
    0.636060566,
    0.642453269,
    0.639198162,
    0.655923133,
    0.709177508,
    0.780525416,
    0.850575155,
    0.889675107,
    0.857296597,
    0.8331226,
    0.810052522,
    0.807183299,
    0.823444535,
    0.854195701,
    0.909981366,
    0.956830858,
    0.997592461,
    0.982938941,
    0.906266575,
    0.809468913,
    0.708400692,
    0.674554109,
    0.629743021,
    0.615008803,
    0.611219166,
    0.612469469,
    0.603981936,
    0.618291286,
    0.68278147,
    0.762639249,
    0.829882376,
    0.863879916,
    0.850019109,
    0.845466279,
    0.831825085,
    0.831814951,
    0.859266524,
    0.906412724,
    0.984855301,
    1.045718758,
    1.097902832,
    1.083150182,
    0.978233602,
    0.825849003,
    0.674776697,
    0.616557022,
    0.599720002,
    0.588461428,
    0.592887685,
    0.610253122,
    0.633558831,
    0.705658804,
    0.772645191,
    0.767416967,
    0.751215623,
    0.744433262,
    0.717771814,
    0.698819538,
    0.673096226,
    0.668170707,
    0.699795137,
    0.763724224,
    0.879741348,
    0.982378742,
    1.038108422,
    1.035292419,
    0.956733203,
    0.816672621,
    0.664496186,
    0.611012457,
    0.574845593,
    0.567308702,
    0.567238086,
    0.587426803,
    0.610449598,
    0.68483226,
    0.736409391,
    0.716998724,
    0.700701336,
    0.70567281,
    0.676551865,
    0.652391944,
    0.633572901,
    0.639178974,
    0.667456497,
    0.740561143,
    0.85532673,
    0.946106802,
    0.996187996,
    0.992314436,
    0.918864727,
    0.789763126,
    0.64394961,
    0.594615103,
    0.588536754,
    0.58025517,
    0.581129079,
    0.600181954,
    0.622033538,
    0.695195957,
    0.754193596,
    0.736366381,
    0.714866888,
    0.71768149,
    0.680559251,
    0.65983274,
    0.634675936,
    0.636340061,
    0.668184168,
    0.738414329,
    0.8538165,
    0.947886093,
    0.995954697,
    0.996376413,
    0.92447695,
    0.793015234,
    0.651282571,
    0.605388464,
    0.571064618,
    0.563636668,
    0.56345864,
    0.581915811,
    0.604624964,
    0.677267183,
    0.730193155,
    0.714202106,
    0.694649752,
    0.700584603,
    0.662750363,
    0.634517595,
    0.610806466,
    0.616321585,
    0.647275709,
    0.719298218,
    0.825766575,
    0.914195115,
    0.947402034,
    0.942596301,
    0.886523072,
    0.764977927,
    0.633139329,
    0.587592788,
    0.566798844,
    0.558915665,
    0.559127954,
    0.579166834,
    0.598278988,
    0.671679783,
    0.736581537,
    0.734451872,
    0.732954562,
    0.745593699,
    0.701591873,
    0.675401847,
    0.650159123,
    0.654191415,
    0.685916417,
    0.749766822,
    0.825824837,
    0.887387179,
    0.906446834,
    0.897426655,
    0.836518677,
    0.744657574,
    0.642135837,
    0.61143955,
    0.584745251,
    0.574551423,
    0.571393125,
    0.576247364,
    0.569337965,
    0.58903204,
    0.658896688,
    0.737846647,
    0.790845603,
    0.827161666,
    0.792680653,
    0.768176927,
    0.743446589,
    0.736973721,
    0.752990007,
    0.780569098,
    0.829632801,
    0.889925691,
    0.916883039,
    0.896618696,
    0.825028457,
    0.734941183,
    0.636294692,
    0.605283228,
    0.601129757,
    0.585946275,
    0.581997639,
    0.583049139,
    0.571370875,
    0.58800967,
    0.661678166,
    0.74508328,
    0.802417261,
    0.837501002,
    0.822306236,
    0.818804445,
    0.802701421,
    0.800966772,
    0.828177287,
    0.873918099,
    0.948456981,
    1.022089244,
    1.061976835,
    1.044540794,
    0.942739861,
    0.795772026,
    0.644359026,
    0.586584934,
    0.600131238,
    0.587028405,
    0.590638631,
    0.609278505,
    0.630061691,
    0.704555057,
    0.771550458,
    0.763906592,
    0.746436926,
    0.745299884,
    0.718457693,
    0.702385558,
    0.673843933,
    0.66929904,
    0.700964493,
    0.765994425,
    0.880904829,
    0.994726489,
    1.037512634,
    1.034358783,
    0.957196867,
    0.821452233,
    0.666480248,
    0.61185342,
    0.600812426,
    0.591298944,
    0.590819622,
    0.611826472,
    0.631749363,
    0.706278097,
    0.763671283,
    0.748069264,
    0.725438907,
    0.733762603,
    0.703220618,
    0.680452873,
    0.657410589,
    0.662591151,
    0.690060295,
    0.76182953,
    0.875548592,
    0.980238117,
    1.024268569,
    1.021728295,
    0.948882068,
    0.82340535,
    0.674006602,
    0.621121144,
    0.575417068,
    0.565442094,
    0.565329621,
    0.585955212,
    0.605810985,
    0.68251921,
    0.738334663,
    0.714679629,
    0.694634251,
    0.704466118,
    0.667871725,
    0.650822252,
    0.623565804,
    0.626017812,
    0.658450549,
    0.731078773,
    0.845576713,
    0.94889588,
    0.980464542,
    0.979478938,
    0.909224529,
    0.782163101,
    0.638254776,
    0.592643083,
    0.635238118,
    0.618923313,
    0.613185158,
    0.629272351,
    0.647697842,
    0.721572261,
    0.776890574,
    0.765738459,
    0.752679458,
    0.776023271,
    0.74936502,
    0.736580959,
    0.722375141,
    0.737958057,
    0.773821139,
    0.850290855,
    0.956412571,
    1.055216589,
    1.07284136,
    1.061479999,
    0.999170712,
    0.872899764,
    0.726822541,
    0.670019327,
    0.589499331,
    0.579760829,
    0.579570693,
    0.600556798,
    0.616925574,
    0.690709625,
    0.760537344,
    0.761275252,
    0.754145418,
    0.770624886,
    0.725426447,
    0.70082744,
    0.671511687,
    0.675196339,
    0.706345704,
    0.769180307,
    0.844482515,
    0.918971253,
    0.931229659,
    0.923095171,
    0.862671754,
    0.774162406,
    0.668415085,
    0.63466797,
    0.641529249,
    0.627661054,
    0.623165817,
    0.63098416,
    0.622305705,
    0.642836629,
    0.704570479,
    0.777496473,
    0.837102966,
    0.883644851,
    0.849692726,
    0.829436152,
    0.800397924,
    0.796488792,
    0.812439537,
    0.842711257,
    0.894695761,
    0.965373909,
    0.983549543,
    0.966995245,
    0.893755586,
    0.805683646,
    0.700700365,
    0.66493679,
    0.621934072,
    0.59799031,
    0.585909601,
    0.569871953,
    0.532763712,
    0.521433003,
    0.608104489,
    0.746156184,
    0.817150749,
    0.811939753,
    0.791832407,
    0.774840969,
    0.793692719,
    0.7892755,
    0.795601295,
    0.82418025,
    0.865123854,
    1.002575852,
    1.069263947,
    1.045255156,
    0.975654863,
    0.877556656,
    0.724001641,
    0.641515696,
    0.625151439,
    0.604769142,
    0.600965232,
    0.59832929,
    0.590209125,
    0.629457632,
    0.730692456,
    0.80146965,
    0.781908789,
    0.731996678,
    0.697167403,
    0.662402743,
    0.66834357,
    0.657164331,
    0.665565222,
    0.705519231,
    0.781348673,
    0.963837998,
    1.048075401,
    1.036070198,
    0.990701643,
    0.904819607,
    0.750588743,
    0.669226302,
    0.617079493,
    0.600117396,
    0.591771426,
    0.590737203,
    0.581515365,
    0.620112577,
    0.710213771,
    0.773240301,
    0.751106056,
    0.710555148,
    0.672724775,
    0.631547603,
    0.643706403,
    0.642197945,
    0.646165228,
    0.693756931,
    0.768357095,
    0.940891263,
    1.026275814,
    1.013576765,
    0.972484238,
    0.897617193,
    0.749690555,
    0.670368036,
    0.617076517,
    0.599269126,
    0.591095872,
    0.588483593,
    0.577852272,
    0.614695304,
    0.708788941,
    0.771166846,
    0.748066197,
    0.706249707,
    0.661880185,
    0.625084114,
    0.631827148,
    0.627315503,
    0.63414087,
    0.681227504,
    0.756886809,
    0.930265841,
    1.011473306,
    1.002348361,
    0.963254124,
    0.887040616,
    0.74381108,
    0.667996652,
    0.61054597,
    0.593280694,
    0.583724487,
    0.579614731,
    0.568914024,
    0.602644068,
    0.69316054,
    0.760881898,
    0.738996896,
    0.698804531,
    0.653724439,
    0.609429927,
    0.61696182,
    0.616187374,
    0.620787201,
    0.669593434,
    0.737080553,
    0.905422272,
    0.976429072,
    0.963327888,
    0.939265495,
    0.872959152,
    0.739201254,
    0.661683624,
    0.614638064,
    0.596676529,
    0.587093254,
    0.583613437,
    0.568624347,
    0.600658868,
    0.704464699,
    0.78857944,
    0.783737926,
    0.748777347,
    0.697842384,
    0.655307926,
    0.660897792,
    0.65880222,
    0.662844606,
    0.703984322,
    0.742677391,
    0.885404561,
    0.946379598,
    0.93012479,
    0.901334722,
    0.863952684,
    0.758360357,
    0.693261356,
    0.645540377,
    0.622920855,
    0.608032397,
    0.593094569,
    0.555162505,
    0.538179691,
    0.620450692,
    0.76189856,
    0.834250258,
    0.834181849,
    0.796914812,
    0.762258665,
    0.76948705,
    0.76264799,
    0.753010308,
    0.768618664,
    0.787342971,
    0.920482255,
    0.97719551,
    0.952136873,
    0.911029662,
    0.8741716,
    0.769568499,
    0.704363998,
    0.622271251,
    0.596455413,
    0.581368948,
    0.561319827,
    0.518622792,
    0.498559007,
    0.591895192,
    0.74171819,
    0.811525153,
    0.80680592,
    0.787706972,
    0.772449722,
    0.788161251,
    0.783955347,
    0.784966507,
    0.816858144,
    0.858311037,
    1.003313077,
    1.070394821,
    1.045112089,
    0.976684928,
    0.885729842,
    0.734224058,
    0.645520327,
    0.59591938,
    0.573118177,
    0.565613833,
    0.56150632,
    0.549934862,
    0.585077496,
    0.67634606,
    0.741137288,
    0.735421633,
    0.694041175,
    0.663763664,
    0.63627041,
    0.640017315,
    0.631963304,
    0.637183977,
    0.68719825,
    0.766710751,
    0.949046845,
    1.017216653,
    1.003787509,
    0.959948666,
    0.879796803,
    0.727513013,
    0.643158711,
    0.57223562,
    0.553010615,
    0.541138122,
    0.539772141,
    0.52828705,
    0.5649534,
    0.640371464,
    0.690825738,
    0.686410702,
    0.657183621,
    0.625114556,
    0.593443563,
    0.603273505,
    0.605509722,
    0.607500682,
    0.667230869,
    0.74686018,
    0.915999701,
    0.977955453,
    0.963640965,
    0.924277734,
    0.853759636,
    0.708311835,
    0.627692191,
    0.648335725,
    0.628004852,
    0.617945782,
    0.614347621,
    0.600467904,
    0.629824809,
    0.725697296,
    0.792362471,
    0.771832407,
    0.735604471,
    0.693317238,
    0.662068213,
    0.661866834,
    0.658540582,
    0.661757288,
    0.713625285,
    0.795081035,
    0.972738406,
    1.048855083,
    1.043063394,
    1.002979303,
    0.927897285,
    0.784954032,
    0.701342126,
    0.700706848,
    0.680769151,
    0.670706718,
    0.665279196,
    0.650793054,
    0.67298661,
    0.777807734,
    0.862457946,
    0.83140966,
    0.79265153,
    0.746821241,
    0.705114454,
    0.702028162,
    0.700416155,
    0.700373698,
    0.748241757,
    0.822310727,
    1.000639749,
    1.080286319,
    1.074292175,
    1.047009508,
    0.979007577,
    0.843927512,
    0.753775185,
    0.674915977,
    0.654703758,
    0.644641257,
    0.64117442,
    0.624170335,
    0.648376778,
    0.758683211,
    0.85058777,
    0.842494926,
    0.812992354,
    0.762852378,
    0.725124352,
    0.721271338,
    0.719088244,
    0.720539897,
    0.763491835,
    0.809658668,
    0.957946667,
    1.017478026,
    1.006045208,
    0.974327573,
    0.93444092,
    0.828935102,
    0.75493692,
    0.644769529,
    0.62113364,
    0.605689674,
    0.591057084,
    0.552352715,
    0.5318248,
    0.615452621,
    0.756626024,
    0.829388532,
    0.836670176,
    0.801707681,
    0.773458042,
    0.773754493,
    0.766889908,
    0.756478293,
    0.77652918,
    0.801482673,
    0.935523211,
    0.978728516,
    0.953893705,
    0.911836264,
    0.873467334,
    0.772073565,
    0.703607506,
    0.650876104,
    0.622919952,
    0.606870861,
    0.588209919,
    0.545152065,
    0.521168025,
    0.609984041,
    0.756297154,
    0.832066917,
    0.837678549,
    0.821431885,
    0.813536184,
    0.82181602,
    0.819053471,
    0.819349019,
    0.856958858,
    0.906731999,
    1.052129352,
    1.107059294,
    1.084215087,
    1.013289932,
    0.919836004,
    0.769305836,
    0.675590269,
    0.68156832,
    0.656612587,
    0.649319821,
    0.645094853,
    0.631467366,
    0.657186537,
    0.760763425,
    0.83913117,
    0.824487851,
    0.786420992,
    0.755514968,
    0.731066975,
    0.724091166,
    0.714950929,
    0.717253516,
    0.766144803,
    0.853183847,
    1.043457047,
    1.117051553,
    1.109709931,
    1.061898279,
    0.977977731,
    0.825097578,
    0.730113427,
    0.717477632,
    0.695783668,
    0.685079831,
    0.682500907,
    0.667506037,
    0.689625058,
    0.791142061,
    0.869891302,
    0.844456012,
    0.812932954,
    0.777216341,
    0.744929701,
    0.740894957,
    0.740128919,
    0.737198951,
    0.789929757,
    0.876914983,
    1.060742015,
    1.144446509,
    1.14014223,
    1.095069351,
    1.019378855,
    0.871319616,
    0.774473486,
    0.729985603,
    0.707662491,
    0.697851899,
    0.69425173,
    0.678608951,
    0.699058541,
    0.806612814,
    0.886071416,
    0.856913457,
    0.824127492,
    0.781248194,
    0.75290834,
    0.742427375,
    0.738030779,
    0.738603375,
    0.789348951,
    0.878138395,
    1.063171884,
    1.14431815,
    1.14405409,
    1.100024735,
    1.021167464,
    0.877783695,
    0.784241448,
    0.706795585,
    0.685420174,
    0.674504284,
    0.670509045,
    0.656317434,
    0.676290518,
    0.775639471,
    0.854649359,
    0.829740176,
    0.801258562,
    0.758479896,
    0.723900845,
    0.714776954,
    0.714399534,
    0.714405638,
    0.768166382,
    0.849557106,
    1.026585457,
    1.091341407,
    1.0855754,
    1.056452787,
    0.986345795,
    0.85340165,
    0.760772107,
    0.746478735,
    0.724479765,
    0.714612684,
    0.711370038,
    0.693053682,
    0.709405232,
    0.829390054,
    0.931829077,
    0.916599009,
    0.89120159,
    0.840797412,
    0.806178193,
    0.792835463,
    0.789784326,
    0.789077504,
    0.831471651,
    0.884768597,
    1.039180606,
    1.101754951,
    1.094873396,
    1.059414313,
    1.01588819,
    0.910299322,
    0.827664438,
    0.741178989,
    0.71299259,
    0.695762935,
    0.685213436,
    0.64753375,
    0.622710439,
    0.687813013,
    0.817578772,
    0.909082905,
    0.93352217,
    0.902519964,
    0.883325234,
    0.875574198,
    0.873527015,
    0.862866081,
    0.891168657,
    0.929029025,
    1.061808734,
    1.096816591,
    1.079026482,
    1.030694818,
    0.987355971,
    0.881703176,
    0.804858758,
    0.737792679,
    0.705714618,
    0.688052838,
    0.673194456,
    0.631183167,
    0.603199292,
    0.675232919,
    0.811031132,
    0.903735517,
    0.925522761,
    0.913003919,
    0.913673088,
    0.914257822,
    0.915832168,
    0.91597742,
    0.961280781,
    1.023200011,
    1.167326488,
    1.21391317,
    1.197218206,
    1.120446554,
    1.022274151,
    0.868178899,
    0.766889835,
    0.626810816,
    0.601096911,
    0.592229793,
    0.590643688,
    0.579266359,
    0.60843129,
    0.693249695,
    0.751517135,
    0.754042512,
    0.731272085,
    0.706061883,
    0.691475748,
    0.682291332,
    0.676053852,
    0.680788233,
    0.740550063,
    0.834516162,
    1.01635095,
    1.060253592,
    1.048412834,
    1.000332642,
    0.91551272,
    0.766854079,
    0.675917035,
    0.613275472,
    0.591215031,
    0.578236252,
    0.579236392,
    0.567868672,
    0.597843199,
    0.669451061,
    0.716097542,
    0.717382728,
    0.70554856,
    0.677871972,
    0.658116793,
    0.654708382,
    0.658329604,
    0.659615874,
    0.727718903,
    0.821562823,
    0.991262411,
    1.032415909,
    1.020095797,
    0.976122876,
    0.900615968,
    0.758459295,
    0.670598798,
    0.60884283,
    0.58633728,
    0.574047238,
    0.574356401,
    0.56287253,
    0.592748257,
    0.666691272,
    0.710024609,
    0.710672613,
    0.698964784,
    0.664864081,
    0.649823135,
    0.641033061,
    0.64149184,
    0.64693549,
    0.714448601,
    0.809983055,
    0.978912246,
    1.013380723,
    1.003802506,
    0.961319262,
    0.882991297,
    0.74608457,
    0.663329789,
    0.660107556,
    0.620598771,
    0.620074918,
    0.614625689,
    0.574241894,
    0.567598555,
    0.658657295,
    0.784822137,
    0.885562471,
    0.963135688,
    1.04757288,
    0.999810562,
    0.970700482,
    0.913015964,
    0.874489074,
    0.857598775,
    0.855984566,
    0.909532485,
    0.940520221,
    0.927124182,
    0.887129025,
    0.876699485,
    0.776438587,
    0.662291356,
    0.609053239,
    0.587129296,
    0.57464356,
    0.575637506,
    0.562083634,
    0.589467076,
    0.672479966,
    0.735081317,
    0.752317076,
    0.749131957,
    0.708108838,
    0.687281928,
    0.676809731,
    0.678998056,
    0.683858976,
    0.744120007,
    0.803897672,
    0.940265752,
    0.952662737,
    0.935073686,
    0.901557168,
    0.858856822,
    0.760290376,
    0.69035009,
    0.640579935,
    0.616053178,
    0.600194122,
    0.587176547,
    0.549351099,
    0.52454141,
    0.608927766,
    0.747417874,
    0.820296662,
    0.841699192,
    0.810411304,
    0.791995903,
    0.781517343,
    0.774376724,
    0.76428545,
    0.790780958,
    0.826642475,
    0.95977307,
    0.980508279,
    0.953065801,
    0.908098461,
    0.865722215,
    0.770499968,
    0.699474558,
    0.730341883,
    0.698267003,
    0.680624926,
    0.666610512,
    0.625506877,
    0.59610644,
    0.669032642,
    0.803561332,
    0.895260072,
    0.923376228,
    0.912363271,
    0.917041511,
    0.913102853,
    0.914251742,
    0.914965179,
    0.962681671,
    1.029274298,
    1.172424825,
    1.208761467,
    1.189735859,
    1.111557879,
    1.011353858,
    0.860602959,
    0.759234537,
    0.696982337,
    0.670246432,
    0.662092308,
    0.660878175,
    0.649132076,
    0.67146995,
    0.767445471,
    0.837271624,
    0.830096762,
    0.809798042,
    0.783411067,
    0.769779219,
    0.752598265,
    0.744921757,
    0.74820323,
    0.805307906,
    0.905032317,
    1.092414977,
    1.14322633,
    1.134427612,
    1.082303166,
    0.993493893,
    0.844774778,
    0.746999571,
    0.762675422,
    0.728625177,
    0.714715768,
    0.709443566,
    0.690824136,
    0.714332851,
    0.804234544,
    0.876460652,
    0.865731323,
    0.835000287,
    0.812115265,
    0.775818159,
    0.774695655,
    0.778914599,
    0.778586495,
    0.832033348,
    0.934590335,
    1.117170024,
    1.184306502,
    1.195377842,
    1.168630756,
    1.097704322,
    0.934657048,
    0.823270267,
    0.767807816,
    0.733334834,
    0.720308226,
    0.714177344,
    0.695277907,
    0.717950227,
    0.812548534,
    0.883997805,
    0.870440007,
    0.838733998,
    0.808910484,
    0.776473168,
    0.769667971,
    0.770394317,
    0.773910822,
    0.825621165,
    0.929671213,
    1.112545975,
    1.176106396,
    1.190409014,
    1.164842098,
    1.090881438,
    0.93269208,
    0.82560719,
    0.763375727,
    0.729876571,
    0.716148278,
    0.709293165,
    0.691519312,
    0.712285516,
    0.803393383,
    0.879251544,
    0.865625191,
    0.836064618,
    0.805216338,
    0.765044303,
    0.758880355,
    0.762879112,
    0.765316138,
    0.817772843,
    0.914165408,
    1.091163511,
    1.144391745,
    1.154242087,
    1.142847539,
    1.077065766,
    0.928559716,
    0.820923229,
    0.690417451,
    0.657176624,
    0.642885942,
    0.638888695,
    0.62011602,
    0.647288216,
    0.733513656,
    0.805341796,
    0.823009677,
    0.80642374,
    0.773349908,
    0.739202638,
    0.735304722,
    0.740035843,
    0.745789314,
    0.79723153,
    0.865635976,
    1.007373094,
    1.029267235,
    1.030088462,
    1.015559686,
    0.978580745,
    0.860941029,
    0.774363916,
    0.785815985,
    0.746002324,
    0.726106194,
    0.712935366,
    0.67250435,
    0.650230584,
    0.706101902,
    0.830420966,
    0.932649717,
    0.955443789,
    0.936306697,
    0.910941833,
    0.907367658,
    0.90972088,
    0.902206291,
    0.929441208,
    0.982022153,
    1.113931048,
    1.136780677,
    1.133393949,
    1.10324285,
    1.06411985,
    0.94301816,
    0.852752645,
    0.77383676,
    0.730621835,
    0.710492429,
    0.692840786,
    0.648251562,
    0.623287388,
    0.687482277,
    0.818494677,
    0.920253338,
    0.939389317,
    0.93825728,
    0.931877868,
    0.937116669,
    0.942614369,
    0.945973711,
    0.989357647,
    1.064894247,
    1.207897593,
    1.242496285,
    1.239260885,
    1.181320278,
    1.088137331,
    0.919647505,
    0.806131605,
    0.726262045,
    0.688826711,
    0.67821278,
    0.672980629,
    0.657551895,
    0.684482453,
    0.772475662,
    0.839452577,
    0.842188735,
    0.812625832,
    0.796209971,
    0.771374897,
    0.764159368,
    0.760525861,
    0.766661928,
    0.819100583,
    0.926542898,
    1.112869543,
    1.160618728,
    1.166278151,
    1.134738883,
    1.05323751,
    0.887629492,
    0.778939792,
    0.716695378,
    0.683010057,
    0.668497336,
    0.665752223,
    0.650388037,
    0.677971216,
    0.754194295,
    0.810965205,
    0.811090945,
    0.791517822,
    0.772242415,
    0.741549162,
    0.740183536,
    0.746056287,
    0.748761174,
    0.808589755,
    0.915546076,
    1.090253019,
    1.137324503,
    1.142518963,
    1.114898844,
    1.042488732,
    0.883145842,
    0.777462528,
    0.70665666,
    0.672725979,
    0.658921338,
    0.655594069,
    0.640412461,
    0.668577547,
    0.746252528,
    0.798722892,
    0.798715417,
    0.779292412,
    0.753704635,
    0.727481546,
    0.72144611,
    0.724232956,
    0.73136118,
    0.790640022,
    0.898906846,
    1.072164841,
    1.112136389,
    1.119411874,
    1.093393551,
    1.018285158,
    0.864286282,
    0.764531683,
    0.734585146,
    0.701500687,
    0.687544144,
    0.682877248,
    0.668046509,
    0.691570507,
    0.773399025,
    0.83842096,
    0.831589475,
    0.811049285,
    0.782716797,
    0.746592079,
    0.739619057,
    0.744599805,
    0.749574428,
    0.80624801,
    0.906611499,
    1.077677639,
    1.116554282,
    1.121473337,
    1.108700547,
    1.041029222,
    0.895581895,
    0.792312889,
    0.751513554,
    0.718190658,
    0.705098327,
    0.701594775,
    0.68349344,
    0.705661873,
    0.803429384,
    0.886682426,
    0.892952367,
    0.876815485,
    0.84169851,
    0.806071194,
    0.796648006,
    0.799485195,
    0.80470201,
    0.851517388,
    0.923571351,
    1.06963113,
    1.101853424,
    1.103767371,
    1.085301092,
    1.044385883,
    0.926715547,
    0.835988537,
    0.770427383,
    0.731632712,
    0.712303369,
    0.700191432,
    0.661906707,
    0.640283194,
    0.697175017,
    0.819949872,
    0.919495185,
    0.946422227,
    0.927790105,
    0.903619367,
    0.898356166,
    0.899844191,
    0.893960152,
    0.921343618,
    0.976279946,
    1.105794651,
    1.12264482,
    1.114839762,
    1.083276409,
    1.042097687,
    0.924191144,
    0.836821424,
    0.860630037,
    0.814416795,
    0.793367355,
    0.780384146,
    0.739058705,
    0.713163604,
    0.758235828,
    0.87679105,
    0.99519349,
    1.028656773,
    1.029983182,
    1.028318305,
    1.029922303,
    1.039677495,
    1.044640343,
    1.092793644,
    1.177956308,
    1.317149573,
    1.350231788,
    1.350659295,
    1.286105082,
    1.187619036,
    1.014522791,
    0.897402317,
    0.813481543,
    0.776033802,
    0.767183545,
    0.761912584,
    0.746651328,
    0.766567375,
    0.872805907,
    0.958260042,
    0.943434157,
    0.91101594,
    0.890887605,
    0.862193723,
    0.848609596,
    0.842064238,
    0.846891104,
    0.891410588,
    1.00181716,
    1.195445127,
    1.262250664,
    1.270479021,
    1.234258769,
    1.148232752,
    0.981591321,
    0.866696971,
    0.74695907,
    0.713704761,
    0.700142332,
    0.69840733,
    0.684743107,
    0.710376928,
    0.792045352,
    0.853540081,
    0.847103747,
    0.829614495,
    0.809307686,
    0.777792371,
    0.773364713,
    0.778038379,
    0.78145407,
    0.838532289,
    0.948298784,
    1.123809497,
    1.175237782,
    1.179020365,
    1.148342057,
    1.072709188,
    0.91422637,
    0.807987673,
    0.754013452,
    0.72048165,
    0.707942337,
    0.705285504,
    0.691409589,
    0.716219792,
    0.803500562,
    0.865112877,
    0.854822739,
    0.835592392,
    0.808000815,
    0.779668821,
    0.769837857,
    0.770825062,
    0.778109636,
    0.832739081,
    0.943566848,
    1.11958724,
    1.169089372,
    1.176028352,
    1.146472796,
    1.067763201,
    0.913998755,
    0.81218765,
    0.799654586,
    0.766932198,
    0.754564923,
    0.750215489,
    0.736277098,
    0.754985467,
    0.850723474,
    0.929460391,
    0.908503584,
    0.886202701,
    0.854862952,
    0.815305444,
    0.80377094,
    0.806332532,
    0.810885533,
    0.860951713,
    0.963623118,
    1.139483873,
    1.193234717,
    1.198938898,
    1.182134613,
    1.110472342,
    0.964610686,
    0.857721623,
    0.781353407,
    0.748694544,
    0.736707701,
    0.734158533,
    0.717908637,
    0.738425093,
    0.842281975,
    0.930835938,
    0.929664538,
    0.914688558,
    0.878172142,
    0.840877949,
    0.829018081,
    0.830471247,
    0.836525675,
    0.879829264,
    0.954001019,
    1.100846681,
    1.139084078,
    1.139296667,
    1.117811448,
    1.073721699,
    0.956785846,
    0.865982948,
    0.884303995,
    0.841736003,
    0.821265896,
    0.814904475,
    0.780617179,
    0.75846238,
    0.790378358,
    0.897505735,
    1.018734031,
    1.061738832,
    1.045654387,
    1.025902035,
    1.017787276,
    1.024880212,
    1.020963485,
    1.053453468,
    1.118960414,
    1.243766851,
    1.262631906,
    1.260179697,
    1.22057042,
    1.17325547,
    1.048222409,
    0.956509104,
    0.840610378,
    0.796004965,
    0.775833154,
    0.763923431,
    0.725310689,
    0.701286191,
    0.747567872,
    0.864650856,
    0.979377057,
    1.015186092,
    1.01632961,
    1.013978984,
    1.015800022,
    1.02444313,
    1.031560317,
    1.078409746,
    1.164226408,
    1.300352683,
    1.330508659,
    1.32551296,
    1.259703618,
    1.159343791,
    0.989165367,
    0.876617326,
    0.836046504,
    0.799531901,
    0.791831937,
    0.787572978,
    0.774464696,
    0.793613155,
    0.905023673,
    0.994696727,
    0.972928411,
    0.941340052,
    0.919780162,
    0.888972126,
    0.873959048,
    0.866063686,
    0.872074157,
    0.912980982,
    1.024798422,
    1.218438061,
    1.291099974,
    1.296907932,
    1.257924123,
    1.168952533,
    1.003135113,
    0.889279077,
    0.862921318,
    0.830073128,
    0.819135168,
    0.81663345,
    0.802726715,
    0.819512694,
    0.927993145,
    1.016860523,
    0.984603315,
    0.958994472,
    0.932518683,
    0.893147674,
    0.88239783,
    0.882740302,
    0.884121604,
    0.928410445,
    1.039229271,
    1.22543823,
    1.308383214,
    1.316052491,
    1.279977558,
    1.199302939,
    1.038413101,
    0.924327729,
    0.880529973,
    0.847407398,
    0.837702871,
    0.834050638,
    0.819685748,
    0.834804225,
    0.951563974,
    1.043377975,
    1.004861342,
    0.976194588,
    0.941783598,
    0.904691125,
    0.8881664,
    0.884430473,
    0.889338444,
    0.929910796,
    1.041533626,
    1.229484398,
    1.313934511,
    1.325419528,
    1.290190726,
    1.20622892,
    1.049640622,
    0.939091097,
    0.911197748,
    0.859908797,
    0.849010157,
    0.830833744,
    0.802470543,
    0.798388753,
    0.907617531,
    0.993919202,
    1.034663984,
    1.059445703,
    1.046942566,
    1.055269684,
    1.051898169,
    1.008505008,
    1.00951687,
    1.064849735,
    1.109152199,
    1.221770594,
    1.292233922,
    1.249262657,
    1.225611659,
    1.167928918,
    1.069441587,
    0.974589952,
    0.890627082,
    0.850997846,
    0.82398502,
    0.807257541,
    0.787388626,
    0.781297251,
    0.830367012,
    0.938973302,
    1.057524809,
    1.158774457,
    1.150932211,
    1.146486349,
    1.090803169,
    1.078850945,
    1.086042542,
    1.132367136,
    1.123982087,
    1.153067588,
    1.146187958,
    1.165108734,
    1.118173108,
    1.076169821,
    1.000250899,
    0.902831362,
    0.855650043,
    0.815303983,
    0.795997756,
    0.790458423,
    0.759114984,
    0.739756882,
    0.774160901,
    0.880591625,
    0.99642194,
    1.039698519,
    1.022794584,
    1.000741838,
    0.994401735,
    0.999945455,
    0.99848279,
    1.028351031,
    1.092749716,
    1.21420129,
    1.232356698,
    1.223493353,
    1.183113205,
    1.13434243,
    1.012340161,
    0.926653763,
    0.813537991,
    0.771132876,
    0.752125255,
    0.741094487,
    0.705481531,
    0.684348006,
    0.7327502,
    0.848960371,
    0.958573649,
    0.994600297,
    0.994878015,
    0.990070897,
    0.993859339,
    1.001032477,
    1.010641476,
    1.054794631,
    1.139423132,
    1.272121803,
    1.302060401,
    1.290746679,
    1.224102707,
    1.12227043,
    0.954908937,
    0.84841793,
    0.761666249,
    0.727182586,
    0.71948644,
    0.71781742,
    0.709527789,
    0.736900277,
    0.833015887,
    0.904808735,
    0.893276071,
    0.869016308,
    0.849908423,
    0.820649371,
    0.810922969,
    0.804285477,
    0.814986234,
    0.859747772,
    0.971374997,
    1.154188376,
    1.210862849,
    1.207276921,
    1.168479255,
    1.07900857,
    0.916881498,
    0.81425619,
    0.762389197,
    0.731801649,
    0.720511753,
    0.721050139,
    0.712648907,
    0.740010341,
    0.827449175,
    0.89226228,
    0.875158621,
    0.8589349,
    0.836076427,
    0.79950489,
    0.795669217,
    0.798030895,
    0.805021477,
    0.85541817,
    0.965831479,
    1.138272145,
    1.198796659,
    1.195255681,
    1.160146743,
    1.079612428,
    0.923234614,
    0.823007114,
    0.71224559,
    0.681878465,
    0.670830791,
    0.671446692,
    0.664351546,
    0.696029339,
    0.776471233,
    0.827849505,
    0.81768014,
    0.804289055,
    0.776714166,
    0.746103147,
    0.740389151,
    0.74073485,
    0.753613952,
    0.806521753,
    0.917575754,
    1.084069679,
    1.128499093,
    1.124124031,
    1.091825305,
    1.009591188,
    0.859749379,
    0.769696098,
    0.766949895,
    0.729514578,
    0.725897848,
    0.707427197,
    0.711371001,
    0.743416211,
    0.80183301,
    0.877195891,
    0.866266835,
    0.864747,
    0.851449874,
    0.853855038,
    0.818641245,
    0.831091728,
    0.868922734,
    0.899531258,
    0.973880365,
    1.117995893,
    1.120729339,
    1.115947986,
    1.077959452,
    1.014123776,
    0.939756278,
    0.878809477,
  ],
};

export default minnesota;
