import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const OneTenOne = ({
    children,
    classNames,
    colonHeader,
    customID
}) => {

    const colonHeaderHTML = colonHeader && customID
        ? (
                <div className="colon-header">
                    <FormattedMessage
                      id={customID}
                      defaultMessage={colonHeader}
                      description={colonHeader}
                    />
                </div>
            ) 
        : null;


    return (
        <div className={`col-md-10 offset-md-1 one-ten-content-section ${classNames}`}>
            {colonHeaderHTML}
            {children}
        </div>
    );
}

export default OneTenOne;

OneTenOne.propTypes = {
    children: PropTypes.node.isRequired,
    classNames: PropTypes.string,
    colonHeader: PropTypes.any
}