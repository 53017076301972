import React, {useState, useContext, useEffect } from "react";
import {ValidateFiveDigitCode} from "../../../utils/Helpers/USPostalCodeValidator"; 

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { useIntl } from 'react-intl';

// import IconZip from "../../../client_customizations/assets/images/icons/icon-zip.png";
import "./InputZipcode.scss";

const InputZipcode = ({zipcode, userLocation, prefix = "" }) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  useEffect(() => {
    setWorkingZipcode(zipcode);
  }, [zipcode]);

  const [workingZipcode,setWorkingZipcode] = useState(
    zipcode
  );

  const isInvalid = !userPrefs.zipcodeIsUpdating && (!ValidateFiveDigitCode(workingZipcode) || userPrefs.zipcodeIsNotFound)

  if (prefix) {
    prefix += '-';
  }

  const id = `${prefix}input-zipcode`;

  const handleUpdateButtonPress = (e, input) => {
    if (input && e.key !== "Enter") return

    if(ValidateFiveDigitCode(workingZipcode) && !userPrefs.zipcodeIsUpdating){

      async function asyncCall() {
        await userPrefs.set({ workingZipcode: workingZipcode });
        if (!userPrefs.zipcodeIsUpdating) {
          userPrefs.syncWorkingZipcode();
        }
      }
      asyncCall()
    }
  }

  return (
    <>
      <div className="form-inline justify-content-end d-none d-lg-flex">
        <div className="input-group mr-sm-2 align-items-center">
          <span className="user-region">
            {userLocation ? `${intl.formatMessage({id:"itLooksLike", defaultMessage:"It looks like you're in"})} ${userLocation.region}` : null}
          </span>
          <input
            id={`${id}-desktop`}
            title="Zipcode"
            className={isInvalid ? "form-control zipcode-input is-invalid" : "form-control zipcode-input is-valid"}
            value={workingZipcode}
            onChange={e => setWorkingZipcode (e.target.value)}
            onKeyDown={e => handleUpdateButtonPress(e, "input")}
          />
        </div>
        <button
          type="button"
          className="btn btn-default update-zipcode-button"
          onClick={() => handleUpdateButtonPress()}
          disabled={userPrefs.zipcodeIsUpdating}
          id={`${prefix}update-zipcode-button-desktop`}
        >
          {intl.formatMessage ? intl.formatMessage({id:"updateLocation", defaultMessage:"Update Location"}) : "Update Location"}
        </button>
      </div>
      {/* Mobile Display */}
      <div className="form-inline d-lg-none justify-content-start">
        <div className="input-group mr-sm-2 align-items-center" style={{width: "70%"}}>
          <span className="user-region" style={{width: "70%", textAlign: "center"}}>
            {userLocation ? `${intl.formatMessage({id:"itLooksLike", defaultMessage:"It looks like you're in"})} ${userLocation.region}` : null}
          </span>
          <input
            id={`${id}-mobile`}
            title="Zipcode"
            className={isInvalid ? "form-control zipcode-input is-invalid" : "form-control zipcode-input is-valid"}
            value={workingZipcode}
            onChange={e => setWorkingZipcode (e.target.value)}
            onKeyDown={e => handleUpdateButtonPress(e, "input")}
          />
        </div>
        <button
          type="button"
          className="btn btn-default update-zipcode-button"
          onClick={() => handleUpdateButtonPress()}
          disabled={userPrefs.zipcodeIsUpdating}
          id={`${prefix}update-zipcode-button-mobile`}
        >
          {intl.formatMessage ? intl.formatMessage({id:"update", defaultMessage:"Update"}) : "Update"}
        </button>
      </div>
    </>
  );
};

export default InputZipcode;
