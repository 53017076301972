import React from "react";
import PropTypes from "prop-types";
import "./ChargerCard.scss";
import typeIcon from "../../../assets/images/icons/typeIcon.svg"
import cordLengthIcon from "../../../assets/images/icons/cordLengthIcon.svg"
import socketIcon from "../../../assets/images/icons/socketIcon.png"
import lightning from "../../../assets/images/icons/xcel_lightning.svg"
import wifiIcon from "../../../assets/images/icons/wifi_icon.svg"
import { FormattedMessage, useIntl } from 'react-intl';

const ChargerCard = ({
  charger,
  selectedVehicle
}) => {
  const intl = useIntl() 

  if (!charger) return null;
  
  const make = charger.make ? charger.make : "";
  const model = charger.model ? charger.model : "";

  const image = charger.img ? charger.img.replace(/ /g,"%20") : "";
  const imgSrc = image
  // const type = charger.form_factor ? charger.form_factor : ""
  const length = charger.cord_length ? charger.cord_length : 0
  const socket = charger.home_outlet ? charger.home_outlet : ""
  const amps = charger.amps ? charger.amps : 0
  const plug = charger.plug ? charger.plug : ""

  let altText = `${make} ${model}`

  const renderChargerDetails = (
    <div className="col renderRowOfData">
      <div className="charger-row">
        <img src={typeIcon} alt="wrench"/>
        <p>
          {socket === "Hardwired connection" ? intl.formatMessage({ id: "homeChargers.hardwired", defaultMessage: "Hardwired"}) : socket}
        </p>
      </div>
      <div className="charger-row">
        <img src={lightning} alt="lightning"/>
        <p>
          {`${amps} ${intl.formatMessage({ id: "maximum", defaultMessage: "maximum"})}`}
        </p>
      </div>
      <div className="charger-row">
        <img src={cordLengthIcon} alt="double-sided arrow"/>
        <p>
          <FormattedMessage 
            id="homeChargers.cable"
            defaultMessage="{length} ft. cord"
            description="cable"
            values= {{
              length: length
            }}
          />
        </p>
      </div>
      <div className="charger-row">
        <img src={socketIcon} alt="plug"/>
        <p>
          {`${plug} ${intl.formatMessage({ id: "homeChargers.socket", defaultMessage: "plug"})}`}
        </p>
      </div>
      <div className="charger-row">
        <img src={wifiIcon} alt="Wireless Charging is Enabled"/>
        <p>
          {intl.formatMessage({ id: "wifiEnabled", defaultMessage: "Wi-Fi enabled"})}
        </p>
      </div>
    </div>
  )

  return (
    <>
      <div className="ChargerCardTop">
        <div className="text-center">        
          <p className="h2">
            {make} {model}  
          </p>
        </div>
        <div className="charger-columns">
          {renderChargerDetails}
          <div className="col charger-image text-center">
            <img src={imgSrc} alt={altText} className="img-fluid" />
          </div>
          <div className="clearfix"></div>
          </div>
          <div className="charger-linkout">
            <a
              href={`/${charger.charger_id}.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >            
              <button className="btn btn-ae btn-xcel">
                {intl.formatMessage({ id: "details", defaultMessage: "Details"})}
              </button>
            </a>
          </div>
      </div>
    </>
  );
};

export default ChargerCard;

ChargerCard.propTypes = {
    charger: PropTypes.object,
    selectedVehicle: PropTypes.object
};
