import { times } from "lodash";
import { CO_RESIDENTIAL_ID } from '../../constants/';

// Source: https://www.xcelenergy.com/staticfiles/xe-responsive/Company/Rates%20&%20Regulations/Regulatory%20Filings/CO%20Recent%20Filings/PSCo_Electric_Entire_Tariff.pdf

const co_regular_residential = {
  name: CO_RESIDENTIAL_ID,
  title: "Regular Residential",
  separatelyMetered: false,
  isBundled: false,
  includeHomeWiringEstimator: true,
  upfrontChargerCost: 999,
  monthlyDiscountForOptimizingCharge: -4.17,
  rateElements: [
    {
      name: "Basic Service Charge",
      rateElementType: "FixedPerMonth",
      rateComponents: [
        {
          charge: times(12, (_) => 7.77),
          name: "Per Month",
        },
      ],
    },
    {
      name: "Energy Charge",
      rateElementType: "EnergyTimeOfUse",
      rateComponents: [
        {
          charge: 0.06237,
          months: [5, 6, 7, 8],
          name: "June through September",
        },
        {
          charge: 0.05461,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: "October through May",
        },
      ],
    },
    {
      name: "Tiered Energy Charges",
      rateElementType: "BlockedTiersInMonths",
      rateComponents: [
        {
          charge: 0.05461,
          min: times(12, (_) => 0),
          max: times(12, (_) => 500),
          name: "First 500 kWh",
        },
        {
          charge: 0.09902,
          min: times(12, (_) => 500),
          max: times(12, (_) => Infinity),
          name: "All over 500 kWh",
        },
      ],
    },
  ],
};

export default co_regular_residential;
