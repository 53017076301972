const ElectricityCost = {
  perHundredElectricMiles: function(
    vehicle,
    inputElectricityCostInDollarsPerKwh,
    electricityRateInDollarsPerKWH
  ) {
    let fuelType = vehicle && vehicle.fuel ? vehicle.fuel : "";

    if (!["BEV", "PHEV"].includes(fuelType.toUpperCase())) return 0;

    const electricityCostInDollarsPerKwh =
      inputElectricityCostInDollarsPerKwh &&
      parseFloat(inputElectricityCostInDollarsPerKwh) > 0
        ? inputElectricityCostInDollarsPerKwh
        : electricityRateInDollarsPerKWH;

    const vehicleKwhPerMile =
      vehicle.electric_efficiency && parseFloat(vehicle.electric_efficiency) > 0
        ? vehicle.electric_efficiency
        : 0;

    let cost = electricityCostInDollarsPerKwh * vehicleKwhPerMile;

    return isFinite(cost) ? cost : 0;
  },

  perMile: function(
    vehicle,
    inputElectricityCostInDollarsPerKwh,
    electricMilesPortionForPhev
  ) {
    let fuelType = vehicle && vehicle.fuel ? vehicle.fuel : "";

    if (!["BEV", "PHEV"].includes(fuelType.toUpperCase())) return 0;

    const PHEVFuelSplitAdjustment =
      ["PHEV"].includes(fuelType.toUpperCase()) &&
      parseFloat(electricMilesPortionForPhev) >= 0
        ? electricMilesPortionForPhev / 100
        : 1;

    let cost =
      (this.perHundredElectricMiles(
        vehicle,
        inputElectricityCostInDollarsPerKwh
      ) *
        PHEVFuelSplitAdjustment) /
      100;

    return isFinite(cost) ? cost : 0;
  }
};

export default ElectricityCost;
