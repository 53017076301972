const filterChargers = (chargers, filterParams) => {
    return chargers.filter(charger => {
      let keep = true;
      filterParams = filterParams || {};
  
    //  Filterting on SOCKET 
      if (filterParams.chargerFormFactorFilters) {
        let allowableFilterKeys = new Set()
        let socketTypes = charger.home_outlet.replace(/\s/g, '').split(";")
        Object.keys(filterParams.chargerFormFactorFilters).forEach(key => {
          if (filterParams.chargerFormFactorFilters[key])
            allowableFilterKeys.add(key);
        });
  
        if (allowableFilterKeys.size) {
            for (const socket of socketTypes) {
                if (!allowableFilterKeys.has(socket)) {
                    keep = false
                }
            }
        }
      }
  
      if (filterParams.chargerTypeFilters) {
        let allowableFilterKeys = [];
        let apiChargerTypeHash = {"Wall mounted": "mounted", "Portable" : "portable"}
        Object.keys(filterParams.chargerTypeFilters).forEach(key => {
          if (filterParams.chargerTypeFilters[key])
            allowableFilterKeys.push(key);
        });
  
        if (
          allowableFilterKeys.length &&
          !allowableFilterKeys.includes(apiChargerTypeHash[charger.form_factor])
        )
          keep = false;
      }

      if (filterParams.chargerWifi) {
        let allowableFilterKeys = [];
        Object.keys(filterParams.chargerWifi).forEach(key => {
          if (filterParams.chargerWifi[key])
            allowableFilterKeys.push(key);
        });
        
        if(filterParams.chargerWifi['yes']) {
          allowableFilterKeys.push(true);
        } 
        if(filterParams.chargerWifi['no']) {
          allowableFilterKeys.push(false);
        } 
  
        if (
          allowableFilterKeys.length &&
          !allowableFilterKeys.includes(charger.wifi)
        )
          keep = false;
      }

      if (filterParams.chargerBudget && filterParams.chargerBudget < charger.price) {
          keep = false;
      }

      if (filterParams.chargerCordLength && filterParams.chargerCordLength < charger.cord_length) {
        keep = false;
    }

  
      return keep;
    });
  };
  
  export default filterChargers;
  