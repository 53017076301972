const colorado = {
  year: 2019,
  data: [
    1.021214177,
    0.951856529,
    0.906657995,
    0.886201281,
    0.879283174,
    0.881661015,
    0.924795904,
    0.944445666,
    0.958641309,
    0.977375708,
    0.988375279,
    0.98025894,
    1.001962962,
    0.979023375,
    1.02421741,
    1.073105795,
    1.190217742,
    1.375321711,
    1.422876368,
    1.38703228,
    1.339396224,
    1.245589608,
    1.067959796,
    0.992071537,
    0.862175607,
    0.839870614,
    0.814154621,
    0.812950773,
    0.832744702,
    0.873104326,
    0.949975903,
    0.973828297,
    0.894497658,
    0.840151419,
    0.810746259,
    0.791126137,
    0.785690494,
    0.765300858,
    0.783381628,
    0.839132569,
    0.988144039,
    1.236777844,
    1.346805667,
    1.348910714,
    1.330889393,
    1.23837364,
    1.053815503,
    0.959646846,
    0.893458982,
    0.859594597,
    0.832593337,
    0.829087401,
    0.847701704,
    0.881711942,
    0.958075398,
    0.975306066,
    0.898522582,
    0.843866047,
    0.807620796,
    0.786833006,
    0.782155748,
    0.755322961,
    0.770283038,
    0.823212377,
    0.976964483,
    1.223488524,
    1.322932423,
    1.330663529,
    1.326867668,
    1.240531457,
    1.063158919,
    0.963058312,
    0.885873119,
    0.849964546,
    0.820758817,
    0.816778973,
    0.834161146,
    0.867413887,
    0.940582661,
    0.963939751,
    0.898281284,
    0.851491155,
    0.815616638,
    0.790230644,
    0.787894099,
    0.762860195,
    0.777219274,
    0.830587786,
    0.972537815,
    1.196457793,
    1.275376953,
    1.269150086,
    1.256013551,
    1.190159258,
    1.060262978,
    0.993236037,
    0.937321424,
    0.903247604,
    0.866479741,
    0.86037588,
    0.87589682,
    0.889170242,
    0.94621293,
    1.026922822,
    1.075203494,
    1.098648643,
    1.097097241,
    1.087151656,
    1.079125248,
    1.06300007,
    1.074819407,
    1.115803742,
    1.210985398,
    1.374171394,
    1.399791834,
    1.362260091,
    1.333095431,
    1.252123046,
    1.113347994,
    1.032751172,
    0.957012223,
    0.910885331,
    0.873854875,
    0.868859548,
    0.882402685,
    0.897356822,
    0.956846559,
    1.048755496,
    1.116774308,
    1.151739317,
    1.166259093,
    1.169331945,
    1.172236594,
    1.154576744,
    1.162944339,
    1.199874548,
    1.304295006,
    1.482860403,
    1.517446954,
    1.485427926,
    1.450558793,
    1.338946516,
    1.126685623,
    0.999564125,
    0.892321064,
    0.835682555,
    0.806693195,
    0.797048389,
    0.815639773,
    0.853659739,
    0.933475516,
    0.954393062,
    0.872544458,
    0.819428515,
    0.792563239,
    0.775861069,
    0.765957283,
    0.74179382,
    0.759094593,
    0.811312997,
    0.96662681,
    1.228823828,
    1.343978575,
    1.347979387,
    1.322405142,
    1.227426436,
    1.036484045,
    0.933714582,
    0.855819471,
    0.823065077,
    0.80002417,
    0.796598648,
    0.815671435,
    0.851248306,
    0.926100705,
    0.939158888,
    0.85177347,
    0.784173893,
    0.748797527,
    0.72661395,
    0.723103401,
    0.701411596,
    0.719933257,
    0.774832599,
    0.930184321,
    1.18920714,
    1.305293683,
    1.30609026,
    1.289094124,
    1.19744946,
    1.012504791,
    0.920738838,
    0.863144607,
    0.839870614,
    0.814154621,
    0.812950773,
    0.832744702,
    0.873104326,
    0.949975903,
    0.973879061,
    0.894497658,
    0.840151419,
    0.810746259,
    0.791126137,
    0.785690494,
    0.765300858,
    0.783381628,
    0.839132569,
    0.98955026,
    1.236777844,
    1.346805667,
    1.348910714,
    1.330889393,
    1.23837364,
    1.053815503,
    0.95834164,
    0.88533818,
    0.85247301,
    0.827468524,
    0.825032935,
    0.846075397,
    0.888553447,
    0.970390483,
    0.995882511,
    0.924935041,
    0.876069098,
    0.848985537,
    0.82965122,
    0.823196613,
    0.796848697,
    0.806230912,
    0.852306512,
    0.992915023,
    1.233010893,
    1.343448283,
    1.34328939,
    1.332029193,
    1.244608618,
    1.067081478,
    0.961673861,
    0.871557137,
    0.828248894,
    0.801539217,
    0.797620901,
    0.816678728,
    0.857176827,
    0.933475397,
    0.959807899,
    0.892694529,
    0.844547037,
    0.81286188,
    0.786639323,
    0.782826631,
    0.760300578,
    0.772967628,
    0.821288359,
    0.951376811,
    1.171328111,
    1.264438477,
    1.251223538,
    1.230581056,
    1.165653346,
    1.03829934,
    0.96361339,
    0.887263572,
    0.845047861,
    0.811677619,
    0.802746693,
    0.817753601,
    0.834808956,
    0.888317082,
    0.958495609,
    0.9854285,
    0.987341732,
    0.97388703,
    0.954832739,
    0.944995794,
    0.937091498,
    0.959792498,
    1.001054943,
    1.091018004,
    1.260009862,
    1.308409214,
    1.266679615,
    1.230558888,
    1.155894532,
    1.026836703,
    0.960796461,
    0.902698025,
    0.871401295,
    0.83730564,
    0.83110971,
    0.845625502,
    0.866869391,
    0.926839403,
    1.015716065,
    1.072360649,
    1.096708285,
    1.10730393,
    1.105737092,
    1.106426285,
    1.094705438,
    1.108217401,
    1.141819852,
    1.236315935,
    1.416156176,
    1.470217004,
    1.432692369,
    1.390014814,
    1.28117636,
    1.074284144,
    0.961101404,
    0.877037267,
    0.832676393,
    0.806021653,
    0.798535359,
    0.820858972,
    0.869071627,
    0.956423954,
    0.988292643,
    0.914950148,
    0.870929571,
    0.855929739,
    0.842248576,
    0.829789884,
    0.805114524,
    0.81476117,
    0.858440319,
    0.998550884,
    1.252403562,
    1.379489817,
    1.374724829,
    1.339812712,
    1.241995313,
    1.049588238,
    0.940792708,
    0.848186685,
    0.806066597,
    0.785717272,
    0.783305015,
    0.805441231,
    0.850190714,
    0.930404726,
    0.949370832,
    0.863438188,
    0.797948305,
    0.76962928,
    0.748205677,
    0.742433202,
    0.722205248,
    0.736770789,
    0.78501647,
    0.926661755,
    1.179601148,
    1.310787901,
    1.303883153,
    1.277644779,
    1.185038409,
    1.001102807,
    0.904945733,
    0.833080951,
    0.803375663,
    0.78097085,
    0.779421582,
    0.801176479,
    0.848920505,
    0.927902092,
    0.95053493,
    0.862868278,
    0.80110947,
    0.772205766,
    0.750479247,
    0.743267269,
    0.727016038,
    0.746129135,
    0.797733285,
    0.936365013,
    1.180777733,
    1.310007751,
    1.305912366,
    1.278896173,
    1.188259196,
    1.008259549,
    0.925219179,
    0.875480218,
    0.852711108,
    0.830014164,
    0.830243559,
    0.855951496,
    0.909862716,
    1.000846408,
    1.039302752,
    0.97884667,
    0.941874154,
    0.928916265,
    0.914251128,
    0.904631192,
    0.87690832,
    0.876677131,
    0.912899315,
    1.036535575,
    1.267047674,
    1.390089829,
    1.380797845,
    1.358953244,
    1.267353297,
    1.087527804,
    0.974757554,
    0.866803511,
    0.812165824,
    0.788312538,
    0.785868717,
    0.808679514,
    0.859172763,
    0.941885457,
    0.975064073,
    0.91003098,
    0.865291936,
    0.84179895,
    0.817290552,
    0.810671503,
    0.789291476,
    0.797090052,
    0.83780187,
    0.952563875,
    1.166134334,
    1.275121706,
    1.253854314,
    1.222698838,
    1.155838469,
    1.028998418,
    0.951627739,
    0.865485511,
    0.817171145,
    0.786867091,
    0.778490075,
    0.796460835,
    0.82171731,
    0.879173105,
    0.950718098,
    0.972405359,
    0.971593861,
    0.960688121,
    0.941598409,
    0.929041633,
    0.923593269,
    0.945083091,
    0.980510262,
    1.057134043,
    1.222809302,
    1.289671552,
    1.242731743,
    1.19726803,
    1.122402902,
    0.996359495,
    0.939226761,
    0.897350028,
    0.870775595,
    0.837620184,
    0.836256502,
    0.853816974,
    0.882602033,
    0.939017145,
    1.045885903,
    1.130864804,
    1.181644757,
    1.215587812,
    1.223020791,
    1.224757716,
    1.213273047,
    1.210069809,
    1.234151253,
    1.307513683,
    1.467912552,
    1.526701552,
    1.477168553,
    1.423622671,
    1.304135752,
    1.094488206,
    0.978027631,
    0.897508007,
    0.841740925,
    0.811059284,
    0.817315494,
    0.837446152,
    0.884456148,
    0.921908139,
    1.002326026,
    1.031262627,
    1.064533413,
    1.110439292,
    1.105918163,
    1.109831072,
    1.101128914,
    1.068745153,
    1.104899766,
    1.223363937,
    1.425196824,
    1.529673859,
    1.486192239,
    1.43333623,
    1.298611979,
    1.097199938,
    0.980523044,
    0.879408124,
    0.819299637,
    0.799884841,
    0.801006073,
    0.828287993,
    0.881203571,
    0.970779683,
    1.001688789,
    0.927394449,
    0.876547363,
    0.859262339,
    0.844315629,
    0.835503244,
    0.810346424,
    0.813986983,
    0.853444266,
    0.982695181,
    1.224199843,
    1.364899548,
    1.358405577,
    1.325262408,
    1.22549505,
    1.037965412,
    0.938219747,
    0.858606757,
    0.819342258,
    0.797593307,
    0.799646955,
    0.826560813,
    0.881223794,
    0.969408947,
    1.003254292,
    0.927333339,
    0.880473794,
    0.861626308,
    0.84690409,
    0.836932544,
    0.814581604,
    0.822557057,
    0.865869419,
    0.993416347,
    1.22597543,
    1.364107335,
    1.363110358,
    1.330417565,
    1.231946035,
    1.048077988,
    0.946287568,
    0.85161535,
    0.807199405,
    0.786628042,
    0.785595138,
    0.812191956,
    0.865398431,
    0.953639879,
    0.978289827,
    0.896967078,
    0.842180486,
    0.814951132,
    0.796448309,
    0.786386438,
    0.761139947,
    0.767730751,
    0.805598954,
    0.927972014,
    1.157360913,
    1.300685901,
    1.302277562,
    1.278205535,
    1.188278287,
    1.016361715,
    0.917965198,
    0.83162367,
    0.791329683,
    0.768623499,
    0.766804419,
    0.791980014,
    0.842868334,
    0.926631493,
    0.95367051,
    0.878826568,
    0.828122318,
    0.797428648,
    0.773679466,
    0.766108829,
    0.742838711,
    0.750708127,
    0.790222999,
    0.902587403,
    1.110247009,
    1.234834582,
    1.22567722,
    1.192815167,
    1.123441399,
    0.999928377,
    0.92997563,
    0.853562557,
    0.811578158,
    0.781940102,
    0.775475891,
    0.796868291,
    0.823692981,
    0.884895942,
    0.95472155,
    0.972456723,
    0.973223026,
    0.960703634,
    0.94539083,
    0.931524136,
    0.921450895,
    0.937617405,
    0.970189978,
    1.043293418,
    1.200621868,
    1.281358079,
    1.24677851,
    1.199582563,
    1.119714076,
    0.994217791,
    0.92798437,
    0.859922839,
    0.82431244,
    0.794037763,
    0.789203331,
    0.809263965,
    0.837512142,
    0.901917524,
    0.98311413,
    1.020401216,
    1.034215579,
    1.037375312,
    1.03606652,
    1.033075151,
    1.021141922,
    1.03329992,
    1.06190655,
    1.144419216,
    1.316767804,
    1.406722157,
    1.380359565,
    1.328079668,
    1.215931148,
    1.0160594,
    0.910142041,
    0.828185861,
    0.78811461,
    0.765051837,
    0.759309994,
    0.78734776,
    0.841583772,
    0.933627188,
    0.958682929,
    0.869816259,
    0.816894743,
    0.794354647,
    0.782279624,
    0.766756775,
    0.740653584,
    0.748864695,
    0.787077068,
    0.915106634,
    1.15720938,
    1.31724014,
    1.325943286,
    1.282105606,
    1.180003682,
    0.993952567,
    0.898393043,
    0.823156054,
    0.7902433,
    0.772244575,
    0.77369449,
    0.803430667,
    0.855962123,
    0.94557342,
    0.966973498,
    0.878394871,
    0.817627436,
    0.789837451,
    0.774837202,
    0.765126872,
    0.738865474,
    0.744755022,
    0.784132553,
    0.912062974,
    1.147950384,
    1.306634035,
    1.313989138,
    1.278874166,
    1.177138129,
    0.994589515,
    0.901777107,
    0.827929014,
    0.795508065,
    0.775046963,
    0.777927306,
    0.807757747,
    0.862692653,
    0.95199698,
    0.978304912,
    0.890559947,
    0.836468815,
    0.808877221,
    0.795022225,
    0.78379734,
    0.75961908,
    0.768256,
    0.810617019,
    0.936320235,
    1.162477929,
    1.317816296,
    1.330333242,
    1.295311315,
    1.193853241,
    1.013940208,
    0.916495573,
    0.821940126,
    0.78152954,
    0.762333683,
    0.762089288,
    0.791688698,
    0.845117158,
    0.934365807,
    0.95090339,
    0.856977763,
    0.794295706,
    0.757940585,
    0.74034672,
    0.729035114,
    0.701994362,
    0.7097434,
    0.746764397,
    0.867170803,
    1.090109651,
    1.251076659,
    1.266531085,
    1.239971529,
    1.147082331,
    0.979334252,
    0.856478521,
    0.722824687,
    0.658786627,
    0.630923018,
    0.635314808,
    0.678691689,
    0.763273719,
    0.898118261,
    0.933277473,
    0.844276349,
    0.781054617,
    0.736045411,
    0.708190755,
    0.692553056,
    0.664172695,
    0.669070116,
    0.707604484,
    0.826644769,
    1.064697612,
    1.224677051,
    1.2158094,
    1.154628894,
    1.048990163,
    0.881373712,
    0.812291698,
    0.733336377,
    0.692922433,
    0.661879423,
    0.65467697,
    0.677097488,
    0.724764577,
    0.811840857,
    0.903800938,
    0.931715906,
    0.93857966,
    0.925945556,
    0.905738021,
    0.883011228,
    0.887051168,
    0.908256833,
    0.943117604,
    1.020727981,
    1.207329986,
    1.314125892,
    1.24385451,
    1.173772632,
    1.080661174,
    0.915252775,
    0.821638933,
    0.728855459,
    0.677590763,
    0.646203179,
    0.638664758,
    0.656351442,
    0.699669968,
    0.782105935,
    0.875140845,
    0.911851557,
    0.920532464,
    0.919139845,
    0.914634983,
    0.90761385,
    0.917617388,
    0.945071637,
    0.981532737,
    1.077221268,
    1.291642655,
    1.416787884,
    1.357688682,
    1.279178216,
    1.145303554,
    0.89433614,
    0.773082997,
    0.684163859,
    0.641997629,
    0.623640664,
    0.622682487,
    0.662900621,
    0.755879399,
    0.898690741,
    0.94491003,
    0.845327571,
    0.78544646,
    0.758852888,
    0.742168136,
    0.722944375,
    0.721272453,
    0.734932038,
    0.776479219,
    0.908736702,
    1.179212589,
    1.375091467,
    1.351362494,
    1.276359037,
    1.146355726,
    0.899624326,
    0.780025987,
    0.685063763,
    0.643486386,
    0.627613176,
    0.63099486,
    0.671035636,
    0.761799195,
    0.900384622,
    0.93649605,
    0.828537875,
    0.752226445,
    0.71604077,
    0.694433579,
    0.681438431,
    0.681647994,
    0.696053905,
    0.741033046,
    0.873554493,
    1.136231813,
    1.330986291,
    1.307961704,
    1.243759745,
    1.117481547,
    0.879611367,
    0.765985626,
    0.675688616,
    0.638119403,
    0.619854561,
    0.623502028,
    0.662414703,
    0.75361281,
    0.889079239,
    0.923445454,
    0.809366308,
    0.733652268,
    0.692607767,
    0.670023556,
    0.656398791,
    0.66062197,
    0.682124792,
    0.732631583,
    0.864262153,
    1.116582897,
    1.310284708,
    1.294081705,
    1.231194377,
    1.106686474,
    0.873564033,
    0.762526999,
    0.66689087,
    0.628705906,
    0.610667046,
    0.611969002,
    0.650998345,
    0.741861094,
    0.878134975,
    0.904126665,
    0.787139769,
    0.706240427,
    0.65970411,
    0.635541809,
    0.621539702,
    0.622704052,
    0.640273634,
    0.682994797,
    0.804889796,
    1.046849555,
    1.244327844,
    1.231048791,
    1.177012524,
    1.062302308,
    0.843280776,
    0.743327387,
    0.668573317,
    0.638921632,
    0.618901493,
    0.621526328,
    0.661017854,
    0.750414834,
    0.885327651,
    0.924054413,
    0.828782844,
    0.764644928,
    0.721155618,
    0.694085203,
    0.679288699,
    0.675732321,
    0.685437569,
    0.723953799,
    0.83219164,
    1.04614537,
    1.217273119,
    1.189870145,
    1.126699049,
    1.036513037,
    0.873483715,
    0.788940644,
    0.703378379,
    0.65793294,
    0.628200062,
    0.623936092,
    0.651664139,
    0.701222278,
    0.791616024,
    0.870832931,
    0.881159939,
    0.877725658,
    0.855802308,
    0.838728851,
    0.814366254,
    0.814966395,
    0.83401457,
    0.868131744,
    0.943140399,
    1.117213669,
    1.252997944,
    1.201844493,
    1.125029174,
    1.025145346,
    0.865168893,
    0.787076516,
    0.712110564,
    0.672492247,
    0.641970458,
    0.639077879,
    0.66334122,
    0.710777122,
    0.800905065,
    0.892673794,
    0.927921367,
    0.940989345,
    0.939676575,
    0.941204138,
    0.930420039,
    0.929871619,
    0.944873293,
    0.976676919,
    1.068638793,
    1.269045752,
    1.418917324,
    1.377599253,
    1.294509071,
    1.151771802,
    0.902030536,
    0.784818501,
    0.69981369,
    0.657062634,
    0.638959292,
    0.643302457,
    0.690342576,
    0.78881649,
    0.941897498,
    0.995486228,
    0.904649693,
    0.856558713,
    0.833605676,
    0.823212631,
    0.799795663,
    0.785649376,
    0.786101587,
    0.820014655,
    0.94839919,
    1.204760192,
    1.418162549,
    1.411259051,
    1.333996857,
    1.193615842,
    0.943975901,
    0.823787684,
    0.729624477,
    0.682377308,
    0.665880892,
    0.675394522,
    0.722695083,
    0.820506408,
    0.972365007,
    1.02450589,
    0.935746199,
    0.88035986,
    0.853497808,
    0.839269571,
    0.820886621,
    0.804413299,
    0.800055132,
    0.834751099,
    0.964174276,
    1.213562009,
    1.421261851,
    1.414546093,
    1.350712102,
    1.212752995,
    0.967782924,
    0.832704463,
    0.709792595,
    0.647993123,
    0.628088518,
    0.634309333,
    0.67645406,
    0.770381657,
    0.912421579,
    0.951085456,
    0.84310026,
    0.775027635,
    0.735576252,
    0.716465264,
    0.701259384,
    0.695987599,
    0.70879028,
    0.752656051,
    0.881515433,
    1.119388067,
    1.322438159,
    1.323591008,
    1.26323702,
    1.13273807,
    0.898881453,
    0.785997374,
    0.687634912,
    0.643970416,
    0.623422594,
    0.626699068,
    0.667810791,
    0.760901975,
    0.903249728,
    0.935102747,
    0.82644108,
    0.754524476,
    0.710614636,
    0.689286628,
    0.674299259,
    0.665807022,
    0.674556838,
    0.710187326,
    0.830062828,
    1.057704803,
    1.260187708,
    1.26425851,
    1.215216809,
    1.095684892,
    0.875422363,
    0.771784058,
    0.691822313,
    0.655277233,
    0.632016642,
    0.635637654,
    0.675695099,
    0.766291183,
    0.906057049,
    0.950703317,
    0.864587754,
    0.809544321,
    0.769296279,
    0.74431235,
    0.729573923,
    0.716395198,
    0.71720811,
    0.748512374,
    0.855194453,
    1.054449326,
    1.226665313,
    1.216740308,
    1.161216582,
    1.068065017,
    0.904285092,
    0.81835195,
    0.73333063,
    0.683334289,
    0.649499912,
    0.645879599,
    0.67327334,
    0.723646591,
    0.818960778,
    0.907806114,
    0.932886563,
    0.943709109,
    0.928939275,
    0.914489237,
    0.890802903,
    0.87943225,
    0.884727435,
    0.91031614,
    0.983352872,
    1.140861233,
    1.273793088,
    1.240340836,
    1.174192135,
    1.072100209,
    0.910210588,
    0.810667545,
    0.702403272,
    0.641160148,
    0.607157226,
    0.60070956,
    0.616263105,
    0.655504145,
    0.730478198,
    0.815837006,
    0.860172773,
    0.868785852,
    0.865574152,
    0.866133456,
    0.857858647,
    0.857963698,
    0.886204136,
    0.920758018,
    1.006570704,
    1.190035598,
    1.32941645,
    1.300504494,
    1.229203605,
    1.08798086,
    0.843137014,
    0.723682804,
    0.620604029,
    0.575454513,
    0.558443521,
    0.558266147,
    0.580136595,
    0.655529041,
    0.748233802,
    0.804641531,
    0.803087108,
    0.767630323,
    0.767448759,
    0.761914868,
    0.72591923,
    0.716587492,
    0.772530746,
    0.826498057,
    0.916720046,
    1.154639396,
    1.320562355,
    1.296516376,
    1.234806889,
    1.086843267,
    0.834117367,
    0.732266065,
    0.668737645,
    0.649706794,
    0.628411296,
    0.633384584,
    0.675067501,
    0.767274827,
    0.912922684,
    0.951991062,
    0.849979712,
    0.78200001,
    0.745811924,
    0.72779275,
    0.715403999,
    0.70093788,
    0.703836521,
    0.737240706,
    0.865505129,
    1.09785876,
    1.301395804,
    1.313989911,
    1.260785197,
    1.128860314,
    0.89362607,
    0.780978269,
    0.690565275,
    0.6484365,
    0.623929822,
    0.628383913,
    0.667646618,
    0.759593915,
    0.901418347,
    0.93976764,
    0.833507061,
    0.766909484,
    0.726725419,
    0.707008523,
    0.694802602,
    0.684191064,
    0.694380829,
    0.732938278,
    0.860970295,
    1.083039266,
    1.281023777,
    1.30039829,
    1.251196143,
    1.122399416,
    0.89164036,
    0.789679189,
    0.710631859,
    0.674862224,
    0.649807539,
    0.653825326,
    0.694507425,
    0.78888209,
    0.936524898,
    0.97964842,
    0.886149961,
    0.82827347,
    0.791109467,
    0.77184102,
    0.757269927,
    0.736845759,
    0.73420275,
    0.761049678,
    0.880502407,
    1.093478869,
    1.290400292,
    1.31186622,
    1.274397663,
    1.15256339,
    0.929386763,
    0.826417124,
    0.753654117,
    0.714365566,
    0.68690363,
    0.693644706,
    0.735626207,
    0.830531849,
    0.980911617,
    1.047970146,
    0.991022235,
    0.963608458,
    0.938928686,
    0.919440704,
    0.902829605,
    0.870288976,
    0.848878291,
    0.867445125,
    0.973111963,
    1.157406711,
    1.323736485,
    1.330515978,
    1.287007006,
    1.187965525,
    1.016277787,
    0.908167811,
    0.797651797,
    0.72287433,
    0.684527874,
    0.682354136,
    0.709941063,
    0.762218391,
    0.864607884,
    0.968792328,
    1.016498096,
    1.049146841,
    1.046942842,
    1.037158287,
    1.013627441,
    0.986211323,
    0.97177896,
    0.986445467,
    1.056930741,
    1.196741181,
    1.323563546,
    1.30749241,
    1.253570341,
    1.148188297,
    0.981895593,
    0.868815176,
    0.74850097,
    0.672309921,
    0.632382402,
    0.625768831,
    0.64398416,
    0.686829651,
    0.776588284,
    0.866353787,
    0.902988469,
    0.917995526,
    0.915394568,
    0.915140925,
    0.911998661,
    0.90124922,
    0.907372273,
    0.929302828,
    1.020261604,
    1.187186071,
    1.329843312,
    1.327112952,
    1.267483728,
    1.128056028,
    0.884391,
    0.777457258,
    0.70718424,
    0.670867129,
    0.643579092,
    0.645416974,
    0.68764888,
    0.783450964,
    0.938393794,
    0.996230708,
    0.914653988,
    0.875042203,
    0.854234565,
    0.84378228,
    0.825667635,
    0.797633071,
    0.788433409,
    0.810194266,
    0.937228714,
    1.161897471,
    1.364582912,
    1.394155297,
    1.34000917,
    1.201446795,
    0.955336959,
    0.839777862,
    0.75463389,
    0.707887182,
    0.682101729,
    0.689973325,
    0.733228889,
    0.8295054,
    0.98534808,
    1.046185441,
    0.972050883,
    0.930352815,
    0.908978649,
    0.89592873,
    0.881949332,
    0.848751172,
    0.830804738,
    0.851835542,
    0.980018745,
    1.197967384,
    1.394266462,
    1.423660339,
    1.383271703,
    1.245774259,
    1.002161012,
    0.869067333,
    0.754837026,
    0.689945144,
    0.660721502,
    0.666563378,
    0.705935372,
    0.800094237,
    0.948967017,
    1.002554332,
    0.916336003,
    0.868263514,
    0.838000652,
    0.821291793,
    0.809021277,
    0.783749989,
    0.779265127,
    0.807742374,
    0.935662377,
    1.143003888,
    1.333740923,
    1.370349971,
    1.333788417,
    1.201707031,
    0.966005476,
    0.846872193,
    0.745106174,
    0.690458521,
    0.660393317,
    0.663579141,
    0.702122903,
    0.795889644,
    0.945728529,
    0.994098245,
    0.90892921,
    0.858705513,
    0.824874128,
    0.806172705,
    0.793759029,
    0.764566556,
    0.755044382,
    0.774686423,
    0.893899483,
    1.091531509,
    1.281025947,
    1.320309028,
    1.295109087,
    1.173577326,
    0.950655312,
    0.871500068,
    0.78684325,
    0.769716852,
    0.741261731,
    0.737349252,
    0.757251472,
    0.811763293,
    0.90298568,
    0.930821125,
    0.852495216,
    0.809238371,
    0.776694048,
    0.756259985,
    0.75291631,
    0.732040574,
    0.729367509,
    0.75007026,
    0.849410721,
    1.003252932,
    1.141569814,
    1.171410928,
    1.155274175,
    1.095713908,
    0.984445073,
    0.913174066,
    0.849594165,
    0.8037529,
    0.76733941,
    0.759213998,
    0.775428679,
    0.807145877,
    0.879455775,
    0.957350724,
    0.97931148,
    0.994184514,
    0.98601347,
    0.977190053,
    0.96890384,
    0.957731296,
    0.958402117,
    0.969761786,
    1.02726206,
    1.126788572,
    1.214687784,
    1.21814366,
    1.188681937,
    1.116783139,
    1.000714447,
    0.91179328,
    0.799874359,
    0.749923225,
    0.726493945,
    0.723386158,
    0.738802827,
    0.776969414,
    0.8411472,
    0.909989187,
    0.941989994,
    0.944038604,
    0.938905723,
    0.934114464,
    0.920337596,
    0.923510984,
    0.930774938,
    0.950924076,
    0.991697156,
    1.108084707,
    1.214146569,
    1.24007292,
    1.213069496,
    1.128525557,
    0.940179473,
    0.833865747,
    0.746774152,
    0.711377749,
    0.694284674,
    0.688825349,
    0.709796822,
    0.771864753,
    0.860604995,
    0.868667314,
    0.771568074,
    0.708124296,
    0.680610522,
    0.66700756,
    0.641108288,
    0.632238822,
    0.631842751,
    0.661281065,
    0.74912755,
    0.931078149,
    1.10731496,
    1.169244299,
    1.153255239,
    1.082478509,
    0.910772467,
    0.815384384,
    0.735689798,
    0.708210415,
    0.696145678,
    0.69720764,
    0.718662583,
    0.777784945,
    0.862227143,
    0.864358926,
    0.765978378,
    0.691915783,
    0.657812141,
    0.639878071,
    0.620973371,
    0.612502905,
    0.611494364,
    0.642584418,
    0.731710161,
    0.907328393,
    1.080637548,
    1.141243773,
    1.136370782,
    1.067770648,
    0.900908973,
    0.809219773,
    0.732561908,
    0.707276695,
    0.691991728,
    0.693328012,
    0.713225874,
    0.773648048,
    0.855597237,
    0.860102934,
    0.759268279,
    0.688305605,
    0.652049584,
    0.633240465,
    0.61353928,
    0.608571014,
    0.612750921,
    0.647528878,
    0.736243885,
    0.903281909,
    1.070964825,
    1.137691719,
    1.134796458,
    1.069315034,
    0.907242972,
    0.815875565,
    0.728456602,
    0.700108085,
    0.685508251,
    0.683275592,
    0.70217009,
    0.761172421,
    0.843622221,
    0.840246956,
    0.73720627,
    0.660427028,
    0.617985972,
    0.595793526,
    0.576429992,
    0.567384487,
    0.568962268,
    0.597266066,
    0.680158623,
    0.842013915,
    1.011487036,
    1.080542162,
    1.08884411,
    1.033010684,
    0.882934056,
    0.795059377,
    0.720868463,
    0.696058922,
    0.677954933,
    0.675224658,
    0.6923745,
    0.749769103,
    0.828786719,
    0.831959512,
    0.741032361,
    0.673485114,
    0.631128079,
    0.604468099,
    0.58785722,
    0.579066064,
    0.579771938,
    0.608200536,
    0.680957954,
    0.819964799,
    0.965174982,
    1.022177347,
    1.02203654,
    0.986893379,
    0.885147388,
    0.829578567,
    0.774080952,
    0.747900638,
    0.721070259,
    0.715206331,
    0.729899418,
    0.766131036,
    0.828751346,
    0.887389589,
    0.904957049,
    0.901168756,
    0.884842928,
    0.868176437,
    0.845228803,
    0.844611942,
    0.84879095,
    0.866386688,
    0.896638257,
    0.983874154,
    1.075499745,
    1.105058916,
    1.090657989,
    1.040227617,
    0.930497195,
    0.866627985,
    0.797285338,
    0.764065241,
    0.735689411,
    0.730864684,
    0.743369855,
    0.781077915,
    0.847472954,
    0.91893139,
    0.95813277,
    0.967515534,
    0.966960146,
    0.962959298,
    0.952020584,
    0.948396235,
    0.949614634,
    0.962774868,
    1.002919451,
    1.1071045,
    1.204320173,
    1.242997103,
    1.225767837,
    1.142141383,
    0.955009557,
    0.848556299,
    0.763552742,
    0.725066958,
    0.702994736,
    0.695767315,
    0.71387143,
    0.775601871,
    0.865918567,
    0.87647743,
    0.785679891,
    0.728019317,
    0.703297481,
    0.689744228,
    0.666046481,
    0.650716093,
    0.646995594,
    0.669910113,
    0.758035518,
    0.929255407,
    1.096833844,
    1.171048166,
    1.164496784,
    1.094855114,
    0.924718164,
    0.827594767,
    0.744500179,
    0.712539037,
    0.695820762,
    0.694355219,
    0.712204265,
    0.770141311,
    0.854359556,
    0.855973835,
    0.759980098,
    0.687665753,
    0.653766372,
    0.634603196,
    0.618383955,
    0.605045738,
    0.603186399,
    0.629092935,
    0.719538591,
    0.885624139,
    1.050972784,
    1.123877752,
    1.12894384,
    1.063070843,
    0.899324193,
    0.806419235,
    0.725780572,
    0.697629038,
    0.678201343,
    0.675920946,
    0.691107691,
    0.749189996,
    0.828116288,
    0.827996059,
    0.724247601,
    0.649600129,
    0.610309513,
    0.58855502,
    0.57244623,
    0.565138065,
    0.571975749,
    0.603534412,
    0.695243653,
    0.854662198,
    1.013856834,
    1.092713739,
    1.100624343,
    1.040269765,
    0.8834316,
    0.799314278,
    0.731589552,
    0.708889425,
    0.689363915,
    0.684594629,
    0.69965926,
    0.757869824,
    0.840358573,
    0.839803446,
    0.743495535,
    0.672248177,
    0.633196142,
    0.610293241,
    0.594454865,
    0.581358591,
    0.582214334,
    0.605295444,
    0.689967664,
    0.842844821,
    0.997247428,
    1.075489349,
    1.094048424,
    1.040126828,
    0.891531558,
    0.804441925,
    0.736310012,
    0.709138998,
    0.685955088,
    0.680871222,
    0.694242644,
    0.751207966,
    0.830760455,
    0.839215991,
    0.75829813,
    0.69927554,
    0.66271958,
    0.635939222,
    0.623312776,
    0.610709122,
    0.610067891,
    0.632966869,
    0.707399978,
    0.837190586,
    0.963467103,
    1.027894674,
    1.037931703,
    1.003944218,
    0.90257311,
    0.841540404,
    0.777688673,
    0.743351197,
    0.712008943,
    0.702272626,
    0.711715864,
    0.745760424,
    0.805519554,
    0.864325515,
    0.885301921,
    0.882755,
    0.867318292,
    0.84796889,
    0.830394119,
    0.829348126,
    0.836252849,
    0.850461217,
    0.884758726,
    0.964442565,
    1.03777618,
    1.074935631,
    1.071751942,
    1.025349903,
    0.918781235,
    0.858109814,
    0.799511417,
    0.767583023,
    0.734396425,
    0.726155986,
    0.733632518,
    0.769802305,
    0.834872272,
    0.910707163,
    0.959286146,
    0.97576477,
    0.980693431,
    0.976200013,
    0.97139509,
    0.966920854,
    0.968588217,
    0.977014131,
    1.019518313,
    1.114058881,
    1.188408974,
    1.232824631,
    1.226861109,
    1.145308759,
    0.958563772,
    0.851665122,
    0.772435137,
    0.730884844,
    0.703942843,
    0.69342242,
    0.70659602,
    0.767222113,
    0.856033946,
    0.871873476,
    0.791137681,
    0.741384085,
    0.723217958,
    0.708901436,
    0.690997025,
    0.674854234,
    0.672592555,
    0.691657538,
    0.78321759,
    0.947192754,
    1.088294082,
    1.166815831,
    1.171525811,
    1.103857033,
    0.933689143,
    0.839005232,
    0.772399462,
    0.739244984,
    0.716880121,
    0.713554263,
    0.727673141,
    0.786332591,
    0.872732314,
    0.887682326,
    0.812201459,
    0.758152505,
    0.737936153,
    0.720860948,
    0.71012992,
    0.693649095,
    0.68849847,
    0.707996422,
    0.800047048,
    0.956905459,
    1.089789321,
    1.16459808,
    1.18015638,
    1.112629068,
    0.944848742,
    0.85294962,
    0.79064359,
    0.757133394,
    0.731060188,
    0.729273916,
    0.742929311,
    0.804449784,
    0.891637404,
    0.916738296,
    0.849298182,
    0.808501058,
    0.793747124,
    0.779004592,
    0.767718834,
    0.752456875,
    0.74810942,
    0.769067424,
    0.858578114,
    1.004484529,
    1.12524996,
    1.203238039,
    1.22020754,
    1.152447766,
    0.985386246,
    0.884649361,
    0.793779069,
    0.746756062,
    0.721177396,
    0.715405055,
    0.727472101,
    0.787332903,
    0.873989594,
    0.890810098,
    0.820286717,
    0.772685692,
    0.751012106,
    0.731878755,
    0.721392355,
    0.703635281,
    0.698447587,
    0.713609472,
    0.798718558,
    0.940934223,
    1.060769822,
    1.13995144,
    1.16834535,
    1.110800048,
    0.956037309,
    0.856962808,
    0.773105175,
    0.728546314,
    0.700009511,
    0.692522979,
    0.701687439,
    0.758856846,
    0.838860194,
    0.858081831,
    0.794363927,
    0.750211706,
    0.725180765,
    0.699447817,
    0.69271823,
    0.678007945,
    0.675875916,
    0.693218359,
    0.769768769,
    0.890824681,
    0.98583233,
    1.052624452,
    1.073423975,
    1.039226972,
    0.935337696,
    0.86172839,
    0.77853146,
    0.729030922,
    0.69364486,
    0.679055617,
    0.682380638,
    0.714059139,
    0.767673712,
    0.826545654,
    0.850038715,
    0.846791786,
    0.831276098,
    0.807860406,
    0.797052255,
    0.799022445,
    0.812925799,
    0.825887703,
    0.866526354,
    0.941642791,
    0.990142363,
    1.031554882,
    1.040337373,
    0.999322181,
    0.896217801,
    0.8332273,
    0.773384379,
    0.739820984,
    0.704215967,
    0.690935833,
    0.693254549,
    0.726983681,
    0.784547822,
    0.858299466,
    0.904702316,
    0.915951258,
    0.916197927,
    0.906142519,
    0.90813895,
    0.910636606,
    0.923701292,
    0.933974542,
    0.984311,
    1.077788578,
    1.132656457,
    1.179856412,
    1.185007325,
    1.108325405,
    0.923749512,
    0.825798883,
    0.776587769,
    0.746373878,
    0.717167211,
    0.706157168,
    0.719026133,
    0.782072645,
    0.872763086,
    0.903633971,
    0.843461359,
    0.814238389,
    0.809706256,
    0.799585119,
    0.787834174,
    0.772993424,
    0.769282102,
    0.785571748,
    0.877736625,
    1.03404833,
    1.148193989,
    1.222215323,
    1.234844495,
    1.162850789,
    0.984577837,
    0.877003205,
    0.793490556,
    0.747514193,
    0.724243666,
    0.720617042,
    0.735755181,
    0.797335633,
    0.885523605,
    0.915286099,
    0.859064283,
    0.8253045,
    0.817564716,
    0.80501023,
    0.800487268,
    0.787843802,
    0.782813461,
    0.801565936,
    0.89407897,
    1.045034122,
    1.153775747,
    1.221572543,
    1.243937595,
    1.171393725,
    0.994241784,
    0.877361651,
    0.766765742,
    0.712574214,
    0.688468645,
    0.683373161,
    0.696335794,
    0.757306612,
    0.837607395,
    0.860430267,
    0.788773344,
    0.7455179,
    0.726234215,
    0.708524987,
    0.704747012,
    0.702516502,
    0.712181249,
    0.740881562,
    0.836559992,
    0.98452682,
    1.092389987,
    1.164344736,
    1.189010816,
    1.123351055,
    0.954231054,
    0.850761107,
    0.762851445,
    0.720981833,
    0.698086625,
    0.691264707,
    0.705745522,
    0.767684198,
    0.8522332,
    0.876131919,
    0.81293142,
    0.77537545,
    0.757049773,
    0.739542648,
    0.736240109,
    0.730153811,
    0.733792047,
    0.755226273,
    0.843378942,
    0.985500611,
    1.090430722,
    1.158748793,
    1.192626994,
    1.13177135,
    0.968596984,
    0.854872082,
    0.748742684,
    0.697801255,
    0.67337667,
    0.665430574,
    0.678494047,
    0.738126791,
    0.816610199,
    0.843297071,
    0.786752535,
    0.753641461,
    0.73156988,
    0.708014406,
    0.708738545,
    0.707991191,
    0.715871308,
    0.741306479,
    0.820650128,
    0.942785319,
    1.02535109,
    1.07840155,
    1.103679521,
    1.064752873,
    0.9503473,
    0.862175462,
    0.760642186,
    0.706140142,
    0.675636131,
    0.661958169,
    0.671199436,
    0.706662534,
    0.76163473,
    0.832989629,
    0.870801823,
    0.88630982,
    0.879339285,
    0.86267623,
    0.858041354,
    0.874428991,
    0.896531399,
    0.916326412,
    0.955400096,
    1.029882166,
    1.06517205,
    1.089295043,
    1.100880148,
    1.051924878,
    0.934377845,
    0.848581813,
    0.755641828,
    0.708622724,
    0.678149884,
    0.665074669,
    0.672842634,
    0.709586753,
    0.766717528,
    0.850133605,
    0.907013089,
    0.932639203,
    0.938567179,
    0.934248509,
    0.941679546,
    0.959222838,
    0.982559836,
    1.000530612,
    1.050551772,
    1.144887784,
    1.188474725,
    1.218955643,
    1.226758844,
    1.144215314,
    0.947677984,
    0.801531596,
    0.70046455,
    0.635458697,
    0.612662638,
    0.601268431,
    0.620342328,
    0.689350098,
    0.783393052,
    0.817639647,
    0.756403415,
    0.727820568,
    0.717175053,
    0.705380825,
    0.701290217,
    0.706340158,
    0.720337537,
    0.748868043,
    0.843834103,
    1.003077765,
    1.109181833,
    1.170869389,
    1.18668514,
    1.107816964,
    0.911651533,
    0.787968973,
    0.677075844,
    0.628710669,
    0.611341998,
    0.605630611,
    0.625963189,
    0.692821901,
    0.78248688,
    0.811387603,
    0.748310067,
    0.709366254,
    0.691874526,
    0.675665775,
    0.679209445,
    0.68790392,
    0.703581403,
    0.736379783,
    0.833128028,
    0.988454214,
    1.090601196,
    1.147189487,
    1.173574897,
    1.096465274,
    0.903967495,
    0.779376225,
    0.642933659,
    0.598048224,
    0.572772656,
    0.564974727,
    0.583892668,
    0.657509482,
    0.747348138,
    0.758887112,
    0.664998839,
    0.593298307,
    0.558447715,
    0.530964963,
    0.516795686,
    0.503560808,
    0.498846294,
    0.526587108,
    0.606155821,
    0.733515414,
    0.838692455,
    0.934114963,
    0.986309807,
    0.936692595,
    0.768325289,
    0.681206856,
    0.608563192,
    0.590175133,
    0.565292776,
    0.55335916,
    0.571921155,
    0.64414072,
    0.73503946,
    0.741087262,
    0.648739328,
    0.576837428,
    0.541193585,
    0.516417171,
    0.508477781,
    0.4986858,
    0.496621809,
    0.522134121,
    0.596059573,
    0.718373611,
    0.820644419,
    0.91068006,
    0.970083473,
    0.92535268,
    0.763413867,
    0.678628198,
    0.614759819,
    0.601529157,
    0.575434718,
    0.565946874,
    0.585813473,
    0.659026636,
    0.74931397,
    0.770853784,
    0.700046816,
    0.645537071,
    0.612734931,
    0.581225543,
    0.570215301,
    0.555220336,
    0.543977392,
    0.565645713,
    0.628005165,
    0.728987218,
    0.806330315,
    0.881815009,
    0.934465467,
    0.909924312,
    0.79411618,
    0.734244398,
    0.679932388,
    0.658794556,
    0.62483219,
    0.612648456,
    0.630067876,
    0.680141618,
    0.749970392,
    0.830908658,
    0.876950472,
    0.893748613,
    0.893976808,
    0.875736987,
    0.857115816,
    0.8473986,
    0.831310119,
    0.839726781,
    0.860846058,
    0.915732907,
    0.94633169,
    0.993544577,
    1.033918182,
    0.99270837,
    0.865646408,
    0.77435955,
    0.654108471,
    0.60525983,
    0.572088799,
    0.555865789,
    0.567528286,
    0.612415617,
    0.673425843,
    0.744786753,
    0.781057669,
    0.779448816,
    0.773601488,
    0.75948661,
    0.757773172,
    0.762321277,
    0.767086236,
    0.784411068,
    0.825375354,
    0.908697861,
    0.955527022,
    1.011449151,
    1.049408436,
    0.98186852,
    0.785133974,
    0.685161178,
    0.620089526,
    0.602203461,
    0.577819471,
    0.565524524,
    0.589912336,
    0.668796903,
    0.769056766,
    0.80003921,
    0.728586332,
    0.685024209,
    0.668790964,
    0.651182221,
    0.635501117,
    0.623451149,
    0.614054278,
    0.637632426,
    0.717268378,
    0.85749285,
    0.960191716,
    1.04199693,
    1.086009729,
    1.023434202,
    0.832303431,
    0.71765784,
    0.604612703,
    0.569926005,
    0.551679155,
    0.543288256,
    0.567490072,
    0.642046687,
    0.734193999,
    0.750991862,
    0.667021766,
    0.603000207,
    0.573251538,
    0.548786319,
    0.542182933,
    0.537209819,
    0.535635676,
    0.566868639,
    0.649698308,
    0.786603045,
    0.887900617,
    0.966014212,
    1.020885395,
    0.963776904,
    0.780877285,
    0.676539168,
    0.583411142,
    0.560512669,
    0.540670745,
    0.532615822,
    0.555751264,
    0.631564496,
    0.720046004,
    0.738257378,
    0.650410151,
    0.58927615,
    0.556295663,
    0.530769428,
    0.524459255,
    0.526434147,
    0.532635227,
    0.570100976,
    0.653395881,
    0.784117891,
    0.879767019,
    0.959831666,
    1.016324281,
    0.961864482,
    0.78197372,
    0.677218644,
    0.575151766,
    0.55061982,
    0.532598597,
    0.521557571,
    0.545048001,
    0.620328642,
    0.710158745,
    0.723168642,
    0.635990944,
    0.572343428,
    0.535081505,
    0.507330711,
    0.502146621,
    0.502597331,
    0.507358172,
    0.540337391,
    0.617663959,
    0.74375698,
    0.838629817,
    0.916228731,
    0.982819248,
    0.936503388,
    0.766273124,
    0.66433272,
    0.590091098,
    0.564536329,
    0.546229474,
    0.550195907,
    0.568879999,
    0.649254826,
    0.746180774,
    0.78494651,
    0.71384908,
    0.674684064,
    0.65592292,
    0.637129948,
    0.63066526,
    0.645685782,
    0.643511831,
    0.698269292,
    0.781473022,
    0.895709416,
    0.962916178,
    1.024624476,
    1.046482511,
    0.992502882,
    0.857117489,
    0.760893887,
    0.637029184,
    0.586815405,
    0.559012954,
    0.543943198,
    0.563100862,
    0.612067381,
    0.674310826,
    0.749317218,
    0.784793722,
    0.794487757,
    0.784720908,
    0.763786768,
    0.752594353,
    0.764349583,
    0.771350267,
    0.794538027,
    0.822757023,
    0.885155066,
    0.910884951,
    0.944831099,
    0.990076823,
    0.949628005,
    0.817593637,
    0.734923655,
    0.644834952,
    0.609165159,
    0.580229952,
    0.565043992,
    0.571503289,
    0.601051146,
    0.654765943,
    0.72422425,
    0.771584794,
    0.789532995,
    0.799699877,
    0.805567372,
    0.811794322,
    0.811722571,
    0.821514447,
    0.841727546,
    0.888301881,
    0.97101024,
    1.035588968,
    1.072283249,
    1.084389936,
    1.009745807,
    0.818725103,
    0.707683495,
    0.629821312,
    0.591956495,
    0.570395845,
    0.557074651,
    0.574229469,
    0.636291911,
    0.726714863,
    0.750129328,
    0.682716284,
    0.644316302,
    0.636550431,
    0.635877627,
    0.628213818,
    0.61614438,
    0.616296464,
    0.643826908,
    0.729191268,
    0.871640362,
    0.995001647,
    1.059925846,
    1.078820689,
    1.013157709,
    0.83156673,
    0.724124193,
    0.642640797,
    0.605147539,
    0.587164954,
    0.579964211,
    0.598423678,
    0.659050142,
    0.747523851,
    0.769424101,
    0.705356779,
    0.661435825,
    0.651221744,
    0.647992399,
    0.646432526,
    0.633505451,
    0.63124266,
    0.659661545,
    0.746533995,
    0.883882328,
    1.002258847,
    1.062554392,
    1.091597967,
    1.026640376,
    0.847717894,
    0.735912268,
    0.637698038,
    0.594485558,
    0.573861205,
    0.566144952,
    0.582237344,
    0.642978666,
    0.726746277,
    0.746291407,
    0.67469784,
    0.629005159,
    0.613561186,
    0.608341669,
    0.60656372,
    0.599398782,
    0.606153351,
    0.640384202,
    0.728414304,
    0.859803227,
    0.973644566,
    1.037381773,
    1.068082739,
    1.008300872,
    0.835276034,
    0.727344741,
    0.628312824,
    0.586823923,
    0.566731646,
    0.555585493,
    0.570735806,
    0.63006093,
    0.714475333,
    0.726418533,
    0.65300528,
    0.601844362,
    0.581058408,
    0.573087411,
    0.571592448,
    0.56160098,
    0.567086114,
    0.595668817,
    0.677998301,
    0.805029839,
    0.919007927,
    0.981516938,
    1.022502128,
    0.972991719,
    0.811905753,
    0.704716988,
    0.609474789,
    0.569153913,
    0.546293346,
    0.533735012,
    0.546223801,
    0.601970793,
    0.679906562,
    0.692777055,
    0.625402196,
    0.577736034,
    0.553635669,
    0.540157499,
    0.541773337,
    0.534262275,
    0.542622266,
    0.57301477,
    0.646526112,
    0.752894549,
    0.843075833,
    0.890722618,
    0.923013275,
    0.897979406,
    0.788301545,
    0.714501614,
    0.64419504,
    0.606640346,
    0.57532235,
    0.557870985,
    0.564956749,
    0.593870334,
    0.646470473,
    0.705646977,
    0.737467614,
    0.748175238,
    0.747940933,
    0.743057574,
    0.738529345,
    0.737881418,
    0.752435963,
    0.774295307,
    0.814919682,
    0.878562753,
    0.925088401,
    0.946474897,
    0.965576855,
    0.928706976,
    0.814327606,
    0.738733085,
    0.666116991,
    0.627420653,
    0.594503047,
    0.578129475,
    0.583217454,
    0.612490077,
    0.667668137,
    0.740442282,
    0.795016462,
    0.821727347,
    0.840521709,
    0.850521908,
    0.858248911,
    0.855422404,
    0.867155753,
    0.88481836,
    0.937266223,
    1.021018289,
    1.075250311,
    1.103247479,
    1.118482043,
    1.045959457,
    0.851489181,
    0.734801034,
    0.649259543,
    0.605655611,
    0.580326188,
    0.565516305,
    0.581093319,
    0.642482574,
    0.73325942,
    0.758387179,
    0.695382935,
    0.662516342,
    0.660261429,
    0.662224161,
    0.656404694,
    0.642343132,
    0.646571154,
    0.672421066,
    0.764466008,
    0.90968794,
    1.024556647,
    1.081328929,
    1.103352806,
    1.040616163,
    0.856721262,
    0.737892016,
    0.630881569,
    0.583445716,
    0.562741234,
    0.55170045,
    0.566228037,
    0.62309158,
    0.706513986,
    0.717819927,
    0.643529563,
    0.590201296,
    0.575360207,
    0.570622428,
    0.572349808,
    0.562858267,
    0.573046045,
    0.604332923,
    0.699255033,
    0.841185153,
    0.95497192,
    1.008858121,
    1.041939606,
    0.985567948,
    0.810819933,
    0.705409337,
    0.625045249,
    0.590684944,
    0.566854616,
    0.556579027,
    0.569960687,
    0.628546496,
    0.70997775,
    0.725228994,
    0.650766616,
    0.603265824,
    0.588204062,
    0.583545958,
    0.584266181,
    0.577869699,
    0.592828125,
    0.627643716,
    0.723023954,
    0.858168106,
    0.965574075,
    1.021954144,
    1.056105248,
    1.002066184,
    0.830014499,
    0.728539826,
    0.651321269,
    0.616526946,
    0.592317453,
    0.580907452,
    0.595621381,
    0.655654754,
    0.742885317,
    0.762143588,
    0.699727443,
    0.660899279,
    0.65005307,
    0.64663416,
    0.64632097,
    0.631960688,
    0.637733737,
    0.662667991,
    0.75088242,
    0.88000448,
    0.984224562,
    1.037637428,
    1.080964098,
    1.031642272,
    0.865288088,
    0.753709489,
    0.657323907,
    0.610757308,
    0.583558312,
    0.571518292,
    0.584388396,
    0.64189916,
    0.724567108,
    0.748958827,
    0.697629348,
    0.667300145,
    0.656591025,
    0.648985815,
    0.65127295,
    0.637793432,
    0.643650858,
    0.668871286,
    0.747582163,
    0.855178,
    0.934689957,
    0.972772852,
    1.006919719,
    0.98003193,
    0.863062302,
    0.775452158,
    0.681153109,
    0.628125138,
    0.592946302,
    0.574659266,
    0.580826245,
    0.609823104,
    0.664368752,
    0.728137827,
    0.768454443,
    0.789302635,
    0.79832199,
    0.798022328,
    0.794876547,
    0.791304723,
    0.807286719,
    0.826271284,
    0.872033135,
    0.936673593,
    0.973414826,
    0.985580953,
    1.007054885,
    0.971594994,
    0.853561472,
    0.770464957,
    0.686956068,
    0.640630449,
    0.604116788,
    0.586350832,
    0.589988786,
    0.618610425,
    0.67458487,
    0.748912892,
    0.808211676,
    0.841247547,
    0.866912278,
    0.880424257,
    0.889634563,
    0.885067554,
    0.900081233,
    0.91615619,
    0.974844869,
    1.060439324,
    1.106057818,
    1.125433796,
    1.143402477,
    1.073471129,
    0.876293412,
    0.75331788,
    0.657935843,
    0.608723585,
    0.580107076,
    0.563214244,
    0.576714348,
    0.636588256,
    0.726219449,
    0.74944392,
    0.686333728,
    0.654539677,
    0.654606951,
    0.658067819,
    0.654327441,
    0.640087309,
    0.650859614,
    0.676580108,
    0.775588788,
    0.924393719,
    1.032894049,
    1.081829534,
    1.106712932,
    1.04833253,
    0.863926366,
    0.743918775,
    0.638454875,
    0.590669853,
    0.566665186,
    0.553895708,
    0.56664971,
    0.622333449,
    0.705207096,
    0.716081624,
    0.643446695,
    0.592913644,
    0.581456273,
    0.578654035,
    0.582319812,
    0.57198576,
    0.587739076,
    0.61836859,
    0.720019781,
    0.865434384,
    0.972585986,
    1.01829845,
    1.054075525,
    1.001350646,
    0.825364316,
    0.714105447,
    0.632409098,
    0.591686307,
    0.561190825,
    0.548070471,
    0.563186321,
    0.629922106,
    0.720546522,
    0.739442508,
    0.661936651,
    0.612674747,
    0.598877677,
    0.595523881,
    0.598959759,
    0.59303846,
    0.61752258,
    0.654943557,
    0.76391671,
    0.910664711,
    1.015695281,
    1.067760348,
    1.111366577,
    1.051554557,
    0.853659497,
    0.747056038,
    0.671440916,
    0.637523711,
    0.606683317,
    0.594071258,
    0.61249005,
    0.683274528,
    0.782694602,
    0.813244044,
    0.756523565,
    0.724603234,
    0.720586565,
    0.720881773,
    0.72179622,
    0.704374932,
    0.713889753,
    0.738721569,
    0.839847619,
    0.98032632,
    1.082524687,
    1.130150759,
    1.183374915,
    1.125884523,
    0.930040575,
    0.792363971,
    0.660610797,
    0.593804368,
    0.560249319,
    0.550390564,
    0.563056198,
    0.627814025,
    0.714707766,
    0.739626662,
    0.691479603,
    0.65675887,
    0.643619128,
    0.630194736,
    0.640958603,
    0.642100233,
    0.642254746,
    0.666217115,
    0.744920643,
    0.848344792,
    0.911985575,
    0.952181298,
    1.00593815,
    0.986179105,
    0.855150148,
    0.761503525,
    0.677364814,
    0.622332698,
    0.580578249,
    0.565227931,
    0.570777748,
    0.603074021,
    0.656500158,
    0.726615615,
    0.783602305,
    0.81515308,
    0.827716383,
    0.825447841,
    0.831650515,
    0.842548753,
    0.848471744,
    0.865347218,
    0.907471838,
    0.96521053,
    0.987815854,
    1.000137966,
    1.0419124,
    1.010341271,
    0.874699155,
    0.77629121,
    0.684886335,
    0.627769402,
    0.585945152,
    0.571450182,
    0.574940299,
    0.606008684,
    0.659378715,
    0.739619684,
    0.815939579,
    0.861289737,
    0.891445566,
    0.905785306,
    0.926481949,
    0.939592999,
    0.945746048,
    0.961755149,
    1.018234482,
    1.100005281,
    1.135172945,
    1.153135556,
    1.191876667,
    1.122058545,
    0.898974513,
    0.751718747,
    0.631290984,
    0.566738072,
    0.536382327,
    0.523264113,
    0.540281646,
    0.609023531,
    0.703812475,
    0.727935555,
    0.665180241,
    0.629048434,
    0.625346363,
    0.625003548,
    0.63034746,
    0.637156871,
    0.647076356,
    0.67728175,
    0.778158681,
    0.930063555,
    1.033409624,
    1.077749809,
    1.118832154,
    1.061166914,
    0.851581542,
    0.714958201,
    0.600289635,
    0.547033657,
    0.521579882,
    0.512140655,
    0.529003547,
    0.593861771,
    0.681698666,
    0.692597292,
    0.619112221,
    0.56335048,
    0.547928,
    0.541701545,
    0.554756828,
    0.56703625,
    0.583190981,
    0.620262315,
    0.723715748,
    0.872707978,
    0.975725107,
    1.01404547,
    1.065173866,
    1.012831273,
    0.811550536,
    0.691188872,
    0.633858825,
    0.58674531,
    0.54613787,
    0.528578526,
    0.539947102,
    0.599824457,
    0.687248451,
    0.706181789,
    0.64919476,
    0.630734512,
    0.653902677,
    0.697192829,
    0.762722045,
    0.827736571,
    0.888235437,
    0.95368016,
    1.063916358,
    1.198843344,
    1.273095918,
    1.281121698,
    1.304256682,
    1.21612643,
    0.977098198,
    0.812955239,
    0.613949364,
    0.546802746,
    0.518258537,
    0.504971077,
    0.52063404,
    0.585222576,
    0.672133165,
    0.680432551,
    0.608192824,
    0.560070618,
    0.545919461,
    0.547420107,
    0.570165827,
    0.595176891,
    0.623872175,
    0.665831111,
    0.764555502,
    0.90239551,
    0.999209117,
    1.027704837,
    1.083221373,
    1.035080007,
    0.838807748,
    0.704634852,
    0.582198438,
    0.533998744,
    0.506037526,
    0.494478895,
    0.510383279,
    0.573719022,
    0.655516895,
    0.669981964,
    0.607766646,
    0.564859942,
    0.543469722,
    0.531799727,
    0.54677968,
    0.564210444,
    0.585171667,
    0.623427974,
    0.709914692,
    0.826477364,
    0.902937681,
    0.918011453,
    0.968750978,
    0.951607969,
    0.815746495,
    0.716007772,
    0.623331887,
    0.5736957,
    0.535938124,
    0.518462114,
    0.526407738,
    0.55707057,
    0.605326656,
    0.666208812,
    0.710446316,
    0.732935646,
    0.738379179,
    0.737820048,
    0.745926818,
    0.772129978,
    0.799661977,
    0.830448531,
    0.880663258,
    0.952292331,
    0.987248125,
    0.977668308,
    1.01526829,
    0.986846834,
    0.846632699,
    0.746111417,
    0.652926845,
    0.601640259,
    0.561997795,
    0.545953271,
    0.551770787,
    0.581988682,
    0.632273068,
    0.709658067,
    0.782727231,
    0.826736867,
    0.855695734,
    0.874057762,
    0.895358183,
    0.919881185,
    0.942279827,
    0.969028732,
    1.030683706,
    1.123336107,
    1.168533237,
    1.16728775,
    1.200643892,
    1.132901317,
    0.903155207,
    0.750077044,
    0.621119369,
    0.555943146,
    0.526257293,
    0.51188185,
    0.530617949,
    0.598415936,
    0.691514182,
    0.71715761,
    0.657347655,
    0.625599854,
    0.623349554,
    0.627867767,
    0.63510585,
    0.649478427,
    0.670118663,
    0.708298517,
    0.812596248,
    0.971746586,
    1.083313824,
    1.112559758,
    1.147808978,
    1.091915327,
    0.875086554,
    0.73069972,
    0.60188306,
    0.543384933,
    0.517161987,
    0.505970431,
    0.523472165,
    0.586729776,
    0.672597812,
    0.68583749,
    0.617248938,
    0.566375377,
    0.55308869,
    0.551701178,
    0.566475521,
    0.584655119,
    0.610312513,
    0.654353613,
    0.760169996,
    0.914803439,
    1.02491827,
    1.051901507,
    1.096418304,
    1.046882979,
    0.839259061,
    0.709663231,
    0.621266626,
    0.568940247,
    0.531662835,
    0.515179124,
    0.527755715,
    0.587732446,
    0.67111724,
    0.689171757,
    0.629192206,
    0.60054625,
    0.608522608,
    0.635619493,
    0.680422516,
    0.730803178,
    0.785417859,
    0.848215585,
    0.95716231,
    1.099900935,
    1.191091623,
    1.204230028,
    1.231888372,
    1.164209229,
    0.937278823,
    0.78677158,
    0.61625955,
    0.554720331,
    0.524995247,
    0.511055647,
    0.526409056,
    0.589854096,
    0.674475289,
    0.687289408,
    0.621997908,
    0.575227847,
    0.558172729,
    0.555586614,
    0.568789026,
    0.586722705,
    0.61453095,
    0.656730757,
    0.754142579,
    0.895383575,
    1.00172505,
    1.031926284,
    1.084697624,
    1.048312948,
    0.854457556,
    0.723820503,
    0.619799365,
    0.562851918,
    0.525076206,
    0.506876111,
    0.518221097,
    0.575493677,
    0.654690668,
    0.674854778,
    0.628924755,
    0.602868055,
    0.600190931,
    0.611308246,
    0.645915336,
    0.682175013,
    0.723322682,
    0.773927652,
    0.861243022,
    0.975268177,
    1.046879639,
    1.051137626,
    1.082030888,
    1.058093576,
    0.905375714,
    0.791387114,
    0.654888265,
    0.590270196,
    0.547324202,
    0.52646768,
    0.531600489,
    0.558588683,
    0.603677094,
    0.668962049,
    0.725337333,
    0.754301779,
    0.765373073,
    0.770034834,
    0.778846888,
    0.806581472,
    0.840875903,
    0.875992276,
    0.923624918,
    0.994018154,
    1.031918517,
    1.021399567,
    1.048904311,
    1.026887487,
    0.88375674,
    0.777042965,
    0.663739732,
    0.600482414,
    0.555856525,
    0.535029537,
    0.536692275,
    0.561537274,
    0.60564034,
    0.680654459,
    0.757239105,
    0.798379276,
    0.826926744,
    0.847422598,
    0.870432696,
    0.899892627,
    0.934995493,
    0.968726483,
    1.029614555,
    1.12273027,
    1.172852614,
    1.1716409,
    1.194677883,
    1.135893317,
    0.906206038,
    0.767156297,
    0.661111231,
    0.602527773,
    0.567305625,
    0.551802651,
    0.569852424,
    0.636786461,
    0.731544626,
    0.77167256,
    0.734423236,
    0.720994462,
    0.732113287,
    0.745402583,
    0.751841493,
    0.762249016,
    0.781169214,
    0.819426808,
    0.921190353,
    1.079031218,
    1.192304508,
    1.22043267,
    1.245944933,
    1.19201891,
    0.966636717,
    0.817755508,
    0.681547785,
    0.60939322,
    0.577092268,
    0.566169756,
    0.584098208,
    0.648176807,
    0.738635612,
    0.772762128,
    0.733792066,
    0.708353795,
    0.712794746,
    0.721795037,
    0.734770081,
    0.746542613,
    0.766119352,
    0.807904009,
    0.910725516,
    1.063761259,
    1.174123258,
    1.199661307,
    1.234523195,
    1.184174953,
    0.964655234,
    0.815780031,
    0.671918387,
    0.598156477,
    0.562866733,
    0.550872602,
    0.565764338,
    0.629069419,
    0.71364434,
    0.744387566,
    0.697215809,
    0.668970168,
    0.666846909,
    0.673617034,
    0.686359733,
    0.70475957,
    0.735192689,
    0.784494075,
    0.887382492,
    1.033337261,
    1.140518557,
    1.170953163,
    1.205813014,
    1.161417913,
    0.948529383,
    0.806088391,
    0.663320752,
    0.592239926,
    0.556853935,
    0.541106176,
    0.554775234,
    0.616333221,
    0.700963643,
    0.724336699,
    0.676536811,
    0.642838036,
    0.635322204,
    0.63964475,
    0.652287194,
    0.66790531,
    0.696736398,
    0.7400084,
    0.835319054,
    0.975732673,
    1.084398236,
    1.114895503,
    1.158094409,
    1.125103521,
    0.925256667,
    0.786410349,
    0.651816486,
    0.583016168,
    0.544479708,
    0.527461546,
    0.538615127,
    0.596077315,
    0.674078603,
    0.701608836,
    0.66519641,
    0.639259447,
    0.630598802,
    0.630214603,
    0.645428151,
    0.662617912,
    0.692176726,
    0.736296021,
    0.819401745,
    0.935274952,
    1.018073281,
    1.033576557,
    1.06519119,
    1.058642155,
    0.915343354,
    0.808600436,
    0.689418171,
    0.618212736,
    0.569733257,
    0.546364248,
    0.549034902,
    0.573196465,
    0.616881494,
    0.689539166,
    0.761998823,
    0.801458343,
    0.821215805,
    0.832073633,
    0.840972517,
    0.867282913,
    0.904107659,
    0.941060096,
    0.984844889,
    1.052200308,
    1.0917029,
    1.08133177,
    1.099141865,
    1.081984857,
    0.935325917,
    0.822519338,
    0.689730155,
    0.612294159,
    0.558472461,
    0.53300204,
    0.528222068,
    0.541099741,
    0.572813846,
    0.655637668,
    0.766617026,
    0.828306688,
    0.873423312,
    0.897216319,
    0.917118587,
    0.946870838,
    0.987814747,
    1.023466061,
    1.075838764,
    1.159504226,
    1.190882729,
    1.188744785,
    1.206127035,
    1.15054737,
    0.912519831,
    0.780851153,
    0.630403921,
    0.58254707,
    0.545726857,
    0.528394526,
    0.539904096,
    0.596030964,
    0.676512992,
    0.698008421,
    0.649338777,
    0.60936032,
    0.605375714,
    0.613549628,
    0.628619288,
    0.646801729,
    0.682530097,
    0.732657246,
    0.835747254,
    0.990094452,
    1.107086625,
    1.136308068,
    1.162016183,
    1.124821126,
    0.912516578,
    0.769436897,
    0.68103675,
    0.593103533,
    0.533157622,
    0.507654134,
    0.506522795,
    0.528477564,
    0.572292138,
    0.629706993,
    0.702694803,
    0.767725912,
    0.845102151,
    0.861118374,
    0.85211006,
    0.860704782,
    0.884397021,
    0.921796677,
    1.00067867,
    1.128543483,
    1.143636151,
    1.165862419,
    1.204131569,
    1.139624755,
    0.879320765,
    0.749582724,
    0.636514503,
    0.601646624,
    0.561322851,
    0.545198599,
    0.555781314,
    0.614086718,
    0.693355381,
    0.721968214,
    0.677376861,
    0.647195419,
    0.645068328,
    0.654440567,
    0.667834601,
    0.688023422,
    0.72681345,
    0.780542744,
    0.882440874,
    1.028239744,
    1.139613959,
    1.172758311,
    1.198654549,
    1.162742548,
    0.951590134,
    0.814447188,
    0.673228137,
    0.600639766,
    0.560138613,
    0.540600176,
    0.550247016,
    0.607218396,
    0.687397003,
    0.710180269,
    0.666848253,
    0.63303006,
    0.626774637,
    0.634179047,
    0.647195455,
    0.663563268,
    0.699340539,
    0.74623454,
    0.840249539,
    0.980244682,
    1.092715766,
    1.125962845,
    1.160410545,
    1.135187392,
    0.93637605,
    0.802110577,
    0.668846155,
    0.597577995,
    0.55383117,
    0.533536977,
    0.541119604,
    0.59460652,
    0.669127221,
    0.698128159,
    0.668599761,
    0.645032926,
    0.639308925,
    0.642573866,
    0.657917328,
    0.674852244,
    0.709685658,
    0.756580181,
    0.838029113,
    0.952938237,
    1.038875985,
    1.057021685,
    1.079887452,
    1.08016715,
    0.937248139,
    0.835136312,
    0.697201096,
    0.625450691,
    0.576451075,
    0.550922595,
    0.548847362,
    0.567064501,
    0.603723151,
    0.666363831,
    0.732470991,
    0.763805226,
    0.780516279,
    0.791096726,
    0.800104391,
    0.826296755,
    0.869103143,
    0.908536981,
    0.948807791,
    1.012167722,
    1.055103983,
    1.049383452,
    1.054005654,
    1.045410226,
    0.918520697,
    0.820249797,
    0.703662662,
    0.630763962,
    0.579109861,
    0.552161856,
    0.545850699,
    0.561467804,
    0.597072955,
    0.666259666,
    0.748601671,
    0.788583527,
    0.821332004,
    0.84830386,
    0.875771409,
    0.908287549,
    0.957601848,
    0.998521865,
    1.052484805,
    1.13642896,
    1.189771193,
    1.191012012,
    1.188633484,
    1.143896848,
    0.934140844,
    0.824202775,
    0.711682475,
    0.6535776,
    0.593530093,
    0.5583679,
    0.549891146,
    0.584659165,
    0.642736994,
    0.663411334,
    0.635866238,
    0.628093796,
    0.652077595,
    0.680673872,
    0.72017685,
    0.762421881,
    0.804245888,
    0.863630769,
    0.984163713,
    1.138644059,
    1.243662329,
    1.261295403,
    1.213608931,
    1.151915396,
    0.947430812,
    0.824934891,
    0.759015576,
    0.692503137,
    0.622570034,
    0.583087442,
    0.569349341,
    0.594203746,
    0.64952432,
    0.668384339,
    0.65662361,
    0.66783121,
    0.724776154,
    0.797742114,
    0.895454599,
    0.984817375,
    1.062028093,
    1.144724662,
    1.270431236,
    1.411299553,
    1.489815233,
    1.476783408,
    1.409673307,
    1.315543717,
    1.08021488,
    0.927155135,
    0.749446567,
    0.66824755,
    0.603194338,
    0.568991215,
    0.556120748,
    0.585306857,
    0.635547458,
    0.651841985,
    0.625708322,
    0.620602588,
    0.651494427,
    0.69580744,
    0.763729487,
    0.831747121,
    0.897013814,
    0.974615795,
    1.097938789,
    1.237364834,
    1.325864219,
    1.333246906,
    1.282188481,
    1.211844719,
    1.002083112,
    0.86879675,
    0.721068108,
    0.652415659,
    0.593391005,
    0.559976911,
    0.548657337,
    0.57966901,
    0.630882503,
    0.640175832,
    0.608648628,
    0.587430345,
    0.597717075,
    0.619434348,
    0.664575869,
    0.70900899,
    0.755708488,
    0.81787906,
    0.932510045,
    1.070383042,
    1.169759897,
    1.189027207,
    1.158280514,
    1.113112699,
    0.929965436,
    0.822574182,
    0.78876976,
    0.726107639,
    0.642934842,
    0.596538196,
    0.576887011,
    0.596700422,
    0.648395862,
    0.677534257,
    0.692755017,
    0.736502198,
    0.814350365,
    0.909077439,
    1.035240503,
    1.147669082,
    1.240861838,
    1.330900072,
    1.438210151,
    1.535479162,
    1.574221318,
    1.537243752,
    1.453313596,
    1.373760753,
    1.180932683,
    1.034994391,
    0.816558676,
    0.7119907,
    0.632893382,
    0.588843372,
    0.566458499,
    0.562547002,
    0.582070718,
    0.646015375,
    0.741832884,
    0.809721279,
    0.869862988,
    0.923393252,
    0.993468443,
    1.072101951,
    1.141041606,
    1.204126803,
    1.269740844,
    1.332038736,
    1.352473815,
    1.319111928,
    1.248267805,
    1.193987961,
    1.036601178,
    0.934889246,
    0.842929609,
    0.762131839,
    0.670109134,
    0.619103483,
    0.589553955,
    0.579034471,
    0.60136515,
    0.680659213,
    0.811875886,
    0.920577885,
    1.033627701,
    1.14333232,
    1.270127333,
    1.386616194,
    1.481456343,
    1.558589742,
    1.638953856,
    1.708848481,
    1.719093389,
    1.670517691,
    1.570331392,
    1.451360216,
    1.184062348,
    1.014552452,
    0.833723554,
    0.729444737,
    0.645814448,
    0.59596501,
    0.577444768,
    0.600356103,
    0.659454677,
    0.689996864,
    0.694963877,
    0.735073002,
    0.81969509,
    0.919422492,
    1.03182322,
    1.135271959,
    1.22579901,
    1.313746923,
    1.438118875,
    1.57538586,
    1.648818282,
    1.629906776,
    1.53650885,
    1.427956667,
    1.172326522,
    1.015517929,
    0.861474333,
    0.760313953,
    0.671733144,
    0.621442231,
    0.599917486,
    0.616143663,
    0.673032748,
    0.702484644,
    0.719381861,
    0.770663683,
    0.876730532,
    1.005447204,
    1.157543968,
    1.291832973,
    1.404090312,
    1.507192135,
    1.634397999,
    1.759971255,
    1.813500448,
    1.772773602,
    1.669447964,
    1.541021925,
    1.265561967,
    1.082906555,
    0.853533758,
    0.739443466,
    0.654641342,
    0.608943732,
    0.588107853,
    0.608427162,
    0.660580175,
    0.688117057,
    0.692253698,
    0.729584086,
    0.812788148,
    0.91671844,
    1.043005621,
    1.158326714,
    1.260692503,
    1.359154558,
    1.483291089,
    1.607328921,
    1.669636402,
    1.64810181,
    1.559437425,
    1.4514265,
    1.198914259,
    1.032020935,
    0.811735551,
    0.70954629,
    0.634692401,
    0.592384979,
    0.574293799,
    0.598021914,
    0.650059329,
    0.667769304,
    0.659434439,
    0.669317712,
    0.717949971,
    0.783100716,
    0.870250483,
    0.948546099,
    1.022061844,
    1.099210829,
    1.214275495,
    1.341646269,
    1.422593202,
    1.423024287,
    1.363110755,
    1.290857045,
    1.0770465,
    0.937554349,
    0.785862078,
    0.701214691,
    0.62562353,
    0.58454265,
    0.565594621,
    0.587819917,
    0.634621641,
    0.657621342,
    0.659358903,
    0.674102038,
    0.71716861,
    0.770424897,
    0.851468542,
    0.922943429,
    0.989709747,
    1.063599238,
    1.166730347,
    1.272373493,
    1.333484357,
    1.325000705,
    1.260014177,
    1.216306739,
    1.058993688,
    0.95178891,
    0.823598721,
    0.736963731,
    0.651519455,
    0.60431344,
    0.578067136,
    0.570570367,
    0.58896444,
    0.655426684,
    0.760684591,
    0.836580689,
    0.906986669,
    0.970387563,
    1.048855599,
    1.132201073,
    1.206948084,
    1.273418314,
    1.33759696,
    1.394806599,
    1.412089041,
    1.377860843,
    1.297140772,
    1.240381267,
    1.078258966,
    0.971839292,
    0.854793474,
    0.767202609,
    0.674492027,
    0.623813468,
    0.592266105,
    0.580649908,
    0.600533797,
    0.679171092,
    0.811423016,
    0.913275238,
    1.018488415,
    1.116904792,
    1.230990002,
    1.33361845,
    1.420894682,
    1.493330758,
    1.571276319,
    1.641709645,
    1.658954379,
    1.621673816,
    1.52350294,
    1.416775006,
    1.160323346,
    1.011011786,
    0.885915741,
    0.785224325,
    0.686625623,
    0.628223292,
    0.603323979,
    0.619391422,
    0.679828666,
    0.71870623,
    0.746762493,
    0.819027582,
    0.943859846,
    1.088798635,
    1.245648243,
    1.384191464,
    1.501677764,
    1.60492625,
    1.729828317,
    1.852922928,
    1.906166298,
    1.865761756,
    1.743573358,
    1.607429291,
    1.320086254,
    1.136845446,
    0.926272887,
    0.800001219,
    0.701460364,
    0.645893029,
    0.61962887,
    0.631239338,
    0.688591374,
    0.72371699,
    0.756038461,
    0.82686229,
    0.95735191,
    1.112672166,
    1.289740902,
    1.442610194,
    1.569577872,
    1.68091563,
    1.807600757,
    1.923725882,
    1.965684326,
    1.914384712,
    1.79289885,
    1.650168635,
    1.357105603,
    1.144692235,
    0.814296001,
    0.689736602,
    0.619772802,
    0.583965005,
    0.566188294,
    0.592139,
    0.638140454,
    0.654975958,
    0.633754865,
    0.624040988,
    0.651130996,
    0.688973228,
    0.744244375,
    0.797217884,
    0.856358649,
    0.92848589,
    1.047295512,
    1.184707871,
    1.281209277,
    1.303211118,
    1.248451635,
    1.19402765,
    0.995903435,
    0.884652206,
    0.808464369,
    0.736797648,
    0.655081096,
    0.609353954,
    0.587409922,
    0.607615931,
    0.659172416,
    0.679687414,
    0.680563462,
    0.700184256,
    0.761762105,
    0.840577973,
    0.940006255,
    1.026287704,
    1.107193476,
    1.188325798,
    1.302779519,
    1.42494298,
    1.500482011,
    1.498179092,
    1.427279428,
    1.348774716,
    1.127233808,
    0.986548929,
    0.836393382,
    0.742804308,
    0.656330533,
    0.609325473,
    0.585318321,
    0.602432058,
    0.649973562,
    0.678416972,
    0.695976937,
    0.731542504,
    0.801228115,
    0.883903208,
    0.99348174,
    1.086758581,
    1.170349711,
    1.25360508,
    1.356694357,
    1.452989925,
    1.501056358,
    1.481191811,
    1.396968074,
    1.335441942,
    1.158368226,
    1.040965247,
    0.899301054,
    0.795954399,
    0.694722207,
    0.638607939,
    0.605730387,
    0.591108386,
    0.611818079,
    0.686505338,
    0.815296299,
    0.926692081,
    1.041428757,
    1.154309921,
    1.282343112,
    1.404937443,
    1.508377757,
    1.59123597,
    1.65517577,
    1.695530663,
    1.689456302,
    1.631783275,
    1.521484364,
    1.433057086,
    1.236247165,
    1.086905255,
    0.844072918,
    0.732878655,
    0.650676136,
    0.607311293,
    0.577860408,
    0.570825983,
    0.586263664,
    0.658291409,
    0.774169256,
    0.840833613,
    0.904144183,
    0.952446355,
    1.013769017,
    1.06758193,
    1.121720874,
    1.172490001,
    1.245257517,
    1.326664569,
    1.369310565,
    1.366536883,
    1.293625513,
    1.226153507,
    1.009690869,
    0.883505316,
    0.772494151,
    0.698776686,
    0.627240217,
    0.586894057,
    0.569672456,
    0.59752118,
    0.650517103,
    0.673365742,
    0.658501956,
    0.653943631,
    0.685201939,
    0.719164412,
    0.756926544,
    0.791349565,
    0.834763714,
    0.893013063,
    1.009083624,
    1.157783795,
    1.266181776,
    1.296299049,
    1.234511414,
    1.183246981,
    0.983980998,
    0.867495352,
    0.777363105,
    0.705879706,
    0.634377418,
    0.596054758,
    0.576886838,
    0.599214807,
    0.647750928,
    0.663563099,
    0.649932225,
    0.64067711,
    0.676020767,
    0.720458383,
    0.781576278,
    0.832599383,
    0.888390157,
    0.956482156,
    1.075330203,
    1.217103333,
    1.315426269,
    1.334267347,
    1.273483841,
    1.21426464,
    1.01011583,
    0.889105781,
    0.768037288,
    0.697099118,
    0.62638745,
    0.591016001,
    0.571699099,
    0.597099928,
    0.642102782,
    0.65841257,
    0.637035104,
    0.622956728,
    0.646080961,
    0.677878169,
    0.72502487,
    0.768432745,
    0.821343479,
    0.88899993,
    1.006120205,
    1.14269761,
    1.242541067,
    1.272872333,
    1.219595727,
    1.172295836,
    0.981588634,
    0.873592077,
    0.77620703,
    0.711751464,
    0.64209284,
    0.605803228,
    0.587518947,
    0.613973933,
    0.663635319,
    0.680459952,
    0.666365944,
    0.654142374,
    0.675487573,
    0.703333473,
    0.745595319,
    0.77874293,
    0.820413796,
    0.877295953,
    0.986060024,
    1.116660501,
    1.216981054,
    1.248743605,
    1.206263125,
    1.167888897,
    0.987767451,
    0.87440273,
    0.77230946,
    0.700351908,
    0.628139372,
    0.591100783,
    0.570774303,
    0.59421417,
    0.637748384,
    0.657621182,
    0.652510304,
    0.646417492,
    0.665918309,
    0.688487784,
    0.733299943,
    0.768003342,
    0.810109862,
    0.867669086,
    0.965673172,
    1.073767035,
    1.150774706,
    1.16911474,
    1.116887524,
    1.102340171,
    0.974675227,
    0.895482508,
    0.826671566,
    0.752766132,
    0.66465994,
    0.617421185,
    0.587616574,
    0.578531934,
    0.594783545,
    0.660546648,
    0.767486278,
    0.83745914,
    0.903040997,
    0.957929335,
    1.025624484,
    1.093384504,
    1.158219536,
    1.216808088,
    1.277014716,
    1.33098956,
    1.352917793,
    1.33312872,
    1.2529633,
    1.207957135,
    1.058213161,
    0.964375863,
    0.8621896,
    0.778649518,
    0.684567474,
    0.634686586,
    0.600060602,
    0.587458483,
    0.60526752,
    0.682515739,
    0.814570202,
    0.90771816,
    1.004551735,
    1.090183955,
    1.190266477,
    1.273967188,
    1.348602103,
    1.411618403,
    1.485580139,
    1.553961947,
    1.577520845,
    1.557192965,
    1.462532464,
    1.369234391,
    1.127407733,
    0.957097241,
    0.814712512,
    0.693733092,
    0.597750443,
    0.54745457,
    0.528782287,
    0.555061045,
    0.624016045,
    0.6702357,
    0.694848328,
    0.744114664,
    0.840522381,
    0.949490894,
    1.067948939,
    1.168574296,
    1.260515931,
    1.349209721,
    1.475591493,
    1.606987925,
    1.674840049,
    1.658799035,
    1.546855162,
    1.434148051,
    1.146232863,
    0.989822285,
    0.828026995,
    0.72257565,
    0.622622845,
    0.572165083,
    0.550510539,
    0.571525226,
    0.63857047,
    0.681790174,
    0.713328326,
    0.767440207,
    0.878789289,
    1.009184664,
    1.160986184,
    1.287409581,
    1.39761564,
    1.500821187,
    1.631390209,
    1.752734487,
    1.801981714,
    1.767539927,
    1.650214871,
    1.521616904,
    1.219045902,
    1.038225632,
    0.786268332,
    0.674451798,
    0.582918004,
    0.535759255,
    0.519183589,
    0.54394456,
    0.600257473,
    0.636103895,
    0.652290755,
    0.687662883,
    0.756202168,
    0.851324631,
    0.948395375,
    1.056260892,
    1.171207146,
    1.280508958,
    1.405078567,
    1.545340768,
    1.632599552,
    1.594078494,
    1.487845261,
    1.390480343,
    1.119145417,
    0.960933312,
    0.801248896,
    0.695053324,
    0.597973428,
    0.546550942,
    0.529159225,
    0.544234687,
    0.598463305,
    0.638018779,
    0.674324393,
    0.732066921,
    0.81918533,
    0.927470227,
    1.045807546,
    1.161497728,
    1.28444874,
    1.397794131,
    1.510543079,
    1.632703666,
    1.691174062,
    1.632198336,
    1.515938873,
    1.405688497,
    1.153509903,
    0.995730528,
    0.875680745,
    0.758419857,
    0.637042641,
    0.573425243,
    0.542418089,
    0.502471607,
    0.50700519,
    0.605963949,
    0.799648731,
    0.986185362,
    1.158743214,
    1.313452359,
    1.479415563,
    1.640306085,
    1.799887379,
    1.935044473,
    1.98627834,
    2.00310205,
    1.92885859,
    1.783274327,
    1.605252841,
    1.464023985,
    1.270922004,
    1.132755982,
    0.927368904,
    0.799524956,
    0.670448691,
    0.599803815,
    0.563098014,
    0.540597097,
    0.564500706,
    0.655810177,
    0.831544236,
    1.016335504,
    1.212859977,
    1.423688918,
    1.634990471,
    1.845918039,
    2.034301498,
    2.174361599,
    2.247545171,
    2.273681374,
    2.228147161,
    2.082800986,
    1.902732525,
    1.744245766,
    1.450795466,
    1.230209038,
    0.936464079,
    0.765804037,
    0.643837277,
    0.578424842,
    0.54163978,
    0.510527125,
    0.522646272,
    0.619924563,
    0.810909196,
    0.99135077,
    1.177700341,
    1.360934231,
    1.546184182,
    1.721294712,
    1.886171596,
    2.015619592,
    2.095673116,
    2.149599818,
    2.118613834,
    1.994494883,
    1.819905872,
    1.648301885,
    1.324983687,
    1.112717582,
    0.870127771,
    0.72453653,
    0.617764176,
    0.55817052,
    0.538814168,
    0.558991471,
    0.626964682,
    0.678062221,
    0.725862012,
    0.813187632,
    0.946089909,
    1.108123454,
    1.264945641,
    1.420975616,
    1.569274844,
    1.695066549,
    1.825532233,
    1.962240134,
    2.024860083,
    1.948812564,
    1.794046008,
    1.642874767,
    1.31118707,
    1.101386325,
    0.862116012,
    0.720553276,
    0.618622201,
    0.564109982,
    0.544609933,
    0.56341413,
    0.627006701,
    0.668963086,
    0.707792728,
    0.773779783,
    0.888925342,
    1.034839456,
    1.185723021,
    1.333999996,
    1.475262415,
    1.599320734,
    1.730075412,
    1.865849548,
    1.928876142,
    1.857696065,
    1.720403983,
    1.580908188,
    1.265505125,
    1.076259415,
    0.883486281,
    0.751596819,
    0.639932701,
    0.581425875,
    0.558375016,
    0.575316181,
    0.638657275,
    0.687104556,
    0.736525581,
    0.828426695,
    0.969867236,
    1.147959665,
    1.333152588,
    1.513743514,
    1.6837584,
    1.827977138,
    1.961055566,
    2.080100349,
    2.121386617,
    2.033828572,
    1.879201819,
    1.716761122,
    1.377582426,
    1.163863753,
    0.921320926,
    0.769206885,
    0.653726068,
    0.590854589,
    0.566458616,
    0.581727595,
    0.648616717,
    0.696079032,
    0.754435544,
    0.856300443,
    1.00927002,
    1.20195711,
    1.403310012,
    1.594915346,
    1.769053551,
    1.911522329,
    2.038528703,
    2.146556276,
    2.179329775,
    2.082190681,
    1.926704858,
    1.760698337,
    1.418752827,
    1.182405993,
    0.886191217,
    0.726497315,
    0.620172879,
    0.56391346,
    0.541547012,
    0.558483623,
    0.617100006,
    0.662867723,
    0.715199569,
    0.795247094,
    0.909304399,
    1.051457138,
    1.206362842,
    1.356073278,
    1.495095048,
    1.61494488,
    1.723822798,
    1.81654378,
    1.846067124,
    1.763490768,
    1.628445815,
    1.522427825,
    1.275755183,
    1.115433689,
    0.939612165,
    0.799524956,
    0.670448691,
    0.599803815,
    0.563098014,
    0.539746357,
    0.564500706,
    0.655810177,
    0.831544236,
    1.016335504,
    1.212859977,
    1.423688918,
    1.634990471,
    1.845918039,
    2.034301498,
    2.174361599,
    2.247545171,
    2.273681374,
    2.228147161,
    2.082800986,
    1.901955509,
    1.744245766,
    1.450795466,
    1.235048727,
    0.959646905,
    0.788386524,
    0.662307881,
    0.594084687,
    0.554647369,
    0.529539134,
    0.550752449,
    0.645123792,
    0.829637918,
    1.01386539,
    1.215250101,
    1.42652347,
    1.637669497,
    1.837515907,
    2.015874159,
    2.148621794,
    2.237858543,
    2.291688812,
    2.265587413,
    2.138531817,
    1.96046093,
    1.774926207,
    1.409019683,
    1.170661621,
    0.901553396,
    0.740882072,
    0.63078579,
    0.569983456,
    0.549506635,
    0.568934926,
    0.639762625,
    0.692089594,
    0.743967992,
    0.841853138,
    0.9897872,
    1.168524626,
    1.344941602,
    1.515251843,
    1.671836303,
    1.802454633,
    1.935245098,
    2.065620282,
    2.115781288,
    2.031056046,
    1.868889672,
    1.700578552,
    1.356130074,
    1.125615131,
    0.832438683,
    0.685886619,
    0.594647087,
    0.547391219,
    0.531031511,
    0.554850446,
    0.617198124,
    0.650673983,
    0.668962832,
    0.704784778,
    0.784155302,
    0.888238078,
    0.997321288,
    1.107046678,
    1.215793824,
    1.318365908,
    1.445042605,
    1.587887115,
    1.669082331,
    1.625862748,
    1.516694423,
    1.40384836,
    1.124641195,
    0.959869215,
    0.791827628,
    0.686417129,
    0.593782947,
    0.54760014,
    0.530254244,
    0.555701389,
    0.615365801,
    0.649538727,
    0.663499389,
    0.700924894,
    0.776011097,
    0.877117785,
    0.983736001,
    1.094749827,
    1.207663992,
    1.314399278,
    1.440137766,
    1.575037161,
    1.652430705,
    1.614669019,
    1.509199975,
    1.399325872,
    1.125455355,
    0.961696065,
    0.782876448,
    0.677379476,
    0.58833189,
    0.542122406,
    0.525729241,
    0.552528942,
    0.613785075,
    0.641940391,
    0.652291023,
    0.678749862,
    0.739390602,
    0.825134417,
    0.916750355,
    1.011540366,
    1.108287029,
    1.200088142,
    1.316551056,
    1.447378044,
    1.531588542,
    1.502161064,
    1.414831275,
    1.323706991,
    1.072480938,
    0.928277303,
    0.811759419,
    0.713400998,
    0.611762454,
    0.559103302,
    0.538303149,
    0.558021462,
    0.61774702,
    0.659350081,
    0.701664554,
    0.770304647,
    0.87201185,
    0.999530079,
    1.141658041,
    1.278264083,
    1.403974488,
    1.514706546,
    1.623091009,
    1.717689913,
    1.750435158,
    1.677796366,
    1.555047124,
    1.45373385,
    1.219586248,
    1.065720069,
    0.891984262,
    0.763121732,
    0.645071445,
    0.581909257,
    0.548798288,
    0.530965326,
    0.555068269,
    0.63785065,
    0.791136889,
    0.944632905,
    1.1039856,
    1.272548299,
    1.443168249,
    1.61662729,
    1.773230074,
    1.892368012,
    1.963860921,
    2.001146146,
    1.97348543,
    1.855770815,
    1.703630784,
    1.569620509,
    1.310575931,
    1.117678299,
    0.8553672,
    0.711895668,
    0.607853659,
    0.553723521,
    0.521398477,
    0.506534025,
    0.523939962,
    0.602486963,
    0.744549222,
    0.863459604,
    0.985746213,
    1.107350055,
    1.229406411,
    1.350236344,
    1.465557853,
    1.557007325,
    1.640164414,
    1.718543591,
    1.735082682,
    1.665552017,
    1.544718012,
    1.416869844,
    1.123698772,
    0.95636277,
    0.812605029,
    0.704267788,
    0.605223961,
    0.551869233,
    0.53506878,
    0.560074709,
    0.630566647,
    0.6744134,
    0.705075556,
    0.773984796,
    0.887472194,
    1.026325902,
    1.16389997,
    1.299193823,
    1.425145129,
    1.53550071,
    1.665225756,
    1.803852575,
    1.869742975,
    1.810270125,
    1.676300434,
    1.530464532,
    1.219275398,
    1.028266086,
    0.82214498,
    0.699830517,
    0.605743744,
    0.557485397,
    0.540763622,
    0.564581782,
    0.631035282,
    0.665824742,
    0.687653029,
    0.736258365,
    0.833394325,
    0.957673043,
    1.091069471,
    1.220268438,
    1.340140563,
    1.449401249,
    1.579624369,
    1.716788331,
    1.782153707,
    1.725974203,
    1.609074461,
    1.473168018,
    1.176834455,
    1.00581645,
    0.849550566,
    0.736301091,
    0.630956198,
    0.577772867,
    0.557174299,
    0.578610992,
    0.645653936,
    0.688116477,
    0.723305631,
    0.803226311,
    0.933271394,
    1.097557314,
    1.273894894,
    1.443094638,
    1.597274966,
    1.730263047,
    1.864209827,
    1.983443561,
    2.022500729,
    1.944479621,
    1.805931453,
    1.640056627,
    1.312841747,
    1.103979225,
    0.859419873,
    0.722403405,
    0.621843879,
    0.569451488,
    0.550268222,
    0.573699535,
    0.641994245,
    0.677572691,
    0.706608016,
    0.771958526,
    0.8828921,
    1.026487099,
    1.182344026,
    1.331170142,
    1.46528216,
    1.580866621,
    1.705261244,
    1.822430359,
    1.871091409,
    1.8050077,
    1.687384636,
    1.54346015,
    1.24263364,
    1.044057203,
    0.81035809,
    0.68514497,
    0.592265617,
    0.545501136,
    0.528033433,
    0.552592422,
    0.61342365,
    0.648519179,
    0.674367386,
    0.723635782,
    0.803028316,
    0.904776337,
    1.023335908,
    1.138343505,
    1.243140598,
    1.339972164,
    1.447276889,
    1.546791153,
    1.587492828,
    1.529497067,
    1.427428003,
    1.336795803,
    1.12345337,
    0.976139413,
    0.790308873,
    0.680460741,
    0.586227927,
    0.537972409,
    0.512851978,
    0.506153394,
    0.527015763,
    0.593175727,
    0.702295526,
    0.790424987,
    0.870855612,
    0.949905593,
    1.03127583,
    1.126142256,
    1.218375095,
    1.296041144,
    1.362913293,
    1.423955552,
    1.438458048,
    1.375321149,
    1.28140613,
    1.204518062,
    1.017936127,
    0.905458295,
    0.808893705,
    0.718582463,
    0.613607778,
    0.559534088,
    0.527727704,
    0.513830855,
    0.53567844,
    0.614192137,
    0.755360684,
    0.884889294,
    1.021644613,
    1.160905609,
    1.304816532,
    1.443598547,
    1.567664683,
    1.664460304,
    1.751637048,
    1.824956105,
    1.826815261,
    1.74490943,
    1.619758968,
    1.469693764,
    1.161875825,
    0.967260459,
    0.750043827,
    0.637781901,
    0.557992731,
    0.516771241,
    0.506590495,
    0.540816651,
    0.60974628,
    0.64003635,
    0.636294847,
    0.657081616,
    0.712387709,
    0.784245334,
    0.854904495,
    0.93212396,
    1.008233966,
    1.086243812,
    1.209627074,
    1.361177165,
    1.45581201,
    1.436000906,
    1.34847386,
    1.244893434,
    0.98966239,
    0.84283609,
    0.711080499,
    0.630246658,
    0.55636037,
    0.520654423,
    0.511000007,
    0.544442595,
    0.609295079,
    0.630105862,
    0.616795174,
    0.616400422,
    0.654499191,
    0.710617059,
    0.776124411,
    0.845748838,
    0.914352376,
    0.990489591,
    1.113939607,
    1.262795487,
    1.358175635,
    1.341853444,
    1.272748739,
    1.180010084,
    0.941043734,
    0.805867602,
    0.691600225,
    0.620966908,
    0.548389035,
    0.515317009,
    0.505580803,
    0.541826741,
    0.603505612,
    0.623317025,
    0.601261518,
    0.595995305,
    0.621743924,
    0.665604428,
    0.718944231,
    0.782274958,
    0.848102755,
    0.923880971,
    1.045632537,
    1.188260658,
    1.284135285,
    1.278042134,
    1.218552727,
    1.135836866,
    0.909968947,
    0.827687372,
    0.839388129,
    0.801841397,
    0.711126591,
    0.661682714,
    0.642400204,
    0.667890486,
    0.734184922,
    0.754796835,
    0.755979996,
    0.791996629,
    0.872181261,
    0.982324694,
    1.107742886,
    1.228495466,
    1.335251056,
    1.433910288,
    1.559341179,
    1.688543702,
    1.75538042,
    1.713983686,
    1.6276104,
    1.504265098,
    1.241758038,
    1.074364487,
    0.921410913,
    0.815418427,
    0.71794441,
    0.665769779,
    0.644015482,
    0.665258285,
    0.728566685,
    0.759115403,
    0.781969925,
    0.843557756,
    0.943151055,
    1.069921443,
    1.219643637,
    1.359810772,
    1.477784277,
    1.58378808,
    1.698179322,
    1.796236023,
    1.824995454,
    1.755927448,
    1.64995032,
    1.534515009,
    1.309729172,
    1.152890105,
    0.941761162,
    0.819136957,
    0.710986876,
    0.659320702,
    0.621257827,
    0.617362242,
    0.643297411,
    0.70327072,
    0.819845634,
    0.910527049,
    1.008283751,
    1.109489086,
    1.223500428,
    1.328327534,
    1.447641546,
    1.550136874,
    1.643126282,
    1.693537859,
    1.660971759,
    1.578982693,
    1.492120215,
    1.398077322,
    1.191889971,
    1.071705131,
    0.93812284,
    0.836633864,
    0.724362166,
    0.670901147,
    0.628466686,
    0.621332435,
    0.64825952,
    0.718261664,
    0.857589666,
    0.969934633,
    1.097895123,
    1.23010016,
    1.37656524,
    1.497115155,
    1.624379521,
    1.732070956,
    1.842287393,
    1.909931575,
    1.879248348,
    1.796187284,
    1.697612406,
    1.552278665,
    1.249284606,
    1.080684816,
    0.920796488,
    0.810955227,
    0.706734955,
    0.654503927,
    0.626669402,
    0.658326726,
    0.731419193,
    0.75492941,
    0.762508806,
    0.809509055,
    0.917813304,
    1.050812471,
    1.194626864,
    1.3163458,
    1.449469491,
    1.569986886,
    1.726347181,
    1.864920098,
    1.895406744,
    1.834477801,
    1.734501761,
    1.585934802,
    1.281806372,
    1.111071163,
    0.942375987,
    0.828811506,
    0.723396909,
    0.672595598,
    0.643754413,
    0.67069683,
    0.742090753,
    0.761970106,
    0.775600442,
    0.827151271,
    0.949272248,
    1.103713754,
    1.281763225,
    1.431279549,
    1.582528793,
    1.718325808,
    1.878602771,
    2.007906798,
    2.020914242,
    1.938847433,
    1.834828802,
    1.668482158,
    1.349092607,
    1.155182584,
    0.921542292,
    0.799274286,
    0.699865176,
    0.654496178,
    0.627965478,
    0.660121801,
    0.727253417,
    0.744167041,
    0.741390454,
    0.777694357,
    0.874313997,
    1.002286009,
    1.153464946,
    1.285593852,
    1.427578397,
    1.559013944,
    1.716427706,
    1.846099453,
    1.870029334,
    1.805163977,
    1.7177607,
    1.570326722,
    1.273372653,
    1.102824949,
    0.920457597,
    0.809596408,
    0.708413216,
    0.658860259,
    0.632391102,
    0.663393208,
    0.735230563,
    0.750398727,
    0.755515795,
    0.801090252,
    0.907246943,
    1.04890743,
    1.21593815,
    1.359739685,
    1.50582058,
    1.636410779,
    1.787943762,
    1.908697861,
    1.925929551,
    1.849235319,
    1.762269772,
    1.609875621,
    1.310052102,
    1.119984592,
    0.888621715,
    0.771209414,
    0.677235854,
    0.633047629,
    0.609213164,
    0.641698277,
    0.706891044,
    0.723263379,
    0.727276083,
    0.762318557,
    0.842730902,
    0.95014276,
    1.088766031,
    1.208541855,
    1.333885729,
    1.451920698,
    1.588579213,
    1.692847436,
    1.700997933,
    1.624883393,
    1.546760434,
    1.439338013,
    1.216239962,
    1.074659262,
    0.905232664,
    0.797585145,
    0.694786253,
    0.644372652,
    0.612683307,
    0.612267808,
    0.644293183,
    0.703900819,
    0.814596296,
    0.917125179,
    1.028311803,
    1.151868965,
    1.291780618,
    1.427307928,
    1.562058323,
    1.677393763,
    1.775432215,
    1.828157938,
    1.788724803,
    1.680260264,
    1.587361221,
    1.462946668,
    1.233123534,
    1.083612198,
    0.902864024,
    0.794017226,
    0.692552649,
    0.643239509,
    0.609573017,
    0.608111476,
    0.639880278,
    0.706349106,
    0.830926416,
    0.942224091,
    1.06770452,
    1.205475863,
    1.359285204,
    1.497362832,
    1.631336167,
    1.746229346,
    1.860498179,
    1.937300876,
    1.910547643,
    1.808691584,
    1.712265328,
    1.548866072,
    1.235593772,
    1.044845778,
    0.838549,
    0.731361811,
    0.647886165,
    0.605690336,
    0.590174431,
    0.631226724,
    0.707549312,
    0.722232449,
    0.702628308,
    0.725269495,
    0.800984243,
    0.903511198,
    1.019408362,
    1.128919228,
    1.246651978,
    1.360947217,
    1.517478546,
    1.673630729,
    1.727363336,
    1.666396187,
    1.587547707,
    1.445871995,
    1.156928698,
    0.987400765,
    0.819484716,
    0.72707678,
    0.64833928,
    0.611029485,
    0.596695282,
    0.635647797,
    0.708804005,
    0.715967375,
    0.692570687,
    0.703729049,
    0.773915935,
    0.875249335,
    1.002270662,
    1.119548335,
    1.241273327,
    1.361486132,
    1.520088627,
    1.673212868,
    1.721901044,
    1.652836235,
    1.583511392,
    1.439841445,
    1.152988993,
    0.990111953,
    0.847303949,
    0.756784368,
    0.668088124,
    0.62674567,
    0.608871501,
    0.646292246,
    0.718799575,
    0.733337304,
    0.720264264,
    0.757570085,
    0.852550917,
    0.985182876,
    1.145037981,
    1.294625632,
    1.442325653,
    1.581412403,
    1.74081662,
    1.876940082,
    1.904574554,
    1.820913181,
    1.735156119,
    1.568886738,
    1.260681429,
    1.077552014,
    0.884550772,
    0.775818604,
    0.68324266,
    0.636791765,
    0.617485441,
    0.65282783,
    0.729532194,
    0.743334415,
    0.740951822,
    0.789024064,
    0.895932613,
    1.044027067,
    1.221241592,
    1.382491447,
    1.534417192,
    1.672521406,
    1.82476328,
    1.949147285,
    1.967835448,
    1.87557903,
    1.788173661,
    1.617026942,
    1.305714392,
    1.10744467,
    0.88009018,
    0.76288096,
    0.670891396,
    0.626099805,
    0.606390664,
    0.64032663,
    0.711186342,
    0.730137269,
    0.737153323,
    0.788833794,
    0.887753755,
    1.021272367,
    1.190161756,
    1.342938456,
    1.484938384,
    1.617470677,
    1.754821345,
    1.8549465,
    1.853160401,
    1.754566305,
    1.664778624,
    1.52558847,
    1.276611361,
    1.112358504,
    0.911780678,
    0.794006696,
    0.692171552,
    0.640854161,
    0.612088704,
    0.612663297,
    0.649393015,
    0.711366566,
    0.824731439,
    0.942020178,
    1.070160269,
    1.217780126,
    1.385072483,
    1.549816913,
    1.699308602,
    1.827153111,
    1.924070606,
    1.971343952,
    1.92256992,
    1.797356849,
    1.693329571,
    1.540730738,
    1.288476193,
    1.123593534,
    0.931595311,
    0.81318239,
    0.706820734,
    0.653364719,
    0.619802177,
    0.61664513,
    0.653542793,
    0.725697194,
    0.862303396,
    1.001085825,
    1.159058576,
    1.337830917,
    1.536181115,
    1.716653556,
    1.874681547,
    2.00804559,
    2.120829957,
    2.183854901,
    2.137340994,
    2.012188833,
    1.893657406,
    1.691936052,
    1.345726346,
    1.134471117,
    0.913288849,
    0.786406311,
    0.688617804,
    0.636972058,
    0.617753229,
    0.653161043,
    0.736198051,
    0.763059563,
    0.772545171,
    0.844490991,
    0.979597516,
    1.152719551,
    1.34505032,
    1.521538592,
    1.680700476,
    1.825204558,
    1.98143752,
    2.113195142,
    2.129037074,
    2.031047175,
    1.912204419,
    1.710349722,
    1.365585857,
    1.147737109,
    0.883785669,
    0.757111936,
    0.670994986,
    0.628888964,
    0.612152662,
    0.648488823,
    0.725209367,
    0.73922914,
    0.731259143,
    0.766275681,
    0.865464879,
    1.000852178,
    1.165854311,
    1.316553578,
    1.455652673,
    1.590004265,
    1.743707052,
    1.880719274,
    1.90901256,
    1.830588084,
    1.740087571,
    1.565022873,
    1.251621323,
    1.063771196,
    0.853925277,
    0.748162554,
    0.662783504,
    0.623209711,
    0.606152437,
    0.645394275,
    0.718293233,
    0.732418512,
    0.716409981,
    0.746885765,
    0.833961801,
    0.957200876,
    1.110385014,
    1.2549022,
    1.392276073,
    1.527306342,
    1.677811421,
    1.808596363,
    1.83864025,
    1.773023037,
    1.690488345,
    1.525024835,
    1.223834171,
    1.047687045,
    0.856148729,
    0.754159483,
    0.668466464,
    0.625940089,
    0.608580673,
    0.647254762,
    0.723376942,
    0.734399733,
    0.722996634,
    0.754147869,
    0.840942949,
    0.965352582,
    1.121320498,
    1.26497795,
    1.397610283,
    1.525736418,
    1.667824877,
    1.790677681,
    1.819107646,
    1.753710989,
    1.677803075,
    1.517864058,
    1.224660898,
    1.044370523,
    0.840590197,
    0.737521983,
    0.653412944,
    0.613185512,
    0.595713783,
    0.633417202,
    0.703362131,
    0.718805298,
    0.71497695,
    0.746756375,
    0.821866222,
    0.927377643,
    1.070730806,
    1.202211303,
    1.322247752,
    1.44315944,
    1.57005604,
    1.669795379,
    1.679735767,
    1.610529373,
    1.534504495,
    1.409502676,
    1.18157595,
    1.029005294,
    0.811905056,
    0.712929787,
    0.634420275,
    0.59700648,
    0.575390089,
    0.586141912,
    0.617612634,
    0.666816365,
    0.744590763,
    0.798367658,
    0.849486445,
    0.90794478,
    0.987784829,
    1.078381388,
    1.168137133,
    1.260060301,
    1.343163763,
    1.407279542,
    1.401623899,
    1.342193463,
    1.285263647,
    1.191263758,
    1.006005581,
    0.89953774,
    0.798562218,
    0.727610203,
    0.645595207,
    0.606740087,
    0.580799461,
    0.58855916,
    0.619805332,
    0.678947228,
    0.778015222,
    0.848667398,
    0.923840524,
    1.007540204,
    1.11365666,
    1.215285901,
    1.310614381,
    1.405312611,
    1.502495381,
    1.585079107,
    1.584748892,
    1.53066476,
    1.463458286,
    1.32308583,
    1.047388968,
    0.898922084,
    0.785154622,
    0.708584518,
    0.632948116,
    0.59460587,
    0.582043763,
    0.627310961,
    0.705516851,
    0.719289495,
    0.69297252,
    0.704297194,
    0.766565979,
    0.854146596,
    0.960842712,
    1.06570821,
    1.166217633,
    1.275310172,
    1.416095955,
    1.562927037,
    1.620304854,
    1.587946923,
    1.51575113,
    1.370698703,
    1.091390315,
    0.928502128,
    0.738461771,
    0.66040631,
    0.60231878,
    0.576941348,
    0.568776849,
    0.617200818,
    0.687937096,
    0.685578348,
    0.633903633,
    0.595222539,
    0.606714271,
    0.638108355,
    0.698086062,
    0.760004008,
    0.827849239,
    0.919522511,
    1.056488049,
    1.209919325,
    1.290419862,
    1.287697313,
    1.255006088,
    1.151612234,
    0.917663913,
    0.802168397,
    0.75396445,
    0.701021824,
    0.629581355,
    0.598258669,
    0.585230434,
    0.63085037,
    0.701220822,
    0.707900435,
    0.670800949,
    0.664814785,
    0.708513667,
    0.780244283,
    0.88314637,
    0.986093053,
    1.085818946,
    1.199408901,
    1.337554971,
    1.472791236,
    1.526238182,
    1.505213593,
    1.450156714,
    1.316734035,
    1.054869129,
    0.910601159,
    0.762445701,
    0.689473654,
    0.622671441,
    0.591261687,
    0.57946575,
    0.626510191,
    0.698928379,
    0.699274836,
    0.658879623,
    0.64030499,
    0.668024345,
    0.722275603,
    0.80861961,
    0.894002834,
    0.977516178,
    1.076811173,
    1.2050388,
    1.336729862,
    1.398139796,
    1.388071553,
    1.351019823,
    1.237339901,
    0.998163603,
    0.861303522,
    0.720556816,
    0.656605165,
    0.596509026,
    0.570737079,
    0.560573643,
    0.608897565,
    0.674030445,
    0.676125333,
    0.635392043,
    0.604497007,
    0.604311572,
    0.6199728,
    0.673000824,
    0.72859268,
    0.786771182,
    0.871427808,
    0.983503437,
    1.096218322,
    1.148487271,
    1.144687404,
    1.117524,
    1.053723549,
    0.893847589,
    0.81317223,
    0.780383535,
    0.724412034,
    0.643630717,
    0.60463413,
    0.581868876,
    0.59231175,
    0.625615298,
    0.678296516,
    0.762729164,
    0.824483783,
    0.885103338,
    0.954770219,
    1.050348946,
    1.154155553,
    1.245972973,
    1.341762353,
    1.416414002,
    1.468611064,
    1.453433375,
    1.401843091,
    1.337410215,
    1.228486667,
    1.068159509,
    0.902681203,
    0.702301187,
    0.620035402,
    0.554447772,
    0.527956515,
    0.508036806,
    0.526119817,
    0.545075019,
    0.575436679,
    0.651164746,
    0.715801236,
    0.775574753,
    0.847318656,
    0.946558567,
    1.041326785,
    1.152452563,
    1.266632411,
    1.37662872,
    1.439200953,
    1.432128067,
    1.3401906,
    1.294157955,
    1.17004328,
    1.010060625,
    0.875421915,
    0.781253611,
    0.697462827,
    0.595328351,
    0.552804122,
    0.544833428,
    0.546144965,
    0.565326372,
    0.619143812,
    0.734742628,
    0.8588454,
    0.993633658,
    1.130665449,
    1.251959464,
    1.37040362,
    1.547065387,
    1.664945748,
    1.778806082,
    1.852195269,
    1.836129048,
    1.685250755,
    1.558014624,
    1.40592568,
    1.146326547,
    0.937332576,
    0.672049002,
    0.592381817,
    0.541527464,
    0.520970756,
    0.520170443,
    0.549614123,
    0.617706466,
    0.611608874,
    0.569243206,
    0.546817597,
    0.602605209,
    0.636986827,
    0.709470798,
    0.769864119,
    0.854939992,
    0.971731665,
    1.127166113,
    1.286322378,
    1.34154005,
    1.312569614,
    1.255242772,
    1.147409501,
    0.926968927,
    0.7902785,
    0.713416018,
    0.644552093,
    0.564472252,
    0.512069814,
    0.487789534,
    0.503425952,
    0.517389169,
    0.534453149,
    0.632628151,
    0.741550129,
    0.875330401,
    1.028818016,
    1.150073101,
    1.275415383,
    1.430484452,
    1.50138854,
    1.586790201,
    1.678212424,
    1.685418579,
    1.60391352,
    1.525615695,
    1.33464047,
    1.083113007,
    0.903768941,
    0.721084352,
    0.643440443,
    0.56506764,
    0.536987113,
    0.526232899,
    0.562553054,
    0.611814343,
    0.597424538,
    0.557777395,
    0.551818245,
    0.571291573,
    0.615206216,
    0.699328629,
    0.766563885,
    0.889287223,
    1.009769606,
    1.159071694,
    1.294434315,
    1.388762528,
    1.33870356,
    1.285443672,
    1.152031136,
    0.932850029,
    0.788030379,
    0.660257577,
    0.620794361,
    0.56192176,
    0.536166152,
    0.529262946,
    0.555284846,
    0.618299186,
    0.609228068,
    0.567782695,
    0.546858707,
    0.553816515,
    0.588923455,
    0.666027939,
    0.719566573,
    0.796928981,
    0.891356164,
    1.02888903,
    1.176699137,
    1.265658106,
    1.239488419,
    1.195814746,
    1.104147057,
    0.914447463,
    0.770951151,
    0.682604007,
    0.635677051,
    0.569203447,
    0.534385727,
    0.51622368,
    0.55256476,
    0.622829227,
    0.608266508,
    0.594294431,
    0.589103271,
    0.629367364,
    0.688343434,
    0.787944349,
    0.882619001,
    0.996171769,
    1.091645096,
    1.217628944,
    1.30541083,
    1.34242345,
    1.290612483,
    1.224114001,
    1.115827536,
    0.958287985,
    0.832965177,
    0.720988842,
    0.631999732,
    0.554447772,
    0.527956515,
    0.508036806,
    0.526119817,
    0.543893368,
    0.575436679,
    0.651164746,
    0.715801236,
    0.775574753,
    0.847318656,
    0.946558567,
    1.041326785,
    1.152452563,
    1.266632411,
    1.37662872,
    1.439200953,
    1.432128067,
    1.337162463,
    1.294157955,
    1.17004328,
    1.010060625,
    0.879215156,
    0.745140521,
    0.667159526,
    0.584244612,
    0.547371257,
    0.53439471,
    0.554109094,
    0.589465504,
    0.639059469,
    0.727886288,
    0.799453696,
    0.899615555,
    0.988908968,
    1.113383482,
    1.234389435,
    1.369929213,
    1.480789809,
    1.594345716,
    1.668916057,
    1.646542208,
    1.576605767,
    1.493022669,
    1.337718095,
    1.078935212,
    0.893632595,
    0.754249312,
    0.663309777,
    0.59614665,
    0.554155633,
    0.548431798,
    0.592374734,
    0.675204984,
    0.683128846,
    0.656022007,
    0.668971838,
    0.736081752,
    0.830704992,
    0.945179399,
    1.03042605,
    1.15864578,
    1.24634559,
    1.410640002,
    1.562314309,
    1.629213159,
    1.589408077,
    1.508850747,
    1.337635074,
    1.077431882,
    0.923350411,
    0.774989379,
    0.672721471,
    0.598381178,
    0.560256062,
    0.541069233,
    0.61081915,
    0.685683119,
    0.688776014,
    0.673717076,
    0.713475841,
    0.809736006,
    0.928869318,
    1.092542006,
    1.234959844,
    1.392699392,
    1.521507023,
    1.699636802,
    1.82839041,
    1.858067881,
    1.805836095,
    1.715355773,
    1.505104673,
    1.212426787,
    1.008221914,
    0.762506917,
    0.649784034,
    0.567372839,
    0.544189161,
    0.544032221,
    0.5830754,
    0.650532808,
    0.659593376,
    0.635624991,
    0.654230588,
    0.727362151,
    0.84232946,
    0.956380016,
    1.075580566,
    1.221730044,
    1.37465075,
    1.546348753,
    1.677923341,
    1.724604814,
    1.681851559,
    1.589714649,
    1.403375541,
    1.137935949,
    0.943069733,
    0.73402117,
    0.631185194,
    0.567559267,
    0.532765005,
    0.527738939,
    0.569498639,
    0.646652809,
    0.671201731,
    0.645513286,
    0.648182246,
    0.711821723,
    0.803248526,
    0.922901045,
    1.038400807,
    1.161960822,
    1.27597907,
    1.418709244,
    1.541505139,
    1.593268947,
    1.564169563,
    1.512350549,
    1.330815986,
    1.078467192,
    0.913544569,
    0.711021473,
    0.610423798,
    0.541636423,
    0.520343444,
    0.516091515,
    0.569893919,
    0.652910553,
    0.666499891,
    0.642026324,
    0.642910856,
    0.69580158,
    0.777514081,
    0.891505019,
    0.982258411,
    1.10736104,
    1.22182713,
    1.395922587,
    1.505320362,
    1.525918581,
    1.485120029,
    1.403745605,
    1.250065505,
    1.056978188,
    0.928457634,
    0.75731452,
    0.663357626,
    0.595969049,
    0.543195409,
    0.536560082,
    0.550322672,
    0.619296906,
    0.666175138,
    0.733110778,
    0.819677383,
    0.895134142,
    0.98741765,
    1.079419483,
    1.177042333,
    1.306157353,
    1.425441211,
    1.536009596,
    1.579739566,
    1.563189193,
    1.473740558,
    1.38106293,
    1.222199559,
    1.053072829,
    0.915639739,
    0.677070873,
    0.598836395,
    0.548979861,
    0.523385131,
    0.516024413,
    0.548608642,
    0.598181067,
    0.667531836,
    0.728903588,
    0.764946028,
    0.789093591,
    0.805809322,
    0.851168568,
    0.916548546,
    0.994932545,
    1.079057274,
    1.185075514,
    1.246674252,
    1.28679721,
    1.250982511,
    1.187094881,
    1.060518248,
    0.839135475,
    0.711336916,
    0.626763307,
    0.581175847,
    0.532496941,
    0.502318612,
    0.497077124,
    0.553683083,
    0.632601548,
    0.6509217,
    0.589066031,
    0.559588602,
    0.578650407,
    0.609753534,
    0.666147878,
    0.744292207,
    0.827325055,
    0.923266862,
    1.047366446,
    1.185651943,
    1.261972962,
    1.258948444,
    1.198013783,
    1.072822929,
    0.86584178,
    0.733689437,
    0.669141769,
    0.605499869,
    0.549853787,
    0.527080148,
    0.515576032,
    0.561983594,
    0.646688274,
    0.66276044,
    0.632711473,
    0.602488846,
    0.631130252,
    0.70048945,
    0.796883819,
    0.90329742,
    1.013480695,
    1.125228374,
    1.270446313,
    1.391896954,
    1.437315851,
    1.415173881,
    1.353091265,
    1.209983206,
    0.975795219,
    0.828089231,
    0.70420607,
    0.629193766,
    0.57246752,
    0.537520819,
    0.537271821,
    0.57968952,
    0.655125447,
    0.669943303,
    0.629242522,
    0.642561572,
    0.673424639,
    0.726157301,
    0.811272173,
    0.925478475,
    1.041096557,
    1.148383356,
    1.321136692,
    1.434297087,
    1.479019162,
    1.465916325,
    1.41364308,
    1.231279321,
    0.97426955,
    0.823059259,
    0.628939045,
    0.558566298,
    0.508591479,
    0.496403068,
    0.496463242,
    0.559759448,
    0.643712586,
    0.633095096,
    0.586411828,
    0.55128228,
    0.519453216,
    0.513292514,
    0.553388134,
    0.592962138,
    0.62952685,
    0.723016519,
    0.892494499,
    1.038626682,
    1.114485544,
    1.129368969,
    1.100275403,
    0.998260409,
    0.838554629,
    0.723544194,
    0.648657934,
    0.590593597,
    0.529854568,
    0.521015117,
    0.520965745,
    0.574352145,
    0.6435664,
    0.665095251,
    0.588111347,
    0.557228902,
    0.539365541,
    0.550766912,
    0.592124371,
    0.663476576,
    0.737207083,
    0.836561588,
    0.975946008,
    1.071708962,
    1.099831294,
    1.107969042,
    1.078499698,
    0.993981215,
    0.858178322,
    0.751544618,
    0.683230163,
    0.631872128,
    0.562023257,
    0.532064251,
    0.527167406,
    0.530533046,
    0.573985189,
    0.640807232,
    0.70427364,
    0.734875004,
    0.757866982,
    0.791805929,
    0.850885011,
    0.93714816,
    1.015078145,
    1.111222903,
    1.217930612,
    1.253763863,
    1.238777594,
    1.192683731,
    1.126802453,
    1.019555264,
    0.867800145,
    0.789333879,
    0.69795133,
    0.631302074,
    0.567786128,
    0.552004708,
    0.540036983,
    0.56578506,
    0.619363676,
    0.689078363,
    0.772270395,
    0.812359456,
    0.87497431,
    0.927157643,
    1.003120169,
    1.092818285,
    1.186652153,
    1.277912847,
    1.336687035,
    1.402127348,
    1.418251959,
    1.405570296,
    1.32766349,
    1.165325202,
    0.930748934,
    0.781880487,
    0.620254421,
    0.56146448,
    0.525429052,
    0.510550782,
    0.511335672,
    0.582842511,
    0.671156641,
    0.686826754,
    0.618779948,
    0.588110264,
    0.5873375,
    0.585588879,
    0.5902072,
    0.613193923,
    0.659364019,
    0.762167993,
    0.887114165,
    1.04610482,
    1.1623079,
    1.190677257,
    1.139504154,
    1.017570116,
    0.823775056,
    0.70423881,
    0.613826845,
    0.564204771,
    0.523188793,
    0.517339444,
    0.521184489,
    0.588612354,
    0.680282817,
    0.681630915,
    0.626680999,
    0.569636809,
    0.567551816,
    0.559467617,
    0.607292144,
    0.631751749,
    0.678966529,
    0.77081057,
    0.903929216,
    1.052340545,
    1.163898122,
    1.18151472,
    1.151916328,
    1.023155589,
    0.82361799,
    0.713245186,
    0.61993127,
    0.566916791,
    0.518639018,
    0.52583657,
    0.527230223,
    0.580236944,
    0.663691765,
    0.67817933,
    0.584055263,
    0.543029294,
    0.523340288,
    0.541535033,
    0.576183617,
    0.613583445,
    0.705922184,
    0.815411902,
    0.94524323,
    1.079759572,
    1.182281209,
    1.200137353,
    1.146554906,
    1.027023841,
    0.841438452,
    0.735486066,
    0.65182333,
    0.601951701,
    0.558626035,
    0.548514646,
    0.549570074,
    0.608215373,
    0.702999851,
    0.694692241,
    0.644134124,
    0.588189652,
    0.57660869,
    0.583857102,
    0.619047835,
    0.655815761,
    0.710710041,
    0.799049823,
    0.920872967,
    1.057705635,
    1.163877222,
    1.171966099,
    1.144210176,
    1.042272862,
    0.859058667,
    0.730531153,
    0.648773251,
    0.601763933,
    0.554598402,
    0.548054886,
    0.560070649,
    0.625927016,
    0.698329544,
    0.724236096,
    0.665717329,
    0.622144896,
    0.609439189,
    0.609401018,
    0.629898022,
    0.659753444,
    0.706348194,
    0.810968487,
    0.949406523,
    1.040748525,
    1.117736966,
    1.125624153,
    1.090188128,
    1.023466983,
    0.896337149,
    0.797675327,
    0.721682786,
    0.641985882,
    0.575638,
    0.564921196,
    0.559263609,
    0.585299444,
    0.631187094,
    0.695727041,
    0.721503008,
    0.754525239,
    0.757069833,
    0.775538285,
    0.800261009,
    0.852189428,
    0.884316274,
    0.975228564,
    1.053547146,
    1.121850739,
    1.173516747,
    1.115888447,
    1.049448036,
    0.981971286,
    0.869746319,
    0.775825436,
    0.664257418,
    0.606111389,
    0.55263744,
    0.534157432,
    0.546386301,
    0.578825336,
    0.610749947,
    0.68120221,
    0.758548519,
    0.77034766,
    0.800622495,
    0.809348954,
    0.838555406,
    0.88923138,
    0.944039985,
    1.037017049,
    1.126101513,
    1.192096784,
    1.2546129,
    1.254902767,
    1.174322593,
    1.041282056,
    0.836504879,
    0.667728418,
    0.57166428,
    0.471350321,
    0.418727785,
    0.402117907,
    0.428411897,
    0.516161357,
    0.655751834,
    0.684020349,
    0.635892754,
    0.6247992,
    0.647991831,
    0.6610008,
    0.715391851,
    0.802826796,
    0.890737831,
    1.018759011,
    1.181386894,
    1.334748064,
    1.437102559,
    1.442889877,
    1.316870681,
    1.113361934,
    0.823752399,
    0.662699743,
    0.492313024,
    0.410866125,
    0.381049865,
    0.382726379,
    0.399378246,
    0.497524632,
    0.614680482,
    0.677692652,
    0.615536566,
    0.55995419,
    0.539105121,
    0.527855254,
    0.552810022,
    0.57397775,
    0.619351763,
    0.659249806,
    0.795348399,
    0.957556657,
    1.083557152,
    1.149548641,
    1.102576098,
    0.942004019,
    0.702136201,
    0.587153395,
    0.490632676,
    0.456437311,
    0.432771906,
    0.426331651,
    0.463694399,
    0.544013916,
    0.674739587,
    0.714087783,
    0.624593828,
    0.592404275,
    0.586559622,
    0.575567488,
    0.609411345,
    0.637995167,
    0.676636747,
    0.723913983,
    0.852506196,
    1.008388314,
    1.140106655,
    1.183525448,
    1.131733141,
    0.98027917,
    0.726362471,
    0.602787029,
    0.488166309,
    0.445068873,
    0.419448294,
    0.403935571,
    0.4430501,
    0.537610466,
    0.660064964,
    0.704633749,
    0.616439443,
    0.573572053,
    0.554276605,
    0.553403461,
    0.577701954,
    0.590304316,
    0.627642096,
    0.660890575,
    0.779926474,
    0.907111856,
    1.077157698,
    1.130448193,
    1.095258898,
    0.953049419,
    0.706919884,
    0.598323757,
    0.497018805,
    0.442806332,
    0.412851976,
    0.406947182,
    0.445462934,
    0.536066261,
    0.659187605,
    0.706617706,
    0.62946801,
    0.583668379,
    0.557598886,
    0.547700973,
    0.560239181,
    0.587197925,
    0.604432982,
    0.653767557,
    0.767337522,
    0.870809281,
    0.985942129,
    1.032052675,
    0.981876554,
    0.880941787,
    0.709759083,
    0.611000329,
    0.524862544,
    0.466319506,
    0.420305347,
    0.399507324,
    0.419382999,
    0.467249745,
    0.53535654,
    0.617275962,
    0.6858976,
    0.73972279,
    0.752034285,
    0.782370755,
    0.811193468,
    0.852695914,
    0.901714289,
    0.942149601,
    1.015612492,
    1.070742345,
    1.117520016,
    1.117021623,
    1.048274701,
    0.910099769,
    0.742377947,
    0.651609029,
    0.519088681,
    0.452883699,
    0.427063948,
    0.408223411,
    0.422309072,
    0.468427985,
    0.535659127,
    0.625003902,
    0.701214953,
    0.739110794,
    0.775506578,
    0.777603217,
    0.819273005,
    0.852847446,
    0.868475338,
    0.904269553,
    0.992198537,
    1.105265673,
    1.201066589,
    1.214358705,
    1.141478567,
    0.99048464,
    0.73503096,
    0.601610086,
    0.489068204,
    0.416355733,
    0.401394866,
    0.3935437,
    0.429521308,
    0.529957938,
    0.664078446,
    0.72169942,
    0.662100869,
    0.609740707,
    0.611286207,
    0.626591351,
    0.641053986,
    0.642668262,
    0.68352828,
    0.76092769,
    0.857750914,
    1.02705079,
    1.202475486,
    1.235482507,
    1.156667841,
    0.990289512,
    0.734868146,
    0.607872254,
    0.494650934,
    0.451005676,
    0.424537465,
    0.423147847,
    0.470932596,
    0.563606448,
    0.701290945,
    0.751704863,
    0.673522008,
    0.609951773,
    0.577875962,
    0.584216392,
    0.592923787,
    0.588511843,
    0.612442232,
    0.666511286,
    0.811094499,
    0.982197836,
    1.157725478,
    1.199581705,
    1.134628059,
    0.982168213,
    0.736788922,
    0.619537749,
    0.523402624,
    0.477026684,
    0.445204179,
    0.44521679,
    0.486511492,
    0.585298254,
    0.701951021,
    0.765286498,
    0.691273919,
    0.626186021,
    0.59626591,
    0.600470509,
    0.616188964,
    0.627952786,
    0.647129872,
    0.698143128,
    0.832722541,
    1.015686918,
    1.16558006,
    1.225006216,
    1.17181055,
    1.012796479,
    0.763668112,
    0.645345607,
    0.534712538,
    0.49229336,
    0.454936499,
    0.450917103,
    0.490785974,
    0.595146079,
    0.73913826,
    0.791118981,
    0.708814464,
    0.667448783,
    0.648357605,
    0.633034677,
    0.632764568,
    0.640766915,
    0.675130048,
    0.738955656,
    0.844183702,
    1.001138853,
    1.162665433,
    1.24861071,
    1.185139912,
    1.048817998,
    0.809644969,
    0.672835223,
    0.552329741,
    0.477366146,
    0.443380123,
    0.44323504,
    0.484161052,
    0.581308251,
    0.728469973,
    0.763754471,
    0.725851161,
    0.685604317,
    0.671746046,
    0.648256103,
    0.684312313,
    0.680244472,
    0.698432811,
    0.756245913,
    0.855188473,
    0.980290013,
    1.088754753,
    1.109298425,
    1.042197746,
    0.929450154,
    0.761960026,
    0.675617948,
    0.601983385,
    0.554094455,
    0.515716227,
    0.513634042,
    0.544554331,
    0.590254036,
    0.671050345,
    0.765912934,
    0.862225046,
    0.911677652,
    0.948109756,
    0.91669255,
    0.908699085,
    0.941221863,
    0.934709518,
    0.978014995,
    1.033605817,
    1.112733825,
    1.176585272,
    1.174635638,
    1.099140283,
    0.999666103,
    0.831550208,
    0.713632689,
    0.585665613,
    0.516541216,
    0.469521793,
    0.466570994,
    0.47771729,
    0.517011669,
    0.592820303,
    0.691471256,
    0.771230079,
    0.805555414,
    0.835611422,
    0.840821539,
    0.86765007,
    0.87246082,
    0.886039719,
    0.925640971,
    1.008128888,
    1.148509429,
    1.234711625,
    1.2671088,
    1.198352028,
    1.061057568,
    0.79843958,
    0.655206828,
    0.533005026,
    0.461000048,
    0.427306844,
    0.425365575,
    0.464844979,
    0.561228183,
    0.702663089,
    0.750312913,
    0.675558675,
    0.629187718,
    0.608534528,
    0.593708887,
    0.602104123,
    0.610008334,
    0.62563438,
    0.679214537,
    0.78335938,
    0.975090292,
    1.163870777,
    1.231098577,
    1.159046546,
    1.016227825,
    0.76658851,
    0.639688265,
    0.532882556,
    0.47911703,
    0.456437731,
    0.453553548,
    0.488567044,
    0.580281921,
    0.720639884,
    0.773913467,
    0.699649635,
    0.632188648,
    0.616267781,
    0.592064928,
    0.596245133,
    0.598540152,
    0.612772151,
    0.66102663,
    0.782957528,
    0.960353849,
    1.138991856,
    1.166200459,
    1.114278552,
    0.964864101,
    0.720321208,
    0.615425858,
    0.53399239,
    0.502875906,
    0.473664431,
    0.472602402,
    0.516076103,
    0.622584777,
    0.749829022,
    0.816723978,
    0.752690283,
    0.71352432,
    0.673378498,
    0.665958567,
    0.678659039,
    0.667219703,
    0.690177426,
    0.741045794,
    0.857572825,
    1.052125781,
    1.215440032,
    1.247981356,
    1.204394157,
    1.05753555,
    0.806426896,
    0.674049386,
    0.547665994,
    0.495776754,
    0.465473128,
    0.457403888,
    0.494400351,
    0.58848692,
    0.72568177,
    0.770012209,
    0.685920006,
    0.613878707,
    0.581872674,
    0.557925979,
    0.561986107,
    0.560498631,
    0.584032386,
    0.63819427,
    0.766998491,
    0.915613285,
    1.099850885,
    1.157542185,
    1.113271152,
    0.987628429,
    0.757538268,
    0.658058917,
    0.57725965,
    0.548874563,
    0.504348595,
    0.499353757,
    0.536455847,
    0.640719279,
    0.773103181,
    0.833600361,
    0.782832758,
    0.744836532,
    0.719564505,
    0.70986256,
    0.732154101,
    0.714161204,
    0.717892646,
    0.760314533,
    0.85310519,
    1.013251907,
    1.123576307,
    1.166787475,
    1.104246218,
    1.016944504,
    0.84275116,
    0.741554492,
    0.636518182,
    0.538960748,
    0.498164472,
    0.48241292,
    0.497958021,
    0.547376091,
    0.612334048,
    0.727370153,
    0.803678959,
    0.833232614,
    0.818393049,
    0.793725627,
    0.808623974,
    0.809780049,
    0.804624021,
    0.845687633,
    0.909040996,
    1.025613925,
    1.111330634,
    1.106805472,
    1.046633511,
    0.96205649,
    0.783583847,
    0.697499828,
    0.578034482,
    0.523359472,
    0.474947447,
    0.46926964,
    0.480939854,
    0.529053916,
    0.601563888,
    0.697644687,
    0.800322753,
    0.83504741,
    0.852638122,
    0.872075618,
    0.884561505,
    0.894409552,
    0.89556841,
    0.924899146,
    0.995276127,
    1.124834508,
    1.218031176,
    1.231533817,
    1.16230931,
    1.020553183,
    0.758860036,
    0.657291408,
    0.539973364,
    0.488217372,
    0.458933396,
    0.451737536,
    0.498255314,
    0.612230623,
    0.753437602,
    0.808378586,
    0.743254909,
    0.699438533,
    0.679121279,
    0.68518919,
    0.688834444,
    0.684736405,
    0.716492272,
    0.757353835,
    0.859254199,
    1.047472179,
    1.209576627,
    1.244960589,
    1.176596175,
    1.037571059,
    0.7900011,
    0.675692208,
    0.577465526,
    0.53803763,
    0.509655813,
    0.516505231,
    0.550663726,
    0.641224912,
    0.781421595,
    0.825896937,
    0.778428015,
    0.722643562,
    0.704671254,
    0.697270593,
    0.693717589,
    0.693055133,
    0.697279614,
    0.742365472,
    0.883211253,
    1.082587825,
    1.250114508,
    1.286877399,
    1.209120714,
    1.083358211,
    0.824291837,
    0.699418925,
    0.594314139,
    0.55155076,
    0.5245337,
    0.524306011,
    0.558070515,
    0.661431967,
    0.802933922,
    0.863309665,
    0.798638111,
    0.743653712,
    0.707975976,
    0.692040864,
    0.710892149,
    0.70304681,
    0.721798021,
    0.75435745,
    0.87672577,
    1.065420859,
    1.249635939,
    1.277226577,
    1.228472729,
    1.108401474,
    0.860989747,
    0.727108048,
    0.600383004,
    0.539942,
    0.513387033,
    0.511469932,
    0.545275268,
    0.642927718,
    0.797723889,
    0.845376894,
    0.773172711,
    0.721748302,
    0.684089009,
    0.672517203,
    0.674736552,
    0.666756665,
    0.682385905,
    0.746114138,
    0.870628704,
    1.04912541,
    1.216486055,
    1.230960261,
    1.177115862,
    1.062506658,
    0.829907075,
    0.706089873,
    0.620013299,
    0.574570758,
    0.542896375,
    0.531158551,
    0.564646479,
    0.67083393,
    0.801037647,
    0.869827755,
    0.836357769,
    0.793559095,
    0.790616737,
    0.75466289,
    0.761612019,
    0.75468278,
    0.756622618,
    0.786203101,
    0.907949742,
    1.037714197,
    1.185556055,
    1.210556867,
    1.156651872,
    1.065672799,
    0.87173261,
    0.779792813,
    0.685106308,
    0.623344202,
    0.569316144,
    0.562853209,
    0.595827755,
    0.642810859,
    0.729752335,
    0.850613255,
    0.953181976,
    0.995767696,
    1.012807177,
    1.001754797,
    0.982870629,
    0.962006525,
    0.950932639,
    0.983022359,
    1.04483047,
    1.16577471,
    1.291874288,
    1.265440156,
    1.214586971,
    1.111968665,
    0.931596515,
    0.820036351,
    0.707416031,
    0.624623742,
    0.57688601,
    0.560705497,
    0.580241626,
    0.62469188,
    0.694578543,
    0.833611025,
    0.955787851,
    1.028682414,
    1.061424663,
    1.058445485,
    1.060305455,
    1.052446466,
    1.040216,
    1.052315892,
    1.15559871,
    1.304290023,
    1.454030003,
    1.458342257,
    1.397828567,
    1.242581088,
    0.95857327,
    0.794583891,
    0.657574761,
    0.57081652,
    0.540043325,
    0.546654215,
    0.576453772,
    0.685151108,
    0.843952867,
    0.915685803,
    0.878474864,
    0.868206726,
    0.869126979,
    0.867715425,
    0.849460867,
    0.841753298,
    0.821871489,
    0.865798078,
    0.97334661,
    1.180552311,
    1.346950425,
    1.380598689,
    1.303481881,
    1.179565535,
    0.918283003,
    0.769287786,
    0.606996541,
    0.543518595,
    0.516461612,
    0.52795625,
    0.565115704,
    0.652340448,
    0.776119598,
    0.859982697,
    0.805768864,
    0.744377012,
    0.710256086,
    0.674901663,
    0.684763089,
    0.67665741,
    0.695578449,
    0.743477627,
    0.850168467,
    1.040928403,
    1.212932532,
    1.235030768,
    1.174255487,
    1.051296777,
    0.806180402,
    0.682312671,
    0.584411289,
    0.555497735,
    0.525270486,
    0.52248028,
    0.578924033,
    0.665011066,
    0.802501249,
    0.861320675,
    0.798414872,
    0.739183249,
    0.723372675,
    0.699534069,
    0.71427129,
    0.727244085,
    0.747600447,
    0.790582286,
    0.912678524,
    1.082470653,
    1.224804455,
    1.249030563,
    1.179594366,
    1.044533266,
    0.793765836,
    0.691316822,
    0.594967555,
    0.576478942,
    0.560042915,
    0.565042724,
    0.585562934,
    0.660752648,
    0.773758739,
    0.818127156,
    0.781769929,
    0.739582484,
    0.716048205,
    0.714561289,
    0.710624394,
    0.699010444,
    0.708774702,
    0.738074083,
    0.826339401,
    0.95732993,
    1.098222947,
    1.089220674,
    1.053574119,
    0.986262515,
    0.800059635,
    0.681253553,
    0.554480417,
    0.506297263,
    0.496974474,
    0.498425436,
    0.524599823,
    0.585104622,
    0.691762235,
    0.739595791,
    0.691477446,
    0.648403499,
    0.603462236,
    0.577250276,
    0.589912202,
    0.579533402,
    0.571038054,
    0.590567233,
    0.688800959,
    0.848793063,
    0.999955434,
    1.012102511,
    0.968087529,
    0.903823611,
    0.797729838,
    0.712732231,
    0.579600951,
    0.536495351,
    0.517555191,
    0.509943231,
    0.522601194,
    0.551149867,
    0.620268746,
    0.707732993,
    0.766661139,
    0.787081528,
    0.773325615,
    0.752313725,
    0.757886805,
    0.755519033,
    0.7529309,
    0.77332712,
    0.829879622,
    0.945951671,
    1.06646563,
    1.05443353,
    1.001408818,
    0.927863752,
    0.812456556,
    0.723845645,
    0.616465998,
    0.574077494,
    0.543081146,
    0.535495767,
    0.546851048,
    0.583139969,
    0.650901914,
    0.742129474,
    0.800363326,
    0.818201072,
    0.830567615,
    0.828644464,
    0.839359822,
    0.85132601,
    0.884938636,
    0.911285086,
    1.017852672,
    1.214859151,
    1.245379756,
    1.188255257,
    1.119909648,
    1.008289344,
    0.793630482,
    0.686196651,
    0.60384093,
    0.565698212,
    0.543228008,
    0.538836695,
    0.56583765,
    0.641819194,
    0.757004287,
    0.803106656,
    0.736991528,
    0.69295498,
    0.682695711,
    0.669644756,
    0.662720332,
    0.655356413,
    0.674720067,
    0.708778597,
    0.853866654,
    1.114780087,
    1.215386909,
    1.193553205,
    1.132389348,
    1.027473196,
    0.818522497,
    0.711560056,
    0.626885027,
    0.586033528,
    0.565944702,
    0.566291918,
    0.593507146,
    0.668170587,
    0.781023849,
    0.823973739,
    0.758680398,
    0.706750898,
    0.691042269,
    0.674553292,
    0.673208552,
    0.66489164,
    0.681357893,
    0.718436343,
    0.86521626,
    1.1218823,
    1.218463996,
    1.195493109,
    1.146533564,
    1.042208806,
    0.836121903,
    0.728179794,
    0.641068043,
    0.596831778,
    0.57368667,
    0.574299663,
    0.600250668,
    0.675323422,
    0.785544278,
    0.830422555,
    0.7635296,
    0.716245868,
    0.697944641,
    0.681139555,
    0.678483259,
    0.673146963,
    0.694732402,
    0.737671901,
    0.885449455,
    1.135398546,
    1.226694542,
    1.209248161,
    1.162485153,
    1.059648774,
    0.855610486,
    0.745553417,
    0.648008722,
    0.598491318,
    0.575424385,
    0.573246094,
    0.59871638,
    0.672041747,
    0.782872125,
    0.820685938,
    0.752823419,
    0.701731383,
    0.677744407,
    0.65867262,
    0.655640334,
    0.646522697,
    0.664436733,
    0.701559209,
    0.843255517,
    1.088361825,
    1.18022878,
    1.163948006,
    1.127771645,
    1.034183263,
    0.841770107,
    0.732300197,
    0.639692944,
    0.590622779,
    0.565055977,
    0.561883695,
    0.585112082,
    0.653512607,
    0.757827006,
    0.7978798,
    0.739362413,
    0.694281449,
    0.66719384,
    0.642986042,
    0.642362763,
    0.634705914,
    0.653128563,
    0.692120235,
    0.82373895,
    1.045278515,
    1.110832695,
    1.080710275,
    1.035697426,
    0.96740846,
    0.829970898,
    0.752219992,
    0.676383012,
    0.627126873,
    0.592691081,
    0.584031472,
    0.598870457,
    0.634101797,
    0.704193291,
    0.78956365,
    0.837935506,
    0.856005497,
    0.853131367,
    0.840473565,
    0.833843502,
    0.834808681,
    0.860187185,
    0.893432088,
    0.991148107,
    1.169049668,
    1.186894854,
    1.130270774,
    1.072711609,
    0.992269609,
    0.851515998,
    0.765976151,
    0.677391334,
    0.623004997,
    0.588170423,
    0.579158271,
    0.58974456,
    0.620531187,
    0.686403903,
    0.774297787,
    0.833023098,
    0.853425772,
    0.860100422,
    0.860417716,
    0.868751181,
    0.874197635,
    0.904697944,
    0.940165615,
    1.056275238,
    1.261138711,
    1.29083833,
    1.244056757,
    1.182253732,
    1.065317566,
    0.842648916,
    0.73067457,
    0.65610614,
    0.612823935,
    0.588074353,
    0.583947357,
    0.61161373,
    0.682224216,
    0.796566497,
    0.840996901,
    0.777515955,
    0.739177286,
    0.725103244,
    0.715670254,
    0.70732499,
    0.695452474,
    0.711793753,
    0.753098945,
    0.908613366,
    1.176791582,
    1.274241367,
    1.260075081,
    1.203798696,
    1.092379124,
    0.875348648,
    0.767272036,
    0.693907592,
    0.651056267,
    0.629705996,
    0.633445677,
    0.663866591,
    0.734975304,
    0.850988893,
    0.901006993,
    0.849075106,
    0.813077989,
    0.800615139,
    0.7907796,
    0.786964305,
    0.770378106,
    0.777482566,
    0.818830087,
    0.976003995,
    1.238932561,
    1.329448068,
    1.311901398,
    1.26650852,
    1.152475804,
    0.934399716,
    0.815350833,
    0.720583645,
    0.664357321,
    0.640708837,
    0.645813537,
    0.675993773,
    0.748186589,
    0.862897231,
    0.917680655,
    0.867763287,
    0.839375732,
    0.826796203,
    0.817762715,
    0.812579528,
    0.797588067,
    0.807938864,
    0.854415493,
    1.012242125,
    1.267651224,
    1.352462184,
    1.339291213,
    1.295434884,
    1.181741491,
    0.964729305,
    0.837951675,
    0.72098509,
    0.654736439,
    0.631653772,
    0.633676039,
    0.662972809,
    0.732699616,
    0.846452786,
    0.891264209,
    0.836290267,
    0.800075693,
    0.77908823,
    0.766541971,
    0.761406968,
    0.74500112,
    0.754318405,
    0.795846566,
    0.947884153,
    1.198819213,
    1.285437329,
    1.273464676,
    1.239247238,
    1.135904422,
    0.932310857,
    0.812248175,
    0.709584519,
    0.650076481,
    0.624857382,
    0.626459588,
    0.654061563,
    0.719780812,
    0.828265769,
    0.877856187,
    0.83516949,
    0.807431123,
    0.785305028,
    0.768258764,
    0.765179187,
    0.749358166,
    0.757490804,
    0.799980157,
    0.941420345,
    1.168070812,
    1.228411488,
    1.202134167,
    1.1590086,
    1.080055818,
    0.930482303,
    0.83674565,
    0.740852203,
    0.676455676,
    0.642808167,
    0.638521697,
    0.657362519,
    0.689104907,
    0.761839985,
    0.853892163,
    0.91594191,
    0.94901977,
    0.950935889,
    0.945540099,
    0.937159413,
    0.929725814,
    0.945033633,
    0.983157841,
    1.090095146,
    1.271306685,
    1.284069664,
    1.230721385,
    1.174752722,
    1.08528218,
    0.934354044,
    0.838150302,
    0.739633025,
    0.675838536,
    0.642059619,
    0.637987566,
    0.653011834,
    0.681092528,
    0.75052553,
    0.847301359,
    0.923147902,
    0.961798009,
    0.976147268,
    0.984939283,
    0.99106245,
    0.986108953,
    1.003791301,
    1.043280725,
    1.168253864,
    1.375089321,
    1.398937498,
    1.354185146,
    1.293257333,
    1.167172814,
    0.933985265,
    0.801391162,
    0.693897763,
    0.632122479,
    0.608723411,
    0.606745333,
    0.636439147,
    0.701493731,
    0.814096499,
    0.855451472,
    0.791714698,
    0.754708457,
    0.735560143,
    0.728798498,
    0.719826449,
    0.706434532,
    0.722262334,
    0.770916268,
    0.936249394,
    1.210559929,
    1.3073104,
    1.296906224,
    1.241353499,
    1.124926121,
    0.903527952,
    0.784857135,
    0.69057827,
    0.640752216,
    0.621126697,
    0.624817434,
    0.655191871,
    0.717929185,
    0.827321973,
    0.863719454,
    0.798457694,
    0.75096676,
    0.725042464,
    0.714261674,
    0.711141499,
    0.69844094,
    0.713029438,
    0.764981098,
    0.932272729,
    1.202440297,
    1.295563061,
    1.282820213,
    1.237861625,
    1.123310208,
    0.906652238,
    0.786843127,
    0.685670778,
    0.633905502,
    0.612173807,
    0.615717316,
    0.644439237,
    0.706630042,
    0.811508445,
    0.845661567,
    0.773546535,
    0.725100614,
    0.693435673,
    0.68095317,
    0.677301799,
    0.670096169,
    0.693089638,
    0.752044354,
    0.92019865,
    1.183673848,
    1.273499427,
    1.265736669,
    1.222125535,
    1.110781131,
    0.899056334,
    0.78318397,
    0.722858967,
    0.656208736,
    0.615954281,
    0.607655363,
    0.618921758,
    0.653096585,
    0.747653946,
    0.853362616,
    0.943806768,
    1.019286444,
    1.069159858,
    1.088484787,
    1.085606261,
    1.033260443,
    0.978525254,
    0.9139632,
    0.94843781,
    1.012707649,
    1.025562328,
    1.017383383,
    1.005439838,
    0.937194541,
    0.804099658,
    0.742614883,
    0.644422945,
    0.625304751,
    0.602153323,
    0.602785354,
    0.629624824,
    0.686688094,
    0.78706,
    0.819323009,
    0.758085539,
    0.713731896,
    0.675509152,
    0.656267333,
    0.654255271,
    0.644607973,
    0.663103123,
    0.716763184,
    0.867212189,
    1.10131781,
    1.166852481,
    1.145087902,
    1.101783801,
    1.024063247,
    0.878088097,
    0.791336612,
    0.702249121,
    0.649866101,
    0.618411645,
    0.613031192,
    0.631096173,
    0.654209517,
    0.718375815,
    0.791731789,
    0.83142434,
    0.843489164,
    0.826682922,
    0.816854544,
    0.809595233,
    0.808975939,
    0.83717756,
    0.888261381,
    1.007881705,
    1.198967058,
    1.218684508,
    1.169960918,
    1.113410939,
    1.025518704,
    0.87874656,
    0.790706131,
    0.700024454,
    0.649015529,
    0.617474789,
    0.612326697,
    0.626666136,
    0.646455968,
    0.707381376,
    0.785538699,
    0.838363129,
    0.855197273,
    0.850329116,
    0.854093784,
    0.861378779,
    0.864235003,
    0.895752151,
    0.948272874,
    1.085516069,
    1.302522639,
    1.333968598,
    1.293487333,
    1.231248226,
    1.106733368,
    0.87838004,
    0.763704268,
    0.684576888,
    0.643069158,
    0.621727711,
    0.621773776,
    0.653663043,
    0.71493707,
    0.826504165,
    0.865555121,
    0.801107537,
    0.764615664,
    0.741282452,
    0.736729262,
    0.7268553,
    0.71283087,
    0.728116987,
    0.782794064,
    0.954673504,
    1.233981871,
    1.333633962,
    1.324946756,
    1.267995041,
    1.147272663,
    0.922597293,
    0.798497286,
    0.693803444,
    0.641272903,
    0.623821796,
    0.628787465,
    0.660666727,
    0.71884463,
    0.825691632,
    0.856380598,
    0.786061163,
    0.734947637,
    0.702314285,
    0.692623607,
    0.688975403,
    0.677546252,
    0.694136467,
    0.752868656,
    0.926216022,
    1.201621899,
    1.299064244,
    1.288270217,
    1.24160849,
    1.124339774,
    0.90640798,
    0.784144263,
    0.67638585,
    0.625391101,
    0.605946741,
    0.610094223,
    0.639739074,
    0.696825592,
    0.797954396,
    0.823536939,
    0.742815953,
    0.687413449,
    0.647134242,
    0.634888936,
    0.631100475,
    0.626698501,
    0.653516995,
    0.719717594,
    0.893374522,
    1.162160549,
    1.257611115,
    1.251919598,
    1.206420423,
    1.093557574,
    0.882244861,
    0.776710832,
    0.694812632,
    0.655916167,
    0.634664116,
    0.637720212,
    0.668066423,
    0.731432093,
    0.839649859,
    0.877967732,
    0.815977185,
    0.774326778,
    0.744353758,
    0.732530717,
    0.727275083,
    0.71242755,
    0.725270964,
    0.77536343,
    0.935597329,
    1.193564276,
    1.282466288,
    1.274161634,
    1.239695388,
    1.133764512,
    0.928913428,
    0.782753637,
    0.702781388,
    0.621482994,
    0.592398994,
    0.594953138,
    0.615277624,
    0.625497542,
    0.691478178,
    0.735963528,
    0.755228428,
    0.753417717,
    0.729520383,
    0.730861597,
    0.725752124,
    0.703029177,
    0.719429298,
    0.750492693,
    0.87578442,
    1.060138852,
    1.105087667,
    1.073163668,
    1.023966954,
    0.9517803,
    0.820742742,
    0.754752058,
    0.716243977,
    0.691016971,
    0.660635686,
    0.657798843,
    0.676297407,
    0.688400941,
    0.747344835,
    0.825347164,
    0.883031345,
    0.90597208,
    0.890684282,
    0.886886617,
    0.878140579,
    0.874532331,
    0.899991577,
    0.948757864,
    1.062252809,
    1.239539458,
    1.260437073,
    1.212654595,
    1.154867329,
    1.066899589,
    0.923048057,
    0.860719072,
    0.821649487,
    0.793394192,
    0.758242697,
    0.75382626,
    0.776612087,
    0.806444716,
    0.872149999,
    0.963719544,
    1.020927736,
    1.042587923,
    1.052395993,
    1.056352326,
    1.043312336,
    1.032208788,
    1.046039304,
    1.096032619,
    1.248128135,
    1.478945148,
    1.524361511,
    1.483001262,
    1.417802315,
    1.286061029,
    1.044631933,
    0.907718833,
    0.791280344,
    0.732481965,
    0.706786988,
    0.701468819,
    0.734232566,
    0.796444219,
    0.897688314,
    0.926774732,
    0.838647644,
    0.779067463,
    0.75134133,
    0.738453893,
    0.716295779,
    0.699100715,
    0.715869286,
    0.775328418,
    0.969916095,
    1.271973377,
    1.393086049,
    1.388136949,
    1.332252123,
    1.213649449,
    0.986567982,
    0.873521759,
    0.786724559,
    0.749510537,
    0.727361173,
    0.72853088,
    0.761607777,
    0.822700602,
    0.922303795,
    0.950331241,
    0.86575509,
    0.800003119,
    0.768977091,
    0.752326734,
    0.735978826,
    0.718366626,
    0.731609802,
    0.792474445,
    0.988144769,
    1.285365242,
    1.400536991,
    1.392806579,
    1.348636845,
    1.231932874,
    1.007225192,
    0.886733107,
    0.785051322,
    0.740005251,
    0.715247344,
    0.71567715,
    0.745804218,
    0.806814686,
    0.902064161,
    0.928115323,
    0.836522063,
    0.768704044,
    0.73179184,
    0.712218517,
    0.696195128,
    0.684674962,
    0.706748979,
    0.774040976,
    0.970263274,
    1.26096058,
    1.372325123,
    1.369767557,
    1.328775574,
    1.216693331,
    0.996890365,
    0.881168882,
    0.779754924,
    0.73705175,
    0.712528101,
    0.70993686,
    0.738746654,
    0.798992272,
    0.895220635,
    0.9153463,
    0.823557746,
    0.751637869,
    0.709874578,
    0.687141593,
    0.67136254,
    0.657083275,
    0.675832177,
    0.736300953,
    0.925571894,
    1.211394607,
    1.323466115,
    1.321463662,
    1.291024066,
    1.18893413,
    0.97991914,
    0.870593467,
    0.786539902,
    0.749538069,
    0.722054569,
    0.720127738,
    0.747937589,
    0.807688692,
    0.903163828,
    0.936891174,
    0.867213049,
    0.812619516,
    0.776094691,
    0.750078647,
    0.735467631,
    0.719495702,
    0.7321531,
    0.790577398,
    0.967905993,
    1.228117566,
    1.311426086,
    1.294158501,
    1.255913083,
    1.175893232,
    1.015564581,
    0.920267589,
    0.823980896,
    0.770595163,
    0.734331445,
    0.726033065,
    0.745264864,
    0.774308417,
    0.838134581,
    0.91633832,
    0.948871327,
    0.949444167,
    0.933674019,
    0.913829379,
    0.89190175,
    0.888481882,
    0.911688543,
    0.966038793,
    1.108960144,
    1.323053267,
    1.35718675,
    1.312036814,
    1.260840761,
    1.17155067,
    1.009904323,
    0.918799579,
    0.831612271,
    0.784718815,
    0.747458663,
    0.74027948,
    0.756315965,
    0.785870867,
    0.852118143,
    0.94401927,
    1.000797524,
    1.015794411,
    1.018670732,
    1.013098239,
    1.003399368,
    1.000061181,
    1.020297998,
    1.071635894,
    1.228317761,
    1.463997216,
    1.505580874,
    1.467606404,
    1.411443172,
    1.285356564,
    1.039740644,
    0.90684395,
    0.805534531,
    0.753367258,
    0.725424329,
    0.719981735,
    0.748637472,
    0.813932513,
    0.920793678,
    0.960523348,
    0.885306087,
    0.834316889,
    0.811754697,
    0.795991806,
    0.775333654,
    0.758467791,
    0.770855189,
    0.828542813,
    1.025253069,
    1.329133814,
    1.443548825,
    1.439646329,
    1.392311214,
    1.275542763,
    1.039293102,
    0.904903182,
    0.789489825,
    0.734630498,
    0.710851413,
    0.709169031,
    0.735617973,
    0.796429148,
    0.895480559,
    0.920941496,
    0.832738947,
    0.759505875,
    0.723229277,
    0.699649207,
    0.687015713,
    0.675637846,
    0.693608856,
    0.756651106,
    0.955080166,
    1.256539765,
    1.37020442,
    1.365277422,
    1.329741646,
    1.219924801,
    0.993012177,
    0.871730981,
    0.775407401,
    0.733165357,
    0.706763403,
    0.705171092,
    0.729542351,
    0.791649682,
    0.888157498,
    0.915256874,
    0.824098376,
    0.752965762,
    0.713706453,
    0.688353607,
    0.675269788,
    0.668552042,
    0.692800594,
    0.760984622,
    0.959117463,
    1.253150101,
    1.362619896,
    1.362078506,
    1.329109079,
    1.222638982,
    0.999091519,
    0.87857461,
    0.77609892,
    0.73184379,
    0.705773561,
    0.701414808,
    0.724833444,
    0.786829667,
    0.885090905,
    0.907320513,
    0.817194591,
    0.743048475,
    0.699877389,
    0.671723333,
    0.658601717,
    0.648585303,
    0.668643891,
    0.72947523,
    0.919957766,
    1.208712177,
    1.319368921,
    1.318796621,
    1.296016358,
    1.199170614,
    0.986119623,
    0.870427896,
    0.782766226,
    0.742560777,
    0.713638845,
    0.709964243,
    0.732542511,
    0.79435849,
    0.891938185,
    0.927746143,
    0.859404631,
    0.802364102,
    0.764255808,
    0.732514358,
    0.720495565,
    0.709251911,
    0.72353945,
    0.782038702,
    0.959938782,
    1.222829095,
    1.30547962,
    1.289218068,
    1.257766217,
    1.183142945,
    1.019587539,
    0.92763962,
    0.845064122,
    0.796249351,
    0.758241757,
    0.751002812,
    0.767671047,
    0.803118504,
    0.876427351,
    0.971499903,
    1.025287899,
    1.042247257,
    1.037307643,
    1.016629513,
    0.99487139,
    0.991079982,
    1.005246498,
    1.053047533,
    1.191024976,
    1.402381447,
    1.431302555,
    1.384552572,
    1.338967705,
    1.249405244,
    1.077443542,
    0.970133452,
    0.867821415,
    0.80560108,
    0.76679539,
    0.760468933,
    0.773841756,
    0.80990526,
    0.885612621,
    0.993632211,
    1.070628911,
    1.100993709,
    1.114221826,
    1.107548984,
    1.097904962,
    1.09435667,
    1.105754792,
    1.150079,
    1.301352892,
    1.534357134,
    1.57225457,
    1.532990383,
    1.482223659,
    1.356011284,
    1.099749278,
    0.954848883,
    0.84580799,
    0.78260181,
    0.75321666,
    0.749508556,
    0.776587037,
    0.849476845,
    0.966797875,
    1.024357503,
    0.969531738,
    0.935165728,
    0.924700573,
    0.908704364,
    0.887671522,
    0.868715561,
    0.870933894,
    0.922421221,
    1.115749608,
    1.418198246,
    1.529700334,
    1.523971003,
    1.48147676,
    1.363515196,
    1.115718807,
    0.966900711,
    0.840963532,
    0.771634585,
    0.746248514,
    0.747005038,
    0.772628859,
    0.84244194,
    0.953730789,
    1.000269328,
    0.935938302,
    0.882591113,
    0.860415252,
    0.837145254,
    0.823273189,
    0.808918715,
    0.814948731,
    0.870791409,
    1.065351761,
    1.36500558,
    1.475477345,
    1.467912038,
    1.436595174,
    1.324208938,
    1.084290479,
    0.942985021,
    0.825487878,
    0.763125327,
    0.735354675,
    0.735743428,
    0.759072156,
    0.829833601,
    0.937688919,
    0.983647259,
    0.913326917,
    0.859035894,
    0.832098212,
    0.806348953,
    0.792366976,
    0.783661301,
    0.797845646,
    0.85952554,
    1.053315086,
    1.345692111,
    1.453683225,
    1.450637187,
    1.421406391,
    1.313170666,
    1.078012665,
    0.94106489,
    0.823174393,
    0.762219078,
    0.734824191,
    0.732704455,
    0.755369625,
    0.826644813,
    0.936776488,
    0.978510807,
    0.909681297,
    0.853035785,
    0.82260837,
    0.794196406,
    0.779898377,
    0.767963343,
    0.777352348,
    0.831129135,
    1.016783519,
    1.303568387,
    1.413407702,
    1.409931822,
    1.390280369,
    1.291371531,
    1.066620411,
    0.938006599,
    0.824651673,
    0.770262277,
    0.740893485,
    0.737588981,
    0.757203546,
    0.820797183,
    0.916871027,
    0.965504622,
    0.914152257,
    0.868389192,
    0.833968522,
    0.800232574,
    0.787261407,
    0.775326746,
    0.783153102,
    0.836762228,
    1.012818393,
    1.275312347,
    1.350772596,
    1.33620046,
    1.306702855,
    1.230591136,
    1.061945785,
    0.967108,
    0.855356894,
    0.80161879,
    0.763852559,
    0.754382456,
    0.765510709,
    0.796852812,
    0.857648618,
    0.955396811,
    1.016189568,
    1.032146914,
    1.015987662,
    0.988612753,
    0.966528815,
    0.967276943,
    0.98471417,
    1.030388838,
    1.169715086,
    1.383282371,
    1.403033907,
    1.361781187,
    1.317307057,
    1.229123465,
    1.059273474,
    0.97117622,
    0.867818492,
    0.82210146,
    0.783443105,
    0.775977874,
    0.784934866,
    0.818735923,
    0.884478737,
    1.000631439,
    1.092531316,
    1.12974354,
    1.136062251,
    1.12510999,
    1.113891404,
    1.112265503,
    1.12221373,
    1.16144015,
    1.311955796,
    1.545012477,
    1.572042283,
    1.53803839,
    1.487704442,
    1.360335162,
    1.10323345,
    0.980159399,
    0.854723568,
    0.808413121,
    0.779910912,
    0.772849464,
    0.79268773,
    0.853201938,
    0.942517479,
    1.000439738,
    0.954402771,
    0.919365313,
    0.893751726,
    0.872248167,
    0.850116536,
    0.832303221,
    0.836738736,
    0.887624379,
    1.08634187,
    1.392563662,
    1.483612649,
    1.489538643,
    1.446340547,
    1.326566572,
    1.085540188,
    0.973742201,
    0.867248298,
    0.8354443,
    0.808277212,
    0.799783278,
    0.806243747,
    0.859352452,
    0.962488015,
    1.049635141,
    1.064310548,
    1.020418299,
    0.985927292,
    0.948759622,
    0.951542721,
    0.972425235,
    1.001954596,
    1.033094798,
    1.147047066,
    1.322156449,
    1.305571549,
    1.264774494,
    1.211715186,
    1.149033704,
    1.034664174,
    0.970424237,
    0.846524289,
    0.821439665,
    0.773178585,
    0.761066926,
    0.779249598,
    0.82326637,
    0.901439509,
    1.016888861,
    1.062349105,
    1.085544356,
    1.069646945,
    1.078336843,
    1.054056771,
    1.043071591,
    1.048791544,
    1.060834429,
    1.150537267,
    1.300856916,
    1.321248738,
    1.310027987,
    1.28895677,
    1.205339808,
    1.025350221,
    0.956176224,
    0.828321534,
    0.812288123,
    0.786755646,
    0.782375611,
    0.807185075,
    0.869007459,
    0.965512675,
    1.018144744,
    0.973354761,
    0.937515769,
    0.927200186,
    0.907411115,
    0.893300104,
    0.870290056,
    0.869071998,
    0.920065716,
    1.096493368,
    1.374900406,
    1.466536992,
    1.444023862,
    1.419954313,
    1.32187437,
    1.10791555,
    0.974396092,
    0.862400674,
    0.802385532,
    0.774655972,
    0.769423563,
    0.792414416,
    0.852051563,
    0.94404647,
    1.002763769,
    0.970778673,
    0.94356021,
    0.933447002,
    0.908422274,
    0.896484664,
    0.875071597,
    0.872722116,
    0.923282048,
    1.08610947,
    1.339071713,
    1.406048186,
    1.368189879,
    1.334653212,
    1.261507253,
    1.101756622,
    0.993947822,
    0.88763117,
    0.822092383,
    0.78599533,
    0.774402869,
    0.789626432,
    0.818992717,
    0.879382449,
    0.984369154,
    1.060004963,
    1.093588222,
    1.108232941,
    1.092807591,
    1.07298609,
    1.062680533,
    1.06977232,
    1.113043143,
    1.233356874,
    1.435912021,
    1.45236151,
    1.384485025,
    1.336261785,
    1.253259044,
    1.092900892,
    0.992820801,
    0.901537911,
    0.845335392,
    0.808608963,
    0.799139896,
    0.812808135,
    0.84424538,
    0.909042677,
    1.032557037,
    1.140427644,
    1.19759419,
    1.23868913,
    1.242670948,
    1.233944963,
    1.218320389,
    1.21613441,
    1.25281437,
    1.382081974,
    1.602889822,
    1.625808955,
    1.563012972,
    1.508482725,
    1.386673634,
    1.140452356,
    0.997392123,
    0.892018399,
    0.82861591,
    0.80152907,
    0.795329709,
    0.823545846,
    0.893051988,
    1.001331237,
    1.077083779,
    1.053068856,
    1.044384974,
    1.061218491,
    1.05347103,
    1.032631581,
    1.00321424,
    0.99045177,
    1.0341131,
    1.208963264,
    1.500549981,
    1.599856751,
    1.57001444,
    1.522146713,
    1.406974845,
    1.16823758,
    1.036030093,
  ],
};

export default colorado;
