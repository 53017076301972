const newMexico = {
  year: 2019,
  data: [
    1.615,
    1.604,
    1.469,
    1.424,
    1.454,
    1.501,
    1.531,
    1.591,
    1.594,
    1.615,
    1.735,
    1.738,
    1.727,
    1.702,
    1.587,
    1.519,
    1.551,
    1.604,
    1.805,
    1.858,
    1.832,
    1.810,
    1.684,
    1.589,
    1.522,
    1.404,
    1.439,
    1.442,
    1.458,
    1.448,
    1.493,
    1.551,
    1.642,
    1.636,
    1.595,
    1.584,
    1.424,
    1.472,
    1.351,
    1.292,
    1.316,
    1.530,
    1.674,
    1.802,
    1.780,
    1.808,
    1.740,
    1.447,
    1.402,
    1.295,
    1.324,
    1.357,
    1.383,
    1.413,
    1.545,
    1.561,
    1.524,
    1.491,
    1.411,
    1.374,
    1.419,
    1.362,
    1.201,
    1.174,
    1.214,
    1.291,
    1.729,
    1.752,
    1.779,
    1.737,
    1.534,
    1.372,
    1.318,
    1.253,
    1.253,
    1.262,
    1.314,
    1.367,
    1.503,
    1.572,
    1.564,
    1.513,
    1.405,
    1.381,
    1.211,
    1.208,
    1.167,
    0.976,
    1.079,
    1.290,
    1.603,
    1.647,
    1.707,
    1.565,
    1.478,
    1.302,
    1.198,
    1.180,
    1.178,
    1.188,
    1.210,
    1.307,
    1.493,
    1.547,
    1.534,
    1.498,
    1.430,
    1.361,
    1.299,
    1.253,
    1.174,
    1.113,
    1.200,
    1.345,
    1.607,
    1.720,
    1.730,
    1.744,
    1.545,
    1.386,
    1.285,
    1.264,
    1.223,
    1.245,
    1.233,
    1.264,
    1.375,
    1.479,
    1.549,
    1.544,
    1.451,
    1.501,
    1.448,
    1.385,
    1.276,
    1.256,
    1.350,
    1.508,
    1.647,
    1.599,
    1.736,
    1.695,
    1.478,
    1.350,
    1.264,
    1.203,
    1.181,
    1.143,
    1.229,
    1.249,
    1.417,
    1.416,
    1.459,
    1.459,
    1.362,
    1.393,
    1.212,
    1.239,
    1.180,
    1.120,
    1.128,
    1.322,
    1.522,
    1.623,
    1.569,
    1.596,
    1.457,
    1.316,
    1.232,
    1.235,
    1.180,
    1.225,
    1.240,
    1.283,
    1.477,
    1.513,
    1.483,
    1.412,
    1.323,
    1.193,
    1.132,
    1.111,
    1.016,
    1.063,
    1.140,
    1.286,
    1.629,
    1.509,
    1.574,
    1.461,
    1.375,
    1.199,
    1.103,
    1.079,
    1.067,
    1.089,
    1.112,
    1.158,
    1.310,
    1.384,
    1.367,
    1.325,
    1.325,
    1.198,
    1.185,
    1.081,
    1.071,
    1.092,
    1.079,
    1.200,
    1.395,
    1.365,
    1.370,
    1.354,
    1.294,
    1.139,
    1.007,
    0.959,
    0.949,
    0.974,
    0.956,
    1.031,
    1.181,
    1.223,
    1.283,
    1.228,
    1.140,
    1.084,
    1.092,
    1.059,
    0.951,
    0.926,
    0.977,
    1.092,
    1.309,
    1.401,
    1.449,
    1.419,
    1.208,
    1.066,
    0.984,
    0.924,
    0.982,
    1.029,
    1.005,
    1.082,
    1.332,
    1.370,
    1.296,
    1.248,
    1.134,
    1.138,
    1.044,
    0.979,
    0.988,
    0.921,
    0.997,
    1.103,
    1.377,
    1.411,
    1.496,
    1.507,
    1.414,
    1.223,
    1.158,
    1.098,
    1.085,
    1.121,
    1.143,
    1.218,
    1.408,
    1.444,
    1.443,
    1.427,
    1.331,
    1.261,
    1.106,
    1.016,
    1.009,
    1.019,
    1.093,
    1.246,
    1.357,
    1.344,
    1.572,
    1.560,
    1.422,
    1.269,
    1.225,
    1.139,
    1.124,
    1.183,
    1.222,
    1.304,
    1.404,
    1.446,
    1.508,
    1.501,
    1.447,
    1.332,
    1.302,
    1.259,
    1.280,
    1.290,
    1.204,
    1.254,
    1.505,
    1.562,
    1.610,
    1.431,
    1.343,
    1.187,
    1.164,
    1.115,
    1.110,
    1.109,
    1.152,
    1.171,
    1.354,
    1.405,
    1.510,
    1.424,
    1.335,
    1.271,
    1.208,
    1.231,
    1.113,
    1.071,
    1.138,
    1.323,
    1.624,
    1.496,
    1.512,
    1.455,
    1.324,
    1.194,
    1.144,
    1.095,
    1.072,
    1.074,
    1.135,
    1.194,
    1.366,
    1.402,
    1.441,
    1.478,
    1.544,
    1.388,
    1.374,
    1.292,
    1.300,
    1.214,
    1.322,
    1.406,
    1.559,
    1.607,
    1.738,
    1.628,
    1.377,
    1.289,
    1.263,
    1.249,
    1.256,
    1.207,
    1.220,
    1.331,
    1.477,
    1.502,
    1.503,
    1.488,
    1.390,
    1.238,
    1.097,
    1.138,
    1.069,
    0.984,
    1.091,
    1.198,
    1.428,
    1.492,
    1.598,
    1.539,
    1.367,
    1.288,
    1.247,
    1.186,
    1.178,
    1.174,
    1.197,
    1.223,
    1.369,
    1.435,
    1.467,
    1.364,
    1.363,
    1.189,
    0.991,
    1.005,
    0.995,
    1.001,
    1.063,
    1.179,
    1.420,
    1.510,
    1.640,
    1.545,
    1.381,
    1.309,
    1.253,
    1.201,
    1.171,
    1.193,
    1.195,
    1.247,
    1.440,
    1.506,
    1.418,
    1.375,
    1.274,
    1.206,
    1.096,
    1.048,
    0.939,
    0.992,
    1.005,
    1.108,
    1.384,
    1.502,
    1.504,
    1.567,
    1.426,
    1.288,
    1.186,
    1.107,
    1.149,
    1.099,
    1.157,
    1.208,
    1.344,
    1.447,
    1.445,
    1.347,
    1.213,
    1.170,
    1.187,
    1.019,
    0.934,
    0.935,
    0.989,
    1.185,
    1.272,
    1.374,
    1.381,
    1.379,
    1.323,
    1.079,
    1.005,
    0.973,
    0.977,
    1.024,
    1.051,
    1.117,
    1.262,
    1.339,
    1.423,
    1.326,
    1.288,
    1.198,
    1.066,
    1.077,
    0.996,
    0.984,
    1.040,
    1.169,
    1.320,
    1.379,
    1.402,
    1.319,
    1.111,
    0.990,
    0.969,
    0.898,
    0.897,
    0.885,
    0.904,
    0.969,
    1.141,
    1.110,
    1.144,
    1.229,
    1.215,
    1.190,
    1.114,
    1.065,
    1.101,
    1.064,
    1.158,
    1.233,
    1.333,
    1.514,
    1.432,
    1.345,
    1.287,
    1.080,
    1.104,
    0.989,
    1.026,
    1.051,
    1.038,
    1.116,
    1.330,
    1.365,
    1.435,
    1.344,
    1.266,
    1.263,
    1.221,
    1.069,
    1.064,
    1.091,
    1.100,
    1.279,
    1.544,
    1.690,
    1.586,
    1.520,
    1.377,
    1.193,
    1.174,
    1.180,
    1.177,
    1.203,
    1.241,
    1.340,
    1.486,
    1.509,
    1.441,
    1.393,
    1.330,
    1.121,
    0.958,
    0.967,
    0.926,
    0.919,
    0.904,
    1.049,
    1.278,
    1.387,
    1.450,
    1.445,
    1.268,
    1.181,
    1.097,
    1.044,
    1.074,
    1.105,
    1.173,
    1.281,
    1.344,
    1.402,
    1.461,
    1.274,
    1.281,
    1.129,
    1.004,
    0.939,
    0.897,
    0.957,
    0.945,
    1.020,
    1.291,
    1.355,
    1.453,
    1.377,
    1.259,
    1.113,
    1.054,
    1.017,
    1.048,
    1.083,
    1.100,
    1.162,
    1.384,
    1.519,
    1.407,
    1.262,
    1.111,
    1.097,
    1.038,
    0.982,
    0.894,
    0.868,
    0.937,
    1.149,
    1.300,
    1.424,
    1.452,
    1.493,
    1.470,
    1.280,
    1.140,
    1.137,
    1.117,
    1.175,
    1.219,
    1.332,
    1.470,
    1.571,
    1.481,
    1.377,
    1.144,
    1.109,
    1.145,
    1.084,
    1.065,
    1.005,
    1.022,
    1.161,
    1.447,
    1.502,
    1.524,
    1.571,
    1.338,
    1.277,
    1.212,
    1.130,
    1.130,
    1.162,
    1.162,
    1.260,
    1.413,
    1.499,
    1.379,
    1.277,
    1.256,
    1.205,
    1.107,
    1.080,
    1.036,
    1.052,
    1.072,
    1.126,
    1.350,
    1.532,
    1.435,
    1.410,
    1.331,
    1.123,
    1.086,
    1.153,
    1.159,
    1.176,
    1.229,
    1.267,
    1.505,
    1.533,
    1.491,
    1.402,
    1.280,
    1.157,
    1.153,
    1.139,
    1.085,
    1.101,
    1.082,
    1.294,
    1.385,
    1.556,
    1.524,
    1.513,
    1.344,
    1.159,
    1.083,
    1.116,
    1.100,
    1.116,
    1.187,
    1.236,
    1.401,
    1.512,
    1.470,
    1.417,
    1.351,
    1.186,
    1.092,
    1.058,
    1.071,
    0.948,
    1.087,
    1.198,
    1.361,
    1.351,
    1.340,
    1.409,
    1.212,
    1.105,
    0.988,
    0.980,
    1.051,
    1.040,
    1.073,
    1.216,
    1.349,
    1.429,
    1.400,
    1.249,
    1.274,
    1.051,
    1.021,
    0.926,
    0.817,
    0.820,
    0.958,
    1.078,
    1.219,
    1.234,
    1.327,
    1.235,
    1.095,
    0.969,
    0.880,
    0.843,
    0.909,
    0.908,
    0.984,
    1.048,
    1.177,
    1.294,
    1.278,
    1.175,
    1.156,
    1.098,
    0.910,
    0.861,
    0.808,
    0.821,
    0.876,
    0.943,
    1.114,
    1.151,
    1.133,
    1.202,
    1.134,
    0.955,
    0.874,
    0.860,
    0.889,
    0.938,
    0.961,
    1.155,
    1.293,
    1.443,
    1.337,
    1.242,
    1.182,
    1.109,
    1.006,
    0.914,
    0.866,
    0.854,
    0.879,
    1.059,
    1.270,
    1.400,
    1.467,
    1.388,
    1.333,
    1.131,
    1.122,
    1.086,
    1.113,
    1.132,
    1.147,
    1.158,
    1.391,
    1.479,
    1.351,
    1.365,
    1.287,
    1.191,
    1.183,
    1.058,
    1.067,
    0.920,
    1.053,
    1.174,
    1.379,
    1.590,
    1.551,
    1.597,
    1.431,
    1.269,
    1.211,
    1.183,
    1.190,
    1.196,
    1.247,
    1.318,
    1.459,
    1.542,
    1.441,
    1.324,
    1.308,
    1.161,
    1.128,
    1.112,
    1.063,
    0.963,
    0.981,
    1.081,
    1.268,
    1.339,
    1.412,
    1.306,
    1.314,
    1.173,
    1.133,
    1.123,
    1.099,
    1.098,
    1.109,
    1.209,
    1.296,
    1.406,
    1.373,
    1.375,
    1.190,
    1.125,
    1.105,
    1.052,
    1.027,
    1.035,
    1.050,
    1.116,
    1.304,
    1.385,
    1.471,
    1.485,
    1.348,
    1.192,
    1.158,
    1.102,
    1.124,
    1.153,
    1.181,
    1.284,
    1.440,
    1.504,
    1.386,
    1.310,
    1.216,
    1.076,
    1.044,
    0.953,
    0.879,
    0.830,
    0.868,
    1.054,
    1.189,
    1.295,
    1.274,
    1.233,
    1.217,
    1.074,
    1.052,
    1.021,
    1.009,
    1.103,
    1.056,
    1.139,
    1.336,
    1.428,
    1.374,
    1.327,
    1.243,
    1.187,
    1.189,
    1.091,
    1.066,
    1.083,
    1.061,
    1.120,
    1.284,
    1.358,
    1.392,
    1.402,
    1.300,
    1.111,
    0.993,
    1.016,
    1.002,
    1.083,
    1.088,
    1.174,
    1.304,
    1.405,
    1.467,
    1.311,
    1.269,
    1.145,
    1.151,
    1.092,
    1.058,
    0.973,
    1.012,
    1.094,
    1.213,
    1.402,
    1.438,
    1.453,
    1.342,
    1.194,
    1.014,
    1.047,
    1.049,
    1.104,
    1.139,
    1.198,
    1.437,
    1.520,
    1.381,
    1.243,
    1.212,
    1.143,
    1.079,
    1.031,
    1.029,
    0.984,
    1.006,
    1.150,
    1.283,
    1.477,
    1.483,
    1.500,
    1.359,
    1.135,
    1.093,
    1.070,
    1.024,
    1.100,
    1.159,
    1.190,
    1.338,
    1.420,
    1.389,
    1.241,
    1.179,
    1.095,
    1.067,
    0.990,
    1.044,
    1.007,
    0.990,
    1.145,
    1.232,
    1.346,
    1.400,
    1.426,
    1.267,
    1.148,
    1.112,
    1.048,
    1.070,
    1.068,
    1.134,
    1.196,
    1.334,
    1.460,
    1.434,
    1.303,
    1.283,
    1.224,
    1.382,
    1.327,
    1.329,
    1.249,
    1.124,
    1.206,
    1.272,
    1.386,
    1.426,
    1.387,
    1.286,
    1.129,
    1.064,
    1.054,
    0.963,
    0.993,
    1.016,
    1.056,
    1.277,
    1.223,
    1.330,
    1.330,
    1.270,
    1.147,
    1.065,
    0.975,
    0.958,
    0.972,
    0.936,
    1.108,
    1.257,
    1.348,
    1.376,
    1.353,
    1.123,
    1.012,
    1.007,
    0.949,
    0.955,
    1.035,
    1.127,
    1.086,
    1.341,
    1.393,
    1.363,
    1.238,
    1.161,
    1.131,
    1.061,
    1.046,
    0.956,
    0.934,
    0.974,
    1.101,
    1.371,
    1.452,
    1.548,
    1.419,
    1.196,
    1.135,
    1.060,
    1.011,
    1.039,
    0.974,
    1.055,
    1.086,
    1.307,
    1.404,
    1.303,
    1.319,
    1.255,
    1.152,
    1.063,
    1.014,
    1.001,
    0.949,
    0.993,
    1.108,
    1.191,
    1.325,
    1.390,
    1.242,
    1.240,
    1.053,
    0.925,
    0.908,
    0.935,
    0.925,
    0.893,
    0.985,
    1.064,
    1.175,
    1.190,
    1.077,
    0.992,
    0.968,
    0.943,
    0.917,
    0.908,
    0.814,
    0.961,
    1.028,
    1.161,
    1.355,
    1.308,
    1.182,
    1.060,
    0.979,
    0.871,
    0.834,
    0.838,
    0.859,
    0.902,
    0.978,
    1.139,
    1.182,
    1.119,
    0.980,
    1.003,
    0.963,
    0.933,
    0.803,
    0.807,
    0.777,
    0.905,
    0.985,
    1.165,
    1.261,
    1.338,
    1.265,
    1.145,
    1.052,
    0.938,
    0.857,
    0.876,
    0.917,
    0.914,
    1.090,
    1.286,
    1.350,
    1.272,
    1.232,
    1.153,
    1.076,
    1.054,
    1.019,
    0.962,
    0.939,
    1.003,
    1.021,
    1.119,
    1.261,
    1.275,
    1.221,
    1.166,
    1.105,
    1.072,
    1.003,
    0.958,
    1.001,
    0.994,
    1.106,
    1.276,
    1.321,
    1.215,
    1.119,
    1.144,
    1.084,
    1.017,
    0.910,
    0.755,
    0.762,
    0.824,
    0.955,
    1.053,
    1.251,
    1.240,
    1.283,
    1.172,
    1.003,
    0.881,
    0.857,
    0.907,
    0.881,
    0.939,
    0.995,
    1.189,
    1.163,
    1.271,
    1.206,
    1.117,
    1.101,
    1.083,
    1.053,
    1.009,
    1.005,
    1.087,
    1.214,
    1.338,
    1.393,
    1.315,
    1.346,
    1.157,
    0.864,
    0.821,
    0.771,
    0.788,
    0.822,
    0.849,
    0.944,
    1.036,
    1.145,
    1.144,
    1.044,
    1.021,
    0.948,
    0.939,
    1.009,
    0.923,
    0.937,
    1.026,
    1.112,
    1.305,
    1.378,
    1.278,
    1.129,
    1.110,
    1.028,
    0.857,
    0.843,
    0.807,
    0.834,
    0.896,
    0.929,
    1.118,
    1.240,
    1.228,
    1.116,
    1.132,
    1.129,
    1.041,
    0.952,
    0.880,
    0.905,
    0.985,
    1.127,
    1.233,
    1.368,
    1.426,
    1.288,
    1.226,
    1.078,
    1.003,
    0.962,
    0.947,
    0.998,
    1.021,
    1.086,
    1.252,
    1.403,
    1.340,
    1.225,
    1.272,
    1.191,
    1.162,
    1.175,
    1.096,
    0.995,
    1.106,
    1.201,
    1.337,
    1.422,
    1.498,
    1.411,
    1.213,
    1.057,
    0.979,
    0.942,
    0.938,
    0.963,
    0.973,
    1.088,
    1.258,
    1.370,
    1.274,
    1.107,
    1.096,
    0.947,
    0.953,
    0.902,
    0.871,
    0.853,
    0.902,
    1.040,
    1.221,
    1.373,
    1.362,
    1.291,
    1.147,
    1.040,
    0.885,
    0.890,
    0.834,
    0.879,
    0.916,
    1.044,
    1.159,
    1.269,
    1.140,
    1.175,
    1.151,
    1.051,
    1.109,
    1.049,
    1.072,
    1.026,
    1.032,
    1.151,
    1.313,
    1.479,
    1.367,
    1.377,
    1.226,
    1.104,
    1.025,
    1.040,
    1.037,
    1.036,
    1.073,
    1.124,
    1.310,
    1.351,
    1.316,
    1.165,
    1.190,
    1.092,
    1.106,
    1.076,
    0.939,
    0.904,
    0.984,
    1.093,
    1.183,
    1.356,
    1.403,
    1.393,
    1.298,
    1.161,
    1.093,
    1.096,
    1.103,
    1.080,
    1.073,
    1.187,
    1.338,
    1.363,
    1.408,
    1.341,
    1.203,
    1.027,
    1.043,
    0.991,
    0.976,
    0.983,
    0.951,
    1.152,
    1.260,
    1.424,
    1.357,
    1.403,
    1.211,
    1.068,
    0.990,
    1.024,
    1.048,
    1.019,
    1.069,
    1.138,
    1.301,
    1.444,
    1.332,
    1.286,
    1.132,
    1.040,
    0.989,
    0.893,
    0.876,
    0.809,
    0.807,
    0.912,
    1.041,
    1.187,
    1.197,
    1.221,
    1.041,
    0.904,
    0.881,
    0.837,
    0.873,
    0.921,
    0.943,
    1.024,
    1.180,
    1.353,
    1.208,
    1.105,
    1.113,
    1.062,
    0.985,
    0.915,
    0.885,
    0.953,
    0.906,
    0.985,
    1.140,
    1.328,
    1.351,
    1.250,
    1.080,
    0.920,
    0.862,
    0.799,
    0.821,
    0.862,
    0.895,
    0.979,
    1.114,
    1.194,
    1.148,
    1.180,
    1.125,
    0.985,
    0.983,
    0.917,
    0.855,
    0.795,
    0.802,
    0.871,
    1.174,
    1.180,
    1.271,
    1.178,
    1.012,
    0.901,
    0.840,
    0.821,
    0.817,
    0.830,
    0.872,
    1.003,
    1.246,
    1.335,
    1.258,
    1.168,
    0.989,
    0.874,
    0.861,
    0.775,
    0.700,
    0.672,
    0.686,
    0.774,
    0.971,
    1.170,
    1.163,
    1.154,
    1.043,
    0.956,
    0.856,
    0.898,
    0.867,
    0.902,
    0.956,
    1.070,
    1.245,
    1.410,
    1.240,
    1.211,
    1.100,
    0.977,
    0.891,
    0.873,
    0.855,
    0.867,
    0.921,
    0.958,
    1.013,
    1.179,
    1.238,
    1.264,
    1.111,
    0.969,
    0.930,
    0.883,
    0.916,
    0.911,
    0.950,
    0.978,
    1.158,
    1.252,
    1.188,
    1.142,
    1.141,
    1.091,
    1.116,
    1.086,
    1.014,
    1.030,
    1.145,
    1.153,
    1.300,
    1.360,
    1.303,
    1.330,
    1.185,
    1.054,
    0.944,
    0.938,
    0.925,
    0.926,
    0.962,
    0.982,
    1.128,
    1.176,
    1.250,
    1.109,
    1.132,
    1.029,
    1.084,
    1.037,
    0.984,
    1.026,
    1.037,
    1.176,
    1.233,
    1.346,
    1.292,
    1.221,
    1.121,
    0.976,
    0.872,
    0.854,
    0.858,
    0.893,
    0.906,
    0.967,
    1.076,
    1.204,
    1.109,
    1.194,
    1.095,
    0.960,
    0.968,
    0.972,
    0.877,
    0.834,
    0.867,
    0.993,
    1.052,
    1.249,
    1.316,
    1.245,
    1.085,
    0.933,
    0.835,
    0.824,
    0.798,
    0.816,
    0.830,
    0.915,
    1.079,
    1.179,
    1.119,
    1.075,
    1.027,
    0.909,
    0.923,
    0.849,
    0.799,
    0.816,
    0.871,
    0.953,
    1.096,
    1.159,
    1.257,
    1.208,
    1.046,
    0.883,
    0.789,
    0.740,
    0.800,
    0.821,
    0.847,
    0.969,
    1.162,
    1.202,
    1.060,
    1.009,
    0.953,
    0.830,
    0.816,
    0.788,
    0.780,
    0.731,
    0.761,
    0.835,
    1.013,
    1.023,
    1.170,
    1.174,
    1.106,
    0.882,
    0.800,
    0.783,
    0.730,
    0.753,
    0.832,
    0.923,
    1.142,
    1.120,
    1.064,
    0.995,
    0.880,
    0.747,
    0.750,
    0.694,
    0.697,
    0.704,
    0.699,
    0.826,
    0.913,
    1.118,
    1.231,
    1.184,
    1.118,
    0.899,
    0.732,
    0.723,
    0.686,
    0.675,
    0.760,
    0.823,
    1.026,
    1.217,
    1.046,
    0.954,
    0.899,
    0.814,
    0.920,
    0.802,
    0.776,
    0.770,
    0.826,
    0.865,
    1.004,
    1.135,
    1.043,
    0.976,
    0.907,
    0.855,
    0.705,
    0.664,
    0.458,
    0.679,
    0.707,
    0.785,
    0.921,
    1.003,
    0.961,
    1.009,
    0.984,
    0.920,
    0.858,
    0.895,
    0.925,
    0.834,
    0.953,
    0.944,
    1.027,
    1.191,
    1.186,
    1.178,
    1.093,
    0.888,
    0.825,
    0.761,
    0.600,
    0.807,
    0.800,
    0.884,
    0.934,
    1.007,
    1.088,
    1.059,
    1.071,
    1.095,
    1.022,
    0.989,
    1.046,
    1.043,
    1.063,
    1.170,
    1.225,
    1.311,
    1.254,
    1.234,
    1.091,
    0.954,
    0.858,
    0.849,
    0.569,
    0.875,
    0.878,
    0.916,
    0.983,
    1.069,
    1.139,
    1.141,
    1.166,
    1.093,
    1.025,
    0.983,
    0.873,
    0.858,
    0.886,
    0.962,
    1.043,
    1.069,
    1.082,
    0.999,
    0.953,
    0.865,
    0.755,
    0.728,
    0.585,
    0.749,
    0.795,
    0.853,
    0.937,
    1.078,
    1.163,
    0.994,
    1.092,
    0.997,
    1.028,
    0.970,
    0.862,
    0.785,
    0.800,
    0.828,
    0.891,
    0.993,
    1.144,
    1.095,
    1.069,
    0.897,
    0.869,
    0.815,
    0.823,
    0.829,
    0.878,
    0.917,
    1.005,
    1.196,
    1.135,
    1.148,
    1.052,
    0.942,
    0.910,
    0.860,
    0.825,
    0.777,
    0.816,
    0.971,
    1.088,
    1.149,
    1.265,
    1.186,
    1.104,
    0.904,
    0.795,
    0.690,
    0.699,
    0.708,
    0.784,
    0.842,
    0.930,
    1.054,
    1.087,
    1.032,
    1.007,
    0.980,
    0.960,
    0.880,
    0.817,
    0.838,
    0.812,
    0.822,
    0.987,
    1.047,
    1.104,
    1.097,
    0.985,
    0.871,
    0.743,
    0.694,
    0.687,
    0.714,
    0.740,
    0.828,
    0.899,
    1.031,
    0.957,
    1.040,
    0.972,
    0.870,
    0.819,
    0.774,
    0.744,
    0.786,
    0.894,
    0.898,
    0.938,
    0.940,
    1.065,
    1.125,
    1.054,
    0.861,
    0.738,
    0.648,
    0.616,
    0.661,
    0.625,
    0.708,
    0.780,
    0.937,
    1.011,
    1.020,
    1.003,
    0.997,
    0.881,
    0.878,
    0.915,
    0.797,
    0.830,
    0.815,
    0.979,
    1.047,
    1.132,
    1.066,
    1.004,
    0.846,
    0.752,
    0.667,
    0.635,
    0.659,
    0.663,
    0.714,
    0.785,
    0.958,
    1.021,
    1.026,
    0.960,
    0.949,
    0.948,
    0.926,
    0.857,
    0.864,
    0.851,
    0.896,
    1.021,
    1.081,
    1.106,
    1.135,
    1.066,
    0.908,
    0.795,
    0.790,
    0.744,
    0.745,
    0.761,
    0.788,
    0.921,
    1.016,
    1.005,
    1.022,
    1.045,
    1.039,
    0.992,
    0.903,
    0.915,
    0.876,
    0.995,
    1.141,
    1.152,
    1.235,
    1.375,
    1.316,
    1.181,
    0.969,
    0.886,
    0.813,
    0.802,
    0.798,
    0.828,
    0.918,
    1.027,
    1.111,
    1.105,
    1.013,
    1.006,
    0.960,
    0.936,
    0.891,
    0.883,
    0.875,
    0.923,
    1.031,
    1.126,
    1.179,
    1.217,
    1.308,
    1.065,
    0.954,
    0.825,
    0.767,
    0.697,
    0.745,
    0.732,
    0.891,
    1.031,
    1.102,
    1.050,
    0.950,
    0.887,
    0.876,
    0.810,
    0.797,
    0.802,
    0.805,
    0.977,
    1.062,
    1.146,
    1.150,
    1.147,
    1.132,
    0.956,
    0.850,
    0.686,
    0.603,
    0.599,
    0.600,
    0.634,
    0.705,
    0.944,
    0.945,
    0.893,
    0.855,
    0.840,
    0.747,
    0.717,
    0.704,
    0.581,
    0.707,
    0.827,
    0.885,
    1.070,
    1.210,
    1.173,
    1.103,
    1.014,
    0.847,
    0.652,
    0.604,
    0.595,
    0.602,
    0.574,
    0.702,
    0.865,
    0.912,
    0.752,
    0.778,
    0.733,
    0.748,
    0.778,
    0.754,
    0.787,
    0.878,
    0.959,
    1.028,
    1.021,
    1.089,
    1.139,
    1.080,
    1.006,
    0.908,
    0.772,
    0.650,
    0.639,
    0.643,
    0.662,
    0.689,
    0.839,
    0.892,
    0.904,
    0.834,
    0.851,
    0.814,
    0.732,
    0.792,
    0.753,
    0.859,
    0.827,
    0.940,
    0.964,
    0.998,
    1.094,
    1.076,
    0.932,
    0.809,
    0.713,
    0.648,
    0.627,
    0.664,
    0.684,
    0.713,
    0.802,
    0.863,
    0.945,
    0.911,
    1.031,
    1.027,
    1.022,
    1.005,
    0.998,
    0.977,
    0.978,
    1.059,
    1.114,
    1.073,
    1.083,
    1.096,
    0.864,
    0.752,
    0.602,
    0.581,
    0.555,
    0.597,
    0.596,
    0.658,
    0.779,
    0.954,
    0.947,
    0.936,
    0.951,
    0.823,
    0.858,
    0.835,
    0.792,
    0.823,
    0.935,
    1.016,
    1.087,
    1.167,
    1.132,
    1.202,
    0.980,
    0.776,
    0.655,
    0.646,
    0.650,
    0.635,
    0.688,
    0.740,
    0.884,
    1.027,
    1.006,
    0.865,
    0.871,
    0.811,
    0.873,
    0.875,
    0.841,
    0.842,
    0.941,
    1.010,
    1.118,
    1.183,
    1.139,
    1.196,
    0.992,
    0.838,
    0.695,
    0.640,
    0.638,
    0.692,
    0.693,
    0.773,
    0.918,
    1.057,
    0.986,
    0.875,
    0.890,
    0.801,
    0.818,
    0.826,
    0.767,
    0.758,
    0.803,
    0.978,
    1.054,
    1.114,
    1.180,
    1.108,
    0.950,
    0.769,
    0.593,
    0.552,
    0.578,
    0.563,
    0.632,
    0.723,
    0.906,
    1.041,
    0.959,
    0.945,
    0.895,
    0.870,
    0.833,
    0.781,
    0.788,
    0.757,
    0.789,
    0.900,
    1.059,
    1.160,
    1.210,
    1.109,
    1.064,
    0.853,
    0.710,
    0.697,
    0.666,
    0.721,
    0.716,
    0.810,
    1.064,
    1.048,
    0.944,
    0.959,
    1.000,
    0.912,
    0.907,
    0.884,
    0.823,
    0.861,
    0.882,
    0.986,
    1.045,
    1.019,
    1.010,
    1.014,
    0.967,
    0.873,
    0.777,
    0.731,
    0.740,
    0.737,
    0.715,
    0.783,
    0.940,
    1.017,
    1.009,
    0.922,
    0.929,
    0.858,
    0.897,
    0.938,
    0.863,
    0.878,
    0.984,
    1.034,
    1.109,
    1.113,
    1.197,
    1.177,
    1.067,
    0.833,
    0.760,
    0.700,
    0.677,
    0.678,
    0.714,
    0.768,
    0.934,
    1.078,
    1.117,
    1.057,
    1.025,
    1.011,
    1.106,
    1.076,
    1.030,
    0.951,
    0.920,
    0.995,
    1.094,
    1.132,
    1.159,
    1.146,
    1.121,
    1.014,
    0.936,
    0.935,
    0.951,
    0.968,
    0.915,
    1.034,
    1.080,
    1.157,
    1.198,
    1.095,
    1.009,
    1.086,
    0.985,
    0.913,
    0.813,
    0.815,
    0.863,
    0.946,
    1.087,
    1.143,
    1.151,
    1.001,
    0.978,
    0.858,
    0.708,
    0.661,
    0.668,
    0.659,
    0.687,
    0.737,
    0.876,
    0.922,
    0.957,
    0.859,
    0.895,
    0.849,
    0.777,
    0.703,
    0.756,
    0.804,
    0.853,
    0.985,
    1.044,
    1.060,
    1.208,
    1.114,
    1.002,
    0.769,
    0.617,
    0.598,
    0.646,
    0.664,
    0.670,
    0.761,
    0.969,
    1.057,
    1.079,
    0.879,
    0.864,
    0.824,
    0.828,
    0.796,
    0.729,
    0.716,
    0.836,
    0.949,
    1.138,
    1.236,
    1.300,
    1.269,
    1.179,
    0.905,
    0.714,
    0.669,
    0.644,
    0.634,
    0.664,
    0.778,
    0.943,
    1.035,
    0.880,
    0.799,
    0.815,
    0.761,
    0.701,
    0.673,
    0.653,
    0.706,
    0.783,
    0.875,
    1.020,
    1.146,
    1.286,
    1.159,
    0.997,
    0.798,
    0.677,
    0.598,
    0.588,
    0.587,
    0.592,
    0.620,
    0.838,
    0.934,
    0.796,
    0.789,
    0.793,
    0.742,
    0.720,
    0.721,
    0.744,
    0.723,
    0.872,
    1.018,
    1.087,
    1.093,
    1.136,
    1.117,
    1.024,
    0.840,
    0.728,
    0.632,
    0.669,
    0.666,
    0.679,
    0.731,
    0.973,
    1.031,
    1.057,
    1.049,
    1.100,
    1.045,
    0.926,
    0.969,
    0.902,
    0.933,
    0.968,
    1.014,
    1.053,
    1.175,
    1.146,
    1.191,
    1.072,
    0.869,
    0.715,
    0.679,
    0.642,
    0.619,
    0.657,
    0.697,
    0.859,
    0.919,
    1.003,
    0.854,
    0.838,
    0.823,
    0.828,
    0.852,
    0.886,
    0.898,
    1.047,
    1.088,
    1.192,
    1.285,
    1.190,
    1.138,
    0.950,
    0.806,
    0.664,
    0.603,
    0.545,
    0.541,
    0.534,
    0.581,
    0.664,
    0.865,
    0.930,
    0.920,
    0.808,
    0.809,
    0.799,
    0.932,
    0.974,
    1.012,
    1.040,
    1.114,
    1.228,
    1.189,
    1.195,
    1.130,
    0.988,
    0.735,
    0.607,
    0.557,
    0.537,
    0.528,
    0.530,
    0.583,
    0.740,
    0.903,
    0.855,
    0.847,
    0.839,
    0.851,
    0.765,
    0.806,
    0.773,
    0.939,
    0.963,
    1.030,
    1.057,
    1.156,
    1.156,
    1.085,
    0.933,
    0.676,
    0.613,
    0.544,
    0.524,
    0.541,
    0.559,
    0.664,
    0.840,
    0.973,
    0.829,
    0.845,
    0.850,
    0.763,
    0.743,
    0.793,
    0.840,
    0.828,
    0.928,
    1.049,
    1.171,
    1.227,
    1.296,
    1.286,
    1.134,
    0.867,
    0.729,
    0.649,
    0.656,
    0.691,
    0.673,
    0.740,
    0.911,
    1.004,
    0.969,
    0.868,
    0.843,
    0.843,
    0.868,
    0.781,
    0.848,
    0.870,
    0.967,
    1.132,
    1.199,
    1.266,
    1.222,
    1.224,
    1.052,
    0.845,
    0.767,
    0.646,
    0.653,
    0.673,
    0.641,
    0.721,
    0.905,
    0.907,
    0.875,
    0.867,
    0.838,
    0.796,
    0.878,
    0.906,
    0.893,
    0.844,
    0.932,
    0.985,
    1.081,
    1.121,
    1.085,
    1.080,
    1.039,
    0.901,
    0.800,
    0.757,
    0.707,
    0.746,
    0.783,
    0.769,
    0.903,
    1.077,
    1.097,
    1.038,
    1.027,
    1.063,
    1.026,
    1.015,
    1.029,
    1.019,
    1.071,
    1.113,
    1.163,
    1.074,
    1.097,
    1.124,
    1.041,
    0.867,
    0.726,
    0.710,
    0.698,
    0.669,
    0.654,
    0.706,
    0.845,
    0.942,
    0.882,
    0.915,
    0.865,
    0.829,
    0.875,
    0.852,
    0.933,
    1.033,
    1.047,
    1.108,
    1.162,
    1.121,
    1.165,
    1.180,
    1.098,
    0.810,
    0.667,
    0.600,
    0.572,
    0.587,
    0.572,
    0.574,
    0.627,
    0.785,
    0.826,
    0.884,
    0.903,
    0.923,
    0.897,
    0.876,
    0.854,
    0.938,
    1.009,
    1.181,
    1.234,
    1.237,
    1.243,
    1.183,
    0.993,
    0.785,
    0.629,
    0.527,
    0.580,
    0.563,
    0.558,
    0.575,
    0.727,
    0.773,
    0.792,
    0.799,
    0.797,
    0.828,
    0.779,
    0.796,
    0.866,
    0.975,
    1.125,
    1.195,
    1.309,
    1.304,
    1.226,
    1.248,
    1.158,
    0.805,
    0.706,
    0.601,
    0.639,
    0.584,
    0.602,
    0.684,
    0.838,
    0.905,
    0.839,
    0.791,
    0.682,
    0.718,
    0.711,
    0.635,
    0.695,
    0.831,
    0.911,
    0.999,
    1.046,
    1.145,
    1.204,
    1.167,
    0.973,
    0.768,
    0.591,
    0.548,
    0.535,
    0.544,
    0.575,
    0.640,
    0.792,
    0.928,
    0.840,
    0.813,
    0.831,
    0.762,
    0.756,
    0.760,
    0.759,
    0.858,
    1.026,
    1.122,
    1.204,
    1.225,
    1.154,
    1.247,
    1.071,
    0.843,
    0.679,
    0.594,
    0.634,
    0.607,
    0.605,
    0.713,
    0.825,
    1.006,
    0.971,
    0.921,
    0.827,
    0.759,
    0.814,
    0.791,
    0.797,
    0.800,
    0.887,
    1.011,
    1.196,
    1.256,
    1.167,
    1.157,
    0.972,
    0.826,
    0.644,
    0.587,
    0.528,
    0.531,
    0.538,
    0.566,
    0.682,
    0.815,
    0.890,
    0.777,
    0.783,
    0.766,
    0.858,
    0.885,
    0.915,
    0.952,
    1.073,
    1.150,
    1.226,
    1.279,
    1.130,
    1.169,
    0.989,
    0.829,
    0.656,
    0.578,
    0.508,
    0.537,
    0.542,
    0.603,
    0.738,
    0.790,
    0.768,
    0.840,
    0.834,
    0.786,
    0.814,
    0.824,
    0.875,
    0.866,
    0.870,
    0.941,
    0.989,
    1.111,
    1.142,
    1.113,
    0.862,
    0.684,
    0.573,
    0.562,
    0.560,
    0.568,
    0.578,
    0.649,
    0.737,
    0.820,
    0.852,
    0.928,
    0.920,
    0.840,
    0.754,
    0.850,
    0.845,
    0.867,
    0.988,
    1.035,
    1.109,
    1.153,
    1.177,
    1.102,
    1.043,
    0.768,
    0.591,
    0.547,
    0.549,
    0.565,
    0.577,
    0.619,
    0.761,
    0.822,
    0.801,
    0.809,
    0.765,
    0.681,
    0.710,
    0.787,
    0.907,
    0.906,
    1.007,
    1.243,
    1.383,
    1.265,
    1.185,
    1.132,
    0.978,
    0.786,
    0.673,
    0.579,
    0.538,
    0.546,
    0.549,
    0.654,
    0.804,
    0.867,
    0.870,
    0.854,
    0.815,
    0.801,
    0.736,
    0.749,
    0.788,
    0.887,
    0.982,
    1.068,
    1.128,
    1.249,
    1.189,
    1.167,
    1.074,
    0.790,
    0.625,
    0.546,
    0.542,
    0.540,
    0.578,
    0.642,
    0.808,
    0.816,
    0.797,
    0.698,
    0.699,
    0.704,
    0.697,
    0.679,
    0.729,
    0.800,
    0.873,
    0.950,
    1.044,
    1.098,
    1.164,
    1.155,
    0.995,
    0.830,
    0.631,
    0.560,
    0.545,
    0.532,
    0.580,
    0.680,
    0.792,
    0.935,
    0.874,
    0.900,
    0.777,
    0.768,
    0.752,
    0.756,
    0.804,
    0.928,
    1.109,
    1.204,
    1.210,
    1.280,
    1.155,
    1.080,
    0.911,
    0.769,
    0.584,
    0.531,
    0.498,
    0.508,
    0.492,
    0.552,
    0.645,
    0.694,
    0.685,
    0.710,
    0.722,
    0.739,
    0.791,
    0.812,
    0.914,
    1.074,
    1.080,
    1.353,
    1.381,
    1.330,
    1.215,
    1.212,
    1.070,
    0.840,
    0.667,
    0.588,
    0.574,
    0.533,
    0.553,
    0.599,
    0.694,
    0.747,
    0.796,
    0.904,
    0.869,
    0.893,
    1.011,
    1.019,
    1.113,
    1.172,
    1.163,
    1.264,
    1.307,
    1.334,
    1.301,
    1.241,
    1.147,
    0.939,
    0.807,
    0.709,
    0.692,
    0.697,
    0.669,
    0.745,
    0.793,
    0.976,
    0.951,
    1.084,
    1.011,
    1.028,
    1.099,
    1.168,
    1.078,
    1.040,
    1.087,
    1.205,
    1.179,
    1.236,
    1.206,
    1.106,
    0.998,
    0.834,
    0.723,
    0.677,
    0.680,
    0.671,
    0.642,
    0.713,
    0.873,
    0.867,
    0.845,
    0.805,
    0.917,
    0.890,
    0.876,
    0.911,
    0.930,
    1.085,
    1.084,
    1.282,
    1.296,
    1.244,
    1.315,
    1.283,
    1.064,
    0.828,
    0.718,
    0.638,
    0.602,
    0.581,
    0.569,
    0.668,
    0.872,
    0.951,
    0.824,
    0.779,
    0.763,
    0.755,
    0.754,
    0.787,
    0.830,
    0.790,
    0.852,
    1.023,
    1.181,
    1.206,
    1.136,
    1.175,
    0.976,
    0.766,
    0.637,
    0.585,
    0.561,
    0.558,
    0.581,
    0.679,
    0.830,
    0.807,
    0.787,
    0.769,
    0.715,
    0.701,
    0.783,
    0.738,
    0.727,
    0.839,
    0.980,
    1.032,
    1.196,
    1.195,
    1.204,
    1.216,
    1.053,
    0.776,
    0.616,
    0.538,
    0.545,
    0.541,
    0.527,
    0.552,
    0.742,
    0.817,
    0.805,
    0.777,
    0.817,
    0.745,
    0.700,
    0.761,
    0.774,
    0.814,
    0.939,
    1.002,
    1.164,
    1.252,
    1.221,
    1.126,
    0.988,
    0.830,
    0.698,
    0.576,
    0.521,
    0.507,
    0.510,
    0.523,
    0.644,
    0.689,
    0.728,
    0.801,
    0.807,
    0.781,
    0.847,
    0.862,
    1.064,
    1.205,
    1.279,
    1.434,
    1.572,
    1.551,
    1.430,
    1.256,
    1.096,
    0.911,
    0.716,
    0.564,
    0.528,
    0.548,
    0.525,
    0.556,
    0.575,
    0.644,
    0.751,
    0.735,
    0.734,
    0.806,
    0.939,
    1.022,
    1.262,
    1.434,
    1.539,
    1.719,
    1.842,
    1.798,
    1.528,
    1.281,
    1.080,
    0.874,
    0.735,
    0.603,
    0.589,
    0.572,
    0.548,
    0.576,
    0.599,
    0.696,
    0.767,
    0.765,
    0.883,
    0.985,
    1.103,
    1.248,
    1.386,
    1.470,
    1.552,
    1.608,
    1.705,
    1.671,
    1.506,
    1.388,
    1.200,
    0.898,
    0.754,
    0.705,
    0.612,
    0.582,
    0.551,
    0.617,
    0.702,
    0.788,
    0.751,
    0.810,
    0.893,
    0.875,
    0.898,
    1.066,
    1.171,
    1.307,
    1.497,
    1.540,
    1.677,
    1.649,
    1.620,
    1.503,
    1.207,
    1.015,
    0.803,
    0.710,
    0.672,
    0.612,
    0.625,
    0.637,
    0.731,
    0.846,
    0.823,
    0.846,
    0.857,
    0.836,
    0.959,
    0.967,
    1.098,
    1.230,
    1.382,
    1.554,
    1.641,
    1.642,
    1.539,
    1.536,
    1.347,
    1.034,
    0.839,
    0.697,
    0.641,
    0.674,
    0.624,
    0.700,
    0.871,
    0.912,
    0.805,
    0.844,
    0.938,
    0.897,
    1.045,
    1.175,
    1.327,
    1.433,
    1.651,
    1.772,
    1.779,
    1.739,
    1.623,
    1.557,
    1.333,
    1.049,
    0.906,
    0.785,
    0.723,
    0.695,
    0.653,
    0.676,
    0.801,
    0.893,
    0.809,
    0.927,
    0.938,
    0.962,
    1.105,
    1.115,
    1.174,
    1.179,
    1.305,
    1.445,
    1.467,
    1.539,
    1.554,
    1.396,
    1.216,
    0.993,
    0.848,
    0.752,
    0.687,
    0.648,
    0.631,
    0.645,
    0.713,
    0.767,
    0.785,
    0.860,
    0.908,
    0.903,
    1.070,
    1.103,
    1.258,
    1.419,
    1.521,
    1.661,
    1.652,
    1.573,
    1.513,
    1.414,
    1.308,
    1.049,
    0.898,
    0.775,
    0.697,
    0.690,
    0.671,
    0.712,
    0.800,
    0.809,
    0.864,
    0.880,
    0.931,
    0.979,
    1.177,
    1.314,
    1.363,
    1.546,
    1.730,
    1.868,
    1.800,
    1.696,
    1.576,
    1.561,
    1.393,
    1.097,
    0.845,
    0.735,
    0.672,
    0.646,
    0.640,
    0.639,
    0.709,
    0.760,
    0.809,
    0.790,
    0.939,
    0.992,
    1.134,
    1.316,
    1.362,
    1.553,
    1.643,
    1.803,
    1.885,
    1.917,
    1.670,
    1.559,
    1.354,
    1.025,
    0.800,
    0.713,
    0.675,
    0.646,
    0.591,
    0.621,
    0.741,
    0.808,
    0.803,
    0.827,
    1.025,
    1.061,
    1.163,
    1.150,
    1.323,
    1.452,
    1.601,
    1.775,
    1.740,
    1.802,
    1.624,
    1.430,
    1.285,
    0.973,
    0.812,
    0.698,
    0.616,
    0.611,
    0.589,
    0.654,
    0.759,
    0.827,
    0.817,
    0.792,
    0.804,
    0.888,
    1.024,
    1.126,
    1.247,
    1.373,
    1.659,
    1.735,
    1.916,
    1.778,
    1.718,
    1.614,
    1.345,
    1.001,
    0.782,
    0.698,
    0.630,
    0.582,
    0.560,
    0.600,
    0.772,
    0.869,
    0.842,
    0.829,
    0.836,
    0.974,
    1.052,
    1.088,
    1.210,
    1.279,
    1.508,
    1.505,
    1.557,
    1.547,
    1.475,
    1.496,
    1.400,
    1.038,
    0.883,
    0.716,
    0.684,
    0.652,
    0.629,
    0.631,
    0.775,
    0.767,
    0.795,
    0.899,
    0.900,
    0.965,
    0.962,
    1.045,
    1.229,
    1.392,
    1.514,
    1.595,
    1.674,
    1.580,
    1.477,
    1.468,
    1.276,
    1.080,
    0.823,
    0.716,
    0.668,
    0.648,
    0.576,
    0.582,
    0.634,
    0.745,
    0.747,
    0.888,
    0.853,
    0.883,
    0.962,
    0.924,
    1.042,
    1.281,
    1.255,
    1.291,
    1.376,
    1.397,
    1.282,
    1.274,
    1.050,
    0.805,
    0.702,
    0.624,
    0.581,
    0.597,
    0.585,
    0.560,
    0.649,
    0.650,
    0.704,
    0.739,
    0.772,
    0.820,
    0.828,
    0.876,
    0.910,
    0.979,
    1.211,
    1.293,
    1.402,
    1.323,
    1.210,
    1.110,
    1.092,
    0.846,
    0.670,
    0.582,
    0.539,
    0.546,
    0.507,
    0.518,
    0.595,
    0.700,
    0.755,
    0.819,
    0.763,
    0.872,
    1.054,
    1.079,
    1.079,
    1.241,
    1.221,
    1.378,
    1.435,
    1.437,
    1.419,
    1.287,
    1.196,
    0.928,
    0.793,
    0.747,
    0.664,
    0.630,
    0.585,
    0.626,
    0.662,
    0.757,
    0.879,
    0.842,
    0.887,
    0.957,
    0.930,
    1.031,
    1.214,
    1.350,
    1.590,
    1.725,
    1.744,
    1.716,
    1.592,
    1.455,
    1.231,
    1.026,
    0.835,
    0.698,
    0.643,
    0.618,
    0.599,
    0.665,
    0.783,
    0.869,
    0.856,
    0.819,
    0.851,
    0.974,
    1.060,
    1.202,
    1.279,
    1.352,
    1.440,
    1.628,
    1.568,
    1.547,
    1.501,
    1.506,
    1.282,
    0.970,
    0.776,
    0.652,
    0.610,
    0.594,
    0.554,
    0.620,
    0.745,
    0.784,
    0.839,
    0.812,
    0.829,
    0.882,
    0.947,
    1.162,
    1.274,
    1.478,
    1.685,
    1.858,
    1.931,
    1.913,
    1.763,
    1.652,
    1.405,
    1.168,
    0.912,
    0.754,
    0.681,
    0.637,
    0.594,
    0.639,
    0.769,
    0.816,
    0.851,
    0.862,
    0.978,
    1.062,
    1.219,
    1.522,
    1.700,
    1.836,
    1.898,
    2.024,
    2.140,
    1.986,
    1.879,
    1.759,
    1.508,
    1.167,
    0.939,
    0.807,
    0.723,
    0.702,
    0.657,
    0.647,
    0.669,
    0.742,
    0.915,
    1.000,
    1.011,
    1.243,
    1.431,
    1.533,
    1.712,
    1.781,
    1.901,
    2.024,
    2.091,
    1.982,
    1.763,
    1.692,
    1.578,
    1.227,
    1.071,
    0.888,
    0.812,
    0.731,
    0.722,
    0.719,
    0.739,
    0.762,
    0.904,
    1.059,
    1.170,
    1.326,
    1.636,
    1.817,
    1.999,
    2.082,
    2.171,
    2.238,
    2.188,
    2.141,
    1.953,
    1.874,
    1.653,
    1.332,
    1.040,
    0.856,
    0.782,
    0.737,
    0.702,
    0.714,
    0.679,
    0.721,
    0.847,
    0.975,
    1.046,
    1.186,
    1.410,
    1.636,
    1.666,
    1.834,
    1.931,
    1.913,
    2.096,
    2.089,
    1.892,
    1.634,
    1.394,
    1.087,
    0.889,
    0.793,
    0.769,
    0.733,
    0.642,
    0.661,
    0.676,
    0.703,
    0.706,
    0.770,
    0.861,
    1.006,
    1.193,
    1.304,
    1.546,
    1.667,
    1.803,
    1.865,
    1.901,
    1.867,
    1.636,
    1.487,
    1.230,
    0.996,
    0.866,
    0.721,
    0.625,
    0.616,
    0.570,
    0.626,
    0.666,
    0.683,
    0.713,
    0.750,
    0.871,
    1.020,
    1.212,
    1.305,
    1.481,
    1.674,
    1.847,
    2.009,
    2.091,
    1.999,
    1.861,
    1.673,
    1.425,
    1.156,
    0.914,
    0.816,
    0.699,
    0.661,
    0.635,
    0.641,
    0.642,
    0.794,
    0.836,
    0.796,
    0.876,
    1.084,
    1.264,
    1.445,
    1.515,
    1.717,
    1.910,
    1.929,
    1.997,
    1.928,
    1.761,
    1.652,
    1.477,
    1.063,
    0.940,
    0.851,
    0.733,
    0.760,
    0.692,
    0.667,
    0.699,
    0.741,
    0.770,
    0.884,
    1.074,
    1.194,
    1.362,
    1.460,
    1.695,
    1.820,
    1.973,
    2.019,
    1.999,
    1.906,
    1.655,
    1.458,
    1.362,
    1.243,
    1.023,
    0.923,
    0.782,
    0.695,
    0.639,
    0.660,
    0.652,
    0.723,
    0.745,
    0.784,
    0.909,
    0.972,
    1.085,
    1.253,
    1.431,
    1.483,
    1.604,
    1.777,
    1.805,
    1.633,
    1.572,
    1.519,
    1.408,
    1.057,
    0.875,
    0.771,
    0.703,
    0.618,
    0.605,
    0.620,
    0.606,
    0.714,
    0.729,
    0.841,
    0.983,
    1.032,
    1.133,
    1.275,
    1.573,
    1.722,
    1.857,
    1.904,
    1.870,
    1.887,
    1.691,
    1.633,
    1.277,
    1.091,
    0.867,
    0.756,
    0.662,
    0.652,
    0.621,
    0.645,
    0.666,
    0.697,
    0.707,
    0.836,
    0.983,
    1.106,
    1.299,
    1.424,
    1.599,
    1.770,
    1.885,
    2.008,
    2.076,
    2.107,
    1.993,
    1.760,
    1.559,
    1.336,
    1.081,
    0.961,
    0.827,
    0.745,
    0.680,
    0.698,
    0.766,
    0.778,
    0.791,
    0.794,
    0.999,
    1.222,
    1.428,
    1.674,
    1.930,
    2.154,
    2.229,
    2.273,
    2.313,
    2.177,
    1.983,
    1.892,
    1.638,
    1.257,
    1.051,
    0.877,
    0.815,
    0.789,
    0.710,
    0.731,
    0.715,
    0.841,
    0.905,
    1.010,
    1.274,
    1.292,
    1.474,
    1.629,
    1.875,
    1.962,
    2.080,
    2.285,
    2.283,
    2.223,
    2.091,
    2.007,
    1.689,
    1.341,
    1.084,
    0.974,
    0.891,
    0.816,
    0.756,
    0.723,
    0.749,
    0.836,
    0.828,
    0.888,
    1.010,
    1.250,
    1.417,
    1.597,
    1.828,
    1.995,
    2.087,
    2.229,
    2.330,
    2.289,
    2.180,
    2.031,
    1.637,
    1.273,
    1.094,
    0.900,
    0.846,
    0.809,
    0.738,
    0.743,
    0.766,
    0.848,
    0.865,
    0.964,
    1.237,
    1.385,
    1.528,
    1.768,
    1.970,
    2.165,
    2.298,
    2.602,
    2.696,
    2.458,
    2.400,
    2.131,
    1.756,
    1.523,
    1.214,
    0.988,
    0.901,
    0.809,
    0.776,
    0.773,
    0.751,
    0.812,
    0.888,
    1.007,
    1.139,
    1.338,
    1.568,
    1.744,
    1.823,
    1.931,
    2.158,
    2.121,
    2.152,
    2.072,
    1.946,
    1.850,
    1.600,
    1.258,
    1.066,
    0.922,
    0.875,
    0.815,
    0.770,
    0.723,
    0.730,
    0.844,
    0.951,
    1.045,
    1.137,
    1.312,
    1.559,
    1.873,
    2.032,
    2.167,
    2.285,
    2.323,
    2.285,
    2.262,
    2.205,
    1.993,
    1.683,
    1.468,
    1.215,
    1.007,
    0.939,
    0.828,
    0.802,
    0.818,
    0.766,
    0.852,
    0.972,
    1.085,
    1.248,
    1.465,
    1.728,
    1.898,
    2.094,
    2.285,
    2.553,
    2.683,
    2.693,
    2.537,
    2.336,
    2.118,
    1.890,
    1.562,
    1.313,
    1.141,
    1.031,
    0.933,
    0.822,
    0.824,
    0.798,
    0.856,
    0.951,
    1.066,
    1.289,
    1.566,
    1.695,
    2.015,
    2.190,
    2.304,
    2.547,
    2.699,
    2.748,
    2.550,
    2.438,
    2.317,
    2.000,
    1.650,
    1.354,
    1.218,
    1.070,
    1.008,
    0.943,
    0.978,
    0.979,
    0.996,
    0.937,
    1.007,
    1.114,
    1.324,
    1.551,
    1.779,
    2.076,
    2.294,
    2.396,
    2.596,
    2.618,
    2.571,
    2.392,
    2.161,
    1.945,
    1.545,
    1.238,
    1.076,
    0.990,
    0.948,
    0.867,
    0.880,
    0.870,
    0.879,
    0.931,
    1.056,
    1.289,
    1.453,
    1.676,
    1.972,
    2.198,
    2.452,
    2.553,
    2.820,
    2.939,
    2.761,
    2.508,
    2.274,
    2.181,
    1.849,
    1.449,
    1.273,
    1.140,
    1.041,
    0.937,
    0.918,
    0.952,
    0.972,
    1.014,
    1.127,
    1.391,
    1.579,
    1.868,
    2.109,
    2.367,
    2.585,
    2.708,
    2.894,
    2.759,
    2.600,
    2.395,
    2.121,
    1.943,
    1.525,
    1.290,
    1.026,
    0.924,
    0.892,
    0.814,
    0.793,
    0.812,
    0.849,
    1.030,
    1.171,
    1.417,
    1.552,
    1.873,
    2.020,
    2.123,
    2.470,
    2.630,
    2.526,
    2.594,
    2.598,
    2.388,
    2.199,
    1.963,
    1.593,
    1.349,
    1.148,
    1.056,
    0.998,
    0.897,
    0.869,
    0.856,
    0.998,
    0.981,
    1.007,
    1.251,
    1.567,
    1.858,
    2.124,
    2.271,
    2.499,
    2.645,
    2.776,
    2.806,
    2.667,
    2.431,
    2.283,
    1.921,
    1.663,
    1.314,
    1.172,
    1.051,
    0.971,
    0.926,
    0.884,
    0.959,
    0.946,
    1.037,
    1.091,
    1.362,
    1.573,
    1.793,
    2.000,
    2.193,
    2.396,
    2.539,
    2.720,
    2.715,
    2.567,
    2.432,
    2.223,
    1.987,
    1.583,
    1.228,
    1.057,
    0.984,
    0.913,
    0.833,
    0.810,
    0.900,
    0.882,
    0.923,
    1.034,
    1.165,
    1.397,
    1.681,
    1.890,
    2.279,
    2.362,
    2.481,
    2.694,
    2.824,
    2.748,
    2.565,
    2.412,
    2.048,
    1.660,
    1.332,
    1.167,
    1.044,
    1.009,
    0.904,
    0.902,
    0.919,
    1.010,
    0.939,
    1.072,
    1.278,
    1.483,
    1.780,
    2.030,
    2.266,
    2.401,
    2.524,
    2.809,
    2.861,
    2.909,
    2.655,
    2.422,
    2.115,
    1.749,
    1.413,
    1.147,
    1.048,
    0.961,
    0.905,
    0.895,
    0.904,
    1.010,
    0.990,
    1.171,
    1.383,
    1.642,
    1.965,
    2.112,
    2.453,
    2.658,
    2.773,
    3.120,
    3.113,
    2.976,
    2.832,
    2.500,
    2.269,
    1.931,
    1.554,
    1.315,
    1.207,
    1.125,
    1.052,
    1.041,
    0.946,
    1.018,
    1.049,
    1.219,
    1.453,
    1.728,
    1.916,
    2.243,
    2.488,
    2.681,
    2.822,
    2.919,
    2.963,
    2.849,
    2.632,
    2.447,
    2.137,
    1.785,
    1.546,
    1.357,
    1.163,
    1.031,
    0.987,
    0.912,
    0.876,
    1.019,
    1.060,
    1.109,
    1.365,
    1.659,
    1.938,
    2.017,
    2.150,
    2.205,
    2.444,
    2.532,
    2.628,
    2.505,
    2.303,
    2.297,
    2.018,
    1.657,
    1.260,
    1.027,
    0.995,
    0.921,
    0.853,
    0.885,
    0.850,
    0.868,
    1.010,
    1.138,
    1.270,
    1.474,
    1.762,
    1.917,
    2.053,
    2.138,
    2.289,
    2.378,
    2.547,
    2.573,
    2.392,
    2.185,
    1.888,
    1.569,
    1.228,
    1.065,
    1.025,
    0.938,
    0.904,
    0.879,
    0.872,
    0.978,
    0.949,
    1.134,
    1.306,
    1.450,
    1.632,
    1.907,
    2.151,
    2.314,
    2.318,
    2.414,
    2.393,
    2.398,
    2.250,
    2.049,
    1.888,
    1.609,
    1.317,
    1.134,
    1.043,
    0.932,
    0.877,
    0.903,
    0.925,
    0.960,
    0.946,
    1.136,
    1.262,
    1.626,
    1.838,
    2.042,
    2.145,
    2.210,
    2.359,
    2.439,
    2.575,
    2.480,
    2.392,
    2.295,
    1.941,
    1.537,
    1.297,
    1.097,
    1.025,
    0.892,
    0.865,
    0.884,
    0.905,
    0.941,
    1.005,
    1.167,
    1.337,
    1.646,
    1.858,
    2.069,
    2.225,
    2.411,
    2.596,
    2.774,
    2.838,
    2.598,
    2.395,
    2.241,
    2.022,
    1.592,
    1.379,
    1.186,
    1.099,
    0.994,
    0.968,
    0.886,
    0.940,
    0.947,
    1.006,
    1.160,
    1.372,
    1.561,
    1.967,
    2.057,
    2.274,
    2.434,
    2.692,
    2.823,
    2.921,
    2.882,
    2.554,
    2.505,
    2.299,
    1.852,
    1.519,
    1.322,
    1.160,
    1.103,
    0.991,
    1.011,
    1.001,
    1.019,
    1.117,
    1.273,
    1.417,
    1.710,
    2.045,
    2.227,
    2.408,
    2.583,
    2.678,
    2.874,
    2.948,
    2.813,
    2.565,
    2.304,
    2.029,
    1.778,
    1.485,
    1.267,
    1.110,
    1.024,
    0.928,
    0.921,
    0.864,
    0.904,
    1.019,
    1.242,
    1.509,
    1.638,
    1.839,
    2.209,
    2.516,
    2.580,
    2.678,
    2.741,
    2.772,
    2.627,
    2.449,
    2.236,
    2.109,
    1.761,
    1.397,
    1.229,
    1.094,
    1.038,
    0.942,
    0.926,
    0.870,
    0.912,
    1.066,
    1.220,
    1.473,
    1.694,
    1.854,
    2.025,
    2.204,
    2.396,
    2.568,
    2.606,
    2.694,
    2.669,
    2.444,
    2.200,
    2.034,
    1.702,
    1.371,
    1.187,
    1.128,
    1.025,
    0.966,
    0.975,
    0.950,
    0.980,
    1.081,
    1.179,
    1.312,
    1.511,
    1.693,
    1.797,
    2.026,
    2.273,
    2.389,
    2.452,
    2.539,
    2.440,
    2.332,
    2.146,
    1.825,
    1.526,
    1.350,
    1.166,
    1.073,
    1.006,
    0.914,
    0.909,
    0.907,
    0.929,
    1.058,
    1.093,
    1.252,
    1.485,
    1.760,
    1.993,
    2.177,
    2.475,
    2.628,
    2.705,
    2.741,
    2.624,
    2.452,
    2.311,
    2.010,
    1.697,
    1.403,
    1.205,
    1.112,
    1.072,
    0.980,
    0.940,
    0.863,
    0.876,
    1.015,
    1.235,
    1.470,
    1.710,
    2.071,
    2.393,
    2.628,
    2.736,
    2.900,
    3.013,
    2.947,
    2.781,
    2.528,
    2.320,
    2.071,
    1.819,
    1.531,
    1.305,
    1.140,
    1.067,
    0.989,
    1.013,
    1.002,
    1.055,
    1.171,
    1.267,
    1.432,
    1.674,
    1.894,
    2.062,
    2.333,
    2.485,
    2.677,
    2.768,
    2.865,
    2.768,
    2.579,
    2.386,
    2.079,
    1.707,
    1.451,
    1.261,
    1.122,
    1.044,
    0.989,
    0.978,
    0.942,
    0.997,
    1.097,
    1.243,
    1.442,
    1.652,
    1.740,
    2.032,
    2.182,
    2.291,
    2.436,
    2.543,
    2.583,
    2.513,
    2.252,
    2.165,
    1.928,
    1.595,
    1.338,
    1.203,
    1.075,
    0.967,
    0.883,
    0.864,
    0.873,
    0.905,
    1.028,
    1.203,
    1.456,
    1.724,
    2.029,
    2.128,
    2.343,
    2.414,
    2.601,
    2.663,
    2.725,
    2.610,
    2.316,
    2.237,
    2.054,
    1.733,
    1.442,
    1.284,
    1.157,
    1.072,
    0.985,
    0.986,
    0.938,
    0.984,
    1.007,
    1.161,
    1.295,
    1.559,
    1.781,
    2.021,
    2.164,
    2.283,
    2.451,
    2.560,
    2.584,
    2.416,
    2.331,
    2.166,
    1.915,
    1.593,
    1.358,
    1.215,
    1.120,
    1.028,
    0.964,
    0.915,
    0.861,
    0.910,
    1.022,
    1.207,
    1.498,
    1.746,
    2.064,
    2.182,
    2.444,
    2.506,
    2.675,
    2.787,
    2.916,
    2.934,
    2.793,
    2.552,
    2.193,
    1.923,
    1.605,
    1.370,
    1.236,
    1.068,
    0.990,
    1.010,
    0.991,
    1.005,
    1.066,
    1.285,
    1.424,
    1.707,
    2.020,
    2.249,
    2.526,
    2.708,
    2.827,
    2.838,
    2.873,
    2.873,
    2.648,
    2.483,
    2.131,
    1.772,
    1.502,
    1.324,
    1.200,
    1.130,
    1.058,
    1.054,
    1.036,
    1.096,
    1.134,
    1.212,
    1.364,
    1.662,
    1.978,
    2.157,
    2.391,
    2.546,
    2.713,
    2.833,
    2.899,
    2.845,
    2.609,
    2.410,
    2.175,
    1.863,
    1.494,
    1.307,
    1.208,
    1.105,
    1.056,
    1.033,
    1.024,
    1.075,
    1.176,
    1.267,
    1.436,
    1.679,
    1.951,
    2.118,
    2.299,
    2.555,
    2.709,
    2.800,
    2.847,
    2.762,
    2.505,
    2.438,
    2.239,
    1.880,
    1.489,
    1.306,
    1.180,
    1.082,
    1.029,
    0.972,
    0.985,
    0.981,
    1.074,
    1.188,
    1.429,
    1.715,
    2.017,
    2.289,
    2.490,
    2.652,
    2.767,
    2.724,
    2.761,
    2.748,
    2.428,
    2.221,
    2.001,
    1.737,
    1.449,
    1.301,
    1.164,
    1.058,
    0.946,
    0.909,
    0.885,
    0.935,
    1.075,
    1.307,
    1.530,
    1.717,
    2.001,
    2.180,
    2.483,
    2.708,
    2.786,
    2.886,
    2.904,
    2.772,
    2.629,
    2.394,
    2.088,
    1.782,
    1.523,
    1.318,
    1.227,
    1.069,
    0.971,
    1.008,
    0.933,
    0.953,
    1.114,
    1.250,
    1.364,
    1.741,
    1.991,
    2.246,
    2.562,
    2.712,
    2.797,
    2.899,
    2.860,
    2.694,
    2.533,
    2.368,
    2.156,
    1.807,
    1.553,
    1.381,
    1.191,
    1.058,
    1.010,
    0.972,
    0.921,
    0.936,
    1.076,
    1.292,
    1.469,
    1.799,
    2.176,
    2.406,
    2.575,
    2.653,
    2.734,
    2.727,
    2.792,
    2.696,
    2.519,
    2.387,
    2.136,
    1.853,
    1.525,
    1.307,
    1.231,
    1.168,
    1.093,
    1.093,
    1.077,
    1.041,
    1.145,
    1.353,
    1.502,
    1.756,
    2.048,
    2.348,
    2.602,
    2.764,
    2.928,
    3.048,
    3.132,
    3.016,
    2.945,
    2.710,
    2.437,
    2.005,
    1.749,
    1.446,
    1.356,
    1.303,
    1.224,
    1.217,
    1.231,
    1.160,
    1.248,
    1.450,
    1.639,
    1.939,
    2.257,
    2.434,
    2.633,
    2.839,
    3.051,
    3.181,
    3.356,
    3.247,
    2.997,
    2.698,
    2.420,
    2.094,
    1.753,
    1.530,
    1.372,
    1.276,
    1.169,
    1.154,
    1.128,
    1.123,
    1.194,
    1.318,
    1.609,
    1.844,
    2.111,
    2.381,
    2.581,
    2.741,
    2.904,
    3.150,
    3.145,
    2.981,
    2.864,
    2.617,
    2.396,
    2.062,
    1.732,
    1.465,
    1.351,
    1.223,
    1.170,
    1.160,
    1.098,
    1.087,
    1.139,
    1.390,
    1.575,
    1.933,
    2.209,
    2.387,
    2.665,
    2.866,
    3.003,
    3.211,
    3.302,
    3.092,
    2.909,
    2.616,
    2.373,
    2.004,
    1.667,
    1.518,
    1.382,
    1.280,
    1.165,
    1.107,
    1.046,
    1.060,
    1.184,
    1.298,
    1.641,
    1.900,
    2.329,
    2.511,
    2.751,
    2.881,
    3.088,
    3.182,
    3.201,
    3.163,
    2.877,
    2.626,
    2.428,
    2.038,
    1.681,
    1.462,
    1.321,
    1.220,
    1.098,
    1.086,
    1.046,
    1.069,
    1.179,
    1.326,
    1.511,
    1.845,
    2.080,
    2.314,
    2.474,
    2.582,
    2.676,
    2.768,
    2.792,
    2.799,
    2.535,
    2.431,
    2.166,
    1.775,
    1.528,
    1.329,
    1.240,
    1.125,
    1.106,
    1.061,
    1.004,
    0.981,
    1.018,
    1.306,
    1.413,
    1.668,
    1.878,
    2.111,
    2.295,
    2.443,
    2.667,
    2.678,
    2.771,
    2.751,
    2.489,
    2.318,
    2.129,
    1.698,
    1.483,
    1.260,
    1.155,
    1.066,
    0.986,
    0.975,
    0.979,
    1.004,
    1.079,
    1.214,
    1.408,
    1.570,
    1.889,
    2.074,
    2.353,
    2.511,
    2.686,
    2.841,
    2.835,
    2.706,
    2.533,
    2.284,
    2.015,
    1.687,
    1.422,
    1.262,
    1.174,
    1.065,
    1.016,
    0.998,
    0.982,
    0.948,
    1.002,
    1.187,
    1.394,
    1.591,
    1.870,
    2.136,
    2.283,
    2.511,
    2.701,
    2.825,
    2.917,
    2.808,
    2.615,
    2.429,
    2.191,
    1.885,
    1.523,
    1.335,
    1.217,
    1.127,
    1.071,
    1.070,
    1.037,
    1.071,
    1.092,
    1.244,
    1.323,
    1.526,
    1.725,
    1.970,
    2.238,
    2.385,
    2.541,
    2.758,
    2.882,
    2.725,
    2.460,
    2.204,
    1.919,
    1.634,
    1.420,
    1.285,
    1.186,
    1.067,
    1.002,
    0.984,
    0.966,
    0.989,
    1.005,
    1.133,
    1.273,
    1.512,
    1.641,
    1.810,
    2.034,
    2.121,
    2.224,
    2.408,
    2.551,
    2.567,
    2.226,
    2.069,
    1.758,
    1.455,
    1.231,
    1.132,
    1.064,
    0.985,
    0.944,
    0.903,
    0.923,
    0.906,
    1.003,
    1.162,
    1.384,
    1.586,
    1.869,
    2.132,
    2.371,
    2.550,
    2.749,
    2.946,
    3.033,
    2.911,
    2.592,
    2.469,
    2.137,
    1.779,
    1.573,
    1.322,
    1.236,
    1.133,
    1.068,
    1.032,
    0.989,
    0.960,
    1.065,
    1.207,
    1.444,
    1.600,
    1.815,
    2.129,
    2.401,
    2.616,
    2.743,
    2.812,
    2.823,
    2.710,
    2.533,
    2.352,
    2.085,
    1.754,
    1.454,
    1.268,
    1.138,
    1.079,
    0.995,
    0.963,
    0.982,
    1.005,
    1.039,
    1.147,
    1.331,
    1.527,
    1.756,
    1.880,
    2.187,
    2.349,
    2.485,
    2.616,
    2.552,
    2.436,
    2.261,
    2.066,
    1.853,
    1.538,
    1.334,
    1.252,
    1.153,
    1.014,
    0.975,
    0.962,
    0.952,
    0.986,
    1.067,
    1.128,
    1.328,
    1.551,
    1.824,
    2.027,
    2.233,
    2.361,
    2.540,
    2.738,
    2.761,
    2.559,
    2.313,
    2.107,
    1.842,
    1.634,
    1.313,
    1.138,
    1.049,
    0.970,
    0.879,
    0.890,
    0.845,
    0.933,
    0.965,
    1.036,
    1.240,
    1.473,
    1.729,
    1.995,
    2.240,
    2.376,
    2.550,
    2.662,
    2.806,
    2.602,
    2.317,
    2.171,
    1.952,
    1.609,
    1.345,
    1.179,
    1.087,
    1.020,
    0.949,
    0.941,
    0.964,
    0.915,
    1.035,
    1.220,
    1.350,
    1.541,
    1.768,
    2.015,
    2.201,
    2.346,
    2.518,
    2.733,
    2.824,
    2.718,
    2.513,
    2.314,
    1.991,
    1.637,
    1.458,
    1.260,
    1.079,
    1.004,
    0.915,
    0.932,
    0.980,
    0.961,
    0.944,
    1.120,
    1.281,
    1.473,
    1.757,
    2.031,
    2.243,
    2.430,
    2.499,
    2.580,
    2.580,
    2.402,
    2.259,
    2.169,
    1.869,
    1.643,
    1.355,
    1.260,
    1.089,
    1.001,
    0.968,
    0.928,
    0.932,
    0.927,
    1.090,
    1.256,
    1.371,
    1.659,
    1.868,
    2.086,
    2.308,
    2.523,
    2.664,
    2.728,
    2.746,
    2.695,
    2.425,
    2.298,
    1.990,
    1.685,
    1.444,
    1.273,
    1.137,
    1.065,
    0.998,
    0.944,
    0.931,
    0.921,
    1.002,
    1.174,
    1.340,
    1.599,
    1.935,
    2.263,
    2.427,
    2.563,
    2.755,
    2.782,
    2.895,
    2.690,
    2.457,
    2.300,
    2.084,
    1.653,
    1.446,
    1.241,
    1.184,
    1.056,
    1.000,
    0.955,
    0.934,
    0.925,
    1.037,
    1.142,
    1.317,
    1.532,
    1.768,
    1.932,
    2.187,
    2.398,
    2.631,
    2.685,
    2.683,
    2.603,
    2.459,
    2.328,
    1.982,
    1.679,
    1.409,
    1.262,
    1.165,
    1.129,
    0.980,
    0.991,
    0.998,
    0.996,
    1.071,
    1.182,
    1.328,
    1.602,
    1.887,
    2.221,
    2.320,
    2.497,
    2.555,
    2.606,
    2.632,
    2.417,
    2.256,
    2.083,
    1.887,
    1.553,
    1.353,
    1.162,
    1.137,
    1.074,
    1.029,
    1.012,
    1.037,
    1.068,
    1.063,
    1.069,
    1.248,
    1.399,
    1.606,
    1.794,
    2.010,
    2.178,
    2.289,
    2.395,
    2.497,
    2.353,
    2.195,
    1.979,
    1.847,
    1.581,
    1.263,
    1.178,
    1.093,
    0.959,
    0.941,
    0.903,
    0.947,
    0.928,
    0.978,
    1.063,
    1.201,
    1.374,
    1.585,
    1.765,
    1.985,
    2.243,
    2.350,
    2.459,
    2.561,
    2.453,
    2.287,
    2.109,
    1.933,
    1.541,
    1.335,
    1.222,
    1.096,
    1.081,
    0.992,
    0.993,
    0.967,
    1.028,
    1.064,
    1.164,
    1.283,
    1.516,
    1.772,
    1.941,
    2.202,
    2.358,
    2.421,
    2.652,
    2.603,
    2.464,
    2.353,
    2.214,
    1.961,
    1.618,
    1.357,
    1.240,
    1.126,
    1.078,
    0.971,
    0.943,
    0.912,
    0.961,
    1.055,
    1.194,
    1.450,
    1.573,
    1.842,
    2.003,
    2.172,
    2.299,
    2.349,
    2.457,
    2.318,
    2.138,
    1.950,
    1.991,
    1.746,
    1.491,
    1.227,
    1.069,
    1.014,
    0.936,
    0.869,
    0.853,
    0.867,
    0.850,
    0.913,
    1.067,
    1.224,
    1.345,
    1.574,
    1.701,
    1.917,
    2.140,
    2.171,
    2.258,
    2.249,
    2.041,
    1.965,
    1.849,
    1.704,
    1.416,
    1.242,
    1.043,
    0.962,
    0.965,
    0.950,
    0.897,
    0.882,
    0.923,
    0.968,
    1.081,
    1.245,
    1.389,
    1.643,
    1.827,
    2.111,
    2.261,
    2.349,
    2.367,
    2.416,
    2.303,
    2.149,
    1.941,
    1.671,
    1.416,
    1.169,
    1.042,
    0.947,
    0.863,
    0.813,
    0.842,
    0.854,
    0.928,
    0.896,
    0.953,
    1.071,
    1.247,
    1.471,
    1.559,
    1.768,
    2.044,
    2.167,
    2.362,
    2.314,
    2.316,
    2.121,
    2.045,
    1.779,
    1.329,
    1.135,
    0.975,
    0.927,
    0.841,
    0.823,
    0.792,
    0.870,
    0.894,
    0.860,
    0.841,
    1.032,
    1.197,
    1.427,
    1.616,
    1.735,
    2.054,
    2.214,
    2.561,
    2.574,
    2.448,
    2.328,
    2.179,
    1.792,
    1.475,
    1.265,
    1.133,
    1.009,
    0.968,
    0.886,
    0.900,
    0.958,
    0.892,
    0.931,
    1.030,
    1.186,
    1.333,
    1.663,
    1.856,
    2.149,
    2.377,
    2.487,
    2.672,
    2.738,
    2.631,
    2.391,
    2.189,
    1.917,
    1.552,
    1.303,
    1.116,
    0.996,
    0.936,
    0.872,
    0.861,
    0.930,
    0.881,
    0.902,
    0.966,
    1.139,
    1.336,
    1.703,
    2.083,
    2.227,
    2.427,
    2.612,
    2.682,
    2.661,
    2.484,
    2.205,
    1.994,
    1.757,
    1.530,
    1.319,
    1.173,
    1.057,
    1.009,
    0.908,
    0.912,
    0.863,
    0.868,
    0.952,
    1.078,
    1.317,
    1.516,
    1.775,
    2.054,
    2.189,
    2.462,
    2.595,
    2.720,
    2.707,
    2.550,
    2.417,
    2.234,
    1.804,
    1.520,
    1.325,
    1.214,
    1.117,
    1.030,
    0.959,
    0.937,
    0.869,
    0.853,
    1.027,
    1.157,
    1.358,
    1.516,
    1.850,
    2.043,
    2.278,
    2.501,
    2.669,
    2.681,
    2.509,
    2.380,
    2.221,
    2.123,
    1.828,
    1.469,
    1.263,
    1.134,
    1.029,
    0.988,
    0.905,
    0.920,
    0.882,
    0.922,
    0.953,
    1.055,
    1.153,
    1.301,
    1.446,
    1.623,
    1.752,
    1.948,
    2.036,
    2.113,
    2.179,
    2.053,
    1.965,
    1.798,
    1.617,
    1.261,
    1.069,
    0.977,
    0.885,
    0.837,
    0.840,
    0.819,
    0.881,
    0.836,
    0.802,
    0.812,
    0.917,
    1.135,
    1.320,
    1.501,
    1.645,
    1.849,
    2.029,
    2.123,
    2.242,
    2.174,
    1.943,
    1.811,
    1.584,
    1.262,
    1.049,
    0.955,
    0.862,
    0.837,
    0.792,
    0.807,
    0.854,
    0.850,
    0.877,
    0.940,
    0.990,
    1.109,
    1.292,
    1.466,
    1.661,
    1.770,
    1.907,
    2.135,
    2.150,
    2.077,
    1.941,
    1.837,
    1.595,
    1.331,
    1.142,
    1.029,
    0.955,
    0.884,
    0.815,
    0.837,
    0.902,
    0.897,
    0.905,
    0.957,
    1.074,
    1.249,
    1.346,
    1.532,
    1.800,
    1.972,
    2.142,
    2.403,
    2.414,
    2.195,
    2.014,
    1.985,
    1.751,
    1.367,
    1.162,
    1.001,
    0.958,
    0.852,
    0.822,
    0.810,
    0.909,
    0.904,
    0.925,
    0.921,
    1.039,
    1.188,
    1.381,
    1.514,
    1.742,
    1.957,
    2.128,
    2.104,
    2.216,
    2.123,
    1.907,
    1.904,
    1.648,
    1.361,
    1.221,
    1.042,
    0.950,
    0.906,
    0.830,
    0.838,
    0.838,
    0.826,
    0.957,
    1.035,
    1.216,
    1.480,
    1.639,
    1.828,
    2.049,
    2.293,
    2.304,
    2.369,
    2.342,
    2.163,
    1.934,
    1.779,
    1.594,
    1.313,
    1.128,
    0.981,
    0.950,
    0.844,
    0.796,
    0.787,
    0.841,
    0.836,
    0.925,
    1.046,
    1.129,
    1.341,
    1.549,
    1.777,
    2.037,
    2.341,
    2.450,
    2.456,
    2.406,
    2.241,
    2.050,
    1.959,
    1.699,
    1.425,
    1.161,
    1.012,
    0.967,
    0.914,
    0.822,
    0.823,
    0.882,
    0.858,
    0.842,
    0.956,
    1.090,
    1.191,
    1.431,
    1.606,
    1.762,
    1.971,
    2.094,
    2.268,
    2.330,
    2.255,
    1.999,
    1.909,
    1.621,
    1.306,
    1.127,
    1.003,
    0.930,
    0.885,
    0.830,
    0.839,
    0.906,
    0.925,
    0.910,
    0.892,
    0.979,
    1.189,
    1.438,
    1.630,
    1.875,
    1.968,
    2.118,
    2.237,
    2.259,
    2.187,
    2.083,
    1.858,
    1.610,
    1.303,
    1.045,
    0.910,
    0.851,
    0.804,
    0.775,
    0.821,
    0.830,
    0.793,
    0.793,
    0.843,
    0.972,
    0.997,
    1.208,
    1.336,
    1.485,
    1.622,
    1.915,
    2.068,
    2.156,
    2.132,
    1.941,
    1.841,
    1.626,
    1.276,
    1.044,
    0.892,
    0.861,
    0.795,
    0.746,
    0.774,
    0.808,
    0.830,
    0.848,
    0.848,
    0.969,
    1.151,
    1.241,
    1.396,
    1.526,
    1.737,
    1.880,
    1.995,
    2.137,
    1.984,
    1.825,
    1.727,
    1.557,
    1.248,
    0.998,
    0.863,
    0.848,
    0.787,
    0.759,
    0.733,
    0.801,
    0.843,
    0.852,
    0.928,
    1.021,
    1.129,
    1.314,
    1.503,
    1.660,
    1.834,
    1.959,
    2.133,
    2.080,
    1.909,
    1.796,
    1.719,
    1.559,
    1.287,
    1.048,
    0.931,
    0.889,
    0.796,
    0.764,
    0.752,
    0.755,
    0.788,
    0.834,
    0.905,
    1.012,
    1.155,
    1.389,
    1.630,
    1.801,
    2.059,
    2.095,
    2.199,
    2.077,
    1.924,
    1.779,
    1.748,
    1.423,
    1.252,
    1.084,
    0.921,
    0.841,
    0.779,
    0.740,
    0.706,
    0.767,
    0.745,
    0.793,
    1.018,
    1.057,
    1.350,
    1.468,
    1.631,
    1.875,
    1.980,
    2.236,
    2.272,
    2.261,
    2.138,
    1.969,
    1.772,
    1.510,
    1.271,
    1.076,
    0.949,
    0.861,
    0.798,
    0.761,
    0.736,
    0.734,
    0.756,
    0.779,
    0.922,
    1.099,
    1.282,
    1.466,
    1.658,
    1.947,
    2.133,
    2.221,
    2.220,
    2.292,
    2.172,
    2.052,
    1.865,
    1.609,
    1.261,
    1.099,
    0.932,
    0.894,
    0.829,
    0.802,
    0.788,
    0.807,
    0.860,
    0.901,
    0.997,
    1.078,
    1.180,
    1.392,
    1.591,
    1.768,
    1.969,
    2.128,
    2.272,
    2.306,
    2.161,
    1.977,
    1.903,
    1.625,
    1.388,
    1.130,
    0.966,
    0.907,
    0.835,
    0.786,
    0.760,
    0.835,
    0.886,
    0.801,
    0.847,
    0.946,
    1.023,
    1.214,
    1.393,
    1.576,
    1.695,
    1.933,
    2.019,
    2.056,
    1.944,
    1.844,
    1.721,
    1.496,
    1.184,
    1.015,
    0.875,
    0.794,
    0.743,
    0.695,
    0.740,
    0.860,
    0.888,
    0.780,
    0.851,
    0.868,
    0.922,
    1.091,
    1.203,
    1.357,
    1.486,
    1.657,
    1.859,
    1.927,
    1.837,
    1.647,
    1.604,
    1.459,
    1.178,
    0.963,
    0.841,
    0.754,
    0.720,
    0.708,
    0.706,
    0.778,
    0.811,
    0.797,
    0.802,
    0.911,
    1.051,
    1.225,
    1.285,
    1.426,
    1.579,
    1.693,
    1.788,
    1.832,
    1.735,
    1.598,
    1.559,
    1.404,
    1.153,
    0.984,
    0.904,
    0.830,
    0.782,
    0.719,
    0.747,
    0.751,
    0.760,
    0.816,
    0.903,
    0.954,
    1.147,
    1.332,
    1.556,
    1.655,
    1.802,
    1.926,
    1.999,
    2.031,
    1.912,
    1.771,
    1.689,
    1.469,
    1.204,
    1.037,
    0.896,
    0.833,
    0.794,
    0.727,
    0.739,
    0.721,
    0.759,
    0.826,
    0.961,
    1.080,
    1.149,
    1.298,
    1.494,
    1.574,
    1.818,
    1.962,
    2.001,
    2.122,
    1.884,
    1.806,
    1.687,
    1.466,
    1.211,
    0.973,
    0.851,
    0.773,
    0.743,
    0.695,
    0.655,
    0.749,
    0.775,
    0.795,
    0.781,
    0.921,
    0.986,
    1.039,
    1.170,
    1.355,
    1.548,
    1.690,
    1.760,
    1.833,
    1.855,
    1.706,
    1.607,
    1.357,
    1.070,
    0.890,
    0.819,
    0.712,
    0.684,
    0.652,
    0.666,
    0.787,
    0.826,
    0.735,
    0.798,
    0.837,
    0.909,
    1.059,
    1.157,
    1.369,
    1.627,
    1.853,
    1.929,
    2.067,
    2.026,
    1.853,
    1.740,
    1.423,
    1.159,
    0.966,
    0.884,
    0.795,
    0.765,
    0.723,
    0.763,
    0.869,
    0.848,
    0.842,
    0.892,
    0.916,
    0.987,
    1.216,
    1.318,
    1.486,
    1.709,
    1.915,
    2.157,
    2.136,
    2.100,
    2.094,
    1.871,
    1.527,
    1.179,
    1.024,
    0.888,
    0.834,
    0.779,
    0.731,
    0.732,
    0.830,
    0.843,
    0.812,
    0.843,
    0.926,
    0.989,
    1.127,
    1.189,
    1.412,
    1.620,
    1.834,
    1.985,
    1.958,
    1.905,
    1.859,
    1.789,
    1.508,
    1.258,
    1.009,
    0.891,
    0.796,
    0.774,
    0.727,
    0.722,
    0.822,
    0.834,
    0.770,
    0.864,
    1.009,
    1.080,
    1.219,
    1.442,
    1.609,
    1.732,
    1.950,
    2.032,
    2.051,
    1.943,
    1.854,
    1.701,
    1.525,
    1.357,
    1.104,
    0.926,
    0.803,
    0.782,
    0.746,
    0.738,
    0.777,
    0.813,
    0.826,
    0.963,
    1.043,
    1.227,
    1.349,
    1.526,
    1.706,
    1.862,
    2.071,
    2.123,
    2.051,
    1.974,
    1.876,
    1.669,
    1.419,
    1.204,
    1.015,
    0.883,
    0.792,
    0.742,
    0.706,
    0.733,
    0.756,
    0.839,
    0.812,
    0.908,
    1.046,
    1.223,
    1.415,
    1.598,
    1.665,
    1.720,
    1.887,
    2.044,
    2.016,
    1.929,
    1.802,
    1.659,
    1.477,
    1.218,
    0.960,
    0.881,
    0.797,
    0.738,
    0.714,
    0.726,
    0.762,
    0.810,
    0.815,
    0.896,
    1.006,
    1.076,
    1.302,
    1.474,
    1.561,
    1.741,
    1.921,
    1.904,
    2.029,
    1.935,
    1.817,
    1.754,
    1.377,
    1.126,
    0.965,
    0.872,
    0.806,
    0.764,
    0.722,
    0.757,
    0.793,
    0.800,
    0.814,
    0.886,
    1.012,
    1.170,
    1.355,
    1.518,
    1.717,
    1.960,
    2.086,
    2.228,
    2.270,
    2.205,
    2.042,
    1.809,
    1.470,
    1.210,
    1.092,
    0.928,
    0.825,
    0.767,
    0.768,
    0.726,
    0.885,
    0.848,
    0.852,
    0.887,
    0.978,
    1.068,
    1.221,
    1.447,
    1.569,
    1.921,
    2.069,
    2.184,
    2.216,
    2.056,
    1.943,
    1.747,
    1.474,
    1.219,
    0.998,
    0.906,
    0.826,
    0.778,
    0.733,
    0.725,
    0.830,
    0.899,
    0.813,
    0.890,
    0.921,
    1.008,
    1.182,
    1.289,
    1.436,
    1.637,
    1.794,
    1.922,
    1.911,
    1.839,
    1.744,
    1.648,
    1.352,
    1.127,
    0.862,
    0.792,
    0.797,
    0.712,
    0.671,
    0.683,
    0.787,
    0.813,
    0.815,
    0.845,
    0.963,
    1.015,
    1.086,
    1.283,
    1.366,
    1.513,
    1.596,
    1.721,
    1.790,
    1.707,
    1.497,
    1.448,
    1.283,
    1.055,
    0.877,
    0.764,
    0.715,
    0.677,
    0.670,
    0.653,
    0.777,
    0.755,
    0.797,
    0.941,
    0.932,
    1.109,
    1.180,
    1.418,
    1.504,
    1.604,
    1.805,
    1.828,
    1.867,
    1.734,
    1.564,
    1.441,
    1.312,
    1.006,
    0.884,
    0.756,
    0.676,
    0.670,
    0.636,
    0.634,
    0.683,
    0.729,
    0.778,
    0.874,
    0.927,
    0.940,
    1.118,
    1.299,
    1.365,
    1.528,
    1.687,
    1.763,
    1.884,
    1.742,
    1.560,
    1.454,
    1.268,
    1.059,
    0.887,
    0.786,
    0.688,
    0.643,
    0.622,
    0.649,
    0.735,
    0.743,
    0.786,
    0.876,
    0.928,
    0.976,
    1.080,
    1.218,
    1.413,
    1.568,
    1.684,
    1.795,
    1.830,
    1.657,
    1.650,
    1.564,
    1.346,
    1.042,
    0.890,
    0.769,
    0.780,
    0.687,
    0.657,
    0.661,
    0.752,
    0.818,
    0.756,
    0.785,
    0.855,
    0.861,
    0.974,
    1.081,
    1.136,
    1.300,
    1.424,
    1.640,
    1.608,
    1.556,
    1.514,
    1.416,
    1.195,
    0.958,
    0.772,
    0.735,
    0.657,
    0.656,
    0.641,
    0.668,
    0.761,
    0.796,
    0.784,
    0.737,
    0.719,
    0.763,
    0.823,
    0.883,
    0.969,
    1.074,
    1.131,
    1.257,
    1.360,
    1.356,
    1.366,
    1.249,
    1.013,
    0.906,
    0.789,
    0.698,
    0.643,
    0.635,
    0.622,
    0.679,
    0.807,
    0.904,
    0.760,
    0.842,
    0.859,
    0.840,
    0.869,
    0.908,
    0.973,
    1.145,
    1.317,
    1.350,
    1.452,
    1.351,
    1.296,
    1.308,
    1.129,
    0.888,
    0.747,
    0.670,
    0.615,
    0.618,
    0.631,
    0.664,
    0.716,
    0.773,
    0.771,
    0.779,
    0.843,
    0.910,
    0.958,
    1.029,
    1.049,
    1.235,
    1.370,
    1.454,
    1.291,
    1.343,
    1.352,
    1.297,
    1.076,
    0.958,
    0.790,
    0.669,
    0.604,
    0.638,
    0.582,
    0.598,
    0.691,
    0.744,
    0.835,
    0.852,
    0.882,
    0.871,
    0.975,
    1.033,
    1.061,
    1.232,
    1.277,
    1.411,
    1.349,
    1.370,
    1.348,
    1.336,
    1.128,
    0.890,
    0.776,
    0.698,
    0.634,
    0.606,
    0.609,
    0.617,
    0.711,
    0.710,
    0.761,
    0.766,
    0.855,
    0.909,
    0.955,
    1.072,
    1.231,
    1.346,
    1.375,
    1.434,
    1.382,
    1.433,
    1.358,
    1.306,
    1.159,
    1.010,
    0.820,
    0.725,
    0.655,
    0.612,
    0.631,
    0.650,
    0.676,
    0.722,
    0.748,
    0.780,
    0.834,
    0.866,
    0.999,
    1.097,
    1.249,
    1.376,
    1.501,
    1.453,
    1.499,
    1.424,
    1.388,
    1.274,
    1.117,
    0.847,
    0.760,
    0.649,
    0.585,
    0.598,
    0.572,
    0.584,
    0.740,
    0.809,
    0.784,
    0.743,
    0.824,
    0.812,
    0.965,
    1.157,
    1.248,
    1.509,
    1.630,
    1.652,
    1.672,
    1.686,
    1.581,
    1.459,
    1.272,
    0.964,
    0.801,
    0.726,
    0.671,
    0.633,
    0.606,
    0.672,
    0.782,
    0.812,
    0.742,
    0.728,
    0.752,
    0.885,
    0.991,
    1.004,
    1.131,
    1.224,
    1.379,
    1.547,
    1.636,
    1.595,
    1.485,
    1.352,
    1.152,
    0.951,
    0.820,
    0.746,
    0.694,
    0.654,
    0.626,
    0.662,
    0.842,
    0.806,
    0.724,
    0.814,
    0.756,
    0.759,
    0.834,
    0.948,
    0.973,
    1.160,
    1.321,
    1.384,
    1.365,
    1.378,
    1.326,
    1.223,
    1.013,
    0.853,
    0.711,
    0.655,
    0.602,
    0.626,
    0.571,
    0.586,
    0.740,
    0.768,
    0.745,
    0.742,
    0.823,
    0.811,
    0.932,
    1.011,
    1.135,
    1.279,
    1.460,
    1.467,
    1.461,
    1.363,
    1.389,
    1.286,
    1.129,
    0.941,
    0.780,
    0.654,
    0.638,
    0.625,
    0.596,
    0.598,
    0.660,
    0.700,
    0.761,
    0.851,
    0.786,
    0.881,
    0.995,
    1.134,
    1.193,
    1.273,
    1.415,
    1.448,
    1.488,
    1.396,
    1.332,
    1.203,
    0.938,
    0.846,
    0.680,
    0.605,
    0.563,
    0.553,
    0.551,
    0.591,
    0.649,
    0.698,
    0.782,
    0.831,
    0.904,
    0.775,
    0.790,
    0.773,
    0.846,
    0.935,
    1.039,
    1.097,
    1.112,
    1.120,
    1.064,
    1.007,
    0.865,
    0.798,
    0.698,
    0.571,
    0.598,
    0.596,
    0.594,
    0.607,
    0.658,
    0.705,
    0.739,
    0.698,
    0.723,
    0.776,
    0.842,
    0.801,
    0.824,
    0.869,
    0.998,
    1.026,
    1.142,
    1.188,
    1.191,
    1.107,
    0.973,
    0.800,
    0.618,
    0.553,
    0.552,
    0.532,
    0.512,
    0.569,
    0.606,
    0.703,
    0.737,
    0.752,
    0.823,
    0.774,
    0.850,
    0.860,
    0.879,
    0.873,
    0.984,
    1.138,
    1.186,
    1.241,
    1.277,
    1.197,
    1.014,
    0.819,
    0.694,
    0.655,
    0.616,
    0.614,
    0.650,
    0.712,
    0.829,
    1.004,
    0.880,
    0.807,
    0.776,
    0.719,
    0.734,
    0.782,
    0.767,
    0.764,
    0.815,
    0.947,
    1.218,
    1.128,
    1.230,
    1.189,
    1.026,
    0.880,
    0.712,
    0.706,
    0.684,
    0.694,
    0.720,
    0.778,
    0.901,
    1.017,
    0.883,
    0.884,
    0.924,
    0.905,
    0.902,
    0.862,
    0.808,
    0.901,
    0.992,
    1.050,
    1.207,
    1.249,
    1.280,
    1.219,
    1.119,
    0.877,
    0.794,
    0.766,
    0.679,
    0.681,
    0.678,
    0.693,
    0.828,
    0.924,
    0.935,
    0.928,
    0.865,
    0.796,
    0.795,
    0.798,
    0.736,
    0.899,
    0.949,
    0.989,
    1.031,
    1.157,
    1.151,
    1.141,
    1.045,
    0.895,
    0.690,
    0.626,
    0.594,
    0.577,
    0.563,
    0.624,
    0.726,
    0.813,
    0.903,
    0.839,
    0.848,
    0.877,
    0.952,
    0.853,
    0.923,
    0.997,
    0.969,
    1.044,
    1.094,
    1.189,
    1.154,
    1.054,
    0.937,
    0.783,
    0.675,
    0.627,
    0.573,
    0.582,
    0.593,
    0.563,
    0.664,
    0.718,
    0.771,
    0.810,
    0.907,
    0.998,
    1.095,
    1.137,
    1.187,
    1.303,
    1.267,
    1.421,
    1.530,
    1.473,
    1.389,
    1.264,
    1.179,
    1.024,
    0.902,
    0.780,
    0.762,
    0.749,
    0.715,
    0.791,
    0.887,
    0.912,
    0.964,
    0.946,
    0.991,
    0.996,
    0.907,
    1.022,
    0.994,
    1.092,
    1.096,
    1.254,
    1.337,
    1.433,
    1.410,
    1.205,
    1.082,
    0.941,
    0.813,
    0.760,
    0.768,
    0.755,
    0.760,
    0.801,
    1.020,
    0.967,
    0.969,
    0.926,
    0.869,
    0.839,
    0.865,
    0.999,
    0.981,
    1.001,
    1.098,
    1.251,
    1.228,
    1.319,
    1.297,
    1.244,
    1.232,
    0.962,
    0.785,
    0.715,
    0.739,
    0.711,
    0.735,
    0.799,
    0.947,
    1.012,
    1.025,
    0.900,
    0.820,
    0.800,
    0.733,
    0.799,
    0.792,
    0.876,
    1.038,
    1.121,
    1.249,
    1.397,
    1.377,
    1.164,
    1.049,
    0.834,
    0.721,
    0.661,
    0.659,
    0.624,
    0.630,
    0.706,
    0.859,
    0.899,
    0.952,
    0.867,
    0.786,
    0.728,
    0.780,
    0.797,
    0.802,
    0.925,
    1.066,
    1.178,
    1.253,
    1.237,
    1.231,
    1.103,
    0.991,
    0.861,
    0.725,
    0.623,
    0.630,
    0.630,
    0.664,
    0.671,
    0.811,
    0.949,
    0.874,
    0.825,
    0.825,
    0.820,
    0.780,
    0.798,
    0.793,
    0.780,
    0.900,
    0.959,
    1.095,
    1.114,
    1.058,
    1.142,
    0.969,
    0.835,
    0.670,
    0.574,
    0.546,
    0.564,
    0.590,
    0.611,
    0.741,
    0.842,
    0.899,
    0.924,
    0.956,
    0.774,
    0.798,
    0.858,
    0.870,
    0.862,
    0.897,
    0.905,
    1.000,
    1.118,
    1.090,
    1.021,
    0.884,
    0.746,
    0.615,
    0.590,
    0.570,
    0.574,
    0.614,
    0.686,
    0.777,
    0.892,
    0.862,
    0.922,
    0.814,
    0.766,
    0.837,
    0.804,
    0.904,
    0.849,
    0.902,
    0.966,
    1.025,
    1.127,
    1.158,
    1.029,
    0.898,
    0.744,
    0.611,
    0.582,
    0.585,
    0.602,
    0.608,
    0.753,
    0.826,
    0.922,
    0.998,
    0.975,
    0.927,
    0.898,
    0.833,
    0.803,
    0.819,
    0.875,
    0.924,
    0.980,
    1.071,
    1.082,
    1.151,
    0.967,
    0.895,
    0.766,
    0.626,
    0.552,
    0.551,
    0.581,
    0.640,
    0.682,
    0.827,
    0.886,
    0.845,
    0.858,
    0.829,
    0.765,
    0.720,
    0.713,
    0.710,
    0.781,
    0.876,
    0.988,
    1.072,
    1.307,
    1.233,
    1.202,
    0.984,
    0.835,
    0.722,
    0.641,
    0.622,
    0.643,
    0.701,
    0.744,
    0.970,
    0.969,
    0.971,
    0.846,
    0.913,
    0.918,
    0.855,
    0.867,
    0.892,
    0.979,
    1.107,
    1.241,
    1.315,
    1.397,
    1.396,
    1.337,
    1.118,
    0.979,
    0.842,
    0.824,
    0.836,
    0.801,
    0.861,
    0.881,
    1.059,
    1.168,
    1.065,
    0.946,
    0.979,
    0.845,
    0.784,
    0.755,
    0.726,
    0.773,
    0.799,
    0.980,
    1.080,
    1.227,
    1.305,
    1.242,
    1.051,
    0.890,
    0.762,
    0.732,
    0.691,
    0.713,
    0.709,
    0.837,
    1.025,
    1.009,
    0.930,
    0.886,
    0.873,
    0.854,
    0.791,
    0.778,
    0.784,
    0.843,
    0.897,
    1.047,
    1.059,
    1.162,
    1.187,
    1.126,
    0.895,
    0.808,
    0.681,
    0.625,
    0.642,
    0.659,
    0.704,
    0.743,
    0.926,
    0.949,
    1.116,
    1.039,
    1.026,
    1.001,
    0.993,
    0.971,
    0.895,
    0.949,
    1.089,
    1.159,
    1.166,
    1.249,
    1.231,
    1.118,
    1.089,
    0.890,
    0.774,
    0.725,
    0.726,
    0.757,
    0.746,
    0.811,
    0.933,
    0.968,
    0.984,
    1.013,
    1.083,
    0.963,
    1.023,
    1.019,
    1.044,
    0.964,
    1.018,
    1.080,
    1.231,
    1.247,
    1.276,
    1.137,
    1.104,
    0.876,
    0.838,
    0.832,
    0.803,
    0.796,
    0.865,
    0.897,
    0.960,
    1.088,
    1.071,
    1.057,
    1.016,
    0.959,
    0.973,
    0.988,
    1.020,
    1.041,
    1.216,
    1.303,
    1.321,
    1.316,
    1.327,
    1.278,
    1.114,
    0.962,
    0.815,
    0.781,
    0.781,
    0.764,
    0.779,
    0.853,
    1.007,
    1.030,
    1.054,
    0.950,
    1.103,
    0.965,
    1.019,
    1.048,
    1.069,
    1.029,
    1.125,
    1.238,
    1.314,
    1.374,
    1.410,
    1.306,
    1.258,
    1.082,
    0.966,
    0.892,
    0.888,
    0.896,
    0.930,
    1.005,
    1.161,
    1.163,
    1.232,
    1.144,
    1.075,
    0.988,
    1.014,
    0.918,
    0.878,
    0.968,
    1.025,
    1.161,
    1.305,
    1.360,
    1.312,
    1.360,
    1.188,
    1.043,
    0.810,
    0.754,
    0.727,
    0.753,
    0.779,
    0.888,
    1.031,
    1.093,
    0.929,
    0.828,
    0.766,
    0.779,
    0.743,
    0.766,
    0.692,
    0.744,
    0.937,
    1.032,
    1.161,
    1.238,
    1.287,
    1.102,
    1.012,
    0.882,
    0.757,
    0.695,
    0.695,
    0.690,
    0.734,
    0.784,
    0.997,
    0.950,
    0.840,
    0.821,
    0.759,
    0.753,
    0.749,
    0.663,
    0.622,
    0.661,
    0.719,
    0.751,
    0.968,
    1.051,
    1.062,
    0.991,
    0.987,
    0.832,
    0.723,
    0.650,
    0.648,
    0.669,
    0.648,
    0.688,
    0.877,
    0.861,
    0.813,
    0.880,
    0.858,
    0.858,
    0.749,
    0.786,
    0.797,
    0.729,
    0.784,
    0.832,
    0.953,
    1.083,
    1.083,
    0.976,
    0.987,
    0.805,
    0.718,
    0.664,
    0.614,
    0.659,
    0.641,
    0.720,
    0.837,
    0.904,
    1.028,
    0.998,
    1.006,
    0.925,
    0.899,
    0.844,
    0.840,
    0.823,
    0.813,
    0.931,
    0.992,
    1.053,
    0.982,
    0.955,
    0.807,
    0.757,
    0.675,
    0.567,
    0.571,
    0.589,
    0.568,
    0.641,
    0.765,
    0.909,
    0.884,
    0.843,
    0.963,
    0.837,
    0.821,
    0.795,
    0.797,
    0.790,
    0.829,
    0.978,
    1.008,
    1.115,
    1.096,
    0.938,
    0.820,
    0.655,
    0.563,
    0.592,
    0.554,
    0.559,
    0.618,
    0.637,
    0.839,
    0.952,
    0.889,
    0.808,
    0.789,
    0.751,
    0.699,
    0.722,
    0.737,
    0.779,
    0.798,
    0.989,
    1.172,
    1.201,
    1.100,
    1.052,
    0.851,
    0.683,
    0.608,
    0.600,
    0.582,
    0.613,
    0.689,
    0.764,
    0.939,
    1.031,
    0.890,
    0.902,
    0.845,
    0.792,
    0.776,
    0.804,
    0.783,
    0.852,
    0.985,
    1.161,
    1.330,
    1.375,
    1.330,
    1.176,
    1.084,
    0.909,
    0.834,
    0.839,
    0.831,
    0.821,
    0.877,
    0.918,
    1.165,
    1.201,
    1.044,
    0.985,
    1.031,
    0.994,
    0.904,
    0.882,
    0.828,
    0.890,
    1.006,
    1.141,
    1.305,
    1.340,
    1.340,
    1.338,
    1.193,
    1.016,
    0.997,
    0.888,
    0.930,
    0.922,
    0.936,
    1.020,
    1.266,
    1.307,
    1.088,
    1.000,
    1.017,
    0.920,
    0.788,
    0.836,
    0.671,
    0.778,
    0.857,
    1.037,
    1.198,
    1.239,
    1.264,
    1.185,
    1.067,
    0.947,
    0.816,
    0.799,
    0.812,
    0.842,
    0.877,
    1.031,
    1.098,
    1.235,
    1.164,
    1.181,
    1.160,
    1.065,
    1.013,
    0.972,
    0.864,
    0.854,
    0.979,
    1.010,
    1.115,
    1.206,
    1.247,
    1.241,
    1.038,
    0.885,
    0.847,
    0.811,
    0.745,
    0.782,
    0.806,
    0.824,
    0.962,
    1.085,
    1.133,
    1.161,
    1.048,
    1.039,
    0.972,
    0.912,
    0.905,
    0.861,
    1.010,
    1.099,
    1.311,
    1.273,
    1.163,
    1.233,
    1.121,
    1.087,
    0.908,
    0.852,
    0.882,
    0.903,
    0.926,
    1.000,
    1.125,
    1.234,
    1.268,
    1.296,
    1.318,
    1.290,
    1.223,
    1.262,
    1.186,
    1.182,
    1.180,
    1.348,
    1.505,
    1.576,
    1.584,
    1.416,
    1.310,
    1.053,
    1.008,
    1.008,
    1.024,
    1.053,
    1.061,
    1.153,
    1.289,
    1.301,
    1.301,
    1.260,
    1.146,
    1.181,
    1.198,
    1.126,
    1.042,
    1.058,
    1.111,
    1.345,
    1.574,
    1.611,
    1.642,
    1.491,
    1.245,
    1.086,
    0.934,
    0.956,
    0.918,
    0.982,
    0.987,
    1.053,
    1.261,
    1.342,
    1.238,
    1.077,
    1.065,
    0.908,
    0.751,
    0.770,
    0.798,
    0.839,
    0.833,
    0.976,
    1.264,
    1.311,
    1.357,
    1.268,
    1.207,
    1.002,
    0.850,
    0.850,
    0.830,
    0.883,
    0.894,
    1.019,
    1.184,
    1.199,
    1.099,
    0.992,
    0.893,
    0.797,
    0.838,
    0.769,
    0.701,
    0.752,
    0.790,
    1.004,
    1.135,
    1.290,
    1.232,
    1.069,
    1.119,
    0.872,
    0.810,
    0.762,
    0.821,
    0.833,
    0.844,
    0.930,
    1.146,
    1.077,
    0.983,
    0.922,
    0.882,
    0.726,
    0.623,
    0.641,
    0.638,
    0.702,
    0.750,
    0.820,
    1.050,
    1.177,
    1.201,
    1.202,
    1.108,
    0.964,
    0.831,
    0.756,
    0.671,
    0.704,
    0.722,
    0.793,
    1.015,
    1.073,
    1.053,
    1.084,
    1.053,
    0.996,
    1.021,
    0.977,
    0.998,
    0.982,
    1.042,
    1.115,
    1.324,
    1.379,
    1.385,
    1.334,
    1.167,
    1.054,
    0.942,
    0.870,
    0.876,
    0.903,
    1.001,
    1.079,
    1.135,
    1.240,
    1.374,
    1.436,
    1.438,
    1.310,
    1.328,
    1.323,
    1.233,
    1.188,
    1.227,
    1.327,
    1.407,
    1.525,
    1.477,
    1.540,
    1.371,
    1.244,
    1.117,
    1.157,
    1.164,
    1.197,
    1.205,
    1.320,
    1.433,
    1.610,
    1.551,
    1.529,
    1.422,
    1.298,
    1.198,
    1.044,
    1.007,
    0.918,
    1.071,
    1.212,
    1.425,
    1.519,
    1.591,
    1.470,
    1.376,
    1.248,
    1.159,
    1.112,
    1.068,
    1.140,
    1.147,
    1.232,
    1.349,
    1.474,
    1.319,
    1.325,
    1.195,
    1.098,
    0.950,
    0.909,
    0.884,
    0.834,
    0.865,
    1.017,
    1.213,
    1.330,
    1.349,
    1.334,
    1.123,
    1.000,
    0.974,
    0.906,
    0.896,
    0.931,
    1.042,
    1.094,
    1.261,
    1.310,
    1.314,
    1.250,
    1.182,
    1.107,
    1.006,
    0.907,
    0.830,
    0.906,
    0.943,
    1.159,
    1.426,
    1.532,
    1.461,
    1.495,
    1.252,
    1.070,
    1.021,
    1.035,
    1.096,
    1.012,
    1.043,
    1.127,
    1.242,
    1.376,
    1.433,
    1.408,
    1.346,
    1.274,
    1.208,
    1.056,
    0.992,
    1.022,
    1.083,
    1.176,
    1.356,
    1.467,
    1.485,
    1.364,
    1.372,
    1.151,
    1.110,
    1.014,
    1.078,
    1.044,
    1.091,
    1.094,
    1.150,
    1.331,
    1.396,
    1.460,
    1.266,
    1.254,
    1.100,
    0.955,
    0.937,
    0.957,
    0.950,
    0.974,
    1.237,
    1.301,
    1.289,
    1.344,
    1.166,
    1.061,
    0.955,
    0.996,
    0.819,
    0.876,
    0.924,
    0.915,
    1.076,
    1.191,
    1.404,
    1.322,
    1.234,
    1.184,
    1.195,
    1.088,
    1.078,
    0.925,
    0.852,
    1.016,
    1.116,
    1.252,
    1.274,
    1.279,
    1.185,
    1.063,
    0.935,
    0.934,
    0.826,
    0.881,
    0.867,
    0.971,
    1.029,
    1.141,
    1.357,
    1.413,
    1.373,
    1.225,
    1.101,
    1.105,
    1.037,
    1.006,
    0.925,
    1.166,
    1.287,
    1.280,
    1.303,
    1.297,
    1.304,
    1.191,
    1.075,
    1.069,
    1.042,
    1.079,
    1.109,
    1.125,
    1.236,
    1.328,
    1.394,
    1.369,
    1.264,
    1.178,
    1.111,
    1.042,
    0.974,
    0.948,
    1.028,
    1.216,
    1.308,
    1.481,
    1.520,
    1.492,
    1.278,
    1.111,
    1.012,
    0.942,
    0.929,
    0.933,
    0.999,
    1.074,
    1.148,
    1.267,
    1.218,
    1.148,
    1.089,
    1.009,
    0.925,
    0.782,
    0.754,
    0.756,
    0.812,
    0.971,
    1.235,
    1.339,
    1.401,
    1.292,
    1.143,
    0.996,
    0.896,
    0.927,
    0.907,
    0.900,
    0.932,
    1.102,
    1.175,
    1.271,
    1.152,
    0.995,
    1.006,
    0.908,
    0.884,
    0.897,
    0.801,
    0.843,
    0.894,
    1.103,
    1.402,
    1.534,
    1.537,
    1.531,
    1.392,
    1.167,
    1.039,
    0.977,
    1.011,
    1.017,
    1.075,
    1.140,
    1.384,
    1.407,
    1.229,
    1.217,
    1.065,
    0.919,
    0.793,
    0.754,
    0.761,
    0.835,
    0.955,
    1.184,
    1.375,
    1.534,
    1.449,
    1.517,
    1.399,
    1.102,
    1.033,
    1.029,
    0.988,
    1.003,
    1.023,
    1.125,
    1.367,
    1.386,
    1.209,
    1.111,
    1.040,
    1.063,
    0.939,
    0.899,
    0.859,
    0.851,
    0.990,
    1.188,
    1.372,
    1.530,
    1.538,
    1.566,
    1.466,
    1.259,
    1.007,
    1.078,
    1.033,
    1.068,
    1.054,
    1.158,
    1.235,
    1.349,
    1.281,
    1.277,
    1.255,
    1.075,
    1.051,
    0.867,
    0.875,
    0.826,
    0.842,
    0.972,
    1.189,
    1.278,
    1.347,
    1.394,
    1.385,
    1.199,
    1.081,
    1.025,
    1.024,
    1.012,
    1.123,
    1.087,
    1.167,
    1.318,
    1.312,
    1.363,
    1.389,
    1.221,
    1.230,
    1.202,
    1.201,
    1.235,
    1.267,
    1.302,
    1.384,
    1.489,
    1.458,
    1.249,
    1.189,
    1.109,
    0.947,
    0.967,
    0.999,
    0.980,
    1.023,
    1.085,
    1.153,
    1.218,
    1.275,
    1.335,
    1.361,
    1.247,
    1.178,
    1.095,
    1.095,
    1.099,
    1.105,
    1.250,
    1.338,
    1.369,
    1.518,
    1.437,
    1.285,
    1.138,
    1.017,
    0.976,
    0.943,
    0.980,
    1.037,
    1.070,
    1.194,
    1.321,
    1.205,
    1.201,
    1.193,
    1.094,
    0.984,
    0.926,
    0.970,
    0.921,
    1.046,
    1.236,
    1.493,
    1.558,
    1.571,
    1.598,
    1.441,
    1.244,
    1.109,
    1.113,
    1.127,
    1.198,
    1.263,
    1.284,
    1.442,
    1.510,
    1.413,
    1.313,
    1.312,
    1.121,
    1.062,
    0.933,
    0.989,
    1.078,
    1.035,
    1.151,
    1.404,
    1.550,
    1.583,
    1.632,
    1.427,
    1.258,
    1.166,
    1.169,
    1.116,
    1.215,
    1.248,
    1.322,
    1.442,
    1.507,
    1.381,
    1.336,
    1.301,
    1.297,
    1.157,
    1.153,
    1.209,
    1.175,
    1.266,
    1.463,
    1.624,
    1.758,
    1.805,
    1.858,
    1.666,
    1.485,
    1.368,
    1.267,
    1.303,
    1.290,
    1.339,
    1.416,
    1.672,
    1.627,
    1.514,
    1.420,
    1.441,
    1.428,
    1.339,
    1.216,
    1.245,
    1.315,
    1.631,
    1.763,
    2.080,
    1.988,
    1.956,
    1.797,
    1.630,
    1.458,
    1.373,
    1.342,
    1.329,
    1.301,
    1.335,
    1.430,
    1.731,
    1.714,
    1.679,
    1.552,
    1.733,
    1.550,
    1.440,
    1.324,
    1.280,
    1.338,
    1.344,
    1.604,
    1.722,
    1.729,
    1.735,
    1.736,
    1.623,
    1.466,
    1.345,
    1.365,
    1.323,
    1.368,
    1.427,
    1.418,
    1.475,
    1.568,
    1.675,
    1.671,
    1.485,
    1.524,
    1.310,
    1.300,
    1.372,
    1.255,
    1.174,
    1.399,
    1.648,
    1.575,
    1.603,
    1.654,
    1.477,
    1.428,
    1.266,
    1.262,
    1.274,
    1.260,
    1.258,
    1.292,
    1.416,
    1.576,
    1.519,
    1.466,
    1.525,
    1.424,
    1.244,
    1.164,
    1.240,
    1.105,
    1.064,
    1.212,
    1.436,
    1.475,
    1.567,
    1.467,
    1.385,
    1.196,
    1.096,
    1.128,
    1.078,
    1.132,
    1.149,
    1.182,
    1.258,
    1.288,
    1.345,
    1.259,
    1.233,
    1.151,
    1.070,
    1.057,
    1.014,
    1.005,
    0.974,
    1.077,
    1.363,
    1.540,
    1.326,
    1.364,
    1.273,
    1.206,
    1.051,
    1.082,
    1.087,
    1.081,
    1.191,
    1.248,
    1.476,
    1.594,
    1.389,
    1.189,
    1.191,
    1.060,
    0.969,
    0.880,
    0.812,
    0.837,
    0.922,
    1.008,
    1.416,
    1.422,
    1.469,
    1.465,
    1.419,
    1.148,
    1.179,
    1.062,
    1.095,
    1.053,
    1.107,
    1.214,
    1.331,
    1.398,
    1.489,
    1.339,
    1.249,
    1.177,
    1.116,
    1.005,
    1.064,
    0.990,
    1.060,
    1.226,
    1.520,
    1.679,
    1.710,
    1.645,
    1.494,
    1.335,
    1.237,
    1.187,
    1.152,
    1.234,
    1.294,
    1.267,
    1.450,
    1.573,
    1.544,
    1.326,
    1.194,
    1.119,
    1.113,
    1.035,
    0.998,
    0.942,
    1.073,
    1.244,
    1.541,
    1.777,
    1.734,
    1.656,
    1.569,
    1.461,
    1.257,
    1.255,
    1.195,
    1.224,
    1.244,
    1.309,
    1.461,
    1.638,
    1.594,
    1.531,
    1.510,
    1.397,
    1.319,
    1.225,
    1.235,
    1.104,
    1.129,
    1.399,
    1.614,
    1.586,
    1.577,
    1.560,
    1.450,
    1.376,
    1.207,
    1.124,
    1.089,
    1.073,
    1.008,
    1.113,
    1.186,
    1.285,
    1.397,
    1.263,
    1.194,
    1.004,
    1.078,
    1.048,
    1.009,
    0.962,
    0.983,
    1.188,
    1.388,
    1.471,
    1.500,
    1.452,
    1.207,
    1.097,
    1.032,
    0.958,
    0.914,
    0.964,
    1.001,
    1.123,
    1.308,
    1.439,
    1.404,
    1.439,
    1.379,
    1.412,
    1.421,
    1.401,
    1.407,
    1.254,
    1.334,
    1.577,
    1.851,
    1.914,
    1.985,
    1.910,
    1.683,
    1.505,
    1.317,
    1.359,
    1.287,
    1.307,
    1.363,
    1.392,
    1.458,
    1.632,
    1.611,
    1.490,
    1.347,
    1.279,
    1.222,
    1.223,
    1.211,
    1.234,
    1.192,
    1.392,
    1.631,
    1.748,
    1.857,
    1.871,
    1.737,
    1.400,
    1.290,
    1.279,
    1.335,
    1.277,
    1.389,
    1.356,
    1.542,
    1.627,
    1.516,
    1.473,
    1.376,
    1.162,
    1.044,
    0.983,
    0.981,
    0.893,
    0.954,
    1.209,
    1.633,
    1.786,
    1.821,
    1.744,
    1.484,
    1.279,
    1.151,
    1.148,
    1.085,
    1.160,
    1.204,
    1.267,
    1.485,
    1.536,
    1.495,
    1.353,
    1.316,
    1.088,
    0.998,
    0.997,
    0.885,
    0.911,
    0.875,
    1.049,
    1.441,
    1.602,
    1.632,
    1.560,
    1.391,
    1.298,
    1.169,
    1.159,
    1.142,
    1.147,
    1.110,
    1.144,
    1.331,
    1.436,
    1.286,
    1.200,
    1.179,
    1.080,
    1.010,
    1.033,
    0.998,
    0.956,
    1.008,
    1.375,
    1.510,
    1.519,
    1.562,
    1.491,
    1.463,
    1.303,
    1.230,
    1.233,
    1.221,
    1.196,
    1.189,
    1.282,
    1.435,
    1.605,
    1.556,
    1.582,
    1.388,
    1.506,
    1.360,
    1.353,
    1.309,
    1.231,
    1.262,
    1.293,
    1.510,
    1.593,
    1.595,
    1.639,
    1.619,
    1.451,
    1.360,
    1.363,
    1.311,
    1.323,
    1.336,
    1.333,
    1.360,
    1.531,
    1.688,
    1.554,
    1.470,
    1.448,
    1.281,
    1.208,
    1.141,
    1.100,
    1.129,
    1.268,
    1.381,
    1.424,
    1.476,
    1.567,
    1.515,
    1.372,
    1.244,
    1.187,
    1.138,
    1.179,
    1.232,
    1.227,
    1.326,
    1.487,
    1.594,
    1.652,
    1.675,
    1.615,
    1.567,
    1.466,
    1.248,
    1.236,
    1.163,
    1.274,
    1.374,
    1.501,
    1.496,
    1.576,
    1.582,
    1.421,
    1.172,
    1.145,
    1.150,
    1.130,
    1.151,
    1.145,
    1.192,
    1.372,
    1.459,
    1.646,
    1.554,
    1.592,
    1.563,
    1.344,
    1.353,
    1.208,
    1.179,
    1.218,
    1.552,
    1.599,
    1.628,
    1.602,
    1.506,
    1.324,
    1.221,
    1.191,
    1.184,
    1.157,
    1.181,
    1.218,
    1.261,
    1.280,
    1.424,
    1.421,
    1.570,
    1.403,
    1.413,
    1.264,
    1.223,
    1.304,
    1.291,
    1.426,
    1.669,
    1.849,
    1.812,
    1.923,
    1.749,
    1.512,
    1.437,
    1.313,
    1.342,
    1.289,
    1.303,
    1.390,
    1.469,
    1.560,
    1.584,
    1.557,
    1.530,
    1.444,
    1.396,
    1.357,
    1.358,
    1.349,
    1.287,
    1.531,
    1.728,
    1.819,
    1.847,
    1.680,
    1.693,
    1.484,
    1.425,
    1.286,
    1.303,
    1.294,
    1.309,
    1.315,
    1.416,
    1.502,
    1.609,
    1.616,
    1.576,
    1.397,
    1.303,
    1.340,
    1.213,
    1.182,
    1.258,
    1.350,
    1.731,
    1.726,
    1.817,
    1.735,
    1.699,
    1.481,
    1.407,
    1.375,
    1.349,
    1.363,
    1.349,
    1.393,
    1.420,
    1.483,
    1.597,
    1.557,
    1.484,
    1.507,
    1.432,
    1.386,
    1.336,
    1.272,
    1.248,
    1.284,
    1.469,
    1.656,
    1.680,
    1.774,
    1.691,
    1.383,
    1.364,
    1.319,
    1.322,
    1.312,
    1.339,
    1.371,
    1.420,
    1.488,
    1.532,
    1.580,
    1.621,
    1.565,
    1.506,
    1.438,
    1.423,
    1.443,
    1.385,
    1.695,
    1.797,
    1.829,
    1.850,
    1.800,
    1.598,
    1.497,
    1.371,
    1.373,
    1.337,
    1.357,
    1.315,
    1.438,
    1.422,
    1.495,
    1.700,
    1.739,
    1.713,
    1.686,
    1.772,
    1.667,
    1.704,
    1.682,
    1.634,
    1.756,
    1.911,
    1.847,
    1.846,
    1.791,
    1.688,
    1.598,
  ],
};

export default newMexico;
