import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import chargerPaymentOptions from '../../../data/chargerPaymentOptions';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

const ChargerPaymentOptionsRadio = () => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <div>
      <div className="mb-2">
        <Link to="/ev-charging-programs" className="charging-program-link">
          Learn about Home Charging Programs &gt;
        </Link>
      </div>
      <div className="mt-4">
        <div className="mb-2">
          <label>Payment Options</label>
        </div>
        <div className="row no-gutters">
          {chargerPaymentOptions.map(paymentOption => {
            const isSelected = userPrefs.get('chargerPaymentType') === paymentOption.id;
            return (
              <div className="col px-1" key={paymentOption.id}>
                <button
                  className={`btn btn-default btn-sm btn-block ${isSelected ? 'active' : ''}`}
                  type="button"
                  onClick={() => userPrefs.set({ chargerPaymentType: paymentOption.id })}
                  aria-pressed={isSelected}
                >
                  {paymentOption.title}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChargerPaymentOptionsRadio;
