export const AFTER_9PM_BEFORE_9AM = "after-9pm-before-9am";
export const AFTER_6PM_BEFORE_9AM = "after-6pm-before-9am";
export const AFTER_2PM_BEFORE_6PM = "after-2pm-before-6pm";
export const AFTER_MIDNIGHT_BEFORE_8AM = "after-midnight-before-8am";
export const AFTER_8AM_BEFORE_MIDNIGHT = "after-8am-before-midnight"
export const AFTER_12PM_BEFORE_8PM = "after-12p-before-8p"
export const AFTER_10PM_BEFORE_11AM = "after-10pm-before-11am";
export const AFTER_11AM_BEFORE_10PM = "after-11am-before-10pm";
export const ALL_HOURS = "all-hours";
export const AFTER_9PM_BEFORE_6AM = "after-9pm-before-6am";
export const AFTER_1230AM_BEFORE_930AM = "after-1230am-before-930am";
export const AFTER_6AM_BEFORE_3PM = "after-6am-before-3pm";
