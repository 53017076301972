export const NM_RESIDENTIAL_ID = 'nm-r';
export const CO_RESIDENTIAL_ID = 'co-r';
export const CO_RESIDENTIAL_TOU_ID = 'co-re-tou';
export const CO_EV_ACCELERATE = 'co-ev-accerlate';
export const MN_A01_ID = 'mn-a01';
export const MN_A08_ID = 'mn-a08';
export const MN_EV_ACCELERATE = 'mn-ev-accelerate';
export const MN_A82_ID = 'mn-a82';
export const WI_E10_ID = 'wi-e10';
export const WI_E11_ID = "wi-e11";
export const WI_EV_ACCELERATE = 'wi_ev_accelerate';
