import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Range from "../../shared/InputElements/Range";
import { FormatAsPercent } from "../../../utils/Helpers/Format";
import { useIntl } from 'react-intl';

const descriptionFn = val => FormatAsPercent(val);

const SlideInterestRateAsBasisPoints = ({
  id = "interest-rate-slider",
  description = descriptionFn,
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({ id: "ev.interestRate", defaultMessage: "Interest Rate"})

  return userPrefs.get("purchaseMethod") === "cash" ? null : (
    <Range
      id={id}
      value={userPrefs.get("interestRateAsBasisPoints") / 100}
      label={label}
      rangeMin={0.05}
      rangeMax={10}
      rangeStep={0.05}
      description={description}
      handler={e =>
        userPrefs.set({ interestRateAsBasisPoints: e.target.value * 100 })
      }
      {...rest}
    />
  );
};

export default SlideInterestRateAsBasisPoints;

SlideInterestRateAsBasisPoints.propTypes = {
  id: PropTypes.string,
  description: PropTypes.func,
  label: PropTypes.string
};
