import React, { useContext } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import Range from '../../../../components/shared/InputElements/Range';
import { FormattedMessage, useIntl } from 'react-intl';

const SlideCurrentMonthlyBill = () => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  return (
    <Range
      id="home-charging-advisor-current-monthly-bill"
      value={userPrefs.get("currentMonthlyBill")}
      handler={(e) => userPrefs.set({currentMonthlyBill: e.target.value})}
      label={<FormattedMessage
        id="hcaCurrentMonthlyBill"
        defaultMessage="Current Monthly Bill"
        description="Current Monthly Bill"
      />}
      rangeMin={10}
      rangeMax={250}
      rangeStep={10}
      description={val => `$${val} / ${intl.formatMessage({id:"month", defaultMessage:"month"})}`}
    />
  );
};

export default SlideCurrentMonthlyBill;
