import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CarGallery from "../../../components/EVJumbotron/CarGallery/CarGallery";
import CarDetails from "../../../components/EVJumbotron/CarDetails/CarDetails";
import CarDetailsCalcs from "../../../components/EVJumbotron/CarDetailsCalcs/CarDetailsCalcs";

import VehicleImage from "../../../components/VehicleImage/VehicleImage";
import PricePanels from "../../../components/EVJumbotron/PricePanels/PricePanels";
import { FormatCarName } from "../../../utils/Helpers/Format";
import ShowHideButton from "../../../components/ShowHideButton/ShowHideButton";
import { FormattedMessage, useIntl } from "react-intl";
import UsedVehiclePanels from "../../../components/EVJumbotron/UsedVehiclePanels/UsedVehiclePanels";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

const EVJumbotron = ({ car, paymentDetails, cars, setUsedEv }) => {
  const intl = useIntl();
  const userPrefs = React.useContext(UserPrefsContext);

  if (!car) return null;
  //Error Checking code to ensure that pictures have both a thumbnail and full version sutible for display
  // It will remove any null value
  let imagesSrc =
    car && car["images"]
      ? car.images.map((n) =>
          n.url_thumbnail == null ? [] : n.url_full == null ? [] : [n]
        )
      : [];

  //Flatten evImgs into a single array
  const images = [].concat.apply([], imagesSrc);
  let carImages = images.map((image, i) => {
    return {
      original: image.url_full,
      thumbnail: image.url_thumbnail,
      originalAlt: image["legal_info"] || `Image ${i + 1}`,
      thumbnailAlt: image.title,
    };
  });
  carImages.shift();

  const handleClick = (make, model) => {
    userPrefs.set({
      vehicleMakeFilter: make,
      vehicleModelFilter: model,
      inventoryDealers: {},
    });
  };

  return (
    <>
      <section className="container-fluid" id="ev-jumbotron-title">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>
                {setUsedEv ? (
                  <FormattedMessage
                    id="ev.usedCarTitle"
                    defaultMessage="Used {car}"
                    description="Used Car Title"
                    values={{
                      car: FormatCarName(car),
                    }}
                  />
                ) : (
                  FormatCarName(car)
                )}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-8">
              <div className="text-center offset-lg-1 col-lg-10 col-md-12">
                <VehicleImage image={images[0]} size="full" alt={FormatCarName(car)} />
              </div>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-4">
              <PricePanels car={car} paymentDetails={paymentDetails} />
              <UsedVehiclePanels cars={cars} car={car} setUsedEv={setUsedEv} />
              <br />
              {setUsedEv ? null : (
                <>
                  <div className="row m-0">
                    <div className="col-6 p-0">
                      <Link
                        to="/inventory"
                        onClick={() => handleClick(car.make, car.model)}
                      >
                        <div
                          style={{
                            width: "175px",
                            whiteSpace: "nowrap",
                            padding: "10px 10px",
                            textAlign: "center",
                          }}
                          className="btn-ae"
                        >
                          <FormattedMessage
                            id="ev.jumbotron.browseInventory"
                            defaultMessage="Browse Inventory"
                            description="Browse Inventory"
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="col-6 p-0">
                      <Link to="/ev-charging-programs">
                        <div
                          style={{
                            marginLeft: "10px",
                            whiteSpace: "nowrap",
                            padding: "10px 10px",
                            textAlign: "center",
                          }}
                          className="btn-ae"
                        >
                          <FormattedMessage
                            id="viewChargingPrograms"
                            defaultMessage="View Charging Programs"
                            description="View Charging Programs"
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid" id="ev-jumbotron-details">
        <div className="container padded">
          <div className="row">
            <div className="col-md-7">
              <CarDetails car={car} setUsedEv={setUsedEv} />
              <br />
              <div className="text-center">
                <ShowHideButton
                  buttonText={`${intl.formatMessage({
                    id: "detailedCalculations",
                    defaultMessage: "DETAILED CALCULATIONS",
                  })} ${String.fromCodePoint(8897)}`}
                  displayAlertOnlyOnMobile
                >
                  <div className="input-well">
                    <CarDetailsCalcs car={car} />
                  </div>
                </ShowHideButton>
              </div>
            </div>
            <div className="col-md-5">
              <CarGallery carImages={carImages} />
            </div>
          </div>
          <br />
        </div>
      </section>
    </>
  );
};

export default EVJumbotron;

EVJumbotron.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object,
};
