import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
// import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"
import "./InventoryEVCard.scss";

import { FormatAsDollars } from "../../utils/Helpers/Format";

import IconBEV from "../../client_customizations/assets/images/icons/icon-electric.png";
import IconPHEV from "../../client_customizations/assets/images/icons/icon-hybrid.png";
import mapIcon from "../../client_customizations/assets/images/icons/orangeMapIcon.svg";
import isBEV from "../../functions/vehicle/isBEV";
import isPHEV from "../../functions/vehicle/isPHEV";
import ReactGA4 from 'react-ga4';

const InventoryEVCard = ({ ev }) => {
  const [renderNoImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageAlt, setImageAlt] = useState("");

  useEffect(() => {
    async function findValidImage() {
      const checkImage = (imgUrl,alt) => {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.src = imgUrl;
          img.alt = alt;
          img.addEventListener("load", () => resolve(img));
        });
      };


      var imgData = await checkImage(
        ev.vehicle_dealership_images && ev.vehicle_dealership_images.length > 0
          ? ev.vehicle_dealership_images[0]
          : ev.images[0].url_full,
          ev.vehicle_dealership_images && ev.vehicle_dealership_images.length > 0
          ? "dealers"
          : ev.images[0].legal_info
      );
      if (imgData.width > 0) {
        setImageUrl(imgData.src);
        setImageAlt(imgData.alt);
        return;
      }
    }
    findValidImage();

    return () => {
      setImageUrl("");
    };
  }, [ev, setImageUrl]);

  if (!ev || imageUrl === "") return null;

  const handleClickWebsite = () => {
       ReactGA4.event({
       category: "Inventory",
       action: "Clicked on Inventory",
       label: `${ev.dealer.name} - ${ev.make} - ${ev.model}`,
       });
  };

  const renderFuelTypeBadge = isBEV(ev) ? (
    <span className="badge-fuel-type">
      <img alt="All-Electric" src={IconBEV} height="25" />
    </span>
  ) : isPHEV(ev) ? (
    <span className="badge-fuel-type">
      <img alt="Hybrid" src={IconPHEV} height="25" />
    </span>
  ) : null;

  const vehicleImage = <img src={imageUrl} alt={imageAlt} />;

  const disclaimer = (
    <p>
      <FormattedMessage
        id="inventory-card.disclaimer-paragraph"
        defaultMessage="Disclaimer: This image is a stock photo. Please View Vehicle Details for accurate images."
        description="Disclaimer: This image is a stock photo. Please View Vehicle Details for accurate images."
      />
    </p>
  );

  return (
    <>
      {/* Desktop View */}
      <div className="d-none d-md-flex d-lg-flex inventory-card flex-column">
        <div className=" d-flex row-item">
          <div className="col-4 p-0 vehicle-img-wrapper">{vehicleImage}</div>
          <div className="col-8 d-flex flex-column justify-content-around inventory-card-details">
            <div className="row">
              <div className="col-10">
                <span className="model">{`${ev.make}`}</span>{" "}
                <span className="make">{ev.model}</span>
              </div>
              <div
                className="col-2 d-flex align-items-center justify-content-center"
                style={{}}
              >
                {renderFuelTypeBadge}
              </div>
            </div>
            <div className="row">
              <div className="col-3 pr-2">
                <div className="row detail-title">
                  <FormattedMessage
                    id="inventory-card.year"
                    defaultMessage="Year"
                    description="Year"
                  />
                </div>
                <div className="row detail">{ev.model_year}</div>
              </div>
              <div className="col-3 px-2">
                <div className="row detail-title">
                  <FormattedMessage
                    id="inventory-card.eletric-range"
                    defaultMessage="Electric Range"
                    description="Electric Range"
                  />
                </div>
                <div className="row detail">{`${ev.electric_range} miles`}</div>
              </div>
              <div className="col-3 px-2">
                <div className="row detail-title">
                  <FormattedMessage
                    id="inventory-card.total-range"
                    defaultMessage="Total Range"
                    description="Total Range"
                  />
                </div>
                <div className="row detail">{`${ev.total_range} miles`}</div>
              </div>
              <div className="col-3 pl-2">
                <div className="row detail-title">
                  <FormattedMessage
                    id="inventory-card.price"
                    defaultMessage="Price"
                    description="Price"
                  />
                </div>
                <div className="row detail">
                  {!ev.vehicle_dealership_price ||
                  Number(ev.vehicle_dealership_price) === 0 ? (
                    <FormattedMessage
                      id="inventory-card.not-available"
                      defaultMessage="Not Available"
                      description="Not Available"
                    />
                  ) : (
                    FormatAsDollars(ev.vehicle_dealership_price)
                  )}
                </div>
              </div>
            </div>
            <div className="row inventory-card-bottom">
              <div className="col-5 my-auto">
                <img src={mapIcon} alt="pin" />
                <a
                  href={ev.dealer.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="dealer-name">{ev.dealer.name}</span>
                </a>
              </div>
              <div className="col-3 px-2">
                  {ev.vehicle_dealership_url ? (
                     <div className="contact-dealer">
                    <a
                      href={ev.vehicle_dealership_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleClickWebsite}
                    >
                      <span>
                        <FormattedMessage
                          id="inventory-card.contact-dealer"
                          defaultMessage="Contact Dealer"
                          description="Contact Dealer"
                        />
                      </span>
                    </a>
                    </div>
                  ) : null}
              </div>
              <div className="col-4 px-2">
                <div className="vehicle-details">
                  {ev.vehicle_dealership_url ? (
                    <a
                      href={ev.vehicle_dealership_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleClickWebsite}
                    >
                      <span>
                        <FormattedMessage
                          id="inventory-card.vehicle-details"
                          defaultMessage="View Vehicle Details"
                          description="View Vehicle Details"
                        />
                      </span>
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderNoImage ? (
          <div className="row stock-image-disclaimer">{disclaimer}</div>
        ) : null}
      </div>

      {/* Mobile View */}
      <div className="d-xs-block d-sm-block d-md-none d-lg-none inventory-card row-item">
        <div className="row p-0 ">
          <div className="col-12 text-center">{vehicleImage}</div>
        </div>
        <div className="col-12 d-flex flex-column justify-content-around inventory-card-details">
          <div className="row pt-2">
            <div className="col-10">
              <span className="model">{`${ev.make}`}</span>{" "}
              <span className="make">{ev.model}</span>
            </div>
            <div className="col-2">{renderFuelTypeBadge}</div>
          </div>
          <div className="row justify-content-around">
            <div className="">
              <div className="row detail-title">
                <FormattedMessage
                  id="inventory-card.year"
                  defaultMessage="Year"
                  description="Year"
                />
              </div>
              <div className="row detail">{ev.model_year}</div>
            </div>
            <div className="">
              <div className="row detail-title">
                <FormattedMessage
                  id="inventory-card.eletric-range"
                  defaultMessage="Electric Range"
                  description="Electric Range"
                />
              </div>
              <div className="row detail">{`${ev.electric_range} miles`}</div>
            </div>
            <div className="">
              <div className="row detail-title">
                <FormattedMessage
                  id="inventory-card.total-range"
                  defaultMessage="Total Range"
                  description="Total Range"
                />
              </div>
              <div className="row detail">{`${ev.total_range} miles`}</div>
            </div>
            <div className="">
              <div className="row detail-title">
                <FormattedMessage
                  id="inventory-card.price"
                  defaultMessage="Price"
                  description="Price"
                />
              </div>
              <div className="row detail">
                {!ev.vehicle_dealership_price ||
                Number(ev.vehicle_dealership_price) === 0 ? (
                  <FormattedMessage
                    id="inventory-card.not-available"
                    defaultMessage="Not Available"
                    description="Not Available"
                  />
                ) : (
                  FormatAsDollars(ev.vehicle_dealership_price)
                )}
              </div>
            </div>
          </div>

          <div className="row inventory-card-bottom pt-2 pb-2">
            <div className="col-5 my-auto">
              <img src={mapIcon} alt="pin" />
              <a
                href={ev.dealer.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="dealer-name">{ev.dealer.name}</span>
              </a>
            </div>
            <div className="col-3 px-2">
                {ev.vehicle_dealership_url ? (
                     <div className="contact-dealer">
                  <a
                    href={ev.vehicle_dealership_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClickWebsite}
                  >
                    <span>
                      <FormattedMessage
                        id="inventory-card.contact-dealer"
                        defaultMessage="Contact Dealer"
                        description="Contact Dealer"
                      />
                    </span>
                  </a>``
                  </div>
                ) : null}
            </div>
            <div className="col-4 px-2">
              <div className="vehicle-details">
                {ev.vehicle_dealership_url ? (
                  <a
                    href={ev.vehicle_dealership_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClickWebsite}
                  >
                    <span>
                      <FormattedMessage
                        id="inventory-card.vehicle-details"
                        defaultMessage="View Vehicle Details"
                        description="View Vehicle Details"
                      />
                    </span>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {renderNoImage ? (
          <div className="row stock-image-disclaimer m-0">{disclaimer}</div>
        ) : null}
      </div>
    </>
  );
};

export default InventoryEVCard;

InventoryEVCard.propTypes = {
  ev: PropTypes.object,
};
