import { UNFINISHED_ROOM_TYPE, FINISHED_ROOM_TYPE } from '../constants/';

const electricPanelLocationRoomTypes = [
  {
    id: UNFINISHED_ROOM_TYPE,
    value: 'Unfinished',
    title: 'Unfinished',
    upfrontCost: 0
  },
  {
    id: FINISHED_ROOM_TYPE,
    value: 'Finished',
    title: 'Finished',
    upfrontCost: 15,
  },
];

export default electricPanelLocationRoomTypes;
