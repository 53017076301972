import React from "react";
import { FormattedMessage } from "react-intl";
import {
     COLORADO,
     HOME_WIRING_REBATE_COLORADO,
     HOME_WIRING_REBATE_NEW_MEXICO,
     MINNESOTA,
     WISCONSIN,
     NEW_MEXICO,
} from "../../../../constants";
import { byApplicable } from "./byApplicable";
import { RenderByState } from "../../../../components/RenderByState";

export const homeWiringRebateFaqs = (faqKey, region) => {
     const pdfLinks = {
          [COLORADO]:
               "https://www.xcelenergy.com/staticfiles/xe-responsive/Programs%20and%20Rebates/Residential/22-03-628%20CO%20EV%20Electrician%20Info%20Sheet%20P1.pdf",
          [WISCONSIN]: "https://www.xcelenergy.com/staticfiles/xe/PDF/WI-EV-Electricians-Info-Sheet.pdf",
          [MINNESOTA]:
               "https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/MN-EV-Electricians-Information-Sheet.pdf?_ga=2.187057198.74467544.1678726736-951842945.1678201088",
     };

     return [
          {
               headLine: (
                    <RenderByState
                         stateName={faqKey}
                         options={{
                              default: (
                                   <span className="headline">
                                        <FormattedMessage
                                             id="faqHWRQualifiyRebate"
                                             defaultMessage="How do I know if I’m qualified for the expanded $1,300 rebate?"
                                             description="How do I know if I’m qualified for the expanded $1,300 rebate?"
                                        />
                                   </span>
                              ),
                              [HOME_WIRING_REBATE_NEW_MEXICO]: (
                                   <span className="headline">
                                        <FormattedMessage
                                             id={`faqHWRQualifiyRebate-${HOME_WIRING_REBATE_NEW_MEXICO}`}
                                             defaultMessage="How do I know if I’m qualified for the expanded $2,500 rebate?"
                                             description="How do I know if I’m qualified for the expanded $2,500 rebate?"
                                        />
                                   </span>
                              ),
                         }}
                    />
               ),
               body: (
                    <RenderByState
                         stateName={faqKey}
                         options={{
                              default: (
                                   <div>
                                        <p>
                                             <FormattedMessage
                                                  id="faqHWRQualifiyRebateAnswer"
                                                  defaultMessage="First, you must complete and submit the Home Wiring Rebate application and check the box for “Income-Qualified” if you think you might meet the requirements. We will then verify your income eligibility. Our partner, GRID Alternatives Colorado, will contact you regarding the information they need to complete this process. Pre-qualification may take up to 2 weeks from the time all the information necessary to process your application is received."
                                                  description="HWR Qualify Rebate Answer"
                                             />
                                        </p>
                                        <p>
                                             <FormattedMessage
                                                  id="faqHWRQualifiyRebateAnswerTwo"
                                                  defaultMessage="Guidance about income qualification can be found below. For more information about income qualification contact our partner, GRID Alternatives Colorado, at 866-434-1690 or information@coloradoevs.org."
                                                  description="HWR Qualify Rebate Answer Two"
                                                  values={{
                                                       phone: <a href="tel:8664341690">866-434-1690</a>,
                                                       link: (
                                                            <a href="mailto:information@coloradoevs.org">
                                                                 information@coloradoevs.org
                                                            </a>
                                                       ),
                                                  }}
                                             />
                                        </p>
                                        <p>
                                             <FormattedMessage
                                                  id="faqHWRQualifiyRebateAnswerFour"
                                                  defaultMessage="To meet the “Income-Qualified” requirement, you must have a household income below"
                                                  description="To meet the “Income-Qualified” requirement, you must have a household income below"
                                             />
                                        </p>
                                        <ul>
                                             <li>
                                                  <FormattedMessage
                                                       id="coloradoSixtyMedianIncome"
                                                       defaultMessage="60% of the state of Colorado’s median income"
                                                       description="60% of the state of Colorado’s median income"
                                                  />
                                                  <div>
                                                       <strong>OR</strong>
                                                  </div>
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="coloradoTwoHundredFederalPoverty"
                                                       defaultMessage="200% of the relevant federal poverty level"
                                                       description="200% of the relevant federal poverty level"
                                                  />
                                                  <div>
                                                       <strong>OR</strong>
                                                  </div>
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="coloradoMedianIncomeArea"
                                                       defaultMessage="80% of area median income (varies by county; see examples, below):"
                                                       description="80% of area median income (varies by county; see examples, below):"
                                                  />
                                                  <table className="expanded-rebate-table">
                                                       <tr>
                                                            <th>
                                                                 <FormattedMessage
                                                                      id="coloradoCountyAMI"
                                                                      defaultMessage="80% of County AMI"
                                                                      description="80% of County AMI"
                                                                 />
                                                            </th>
                                                            <th>
                                                                 <FormattedMessage
                                                                      id="coloradoIndividual"
                                                                      defaultMessage="Individual"
                                                                      description="Individual"
                                                                 />
                                                            </th>
                                                            <th>
                                                                 <FormattedMessage
                                                                      id="coloradoFamilyFour"
                                                                      defaultMessage="Family of 4"
                                                                      description="Family of 4"
                                                                 />
                                                            </th>
                                                       </tr>
                                                       <tr>
                                                            <td>
                                                                 <b>
                                                                      Denver, Jefferson, Arapahoe, Adams, and Douglas
                                                                      County
                                                                 </b>
                                                            </td>
                                                            <td>$73,040</td>
                                                            <td>$104,320</td>
                                                       </tr>
                                                       <tr>
                                                            <td>
                                                                 <b>Boulder County</b>
                                                            </td>
                                                            <td>$81,760</td>
                                                            <td>$116,800</td>
                                                       </tr>
                                                       <tr>
                                                            <td>
                                                                 <b>Mesa County</b>
                                                            </td>
                                                            <td>$52,800</td>
                                                            <td>$75,360</td>
                                                       </tr>
                                                       <tr>
                                                            <td>
                                                                 <b>Summit County</b>
                                                            </td>
                                                            <td>$68,240</td>
                                                            <td>$97,440</td>
                                                       </tr>
                                                  </table>
                                             </li>
                                        </ul>
                                        <p>
                                             <FormattedMessage
                                                  id="faqHWRQualifiyRebateAnswerThree"
                                                  defaultMessage="{bold}, be currently enrolled in any {boldOne} of the following programs:"
                                                  description="{bold}, be currently enrolled in any {boldOne} of the following programs:"
                                                  values={{
                                                       bold: (
                                                            <b>
                                                                 <FormattedMessage
                                                                      id="or"
                                                                      defaultMessage="OR"
                                                                      description="OR"
                                                                 />
                                                            </b>
                                                       ),
                                                       boldOne: (
                                                            <b>
                                                                 <FormattedMessage
                                                                      id="one"
                                                                      defaultMessage="one"
                                                                      description="one"
                                                                 />
                                                            </b>
                                                       ),
                                                  }}
                                             />
                                        </p>
                                        <ul>
                                             <li>
                                                  <FormattedMessage
                                                       id="lowIncomeAssistProgram"
                                                       defaultMessage="State of Colorado Low-Income-Energy Assistance Program (LEAP)"
                                                       description="State of Colorado Low-Income-Energy Assistance Program (LEAP)"
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="coloradoAffordableResidentialEnergyProgram"
                                                       defaultMessage="Energy Outreach Colorado’s Colorado Affordable Residential Energy Program (CARE)"
                                                       description="Energy Outreach Colorado’s Colorado Affordable Residential Energy Program (CARE)"
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="coloradoWeatherAssistanceProgram"
                                                       defaultMessage="Colorado’s Weather Assistance Program (WAP)"
                                                       description="Colorado’s Weather Assistance Program (WAP)"
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="coloradoDemandSideManagementProgram"
                                                       defaultMessage="Xcel Energy income-qualified demand side management program"
                                                       description="Xcel Energy income-qualified demand side management program"
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="coloradoCommunitySolarGardensProgram"
                                                       defaultMessage="Xcel Energy’s income-qualified Community Solar Gardens program"
                                                       description="Xcel Energy’s income-qualified Community Solar Gardens program"
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="coloradoSNAPProgram"
                                                       defaultMessage="Supplemental Nutrition Assistance Program (SNAP)"
                                                       description="Supplemental Nutrition Assistance Program (SNAP)"
                                                  />
                                             </li>
                                             <li>
                                                  <FormattedMessage
                                                       id="coloradoTANFProgram"
                                                       defaultMessage="Temporary Assistance for Needy Families program (TANF)"
                                                       description="Temporary Assistance for Needy Families program (TANF)"
                                                  />
                                             </li>
                                        </ul>
                                   </div>
                              ),
                              [HOME_WIRING_REBATE_NEW_MEXICO]: (
                                   <p>
                                        <FormattedMessage
                                             id={`faqHWRQualifiyRebateAnswer-${HOME_WIRING_REBATE_NEW_MEXICO}`}
                                             defaultMessage="The expanded $2,500 rebate is available to our Income-Qualified customers whose annual household adjusted gross income is equal to or less than 200% of the federal poverty level, as defined in the Income Tax Act and as published annually by the United States Department of Health and Human Services.  To apply for the expanded rebate, you first must complete and submit the Home Wiring Rebate application and check the box for “Income-Qualified” if you think you might meet the requirements. We will then verify your income eligibility. Our income verification partner Frontier Energy will contact you regarding the information they need to complete this process. Pre-qualification may take up to 2 weeks from the time all the information necessary to process your application is received."
                                             description="Expanded rebate qualification answer"
                                        />
                                   </p>
                              ),
                         }}
                    />
               ),
               applicableTo: [HOME_WIRING_REBATE_COLORADO, HOME_WIRING_REBATE_NEW_MEXICO],
          },
          {
               headLine: (
                    <span className="headline">
                         <FormattedMessage
                              id="faqHWRExpensesQualify"
                              defaultMessage="What expenses and Level 2 chargers qualify for the EV Charger and Wiring Rebate?"
                              description="What expenses and Level 2 chargers qualify for the EV Charger and Wiring Rebate?"
                         />
                    </span>
               ),
               body: (
                    <div>
                         <p>
                              <RenderByState
                                   stateName={faqKey}
                                   options={{
                                        default: (
                                             <p>
                                                  <FormattedMessage
                                                       id="faqHWRExpensesQualifyAnswer"
                                                       defaultMessage="Qualifying expenses include permitting, materials,
                        installation and electrical work completed by a licensed
                        electrician to install a 240-volt circuit to support a Level
                        2 charger. The cost of purchase a Level 2 charger also
                        qualifies for the rebate."
                                                       description="Qualifying expenses include permitting, materials,
                        installation and electrical work completed by a licensed
                        electrician to install a 240-volt circuit to support a Level
                        2 charger. The cost of purchase a Level 2 charger also
                        qualifies for the rebate."
                                                  />
                                             </p>
                                        ),
                                        [HOME_WIRING_REBATE_NEW_MEXICO]: (
                                             <FormattedMessage
                                                  id={`faqHWRExpensesQualifyAnswer-${HOME_WIRING_REBATE_NEW_MEXICO}`}
                                                  defaultMessage="Permitting, materials, installation and electrical completed by a licensed electrician to install a 240-volt circuit to support a Level 2 charger. The rebate can also be applied to the cost of an eligible Level 2 charger, including the {chargerOne} and the {chargerTwo}. {chargerThree}"
                                                  description="Homepage Incentives Title"
                                                  values={{
                                                       chargerOne: (
                                                            <a
                                                                 href={`/ChargePoint_Home_Flex.pdf`}
                                                                 target="_blank"
                                                                 rel="noopener noreferrer"
                                                            >
                                                                 <FormattedMessage
                                                                      id="chargePointHomeFlex"
                                                                      defaultMessage="ChargePoint Home Flex"
                                                                      description="ChargePoint Home Flex"
                                                                 />
                                                            </a>
                                                       ),
                                                       chargerTwo: (
                                                            <a
                                                                 href={`/Enel_X_JuiceBox_48.pdf`}
                                                                 target="_blank"
                                                                 rel="noopener noreferrer"
                                                            >
                                                                 Enel X Way Juice Box
                                                            </a>
                                                       ),
                                                       chargerThree: (
                                                            <a
                                                                 href={`https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/21-07-530_CO-EV-HomeWiring_app_P03.pdf`}
                                                                 target="_blank"
                                                                 rel="noopener noreferrer"
                                                            >
                                                                 View Terms and Conditions Here
                                                            </a>
                                                       ),
                                                  }}
                                             />
                                        ),
                                   }}
                              />
                         </p>
                         <RenderByState
                              stateName={faqKey}
                              options={{
                                   default: (
                                        <p>
                                             <FormattedMessage
                                                  id="faqHWRExpensesQualifyAnswerTwo"
                                                  defaultMessage="If participating in our {link}, the rebate cannot be applied toward the monthly rental cost of the charger."
                                                  description="If participating in our EV Accelerate At Home program, the rebate cannot be applied toward the monthly rental cost of the charger."
                                                  values={{
                                                       link: (
                                                            <a href="/ev-accelerate-at-home-co">
                                                                 <FormattedMessage
                                                                      id="evAccelerateAtHomeProgram"
                                                                      defaultMessage="EV Accelerate At Home program"
                                                                      description="EV Accelerate At Home program"
                                                                 />
                                                            </a>
                                                       ),
                                                  }}
                                             />
                                        </p>
                                   ),
                                   [HOME_WIRING_REBATE_NEW_MEXICO]: (
                                        <div>
                                             <p>
                                                  <FormattedMessage
                                                       id={`faqHWRExpensesQualifyAnswerTwo-${HOME_WIRING_REBATE_NEW_MEXICO}`}
                                                       defaultMessage="If participating in our {link}, the rebate cannot be applied toward the monthly rental cost of the charger, unless you are an Income-Qualified customer receiving the $2,500 rebate."
                                                       description="If participating in our EV Accelerate At Home program, the rebate cannot be applied toward the monthly rental cost of the charger, unless you are an Income-Qualified customer receiving the $2,500 rebate."
                                                       values={{
                                                            link: (
                                                                 <a href="/ev-accelerate-at-home-nm">
                                                                      <FormattedMessage
                                                                           id="evAccelerateAtHomeProgram"
                                                                           defaultMessage="EV Accelerate At Home program"
                                                                           description="EV Accelerate At Home program"
                                                                      />
                                                                 </a>
                                                            ),
                                                       }}
                                                  />
                                             </p>
                                             <p>
                                                  <FormattedMessage
                                                       id={`viewTermsConditions`}
                                                       defaultMessage="View terms and conditions {link}."
                                                       description="View terms and conditions {link}."
                                                       values={{
                                                            link: (
                                                                 <a href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/NM-EV-HomeWiringRebate_app_updated.pdf">
                                                                      <FormattedMessage
                                                                           id="hereLink"
                                                                           defaultMessage="here"
                                                                           description="here"
                                                                      />
                                                                 </a>
                                                            ),
                                                       }}
                                                  />
                                             </p>
                                        </div>
                                   ),
                              }}
                         />
                    </div>
               ),
               applicableTo: [HOME_WIRING_REBATE_COLORADO, HOME_WIRING_REBATE_NEW_MEXICO],
          },
          {
               headLine: (
                    <span className="headline">
                         <FormattedMessage
                              id={`faqWorkWithElectricians${faqKey.split("-")[1]}`}
                              defaultMessage="Do you work with electricians in {stateName}?"
                              description="Do you work with electricians in Colorado?"
                              values={{
                                   stateName: region,
                              }}
                         />
                    </span>
               ),
               body: (
                    <RenderByState
                         stateName={region}
                         options={{
                              default: (
                                   <div>
                                        <p style={{ fontWeight: 400 }}>
                                             <FormattedMessage
                                                  id={`faqWorkWithElectricians${faqKey.split("-")[1]}Answer`}
                                                  defaultMessage="We work with skilled electricians who are experienced in
                        installing Level 2 chargers for electric vehicles. "
                                                  description="Do you work with electricians in Colorado? Answer"
                                             />
                                             &nbsp;
                                             <a style={{ fontWeight: "bold" }} href={`${pdfLinks[region]}`}>
                                                  <FormattedMessage
                                                       id={`faqWorkWithElectriciansAnswerLink`}
                                                       defaultMessage="Here are the electricians we partner with in {stateName}"
                                                       description="Do you work with electricians in Colorado? Answer link"
                                                       values={{
                                                            stateName: region,
                                                       }}
                                                  />
                                             </a>
                                        </p>
                                   </div>
                              ),
                              [NEW_MEXICO]: (
                                   <div>
                                        <p>
                                             <FormattedMessage
                                                  id={`faqWorkWithElectricians${faqKey.split("-")[1]}Answer`}
                                                  defaultMessage="We work with skilled electricians who are experienced in installing Level 2 chargers for electric vehicles. Here are the electricians we partner with in New Mexico:"
                                                  description="Do you work with electricians in New Mexico? Answer"
                                             />
                                        </p>
                                        <ul>
                                             <li>
                                                  Kirkmeyer Electric, Inc.
                                                  <br />
                                                  2024 N Dal Paso,
                                                  <br />
                                                  Hobbs NM, 88240
                                                  <br />
                                                  <a href="mailto:kirkmeyer@kirkmeyer.net">kirkmeyer@kirkmeyer.net</a>
                                                  <br />
                                                  Office (575)-397-3631
                                             </li>
                                        </ul>
                                   </div>
                              ),
                         }}
                    />
               ),
               applicableTo: [HOME_WIRING_REBATE_COLORADO, HOME_WIRING_REBATE_NEW_MEXICO],
          },
          {
               headLine: (
                    <span className="headline">
                         <FormattedMessage
                              id="faqHWRProvideForRebate"
                              defaultMessage="What do I need to provide to Xcel Energy to get the Home Wiring Rebate?"
                              description="What do I need to provide to Xcel Energy to get the Home Wiring Rebate?"
                         />
                    </span>
               ),
               body: (
                    <div>
                         <ol>
                              <li>
                                   <RenderByState
                                        stateName={faqKey}
                                        options={{
                                             default: (
                                                  <FormattedMessage
                                                       id="faqHWRProvideForRebateAnswerOne"
                                                       defaultMessage="Ensure you’ve been accepted in Optimize Your Charge program."
                                                       description="Ensure you’ve been accepted in Optimize Your Charge program."
                                                  />
                                             ),
                                             [HOME_WIRING_REBATE_NEW_MEXICO]: (
                                                  <FormattedMessage
                                                       id={`faqHWRProvideForRebateAnswerOne-${HOME_WIRING_REBATE_NEW_MEXICO}`}
                                                       defaultMessage="Ensure you’ve enrolled in Optimize Your Charge program or the Whole-Home Time of Use Rate."
                                                       description="Ensure you’ve enrolled in Optimize Your Charge program or the Whole-Home Time of Use Rate."
                                                  />
                                             ),
                                        }}
                                   />
                              </li>
                              <li>Submit your application for your EV Charger and Home Wiring Rebate.</li>
                              <li>
                                   <RenderByState
                                        stateName={faqKey}
                                        options={{
                                             default: (
                                                  <FormattedMessage
                                                       id="faqHWRProvideForRebateAnswerFour"
                                                       defaultMessage="If you have wiring expenses, include your electrician’s invoice for work completed on or after August 5, 2021."
                                                       description="If you have wiring expenses, include your electrician’s invoice for work completed on or after August 5, 2021."
                                                  />
                                             ),
                                             [HOME_WIRING_REBATE_NEW_MEXICO]: (
                                                  <FormattedMessage
                                                       id={`faqHWRProvideForRebateAnswerFour-${HOME_WIRING_REBATE_NEW_MEXICO}`}
                                                       defaultMessage="If you have wiring expenses, include your electrician’s invoice for work completed on or after March 2, 2022."
                                                       description="If you have wiring expenses, include your electrician’s invoice for work completed on or after March 2, 2022."
                                                  />
                                             ),
                                        }}
                                   />
                              </li>
                              <li>
                                   <RenderByState
                                        stateName={faqKey}
                                        options={{
                                             default: (
                                                  <p>
                                                       If you have purchased Level 2 charger, include a receipt dated on
                                                       or after August 5, 2021 that shows the price and the model. The
                                                       receipt must be valid within one year of the application's
                                                       submission date.
                                                  </p>
                                             ),
                                             [HOME_WIRING_REBATE_NEW_MEXICO]: (
                                                  <p>
                                                       If you have purchased Level 2 charger, include a receipt dated on
                                                       or after March 2, 2022 that shows the price and the model. The
                                                       receipt must be valid within one year of the application's
                                                       submission date.
                                                  </p>
                                             ),
                                        }}
                                   />
                              </li>
                         </ol>
                         <p>
                              <FormattedMessage
                                   id="faqHWRProvideForRebateAnswerTC"
                                   defaultMessage="For full list of requirements, see {link}"
                                   description="For full list of requirements, see Terms & Conditions"
                                   values={{
                                        link: (
                                             <a
                                                  href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/21-07-530_CO-EV-HomeWiring_app_P03.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                             >
                                                  <FormattedMessage
                                                       id="termsAndConditions"
                                                       defaultMessage="Terms & Conditions"
                                                       description="Terms & Conditions"
                                                  />
                                             </a>
                                        ),
                                   }}
                              />
                         </p>
                    </div>
               ),
               applicableTo: [HOME_WIRING_REBATE_COLORADO, HOME_WIRING_REBATE_NEW_MEXICO],
          },
          {
               headLine: (
                    <span className="headline">
                         <FormattedMessage
                              id="faqApplyMyAccount"
                              defaultMessage="Do I have to apply through My Account?"
                              description="Do I have to apply through My Account?"
                         />
                    </span>
               ),
               body: (
                    <div>
                         <p>
                              <RenderByState
                                   stateName={faqKey}
                                   options={{
                                        default: (
                                             <FormattedMessage
                                                  id="faqApplyMyAccountAnswer"
                                                  defaultMessage="You may also fill out and send us the completed {link}. Make sure to include your electrician’s invoice or your receipt for a Level 2 Charger dated on or after March 2, 2022."
                                                  description="You may also fill out and send us the completed rebate application. Make sure to include your electrician’s invoice or your receipt for a Level 2 Charger dated on or after March 2, 2022."
                                                  values={{
                                                       link: (
                                                            <a
                                                                 href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/21-07-530_CO-EV-HomeWiring_app_P03.pdf?_ga=2.211321302.2107332365.1665766645-1221231883.1665766645"
                                                                 target="_blank"
                                                                 rel="noopener noreferrer"
                                                            >
                                                                 <FormattedMessage
                                                                      id="faqApplyMyAccountAnswerLink"
                                                                      defaultMessage="rebate application"
                                                                      description="rebate application"
                                                                 />
                                                            </a>
                                                       ),
                                                  }}
                                             />
                                        ),
                                        [HOME_WIRING_REBATE_NEW_MEXICO]: (
                                             <FormattedMessage
                                                  id="faqApplyMyAccountAnswer"
                                                  defaultMessage="You may also fill out and send us the completed {link}. Make sure to include your electrician’s invoice or your receipt for a Level 2 Charger dated on or after March 2, 2022."
                                                  description="You may also fill out and send us the completed rebate application. Make sure to include your electrician’s invoice or your receipt for a Level 2 Charger dated on or after March 2, 2022."
                                                  values={{
                                                       link: (
                                                            <a
                                                                 href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/NM-EV-HomeWiringRebate_app_updated.pdf?_ga=2.204639315.2107332365.1665766645-1221231883.1665766645"
                                                                 target="_blank"
                                                                 rel="noopener noreferrer"
                                                            >
                                                                 <FormattedMessage
                                                                      id="faqApplyMyAccountAnswerLink"
                                                                      defaultMessage="rebate application"
                                                                      description="rebate application"
                                                                 />
                                                            </a>
                                                       ),
                                                  }}
                                             />
                                        ),
                                   }}
                              />
                         </p>
                    </div>
               ),
               applicableTo: [HOME_WIRING_REBATE_COLORADO, HOME_WIRING_REBATE_NEW_MEXICO],
          },
          {
               headLine: (
                    <span className="headline">
                         <FormattedMessage
                              id="faqEnrollInTime"
                              defaultMessage="How do I enroll in the Time of Use rate?"
                              description="How do I enroll in the Time of Use rate?"
                         />
                    </span>
               ),
               body: (
                    <div>
                         <p>
                              <FormattedMessage
                                   id="faqEnrollInTimeAnswer"
                                   defaultMessage="To enroll in the Time of Use rate, please call 800-895-4999 and one of our customer care agents can help you."
                                   description="To enroll in the Time of Use rate, please call 800-895-4999 and one of our customer care agents can help you."
                              />
                         </p>
                    </div>
               ),
               applicableTo: [HOME_WIRING_REBATE_NEW_MEXICO],
          },
          {
               headLine: (
                    <span className="headline">
                         <FormattedMessage
                              id="faqHWRHomeownerInstallation"
                              defaultMessage="Can the homeowner perform the home wiring work?"
                              description="Can the homeowner perform the home wiring work?"
                         />
                    </span>
               ),
               body: (
                    <div>
                         <p>
                              <FormattedMessage
                                   id="faqHWRHomeownerInstallationAnswer"
                                   defaultMessage="A licensed electrician needs to perform the home wiring work to qualify for our EV Charger and Wiring Rebate. You can use one of our electricians or choose another licensed electrician."
                                   description="A licensed electrician needs to perform the home wiring work to qualify for our EV Charger and Wiring Rebate. You can use one of our electricians or choose another licensed electrician."
                              />
                         </p>
                    </div>
               ),
               applicableTo: [HOME_WIRING_REBATE_COLORADO, HOME_WIRING_REBATE_NEW_MEXICO],
          },
          {
               headLine: (
                    <span className="headline">
                         <FormattedMessage
                              id="faqHWRReceiveRebate"
                              defaultMessage="When will I receive my rebate?"
                              description="When will I receive my rebate?"
                         />
                    </span>
               ),
               body: (
                    <div>
                         <p>
                              <FormattedMessage
                                   id="faqHWRReceiveRebateAnswer"
                                   defaultMessage="Expect your rebate check in 8 weeks or less. If you enroll in {link} program, one of our electricians can help you take advantage of this rebate up front when they perform your wiring work and install your Level 2 charger."
                                   description="Expect your rebate check in 8 weeks or less. If you enroll in {link} program, one of our electricians can help you take advantage of this rebate up front when they perform your wiring work and install your Level 2 charger."
                                   values={{
                                        link: (
                                             <RenderByState
                                                  stateName={faqKey}
                                                  options={{
                                                       default: (
                                                            <a href="/ev-accelerate-at-home-co">
                                                                 EV Accelerate At Home
                                                            </a>
                                                       ),
                                                       [HOME_WIRING_REBATE_NEW_MEXICO]: (
                                                            <a href="/ev-accelerate-at-home-nm">
                                                                 EV Accelerate At Home
                                                            </a>
                                                       ),
                                                  }}
                                             />
                                        ),
                                   }}
                              />
                         </p>
                    </div>
               ),
               applicableTo: [HOME_WIRING_REBATE_COLORADO, HOME_WIRING_REBATE_NEW_MEXICO],
          },
          {
               headLine: (
                    <span className="headline">
                         <FormattedMessage
                              id="faqHWRRentingHome"
                              defaultMessage="What if I am renting my home?"
                              description="What if I am renting my home?"
                         />
                    </span>
               ),
               body: (
                    <div>
                         <p>
                              <FormattedMessage
                                   id="faqHWRRentingHomeAnswer"
                                   defaultMessage="You may qualify for the rebate. Property must be a detached single-family home, townhome, rowhouse or duplex, and have a separately metered service. You must receive written consent from the property owner to have the wiring work performed and the eligible Level 2 charger installed"
                                   description="You may qualify for the rebate. Property must be a detached single-family home, townhome, rowhouse or duplex, and have a separately metered service. You must receive written consent from the property owner to have the wiring work performed and the eligible Level 2 charger installed"
                              />
                         </p>
                    </div>
               ),
               applicableTo: [HOME_WIRING_REBATE_COLORADO, HOME_WIRING_REBATE_NEW_MEXICO],
          },
     ].filter(byApplicable(faqKey));
};
