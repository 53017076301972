import React, { useContext } from 'react';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import { FormattedMessage } from 'react-intl';
import { NEW_CONDITION_ID, USED_CONTIDION_ID } from '../../../constants/';


const RateComparisonNewOrUsedRadio = () => {
  const userPrefs = useContext(UserPrefsContext);
  const vehicleConditions = [
    {
      id: NEW_CONDITION_ID, value: 'New', title: <FormattedMessage
        id="hcaNewestModels"
        defaultMessage="Newest Models"
        description="Newest Models"
      />
    },
    {
      id: USED_CONTIDION_ID, value: 'Used', title: <FormattedMessage
        id="hcaOlderModels"
        defaultMessage="Older Models"
        description="Older Models"
      />
    },
  ];
  return (
    <div>
      <div className="mb-2">
        <label>
          <FormattedMessage
            id="hcaCarCondition"
            defaultMessage="CAR CONDITION"
            description="CAR CONDITION"
          />
        </label>
      </div>
      <div className="row no-gutters">
        {vehicleConditions.map(condition => {
          const isSelected = userPrefs.get('rateComparisonVehicleCondition') === condition.id;
          return (
            <div className="col px-1" key={condition.id}>
              <button
                className={`btn btn-default btn-sm btn-block ${isSelected ? 'active' : ''}`}
                type="button"
                onClick={() => userPrefs.set({ rateComparisonVehicleCondition: condition.id })}
                aria-pressed={isSelected}
              >
                {condition.title}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RateComparisonNewOrUsedRadio;
