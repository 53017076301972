import React from "react";

const HowWorks = () => {
  return (
    <>
      <p className="how-works-step-p">
        <span className="how-works-step">Step 1:</span> Request enrollment in
        the pilot by completing the online form. Here's what you'll need to
        provide:{" "}
      </p>
      <ul>
        <li>Tell us about your home and where you plan to charge your EV.</li>
        <li>
          Select your Level 2 charger - pick either the ChargePoint® Home Flex
          or Enel X Juice Box.
        </li>
        <li>
          Confirm you would like to rent the charger and have us maintain it
          (there is an option to prepay if you'd like to own the charger).
        </li>
        <li>
          Choose if you want to charge your EV with 100% renewable energy.
        </li>
      </ul>
      <p className="how-works-step-p">
        <span className="how-works-step">Step 2:</span> We will review your
        information and contact you regarding next steps within two business
        days.
      </p>
      <p className="how-works-step-p">
        <span className="how-works-step">Step 3:</span> A qualified electrician
        from Xcel Energy's trusted network will reach out within two business
        days of enrollment confirmation to schedule the installation of your
        charger within four weeks.
      </p>
      <p>Things to consider before installation:</p>
      <ul>
        <li>
          Wi-Fi
          <ul className="inner-list">
            <li className="inner-bullet">
              Since your charger will be Wi-Fi enabled, please confirm that the
              installation location has Wi-Fi access. If signal strength in this
              location is below three bars, you’ll need to install a Wi-Fi
              booster before the electrician arrives to install the charger.
            </li>
            <li className="inner-bullet">
              Be sure to have your Wi-Fi password available for connecting the
              charger during installation.
            </li>
          </ul>
        </li>
        <li>
          Charger Mobile App
          <ul className="inner-list">
            <li className="inner-bullet">
              Once you select your charger and we confirm your enrollment,
              download the manufacturer’s app (ChargePoint or Enel X) and set up
              an account if prompted. Please complete this step before our
              electrician arrives to install the charger.
              <br />
              <br />
              Enel X (EV JuiceNet){" "}
              <a
                href="https://apps.apple.com/us/app/ev-juicenet/id1106989154"
                target="_blank"
                rel="noopener noreferrer"
              >
                iOS
              </a>{" "}
              |{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.emotorwerks.juicebox&hl=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                Android
              </a>
              <br />
              ChargePoint{" "}
              <a
                href="https://apps.apple.com/us/app/chargepoint/id356866743"
                target="_blank"
                rel="noopener noreferrer"
              >
                iOS
              </a>{" "}
              |{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.coulombtech&hl=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                Android
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <p className="how-works-step-p">
        <span className="how-works-step">Step 4:</span> After the electrician
        sets up your new charger, you'll be ready begin charging. The charger
        will be programmed to charge your car between 9 p.m. and 9 a.m. You will
        have the flexibility to charge during the day if needed at a higher,
        on-peak rate.
      </p>
      <p className="how-works-step-p">
        <span className="how-works-step">Step 5:</span> Share your experience to
        help us improve future EV programs. We will email a short survey for you
        to complete after the installation of your charger.
      </p>
      <p>You’ll be enrolled in the pilot program for 2 years.</p>
    </>
  );
};

export default HowWorks;
