import React, { useContext } from 'react';
import uniq from 'lodash/uniq';
import { FormatCarName } from '../../../../utils/Helpers/Format';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import Select from '../../../../components/shared/InputElements/Select';
import useSelectedVehicle from '../../../../hooks/useSelectedVehicle';
import { NEW_CONDITION_ID } from '../../../constants/vehicleConditions';
import { FormattedMessage } from 'react-intl';

const sortEvs = (a, b) => {
  return FormatCarName(a).localeCompare(FormatCarName(b)) ||
    (b.model_year - a.model_year);
};

const SelectRateComparisonVehicle = ({
  electricVehicles,
  selectedVehicle: givenSelectedVehicle,
  vehicleConditionType = NEW_CONDITION_ID,
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const contextKey = vehicleConditionType === NEW_CONDITION_ID ? "vehicleIdForRateComparison" : "usedVehicleIdForRateComparison"
  const fallbackSelectedVehicle = useSelectedVehicle(
    electricVehicles,
    userPrefs.get(contextKey)
  );

  const selectedVehicle = givenSelectedVehicle || fallbackSelectedVehicle;

  return (
    <>
      <Select
        id={`home-charging-advisor-vehicle-make-${contextKey}`}
        value={selectedVehicle.make}
        optionNames={uniq(electricVehicles.map(ev => ev.make)).sort()}
        optionValues={uniq(electricVehicles.map(ev => ev.make)).sort()}
        handler={(e) => {
          const newVehicle = electricVehicles.find(ev => ev.make === e.target.value);
          userPrefs.set({
            [contextKey]: newVehicle.electric_vehicle_id
          });
        }}
        label={<FormattedMessage
          id="hcaCarMake"
          defaultMessage="Car Make"
          description="Car Make"
        />}
      />

      <Select
        id={`home-charging-advisor-vehicle-model-${contextKey}`}
        value={selectedVehicle.electric_vehicle_id}
        optionNames={electricVehicles.sort(sortEvs).filter(ev => ev.make === selectedVehicle.make).map(ev => `${ev.model_year} ${FormatCarName(ev).split(" ").slice(1).join(" ")}`)}
        optionValues={electricVehicles.sort(sortEvs).filter(ev => ev.make === selectedVehicle.make).map(ev => ev.electric_vehicle_id)}
        handler={(e) => {
          const newVehicle = electricVehicles.find(ev => ev.electric_vehicle_id === parseInt(e.target.value, 10));
          userPrefs.set({
            [contextKey]: newVehicle.electric_vehicle_id
          });
        }}
        label={<FormattedMessage
          id="hcaCarModel"
          defaultMessage="Car Model"
          description="Car Model"
        />}
      />
    </>
  );
};

export default SelectRateComparisonVehicle;
