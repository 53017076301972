import {
  ATTACHED_GARAGE,
  DETACHED_GARAGE,
  DRIVEWAY,
} from '../constants/';

const chargingLocations = [
  {
    id: ATTACHED_GARAGE,
    title: 'Attached Garage',
    upfrontCost: 247,
  },
  {
    id: DETACHED_GARAGE,
    title: 'Detached Garage',
    upfrontCost: 894,
  },
  {
    id: DRIVEWAY,
    title: 'Not in a Garage',
    upfrontCost: 894,
  },
];

export default chargingLocations;
