import React from "react";
import PropTypes from "prop-types";

import "./LinkCard.scss";
import { Link } from "react-router-dom";
import SmoothScroll from "./../../utils/Helpers/SmoothScroll";

const LinkCard = ({ title, image, alt, scrollTo, link }) => {

  if (!link & !scrollTo) return null

  return link ? (
    <div className="link-card">
      <Link to={link} className="evc-card">
        <div>
          <img src={image} alt={alt} />
          <p className="h6">{title}</p>
        </div>
      </Link>
    </div>
  ) : (
    <div className="link-card">
      <div className="evc-card" onClick={e => SmoothScroll(scrollTo)}>
        <div>
          <img src={image} alt={alt} />
          <p className="h6">{title}</p>
        </div>
      </div>
    </div>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string
};
