import React from "react";
import { FormattedMessage } from "react-intl";

const HowToGetStarted = () => {
     return (
          <>
               <ul>
                    <li>
                         <b>
                              <FormattedMessage
                                   id="perksBMWDrivers"
                                   defaultMessage="BMW drivers:"
                                   description="BMW drivers:"
                              />
                         </b>
                         &nbsp;
                         <a href="https://bmwchargeforward.com/" target="_blank" rel="noopener noreferrer">
                              <FormattedMessage
                                   id="perksApplyHere"
                                   defaultMessage="Apply here"
                                   description="Apply here"
                              />{" "}
                         </a>
                         <FormattedMessage
                              id="perksBMWDriversComp"
                              defaultMessage="if you drive any BMW fully electric or plug-in hybrid vehicle"
                              description="if you drive any BMW fully electric or plug-in hybrid vehicle"
                         />
                    </li>
                    <li>
                         <b>
                              <FormattedMessage
                                   id="perksChevroletDrivers"
                                   defaultMessage="Chevrolet drivers:"
                                   description="Chevrolet drivers:"
                              />
                              &nbsp;
                         </b>
                         <a href="https://smartcharging.chevrolet.com/" target="_blank" rel="noopener noreferrer">
                              <FormattedMessage
                                   id="perksApplyHere"
                                   defaultMessage="Apply here"
                                   description="Apply here"
                              />{" "}
                         </a>
                         <FormattedMessage
                              id="perksChevroletDriversComp"
                              defaultMessage="if you drive an eligible Chevrolet electric vehicle or plug-in hybrids."
                              description="if you drive an eligible Chevrolet electric vehicle or plug-in hybrids."
                         />
                    </li>
                    <li>
                         <b>
                              <FormattedMessage
                                   id="perksFordDrivers"
                                   defaultMessage="Ford drivers:"
                                   description="Ford drivers:"
                              />
                              &nbsp;
                         </b>
                         <a href="http://www.ford.com/grid/xcel" target="_blank" rel="noopener noreferrer">
                              <FormattedMessage
                                   id="perksApplyHeret"
                                   defaultMessage="Apply here"
                                   description="Apply here"
                              />{" "}
                         </a>

                         <FormattedMessage
                              id="perksChevroletFordComp"
                              defaultMessage="if you drive any of the eligible Ford vehicles"
                              description=" if you drive any of the eligible Ford vehicles"
                         />
                    </li>
                    <li>
                         <b>
                              <FormattedMessage
                                   id="perksTeslaDrivers"
                                   defaultMessage="Tesla drivers:"
                                   description="Tesla drivers:"
                              />
                              &nbsp;
                         </b>
                         <FormattedMessage
                              id="perksHondaTeslaComp"
                              defaultMessage="If you drive a Tesla, you can apply through evPulse, an EV charge optimization platform from WeaveGrid, a smart charging service provider."
                              description="If you drive a Tesla, you can apply through evPulse, an EV charge optimization platform from WeaveGrid, a smart charging service provider."
                         />
                         <a
                              href="https://chargingperks.xcelenergy.ev-pulse.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                         >
                              {" "}
                              <FormattedMessage
                                   id="clickHere_tesla"
                                   defaultMessage="Click here"
                                   description="Click here"
                              />
                         </a>{" "}
                         <FormattedMessage
                              id="teslaDriversLearnMore"
                              defaultMessage="to learn more about the program and apply on the evPulse enrollment website."
                              description="to learn more about the program and apply on the evPulse enrollment website."
                         />
                    </li>
                    <li>
                         <b>
                              <FormattedMessage
                                   id="perksWallboxCharger"
                                   defaultMessage="Wallbox charger:"
                                   description="Wallbox charger:"
                              />
                              &nbsp;
                         </b>
                         <FormattedMessage
                              id="perksWallboxChargerComp"
                              defaultMessage="If you use a Wallbox charger, you can apply to participate through evPulse, an EV charge optimization platform from WeaveGrid, a smart charging service provider. {link} to learn more about the program and apply on the evPulse enrollment website."
                              description="Wallbox charger description"
                              values={{
                                   link: (
                                        <a
                                             href="https://chargingperks.xcelenergy.ev-pulse.com/"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >
                                             <FormattedMessage
                                                  id="clickHere_wallbox"
                                                  defaultMessage="Click here"
                                                  description="Click here"
                                             />
                                        </a>
                                   ),
                              }}
                         />
                    </li>
               </ul>
               <div style={{ marginTop: "15px" }}>
                    <FormattedMessage
                         id="perksFullList"
                         defaultMessage="For a full list of make and model of current eligible vehicles and chargers, click {here}."
                         description="For a full list of make and model of current eligible vehicles and chargers, click {here}."
                         values={{
                              here: (
                                   <a
                                        href="https://www.xcelenergy.com/staticfiles/xe-responsive/23-10-615%20Charging%20Perks%20Eligible%20EVs_P1.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                   >
                                        <FormattedMessage id="here" defaultMessage="here" description="here" />
                                   </a>
                              ),
                         }}
                    />
               </div>
               <div className="charging-perks-iframe">
                    <iframe title="xcel-charging-perks" height="340" src="https://www.youtube.com/embed/XYNrBxrQlsE" />
               </div>
          </>
     );
};

export default HowToGetStarted;
