export default `
-----BEGIN PGP PUBLIC KEY BLOCK-----
Version: PGP Desktop 10.1.1 (Build 10) - not licensed for commercial use: www.pgp.com
mQENBGGX/egBCADLRLJYr9jA6cAYNNsdmOCbXDehyXfKMRVLvaW10fJSUi8+2o0v
DCBFPTpJVm81uRHekFj4sVsMRuH2GKuBxBNO5y338/blmRb1YKk5ClvwaR/byoRw
lidnkw2ugujAsLrnlgQqXlBZI4e/QVp0W0gqMloqstPwgD4t1kczCNlDZKO2Ghot
8STatl3frmf9wUZrxDPBGNe1QnXkWe8r/DzqyVtqphHc+ob0ACUdqhGHEGQAfPP/
gkLvF1kIgvdjvsyrPYjk/KRB2G5dKw7c01hLFMxKbkHa/aUnklveHL/PdXM7MpkL
zirYJipshkqZ0kL3RayXkf8vqYKilVeJ0qvnABEBAAG0MVR1b21pZSwgVGF5bG9y
IE0gPHRheWxvci5tLnR1b21pZUB4Y2VsZW5lcmd5LmNvbT6JAXIEEAECAFwFAmGX
/egwFIAAAAAAIAAHcHJlZmVycmVkLWVtYWlsLWVuY29kaW5nQHBncC5jb21wZ3Bt
aW1lCAsJCAcDAgoBAhkBBRsDAAAABRYAAwIBBR4BAAAABhUICQoCAwAKCRC4TkKI
JPetXviHCADALxnZCYu391q3KnMVc1A0lhnzvnFvW8LRC0DbsA/UT4ANudzNdf3Q
F/b0ax+uYTxlrsHMbKHB6rBDqNOed3rIpLvc9jVip2CGpGKBDGO6D67Sxlbkih8O
7W8XYAVOgKfdqfUENkVwCFGQjY7ChtL7bWIgMKozji72kKYaPKN+PAoX6DJfWNj6
MCSWFJFSo5dupSkq6GyfdxfXK4MZM5NEXmkzxnlXHY5155j6lDNaNEmU+OSpe+Ir
wHuzI0Vqyn2TTCQ5sizsj8VHHTleConK2roY8aLQuLz3/5yN3quBg6l5s2HBji6K
dS1duz+r9xPN/mZHR2y6YFXzQg5phTFAuQENBGGX/ekBCADHIaOyMjXgWdOm4rC/
ncNjkRoo4ljpU1g+i3kea+kzWCyoxuCfA7byTE4Y5wYILkFiP0O5spgf+gFmLE9Q
kyC4xvoEoDS5BHawSaC2JZmpBDevOGSgdASI/NbrlAKlY1XZO25s1CTa80tJ1U+I
XEdiS3XKWKqAJiWVlTzxEKcHxMMM8JC8/Cf4YzOROfqRoPrBmP0eyp0v7Oh1aAIT
rnoV14v1s/dY4CNUpkfLDZtHNiGvL/wYObUkF1oyJyU9pN6Yw/iCpk4K4Nc4nORk
ndQlucDEWhBvd8F5EcODnLrQYKXGn8TTlUYT12aL9m3YF290b4djakzVNiIcaG8m
NUGzABEBAAGJAkEEGAECASsFAmGX/eoFGwwAAADAXSAEGQEIAAYFAmGX/ekACgkQ
dRbYEZ3aRhJ6RggAidG8t9AMzyLFE06zlVMjomRXtFNM9FpFU6L9V2SKqqOmhUng
Vvni2S6vk+3Sds8nJR35+51nIWXpMRHCnFeZpbapFBZxS+SwfJcJwKs8W8oLNRQb
sA2d/YXRxU7dtEiqNctYdFEhzUIZWbaaDIf3Wg68fuqsPTrhIpDKLkvUKQkrznvG
dOpc0VwWyrkWffz1TIh9uqxPVsIDCyVc2ACZM/SZI4UGBFGF7h8dtldb7I3U1OPn
dn2f0R08tL0RBLR/ZpPLnLTxWP8BrmN+l/WDeRvUdscXxaFzWCbtNoGXMX+qXFt6
jdGB5Gsg83SEKHTMnmF+0fpZhRV77Omepaxj+wAKCRC4TkKIJPetXk1DCAC+pdbJ
yxOjiaHbGRSVdlzTFizQFeGvnYnXpDXB3U5rDoEyQLmaRHgIDGYvxYhxtpviQ22J
f2sPv6oQUNUZ4pLNRHkpg3Yn63c0YOpwJV7ac/jCBaQQp0N1doEW/ulit6hq4+vy
CaEd9iBosgIlhei10suJnFk6HV3GveDnYZVk2C9vI8lWWx+zlsdV3cvDdX4FP4t/
0TJmJG/qTG36m5EzzejhdO3t3FS2EjDgvTQp/lsBt+y27UeOR0cUF9wKF981YXRD
EHMXHH6bh8R+8lsYY3xk8uIP3ux//c7Crh9kcj4dRBux9Kbay5wIPhHUijw/nhn9
owUUukbcBfd6HXSL
=4epY
-----END PGP PUBLIC KEY BLOCK-----
`;
