import { WI_E10_ID } from '../../constants/';

const wi_e10 = {
  name: WI_E10_ID,
  title: "Regular Residential",
  separatelyMetered: false,
  isBundled: false,
  includeHomeWiringEstimator: true,
  upfrontChargerCost: 999,
  rateElements: [
    {
      name: "Basic Service Charge",
      rateElementType: "FixedPerMonth",
      rateComponents: [
        {
          charge: 17,
          name: "Per Month",
        },
      ],
    },
    {
      name: "Energy charge per kWh",
      rateElementType: "EnergyTimeOfUse",
      rateComponents: [
        {
          charge: 0.08318 + 0.042,
          months: [5, 6, 7, 8],
          name: "June through September",
        },
        {
          charge: 0.07165 + 0.042,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: "October through May",
        },
      ],
    },
  ],
};

export default wi_e10;
