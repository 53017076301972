import React from "react";

const ChargerApp = () => {
    return (
        <>
            <p>Once you select your charger and we confirm your enrollment, download the manufacturer’s app (ChargePoint or Enel X) and set up an account if prompted. Please complete this step before our electrician arrives to install the charger.</p>
            <p>Enel X (EV JuiceNet) <a
                        href="https://apps.apple.com/us/app/ev-juicenet/id1106989154"
                        target="_blank"
                        rel="noopener noreferrer"
                    >iOS</a> |  <a
                        href="https://play.google.com/store/apps/details?id=com.emotorwerks.juicebox&hl=en_US"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Android</a>
                        <br/>
                        ChargePoint <a
                            href="https://apps.apple.com/us/app/chargepoint/id356866743"
                            target="_blank"
                            rel="noopener noreferrer"
                        >iOS</a> | <a
                            href="https://play.google.com/store/apps/details?id=com.coulombtech&hl=en_US"
                            target="_blank"
                            rel="noopener noreferrer"
                        >Android</a></p>
        </>
    );
}

export default ChargerApp;