import React, { useState } from "react";
import PropTypes from "prop-types";
import "./CollapsibleSection.scss"

import ReactGA from "react-ga4";



/* A collapsible component that opens and closes sub-content 
    todo: have state of open ityems persist on redraw
*/

const CollapsibleSection = ({
    id,
    headLine,
    children,
    collectionOfOpenItems = [],
    updateCollectionOfOpenItemsFunction = x => x
}) => {
    const [collapsibleSectionButtonOpen, setCollapsibleSectionButtonOpen] = useState(false);

    function handleHideSection(e, id) {
        e.preventDefault();
        setCollapsibleSectionButtonOpen (!collapsibleSectionButtonOpen);
        var grandParentElement = e.currentTarget.parentElement.parentElement.parentElement;

        if (collectionOfOpenItems.includes(grandParentElement)) {
            updateCollectionOfOpenItemsFunction([...collectionOfOpenItems.filter(item => item !== grandParentElement)]);
        } else {
            updateCollectionOfOpenItemsFunction([...collectionOfOpenItems, grandParentElement]);
        }

        if (grandParentElement.className.includes('contentHide')) {
            grandParentElement.className = grandParentElement.className.replace('contentHide', 'contentShow');
        } else {
            grandParentElement.className = grandParentElement.className.replace('contentShow', 'contentHide');
        }

        // Tracking Functionality
        if(id === "participateOptimizeCharger" || id === "participateEvAccelerateAtHome") {
            ReactGA.event({
                category: 'MN-FAQ',
                action: "FAQ Clicked",
                value: id,
            });
        }
    }

    return (
        <>
            <div className="collapsibleSection row">
                <div className={`collapsibleSection col-md-6 offset-md-3 contentHide`}>
                    <div className={`collapsibleSectionHead d-flex`} >
                        <div className="collapsibleSectionHeadLine">{headLine}</div>
                        <div className="collapsibleSectionHeadButton" >
                            <button className="collapsibleSectionCollaspeButton" style={{ border: "0px" }} onClick={e => handleHideSection(e, id)}> {collapsibleSectionButtonOpen ? "-" : "+"} </button></div>
                    </div>
                    <div className="collapsibleSectionBody">
                        <div className="collapsibleSectionBodyContent">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default CollapsibleSection;

CollapsibleSection.propTypes = {
        headline: PropTypes.node,
    children: PropTypes.node.isRequired,
    collectionOfOpenItems: PropTypes.array,
    updateCollectionOfOpenItemsFunction: PropTypes.func
}