import React from "react";
import { FormattedMessage } from "react-intl";
import driverWithDog from "../../client_customizations/assets/images/hero/driverWithDog.png";
import ContentCenter from "../../client_customizations/components/PageLayout/BodyTemplates/ContentCenter";
import { EasyChargingHome } from "../../client_customizations/components/EasyChargingHome";
import FrequentlyAskedQuestionsSection from "../../client_customizations/components/FrequentlyAskedQuestionsSection/FrequentlyAskedQuestionsSection";
import { getByState } from "../RenderByState";
import {NEW_MEXICO} from "../../constants";

export const AccelerateAtHome = ({ stateName, faqKey, price }) => {
  return (
    <>
      <img
        src={driverWithDog}
        alt="Hero"
        style={{ minWidth: "100%", maxWidth: "100%" }}
      />
      <ContentCenter
        pageHeaderLead="EV ACCELERATE AT HOME"
        pageHeaderHeadLine={
          <FormattedMessage
            id="evaahHeadline"
            defaultMessage="Easy Electric Vehicle Charging at Home."
            description="Easy Electric Vehicle Charging at Home."
          />
        }
        pageHeaderSubHeading={
          <FormattedMessage
            id="evaahSubHeadline"
            defaultMessage="Charging your electric vehicle (EV) at home is convenient, and it just got even easier.  With EV Accelerate At home, you can save on charging by renting a Level 2 charger from Xcel Energy or providing your own pre-approved charger."
            description="Easy Electric Vehicle Charging at Home Subheader"
          />
        }
        sectionStyle={{ backgroundColor: "#F6F8FB" }}
      >
        <EasyChargingHome
          stateName={stateName}
          enrollURL={getByState({
            stateName,
            options: {
              default: "https://my.xcelenergy.com/MyAccount/s/ev",
              [NEW_MEXICO]:
                "https://my.xcelenergy.com/MyAccount/s/ev",
            },
          })}
          price={price}
        />
      </ContentCenter>
      <ContentCenter
        pageHeaderLead={
          <FormattedMessage
            id="frequentlyAskedQuestions"
            defaultMessage="FREQUENTLY ASKED QUESTIONS"
            description="FREQUENTLY ASKED QUESTIONS"
          />
        }
        sectionStyle={{ backgroundColor: "#FFFFFF" }}
      >
        <FrequentlyAskedQuestionsSection applicableTo={faqKey} region={stateName} />
      </ContentCenter>
    </>
  );
};

AccelerateAtHome.displayName = "AccelerateAtHome";
