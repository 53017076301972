import IncentiveIsCustomPrimary from "../isCustomPrimary";

const sortRebates = rebates => {
  let monetized = [];
  let unmonetized = [];
  for (let i = 0; i < rebates.length; i++) {
    //Due to way w the incentives endpoint returns values
    // An incentive may not have an evaluation parameter
    if( !('evaluation' in rebates[i]) ) { 
      unmonetized.unshift(rebates[i]);
    } else {
      monetized.unshift(rebates[i]);
    }
  }
  return monetized.concat(unmonetized);
};

const sortIncentives = incentives => {
  if (!incentives) return null;

  let customPrimary = []; // a few incentives that the UI should always show first
  let eligibleRebates = [];
  let eligibleBenefits = [];
  let unknownRebates = [];
  let unknownBenefits = [];
  let ineligibleRebates = [];
  let ineligibleBenefits = [];

  for (let i = 0; i < incentives.length; i++) {
    let incentive = incentives[i];

    //Handle call to incentive page to show all available incentives 
    let eligibility = ('evaluation' in incentive) ? incentive.evaluation.eligibility : "unknown" ;

    let isRebate =
      incentive.type &&
      ["REBATE", "TAX CREDIT"].includes(incentive.type.toUpperCase());

    if (IncentiveIsCustomPrimary(incentive)) {
      customPrimary.unshift(incentive);
    } else if (isRebate) {
      switch (eligibility) {
        case "eligible":
          eligibleRebates.unshift(incentive);
          break;
        case "ineligible":
          ineligibleRebates.unshift(incentive);
          break;
        default:
          unknownRebates.unshift(incentive);
      }
    } else {
      switch (eligibility ) {
        case "eligible":
          eligibleBenefits.unshift(incentive);
          break;
        case "ineligible":
          ineligibleBenefits.unshift(incentive);
          break;
        default:
          unknownBenefits.unshift(incentive);
      }
    }
  }

  const sortedEligibleRebates = sortRebates(eligibleRebates);
  const sortedUnkonwnRebates = sortRebates(unknownRebates);
  const sortedIneligibleRebates = sortRebates(ineligibleRebates);

  const all = customPrimary.concat(
    sortedEligibleRebates,
    eligibleBenefits,
    sortedUnkonwnRebates,
    unknownBenefits,
    sortedIneligibleRebates,
    ineligibleBenefits
  );

  return {
    get(category) {
      switch (category) {
        case "all":
          return all;
        case "first-four":
          return all.slice(0, 4);
        case "eligible-rebates":
          return customPrimary.concat(sortedEligibleRebates);
        case "eligible-benefits":
          return eligibleBenefits;
        case "eligible-incentives":
          return customPrimary.concat(sortedEligibleRebates, eligibleBenefits);
        case "unknown":
          return sortedUnkonwnRebates.concat(unknownBenefits);
        case "ineligible":
          return sortedIneligibleRebates.concat(ineligibleBenefits);
        default:
          return null;
      }
    },
    total(category) {
      if (category === "eligible-rebates") {
        const arr = customPrimary.concat(eligibleRebates);
        let allAreEligible = true;

        const total = arr.reduce((acc, incentive) => {
          if (incentive.evaluation.eligibility !== "eligible") {
            allAreEligible = false;
          }

          return incentive &&
            incentive.evaluation &&
            !isNaN(
              parseInt(
                incentive.evaluation.amount_in_purchase,
                10
              )
            )
            ? acc +
                parseInt(
                  incentive.evaluation.amount_in_purchase,
                  10
                )
            : acc;
        }, 0);

        return allAreEligible ? total : 0;
      } else {
        return 0;
      }
    }
  };
};

export default sortIncentives;
