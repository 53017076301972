const wisconsin = {
  year: 2018,
  data: [
    1.141714409,
    1.089397387,
    1.006439041,
    0.984375581,
    1.016842357,
    1.0685187,
    1.141870058,
    1.221315353,
    1.428863109,
    1.60029783,
    1.671924731,
    1.757174121,
    1.702447851,
    1.648723605,
    1.627683942,
    1.619098958,
    1.825198781,
    1.968493353,
    1.993837941,
    1.894462385,
    1.812224033,
    1.674222901,
    1.321696935,
    1.201205172,
    1.108811079,
    1.128341321,
    1.113665039,
    1.124517556,
    1.142567587,
    1.275661759,
    1.447193248,
    1.453499544,
    1.404967557,
    1.386680422,
    1.366861247,
    1.364877436,
    1.349000906,
    1.341063583,
    1.383570613,
    1.445403517,
    1.618705534,
    1.848347672,
    1.911577086,
    1.896293073,
    1.847939274,
    1.728878578,
    1.485904188,
    1.338633004,
    1.207025346,
    1.156766974,
    1.124300406,
    1.125012135,
    1.147382614,
    1.287755169,
    1.448330577,
    1.453631828,
    1.393661724,
    1.375652846,
    1.357828531,
    1.354641451,
    1.327539431,
    1.330763812,
    1.379014889,
    1.433934915,
    1.610033752,
    1.818891252,
    1.875480936,
    1.867154161,
    1.806658271,
    1.688484385,
    1.446327624,
    1.306945594,
    1.246432463,
    1.213546936,
    1.189472668,
    1.196476868,
    1.221710386,
    1.356054384,
    1.514351982,
    1.528152099,
    1.470241942,
    1.451459041,
    1.425615016,
    1.421293697,
    1.392276653,
    1.39737294,
    1.440096093,
    1.490704953,
    1.654469215,
    1.866552873,
    1.934395082,
    1.924725632,
    1.882604356,
    1.781275283,
    1.54967446,
    1.399581595,
    1.293825547,
    1.235599884,
    1.20330974,
    1.207874181,
    1.228551742,
    1.356613325,
    1.514834037,
    1.553375277,
    1.510648828,
    1.485255806,
    1.463378266,
    1.455015708,
    1.419938378,
    1.429159099,
    1.468762623,
    1.489395656,
    1.631977558,
    1.80373917,
    1.840307487,
    1.809992457,
    1.755772415,
    1.69427343,
    1.516474768,
    1.404937258,
    1.277904182,
    1.201253403,
    1.135185576,
    1.124296483,
    1.133955164,
    1.205064684,
    1.323373253,
    1.473471515,
    1.602696856,
    1.673132109,
    1.666972788,
    1.632843647,
    1.586560659,
    1.539789847,
    1.50826886,
    1.500563836,
    1.597486054,
    1.740149666,
    1.745888916,
    1.687974111,
    1.615404274,
    1.548719983,
    1.37788695,
    1.254399704,
    1.192445848,
    1.13156715,
    1.077832961,
    1.063572622,
    1.074979726,
    1.138772697,
    1.249666903,
    1.398385983,
    1.52466999,
    1.611750098,
    1.621172881,
    1.619245709,
    1.608448714,
    1.563329724,
    1.521792871,
    1.532434017,
    1.653000523,
    1.835653293,
    1.873525862,
    1.837639605,
    1.734165382,
    1.607487858,
    1.373164796,
    1.178019944,
    1.036710373,
    0.965523566,
    0.927168946,
    0.926189611,
    0.951497463,
    1.081654481,
    1.252388916,
    1.267838398,
    1.237647175,
    1.239681216,
    1.230119515,
    1.220181437,
    1.207461696,
    1.210009162,
    1.250535374,
    1.30065351,
    1.472401861,
    1.684199712,
    1.724139831,
    1.690508215,
    1.624503678,
    1.494037897,
    1.242443883,
    1.094103337,
    1.105601108,
    1.091112057,
    1.080214753,
    1.094113333,
    1.114771972,
    1.243009114,
    1.420786094,
    1.442976865,
    1.393697358,
    1.374884424,
    1.351097273,
    1.339545984,
    1.326948476,
    1.315458489,
    1.357384182,
    1.41573581,
    1.591983977,
    1.816552413,
    1.880167048,
    1.866177976,
    1.818490651,
    1.696503853,
    1.461382331,
    1.307271624,
    1.119509824,
    1.048831521,
    1.007220587,
    1.002663639,
    1.026666311,
    1.158399125,
    1.322349794,
    1.332921826,
    1.277671204,
    1.262388737,
    1.246178475,
    1.241602224,
    1.221428444,
    1.227859681,
    1.275619049,
    1.329038959,
    1.506667195,
    1.700721656,
    1.743270251,
    1.731626146,
    1.667539359,
    1.541420395,
    1.304161267,
    1.144113352,
    1.019817626,
    0.94764061,
    0.899744432,
    0.892618729,
    0.920228848,
    1.041318586,
    1.204547987,
    1.223414018,
    1.175039662,
    1.164986927,
    1.145806849,
    1.147410184,
    1.133417848,
    1.147483723,
    1.190761092,
    1.240345178,
    1.403742941,
    1.587181585,
    1.620737524,
    1.604634086,
    1.553947571,
    1.439504861,
    1.21204153,
    1.063831547,
    1.101333507,
    1.107146309,
    1.111779676,
    1.128209009,
    1.154564648,
    1.276992307,
    1.461377261,
    1.506966576,
    1.463290831,
    1.438808656,
    1.423429561,
    1.418786007,
    1.398478144,
    1.398478767,
    1.439719816,
    1.461500848,
    1.608680058,
    1.785020204,
    1.828146396,
    1.791090006,
    1.752607254,
    1.683043751,
    1.519324298,
    1.436980301,
    1.350797874,
    1.341984679,
    1.331803463,
    1.343231725,
    1.361932518,
    1.436727336,
    1.578782224,
    1.743306808,
    1.870566373,
    1.939007068,
    1.929096894,
    1.886335439,
    1.843962798,
    1.775441712,
    1.741495055,
    1.730135494,
    1.834997634,
    1.999769062,
    2.030063733,
    1.966517853,
    1.897914283,
    1.829108578,
    1.667427604,
    1.573480391,
    1.447931392,
    1.378438321,
    1.33085008,
    1.320087472,
    1.33863632,
    1.405141808,
    1.505095541,
    1.660655986,
    1.785979176,
    1.874902409,
    1.874108106,
    1.85643825,
    1.846193665,
    1.787468658,
    1.734200599,
    1.740086451,
    1.878791648,
    2.071841932,
    2.114426231,
    2.071071948,
    1.960112733,
    1.847253012,
    1.615501197,
    1.442302184,
    1.360651305,
    1.297131646,
    1.267078919,
    1.241683797,
    1.275100706,
    1.406151452,
    1.549290606,
    1.577803415,
    1.533217434,
    1.559694006,
    1.542066261,
    1.497286557,
    1.508698817,
    1.46848032,
    1.462482447,
    1.496536147,
    1.719494873,
    1.944213458,
    1.993317744,
    1.942981869,
    1.89267875,
    1.822562079,
    1.577400321,
    1.431184179,
    1.349147651,
    1.313602477,
    1.295925913,
    1.327206565,
    1.345594645,
    1.471454338,
    1.636058954,
    1.666604286,
    1.614867845,
    1.581973343,
    1.53645251,
    1.502688258,
    1.472462062,
    1.471155201,
    1.51272066,
    1.567825459,
    1.740366249,
    1.965921186,
    2.047320565,
    2.040940176,
    1.98612475,
    1.863292449,
    1.629251649,
    1.492301899,
    1.303355849,
    1.219017807,
    1.164401673,
    1.159710668,
    1.182509296,
    1.310193589,
    1.464178159,
    1.481713616,
    1.423282322,
    1.402314409,
    1.374427122,
    1.352237531,
    1.321506576,
    1.329257139,
    1.372600408,
    1.422533186,
    1.600226591,
    1.791278588,
    1.840472183,
    1.831766741,
    1.76125032,
    1.636192159,
    1.400425669,
    1.247611894,
    1.132453601,
    1.054621646,
    1.000241587,
    0.993385781,
    1.020625566,
    1.136929273,
    1.29581167,
    1.321201006,
    1.270154866,
    1.255882501,
    1.228897506,
    1.216580479,
    1.197101803,
    1.210846053,
    1.251131319,
    1.297975646,
    1.462093434,
    1.642137018,
    1.680192044,
    1.665136891,
    1.61028424,
    1.494453658,
    1.270479247,
    1.113770177,
    1.075840305,
    1.03822364,
    1.015718196,
    1.01996672,
    1.045965165,
    1.157598415,
    1.335477525,
    1.387284604,
    1.347669869,
    1.32562307,
    1.308936001,
    1.297474675,
    1.279338502,
    1.286422726,
    1.323270631,
    1.345177593,
    1.490504549,
    1.645713253,
    1.667360119,
    1.628943274,
    1.580373667,
    1.504172504,
    1.344429615,
    1.229020067,
    1.098893695,
    1.03900681,
    0.994806306,
    0.985658461,
    0.998861492,
    1.053602072,
    1.199768482,
    1.36323041,
    1.488619931,
    1.559265426,
    1.555999843,
    1.516200232,
    1.483756979,
    1.429643041,
    1.397099241,
    1.387662086,
    1.488340114,
    1.621587583,
    1.624724837,
    1.561215836,
    1.500200892,
    1.420576596,
    1.269125022,
    1.157611988,
    1.117412152,
    1.084798502,
    1.056645434,
    1.049213705,
    1.066590585,
    1.118698093,
    1.25389513,
    1.417372423,
    1.538517601,
    1.622884804,
    1.631145971,
    1.619861691,
    1.618771541,
    1.563087356,
    1.519597593,
    1.528411916,
    1.657095677,
    1.835623545,
    1.876962085,
    1.835983222,
    1.739574953,
    1.602332474,
    1.383822085,
    1.212223478,
    1.104725161,
    1.068696695,
    1.05966273,
    1.071485976,
    1.102098534,
    1.22242703,
    1.408529156,
    1.435871519,
    1.395903742,
    1.390858438,
    1.374606925,
    1.347397765,
    1.338707028,
    1.328335083,
    1.367501464,
    1.414793915,
    1.591736989,
    1.810286456,
    1.872766133,
    1.836545912,
    1.779805702,
    1.642902493,
    1.40880609,
    1.251552293,
    1.082114158,
    0.99249372,
    0.944290371,
    0.94108515,
    0.963278398,
    1.074421833,
    1.246308258,
    1.279254592,
    1.231770816,
    1.215135203,
    1.185892396,
    1.161210107,
    1.154176833,
    1.150683245,
    1.188199439,
    1.242702813,
    1.416509002,
    1.611421085,
    1.645857334,
    1.630290561,
    1.564505214,
    1.430985236,
    1.200957014,
    1.044973882,
    0.939414084,
    0.87830213,
    0.835982985,
    0.826259866,
    0.855507024,
    0.97165095,
    1.150312763,
    1.171442758,
    1.112872414,
    1.099331389,
    1.083798542,
    1.075401189,
    1.069454182,
    1.073329749,
    1.119693464,
    1.170651805,
    1.348220186,
    1.528335594,
    1.554336334,
    1.539192688,
    1.472751287,
    1.335615836,
    1.109040796,
    0.954965207,
    0.900557751,
    0.868184302,
    0.853038458,
    0.856965765,
    0.892291132,
    1.003969359,
    1.196296028,
    1.226099453,
    1.166211616,
    1.154057005,
    1.136049691,
    1.132196117,
    1.134648465,
    1.134940296,
    1.178778718,
    1.227378573,
    1.395115726,
    1.586520563,
    1.629076429,
    1.609525209,
    1.573013269,
    1.449345681,
    1.237555609,
    1.089849267,
    1.034205481,
    1.014336596,
    1.015123909,
    1.027553812,
    1.057327246,
    1.168577867,
    1.362244952,
    1.417632005,
    1.365985242,
    1.341206806,
    1.325444478,
    1.313101174,
    1.304037045,
    1.301873906,
    1.34040923,
    1.361709055,
    1.508378028,
    1.674772295,
    1.705353826,
    1.668967368,
    1.629432296,
    1.552528485,
    1.396826807,
    1.285288152,
    1.109351398,
    1.033695097,
    0.985694078,
    0.974763612,
    0.98784839,
    1.040761844,
    1.1867631,
    1.351916494,
    1.466473467,
    1.533676438,
    1.528456859,
    1.485511902,
    1.456502891,
    1.402198535,
    1.36888673,
    1.358590945,
    1.457668202,
    1.592066913,
    1.591751058,
    1.534444833,
    1.471497683,
    1.393289622,
    1.239933557,
    1.117426084,
    1.039647017,
    0.975932289,
    0.930039803,
    0.911110178,
    0.925183759,
    0.970288508,
    1.103381699,
    1.26695842,
    1.376017528,
    1.45999483,
    1.469153262,
    1.457527123,
    1.461656528,
    1.40930134,
    1.366122526,
    1.374308974,
    1.497689585,
    1.667413763,
    1.695360572,
    1.664334188,
    1.567888973,
    1.429366134,
    1.207910224,
    1.018160946,
    0.896202725,
    0.838087495,
    0.814770502,
    0.815581431,
    0.846825668,
    0.963730458,
    1.156374277,
    1.185025116,
    1.139235724,
    1.13897069,
    1.127922137,
    1.109062638,
    1.115087448,
    1.108741058,
    1.14706854,
    1.194225886,
    1.36707958,
    1.577898898,
    1.611196316,
    1.578775429,
    1.517329159,
    1.376789831,
    1.13748743,
    0.976152302,
    0.887998882,
    0.831700586,
    0.80649516,
    0.807992223,
    0.83510318,
    0.944874428,
    1.138461496,
    1.171508598,
    1.110652598,
    1.093977309,
    1.071672163,
    1.057147731,
    1.06914896,
    1.056550057,
    1.096914969,
    1.153455147,
    1.326720689,
    1.532795369,
    1.566687582,
    1.554461875,
    1.499130081,
    1.365111005,
    1.13546666,
    0.990782092,
    0.949278125,
    0.932437748,
    0.927019985,
    0.933707621,
    0.965997648,
    1.088458618,
    1.285861794,
    1.308265887,
    1.228844074,
    1.208909668,
    1.194405203,
    1.184273915,
    1.188468707,
    1.178325725,
    1.226996183,
    1.279094033,
    1.459057372,
    1.665304894,
    1.715100628,
    1.706495523,
    1.656404675,
    1.52267716,
    1.298382559,
    1.159270326,
    0.976304831,
    0.926489398,
    0.901398253,
    0.89648635,
    0.920382758,
    1.026645824,
    1.179719493,
    1.200106166,
    1.162486377,
    1.137867635,
    1.102388626,
    1.101436014,
    1.067654347,
    1.08513305,
    1.136229163,
    1.173823474,
    1.318310864,
    1.458637666,
    1.534189152,
    1.516870099,
    1.495019408,
    1.382054074,
    1.195768746,
    1.06093585,
    1.034212757,
    1.013974124,
    1.007664904,
    1.012338744,
    1.031532528,
    1.136937192,
    1.2979084,
    1.346042491,
    1.317758194,
    1.28131667,
    1.252794124,
    1.249882951,
    1.210292217,
    1.224546353,
    1.262298444,
    1.28213704,
    1.403455051,
    1.519849337,
    1.583070224,
    1.54875262,
    1.528255057,
    1.459724223,
    1.333198944,
    1.232054764,
    1.076075511,
    1.018227383,
    0.979591133,
    0.966687056,
    0.976302589,
    1.035460147,
    1.181682148,
    1.345751051,
    1.468441418,
    1.515332563,
    1.493756162,
    1.453330639,
    1.401960405,
    1.359193961,
    1.332412041,
    1.318071082,
    1.389521145,
    1.478025094,
    1.517303199,
    1.462947891,
    1.428506126,
    1.357707317,
    1.231727125,
    1.122527915,
    1.067832258,
    1.026920761,
    0.994876242,
    0.977863914,
    0.991099309,
    1.048255368,
    1.185659902,
    1.352287422,
    1.469858578,
    1.532578466,
    1.522923951,
    1.509973841,
    1.48851661,
    1.44259495,
    1.404709873,
    1.406795092,
    1.501321951,
    1.625710895,
    1.696883374,
    1.66627732,
    1.598461622,
    1.46944072,
    1.277482427,
    1.103306771,
    0.984789314,
    0.946097677,
    0.935193903,
    0.938328493,
    0.959616152,
    1.071190259,
    1.237705311,
    1.25696105,
    1.227491479,
    1.210825108,
    1.183290414,
    1.168231657,
    1.142107451,
    1.143063476,
    1.192648637,
    1.229665556,
    1.380692602,
    1.552357565,
    1.635520564,
    1.605381724,
    1.572858912,
    1.445918087,
    1.243000617,
    1.095000586,
    0.956347327,
    0.891555621,
    0.863109256,
    0.857536265,
    0.873737916,
    0.979134012,
    1.13826041,
    1.163430913,
    1.1244746,
    1.094757208,
    1.05505052,
    1.045092002,
    1.020527102,
    1.022395865,
    1.070615574,
    1.115529535,
    1.265583962,
    1.423759353,
    1.493093194,
    1.48333196,
    1.446672817,
    1.326578565,
    1.123413883,
    0.982238552,
    0.88360533,
    0.840841931,
    0.818131569,
    0.80882968,
    0.832084775,
    0.94644588,
    1.112760798,
    1.127319595,
    1.074669413,
    1.045854757,
    1.018237207,
    1.019358772,
    0.993681077,
    1.001201294,
    1.058351749,
    1.099398128,
    1.253773136,
    1.401591181,
    1.469621085,
    1.461956387,
    1.42657172,
    1.302097835,
    1.103368359,
    0.961431997,
    0.869492462,
    0.83218312,
    0.821638135,
    0.820564727,
    0.849121001,
    0.957743435,
    1.129913981,
    1.152355388,
    1.099408828,
    1.072533162,
    1.040160877,
    1.044758894,
    1.025201402,
    1.034076125,
    1.087822796,
    1.126099254,
    1.268193807,
    1.420642877,
    1.497674408,
    1.485936643,
    1.472646507,
    1.362966613,
    1.176567345,
    1.036257601,
    0.95941773,
    0.929562124,
    0.927410964,
    0.931650816,
    0.953352471,
    1.059467913,
    1.231266785,
    1.280738927,
    1.236834599,
    1.198138389,
    1.171694756,
    1.17148785,
    1.143255871,
    1.154022311,
    1.19349932,
    1.213629033,
    1.331175703,
    1.455108878,
    1.513773488,
    1.486417892,
    1.468173255,
    1.401321046,
    1.271933266,
    1.175795096,
    1.079592257,
    1.043061556,
    1.021356749,
    1.01624103,
    1.028255224,
    1.090252693,
    1.248633529,
    1.417850871,
    1.521790945,
    1.564144186,
    1.542298637,
    1.501297486,
    1.457830137,
    1.407386585,
    1.380938573,
    1.365316039,
    1.435279395,
    1.541138111,
    1.586609243,
    1.539563607,
    1.508429196,
    1.442195519,
    1.313162076,
    1.214071799,
    1.156454758,
    1.123045806,
    1.104077464,
    1.096516735,
    1.11232528,
    1.174471648,
    1.313848079,
    1.483303437,
    1.581187061,
    1.636454668,
    1.62311499,
    1.606775076,
    1.589293213,
    1.538550925,
    1.500032958,
    1.500795056,
    1.594384444,
    1.738310307,
    1.813708142,
    1.790196514,
    1.720566725,
    1.599643506,
    1.404333863,
    1.237486094,
    1.105375798,
    1.057491731,
    1.046021626,
    1.050554615,
    1.07019716,
    1.182445056,
    1.341910011,
    1.361268315,
    1.312185292,
    1.288789079,
    1.255325606,
    1.233266373,
    1.207080312,
    1.205546544,
    1.255071082,
    1.291459908,
    1.437245106,
    1.61903154,
    1.70976503,
    1.691533425,
    1.6545004,
    1.536154081,
    1.327170794,
    1.183535845,
    1.068388871,
    1.000372797,
    0.971981132,
    0.9686676,
    0.982543007,
    1.089694498,
    1.243045926,
    1.268739669,
    1.210153418,
    1.173951505,
    1.128323279,
    1.10977366,
    1.086990691,
    1.086534743,
    1.134562446,
    1.17832197,
    1.323137639,
    1.491838073,
    1.569400634,
    1.571161824,
    1.530992866,
    1.417154526,
    1.209694683,
    1.071811131,
    0.980058131,
    0.93244049,
    0.910055052,
    0.902055258,
    0.923147811,
    1.038529653,
    1.199644016,
    1.214522814,
    1.142419948,
    1.107486404,
    1.07412109,
    1.067732163,
    1.044575986,
    1.049811492,
    1.106739392,
    1.147021122,
    1.296049841,
    1.453784615,
    1.528570525,
    1.53204462,
    1.492684123,
    1.374535833,
    1.170627433,
    1.03411748,
    0.972731355,
    0.940167036,
    0.935345223,
    0.938045669,
    0.964498703,
    1.074650341,
    1.24283328,
    1.265931143,
    1.19251647,
    1.158536465,
    1.120573793,
    1.117982804,
    1.100180798,
    1.105031496,
    1.159020152,
    1.196720049,
    1.334346368,
    1.499846901,
    1.586929127,
    1.586461113,
    1.571326871,
    1.469085934,
    1.278227414,
    1.147376587,
    1.092038861,
    1.068086957,
    1.074562265,
    1.084452574,
    1.104189059,
    1.212575461,
    1.379863508,
    1.43072643,
    1.364086607,
    1.317570397,
    1.283388813,
    1.275479888,
    1.24696257,
    1.252630369,
    1.294154984,
    1.311360231,
    1.426124971,
    1.566806508,
    1.638760485,
    1.623051876,
    1.602709741,
    1.544447394,
    1.408656413,
    1.318232873,
    1.185946392,
    1.128678734,
    1.098189339,
    1.091204273,
    1.102858807,
    1.166051798,
    1.31312179,
    1.482605133,
    1.566734407,
    1.602239264,
    1.574124769,
    1.527430711,
    1.484269134,
    1.434596654,
    1.407182496,
    1.390341232,
    1.457267802,
    1.570377949,
    1.612903695,
    1.577184518,
    1.537269152,
    1.477860796,
    1.340352288,
    1.238002096,
    1.18478265,
    1.138899397,
    1.115257348,
    1.097769947,
    1.114908308,
    1.172577321,
    1.300994363,
    1.470566984,
    1.573252589,
    1.630539236,
    1.617276372,
    1.590269812,
    1.57906002,
    1.52099529,
    1.490814606,
    1.490101107,
    1.573535345,
    1.721920944,
    1.7902685,
    1.767220043,
    1.686612856,
    1.580705633,
    1.37524844,
    1.216455744,
    1.120969228,
    1.100944531,
    1.116182002,
    1.100101256,
    1.139152257,
    1.226519805,
    1.358291782,
    1.366135744,
    1.414810587,
    1.421011733,
    1.395542515,
    1.323494047,
    1.32162545,
    1.285450415,
    1.393184368,
    1.416921944,
    1.528439555,
    1.711321185,
    1.810751276,
    1.752765587,
    1.681459821,
    1.61910679,
    1.394023192,
    1.28232623,
    1.168302208,
    1.114874268,
    1.103560127,
    1.109204125,
    1.123729762,
    1.235498334,
    1.388422947,
    1.414338081,
    1.333257298,
    1.28794671,
    1.237035674,
    1.216060217,
    1.198451448,
    1.191876824,
    1.238965299,
    1.280221407,
    1.424612959,
    1.611420874,
    1.703090937,
    1.715852746,
    1.681471708,
    1.570105651,
    1.355737302,
    1.219630879,
    1.076854905,
    1.005447709,
    0.97399862,
    0.964121149,
    0.983701408,
    1.10047675,
    1.248478271,
    1.261547892,
    1.177816035,
    1.139079948,
    1.099507677,
    1.085782733,
    1.063267637,
    1.070371501,
    1.126100851,
    1.165201689,
    1.3115049,
    1.470704295,
    1.544901405,
    1.55718483,
    1.509991204,
    1.396169792,
    1.183244123,
    1.038622957,
    0.955983164,
    0.896195501,
    0.875044546,
    0.869347091,
    0.896198217,
    1.006753505,
    1.163674909,
    1.182806606,
    1.103041813,
    1.069024637,
    1.027945464,
    1.024371024,
    1.011352686,
    1.021170471,
    1.073739008,
    1.110490946,
    1.24602912,
    1.404078868,
    1.478881057,
    1.484754243,
    1.459303128,
    1.358190822,
    1.154673883,
    1.009896582,
    0.989018629,
    0.960295504,
    0.964577142,
    0.972758943,
    0.996220762,
    1.10669681,
    1.275447052,
    1.322802868,
    1.251907195,
    1.206501424,
    1.175065007,
    1.170013863,
    1.153896654,
    1.16004045,
    1.203051263,
    1.220446677,
    1.336325437,
    1.473915874,
    1.536252227,
    1.522778245,
    1.502648193,
    1.441428241,
    1.300337489,
    1.214438449,
    1.174310501,
    1.159874148,
    1.160952954,
    1.169946864,
    1.187456869,
    1.257994522,
    1.415848048,
    1.587588819,
    1.665573957,
    1.699518579,
    1.673053076,
    1.626693807,
    1.594137347,
    1.534232911,
    1.506997965,
    1.488153866,
    1.56122508,
    1.691692668,
    1.744649017,
    1.707793851,
    1.674224209,
    1.614131867,
    1.475534739,
    1.385689685,
    1.276660163,
    1.218623136,
    1.195424176,
    1.19117367,
    1.209006361,
    1.27594633,
    1.398654982,
    1.56453589,
    1.641849625,
    1.689816612,
    1.669944146,
    1.646916146,
    1.635203857,
    1.585647761,
    1.545886983,
    1.544073836,
    1.64095108,
    1.797323076,
    1.865798194,
    1.852628953,
    1.775228576,
    1.660236193,
    1.45168272,
    1.29141967,
    1.205923478,
    1.156870371,
    1.147591344,
    1.156605295,
    1.177694781,
    1.295432601,
    1.441571164,
    1.45659968,
    1.387892207,
    1.357849569,
    1.318040946,
    1.287113994,
    1.267438941,
    1.263577005,
    1.313501687,
    1.348111409,
    1.494599377,
    1.686578341,
    1.783014686,
    1.776478793,
    1.735663418,
    1.623016763,
    1.403200546,
    1.275971942,
    1.247842687,
    1.209203082,
    1.204797448,
    1.218227489,
    1.232774714,
    1.350186767,
    1.492329237,
    1.515369765,
    1.435001557,
    1.388778064,
    1.335244755,
    1.304576095,
    1.284603383,
    1.276116194,
    1.324982675,
    1.365122467,
    1.513408464,
    1.704845055,
    1.805607557,
    1.818205583,
    1.784491956,
    1.679260349,
    1.465126821,
    1.360562832,
    1.303871962,
    1.276994784,
    1.275548145,
    1.285811414,
    1.304981863,
    1.431600036,
    1.567933115,
    1.580222056,
    1.487736924,
    1.440233075,
    1.392966913,
    1.366155415,
    1.33670127,
    1.334904633,
    1.390430793,
    1.425867188,
    1.578163079,
    1.760714278,
    1.864735345,
    1.88022238,
    1.843425988,
    1.737789524,
    1.525558116,
    1.407873276,
    1.142650469,
    1.057782379,
    0.988982575,
    0.97551933,
    1.007688278,
    1.127092483,
    1.300249644,
    1.330155702,
    1.256361113,
    1.205488339,
    1.170083616,
    1.146304389,
    1.088188375,
    1.096433722,
    1.131946167,
    1.191100902,
    1.291867208,
    1.427585315,
    1.476014426,
    1.53558545,
    1.522300038,
    1.411852277,
    1.185228614,
    1.043975727,
    0.963058038,
    0.892896864,
    0.834503519,
    0.820646228,
    0.849164972,
    0.959340778,
    1.140640607,
    1.193956714,
    1.138693864,
    1.084728159,
    1.065070805,
    1.049399757,
    0.994525558,
    1.005781717,
    1.033724551,
    1.071456078,
    1.147304325,
    1.240753505,
    1.248857888,
    1.283868964,
    1.264576644,
    1.192174586,
    1.028716292,
    0.922218357,
    0.871775821,
    0.830720475,
    0.78621761,
    0.772146127,
    0.787049709,
    0.842270892,
    1.008924275,
    1.171843958,
    1.268998278,
    1.303812136,
    1.300414432,
    1.261635844,
    1.196127039,
    1.142568319,
    1.109830886,
    1.113662645,
    1.149603256,
    1.239507591,
    1.243976362,
    1.256027329,
    1.250719147,
    1.17496318,
    1.024719847,
    0.91960095,
    0.857662008,
    0.823536277,
    0.790679982,
    0.772973501,
    0.790541486,
    0.839600482,
    0.995832307,
    1.160590211,
    1.252611703,
    1.303762012,
    1.314975541,
    1.305133838,
    1.274365832,
    1.216288429,
    1.173161809,
    1.19486216,
    1.259097125,
    1.39010821,
    1.42447079,
    1.46588517,
    1.430367184,
    1.291321891,
    1.069135061,
    0.909320265,
    0.79717294,
    0.758260929,
    0.736782833,
    0.736208807,
    0.77310258,
    0.897492657,
    1.101664407,
    1.127125318,
    1.074223349,
    1.042111927,
    1.029696711,
    1.011638851,
    0.980486059,
    0.971457716,
    1.00701968,
    1.067159359,
    1.184808265,
    1.350122671,
    1.392397973,
    1.424597592,
    1.410094105,
    1.268681753,
    1.026733082,
    0.907553664,
    0.914989964,
    0.909806089,
    0.903390728,
    0.913544706,
    0.945567446,
    1.068088686,
    1.2738133,
    1.304051227,
    1.231564207,
    1.177583565,
    1.150822542,
    1.128779866,
    1.099050009,
    1.078188332,
    1.117442897,
    1.185933729,
    1.309007528,
    1.482049071,
    1.545148312,
    1.599818004,
    1.6000168,
    1.467038336,
    1.235923898,
    1.114626395,
    0.967098173,
    0.921570479,
    0.89094613,
    0.886549712,
    0.921096705,
    1.050052221,
    1.239944933,
    1.256933129,
    1.177126157,
    1.124998998,
    1.104182399,
    1.087172488,
    1.047375214,
    1.043364544,
    1.088060141,
    1.149926003,
    1.273810839,
    1.420141805,
    1.471388603,
    1.529528959,
    1.513739867,
    1.378172775,
    1.145403619,
    1.016952488,
    0.897887802,
    0.838323261,
    0.796295225,
    0.788737171,
    0.826432366,
    0.946614827,
    1.129181178,
    1.151514949,
    1.081324086,
    1.035692184,
    1.010615365,
    1.000498113,
    0.962798929,
    0.96813831,
    1.007224839,
    1.064468981,
    1.172485893,
    1.303971517,
    1.347454707,
    1.400637453,
    1.395163724,
    1.273574585,
    1.046759547,
    0.914186504,
    0.878879229,
    0.848753797,
    0.82627842,
    0.82657048,
    0.8615111,
    0.978001319,
    1.170234863,
    1.21885275,
    1.163399951,
    1.108405632,
    1.094015112,
    1.08063877,
    1.040880085,
    1.043470862,
    1.073836485,
    1.111448553,
    1.19813934,
    1.300097579,
    1.32024109,
    1.352447487,
    1.351522256,
    1.270973246,
    1.108827276,
    1.030837876,
    1.030536995,
    1.0370083,
    1.028933687,
    1.035915045,
    1.059145032,
    1.127538174,
    1.298712552,
    1.467071658,
    1.568412976,
    1.602693367,
    1.595259586,
    1.545829307,
    1.482878638,
    1.414364497,
    1.378676279,
    1.378531428,
    1.428700359,
    1.538035569,
    1.559368749,
    1.569501379,
    1.569122557,
    1.491426975,
    1.333177754,
    1.242394453,
    1.09728562,
    1.032724517,
    0.988805968,
    0.976883815,
    0.997198817,
    1.055044826,
    1.187969234,
    1.34672042,
    1.446811835,
    1.495632593,
    1.497329394,
    1.475108701,
    1.437996226,
    1.383167167,
    1.336154155,
    1.355231169,
    1.429893854,
    1.560477672,
    1.597643361,
    1.638581375,
    1.593276243,
    1.454235772,
    1.219384945,
    1.07661546,
    1.023091861,
    0.991855093,
    0.971279216,
    0.976584502,
    1.013000702,
    1.144660111,
    1.332860721,
    1.354403078,
    1.304450044,
    1.26651965,
    1.246450225,
    1.212112107,
    1.171524981,
    1.162678962,
    1.199530813,
    1.253547581,
    1.378865996,
    1.543603827,
    1.605378147,
    1.643176714,
    1.62979358,
    1.488906292,
    1.236959109,
    1.105508066,
    0.98412611,
    0.91336599,
    0.869995773,
    0.868491564,
    0.899653603,
    1.023460431,
    1.201222555,
    1.224461523,
    1.167393352,
    1.117318109,
    1.086081576,
    1.056533812,
    1.020545556,
    1.01337406,
    1.050284583,
    1.112891012,
    1.236355954,
    1.378113602,
    1.425814136,
    1.481117663,
    1.464006606,
    1.324509732,
    1.081541205,
    0.963797136,
    0.924608254,
    0.89596059,
    0.867860901,
    0.866781622,
    0.905092646,
    1.037692267,
    1.219325527,
    1.230137344,
    1.161846432,
    1.112019939,
    1.092015919,
    1.073045564,
    1.034511721,
    1.032718847,
    1.07885859,
    1.137055342,
    1.267544531,
    1.39972338,
    1.451569187,
    1.507363889,
    1.494640706,
    1.35070603,
    1.113081204,
    0.987431961,
    0.88250567,
    0.835244249,
    0.80714963,
    0.807787955,
    0.85016968,
    0.975516473,
    1.154674295,
    1.171624333,
    1.110729018,
    1.06599297,
    1.042200669,
    1.028822745,
    0.994363798,
    0.99837462,
    1.039849477,
    1.093867393,
    1.21077898,
    1.335026511,
    1.385494923,
    1.436571475,
    1.440466926,
    1.309468104,
    1.080649393,
    0.953672724,
    0.902879188,
    0.868593634,
    0.847926598,
    0.851869855,
    0.889606631,
    1.010566397,
    1.192662205,
    1.235707714,
    1.190957484,
    1.137101669,
    1.121708611,
    1.103861496,
    1.062519656,
    1.067447015,
    1.0959709,
    1.132060168,
    1.226028728,
    1.315607603,
    1.338742262,
    1.36971628,
    1.371397748,
    1.284171452,
    1.11597949,
    1.038048701,
    1.025233569,
    1.019579237,
    1.005242545,
    1.013028969,
    1.039719437,
    1.110269764,
    1.267931538,
    1.426536868,
    1.539123006,
    1.575572003,
    1.566926444,
    1.515371225,
    1.451794806,
    1.388281765,
    1.352311631,
    1.351751719,
    1.407477109,
    1.500658041,
    1.522089206,
    1.52860155,
    1.52919901,
    1.444240924,
    1.280259548,
    1.189629675,
    1.064544849,
    1.003201741,
    0.961585998,
    0.952714386,
    0.977126614,
    1.03710398,
    1.162713992,
    1.313381267,
    1.42383862,
    1.475035978,
    1.476816096,
    1.453500959,
    1.41757891,
    1.364783501,
    1.317780554,
    1.336259672,
    1.416307969,
    1.534607994,
    1.575204918,
    1.61106738,
    1.568979925,
    1.421672847,
    1.182293979,
    1.026395765,
    0.908988789,
    0.844851987,
    0.808174145,
    0.80791499,
    0.847212738,
    0.97977867,
    1.154958777,
    1.169942909,
    1.137008676,
    1.106293249,
    1.088307845,
    1.056465449,
    1.018786695,
    1.01926667,
    1.055510618,
    1.105841259,
    1.234423502,
    1.370388238,
    1.413171948,
    1.446321446,
    1.426782705,
    1.273766916,
    1.016406356,
    0.885911867,
    0.83215421,
    0.780792647,
    0.7485873,
    0.752034791,
    0.790193289,
    0.916017259,
    1.091152501,
    1.106924589,
    1.062925836,
    1.017653196,
    0.991422503,
    0.96806468,
    0.939527023,
    0.932589903,
    0.972018967,
    1.032865433,
    1.163491564,
    1.289458256,
    1.333185736,
    1.383414836,
    1.373240323,
    1.223470942,
    0.977393027,
    0.852465316,
    0.772818591,
    0.734780248,
    0.708982159,
    0.710299459,
    0.755739977,
    0.889535714,
    1.065299483,
    1.06793523,
    1.014270874,
    0.970519405,
    0.955916411,
    0.943874079,
    0.913250928,
    0.914271634,
    0.962522282,
    1.018596882,
    1.154821708,
    1.265304408,
    1.307738202,
    1.358863111,
    1.349365878,
    1.193934935,
    0.953209515,
    0.825580177,
    0.734141712,
    0.689398546,
    0.666087089,
    0.670710448,
    0.720377674,
    0.847558229,
    1.021359689,
    1.030671394,
    0.983666211,
    0.94486352,
    0.925810378,
    0.918756589,
    0.891712157,
    0.897714945,
    0.941094486,
    0.992637234,
    1.115014543,
    1.219306151,
    1.262004382,
    1.308846117,
    1.318120307,
    1.176409333,
    0.944496447,
    0.813827634,
    0.758850797,
    0.725015663,
    0.709494558,
    0.717621007,
    0.762765833,
    0.885811634,
    1.06254856,
    1.098025166,
    1.06778717,
    1.019030886,
    1.00942752,
    0.997815021,
    0.963263023,
    0.970400709,
    0.997758249,
    1.035294995,
    1.134024818,
    1.204058851,
    1.219774305,
    1.246366649,
    1.253857741,
    1.156299483,
    0.985969798,
    0.885827636,
    0.790836077,
    0.744101168,
    0.711047927,
    0.710481163,
    0.739035604,
    0.804516146,
    0.945622206,
    1.086823619,
    1.2119461,
    1.252682363,
    1.249540357,
    1.207851379,
    1.150255974,
    1.102540715,
    1.070210128,
    1.071598963,
    1.125388149,
    1.182851751,
    1.189758585,
    1.194432825,
    1.200431281,
    1.103126484,
    0.939609376,
    0.835707525,
    0.769710998,
    0.725926172,
    0.694212921,
    0.685418234,
    0.715435707,
    0.773202175,
    0.898724366,
    1.038751612,
    1.159183691,
    1.217551801,
    1.228376081,
    1.216617592,
    1.188756964,
    1.138039966,
    1.09442137,
    1.113152826,
    1.191322259,
    1.283641152,
    1.320267205,
    1.353411969,
    1.328001596,
    1.167831948,
    0.930265128,
    0.770960547,
    0.668116148,
    0.622579332,
    0.60394595,
    0.61332227,
    0.661070507,
    0.79780506,
    0.97531982,
    0.981487187,
    0.961304677,
    0.938497914,
    0.929231599,
    0.909470181,
    0.882251835,
    0.880406733,
    0.916038164,
    0.962510183,
    1.090704732,
    1.206618973,
    1.236719853,
    1.269600265,
    1.271515696,
    1.111627562,
    0.856586996,
    0.725899927,
    0.667267563,
    0.627424763,
    0.607096538,
    0.619126468,
    0.664858609,
    0.796071855,
    0.971369238,
    0.977201086,
    0.942701294,
    0.903978457,
    0.884291979,
    0.868547482,
    0.846997863,
    0.838730924,
    0.876202598,
    0.933707822,
    1.061417331,
    1.167394963,
    1.203199898,
    1.2561004,
    1.263648901,
    1.109741736,
    0.864397694,
    0.742518229,
    0.669925199,
    0.643396932,
    0.626546106,
    0.636088164,
    0.687204641,
    0.828651218,
    1.004188714,
    0.996556612,
    0.948407334,
    0.910295134,
    0.900742457,
    0.893217353,
    0.864939631,
    0.8669965,
    0.913122632,
    0.967029368,
    1.102086663,
    1.197076025,
    1.236217731,
    1.289364151,
    1.292356052,
    1.133516883,
    0.891339104,
    0.767504142,
    0.674586791,
    0.638592938,
    0.622001162,
    0.634010537,
    0.687858037,
    0.823930438,
    0.997000345,
    0.99578995,
    0.951360076,
    0.917919854,
    0.902431281,
    0.897901662,
    0.869556101,
    0.878381928,
    0.918573216,
    0.969030562,
    1.090377215,
    1.181746228,
    1.223360599,
    1.272123361,
    1.290700263,
    1.147158564,
    0.912347555,
    0.7827713,
    0.707736592,
    0.674506201,
    0.659388104,
    0.672041178,
    0.720464147,
    0.852861893,
    1.026445276,
    1.050467763,
    1.02164948,
    0.978727898,
    0.972465768,
    0.963011068,
    0.924811182,
    0.937993263,
    0.956908959,
    0.997319603,
    1.092861023,
    1.147393297,
    1.158666688,
    1.187762527,
    1.20047644,
    1.102134925,
    0.928907098,
    0.836973184,
    0.795061589,
    0.777995265,
    0.759459673,
    0.771198034,
    0.804797226,
    0.883682783,
    1.03235072,
    1.165863038,
    1.28934614,
    1.334549581,
    1.332578911,
    1.28912979,
    1.228136863,
    1.179778593,
    1.140994837,
    1.145384349,
    1.20032624,
    1.258702268,
    1.274126531,
    1.276269957,
    1.289382541,
    1.192172946,
    1.025459164,
    0.923244451,
    0.825380845,
    0.771743345,
    0.746426475,
    0.749926125,
    0.780497675,
    0.843999607,
    0.953378283,
    1.082012617,
    1.210129038,
    1.268674104,
    1.276326087,
    1.275185523,
    1.248071933,
    1.196716809,
    1.170231002,
    1.186356659,
    1.270416551,
    1.36575544,
    1.389229483,
    1.379956925,
    1.373661517,
    1.213970279,
    0.964194203,
    0.830742166,
    0.742344835,
    0.697975106,
    0.682480683,
    0.700986797,
    0.744595768,
    0.882392297,
    1.040894278,
    1.045735967,
    1.031366365,
    1.005909667,
    0.990702505,
    0.979390603,
    0.948214859,
    0.943743057,
    0.999038983,
    1.045542342,
    1.179713482,
    1.307230997,
    1.333664156,
    1.322921748,
    1.332840556,
    1.169984082,
    0.902719079,
    0.792474017,
    0.725470602,
    0.672486314,
    0.649307598,
    0.667133833,
    0.70900439,
    0.841034423,
    0.994276788,
    0.998560617,
    0.972093355,
    0.932730182,
    0.907665384,
    0.901126055,
    0.874830438,
    0.866672042,
    0.923048886,
    0.979136748,
    1.112228394,
    1.226407945,
    1.251836858,
    1.260813549,
    1.271719587,
    1.112517024,
    0.853952059,
    0.75559024,
    0.743825784,
    0.725197086,
    0.716776599,
    0.738277174,
    0.785823982,
    0.929874479,
    1.088386632,
    1.080774763,
    1.039754749,
    0.999244566,
    0.983944043,
    0.983499949,
    0.951779665,
    0.948732017,
    1.013915781,
    1.064812074,
    1.204780192,
    1.314620271,
    1.350591586,
    1.360436584,
    1.378018636,
    1.217261024,
    0.965487091,
    0.860293072,
    0.740284085,
    0.688016585,
    0.667673022,
    0.685681792,
    0.735036394,
    0.871662275,
    1.021041209,
    1.018358548,
    0.982994824,
    0.948105292,
    0.926451315,
    0.930362825,
    0.896957967,
    0.904522714,
    0.96252877,
    1.00860255,
    1.131884521,
    1.228700033,
    1.257808084,
    1.266040141,
    1.292131035,
    1.147024902,
    0.899344202,
    0.791007452,
    0.750501986,
    0.712383701,
    0.696824012,
    0.717431784,
    0.761943777,
    0.895373743,
    1.047146004,
    1.068947833,
    1.04864913,
    1.00533618,
    0.992893892,
    0.993768418,
    0.95182493,
    0.962876713,
    1.000857209,
    1.037508612,
    1.135526073,
    1.197544421,
    1.198454346,
    1.188086297,
    1.211652323,
    1.110525362,
    0.923486833,
    0.840604781,
    0.757122047,
    0.709842665,
    0.679283433,
    0.693362505,
    0.725559378,
    0.804233914,
    0.925652726,
    1.042086176,
    1.170220788,
    1.214965154,
    1.211387277,
    1.185522024,
    1.124090554,
    1.08173585,
    1.062820636,
    1.065272523,
    1.118468078,
    1.167011272,
    1.159051184,
    1.12666659,
    1.153718346,
    1.055076277,
    0.875126133,
    0.788274335,
    0.718256219,
    0.674927626,
    0.641860538,
    0.636138032,
    0.656107436,
    0.719647564,
    0.849027306,
    0.991340967,
    1.110305661,
    1.162980646,
    1.170786059,
    1.169391642,
    1.122619201,
    1.079544052,
    1.041936868,
    1.063987534,
    1.130912175,
    1.202127629,
    1.228015093,
    1.258244693,
    1.24056989,
    1.10216309,
    0.851950706,
    0.711408554,
    0.626803839,
    0.589740605,
    0.57606461,
    0.591113437,
    0.630087325,
    0.763313562,
    0.939606761,
    0.953109768,
    0.931562981,
    0.902752721,
    0.890557121,
    0.88060822,
    0.838629751,
    0.840306809,
    0.87526702,
    0.935639277,
    1.056942105,
    1.162701545,
    1.189304941,
    1.217807114,
    1.218367069,
    1.075865175,
    0.811614568,
    0.697619888,
    0.641269083,
    0.601707789,
    0.58414113,
    0.599955588,
    0.63644801,
    0.763185641,
    0.936438854,
    0.951544862,
    0.917383129,
    0.872578834,
    0.849553133,
    0.842978799,
    0.806752919,
    0.80322877,
    0.841239537,
    0.911197536,
    1.032668837,
    1.128417567,
    1.158297799,
    1.206980783,
    1.211009595,
    1.075048171,
    0.823789885,
    0.710032473,
    0.636485544,
    0.603797025,
    0.585669064,
    0.597078422,
    0.638191081,
    0.773184821,
    0.944129283,
    0.947141042,
    0.902020949,
    0.857429695,
    0.843700086,
    0.845238724,
    0.803392708,
    0.810051574,
    0.855998911,
    0.919724865,
    1.04488206,
    1.124531494,
    1.151988608,
    1.203739461,
    1.205832477,
    1.067705215,
    0.82114569,
    0.702651278,
    0.619708511,
    0.577822476,
    0.559419515,
    0.572796448,
    0.616763307,
    0.74491729,
    0.912434875,
    0.9222978,
    0.882335932,
    0.84244671,
    0.822863896,
    0.828387553,
    0.789376258,
    0.802452141,
    0.843078938,
    0.901122459,
    1.010895117,
    1.084269302,
    1.10971753,
    1.158354034,
    1.178254816,
    1.056818341,
    0.819469334,
    0.689244943,
    0.633150089,
    0.60682372,
    0.567870468,
    0.583948333,
    0.627282714,
    0.754036353,
    0.901994765,
    0.960652626,
    0.961202614,
    0.914592389,
    0.880053479,
    0.87082924,
    0.84249931,
    0.822202411,
    0.857106926,
    0.873006182,
    0.92985388,
    0.961894717,
    0.973731081,
    0.956743463,
    0.940419429,
    0.893316692,
    0.743630516,
    0.647691712,
    0.636805543,
    0.607967111,
    0.578750248,
    0.584209368,
    0.604047885,
    0.674517987,
    0.814551975,
    0.949960515,
    1.072799046,
    1.108177714,
    1.108250282,
    1.084949726,
    1.011746991,
    0.969711161,
    0.941602252,
    0.941942781,
    0.980602772,
    1.008275151,
    0.998011005,
    1.017824079,
    1.047232288,
    0.975908617,
    0.810476962,
    0.713358174,
    0.652443539,
    0.612739771,
    0.583947192,
    0.580306252,
    0.60190872,
    0.666178289,
    0.793355898,
    0.928997747,
    1.048427736,
    1.101769147,
    1.109259088,
    1.111361391,
    1.070055713,
    1.030223801,
    0.991930305,
    1.008889845,
    1.073585944,
    1.137158524,
    1.156758085,
    1.190439593,
    1.183450695,
    1.048750644,
    0.80517466,
    0.665076751,
    0.575862465,
    0.542016264,
    0.529084127,
    0.544756959,
    0.584801286,
    0.717997824,
    0.8889872,
    0.898982956,
    0.882467075,
    0.856654731,
    0.844048835,
    0.837087275,
    0.802698799,
    0.811754906,
    0.847103229,
    0.902958267,
    1.027128689,
    1.124292942,
    1.139084839,
    1.167301074,
    1.170652169,
    1.030669657,
    0.771886847,
    0.658108708,
    0.602414731,
    0.563520531,
    0.543171072,
    0.557321063,
    0.593435501,
    0.716626688,
    0.885809282,
    0.898129719,
    0.871040109,
    0.832837889,
    0.811574415,
    0.812561045,
    0.788692593,
    0.794101562,
    0.834797482,
    0.901729433,
    1.031193224,
    1.123264011,
    1.141909833,
    1.185762716,
    1.190190864,
    1.057291885,
    0.808145778,
    0.688720366,
    0.615817556,
    0.578958651,
    0.557749965,
    0.568806542,
    0.610083478,
    0.744939512,
    0.911426292,
    0.91115247,
    0.870519971,
    0.828159348,
    0.812720108,
    0.816278738,
    0.781988677,
    0.795996805,
    0.842621802,
    0.903258592,
    1.030800867,
    1.102974118,
    1.120675592,
    1.170025859,
    1.17239606,
    1.03562256,
    0.792792938,
    0.676106658,
    0.619466515,
    0.583504584,
    0.567647922,
    0.582835055,
    0.627690168,
    0.757174044,
    0.923631882,
    0.930295791,
    0.891478593,
    0.852442921,
    0.830690059,
    0.836422037,
    0.804576813,
    0.825409378,
    0.868865586,
    0.927442226,
    1.047366736,
    1.121667817,
    1.144562019,
    1.185521231,
    1.201357702,
    1.077792714,
    0.843300787,
    0.718797337,
    0.650397575,
    0.613753737,
    0.600340142,
    0.615583398,
    0.654756831,
    0.779113779,
    0.944392343,
    0.976348754,
    0.954023397,
    0.905761534,
    0.892257258,
    0.893160543,
    0.853117711,
    0.879015612,
    0.912713287,
    0.953610146,
    1.0491452,
    1.087853084,
    1.079614449,
    1.10245696,
    1.11215018,
    1.03352748,
    0.861027612,
    0.766946024,
    0.687640007,
    0.642162122,
    0.611058806,
    0.616109764,
    0.63642352,
    0.708795923,
    0.845489559,
    0.97658091,
    1.103027106,
    1.140911114,
    1.132429087,
    1.10626411,
    1.040436313,
    1.014075058,
    0.987549134,
    0.990341882,
    1.040655491,
    1.069353146,
    1.055798851,
    1.057747726,
    1.073434696,
    0.998785746,
    0.832858861,
    0.732446419,
    0.671436077,
    0.626619377,
    0.595865786,
    0.591870616,
    0.613808062,
    0.67841973,
    0.800005406,
    0.931323358,
    1.052869939,
    1.107791556,
    1.11220963,
    1.114660124,
    1.077142304,
    1.044836037,
    1.007099405,
    1.02408851,
    1.096055574,
    1.15650252,
    1.171795333,
    1.201274223,
    1.189908518,
    1.055128171,
    0.810135905,
    0.671643089,
    0.586230911,
    0.552550009,
    0.534332704,
    0.546281826,
    0.583581434,
    0.709747907,
    0.878144962,
    0.887015653,
    0.878275962,
    0.862398062,
    0.853477006,
    0.85802755,
    0.839128745,
    0.860136339,
    0.903377645,
    0.963300951,
    1.106107621,
    1.209308212,
    1.21727904,
    1.232094176,
    1.227719787,
    1.088888475,
    0.822998929,
    0.69381005,
    0.627196299,
    0.577836371,
    0.553711459,
    0.567365699,
    0.602670565,
    0.727632515,
    0.891626731,
    0.90153442,
    0.87689356,
    0.837572349,
    0.811156692,
    0.809423387,
    0.788048013,
    0.800228298,
    0.841554768,
    0.9088032,
    1.039140703,
    1.123921394,
    1.13691403,
    1.1773958,
    1.17832358,
    1.04449547,
    0.796225082,
    0.677801485,
    0.630082545,
    0.594165696,
    0.573097827,
    0.585116798,
    0.626498362,
    0.762575411,
    0.925676186,
    0.923981462,
    0.885673849,
    0.843746908,
    0.826028058,
    0.829311711,
    0.799210853,
    0.819701789,
    0.869918562,
    0.931805144,
    1.066558073,
    1.137346736,
    1.152489555,
    1.19439957,
    1.195007165,
    1.057411987,
    0.813511433,
    0.691940547,
    0.613331444,
    0.571178408,
    0.553293977,
    0.568022185,
    0.612777665,
    0.742195196,
    0.900362643,
    0.906864719,
    0.87388259,
    0.836612211,
    0.813768582,
    0.820281271,
    0.791098602,
    0.817146918,
    0.86313528,
    0.920445811,
    1.042485496,
    1.108669213,
    1.12416681,
    1.162313156,
    1.181110633,
    1.059742076,
    0.823707015,
    0.695818442,
    0.638539905,
    0.601125414,
    0.5869366,
    0.602534649,
    0.641889828,
    0.766572145,
    0.924017788,
    0.956888682,
    0.94086382,
    0.89403987,
    0.880527884,
    0.882728578,
    0.845337503,
    0.874583595,
    0.912832736,
    0.952332606,
    1.049590962,
    1.081891556,
    1.069415979,
    1.087485634,
    1.101544456,
    1.024177681,
    0.851525571,
    0.753440348,
    0.67100565,
    0.621418137,
    0.589108054,
    0.594454428,
    0.615218838,
    0.688150039,
    0.815242051,
    0.944966402,
    1.077807978,
    1.116088046,
    1.108998566,
    1.085349809,
    1.021082722,
    0.995913442,
    0.971742974,
    0.971843501,
    1.020543669,
    1.041929321,
    1.025801395,
    1.025410995,
    1.049657685,
    0.977153884,
    0.811250038,
    0.710731332,
    0.659226483,
    0.617135977,
    0.587202038,
    0.584367167,
    0.606930506,
    0.673121596,
    0.787433711,
    0.920674063,
    1.04645869,
    1.102310637,
    1.108186038,
    1.112944407,
    1.076404632,
    1.044632726,
    1.010010139,
    1.026387382,
    1.098100162,
    1.154547555,
    1.171116213,
    1.19528074,
    1.189581467,
    1.055536558,
    0.809049512,
    0.663170465,
    0.576971029,
    0.538944384,
    0.524779791,
    0.541753745,
    0.581811446,
    0.717818924,
    0.877638144,
    0.887808856,
    0.878106214,
    0.854764766,
    0.841285927,
    0.836048218,
    0.805035121,
    0.8227797,
    0.864365648,
    0.920987965,
    1.049291137,
    1.136834784,
    1.145498909,
    1.162984189,
    1.172178179,
    1.03575737,
    0.774578981,
    0.644265605,
    0.565374582,
    0.516366918,
    0.503672685,
    0.51766989,
    0.574744973,
    0.713002455,
    0.875982325,
    0.884408887,
    0.857761599,
    0.809048034,
    0.792137501,
    0.771035447,
    0.760604416,
    0.76556003,
    0.807954292,
    0.885243304,
    1.013619546,
    1.107201023,
    1.135276651,
    1.158419488,
    1.156694024,
    1.03762505,
    0.755015396,
    0.629069329,
    0.558675349,
    0.519715333,
    0.505537365,
    0.514797938,
    0.575517739,
    0.721887337,
    0.881795411,
    0.878058606,
    0.841195435,
    0.79341067,
    0.78637603,
    0.773618918,
    0.755851163,
    0.771277082,
    0.819921633,
    0.89034849,
    1.020607242,
    1.095923417,
    1.121391566,
    1.148882471,
    1.147832322,
    1.028845439,
    0.750646786,
    0.621166413,
    0.55004927,
    0.505547992,
    0.491908933,
    0.503757425,
    0.56596826,
    0.706228785,
    0.863646217,
    0.868096357,
    0.835157275,
    0.79271302,
    0.780734943,
    0.772523386,
    0.755145794,
    0.77564993,
    0.819422069,
    0.886166803,
    1.00081091,
    1.07276345,
    1.098910419,
    1.121026688,
    1.137738476,
    1.036232733,
    0.765712875,
    0.631438973,
    0.561477961,
    0.520559275,
    0.508110788,
    0.519965731,
    0.577406034,
    0.712030099,
    0.867458419,
    0.895560889,
    0.881182575,
    0.83089715,
    0.827866657,
    0.81458039,
    0.790429306,
    0.812514521,
    0.852176113,
    0.910233514,
    0.998669833,
    1.036786011,
    1.02655628,
    1.029920795,
    1.0382643,
    0.982595878,
    0.786693601,
    0.676283547,
    0.613795861,
    0.565742781,
    0.541873951,
    0.54354745,
    0.576192558,
    0.654090144,
    0.760874787,
    0.87933945,
    1.013390544,
    1.049179604,
    1.060051021,
    1.021065637,
    0.970788197,
    0.949370814,
    0.945870435,
    0.960714988,
    1.008288099,
    1.044939535,
    1.040059026,
    1.014952804,
    1.026741018,
    0.975678596,
    0.789574007,
    0.680511098,
    0.617884337,
    0.573357812,
    0.551215476,
    0.54460875,
    0.578474681,
    0.647361049,
    0.739828266,
    0.863090796,
    0.989693076,
    1.040841521,
    1.063975518,
    1.054897116,
    1.035253885,
    1.010817498,
    0.991242896,
    1.025536496,
    1.100305903,
    1.175268518,
    1.20600814,
    1.202337006,
    1.177549035,
    1.065381697,
    0.79227252,
    0.644741663,
    0.540913129,
    0.495104195,
    0.48100671,
    0.492694134,
    0.548567682,
    0.696522805,
    0.853839858,
    0.863120147,
    0.85335521,
    0.826669959,
    0.823454047,
    0.804125467,
    0.782046909,
    0.797318481,
    0.834510112,
    0.901284012,
    1.025622728,
    1.123590015,
    1.143263154,
    1.143814345,
    1.143253277,
    1.026402465,
    0.735497948,
    0.628007003,
    0.607369857,
    0.566466721,
    0.529228873,
    0.527135099,
    0.571458484,
    0.688823258,
    0.861646939,
    0.884263451,
    0.884320202,
    0.879142507,
    0.895104838,
    0.929463014,
    0.9580209,
    0.985232016,
    1.057743334,
    1.157673845,
    1.331657953,
    1.462115907,
    1.480209784,
    1.454255457,
    1.431647443,
    1.315957377,
    0.98722628,
    0.823486673,
    0.680039195,
    0.6094326,
    0.559727436,
    0.553499419,
    0.601248324,
    0.73865132,
    0.897898068,
    0.904423048,
    0.883671209,
    0.8569111,
    0.863933634,
    0.880034076,
    0.877184278,
    0.90329808,
    0.967669551,
    1.055450817,
    1.193402598,
    1.279082741,
    1.30082398,
    1.299331701,
    1.28295435,
    1.162717688,
    0.857548073,
    0.705443283,
    0.636864915,
    0.560999278,
    0.519629949,
    0.525580139,
    0.580808934,
    0.724328847,
    0.878117965,
    0.884599205,
    0.851312888,
    0.810748628,
    0.799269299,
    0.796529891,
    0.774724365,
    0.795252427,
    0.838706034,
    0.907904071,
    1.001426554,
    1.064904114,
    1.091513187,
    1.105660137,
    1.125616179,
    1.029746454,
    0.759265936,
    0.627486919,
    0.579116682,
    0.53507808,
    0.513436052,
    0.525045475,
    0.580599496,
    0.721027111,
    0.873921888,
    0.902656524,
    0.885356248,
    0.837306784,
    0.837313094,
    0.828483343,
    0.798487493,
    0.821866466,
    0.861830138,
    0.92047098,
    0.997786319,
    1.030659317,
    1.021415226,
    1.018961003,
    1.035498091,
    0.987276189,
    0.790263623,
    0.677994822,
    0.603726589,
    0.55609745,
    0.529702377,
    0.531760119,
    0.563003394,
    0.646049253,
    0.749572268,
    0.868429812,
    1.000538668,
    1.038561394,
    1.052186709,
    1.017548912,
    0.962255561,
    0.94250691,
    0.93759273,
    0.953718547,
    0.992676883,
    1.024454278,
    1.019566318,
    0.992605217,
    1.014037614,
    0.970329695,
    0.783655999,
    0.669518271,
    0.59347893,
    0.544023927,
    0.517068201,
    0.510156255,
    0.542205086,
    0.615627497,
    0.702550321,
    0.824618186,
    0.948757492,
    1.00187356,
    1.027677518,
    1.023409501,
    0.997828671,
    0.972792632,
    0.951120449,
    0.982910619,
    1.043372145,
    1.108272209,
    1.135444177,
    1.137097382,
    1.129225413,
    1.026776579,
    0.756146279,
    0.607367585,
    0.522835278,
    0.482067933,
    0.469040435,
    0.483301762,
    0.539512169,
    0.694344103,
    0.851319974,
    0.8602751,
    0.846395488,
    0.821736618,
    0.822253938,
    0.807271613,
    0.780273674,
    0.795797163,
    0.833626687,
    0.901438007,
    1.015946882,
    1.110734265,
    1.13175854,
    1.127773953,
    1.137426046,
    1.028489277,
    0.738698797,
    0.609219827,
    0.54259951,
    0.499601481,
    0.481794844,
    0.496745588,
    0.551306801,
    0.69840098,
    0.852992962,
    0.862238122,
    0.833354159,
    0.792538866,
    0.783618678,
    0.771996724,
    0.750633515,
    0.759668168,
    0.802938153,
    0.885115745,
    0.996510119,
    1.080419297,
    1.107853461,
    1.123355311,
    1.137292566,
    1.033592032,
    0.752449431,
    0.62691254,
    0.545994364,
    0.510582725,
    0.490100456,
    0.499217576,
    0.556309868,
    0.709271242,
    0.860966066,
    0.859302367,
    0.820721443,
    0.78333427,
    0.786227286,
    0.78670925,
    0.761676518,
    0.781863989,
    0.834496977,
    0.912018381,
    1.030109892,
    1.102487006,
    1.129735768,
    1.146082673,
    1.156030212,
    1.050782874,
    0.771582916,
    0.645341241,
    0.561581312,
    0.516470857,
    0.48923283,
    0.49655373,
    0.552312773,
    0.693103184,
    0.844516081,
    0.854605733,
    0.826561479,
    0.802928271,
    0.806506265,
    0.822077795,
    0.806827573,
    0.836495846,
    0.89096938,
    0.97006691,
    1.084295792,
    1.162278762,
    1.188408767,
    1.189176791,
    1.210893079,
    1.119957843,
    0.837724529,
    0.691042835,
    0.598218931,
    0.542343655,
    0.510817504,
    0.517840848,
    0.568606527,
    0.705670088,
    0.855726739,
    0.887363427,
    0.874118967,
    0.83740704,
    0.845508177,
    0.852011292,
    0.827857269,
    0.85612471,
    0.904518857,
    0.967033188,
    1.046554413,
    1.084863186,
    1.076449678,
    1.061050093,
    1.079249605,
    1.038793237,
    0.83369071,
    0.718315633,
    0.629425049,
    0.568376358,
    0.523376642,
    0.519217462,
    0.543497297,
    0.624380744,
    0.724639577,
    0.842518978,
    0.971794975,
    1.016770399,
    1.036657342,
    1.014908243,
    0.965251193,
    0.949463092,
    0.948822188,
    0.968473186,
    1.004816021,
    1.03740915,
    1.031008312,
    0.999056093,
    1.026916858,
    0.99046524,
    0.798598104,
    0.683794949,
    0.613051846,
    0.558001405,
    0.517595944,
    0.50716415,
    0.533777525,
    0.606857703,
    0.691621251,
    0.812374998,
    0.933971839,
    0.991768702,
    1.023413982,
    1.030331552,
    1.009875237,
    0.989144531,
    0.973884821,
    1.01121589,
    1.071662298,
    1.141476698,
    1.168423785,
    1.16243967,
    1.157417353,
    1.058976741,
    0.785687504,
    0.644302341,
    0.558455972,
    0.513762126,
    0.483805659,
    0.490175302,
    0.539859268,
    0.686473705,
    0.841040173,
    0.856069812,
    0.853064919,
    0.846176609,
    0.861790995,
    0.87056371,
    0.856190183,
    0.879744931,
    0.931282518,
    1.012040297,
    1.137737926,
    1.244393808,
    1.266029505,
    1.239658348,
    1.241221007,
    1.133570521,
    0.82664047,
    0.679183142,
    0.591285088,
    0.528331285,
    0.493101732,
    0.503045917,
    0.551780984,
    0.697606427,
    0.848615492,
    0.861436552,
    0.835341358,
    0.800844695,
    0.796586059,
    0.794717182,
    0.77407391,
    0.785066773,
    0.8316172,
    0.917294665,
    1.020580916,
    1.104032484,
    1.132049592,
    1.137980703,
    1.153527514,
    1.056263512,
    0.77225775,
    0.641400981,
    0.57136595,
    0.526160373,
    0.496622461,
    0.505624938,
    0.56132407,
    0.72080092,
    0.869592765,
    0.868908305,
    0.826895153,
    0.788239107,
    0.791359151,
    0.792146498,
    0.759341017,
    0.77840832,
    0.830138798,
    0.907796372,
    1.009796911,
    1.074006471,
    1.102764482,
    1.116675373,
    1.134079427,
    1.035165212,
    0.758061697,
    0.625340017,
    0.543601431,
    0.49745224,
    0.474995662,
    0.488356691,
    0.547336805,
    0.70101852,
    0.847508494,
    0.854629974,
    0.815878573,
    0.782489948,
    0.78113689,
    0.785729674,
    0.753233435,
    0.777452133,
    0.824543727,
    0.897965373,
    0.987921651,
    1.049904577,
    1.079224751,
    1.088584589,
    1.126342555,
    1.044044947,
    0.775118869,
    0.635733369,
    0.554829948,
    0.512161697,
    0.492682743,
    0.506710675,
    0.561764796,
    0.710811654,
    0.855993041,
    0.886940317,
    0.866278493,
    0.823809937,
    0.831504972,
    0.830503959,
    0.790538068,
    0.816208473,
    0.858948542,
    0.9191067,
    0.984737086,
    1.010965614,
    1.004241605,
    0.994763507,
    1.028333772,
    0.993532438,
    0.795746753,
    0.681716427,
    0.585523757,
    0.536647133,
    0.50424877,
    0.505923198,
    0.533836885,
    0.624123084,
    0.719607848,
    0.840572969,
    0.969472204,
    1.01158613,
    1.03072209,
    1.003826625,
    0.940995099,
    0.923589608,
    0.919000624,
    0.937464983,
    0.962319053,
    0.986140118,
    0.981967609,
    0.952573216,
    0.990613621,
    0.958966867,
    0.771642226,
    0.656403209,
    0.576460361,
    0.53319431,
    0.496667316,
    0.484306726,
    0.503072613,
    0.562152402,
    0.62409958,
    0.769538763,
    0.928153898,
    1.008355536,
    1.051070603,
    1.048905612,
    1.014997657,
    0.986773013,
    0.971708197,
    1.001288071,
    1.051827247,
    1.101445738,
    1.150124253,
    1.137972289,
    1.136649756,
    1.035219894,
    0.775984684,
    0.641828481,
    0.594412088,
    0.56748175,
    0.50031908,
    0.474835917,
    0.475772785,
    0.526305484,
    0.636990465,
    0.782711048,
    0.979750101,
    1.106104743,
    1.2164686,
    1.240375023,
    1.25947357,
    1.316086507,
    1.386479874,
    1.426082934,
    1.584160629,
    1.652738686,
    1.746798029,
    1.626026686,
    1.5811597,
    1.424075953,
    1.129243731,
    0.959950064,
    0.693150941,
    0.628453301,
    0.562778096,
    0.556796385,
    0.593110671,
    0.728477548,
    0.883389279,
    0.908615938,
    0.902412693,
    0.888413359,
    0.896849209,
    0.924080476,
    0.921268962,
    0.942198053,
    1.008873517,
    1.109117328,
    1.22185148,
    1.323713575,
    1.347766394,
    1.319632747,
    1.316524118,
    1.213325281,
    0.895249425,
    0.740434181,
    0.665518913,
    0.590940217,
    0.534808107,
    0.535155176,
    0.582433094,
    0.741203822,
    0.885532548,
    0.889821286,
    0.852033558,
    0.814818987,
    0.817428376,
    0.823309385,
    0.788591926,
    0.806593631,
    0.859631428,
    0.9403406,
    1.024405285,
    1.080068633,
    1.108814008,
    1.114181762,
    1.131402693,
    1.03614682,
    0.755779922,
    0.626068985,
    0.56243779,
    0.512614397,
    0.479882877,
    0.490737837,
    0.54640686,
    0.701130016,
    0.843011515,
    0.853549441,
    0.816512531,
    0.785443958,
    0.786284722,
    0.794578143,
    0.758815394,
    0.783172934,
    0.831981795,
    0.908069412,
    0.989648936,
    1.047795347,
    1.078250948,
    1.082903846,
    1.12396251,
    1.045767849,
    0.776125119,
    0.688476973,
    0.751053849,
    0.755829298,
    0.721505605,
    0.721231722,
    0.756126751,
    0.852238706,
    0.981372692,
    1.047308279,
    1.102655767,
    1.164851907,
    1.257064862,
    1.332057854,
    1.386331453,
    1.469214323,
    1.573338519,
    1.669639266,
    1.822155477,
    1.868838682,
    1.82726933,
    1.76030148,
    1.685764711,
    1.615450223,
    1.382664716,
    1.224912329,
    0.999497852,
    0.898747137,
    0.813087437,
    0.786177473,
    0.780025065,
    0.817932224,
    0.90064134,
    1.057174353,
    1.241540048,
    1.362361586,
    1.45674278,
    1.499787543,
    1.525004135,
    1.548316789,
    1.594714018,
    1.634121614,
    1.717231744,
    1.753548296,
    1.715187065,
    1.637787352,
    1.572768672,
    1.5061981,
    1.292684132,
    1.141133322,
    1.003537186,
    0.898662254,
    0.80236255,
    0.770025635,
    0.765161316,
    0.797219535,
    0.861828269,
    1.01179705,
    1.188645055,
    1.304976774,
    1.394915229,
    1.458826506,
    1.503751756,
    1.519777957,
    1.543165045,
    1.597819378,
    1.688900628,
    1.762088557,
    1.757968988,
    1.704766006,
    1.605194276,
    1.484531827,
    1.187735799,
    1.022110851,
    0.955367531,
    0.883921862,
    0.805547102,
    0.789957808,
    0.809573273,
    0.909388633,
    1.032812437,
    1.083904416,
    1.153347033,
    1.240053045,
    1.337867847,
    1.415095806,
    1.481658577,
    1.554206468,
    1.650160417,
    1.75367542,
    1.915826552,
    2.011381023,
    2.001330007,
    1.925997999,
    1.832525184,
    1.692340314,
    1.357123596,
    1.131835265,
    0.855340538,
    0.723485189,
    0.651832579,
    0.647826784,
    0.669682727,
    0.769675361,
    0.88862465,
    0.910170881,
    0.928527058,
    0.915950555,
    0.920589998,
    0.939058177,
    0.940232971,
    0.937964674,
    0.984073613,
    1.065283344,
    1.157860441,
    1.224669499,
    1.229139139,
    1.214628989,
    1.180778462,
    1.100869091,
    0.846703468,
    0.74100739,
    0.798637212,
    0.776435368,
    0.723625226,
    0.716702535,
    0.746162101,
    0.853992851,
    0.974951005,
    1.006362643,
    1.034127867,
    1.078502637,
    1.149042537,
    1.212331194,
    1.248590755,
    1.304926083,
    1.394153751,
    1.494074814,
    1.653985482,
    1.730062826,
    1.726393323,
    1.690828796,
    1.620497705,
    1.501776179,
    1.202584076,
    1.070186054,
    1.031407815,
    0.984872486,
    0.906716439,
    0.886821593,
    0.905911632,
    1.001511827,
    1.122867887,
    1.201152928,
    1.276032475,
    1.403772985,
    1.540187984,
    1.663783515,
    1.757267015,
    1.875837675,
    2.001905856,
    2.117445953,
    2.290450306,
    2.363189446,
    2.331230465,
    2.267642165,
    2.182969819,
    2.041434431,
    1.678151702,
    1.398705491,
    1.003130679,
    0.819397171,
    0.721868637,
    0.706724976,
    0.722682181,
    0.818239098,
    0.93184359,
    0.974975095,
    1.007728233,
    0.988126518,
    1.000272512,
    1.026011192,
    1.016707402,
    1.026102989,
    1.070899525,
    1.136467486,
    1.173723348,
    1.178363907,
    1.148698725,
    1.109015116,
    1.080996176,
    1.060800014,
    0.889046764,
    0.775741137,
    0.78318595,
    0.716436734,
    0.653223283,
    0.640750509,
    0.641629291,
    0.683427542,
    0.752997856,
    0.891315419,
    1.06868516,
    1.135273733,
    1.172370136,
    1.174004133,
    1.140261802,
    1.114136966,
    1.125433475,
    1.138837102,
    1.163194109,
    1.177594133,
    1.14888949,
    1.098024073,
    1.076872861,
    1.057523021,
    0.898719252,
    0.766984361,
    0.664609974,
    0.610926997,
    0.578379799,
    0.571667678,
    0.585375385,
    0.623077977,
    0.682960893,
    0.824860496,
    0.993956201,
    1.079891891,
    1.136150737,
    1.165494182,
    1.160808227,
    1.135572184,
    1.134467494,
    1.165013545,
    1.232604526,
    1.294869592,
    1.301031351,
    1.280791496,
    1.228028319,
    1.147532823,
    0.901814338,
    0.742146324,
    0.614440395,
    0.568247939,
    0.544707411,
    0.557889647,
    0.595577911,
    0.707261101,
    0.825105254,
    0.845934763,
    0.875731757,
    0.894799065,
    0.931095477,
    0.954115401,
    0.954178976,
    0.970256909,
    1.021077097,
    1.093424368,
    1.224934877,
    1.319260345,
    1.32390649,
    1.29380788,
    1.264031876,
    1.17615177,
    0.915589035,
    0.772692206,
    0.657012471,
    0.60608106,
    0.574832618,
    0.586552859,
    0.620933438,
    0.724672357,
    0.843406322,
    0.869036837,
    0.887044384,
    0.891757649,
    0.917967687,
    0.944422771,
    0.951238876,
    0.963086585,
    1.020500279,
    1.108237007,
    1.237925177,
    1.322877837,
    1.33074796,
    1.317399838,
    1.287875177,
    1.202633262,
    0.943025282,
    0.790018871,
    0.653450765,
    0.59688226,
    0.56330325,
    0.571431355,
    0.609882968,
    0.723271125,
    0.839773292,
    0.850501051,
    0.852500474,
    0.847074439,
    0.873760352,
    0.901211948,
    0.893677814,
    0.905473717,
    0.963310484,
    1.038334702,
    1.158057327,
    1.223364944,
    1.232355106,
    1.228449766,
    1.203757556,
    1.125545462,
    0.881308408,
    0.748487828,
    0.646569655,
    0.602725921,
    0.571886865,
    0.581988594,
    0.622614497,
    0.728367072,
    0.843424255,
    0.866644398,
    0.876969209,
    0.888254856,
    0.919961753,
    0.95896177,
    0.961272804,
    0.988890529,
    1.050365677,
    1.128968966,
    1.249973634,
    1.318593441,
    1.327200642,
    1.311359234,
    1.295098338,
    1.221716892,
    0.97318655,
    0.832026127,
    0.743324494,
    0.697760579,
    0.658852189,
    0.662115903,
    0.696490526,
    0.795653072,
    0.914862794,
    0.97155432,
    1.012947502,
    1.041012178,
    1.104238237,
    1.15863691,
    1.18084028,
    1.232767568,
    1.316017956,
    1.4012746,
    1.516963361,
    1.555010796,
    1.525870545,
    1.474436071,
    1.429892208,
    1.384902474,
    1.182230342,
    1.017580789,
    0.797515779,
    0.697569971,
    0.635682794,
    0.62572768,
    0.627962127,
    0.669866852,
    0.738797758,
    0.880887487,
    1.05545362,
    1.128540718,
    1.17412747,
    1.180158513,
    1.151211532,
    1.131036509,
    1.147639698,
    1.163407887,
    1.198757373,
    1.218240851,
    1.191602556,
    1.141094194,
    1.122593284,
    1.100973154,
    0.940135264,
    0.818933561,
    0.740022476,
    0.67929289,
    0.627691442,
    0.613825908,
    0.621464358,
    0.65763848,
    0.714279399,
    0.859809858,
    1.029575831,
    1.121073157,
    1.184271677,
    1.221784724,
    1.226121643,
    1.208404279,
    1.213794766,
    1.249823446,
    1.31922354,
    1.381780533,
    1.387171779,
    1.359448318,
    1.301301643,
    1.213210693,
    0.957554073,
    0.794186099,
    0.66342893,
    0.610311293,
    0.576990535,
    0.585475211,
    0.61982103,
    0.730126495,
    0.846392486,
    0.872340628,
    0.906855117,
    0.931383805,
    0.973909508,
    1.002936245,
    1.007387491,
    1.027183441,
    1.084520304,
    1.162904824,
    1.29589826,
    1.390797017,
    1.397244003,
    1.359067342,
    1.323686699,
    1.229062203,
    0.958272458,
    0.817242623,
    0.723199232,
    0.669520032,
    0.62684263,
    0.632981932,
    0.663348657,
    0.765680721,
    0.88366168,
    0.919874454,
    0.947616086,
    0.9679192,
    1.00967191,
    1.048808362,
    1.067414404,
    1.092034393,
    1.162106126,
    1.259448761,
    1.399334559,
    1.487098934,
    1.493277727,
    1.467722147,
    1.426283296,
    1.328442126,
    1.0493221,
    0.878328072,
    0.703520204,
    0.628599677,
    0.583593143,
    0.587291264,
    0.621924034,
    0.733685097,
    0.847896581,
    0.862942573,
    0.867934605,
    0.862992118,
    0.890641071,
    0.920145437,
    0.912975325,
    0.924108377,
    0.983514886,
    1.058794184,
    1.172056275,
    1.233874775,
    1.243449659,
    1.23648101,
    1.212097635,
    1.134043879,
    0.887623252,
    0.742803385,
    0.63784132,
    0.578589036,
    0.545544302,
    0.556126237,
    0.596577281,
    0.703822641,
    0.817251772,
    0.838682486,
    0.845377397,
    0.841524723,
    0.860810909,
    0.890670376,
    0.880479469,
    0.893343479,
    0.946434117,
    1.015852141,
    1.116457461,
    1.176074578,
    1.186280004,
    1.177353264,
    1.174404922,
    1.115530161,
    0.882009905,
    0.737890877,
    0.627502476,
    0.583822103,
    0.55969619,
    0.571503316,
    0.609854908,
    0.710576578,
    0.824130856,
    0.869435742,
    0.896015884,
    0.890888454,
    0.92652458,
    0.95618193,
    0.948234062,
    0.969715147,
    1.027155972,
    1.093915657,
    1.179869878,
    1.209415037,
    1.187839013,
    1.159850695,
    1.148709834,
    1.130855943,
    0.965432059,
    0.835331379,
    0.69494929,
    0.631249084,
    0.590961512,
    0.587764753,
    0.594911285,
    0.637364634,
    0.707590937,
    0.853579889,
    1.028954713,
    1.103236809,
    1.152632027,
    1.157451596,
    1.125391047,
    1.10448928,
    1.122650194,
    1.136934796,
    1.179164761,
    1.20244769,
    1.177584166,
    1.130678444,
    1.118645482,
    1.099247065,
    0.941236836,
    0.828754059,
    0.74485119,
    0.696172972,
    0.648194952,
    0.634136542,
    0.641839389,
    0.678001188,
    0.73857524,
    0.893227893,
    1.066906931,
    1.171656085,
    1.253472729,
    1.3032261,
    1.322567853,
    1.320160004,
    1.338002933,
    1.383592297,
    1.473365223,
    1.546601319,
    1.551127039,
    1.514804855,
    1.446083757,
    1.343188138,
    1.068744304,
    0.893625145,
    0.752300109,
    0.685714835,
    0.638636759,
    0.640026825,
    0.670078679,
    0.776942439,
    0.89315489,
    0.931113096,
    0.978108315,
    1.021855135,
    1.084059392,
    1.128799975,
    1.151199117,
    1.186465216,
    1.258984897,
    1.346283861,
    1.490369211,
    1.586401357,
    1.590325293,
    1.53907785,
    1.488022738,
    1.379227368,
    1.087471485,
    0.92267773,
    0.760856307,
    0.682897979,
    0.628969258,
    0.630914786,
    0.657498302,
    0.757533327,
    0.872974005,
    0.907490073,
    0.932961359,
    0.939609446,
    0.968386175,
    0.999708051,
    1.009281637,
    1.021102673,
    1.084150905,
    1.176162664,
    1.297980422,
    1.378277032,
    1.387176869,
    1.36543743,
    1.33116698,
    1.240908968,
    0.971024872,
    0.847421768,
    0.837165652,
    0.795915231,
    0.736368267,
    0.727461083,
    0.75485091,
    0.861524629,
    0.978401605,
    1.024834295,
    1.061882538,
    1.118510552,
    1.204701955,
    1.278239941,
    1.320452611,
    1.383894733,
    1.483971685,
    1.587427824,
    1.750830738,
    1.826662809,
    1.823820786,
    1.781374837,
    1.710930649,
    1.5878995,
    1.279319401,
    1.130730289,
    1.016997655,
    0.946553952,
    0.861283026,
    0.840230571,
    0.857916085,
    0.952861321,
    1.068187733,
    1.142020172,
    1.206807601,
    1.303623565,
    1.414508021,
    1.51944476,
    1.590385062,
    1.684471476,
    1.799655408,
    1.909336214,
    2.062877189,
    2.130102748,
    2.111073999,
    2.05080882,
    1.97936584,
    1.851426603,
    1.512370721,
    1.289054193,
    0.987617693,
    0.851107788,
    0.757098454,
    0.737813152,
    0.752517506,
    0.843201691,
    0.955187242,
    1.012491234,
    1.056502958,
    1.059966364,
    1.095839619,
    1.13971427,
    1.148942401,
    1.177216041,
    1.247749947,
    1.327667089,
    1.39316679,
    1.40733636,
    1.380324388,
    1.324921995,
    1.283591252,
    1.246510734,
    1.049299326,
    0.930519766,
    0.908711515,
    0.827402023,
    0.742053541,
    0.718550826,
    0.712411102,
    0.749678934,
    0.822253861,
    0.980582283,
    1.16387664,
    1.260860164,
    1.334026051,
    1.361194196,
    1.359503458,
    1.358857551,
    1.395849906,
    1.426530853,
    1.482501697,
    1.507143127,
    1.476461105,
    1.409016258,
    1.369266946,
    1.32239089,
    1.129204675,
    0.977437861,
    0.793979666,
    0.710049282,
    0.641838688,
    0.621025432,
    0.615972522,
    0.64278611,
    0.688396045,
    0.834505019,
    1.018410378,
    1.128071852,
    1.203936431,
    1.259501352,
    1.27459609,
    1.275980004,
    1.283538603,
    1.338607024,
    1.413072431,
    1.477983661,
    1.476043017,
    1.41786403,
    1.34756009,
    1.262873666,
    1.013201064,
    0.856890762,
    0.760616582,
    0.698993662,
    0.646783614,
    0.637045236,
    0.644771783,
    0.733545739,
    0.82111262,
    0.870131262,
    0.947666389,
    1.015856857,
    1.102317007,
    1.162969423,
    1.19560091,
    1.262890565,
    1.365519272,
    1.459345205,
    1.601831829,
    1.693437725,
    1.678868207,
    1.600190434,
    1.53376391,
    1.427467516,
    1.149000465,
    0.987581719,
    0.817822086,
    0.734216302,
    0.673962865,
    0.658969103,
    0.656970992,
    0.736701871,
    0.816364729,
    0.888194492,
    0.975525577,
    1.030164699,
    1.102070491,
    1.155981508,
    1.180682758,
    1.232920998,
    1.319382159,
    1.420928698,
    1.543594468,
    1.620186855,
    1.593103417,
    1.536382566,
    1.470908655,
    1.372302831,
    1.119807024,
    0.961700938,
    0.840087391,
    0.750832973,
    0.688367661,
    0.658505086,
    0.646394065,
    0.725817693,
    0.779323775,
    0.903981283,
    1.050140576,
    1.132462392,
    1.237093466,
    1.281726093,
    1.284686475,
    1.322545351,
    1.371199437,
    1.456085538,
    1.543314777,
    1.596372043,
    1.536674176,
    1.495913212,
    1.420585028,
    1.31606192,
    1.116449572,
    1.023777284,
    0.775325048,
    0.722029391,
    0.661628725,
    0.644212411,
    0.652468845,
    0.733822527,
    0.813113278,
    0.87808273,
    0.953722052,
    1.013006806,
    1.087871826,
    1.151764188,
    1.169984152,
    1.232296891,
    1.318013613,
    1.407018733,
    1.520209885,
    1.581429473,
    1.555725877,
    1.49914234,
    1.444966254,
    1.362088348,
    1.123084426,
    0.98846636,
    0.839868473,
    0.776334867,
    0.714136663,
    0.696450124,
    0.700677274,
    0.778815173,
    0.866285158,
    0.942961438,
    1.021617534,
    1.075076189,
    1.16236735,
    1.237142905,
    1.268292636,
    1.35181524,
    1.446756398,
    1.531197127,
    1.643141682,
    1.677050944,
    1.633096661,
    1.549177132,
    1.484527003,
    1.43727222,
    1.231326368,
    1.100037963,
    0.864356755,
    0.771503891,
    0.690900538,
    0.667480888,
    0.65538266,
    0.684602171,
    0.747425107,
    0.898030909,
    1.088014404,
    1.188100702,
    1.256261413,
    1.287698394,
    1.280642213,
    1.285216098,
    1.305607169,
    1.344184971,
    1.395992898,
    1.418115556,
    1.381590551,
    1.296275834,
    1.258411981,
    1.228827784,
    1.047210277,
    0.919814961,
    0.79245266,
    0.707800162,
    0.639944893,
    0.617916706,
    0.612521177,
    0.637159941,
    0.682041058,
    0.827756261,
    1.013072848,
    1.117339031,
    1.188261837,
    1.240169342,
    1.252229892,
    1.249115488,
    1.25513401,
    1.306341437,
    1.378782598,
    1.441972694,
    1.438995902,
    1.384430833,
    1.315751362,
    1.232302092,
    0.985470607,
    0.841405308,
    0.753973355,
    0.702550957,
    0.653128901,
    0.644798734,
    0.655326379,
    0.742633802,
    0.835226847,
    0.875180966,
    0.942549151,
    1.009958808,
    1.098455519,
    1.164583885,
    1.20747968,
    1.281395762,
    1.391086339,
    1.492999712,
    1.651857737,
    1.748903943,
    1.742078604,
    1.662276622,
    1.593101486,
    1.481973724,
    1.185739598,
    1.012544351,
    0.78039685,
    0.691645099,
    0.634181625,
    0.625370416,
    0.630513082,
    0.712598109,
    0.802340373,
    0.833567252,
    0.875700624,
    0.892507504,
    0.931778987,
    0.974215524,
    0.99226181,
    1.03104477,
    1.117394047,
    1.217253614,
    1.343617131,
    1.422495085,
    1.420432746,
    1.374297104,
    1.33023245,
    1.243891477,
    0.983282456,
    0.841899373,
    0.719342163,
    0.652646018,
    0.601931937,
    0.593939388,
    0.606875718,
    0.697617852,
    0.785769801,
    0.803676921,
    0.830340869,
    0.841559788,
    0.885995336,
    0.930655426,
    0.937543243,
    0.979214567,
    1.067048953,
    1.155864551,
    1.282069985,
    1.346621939,
    1.345753305,
    1.31041662,
    1.271224225,
    1.18964201,
    0.944007269,
    0.805306112,
    0.659710299,
    0.596523164,
    0.558235173,
    0.55754885,
    0.577565574,
    0.662702881,
    0.750364466,
    0.77437704,
    0.802248051,
    0.816155826,
    0.854059655,
    0.899168687,
    0.905393351,
    0.950330262,
    1.031301246,
    1.113621404,
    1.23048679,
    1.294617422,
    1.29572784,
    1.260997296,
    1.242009476,
    1.178018637,
    0.945607977,
    0.817236263,
    0.705532962,
    0.657949708,
    0.620726955,
    0.616736364,
    0.631874381,
    0.711167683,
    0.802882025,
    0.855692579,
    0.908358466,
    0.930318594,
    0.992282575,
    1.048253265,
    1.065753412,
    1.131222672,
    1.223452026,
    1.299374088,
    1.413179333,
    1.451814099,
    1.42242641,
    1.356622824,
    1.31499796,
    1.281566211,
    1.093256998,
    1.010066026,
    0.955516406,
    0.902137472,
    0.820355807,
    0.789624854,
    0.77473784,
    0.80189553,
    0.877907441,
    1.059523481,
    1.268121284,
    1.434834613,
    1.574918112,
    1.656402498,
    1.711963859,
    1.768037311,
    1.825173686,
    1.882099365,
    1.992873834,
    2.037312453,
    1.98609901,
    1.890295166,
    1.821319532,
    1.746339082,
    1.504560348,
    1.370287625,
    1.193496259,
    1.081288968,
    0.951560012,
    0.902604898,
    0.875750662,
    0.897794007,
    0.956553132,
    1.13488121,
    1.344214342,
    1.526401201,
    1.688659804,
    1.818198763,
    1.924077484,
    2.003398095,
    2.060250676,
    2.144707254,
    2.272208355,
    2.363056795,
    2.332733895,
    2.248880722,
    2.129747073,
    1.983534713,
    1.639884176,
    1.400196628,
    1.105248792,
    0.951335183,
    0.832786716,
    0.793789731,
    0.78096818,
    0.856513746,
    0.948173083,
    0.996617462,
    1.078783715,
    1.145059022,
    1.226680912,
    1.299658502,
    1.354908631,
    1.427674022,
    1.54043379,
    1.64826509,
    1.776067,
    1.854158443,
    1.841761955,
    1.745449555,
    1.652307683,
    1.528660692,
    1.212783784,
    1.062433509,
    0.996325343,
    0.902130397,
    0.806916772,
    0.776580286,
    0.765726608,
    0.839106471,
    0.931237619,
    0.985477024,
    1.055060343,
    1.108884488,
    1.18110553,
    1.253024174,
    1.307766565,
    1.380042781,
    1.493279573,
    1.608379604,
    1.751633612,
    1.830310486,
    1.815761655,
    1.744391584,
    1.659813435,
    1.541746012,
    1.237657279,
    1.09374586,
    0.990987453,
    0.917720005,
    0.831543885,
    0.798598132,
    0.79352435,
    0.874354522,
    0.966430568,
    1.018636701,
    1.086560865,
    1.162074173,
    1.264627603,
    1.35826488,
    1.421616585,
    1.519252595,
    1.652325888,
    1.769199135,
    1.934310777,
    2.005673289,
    1.984381355,
    1.912146225,
    1.816373945,
    1.684893271,
    1.366628905,
    1.207792582,
    1.050091058,
    0.954700294,
    0.857790476,
    0.821729804,
    0.815420432,
    0.887926393,
    0.978620337,
    1.041496664,
    1.118326024,
    1.201971844,
    1.302011218,
    1.403087768,
    1.472337864,
    1.578091047,
    1.709732649,
    1.824769817,
    1.975052804,
    2.041442398,
    2.017474025,
    1.939508521,
    1.855206315,
    1.73466624,
    1.416460344,
    1.215842654,
    0.971434049,
    0.846077576,
    0.756584311,
    0.728425776,
    0.724424484,
    0.794370766,
    0.885329168,
    0.942566881,
    1.005484801,
    1.022646249,
    1.073743315,
    1.131153281,
    1.155356896,
    1.215953993,
    1.307976442,
    1.384256243,
    1.473004557,
    1.497128869,
    1.463050692,
    1.386937252,
    1.327032048,
    1.284387903,
    1.08521883,
    0.956126977,
    0.832099394,
    0.732447821,
    0.657112589,
    0.63633088,
    0.627110195,
    0.649982167,
    0.709286507,
    0.853750302,
    1.045369464,
    1.125762774,
    1.172607854,
    1.187373274,
    1.170748739,
    1.159292811,
    1.169288173,
    1.195199184,
    1.236070724,
    1.25433096,
    1.219194048,
    1.14923079,
    1.115254663,
    1.092819957,
    0.930576984,
    0.81508306,
    0.708395976,
    0.640310727,
    0.59365882,
    0.576722292,
    0.577718893,
    0.596192661,
    0.642817944,
    0.786589251,
    0.974620228,
    1.067167775,
    1.12520558,
    1.162697553,
    1.168137512,
    1.151740582,
    1.150464337,
    1.190449973,
    1.261959894,
    1.323853789,
    1.321579829,
    1.281005133,
    1.215424349,
    1.137462332,
    0.903780078,
    0.746166201,
    0.606690502,
    0.551846067,
    0.528587996,
    0.531718676,
    0.551191129,
    0.636077969,
    0.730451212,
    0.752060253,
    0.799423855,
    0.816630343,
    0.856295454,
    0.880979096,
    0.889030647,
    0.918772384,
    0.990492781,
    1.066651338,
    1.198957731,
    1.290205908,
    1.287762226,
    1.245337917,
    1.207733455,
    1.124877783,
    0.877741177,
    0.774458785,
    0.737248975,
    0.704654373,
    0.668578946,
    0.662026638,
    0.672129072,
    0.747473353,
    0.84513134,
    0.892530146,
    0.953594845,
    1.003454885,
    1.077318983,
    1.138872476,
    1.190498034,
    1.259805396,
    1.37032067,
    1.481397164,
    1.658562829,
    1.755308017,
    1.744383211,
    1.690838404,
    1.615960355,
    1.500423058,
    1.209969284,
    1.080273311,
    0.977965587,
    0.923911711,
    0.851618925,
    0.82111611,
    0.818902967,
    0.896110982,
    0.993066083,
    1.055066687,
    1.135569862,
    1.234449648,
    1.360292207,
    1.468003714,
    1.551157916,
    1.666703921,
    1.813083275,
    1.934674672,
    2.123138852,
    2.200824627,
    2.17117751,
    2.099464539,
    1.993358365,
    1.847491426,
    1.508182871,
    1.361047219,
    1.221769904,
    1.129634281,
    1.010491885,
    0.960541134,
    0.942748468,
    1.006392403,
    1.101347784,
    1.18982243,
    1.2994541,
    1.434584765,
    1.581089134,
    1.719521793,
    1.829752985,
    1.972843686,
    2.131790311,
    2.258167183,
    2.42646462,
    2.491820689,
    2.446218034,
    2.359246037,
    2.251101717,
    2.101914665,
    1.730607591,
    1.509498157,
    1.220568632,
    1.055949594,
    0.923985386,
    0.875025749,
    0.856393099,
    0.916594745,
    1.013277272,
    1.089478024,
    1.176424582,
    1.227375296,
    1.304961474,
    1.389167719,
    1.449247823,
    1.538880673,
    1.656873753,
    1.744640682,
    1.851424291,
    1.876904908,
    1.832224581,
    1.731019691,
    1.628555695,
    1.554966594,
    1.314693985,
    1.202909803,
    1.13720603,
    1.030092233,
    0.90376667,
    0.854990469,
    0.82909105,
    0.844433394,
    0.916349631,
    1.084854313,
    1.292923636,
    1.429092757,
    1.532625206,
    1.594088085,
    1.641814916,
    1.677741852,
    1.721699075,
    1.774852574,
    1.861071123,
    1.892677754,
    1.843266156,
    1.740064986,
    1.65164673,
    1.577038139,
    1.348609675,
    1.21085651,
    1.082601912,
    0.970223019,
    0.860876228,
    0.816356492,
    0.797399403,
    0.809233102,
    0.867030834,
    1.030682659,
    1.234644263,
    1.377932358,
    1.494220918,
    1.584116418,
    1.661629981,
    1.706913256,
    1.742613583,
    1.81577984,
    1.934397627,
    2.018728083,
    2.000826313,
    1.918509581,
    1.791719868,
    1.658149206,
    1.351574798,
    1.147093889,
    0.944453099,
    0.831934476,
    0.751297071,
    0.72420876,
    0.722707651,
    0.794027112,
    0.892595783,
    0.932140822,
    1.007491406,
    1.057962492,
    1.126266201,
    1.181476358,
    1.231545313,
    1.29218588,
    1.394186706,
    1.494649691,
    1.640336549,
    1.73044929,
    1.719655708,
    1.642401216,
    1.551815754,
    1.428244317,
    1.128128893,
    0.966948412,
    0.824696508,
    0.731489075,
    0.669434555,
    0.654303515,
    0.657578254,
    0.727291799,
    0.824475031,
    0.854749057,
    0.902688223,
    0.908792641,
    0.936462384,
    0.968382297,
    0.996330124,
    1.02757792,
    1.109255395,
    1.205358285,
    1.338847712,
    1.421026391,
    1.416082561,
    1.379824028,
    1.316789753,
    1.21633092,
    0.955207861,
    0.86656394,
    0.962429325,
    0.927720369,
    0.837489397,
    0.80638064,
    0.817289048,
    0.932038208,
    1.029421774,
    1.113386246,
    1.193841404,
    1.292909571,
    1.432954695,
    1.558643946,
    1.640989936,
    1.765112399,
    1.929757443,
    2.065834856,
    2.235536294,
    2.293861883,
    2.280724123,
    2.191041183,
    2.133560614,
    1.950992673,
    1.546559847,
    1.376971522,
    1.178204349,
    1.074410041,
    0.947387314,
    0.901326383,
    0.901980788,
    1.005655816,
    1.103121095,
    1.206926082,
    1.30827243,
    1.429532233,
    1.577915978,
    1.723539764,
    1.824074015,
    1.965725603,
    2.135052538,
    2.272775692,
    2.418743953,
    2.464833551,
    2.438098263,
    2.332229103,
    2.281049081,
    2.102770776,
    1.680777704,
    1.442314673,
    1.123289847,
    0.952271008,
    0.81789647,
    0.776771203,
    0.776866322,
    0.875018605,
    0.970433543,
    1.054188529,
    1.128750334,
    1.149346757,
    1.213680441,
    1.291460592,
    1.325255456,
    1.393550528,
    1.511766591,
    1.5968163,
    1.663633131,
    1.655307075,
    1.61935529,
    1.516203002,
    1.486766249,
    1.410104332,
    1.154530392,
    1.019152653,
    0.918962646,
    0.805176035,
    0.697343885,
    0.664681063,
    0.655923407,
    0.694572137,
    0.731970772,
    0.897981109,
    1.112061182,
    1.201700059,
    1.272290418,
    1.304387693,
    1.295536169,
    1.294036473,
    1.322403585,
    1.354436243,
    1.380986448,
    1.377265472,
    1.344311912,
    1.252789004,
    1.252792959,
    1.19778433,
    0.988393544,
    0.864064309,
    0.766474338,
    0.687227968,
    0.617051115,
    0.590618327,
    0.593566229,
    0.624668023,
    0.649147433,
    0.814508425,
    1.017977257,
    1.121753842,
    1.20304166,
    1.265419657,
    1.284948769,
    1.281531174,
    1.300118508,
    1.348958084,
    1.414872322,
    1.463949441,
    1.466556712,
    1.409061769,
    1.378709687,
    1.263117371,
    0.974560447,
    0.858801942,
    0.851999395,
    0.820809119,
    0.75529688,
    0.734885491,
    0.754889078,
    0.864757634,
    0.969274969,
    1.050207282,
    1.150160719,
    1.255817539,
    1.394196124,
    1.502967611,
    1.59327491,
    1.703969181,
    1.856828849,
    1.98353252,
    2.161029527,
    2.247479454,
    2.237672522,
    2.125448758,
    2.060960437,
    1.877084436,
    1.476890726,
    1.280239575,
    1.044050762,
    0.925660597,
    0.822466478,
    0.789856059,
    0.794150285,
    0.89577994,
    0.997794005,
    1.078230009,
    1.157912104,
    1.220643537,
    1.314312722,
    1.406095328,
    1.479379909,
    1.56696993,
    1.704354546,
    1.836611897,
    1.984364568,
    2.051704401,
    2.043506446,
    1.954088174,
    1.89584242,
    1.727242046,
    1.352221577,
    1.175988199,
    0.99886319,
    0.88996118,
    0.784294918,
    0.748797541,
    0.758381796,
    0.86828559,
    0.966456415,
    1.020183387,
    1.073845939,
    1.105722686,
    1.178005149,
    1.257123448,
    1.301453162,
    1.371563869,
    1.497183969,
    1.612496428,
    1.737834773,
    1.782065684,
    1.781138736,
    1.709649329,
    1.663706794,
    1.511665545,
    1.168792832,
    0.996870636,
    0.842763997,
    0.741329261,
    0.662081999,
    0.64247305,
    0.664300984,
    0.769495363,
    0.866163997,
    0.913602542,
    0.95389137,
    0.966121558,
    1.010534892,
    1.073037631,
    1.097202202,
    1.150450944,
    1.252517032,
    1.349245485,
    1.451798274,
    1.494325324,
    1.500402168,
    1.447232476,
    1.440073918,
    1.324970866,
    1.024121102,
    0.883511526,
    0.806794949,
    0.742546685,
    0.679160932,
    0.663518168,
    0.686737181,
    0.786679187,
    0.889374323,
    0.964410454,
    1.027365988,
    1.048856219,
    1.119625406,
    1.189804139,
    1.224802386,
    1.297874416,
    1.415583502,
    1.494531621,
    1.603646342,
    1.62033485,
    1.587338512,
    1.50534589,
    1.480274498,
    1.402533066,
    1.160741807,
    1.008239146,
    0.806150044,
    0.700917492,
    0.62567341,
    0.604883522,
    0.606642012,
    0.643596598,
    0.692341024,
    0.851676579,
    1.060274307,
    1.145282606,
    1.206749095,
    1.232155621,
    1.218732274,
    1.212282872,
    1.23454994,
    1.260372835,
    1.297656359,
    1.303953819,
    1.271420417,
    1.194948334,
    1.195570224,
    1.144173908,
    0.948339759,
    0.831351321,
    0.731952805,
    0.661799666,
    0.601577998,
    0.577445483,
    0.583160325,
    0.61270482,
    0.648880027,
    0.804459812,
    1.002935901,
    1.10402552,
    1.173116575,
    1.227311183,
    1.243190809,
    1.234427315,
    1.245361247,
    1.289856411,
    1.354188678,
    1.405363423,
    1.40671982,
    1.357932008,
    1.323319987,
    1.210699643,
    0.933366748,
    0.780366437,
    0.657059551,
    0.605696688,
    0.568559876,
    0.56504339,
    0.595473024,
    0.708989161,
    0.817155736,
    0.856327474,
    0.908636771,
    0.934772635,
    0.99157308,
    1.036984548,
    1.062016438,
    1.107708961,
    1.200544378,
    1.292213487,
    1.428383759,
    1.511638161,
    1.510697216,
    1.450116667,
    1.427802282,
    1.296414849,
    0.985735575,
    0.855183562,
    0.773395391,
    0.721171256,
    0.668119983,
    0.657985468,
    0.680696212,
    0.785978908,
    0.897667483,
    0.952106409,
    1.003022279,
    1.037168613,
    1.104332268,
    1.169766972,
    1.220181978,
    1.285207701,
    1.399612129,
    1.516668031,
    1.676179444,
    1.757306868,
    1.75165344,
    1.694722911,
    1.654057008,
    1.505790232,
    1.17184712,
    1.018355272,
    0.866636034,
    0.797005306,
    0.72588794,
    0.703591003,
    0.724900857,
    0.837479556,
    0.948946087,
    0.993697663,
    1.035737952,
    1.074776869,
    1.154881498,
    1.233772948,
    1.283275825,
    1.363326103,
    1.487453415,
    1.601822139,
    1.760114389,
    1.823567877,
    1.816251213,
    1.756168011,
    1.703226274,
    1.547522415,
    1.210122812,
    1.040304025,
    0.846204006,
    0.754790893,
    0.68110109,
    0.662103816,
    0.685733852,
    0.791112773,
    0.900718937,
    0.94201322,
    0.97659751,
    0.99655193,
    1.045918346,
    1.111959874,
    1.145178675,
    1.209638666,
    1.312232462,
    1.413235975,
    1.536795634,
    1.591880558,
    1.589789692,
    1.538679395,
    1.5144731,
    1.388633926,
    1.082163869,
    0.926284039,
    0.808871501,
    0.735072979,
    0.67188808,
    0.657056173,
    0.680863551,
    0.780990184,
    0.894864959,
    0.955747216,
    1.004642112,
    1.013987438,
    1.070092455,
    1.129896317,
    1.158685314,
    1.224773766,
    1.324624981,
    1.401239579,
    1.503897115,
    1.525239369,
    1.492081595,
    1.423318956,
    1.393254433,
    1.319322285,
    1.090276791,
    0.966649599,
    0.818932566,
    0.73864609,
    0.663674246,
    0.640950825,
    0.642815519,
    0.678997636,
    0.749215108,
    0.907240676,
    1.106636755,
    1.208199884,
    1.278163819,
    1.310637117,
    1.313509789,
    1.322593607,
    1.350705642,
    1.387198116,
    1.44634137,
    1.467019136,
    1.43039686,
    1.350267607,
    1.329504872,
    1.260763168,
    1.048613817,
    0.908313619,
    0.765528254,
    0.677535383,
    0.612597216,
    0.588448813,
    0.594401435,
    0.623494987,
    0.671463321,
    0.818403353,
    1.009387128,
    1.11116046,
    1.178034255,
    1.230498117,
    1.250233956,
    1.245478411,
    1.251943953,
    1.298169243,
    1.367203666,
    1.425918437,
    1.424556754,
    1.380211514,
    1.334269848,
    1.218583071,
    0.944552703,
    0.778846665,
    0.64041615,
    0.580112927,
    0.545639141,
    0.544581204,
    0.576493996,
    0.692939485,
    0.813788314,
    0.839587162,
    0.877421741,
    0.888177072,
    0.92645844,
    0.958367799,
    0.973210166,
    1.008645395,
    1.083135283,
    1.16287922,
    1.28674598,
    1.369616378,
    1.365288038,
    1.322170422,
    1.300602417,
    1.180000685,
    0.890599429,
    0.75129372,
    0.64205018,
    0.589925291,
    0.558035661,
    0.55927122,
    0.590099238,
    0.698566121,
    0.820300225,
    0.846745906,
    0.868079532,
    0.86280526,
    0.889747067,
    0.923481031,
    0.944224319,
    0.975691218,
    1.054273508,
    1.146075811,
    1.276965503,
    1.354053374,
    1.352235255,
    1.33154816,
    1.312566866,
    1.195801729,
    0.914891532,
    0.778298303,
    0.653572184,
    0.610556385,
    0.577782991,
    0.573650721,
    0.608158271,
    0.724062222,
    0.845162979,
    0.860361094,
    0.869845573,
    0.870527357,
    0.912820463,
    0.958746371,
    0.977853959,
    1.0249773,
    1.113082692,
    1.200521482,
    1.340298786,
    1.40541383,
    1.402143343,
    1.382790364,
    1.356764809,
    1.23400443,
    0.953588353,
    0.844029587,
    0.793046768,
    0.765014762,
    0.717249545,
    0.702339943,
    0.731872961,
    0.838452894,
    0.962984996,
    1.012533549,
    1.054719458,
    1.114138486,
    1.202457927,
    1.292400129,
    1.35638373,
    1.458092124,
    1.580373409,
    1.692916279,
    1.864115489,
    1.940370832,
    1.926748377,
    1.864842756,
    1.813682587,
    1.663056754,
    1.326811868,
    1.133032574,
    0.886326895,
    0.791739705,
    0.723057993,
    0.702351954,
    0.722619085,
    0.821889471,
    0.945739177,
    1.005664502,
    1.054048558,
    1.071026695,
    1.132441963,
    1.197621808,
    1.235175843,
    1.308230914,
    1.405525359,
    1.486115056,
    1.591830123,
    1.617466164,
    1.580539669,
    1.509660831,
    1.465236147,
    1.381722835,
    1.141646492,
    1.004582375,
    0.850882746,
    0.7532418,
    0.670560404,
    0.645714478,
    0.645552766,
    0.681323982,
    0.753490888,
    0.903216414,
    1.098542133,
    1.190253342,
    1.248001201,
    1.272957276,
    1.267563372,
    1.265674104,
    1.283363065,
    1.314545022,
    1.35699379,
    1.374278598,
    1.338184714,
    1.266253872,
    1.24647737,
    1.183594707,
    0.980442923,
    0.871628071,
    0.779980697,
    0.718950778,
    0.653715246,
    0.627169464,
    0.632077911,
    0.660805161,
    0.71942337,
    0.869398056,
    1.059730681,
    1.172193486,
    1.254795287,
    1.317176373,
    1.351539433,
    1.358976258,
    1.374228851,
    1.432348037,
    1.518290673,
    1.591017343,
    1.589084657,
    1.535941557,
    1.474690768,
    1.339103262,
    1.046667339,
    0.875786869,
    0.720055513,
    0.655714662,
    0.610244702,
    0.603563399,
    0.633517686,
    0.746543208,
    0.871997879,
    0.902542057,
    0.94649494,
    0.975112105,
    1.033898918,
    1.079815317,
    1.110658395,
    1.157743625,
    1.246655309,
    1.33819284,
    1.47998969,
    1.575137017,
    1.575470301,
    1.519273714,
    1.478316738,
    1.332359713,
    1.018920982,
    0.895728927,
    0.85170823,
    0.802456066,
    0.73998586,
    0.724444246,
    0.744767148,
    0.849305881,
    0.9780576,
    1.034638814,
    1.086897614,
    1.140538344,
    1.226962747,
    1.304603836,
    1.372136149,
    1.451818529,
    1.571994277,
    1.691260232,
    1.863836873,
    1.957715591,
    1.946936616,
    1.88560258,
    1.822339481,
    1.653993213,
    1.304407813,
    1.151737003,
    0.995452582,
    0.924000861,
    0.837112029,
    0.804223575,
    0.819375579,
    0.929543667,
    1.056318988,
    1.110344164,
    1.161352199,
    1.229136447,
    1.3369673,
    1.435639314,
    1.508801245,
    1.609371969,
    1.745564042,
    1.866414095,
    2.040374559,
    2.11519065,
    2.098156812,
    2.025295431,
    1.945447611,
    1.76331337,
    1.398543315,
    1.23999199,
    1.090327616,
    0.998503858,
    0.891666791,
    0.851961034,
    0.861972896,
    0.962840206,
    1.087723592,
    1.157015342,
    1.22172649,
    1.302356088,
    1.410841663,
    1.521711328,
    1.603201974,
    1.713959047,
    1.850354485,
    1.970149305,
    2.122382097,
    2.188397381,
    2.167200123,
    2.08517819,
    2.017605602,
    1.845296464,
    1.474117995,
    1.280646371,
    1.073122508,
    0.953813896,
    0.846458378,
    0.80925862,
    0.817688851,
    0.913268366,
    1.039594202,
    1.116609084,
    1.182685974,
    1.221621178,
    1.303969555,
    1.388539129,
    1.443376871,
    1.526448114,
    1.638481142,
    1.727368894,
    1.829618917,
    1.851834095,
    1.811895624,
    1.72041759,
    1.659656868,
    1.555373456,
    1.281921699,
    1.111820254,
    0.889855916,
    0.766664825,
    0.683264457,
    0.655497299,
    0.667128816,
    0.737155971,
    0.843459079,
    0.961194152,
    1.107362871,
    1.156500351,
    1.155141165,
    1.147028652,
    1.118763689,
    1.100940383,
    1.082618411,
    1.107285337,
    1.148609838,
    1.168155032,
    1.143921727,
    1.129934791,
    1.070212948,
    0.981337981,
    0.827813203,
    0.721800381,
    0.712755601,
    0.668585661,
    0.617975413,
    0.587741663,
    0.598620154,
    0.644282034,
    0.720151995,
    0.876867412,
    1.044655798,
    1.136953585,
    1.171769052,
    1.189053317,
    1.176277047,
    1.146894417,
    1.123445359,
    1.151209643,
    1.211526173,
    1.273027619,
    1.290965989,
    1.312587841,
    1.234296696,
    1.066742093,
    0.841744085,
    0.683968437,
    0.679847738,
    0.664671264,
    0.620243317,
    0.601112381,
    0.611049255,
    0.684401228,
    0.780256453,
    0.970318317,
    1.126573539,
    1.246128347,
    1.334687042,
    1.35411264,
    1.301861963,
    1.274196501,
    1.294760773,
    1.296628974,
    1.347323618,
    1.409277279,
    1.409986334,
    1.390949252,
    1.346694199,
    1.105479825,
    0.844005017,
    0.751116266,
    0.57546281,
    0.556599448,
    0.537019252,
    0.540351671,
    0.585714559,
    0.716814822,
    0.859095094,
    0.856446379,
    0.837490301,
    0.807176813,
    0.792872727,
    0.801415609,
    0.807226346,
    0.830664859,
    0.879998652,
    0.966580258,
    1.114017938,
    1.193865751,
    1.211321298,
    1.245926946,
    1.187482326,
    1.038802887,
    0.806940878,
    0.680550455,
    0.597571319,
    0.568329812,
    0.548742629,
    0.546063172,
    0.59343599,
    0.734722833,
    0.873392066,
    0.858184935,
    0.829815705,
    0.800729402,
    0.797652898,
    0.813856802,
    0.811790141,
    0.843777466,
    0.901647871,
    0.986147007,
    1.139245995,
    1.217264921,
    1.231790493,
    1.269073998,
    1.20723527,
    1.053950096,
    0.821817893,
    0.694894811,
    0.602205822,
    0.564648621,
    0.544236227,
    0.543296939,
    0.592611119,
    0.725724618,
    0.861618813,
    0.856374754,
    0.834963781,
    0.814983136,
    0.811867107,
    0.836166016,
    0.839330667,
    0.879807807,
    0.936023802,
    1.01829621,
    1.160007055,
    1.236171348,
    1.250254416,
    1.279124865,
    1.235039949,
    1.094589036,
    0.86573877,
    0.726596432,
    0.629724523,
    0.58642166,
    0.56536139,
    0.564128089,
    0.60877944,
    0.737348733,
    0.875525901,
    0.894195198,
    0.887938654,
    0.852555307,
    0.853415722,
    0.86950236,
    0.862285336,
    0.900242334,
    0.944528366,
    1.010295636,
    1.1175525,
    1.157978128,
    1.145313919,
    1.158127257,
    1.108284407,
    1.017869427,
    0.859642517,
    0.756898049,
    0.664472693,
    0.617627102,
    0.581048802,
    0.570161358,
    0.595618248,
    0.669747104,
    0.776546746,
    0.899011251,
    1.042560622,
    1.097227177,
    1.107370811,
    1.095167632,
    1.06179997,
    1.042670352,
    1.028477158,
    1.052311929,
    1.111303959,
    1.146260123,
    1.130588114,
    1.126815923,
    1.083263395,
    0.991757854,
    0.838942614,
    0.731606627,
    0.660299198,
    0.615449797,
    0.582417106,
    0.564766269,
    0.593873439,
    0.660748251,
    0.75379878,
    0.879479086,
    1.019970697,
    1.089037592,
    1.11215502,
    1.126865899,
    1.12051226,
    1.093320277,
    1.068211612,
    1.108166025,
    1.190474186,
    1.264093662,
    1.282186554,
    1.308380634,
    1.229678465,
    1.07086407,
    0.838256152,
    0.685333843,
    0.579067515,
    0.539076656,
    0.523542829,
    0.525993395,
    0.571974132,
    0.711730103,
    0.855642581,
    0.85300257,
    0.851491647,
    0.838025445,
    0.838783407,
    0.842951587,
    0.837986697,
    0.858376783,
    0.905476943,
    0.981757668,
    1.124297196,
    1.22646379,
    1.242261438,
    1.260159275,
    1.202773194,
    1.044288447,
    0.795651384,
    0.71317594,
    0.747727935,
    0.746162733,
    0.715653918,
    0.704799322,
    0.73850259,
    0.86967997,
    1.00687059,
    1.029961911,
    1.055721667,
    1.089909638,
    1.154400559,
    1.225343842,
    1.282463403,
    1.364260605,
    1.474614936,
    1.607114583,
    1.814128346,
    1.915624721,
    1.892658132,
    1.865392773,
    1.790439635,
    1.59056991,
    1.266608793,
    1.060904586,
    0.785544091,
    0.682701405,
    0.630256821,
    0.613689641,
    0.648490279,
    0.783760401,
    0.921964798,
    0.913888388,
    0.894584315,
    0.864878905,
    0.863633857,
    0.884735946,
    0.882877047,
    0.905220304,
    0.965593547,
    1.052068577,
    1.179866185,
    1.249516944,
    1.266624747,
    1.294881813,
    1.231736463,
    1.070870236,
    0.825781673,
    0.711357503,
    0.684250983,
    0.638822275,
    0.599513829,
    0.590164299,
    0.632276597,
    0.762303417,
    0.897582059,
    0.897780248,
    0.88365635,
    0.864107327,
    0.865152614,
    0.894158196,
    0.896646758,
    0.929328482,
    0.989699616,
    1.076366105,
    1.204684353,
    1.277754374,
    1.295086952,
    1.316416072,
    1.271884465,
    1.122577587,
    0.880351907,
    0.773995949,
    0.771602516,
    0.749005616,
    0.714223278,
    0.700951946,
    0.738567815,
    0.86554384,
    1.003440016,
    1.042824627,
    1.070766533,
    1.087089058,
    1.150210821,
    1.220935854,
    1.258432526,
    1.34278208,
    1.442907645,
    1.541783782,
    1.696160626,
    1.743380022,
    1.701608937,
    1.661095122,
    1.596176923,
    1.46472643,
    1.231161747,
    1.092240563,
    0.863927813,
    0.771131458,
    0.699226832,
    0.672991333,
    0.687272913,
    0.758166896,
    0.864315637,
    0.990035873,
    1.140092495,
    1.204952672,
    1.238274253,
    1.249378044,
    1.23388331,
    1.222401477,
    1.225069402,
    1.266583924,
    1.328867666,
    1.360338304,
    1.333826769,
    1.305059509,
    1.257919684,
    1.145205713,
    0.958252325,
    0.847861891,
    0.790009451,
    0.717682982,
    0.653978725,
    0.627542322,
    0.648585242,
    0.712348089,
    0.801711682,
    0.925311946,
    1.067290942,
    1.138547084,
    1.176428618,
    1.207108694,
    1.212744021,
    1.191271536,
    1.178289986,
    1.231208545,
    1.31402494,
    1.389565956,
    1.399871651,
    1.40737001,
    1.329621289,
    1.155164373,
    0.899501718,
    0.755157407,
    0.662302608,
    0.616171868,
    0.583943265,
    0.580102421,
    0.621351358,
    0.756668439,
    0.895896807,
    0.89759289,
    0.90809902,
    0.90659204,
    0.927240217,
    0.949569183,
    0.955222968,
    0.979810741,
    1.042389014,
    1.135465178,
    1.286202742,
    1.393713847,
    1.408347699,
    1.404249989,
    1.34368517,
    1.16632967,
    0.890310813,
    0.772029582,
    0.711492567,
    0.666669431,
    0.629326108,
    0.623613036,
    0.660765441,
    0.791046934,
    0.928823273,
    0.938247839,
    0.94232259,
    0.932652199,
    0.950490189,
    0.983327991,
    0.99999164,
    1.026802658,
    1.100617697,
    1.212515144,
    1.373193306,
    1.473614653,
    1.485479411,
    1.492938635,
    1.432626471,
    1.253076399,
    0.971500348,
    0.820450291,
    0.683182698,
    0.614625262,
    0.575393367,
    0.568567546,
    0.611998603,
    0.750142525,
    0.889364022,
    0.881821527,
    0.859600078,
    0.826432116,
    0.826339755,
    0.842458419,
    0.830965,
    0.839356456,
    0.897492497,
    0.982096191,
    1.112049342,
    1.189550829,
    1.218759227,
    1.25628575,
    1.201864837,
    1.039290865,
    0.797407241,
    0.682639652,
    0.614665213,
    0.572035876,
    0.544989404,
    0.544237279,
    0.593756537,
    0.725767889,
    0.862591652,
    0.863241155,
    0.845118515,
    0.819100419,
    0.816952141,
    0.837825194,
    0.82825175,
    0.843285361,
    0.897911531,
    0.978866938,
    1.101803417,
    1.179435842,
    1.210152086,
    1.243870512,
    1.209454304,
    1.061626119,
    0.826906895,
    0.72928288,
    0.712206343,
    0.695515564,
    0.668883427,
    0.661568305,
    0.703951007,
    0.831305638,
    0.969187776,
    1.006730732,
    1.029440462,
    1.033514045,
    1.087252096,
    1.146643252,
    1.168044197,
    1.229524067,
    1.320721276,
    1.412983575,
    1.554922469,
    1.604614037,
    1.577101413,
    1.550167913,
    1.497134374,
    1.368985012,
    1.147030408,
    0.995175053,
    0.784103407,
    0.680721651,
    0.621380199,
    0.604281842,
    0.625695855,
    0.696928568,
    0.802531899,
    0.933370543,
    1.083420908,
    1.133895636,
    1.145488275,
    1.129990145,
    1.088343755,
    1.046900369,
    1.031629297,
    1.05233567,
    1.086028453,
    1.11566601,
    1.111354093,
    1.108528614,
    1.071611665,
    0.971758988,
    0.811566913,
    0.72371454,
    0.68536123,
    0.640429629,
    0.596837603,
    0.576640024,
    0.604290733,
    0.668963137,
    0.760001485,
    0.890768067,
    1.034042391,
    1.099013111,
    1.127023697,
    1.140564818,
    1.12766955,
    1.082218863,
    1.058630792,
    1.098401805,
    1.164475824,
    1.237693352,
    1.263050176,
    1.288473223,
    1.221243889,
    1.053748997,
    0.811409626,
    0.674168716,
    0.581663848,
    0.54675146,
    0.530357768,
    0.533111547,
    0.579180138,
    0.715011079,
    0.856024238,
    0.860317519,
    0.866166099,
    0.856917226,
    0.872168543,
    0.884051653,
    0.876446849,
    0.882375782,
    0.93583558,
    1.016597391,
    1.154629285,
    1.263382457,
    1.289739723,
    1.303002993,
    1.256082852,
    1.085671345,
    0.822876305,
    0.727354488,
    0.699605209,
    0.673864606,
    0.643645707,
    0.637648052,
    0.675031048,
    0.80482819,
    0.944767674,
    0.962449148,
    0.972384063,
    0.972833503,
    1.006822079,
    1.049926976,
    1.071786048,
    1.100637768,
    1.185580028,
    1.30154302,
    1.469189981,
    1.57613311,
    1.585850686,
    1.586787787,
    1.529703082,
    1.341647434,
    1.046102387,
    0.885855508,
    0.712820088,
    0.6365695,
    0.594542442,
    0.583519127,
    0.623320385,
    0.758375066,
    0.900564156,
    0.898842001,
    0.877119529,
    0.845181448,
    0.850355486,
    0.870825656,
    0.859953965,
    0.863566298,
    0.925291375,
    1.009949227,
    1.133908584,
    1.21724661,
    1.248241323,
    1.282154132,
    1.229754833,
    1.065194457,
    0.819043074,
    0.700891597,
    0.639516126,
    0.587850001,
    0.556050691,
    0.550844549,
    0.596733835,
    0.726473137,
    0.869622582,
    0.875613157,
    0.853013358,
    0.822789354,
    0.819151971,
    0.839367864,
    0.826689833,
    0.832373635,
    0.886937866,
    0.964206859,
    1.075070485,
    1.159469679,
    1.194353079,
    1.229185752,
    1.195093379,
    1.049968076,
    0.818584194,
    0.691835086,
    0.621310123,
    0.579859252,
    0.560376204,
    0.558409252,
    0.602585749,
    0.728038658,
    0.874709611,
    0.906506232,
    0.898048738,
    0.858132623,
    0.865477663,
    0.881478787,
    0.861679011,
    0.870614089,
    0.916455567,
    0.977475161,
    1.064941156,
    1.119296822,
    1.123829846,
    1.142932295,
    1.105412075,
    1.010183424,
    0.850427787,
    0.756158549,
    0.660797116,
    0.615639694,
    0.580675667,
    0.567978625,
    0.593397879,
    0.663316497,
    0.778001122,
    0.914385235,
    1.056171664,
    1.108275316,
    1.125863844,
    1.111642397,
    1.069599919,
    1.026542529,
    1.013845347,
    1.033682039,
    1.075607435,
    1.124016557,
    1.119450795,
    1.119041597,
    1.086394707,
    0.99170778,
    0.837907266,
    0.741663079,
    0.673192529,
    0.635054375,
    0.603925227,
    0.583897576,
    0.61173888,
    0.674131292,
    0.776033765,
    0.914834643,
    1.052167628,
    1.118193146,
    1.148748606,
    1.162854736,
    1.15189776,
    1.105369409,
    1.083827734,
    1.124540014,
    1.197311191,
    1.289112877,
    1.314537547,
    1.336996024,
    1.264203785,
    1.100564836,
    0.865579143,
    0.718794862,
    0.581944272,
    0.54425561,
    0.54076811,
    0.562571333,
    0.60971356,
    0.748036145,
    0.883574566,
    0.89779376,
    0.886487299,
    0.872314247,
    0.863482339,
    0.847154579,
    0.823245502,
    0.824278696,
    0.865136025,
    0.933243982,
    1.067402353,
    1.167880322,
    1.244740046,
    1.253278777,
    1.188198983,
    1.022972703,
    0.772455571,
    0.6728418,
    0.591130494,
    0.541969543,
    0.524848132,
    0.542835984,
    0.586544958,
    0.716460927,
    0.845512906,
    0.861730712,
    0.839389885,
    0.810661904,
    0.790180098,
    0.777899091,
    0.757055371,
    0.753403229,
    0.795306886,
    0.870926875,
    0.999234232,
    1.080738153,
    1.14885915,
    1.181863338,
    1.120540345,
    0.965157981,
    0.724830871,
    0.632097605,
    0.571810144,
    0.543823202,
    0.527402112,
    0.541154646,
    0.588364012,
    0.724724338,
    0.851099862,
    0.855873431,
    0.824966392,
    0.800315813,
    0.794446891,
    0.795741767,
    0.773992482,
    0.783124006,
    0.835346445,
    0.90861652,
    1.045063426,
    1.115519586,
    1.179466353,
    1.209209183,
    1.146874673,
    0.986846806,
    0.748880646,
    0.645676833,
    0.567093023,
    0.523904935,
    0.50742859,
    0.523841396,
    0.573592801,
    0.704121234,
    0.830846398,
    0.842480315,
    0.814305215,
    0.79076675,
    0.775780186,
    0.778536933,
    0.756698908,
    0.766954334,
    0.81238269,
    0.880479205,
    0.999912564,
    1.068594171,
    1.135365755,
    1.160540545,
    1.114575231,
    0.970638817,
    0.745275492,
    0.640138084,
    0.58159974,
    0.541853596,
    0.526683463,
    0.543261695,
    0.588683382,
    0.714585184,
    0.842505769,
    0.878132572,
    0.86646602,
    0.833498554,
    0.826965322,
    0.82516877,
    0.797414812,
    0.8100892,
    0.845500593,
    0.900359542,
    0.992968363,
    1.028769363,
    1.067222776,
    1.074857449,
    1.023921824,
    0.926856803,
    0.76764559,
    0.69092878,
    0.620111157,
    0.573919216,
    0.544749193,
    0.552619747,
    0.579161092,
    0.65378437,
    0.750023698,
    0.884543869,
    1.020837755,
    1.069098499,
    1.073770618,
    1.044594213,
    0.990606227,
    0.953763966,
    0.933185904,
    0.947937199,
    0.99591635,
    1.025722906,
    1.06309211,
    1.053462604,
    1.006564253,
    0.909660614,
    0.756711268,
    0.677500713,
    0.618117689,
    0.576768845,
    0.54957091,
    0.549504376,
    0.577553097,
    0.644645522,
    0.727459059,
    0.866752416,
    0.994052881,
    1.055367896,
    1.071659126,
    1.07092152,
    1.045655885,
    1.004871203,
    0.975165292,
    1.007808973,
    1.080722408,
    1.146830004,
    1.215939312,
    1.231910708,
    1.149799566,
    0.988470138,
    0.758882027,
    0.633820478,
    0.551753327,
    0.511433279,
    0.498765022,
    0.515481446,
    0.559723003,
    0.695789588,
    0.828820303,
    0.843890987,
    0.837544796,
    0.825747264,
    0.81835927,
    0.807595965,
    0.786050737,
    0.788947434,
    0.82813316,
    0.891124262,
    1.021485715,
    1.119033337,
    1.179555576,
    1.185335742,
    1.122508001,
    0.963455775,
    0.722945977,
    0.62584006,
    0.577981466,
    0.541957348,
    0.527326668,
    0.544813874,
    0.587070058,
    0.717077608,
    0.849548312,
    0.867201743,
    0.847176805,
    0.8193671,
    0.801349763,
    0.792658209,
    0.776331251,
    0.773379848,
    0.817249318,
    0.892710283,
    1.026832385,
    1.11983661,
    1.186921412,
    1.208594111,
    1.145392019,
    0.990612348,
    0.759358109,
    0.66342779,
    0.593759955,
    0.563547762,
    0.548262682,
    0.560943565,
    0.606674465,
    0.744563902,
    0.875071239,
    0.88204133,
    0.850495732,
    0.822750351,
    0.813720234,
    0.812221926,
    0.789567284,
    0.796477933,
    0.84829802,
    0.918897378,
    1.058667995,
    1.140594232,
    1.208406045,
    1.230759101,
    1.162430338,
    1.004407302,
    0.778014898,
    0.68196239,
    0.623627201,
    0.592811001,
    0.581335684,
    0.597259474,
    0.64448635,
    0.77649131,
    0.906845981,
    0.9226973,
    0.89398327,
    0.869017779,
    0.853663265,
    0.853845036,
    0.831785111,
    0.843632102,
    0.891518175,
    0.95910996,
    1.089490107,
    1.175561218,
    1.250951413,
    1.266023959,
    1.212006377,
    1.068620651,
    0.851013498,
    0.74875221,
    0.678351486,
    0.643028314,
    0.630017157,
    0.643930711,
    0.684645888,
    0.811111634,
    0.940439785,
    0.98063988,
    0.968779083,
    0.934426058,
    0.926081568,
    0.920598788,
    0.890930896,
    0.906533466,
    0.942824757,
    0.998099319,
    1.103143621,
    1.155637217,
    1.202850391,
    1.199346498,
    1.137763769,
    1.040917723,
    0.884942337,
    0.816201436,
    0.746194269,
    0.706207381,
    0.67645006,
    0.682097332,
    0.705081223,
    0.779358461,
    0.875560496,
    1.01654478,
    1.155944181,
    1.205760577,
    1.207388566,
    1.17507986,
    1.120642122,
    1.084479839,
    1.064789998,
    1.082911215,
    1.144678474,
    1.188611587,
    1.231079938,
    1.207921443,
    1.143991179,
    1.046196747,
    0.894169381,
    0.818932397,
    0.759291203,
    0.71692421,
    0.686191014,
    0.683871961,
    0.707864743,
    0.774564022,
    0.855992681,
    1.000405873,
    1.133386035,
    1.195375388,
    1.208700423,
    1.204115129,
    1.17815865,
    1.140208633,
    1.110436113,
    1.14596797,
    1.232171123,
    1.312062223,
    1.386329159,
    1.390342743,
    1.28874297,
    1.127540646,
    0.901217932,
    0.774498723,
    0.662914692,
    0.612873275,
    0.590704613,
    0.599634371,
    0.638107938,
    0.772849189,
    0.901217023,
    0.919075173,
    0.914441843,
    0.901603272,
    0.892009313,
    0.877486987,
    0.853022544,
    0.86245349,
    0.903579782,
    0.968111558,
    1.108218824,
    1.216442269,
    1.279525684,
    1.273758686,
    1.194193508,
    1.034720693,
    0.796321178,
    0.696803757,
    0.648154289,
    0.606067082,
    0.58353685,
    0.594905315,
    0.63215707,
    0.761123996,
    0.88963413,
    0.90961351,
    0.892321889,
    0.864483356,
    0.845462477,
    0.835275335,
    0.8172371,
    0.81947353,
    0.864034894,
    0.939140661,
    1.079918982,
    1.181412355,
    1.246818763,
    1.258684287,
    1.185036194,
    1.031543284,
    0.804947516,
    0.704725958,
    0.626498028,
    0.589519117,
    0.566840434,
    0.574324749,
    0.616084736,
    0.752806944,
    0.880061308,
    0.888647051,
    0.861513015,
    0.83475527,
    0.825993045,
    0.825494528,
    0.802696465,
    0.813085646,
    0.864768008,
    0.933088789,
    1.074978942,
    1.160956867,
    1.222127879,
    1.237613524,
    1.165376879,
    1.010863149,
    0.790141821,
    0.688932964,
    0.617053467,
    0.579068523,
    0.559326646,
    0.569677214,
    0.613933824,
    0.744486901,
    0.871346261,
    0.887584718,
    0.864276458,
    0.841448595,
    0.82756924,
    0.830750759,
    0.810308447,
    0.825660843,
    0.873121325,
    0.938154834,
    1.068870346,
    1.155484678,
    1.219746758,
    1.228253527,
    1.171020081,
    1.030469755,
    0.81943456,
    0.709460273,
    0.624670291,
    0.580956461,
    0.559115105,
    0.568159645,
    0.607047325,
    0.73200938,
    0.857246346,
    0.896507705,
    0.892038358,
    0.861192721,
    0.856410464,
    0.856640978,
    0.829723771,
    0.847776626,
    0.880664186,
    0.932777948,
    1.032110955,
    1.080198597,
    1.110278681,
    1.10358736,
    1.045222709,
    0.953151612,
    0.803067589,
    0.724066984,
    0.650891259,
    0.60707164,
    0.57238374,
    0.57399352,
    0.594170663,
    0.666734752,
    0.760544673,
    0.897340904,
    1.038589609,
    1.091445364,
    1.09736699,
    1.072876501,
    1.021819236,
    0.987285441,
    0.967093575,
    0.980852366,
    1.035341947,
    1.075690192,
    1.105513461,
    1.082580739,
    1.028668597,
    0.935816142,
    0.791594814,
    0.709966981,
    0.653682656,
    0.61410863,
    0.582276696,
    0.576748173,
    0.59876017,
    0.664184378,
    0.746310648,
    0.886919141,
    1.023554052,
    1.088766652,
    1.107855588,
    1.111103418,
    1.089069945,
    1.050100871,
    1.020567388,
    1.052161661,
    1.131789657,
    1.207338427,
    1.271087602,
    1.27571597,
    1.184674976,
    1.026701811,
    0.810920991,
    0.686068487,
    0.595627098,
    0.558541024,
    0.541064713,
    0.551443304,
    0.589875108,
    0.725394955,
    0.857512459,
    0.875607858,
    0.876313756,
    0.866023891,
    0.860996053,
    0.853179603,
    0.834497933,
    0.84270594,
    0.884254387,
    0.946078823,
    1.086502836,
    1.198622567,
    1.254227452,
    1.242347102,
    1.169941837,
    1.013332977,
    0.784759742,
    0.686684859,
    0.628945221,
    0.592810964,
    0.572049122,
    0.582920109,
    0.618887984,
    0.7482566,
    0.87868808,
    0.899436957,
    0.886500403,
    0.860232624,
    0.844232966,
    0.838002994,
    0.823596763,
    0.826334528,
    0.871283527,
    0.945525739,
    1.089692806,
    1.197395424,
    1.259340787,
    1.263135126,
    1.190219703,
    1.038073698,
    0.819328787,
    0.722956919,
    0.643395813,
    0.611605212,
    0.588920813,
    0.594577312,
    0.633946895,
    0.771054211,
    0.898717405,
    0.908668972,
    0.885021813,
    0.859024129,
    0.85179901,
    0.85280404,
    0.831752398,
    0.844307324,
    0.897204776,
    0.965707368,
    1.114489119,
    1.209663617,
    1.270751536,
    1.276089154,
    1.198776726,
    1.044083786,
    0.829297579,
    0.726227983,
    0.627876201,
    0.580815858,
    0.553880771,
    0.559915983,
    0.60087426,
    0.730704487,
    0.854120856,
    0.871145018,
    0.853788636,
    0.832604867,
    0.820127482,
    0.825556035,
    0.805238401,
    0.822959395,
    0.869477043,
    0.930753365,
    1.060429748,
    1.146435168,
    1.201416291,
    1.20616878,
    1.149040645,
    1.013037082,
    0.80641538,
    0.70763995,
    0.637123487,
    0.607882754,
    0.583036946,
    0.588150539,
    0.623453319,
    0.746679732,
    0.869303486,
    0.909283825,
    0.913012873,
    0.890265592,
    0.895350347,
    0.906703453,
    0.88928837,
    0.918042581,
    0.958225708,
    1.016396426,
    1.129686709,
    1.186673421,
    1.208557152,
    1.184541127,
    1.122214544,
    1.027228519,
    0.871287531,
    0.78400544,
    0.705334469,
    0.65340835,
    0.613101715,
    0.611874906,
    0.628444255,
    0.700487309,
    0.795558101,
    0.935231557,
    1.081032282,
    1.135287252,
    1.142045761,
    1.11918181,
    1.070959708,
    1.037155971,
    1.017990858,
    1.034396559,
    1.094643842,
    1.142221059,
    1.172630019,
    1.137749186,
    1.073727083,
    0.978672008,
    0.837888458,
    0.763317166,
    0.709202415,
    0.672623799,
    0.636768608,
    0.629158845,
    0.647938557,
    0.713078037,
    0.794989247,
    0.936703176,
    1.079116536,
    1.145720655,
    1.165127206,
    1.169004522,
    1.149210349,
    1.113067689,
    1.084863773,
    1.120219402,
    1.209407156,
    1.2936796,
    1.35885892,
    1.351552829,
    1.247218315,
    1.087045678,
    0.876506643,
    0.746581888,
    0.626470482,
    0.576992831,
    0.550422399,
    0.555887123,
    0.590762779,
    0.725640325,
    0.852569374,
    0.871749304,
    0.879161503,
    0.870451128,
    0.865867267,
    0.858691058,
    0.837857191,
    0.849645093,
    0.890034785,
    0.948712072,
    1.08915403,
    1.199320793,
    1.246848439,
    1.231517858,
    1.157137844,
    1.004320743,
    0.780448048,
    0.68270201,
    0.643151557,
    0.612721885,
    0.589491387,
    0.598200807,
    0.631968245,
    0.761649182,
    0.889959062,
    0.911606666,
    0.905788112,
    0.881148634,
    0.866633736,
    0.861172595,
    0.845402179,
    0.851114772,
    0.895363521,
    0.970008913,
    1.119390628,
    1.230017529,
    1.290307895,
    1.285580895,
    1.209168611,
    1.056383287,
    0.842163308,
    0.743249649,
    0.636536196,
    0.596495101,
    0.567102405,
    0.569765782,
    0.607211985,
    0.743771296,
    0.866681244,
    0.87678398,
    0.864233753,
    0.841377484,
    0.836113043,
    0.839407544,
    0.815524933,
    0.830493216,
    0.88081511,
    0.945804363,
    1.091846019,
    1.179486345,
    1.230458524,
    1.232771128,
    1.159452339,
    1.007866348,
    0.797890961,
    0.73583515,
    0.799361826,
    0.822423978,
    0.831252638,
    0.852231884,
    0.882262844,
    1.01612766,
    1.113468929,
    1.112411669,
    1.06381659,
    1.030479184,
    1.024456331,
    1.00781192,
    1.004707361,
    0.995345806,
    1.018083591,
    1.075499677,
    1.24014168,
    1.37157659,
    1.448124226,
    1.453089963,
    1.419285071,
    1.308147819,
    1.135608845,
    1.058375292,
    0.964855474,
    0.94224829,
    0.943263077,
    0.958485258,
    0.978088599,
    1.106613285,
    1.190695225,
    1.210381652,
    1.177613745,
    1.136056553,
    1.133326956,
    1.109671221,
    1.093322652,
    1.095301925,
    1.105702611,
    1.15061265,
    1.292196705,
    1.389716215,
    1.440633698,
    1.431989858,
    1.383870823,
    1.316114797,
    1.19391352,
    1.151609859,
    1.085668729,
    1.058157766,
    1.032085174,
    1.044037185,
    1.060760747,
    1.153444907,
    1.237216563,
    1.366745773,
    1.473617063,
    1.509823626,
    1.509611458,
    1.45683725,
    1.425492705,
    1.372429349,
    1.319913986,
    1.322492408,
    1.417389182,
    1.49199748,
    1.518744618,
    1.486788332,
    1.427828058,
    1.355037888,
    1.225972686,
    1.159546649,
    1.055832719,
    0.990583224,
    0.955542272,
    0.940799863,
    0.966250126,
    1.022837357,
    1.123253581,
    1.267882548,
    1.347594333,
    1.394498351,
    1.387446473,
    1.368942833,
    1.367040968,
    1.326862607,
    1.292452878,
    1.310926801,
    1.414789779,
    1.540723372,
    1.579757226,
    1.540498386,
    1.448396504,
    1.308084878,
    1.129542362,
    0.964521974,
    0.85373469,
    0.797459315,
    0.775581744,
    0.775154156,
    0.801423644,
    0.896466898,
    1.013414157,
    1.010580046,
    0.96749888,
    0.945120886,
    0.923265355,
    0.908972909,
    0.905205671,
    0.911987873,
    0.959174243,
    1.025017723,
    1.190058645,
    1.357334208,
    1.39187098,
    1.350557297,
    1.288360306,
    1.154074404,
    0.975548129,
    0.836325955,
    0.774531933,
    0.728933771,
    0.713687397,
    0.717528542,
    0.745473539,
    0.839123718,
    0.966866626,
    0.969506497,
    0.916370366,
    0.881220936,
    0.853304481,
    0.847679385,
    0.853373954,
    0.851057843,
    0.900495654,
    0.976521936,
    1.140843829,
    1.304177517,
    1.340305706,
    1.317697463,
    1.266176422,
    1.139719776,
    0.965677602,
    0.840107955,
    0.772888471,
    0.746514876,
    0.737963857,
    0.742130131,
    0.774913339,
    0.878757677,
    1.011121714,
    1.004066907,
    0.936944008,
    0.901461755,
    0.885008532,
    0.888265015,
    0.889327033,
    0.892073376,
    0.950736802,
    1.023904887,
    1.193013293,
    1.352162531,
    1.396337178,
    1.374557168,
    1.322737041,
    1.193263088,
    1.025431494,
    0.904074058,
    0.819001607,
    0.786465476,
    0.781098804,
    0.789181685,
    0.823473288,
    0.920965848,
    1.04882211,
    1.048436091,
    0.983098229,
    0.950189131,
    0.926560157,
    0.930314884,
    0.930858931,
    0.938534378,
    0.993084109,
    1.062438228,
    1.218054772,
    1.37788833,
    1.428610844,
    1.404228312,
    1.367916856,
    1.254900403,
    1.096917507,
    0.968068199,
    0.843518764,
    0.794188375,
    0.779302215,
    0.780026099,
    0.807955058,
    0.902430831,
    1.02509961,
    1.046593656,
    0.994832959,
    0.952928911,
    0.936524845,
    0.940016038,
    0.931453087,
    0.94853867,
    0.998838866,
    1.049073409,
    1.178865826,
    1.304363875,
    1.32192374,
    1.284204011,
    1.234570627,
    1.163170702,
    1.053964874,
    0.956312682,
    0.876163736,
    0.826421019,
    0.797648189,
    0.78735934,
    0.805598967,
    0.865336487,
    1.001283522,
    1.145611218,
    1.22500153,
    1.258579964,
    1.252190421,
    1.225708514,
    1.20362164,
    1.166506675,
    1.147635642,
    1.155722533,
    1.232967972,
    1.342025661,
    1.350095525,
    1.293220251,
    1.245302807,
    1.171145028,
    1.05887738,
    0.948321715,
    0.86877956,
    0.818079463,
    0.790268381,
    0.772258511,
    0.794923539,
    0.855129664,
    0.986991061,
    1.137381169,
    1.213559682,
    1.263431317,
    1.267698137,
    1.266729847,
    1.268609673,
    1.224269848,
    1.193393359,
    1.215228445,
    1.310165705,
    1.448869849,
    1.486695579,
    1.452804847,
    1.374631618,
    1.240575486,
    1.059516383,
    0.886905335,
    0.75949169,
    0.70752929,
    0.689836655,
    0.690061863,
    0.717092401,
    0.821405824,
    0.955369373,
    0.950183378,
    0.901007188,
    0.879195105,
    0.865718095,
    0.865601805,
    0.864018049,
    0.86519232,
    0.913284917,
    0.980109008,
    1.137544859,
    1.313672085,
    1.346629672,
    1.313075875,
    1.264034882,
    1.139702146,
    0.958584312,
    0.81536197,
    0.739685206,
    0.693929985,
    0.677339642,
    0.678567021,
    0.704264506,
    0.804834896,
    0.940556468,
    0.94043386,
    0.880684987,
    0.844960392,
    0.822339374,
    0.826920963,
    0.830666925,
    0.825647888,
    0.875566664,
    0.952188734,
    1.108565646,
    1.278746697,
    1.31473908,
    1.300374338,
    1.254177117,
    1.138164923,
    0.959807247,
    0.829728712,
    0.745122954,
    0.71242709,
    0.698218394,
    0.696616765,
    0.726424251,
    0.836945011,
    0.973969012,
    0.963761929,
    0.890400042,
    0.854437243,
    0.842783814,
    0.855332618,
    0.85337353,
    0.855669966,
    0.914593803,
    0.988189912,
    1.149667325,
    1.313680694,
    1.354679056,
    1.340919844,
    1.290085821,
    1.169751241,
    0.99585257,
    0.864653571,
    0.748097374,
    0.700955899,
    0.68474949,
    0.683645873,
    0.716639183,
    0.821488014,
    0.953372896,
    0.948915179,
    0.881146882,
    0.849741094,
    0.831579059,
    0.847308689,
    0.845104968,
    0.854140593,
    0.90782741,
    0.977142202,
    1.125074393,
    1.283461531,
    1.322679492,
    1.306100022,
    1.270527277,
    1.166016771,
    0.998398894,
    0.865071704,
    0.820081054,
    0.793094278,
    0.788213077,
    0.794705467,
    0.821545283,
    0.924881679,
    1.058152454,
    1.077526408,
    1.021254589,
    0.978985059,
    0.96929135,
    0.981801325,
    0.973319788,
    0.984993124,
    1.03612124,
    1.088473739,
    1.215428128,
    1.353972285,
    1.382803987,
    1.348388089,
    1.308326195,
    1.243744815,
    1.134802846,
    1.044692104,
    0.930726012,
    0.884709631,
    0.858326303,
    0.85223093,
    0.867806689,
    0.936118165,
    1.068507237,
    1.209858291,
    1.288358261,
    1.322301044,
    1.318253165,
    1.295645973,
    1.26947416,
    1.231237421,
    1.212040671,
    1.220878344,
    1.294544374,
    1.41222733,
    1.427862068,
    1.374285744,
    1.326919864,
    1.260058906,
    1.144489475,
    1.046534659,
    1.005927653,
    0.971768345,
    0.950241242,
    0.943630929,
    0.965069229,
    1.036780447,
    1.154490955,
    1.298578874,
    1.374465864,
    1.421882305,
    1.424981397,
    1.421979616,
    1.419458345,
    1.37483888,
    1.342466564,
    1.363683071,
    1.455956825,
    1.606915692,
    1.65720236,
    1.623845293,
    1.542806896,
    1.417018774,
    1.236751444,
    1.085823955,
    0.996767506,
    0.965156428,
    0.958818475,
    0.969639343,
    0.987548996,
    1.093933444,
    1.208946218,
    1.199856674,
    1.148782334,
    1.123288115,
    1.107758461,
    1.10114046,
    1.088069538,
    1.086380051,
    1.137138446,
    1.203063773,
    1.359208492,
    1.548917221,
    1.613960138,
    1.582930736,
    1.53218873,
    1.418652347,
    1.241159672,
    1.115878058,
    0.989310468,
    0.927840554,
    0.900392756,
    0.899723962,
    0.913676996,
    1.017517842,
    1.119517047,
    1.114484882,
    1.058855306,
    1.022116228,
    0.993879849,
    0.988495118,
    0.974024754,
    0.976763805,
    1.025236054,
    1.099742128,
    1.255791661,
    1.427235297,
    1.47568933,
    1.463991689,
    1.402631319,
    1.293453703,
    1.10920912,
    0.990351347,
    0.947092249,
    0.915004994,
    0.895338383,
    0.894582459,
    0.914547416,
    1.029073476,
    1.136154784,
    1.121215716,
    1.055217217,
    1.019112428,
    1.003107925,
    1.007640921,
    0.989833677,
    0.99674794,
    1.053583625,
    1.124338697,
    1.28474292,
    1.448614872,
    1.501160007,
    1.490479278,
    1.432350617,
    1.31963138,
    1.141053999,
    1.001308749,
    1.014863895,
    0.97323328,
    0.918183952,
    0.919219127,
    0.929581801,
    1.016137665,
    1.086330936,
    1.283304997,
    1.458466901,
    1.607346664,
    1.693503924,
    1.630391765,
    1.478798947,
    1.380608019,
    1.301429175,
    1.293342714,
    1.35891638,
    1.394599637,
    1.379783117,
    1.363158541,
    1.355182014,
    1.269184242,
    1.12419133,
    1.04221578,
    1.025264806,
    0.989778189,
    0.984574397,
    0.997252818,
    1.018247174,
    1.036397205,
    1.129876119,
    1.255576508,
    1.36039517,
    1.400095992,
    1.41081241,
    1.391781913,
    1.374630937,
    1.349613769,
    1.326110589,
    1.333582834,
    1.455647312,
    1.520988845,
    1.557395114,
    1.504067175,
    1.465025373,
    1.375692211,
    1.257161496,
    1.160992252,
    1.032697185,
    0.999263377,
    0.969687599,
    0.966732715,
    0.972929021,
    1.026336646,
    1.130922532,
    1.282808408,
    1.396238922,
    1.445534594,
    1.444223121,
    1.420976959,
    1.38840309,
    1.343187159,
    1.317234305,
    1.320218794,
    1.393620113,
    1.49796515,
    1.521258668,
    1.461171407,
    1.408511356,
    1.340990197,
    1.222610762,
    1.121512668,
    1.006403046,
    0.947825061,
    0.903018803,
    0.885110468,
    0.900695533,
    0.978047019,
    1.081794431,
    1.215557024,
    1.292238202,
    1.34235977,
    1.344113669,
    1.346760705,
    1.334913671,
    1.297988345,
    1.263385556,
    1.288787745,
    1.385319211,
    1.526804748,
    1.566473078,
    1.53457434,
    1.447543652,
    1.323363058,
    1.125811624,
    0.953178099,
    0.850089343,
    0.799080507,
    0.773273495,
    0.772991245,
    0.791941587,
    0.91198062,
    1.032806163,
    1.017932622,
    0.964827677,
    0.941819062,
    0.929204846,
    0.932150761,
    0.921699188,
    0.926476753,
    0.971355387,
    1.046009466,
    1.212733886,
    1.395989622,
    1.436999755,
    1.403032645,
    1.349915184,
    1.230670745,
    1.034847653,
    0.89268222,
    0.836465195,
    0.798862189,
    0.780947758,
    0.787579063,
    0.805724992,
    0.924575897,
    1.051858365,
    1.043276239,
    0.976976096,
    0.939385379,
    0.917424807,
    0.924179199,
    0.920725332,
    0.917344785,
    0.96398321,
    1.050244957,
    1.220359161,
    1.402825561,
    1.451853169,
    1.434478507,
    1.38925848,
    1.276268181,
    1.085150486,
    0.956080667,
    0.843238841,
    0.806366105,
    0.784214362,
    0.784778987,
    0.80655628,
    0.935286911,
    1.059898882,
    1.040565389,
    0.960982809,
    0.923101578,
    0.909787614,
    0.923141891,
    0.913125374,
    0.918494798,
    0.971325624,
    1.054638286,
    1.22914535,
    1.399215656,
    1.445922327,
    1.431407184,
    1.382090091,
    1.265633655,
    1.074367122,
    0.941504676,
    0.843658464,
    0.802498086,
    0.781630089,
    0.785156023,
    0.810337486,
    0.933933302,
    1.05792349,
    1.044903618,
    0.966293154,
    0.931358883,
    0.911022542,
    0.926497572,
    0.918483949,
    0.929124977,
    0.976910805,
    1.058413625,
    1.222679208,
    1.391799316,
    1.440716323,
    1.42163346,
    1.388281375,
    1.28579356,
    1.101768262,
    0.964784441,
    0.898080486,
    0.868002426,
    0.854693164,
    0.863759249,
    0.883579318,
    1.006218877,
    1.134435466,
    1.14381105,
    1.073877647,
    1.028332642,
    1.015221108,
    1.02702329,
    1.016170965,
    1.029178676,
    1.07923258,
    1.139223089,
    1.281907039,
    1.42877462,
    1.462949825,
    1.427867007,
    1.392649759,
    1.328995942,
    1.201529672,
    1.088634696,
    1.032159349,
    0.974191046,
    0.948890646,
    0.942228275,
    0.956182223,
    1.023725148,
    1.153751294,
    1.31736693,
    1.452088181,
    1.519944435,
    1.523505408,
    1.492503421,
    1.470131158,
    1.417655804,
    1.386778748,
    1.389631511,
    1.502977796,
    1.655111789,
    1.641114189,
    1.597536252,
    1.542255486,
    1.451582597,
    1.294788959,
    1.185389984,
    1.131321686,
    1.092697408,
    1.071702954,
    1.067399247,
    1.087352085,
    1.15774658,
    1.266720186,
    1.429636595,
    1.557509842,
    1.637207427,
    1.647576001,
    1.638439063,
    1.638901788,
    1.585739007,
    1.542163395,
    1.561401842,
    1.700800566,
    1.899930062,
    1.921585215,
    1.896959985,
    1.802414209,
    1.657714823,
    1.433249868,
    1.258693705,
    1.136377652,
    1.085893479,
    1.069973714,
    1.077037162,
    1.098211855,
    1.217006062,
    1.347723119,
    1.360060867,
    1.332995138,
    1.324613621,
    1.314304368,
    1.294945699,
    1.282795923,
    1.276824609,
    1.327262793,
    1.390077453,
    1.587097292,
    1.813318766,
    1.85709803,
    1.841483367,
    1.784906558,
    1.646526652,
    1.415778079,
    1.27467928,
    1.24196013,
    1.211012253,
    1.19804822,
    1.210014154,
    1.223481384,
    1.343488498,
    1.468496496,
    1.488886845,
    1.446035395,
    1.419192576,
    1.390480474,
    1.366000968,
    1.34792849,
    1.337428258,
    1.384370625,
    1.455635866,
    1.655662888,
    1.885873626,
    1.938463732,
    1.943331168,
    1.893564194,
    1.764821236,
    1.534140495,
    1.389530281,
    1.192437526,
    1.102221953,
    1.049135698,
    1.038269925,
    1.055068424,
    1.179150025,
    1.2926747,
    1.300098061,
    1.251835313,
    1.230141464,
    1.210584486,
    1.19583205,
    1.173513097,
    1.183133269,
    1.234693087,
    1.3045555,
    1.508667814,
    1.703238322,
    1.724641989,
    1.73141693,
    1.662664883,
    1.52662238,
    1.288225455,
    1.141604872,
    1.109598252,
    1.066329379,
    1.036189953,
    1.035305835,
    1.05743463,
    1.178096129,
    1.30380021,
    1.318435142,
    1.268926638,
    1.248083716,
    1.226007196,
    1.217542059,
    1.202878219,
    1.210677386,
    1.259060623,
    1.328614326,
    1.525097281,
    1.727940064,
    1.76127691,
    1.762268748,
    1.72024198,
    1.597140705,
    1.370946347,
    1.215589839,
    1.127869789,
    1.07908926,
    1.057470454,
    1.060992555,
    1.079809072,
    1.199039987,
    1.334208188,
    1.37295723,
    1.331827632,
    1.302561342,
    1.286977864,
    1.278143818,
    1.260964421,
    1.269746017,
    1.320432956,
    1.361710546,
    1.53998124,
    1.715882799,
    1.727836855,
    1.70961274,
    1.664454665,
    1.579850021,
    1.406837102,
    1.291156712,
    1.136302623,
    1.054332091,
    0.995562824,
    0.983176016,
    0.99442502,
    1.069688218,
    1.18786498,
    1.342509698,
    1.455721128,
    1.517632054,
    1.512828419,
    1.474315737,
    1.445458007,
    1.403525704,
    1.368063249,
    1.375734905,
    1.505424831,
    1.653534511,
    1.630951351,
    1.587154384,
    1.524551817,
    1.432970574,
    1.25753843,
    1.12847686,
    1.081190105,
    1.027099785,
    0.983768817,
    0.97247754,
    0.988093876,
    1.063097188,
    1.181670792,
    1.337995974,
    1.446134856,
    1.522940126,
    1.530988404,
    1.522268031,
    1.526074638,
    1.480647458,
    1.433921657,
    1.459105539,
    1.613331347,
    1.806383247,
    1.81761743,
    1.791788462,
    1.700033005,
    1.54780066,
    1.308683984,
    1.107981343,
    0.966848754,
    0.907136449,
    0.879908294,
    0.881434876,
    0.901870344,
    1.02888094,
    1.179493501,
    1.190118921,
    1.148644704,
    1.139591245,
    1.130291863,
    1.116884307,
    1.118577155,
    1.116258064,
    1.159624553,
    1.236615201,
    1.451185017,
    1.674290115,
    1.69776022,
    1.675722032,
    1.623903233,
    1.474307314,
    1.226789346,
    1.056666252,
    0.960019372,
    0.902188529,
    0.869935613,
    0.870432415,
    0.886015854,
    1.010107332,
    1.15993055,
    1.176494466,
    1.122082402,
    1.098131785,
    1.075889548,
    1.064031359,
    1.069116807,
    1.063848034,
    1.105743229,
    1.193442951,
    1.411555272,
    1.628239099,
    1.648130032,
    1.644624244,
    1.596464032,
    1.451759834,
    1.209187816,
    1.045167201,
    0.935542066,
    0.889754994,
    0.85918014,
    0.855953406,
    0.876481925,
    1.009882236,
    1.162580524,
    1.168700736,
    1.100478046,
    1.076383426,
    1.063629291,
    1.060116227,
    1.06158207,
    1.063267459,
    1.112029522,
    1.197806157,
    1.42218564,
    1.628211482,
    1.647278629,
    1.644845803,
    1.595579289,
    1.446780522,
    1.20673685,
    1.042251999,
    0.942495692,
    0.89927142,
    0.875401457,
    0.877664136,
    0.901998527,
    1.030711092,
    1.186090206,
    1.199776356,
    1.131411423,
    1.108560107,
    1.089270889,
    1.087855503,
    1.091377573,
    1.096119495,
    1.140082207,
    1.224414319,
    1.438848878,
    1.646977525,
    1.673085423,
    1.666578561,
    1.63687481,
    1.502704071,
    1.270705321,
    1.101684736,
    0.99976399,
    0.95537195,
    0.933428794,
    0.937949572,
    0.957521725,
    1.084069713,
    1.242354876,
    1.279654856,
    1.220207618,
    1.187543363,
    1.17262297,
    1.167128816,
    1.164744704,
    1.172398316,
    1.21763424,
    1.275354661,
    1.469559784,
    1.648425555,
    1.65229717,
    1.628068225,
    1.592346078,
    1.498817087,
    1.318857571,
    1.212517163,
    1.151499759,
    1.128585291,
    1.103119757,
    1.111778683,
    1.128190977,
    1.21780657,
    1.371752234,
    1.521885919,
    1.612004453,
    1.668178916,
    1.663702292,
    1.628386424,
    1.607489829,
    1.552803392,
    1.519063805,
    1.529531033,
    1.678407897,
    1.853027308,
    1.848329341,
    1.795689884,
    1.744940003,
    1.648730153,
    1.466632618,
    1.358900943,
    1.271112361,
    1.234959734,
    1.207282837,
    1.21720002,
    1.238224307,
    1.329389791,
    1.462034944,
    1.618282355,
    1.700794993,
    1.762218278,
    1.760119476,
    1.751261044,
    1.755130383,
    1.706555476,
    1.654194112,
    1.682404482,
    1.85572563,
    2.074849036,
    2.100531765,
    2.065578484,
    1.971060202,
    1.820426227,
    1.570300016,
    1.383177348,
    1.222666471,
    1.162151533,
    1.131886102,
    1.141445272,
    1.158566774,
    1.293031439,
    1.442012133,
    1.45212331,
    1.383975656,
    1.361187056,
    1.337453823,
    1.307906207,
    1.302073038,
    1.297034044,
    1.334325278,
    1.423107446,
    1.649635569,
    1.887995987,
    1.936773701,
    1.916671384,
    1.866533159,
    1.720958667,
    1.45597666,
    1.294077725,
    1.162414746,
    1.087032061,
    1.03760028,
    1.039019469,
    1.049424617,
    1.180984503,
    1.32628615,
    1.343997605,
    1.266973601,
    1.232819739,
    1.19608252,
    1.168573368,
    1.165327919,
    1.163451984,
    1.196977781,
    1.295136641,
    1.525501671,
    1.748153605,
    1.775366212,
    1.773202613,
    1.722100443,
    1.578297973,
    1.319531653,
    1.158758575,
    1.091969246,
    1.057694224,
    1.025668891,
    1.028946565,
    1.045651867,
    1.187132147,
    1.343604225,
    1.351673062,
    1.259377758,
    1.224781409,
    1.199101111,
    1.1819375,
    1.178970615,
    1.179236565,
    1.220761441,
    1.318262773,
    1.556665792,
    1.772236591,
    1.808503894,
    1.805436939,
    1.75996275,
    1.611410091,
    1.358105885,
    1.181569082,
    1.046537336,
    0.962209479,
    0.915010697,
    0.902006022,
    0.922580181,
    1.044835307,
    1.183779981,
    1.209798274,
    1.155528017,
    1.143365748,
    1.122686037,
    1.118483357,
    1.103034075,
    1.121888569,
    1.169050205,
    1.238248813,
    1.439876748,
    1.630184317,
    1.645275664,
    1.640942735,
    1.609126795,
    1.481726349,
    1.240229727,
    1.082511196,
    0.963198136,
    0.883014858,
    0.83509648,
    0.814896461,
    0.83207538,
    0.943960228,
    1.085140839,
    1.144578882,
    1.120356825,
    1.110883075,
    1.108767221,
    1.11505207,
    1.087267817,
    1.116169,
    1.15146067,
    1.193772101,
    1.360593295,
    1.504542748,
    1.479663215,
    1.45737211,
    1.42559058,
    1.341509073,
    1.15560438,
    1.035166476,
    0.931227959,
    0.877003568,
    0.827780332,
    0.805680235,
    0.812911801,
    0.880028088,
    1.029576018,
    1.205292571,
    1.334988878,
    1.413421594,
    1.425478828,
    1.398683631,
    1.359699266,
    1.322114817,
    1.295509034,
    1.30220245,
    1.423187458,
    1.560453505,
    1.531223411,
    1.482147555,
    1.45451365,
    1.366610551,
    1.18728274,
    1.06109018,
    0.952694631,
    0.893920659,
    0.847541331,
    0.813958153,
    0.823391472,
    0.882629213,
    1.013706047,
    1.197385275,
    1.343424843,
    1.447930587,
    1.473004726,
    1.471721251,
    1.451450328,
    1.412264686,
    1.377244528,
    1.396787171,
    1.530157578,
    1.696651051,
    1.70010551,
    1.676407907,
    1.616562767,
    1.476266708,
    1.233629791,
    1.060720767,
    0.97066705,
    0.899509389,
    0.861275821,
    0.849145508,
    0.865559766,
    0.934716359,
    1.061747152,
    1.234353644,
    1.454994112,
    1.578859256,
    1.591373108,
    1.600040601,
    1.560767095,
    1.47932998,
    1.4902694,
    1.524462493,
    1.542810712,
    1.575345932,
    1.532136703,
    1.482921668,
    1.517196766,
    1.435857479,
    1.250129357,
    1.138652733,
    1.053220709,
    0.962558108,
    0.88616012,
    0.857250225,
    0.897728259,
    0.918447414,
    1.058552411,
    1.255709841,
    1.443593631,
    1.573505195,
    1.569110157,
    1.579000764,
    1.490635305,
    1.390920946,
    1.36278931,
    1.304321521,
    1.406594325,
    1.533752512,
    1.527933667,
    1.50480333,
    1.496659765,
    1.425431867,
    1.215127114,
    1.112801727,
    0.870246056,
    0.863358146,
    0.832486306,
    0.817512071,
    0.841726915,
    0.953176059,
    1.05858353,
    1.070549313,
    1.106836123,
    1.113957875,
    1.123996131,
    1.133594361,
    1.110058743,
    1.114050606,
    1.169797501,
    1.219612672,
    1.404359111,
    1.560800041,
    1.570130681,
    1.568850915,
    1.504445704,
    1.34970871,
    1.13768357,
    1.00122558,
    0.881068446,
    0.834001082,
    0.810653888,
    0.802668411,
    0.830638735,
    0.939232813,
    1.040408161,
    1.054428269,
    1.085073014,
    1.088970495,
    1.094704793,
    1.100682107,
    1.080056939,
    1.092079429,
    1.138056034,
    1.183192874,
    1.362115518,
    1.519877523,
    1.535749249,
    1.531548616,
    1.484491009,
    1.354559856,
    1.155616296,
    1.019074709,
    0.916096705,
    0.860124581,
    0.835092736,
    0.826481712,
    0.848855277,
    0.95283357,
    1.052635192,
    1.092902492,
    1.139750463,
    1.135970125,
    1.14995247,
    1.153324672,
    1.123206623,
    1.140880152,
    1.162020465,
    1.19716509,
    1.352204181,
    1.474139597,
    1.460589063,
    1.439565494,
    1.384880539,
    1.294770831,
    1.15133362,
    1.044777663,
    0.974375737,
    0.926678269,
    0.89084498,
    0.8769643,
    0.890180388,
    0.950945573,
    1.037513501,
    1.193992913,
    1.395303774,
    1.478555188,
    1.494995074,
    1.462188023,
    1.423659036,
    1.373322017,
    1.339525609,
    1.337823235,
    1.442890973,
    1.554380818,
    1.536701622,
    1.490854364,
    1.431372238,
    1.338915291,
    1.198288877,
    1.092803003,
    1.051715715,
    1.016378141,
    0.991297454,
    0.979039663,
    0.998185572,
    1.060085533,
    1.133122588,
    1.290596637,
    1.489881549,
    1.587585256,
    1.611153919,
    1.602079896,
    1.590463004,
    1.533879368,
    1.487337782,
    1.500738375,
    1.628423251,
    1.784262346,
    1.805163265,
    1.780039179,
    1.683330756,
    1.533314469,
    1.327555318,
    1.157216753,
    1.05118501,
    0.955265661,
    0.938191873,
    0.944208267,
    0.96880328,
    1.037396634,
    1.124671318,
    1.210397884,
    1.281743004,
    1.356343903,
    1.43811829,
    1.428270725,
    1.388319173,
    1.450456302,
    1.419980163,
    1.502736184,
    1.591742041,
    1.795194591,
    1.799424171,
    1.682993474,
    1.587482403,
    1.462053702,
    1.326278127,
    1.203971459,
  ],
};

export default wisconsin;
