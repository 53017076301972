import React, { useContext } from 'react';
import Select from '../../../../components/shared/InputElements/Select';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import { FormattedMessage, useIntl } from 'react-intl';

const SelectChargingPattern = ({ chargingPatterns }) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const chargingPatternsTranslations = {
    "after-6am-before-3pm": intl.formatMessage({id:"AFTER_6AM_BEFORE_3PM", defaultMessage:"After 6am; before 3pm"}),
    "after-11am-before-10pm": intl.formatMessage({id:"AFTER_11AM_BEFORE_10PM", defaultMessage:"After 11am; before 10pm"}),
    "after-9pm-before-6am": intl.formatMessage({id:"AFTER_9PM_BEFORE_6AM", defaultMessage:"After 9pm; before 6am"}),
    "after-10pm-before-11am": intl.formatMessage({id:"AFTER_10PM_BEFORE_11AM", defaultMessage:"After 10pm; before 11am"}),
    "after-1230am-before-930am": intl.formatMessage({id:"AFTER_1230AM_BEFORE_930AM", defaultMessage:"After 12:30am; before 9:30am"}),
    "after-6pm-before-9am": intl.formatMessage({id:"AFTER_6PM_BEFORE_9AM", defaultMessage:"After 6pm; before 9am"}),
    "after-9pm-before-9am": intl.formatMessage({id:"AFTER_9PM_BEFORE_9AM", defaultMessage:"After 9pm; before 9am"}),
    "after-2pm-before-6pm": intl.formatMessage({id:"AFTER_2PM_BEFORE_6PM", defaultMessage:"After 2pm; before 6pm"}),

    "after-8am-before-midnight": intl.formatMessage({id:"AFTER_8AM_BEFORE_MN", defaultMessage:"After 8am; before midnight"}),
    "after-12p-before-8p": intl.formatMessage({id:"AFTER_12PM_BEFORE_8PM", defaultMessage:"After 12pm; before 8pm"}),
    "after-midnight-before-8am": intl.formatMessage({id:"AFTER_MN_BEFORE_8AM", defaultMessage:"After midnight; before 8am"}),

    "all-hours": intl.formatMessage({id:"ALL_HOURS", defaultMessage:"All hours (unpredictable)"}),
  }

  return (
    <Select
      id="home-charging-advisor-charging-pattern"
      value={userPrefs.get('chargingPatternId')}
      optionNames={chargingPatterns.map(p => chargingPatternsTranslations[p.id])}
      optionValues={chargingPatterns.map(p => p.id)}
      handler={(e) => userPrefs.set({ chargingPatternId: e.target.value })}
      label={<FormattedMessage
        id="hcaWhenWillYouCharge"
        defaultMessage="When will you charge?"
        description="When will you charge?"
      />}
    />
  );
};

export default SelectChargingPattern;
