import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { Link } from "react-router-dom";

import calcCharging from "../../../functions/vehicle/Charging/calcCharging";
import calcEmissions from "../../../functions/vehicle/Emissions/calcEmissions";
import { FormatAsInt, FormatAsThousands, FormatAsTime, FormatCarName, FormatAsDollars } from "../../../utils/Helpers/Format";

import VehicleImage from "../../../components/VehicleImage/VehicleImage"
import getPaymentDetails from "../../../functions/vehicle/getPaymentDetails"
import sortIncentives from "../../../functions/incentives/Sort/sortIncentives"
import isPHEV from "../../../functions/vehicle/isPHEV"
import ToolTip from "../../../components/shared/ToolTip/ToolTip"
import Uuid from "../../../utils/Uuid/Uuid"

import "./ComparedCarDetails.scss";

import { useIntl } from 'react-intl';

const DetailColumn = ({ rowClassName, title, value, columns, tooltipText }) => {

  const renderTooltip = tooltipText ? (
    <ToolTip
      message={tooltipText}
      id={title.toLowerCase().replace(/\s+/g, "_") + "_label_tooltip" + Uuid()}
    />
  ) : null;
  
  return (
    <div className={rowClassName}>
      <div className={`DetailColumn col-${columns || 6}`}>
        <p className="title">
          {title}
          {renderTooltip}
        </p>
        <p className="h5">{value}</p>
      </div>
    </div>
  )
};

DetailColumn.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.number
};

DetailColumn.defaultProps = {
  columns: 12,
  value: "",
  rowClassName: "row"
};

const ComparedCarDetails = ({ vehicle, compareUsedVehicles }) => {

  const userPrefs = useContext(UserPrefsContext);
  const milesDrivenAnnually = userPrefs.get("milesDrivenAnnually");
  const intl = useIntl()

  let paymentDetails = {}

  if (vehicle) {
    paymentDetails = getPaymentDetails(
      vehicle,
      userPrefs.get("monthsOfOwnership"),
      userPrefs.get("interestRateAsBasisPoints"),
      milesDrivenAnnually,
      userPrefs.get("salesTax"),
    )
  }

  let evImgs = vehicle.images ? vehicle.images.map( (n) =>
  (n.url_thumbnail == null) ?  [] : 
  (n.url_full == null) ?  []: 
  [n]) : [];

  //Flatten evImgs into a single array                  
  const evImgSrc = [].concat.apply([], evImgs);

  const mainVehicleImage = vehicle.images ? (vehicle.images.length >= 0 ? evImgSrc[0] : "") : "";

  const isGasTest = (fuelType, ifYes, ifNot) => {
    return (fuelType === "gas") ? ifYes : ifNot;
  }

  const fuelTypeNames = { 
    PHEV: intl.formatMessage({ id: "vehicle.phev", defaultMessage: "Electricity and Gasoline"}), 
    BEV: intl.formatMessage({ id: "vehicle.bev", defaultMessage: "Electricity"}),
  }

  const vehicleTypes = {
    Sedan: intl.formatMessage({ id: "vehicle.type.sedan", defaultMessage: "Sedan"}),
    Hatchback: intl.formatMessage({ id: "vehicle.type.hatchback", defaultMessage: "Hatchback"}),
    Coupe: intl.formatMessage({ id: "vehicle.type.coupe", defaultMessage: "Coupe"}),
    Crossover: intl.formatMessage({ id: "vehicle.type.crossover", defaultMessage: "Crossover"}),
    Minivan: intl.formatMessage({ id: "vehicle.type.minivan", defaultMessage: "Minivan"}),
    SUV: intl.formatMessage({ id: "vehicle.type.suv", defaultMessage: "SUV"}),
    Wagon: intl.formatMessage({ id: "vehicle.type.wagon", defaultMessage: "Wagon"}),
    Truck: intl.formatMessage({ id: "vehicle.type.truck", defaultMessage: "Truck"})
  }

  const usedIncentives = vehicle.fuel === "gas" ? null : vehicle.incentives.filter(incentive => incentive.applicable_to_used_vehicles)

  const totalSavings = usedIncentives ? sortIncentives(usedIncentives).total("eligible-rebates") : null;

  const renderVehicle = (fuelType) => {
    return (
      <>
        <DetailColumn rowClassName="row afterIncentives" title={intl.formatMessage({ id: "vehicle.afterIncentives", defaultMessage: "After Incentives"})}
          value={isGasTest(fuelType, FormatAsDollars(vehicle.msrp), compareUsedVehicles ? `${FormatAsDollars(vehicle.lowerBoundMsrp - totalSavings)} - ${FormatAsDollars(vehicle.upperBoundMsrp - totalSavings)}` : FormatAsDollars(paymentDetails.afterIncentives))}
        />
        {!compareUsedVehicles &&
        <DetailColumn rowClassName="row msrp" title={intl.formatMessage({ id: "vehicle.msrp", defaultMessage: "MSRP"})} value={FormatAsDollars(vehicle.msrp)} />
        }
        
        <DetailColumn rowClassName="row estimatedIncentives" title={intl.formatMessage({ id: "vehicle.estimatedIncentives", defaultMessage: "ESTIMATED INCENTIVES"})}
          value={`${isGasTest(fuelType, "N/A", compareUsedVehicles ? FormatAsDollars(totalSavings) : FormatAsDollars(paymentDetails.totalIncentivesForPurchase))}`}
        />
        
        <DetailColumn rowClassName="row vehicleType" title={intl.formatMessage({ id: "vehicle.type", defaultMessage: "TYPE"})} value={vehicleTypes[vehicle.form_factor]} />
        
        <DetailColumn rowClassName="row fuelType" title={intl.formatMessage({ id: "vehicle.fuelType", defaultMessage: "FUEL TYPE"})} value={`${isGasTest(fuelType, intl.formatMessage({ id: "gasoline", defaultMessage: "Gasoline"}), fuelTypeNames[vehicle.fuel])}`} />
        
        <DetailColumn rowClassName="row batterySize" title={intl.formatMessage({ id: "vehicle.batterySize", defaultMessage: "BATTERY SIZE"})}
          value={isGasTest(fuelType, "N/A", `${vehicle.battery_capacity} kWh`)} tooltipText={compareUsedVehicles ? intl.formatMessage({ id: "ev.carDetails.usedBatterySizeTooltip", defaultMessage: "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns."}) : null} />

        <DetailColumn rowClassName="row electricRange" title={intl.formatMessage({ id: "vehicle.electricRange", defaultMessage: "ELECTRIC RANGE"})}
          value={isGasTest(fuelType, "N/A", `${vehicle.electric_range} ${intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"})}`)} tooltipText={compareUsedVehicles ? intl.formatMessage({ id: "ev.cardetails.electricRange", defaultMessage: "To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns."}) : null}
        />
        <DetailColumn rowClassName="row timeToChargeL2" title={intl.formatMessage({ id: "vehicle.timeToChargeLvlTwo", defaultMessage: "TIME TO CHARGE - LEVEL 2"})}
          value={isGasTest(fuelType, "N/A", timeToChargeLevel2)}
        />
        {!isPHEV(vehicle) && 
        <DetailColumn title={intl.formatMessage({ id: "vehicle.milesPerThirtyFastCharge", defaultMessage: "MILES PER 30 MIN OF FAST CHARGING"})}
          value={isGasTest(fuelType, "N/A", chargingSpeedText)}
        />
        }
        <DetailColumn title={intl.formatMessage({ id: "vehicle.coTwoEmissions", defaultMessage: "CO2 EMISSIONS REDUCTION"})}
          value={isGasTest(fuelType, "N/A", emissionsText)}
        />
        {fuelType === "gas" ? null : 
          <Link
            to={compareUsedVehicles ? `/used-vehicles/${vehicle.make.replace(/ /g,"_")}:${vehicle.model.replace(/ /g,"_")}:${vehicle.trim.replace(/ /g,"_")}` : `/vehicles/${vehicle.handle}`}
            className="btn btn-block btn-dte"
            role="button"
          >
            {`${intl.formatMessage({ id: "vehicle.view", defaultMessage: "View"})} ${FormatCarName(vehicle)}`}
          </Link>
        }
      </>
    )
  }

  const electricMilesPortionForPhev = userPrefs.get(
    "electricMilesPortionForPhev"
  );

  const milesPerHalfHourOfCharge = FormatAsInt(
    calcCharging.milesPerHalfHourOfCharge(
      vehicle.electric_efficiency,
      "dc_fast_charger"
    )
  );
  const chargingSpeedText = `~ ${milesPerHalfHourOfCharge} ${intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"})}`;

  const co2ReductionInTons = FormatAsThousands(
    calcEmissions.reductionInLbs(
      vehicle,
      milesDrivenAnnually,
      electricMilesPortionForPhev
    )
  );

  const treesPlanted = FormatAsThousands(
    calcEmissions.treesPlanted(
      vehicle,
      milesDrivenAnnually,
      electricMilesPortionForPhev
    )
  );

  const timeToChargeLevel2 = FormatAsTime(
    calcCharging.timeForFullBattery(
      vehicle.battery_capacity,
      vehicle.ac_charging_power,
      "level_2"
    )
  )

  const emissionsText = `${co2ReductionInTons} lbs/yr = ${treesPlanted} ${intl.formatMessage({ id: "vehicle.trees", defaultMessage: "trees"})}`;


  return (
    <div className="ComparedCarDetails row">
      <div className="col-sm-12">
        <VehicleImage image={mainVehicleImage} size="full" alt={FormatCarName(vehicle)} />

        {renderVehicle(vehicle.fuel)}

      </div>
    </div>
  );
};

ComparedCarDetails.propTypes = {
  vehicle: PropTypes.object.isRequired,
  compareUsedVehicles: PropTypes.bool
};

export default ComparedCarDetails;
