import React from "react";
import { chartOptions, chartPlugins } from "./chartOptions";
import { Bar } from "react-chartjs-2";
import "./RateComparisonChart.scss";
import { useIntl } from "react-intl";

const RateAdvisorChart = ({
  title,
  chartData,
  savingsColor,
  children,
  className,
}) => {
  const intl = useIntl();

  let monthText = intl.formatMessage({ id: "perMonth", defaultMessage: "/mo" });

  const options = chartOptions({ savingsColor, monthText });

  return (
    <div className={`RateAdvisorChart ${className}`}>
      <h2 className="title">{title}</h2>
      <Bar data={chartData} options={options} plugins={chartPlugins} />
      {children}
    </div>
  );
};

export default RateAdvisorChart;
