import { MN_A01_ID } from '../../constants/';

const mn_a01 = {
  name: MN_A01_ID,
  title: "Residential Service",
  separatelyMetered: false,
  isBundled: false,
  includeHomeWiringEstimator: true,
  upfrontChargerCost: 999,
  rateElements: [
    {
      name: "Basic Service Charge",
      rateElementType: "FixedPerMonth",
      rateComponents: [
        {
          charge: 8,
          name: "Per Month",
        },
      ],
    },
    {
      name: "Energy charge per kWh",
      rateElementType: "EnergyTimeOfUse",
      rateComponents: [
        {
          charge: 0.10301,
          months: [5, 6, 7, 8],
          name: "June through September",
        },
        {
          charge: 0.08803,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: "October through May",
        },
      ],
    },
  ],
};

export default mn_a01;
