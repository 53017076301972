import React from "react";
import PropTypes from "prop-types";

import "./ChargingStationCard.scss";

const ChargingStationCard = props => {
  let station = props.chargingStation || {};
  let address = `${station.street_address}, ${station.city}, ${station.state} ${
    station.zip
  }`;
  return (
    <div className="ChargingStationCard">
      <div>
        <b>{station.station_name}</b> {address}
        &nbsp;
        <span className="badge badge-secondary">{station.access_code}</span>
      </div>
    </div>
  );
};

ChargingStationCard.propTypes = {
  chargingStation: PropTypes.object
};

export default ChargingStationCard;
