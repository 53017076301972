import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Select from "../../shared/InputElements/Select";
import { useIntl } from 'react-intl';

const SelectPurchaseMethod = ({
  id = "select-purchase-method",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({ id: "ev.purchaseMethod", defaultMessage: "Purchase Method"})
  const tooltip = intl.formatMessage({ id: "ev.purchaseMethodToolTip", defaultMessage: "In a lease, you have to return the car after the lease period. In the case of loan financing, you keep the car after the loan is paid off."})

  return (
    <Select
      id={id}
      value={userPrefs.get("purchaseMethod")}
      label={label}
      optionNames={[intl.formatMessage({ id: "ev.cash", defaultMessage: "Cash"}), intl.formatMessage({ id: "ev.loan", defaultMessage: "Loan"}), intl.formatMessage({ id: "ev.lease", defaultMessage: "Lease"})]}
      optionValues={["cash", "loan", "lease"]}
      tooltip={tooltip}
      handler={e => userPrefs.set({ purchaseMethod: e.target.value })}
    />
  );
};

export default SelectPurchaseMethod;

SelectPurchaseMethod.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string
};
