import { NM_RESIDENTIAL_ID } from '../../constants/';

const nm_r = {
  name: NM_RESIDENTIAL_ID,
  title: 'Regular Residential',
  separatelyMetered: false,
  isBundled: false,
  includeHomeWiringEstimator: true,
  upfrontChargerCost: 999,
  rateElements: [
    {
      name: 'Basic Service Charge',
      rateElementType: 'FixedPerMonth',
      rateComponents: [
        {
          charge: 9.6,
          name: 'Per Month',
        },
      ],
    },
    {
      name: 'Energy charge per kWh',
      rateElementType: 'EnergyTimeOfUse',
      rateComponents: [
        {
          charge: 0.083237,
          months: [5, 6, 7, 8],
          name: 'June through September',
        },
        {
          charge: 0.069364,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          name: 'October through May',
        },
      ],
    },
  ],
};

export default nm_r;
