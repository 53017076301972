import React from "react";
import { FormattedMessage } from "react-intl";

const Requirements = () => {
    return (
        <>
            <p>
                <FormattedMessage
                    id="MNTimeofDaySeparateMeter_toparticipate"
                    defaultMessage="To participate in the EV Pricing Plan, you will need to have a second meter installed. You’ll need to work with a certified electrician to install a meter socket and perform any additional wiring. The upfront cost of this work will vary per house and depend heavily on the distance between your charging location and the location of your current service panel and electric meter."
                />
            </p>
        </>
    );
}

export default Requirements;