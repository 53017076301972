import {
  BUY_CHARGER_ID,
  HOME_ENERGY_COST_ID,
  EV_ENERGY_COST_ID,
  GAS_ENERGY_COST_ID,
} from '../client_customizations/constants/';

const specialAdjustments = ({
  chargerPaymentType,
  rate: {
    id,
    data: {
      isBundled,
      monthlyDiscountForBuyingCharger = 0,
      monthlyDiscountForSwitchingFromRate = {},
    }
  },
  currentRate: {
    id: currentRateId,
  },
  isOptimizingCharge,
}) => {
  const isBuyingCharger = chargerPaymentType === BUY_CHARGER_ID;
  const isBuyingChargerAdjustment = isBuyingCharger ? monthlyDiscountForBuyingCharger : 0;
  // Switching from certain rates to other rates can have special discounts.
  // e.g. switching to the WI EV accelerate rate while on Time of Day whole home
  // works out to $13/mo when renting the charger and $3/mo when buying it.
  let switchingDiscount = monthlyDiscountForSwitchingFromRate[currentRateId] ?
    monthlyDiscountForSwitchingFromRate[currentRateId][chargerPaymentType] :
    0;

  // Optimizing charge is a yearly $50 credit
  const isOptimizingChargeAdjustment = isOptimizingCharge ? -50 / 12 : 0; 

  // Per XE (Alisa), estimate charger maintenance to be ~$30/yr (or $2.50/mo)
  // for rates that are not bundled (regardless of buy or rent)
  return isBundled ?
    (isBuyingChargerAdjustment + switchingDiscount + isOptimizingChargeAdjustment) :
    2.5 + isOptimizingChargeAdjustment;
};

const rateTotals = ({
  rates,
  currentRate,
  rateCostCalculator,
  gasolineCostCalculator,
  upfrontChargerCostCalculator,
  publicChargingElectricCostPerMonth,
  chargerPaymentType,
  selectedEnergyCostIds,
  isOptimizingCharge,
}) => {
  const {
    equivalentGasVehicle: equivalentGasVehicleGasolineCost,
    ev: evGasolineCost,
  } = gasolineCostCalculator.monthlyCosts();

  return rates.map((rate, idx) => {
    const isCurrentUserSituation = idx === 0;

    const {
      home: homeElectricityCost,
      ev: evElectricityCost,
    } = rateCostCalculator.monthlyCosts(rate);

    const upfront = isCurrentUserSituation ?
      {
        total: 0,
        chargerPlusInstall: 0,
        wiring: 0,
        meter: 0,
      } : {
        total: upfrontChargerCostCalculator.total(rate),
        chargerPlusInstall: upfrontChargerCostCalculator.chargerPlusInstall(rate),
        wiring: upfrontChargerCostCalculator.wiring(rate),
        meter:upfrontChargerCostCalculator.meter(rate),
      };

    const gasolineCost = (() => {
      if (!selectedEnergyCostIds.includes(GAS_ENERGY_COST_ID)) return 0;
      if (isCurrentUserSituation) return equivalentGasVehicleGasolineCost;
      return evGasolineCost;
    })();

    const evCost = (() => {
      if (!selectedEnergyCostIds.includes(EV_ENERGY_COST_ID)) return 0;
      if (isCurrentUserSituation) return 0;
      const chargerAdjustment = specialAdjustments({
        chargerPaymentType,
        rate,
        currentRate,
        isOptimizingCharge,
      });

      return publicChargingElectricCostPerMonth + chargerAdjustment + evElectricityCost;
    })();

    const homeCost = selectedEnergyCostIds.includes(HOME_ENERGY_COST_ID) ?
      homeElectricityCost :
      0;

    const totalMonthlyCost = gasolineCost + evCost + homeCost;

    return {
      id: `${rate.id}${idx === 0 ? "-current" : ""}`,
      title: rate.title.join(" "),
      [HOME_ENERGY_COST_ID]: homeCost,
      monthly: totalMonthlyCost,
      [EV_ENERGY_COST_ID]: evCost,
      [GAS_ENERGY_COST_ID]: gasolineCost,
      upfront,
      tenYear: totalMonthlyCost * 12 * 10 + upfront.total,
      canDetermineUpfrontCosts: !isCurrentUserSituation,
      isBundled: rate.data.isBundled
    };
  });
};

export default rateTotals;
