import React from "react";
import PropTypes from "prop-types";

const RenderAssumption = ({ assumption }) => {
  let linkElement = assumption.link ? (
    <a target="new" href={assumption.link}>
      {assumption.valueAsFormatted}
    </a>
  ) : (
    assumption.valueAsFormatted.toString()
  );

  return (
    <p
      className="legal-disclaimer"
      style={{ fontSize: "12px", lineHeight: "15px" }}
    >
      {assumption.title}
      <span style={{ float: "right" }}>{linkElement}</span>
    </p>
  );
};

export default RenderAssumption;

RenderAssumption.propTypes = {
  assumption: PropTypes.object
};
