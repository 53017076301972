import React from 'react';
import Tooltip from '../../../components/shared/ToolTip/ToolTip';
import { FormatAsDollars } from '../../../utils/Helpers/Format';
import { useIntl } from 'react-intl';

const ChargerCostTooltip = ({ id, meterCost }) => {
  const intl = useIntl()

  return (
    <Tooltip
      id={id}
      message={(
        <>
          {intl.formatMessage({ id: "estimatedDIYCost", defaultMessage: "Estimated average DIY cost:" })}
          <br />
          $699 {intl.formatMessage({ id: "forLevelTwoCharger", defaultMessage: "for level 2 charger" })} +
          <br />
          $300 {intl.formatMessage({ id: "forInstallation", defaultMessage: "for installation" })} {meterCost > 0 && " +"}
          {meterCost > 0 && (
            <>
              <br />
              {FormatAsDollars(meterCost)} {intl.formatMessage({ id: "forSeparateMeter", defaultMessage: "for separate meter" })}
            </>
          )}
        </>
      )}
    />
  );
};

export default ChargerCostTooltip;
