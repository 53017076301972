import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Uuid from "../../../utils/Uuid/Uuid"

import { useIntl } from 'react-intl';

const vehicleTypeOptions = (intl, compareUsedVehicles) => {
  const fuelTypes = compareUsedVehicles ? ["PHEV", "BEV"] : ["PHEV", "BEV", "gas"]
  const fuelTypesSpelledOut = {
    PHEV: intl.formatMessage({ id: "vehicle.plugInHybrid", defaultMessage: "Plug in Hybrid"}), 
    BEV: intl.formatMessage({ id: "vehicle.allElectric", defaultMessage: "All Electric"}), 
    gas: intl.formatMessage({ id: "vehicle.gas", defaultMessage: "Gasoline"})
  }
  return fuelTypes.map(fuelType => {
    return (
      <option value={fuelType} key={fuelType}>
        {fuelTypesSpelledOut[fuelType]}
      </option>
    );
  });
};

const vehicleMakeOptions = (vehicles) => {

  let vehicleMakes = new Set()
  vehicles.forEach(vehicle => {
    if (vehicle) {
      vehicleMakes.add(vehicle.make)
    }
  })

  vehicleMakes = [...vehicleMakes]
  
  vehicleMakes.sort((a, b) => {
    return a.localeCompare(b)
  })

  return vehicleMakes.map(make => {
    return (
      <option
        value={make}
        key={make}
      >
        {make}
      </option>
    )
  })
}

const vehicleModelOptions = (vehicles, makeOption, compareUsedVehicles) => {
  let vehicleModels = new Set()

  if (makeOption) {
    vehicles = vehicles.filter(vehicle => {
      const modelAndTrim = vehicle.trim ? `${vehicle.model} ${vehicle.trim}` : `${vehicle.model}`
      if (vehicle.make === makeOption) {
        if (!vehicleModels.has(modelAndTrim)){
          vehicleModels.add(modelAndTrim)
          return vehicle
        }
      }
      return false
    })
    return vehicles.map(vehicle => {
      const modelAndTrim = vehicle.trim ? `${vehicle.model} ${vehicle.trim}` : `${vehicle.model}`
      return (
        <option
          value={modelAndTrim}
          key={Uuid()}
          data-handle={compareUsedVehicles ? vehicle.display_name : vehicle.handle}
        >
          {modelAndTrim}
        </option>
      )
    }
  )}
}

const vehicleYearOptions = (vehicles, modelOption) => {
  if (modelOption) {
    vehicles = vehicles.filter(vehicle => vehicle.display_name.trim().toLowerCase() === modelOption.toLowerCase())
    vehicles.sort((v1, v2) => v2.model_year - v1.model_year)

    return vehicles.map(vehicle => {
      return (
        <option
          value={vehicle.model_year}
          key={Uuid()}
          data-handle={vehicle.handle}
        >
          {vehicle.model_year}
        </option>
      )
    })
  }
}

const ComparedVehicle = ({ electricVehicles, gasolineVehicles, setVehicle, compareUsedVehicles }) => {

  const [evOptions, setEvOptions] = useState([]);
  const [fuelOption, setFuelOption] = useState("");
  const [makeOption, setMakeOption] = useState("");
  const [modelOption, setModelOption] = useState("");
  const [yearOption, setYearOption] = useState("");

  useEffect(() => {
    setFuelOption("")
    setMakeOption("")
    setModelOption("")
    setYearOption("")

  }, [compareUsedVehicles])

  useEffect(() => {
    let vehicles = compareUsedVehicles
      ? electricVehicles
      : electricVehicles.concat(gasolineVehicles);

      if (fuelOption) {
        if (fuelOption === "PHEV" || fuelOption === "BEV") {
          vehicles = electricVehicles.filter((vehicle) => {
            return vehicle.fuel === fuelOption;
          });
        } else if (fuelOption === "gas") {
          vehicles = gasolineVehicles.filter((vehicle) => {
            return vehicle.fuel === fuelOption;
          });
        }
      } 
      
      setEvOptions(vehicles);
  }, [compareUsedVehicles, electricVehicles, gasolineVehicles, fuelOption]);

  const allVehicles = compareUsedVehicles ? electricVehicles : electricVehicles.concat(gasolineVehicles)

  const intl = useIntl()

  if (allVehicles.length === 0) return null;

  const onChangeFuelType = e => {
    setFuelOption(e.target.value);
    setMakeOption('')
    setModelOption('')
  };

const onChangeMake = e => {
  const newMakeType = e.target.value
  setMakeOption(newMakeType)
  setModelOption('')
  setYearOption('')
}

const onChangeModelType = e => {
  const newModelType = e.target.value
  const vehicleHandle = e.target.options[e.target.selectedIndex].dataset.handle

  if (newModelType === 'Select a Model') {
    setModelOption(newModelType)
    return
  } 

  const selectedVehicle = evOptions.find(vehicle => {
    return vehicle.handle === vehicleHandle
  })

  setFuelOption(selectedVehicle.fuel)
  setModelOption(newModelType)
  setYearOption('')
  !compareUsedVehicles && setVehicle(selectedVehicle)
}

const onChangeYear = e => {
  const newYear = e.target.value
  setYearOption(newYear)
  const vehicleHandle = e.target.options[e.target.selectedIndex].dataset.handle

  if (newYear === 'Select a Year') {
    setYearOption(newYear)
    return
  } 

  const selectedVehicle = evOptions.find(vehicle => {
    return vehicle.handle === vehicleHandle
  })

  setVehicle(selectedVehicle)
}

  return (
    <div className="ComparedVehicle">
      <div className="row" style={{justifyContent: "center"}}>
        <div className="col-sm-10">
          <div className="form-group">
            <select
              className="form-control select-dte"
              value={fuelOption}
              onChange={onChangeFuelType}
              title={intl.formatMessage({ id: "compareVehicles.selectFuel", defaultMessage: "Select a Fuel Type"})}
            >
              <option defaultValue="">
                {intl.formatMessage({ id: "compareVehicles.selectFuel", defaultMessage: "Select a Fuel Type"})}
              </option>
              {vehicleTypeOptions(intl, compareUsedVehicles)}
            </select>
          </div>

          <div className="form-group">
            <select
              className="form-control select-dte"
              value={makeOption}
              onChange={onChangeMake}
              title={intl.formatMessage({ id: "compareVehicles.selectMake", defaultMessage: "Select a Make"})}
            >
              <option defaultValue="">
                {intl.formatMessage({ id: "compareVehicles.selectMake", defaultMessage: "Select a Make"})}
              </option>
              {vehicleMakeOptions(evOptions)}
            </select>
          </div>

          <div className="form-group">
            <select
              className="form-control select-dte"
              value={modelOption}
              onChange={onChangeModelType}
              disabled={makeOption ? null : true}
              title={intl.formatMessage({ id: "compareVehicles.selectModel", defaultMessage: "Select a Model"})}
            >
              <option defaultValue="">
                {intl.formatMessage({ id: "compareVehicles.selectModel", defaultMessage: "Select a Model"})}
              </option>
              {vehicleModelOptions(evOptions, makeOption)}
            </select>
          </div>
          {compareUsedVehicles && 
          <div className="form-group">
            <select
              className="form-control select-dte"
              value={yearOption}
              onChange={onChangeYear}
              disabled={modelOption === "" ? true : null}
              title={intl.formatMessage({ id: "compareVehicles.selectYear", defaultMessage: "Select a Year"})}
            >
              <option defaultValue="">
                {intl.formatMessage({ id: "compareVehicles.selectYear", defaultMessage: "Select a Year"})}
              </option>
              {vehicleYearOptions(evOptions, modelOption)}
            </select>
          </div>}
        </div>
      </div>
    </div>
  );
};

ComparedVehicle.propTypes = {
  vehicle: PropTypes.object,
  electricVehicles: PropTypes.array,
  setVehicle: PropTypes.func,
  compareUsedVehicles: PropTypes.bool,
  usedElectricVehicles: PropTypes.array
};

ComparedVehicle.defaultProps = {
  vehicle: {},
  electricVehicles: [],
  usedElectricVehicles: []
};

export default ComparedVehicle;
