import React from "react";
import PropTypes from "prop-types";

import HowProgramWorks from "../EasyChargingHome/HowProgramWorks";
import EligibleChecklist from "../EasyChargingHome/EligibleChecklist";

import GrandTitleParas from "../shared/GrandTitledParas/GrandTitledParas";
import HR from "../shared/HR/HR";
import IconHardHat from "../../assets/images/icons/icon-hardhat.svg";
import IconZappyBolt from "../../assets/images/icons/icon-zappy-bolt.svg";
import IconMail from "../../assets/images/icons/icon-mail.png";
import { FormattedMessage } from "react-intl";
import GaTracker from "../../../utils/GaTracker/GaTracker";

import "./HomeWiringRebateCo.scss";
import { COLORADO, NEW_MEXICO } from "../../../constants";
import { RenderByState } from "../../../components/RenderByState";
// import chargers from "../EasyChargingHome/chargers/chargers";
// import ChargerChoice from "../EasyChargingHome/ChargerChoice";

const coloradoText = [
  <FormattedMessage
    id="scheduleHomeWiring"
    defaultMessage="Schedule home wiring with a licensed electrician and/or purchase your eligible Level 2 charger."
    description="Schedule home wiring with a licensed electrician and/or purchase your eligible Level 2 charger."
  />,
  <FormattedMessage
    id="enrollInOptimizeYourCharge"
    defaultMessage="Enroll in Optimize Your Charge and save with an off-peak charging schedule."
    description="Enroll in Optimize Your Charge and save with an off-peak charging schedule."
  />,
  <FormattedMessage
    id="submitYourApplicationHWR"
    defaultMessage="Submit your application for your EV Charger and Wiring Rebate."
    description="Submit your application for your EV Charger and Wiring Rebate."
  />,
];

const newMexicoText = [
  <FormattedMessage
    id="scheduleHomeWiring"
    defaultMessage="Schedule home wiring with a licensed electrician and/or purchase your eligible Level 2 charger."
    description="Schedule home wiring with a licensed electrician and/or purchase your eligible Level 2 charger."
  />,
  <FormattedMessage
    id={`enrollInOptimizeYourCharge-nm`}
    defaultMessage="Enroll in Optimize Your Charge or the Time of Use Rate and save with an off-peak charging schedule."
    description="Enroll in Optimize Your Charge or the Time of Use Rate and save with an off-peak charging schedule."
  />,
  <FormattedMessage
    id="submitYourApplicationHWR"
    defaultMessage="Submit your application for your EV Charger and Wiring Rebate."
    description="Submit your application for your EV Charger and Wiring Rebate."
  />,
];

const getHowHomeWiringWorks = (stateName = COLORADO) => {
  const images = [IconHardHat, IconZappyBolt, IconMail];

  switch (stateName) {
    case NEW_MEXICO:
      return {
        images,
        text: newMexicoText,
        scrollTo: "home-wiring-costs",
      };
    default:
      return {
        images,
        text: coloradoText,
        scrollTo: "home-wiring-costs",
      };
  }
};

const renderEnrollmentDetails = (stateName, enrollURL) => {
  const handleHomeWiringClick = () => {
    GaTracker.trackEvent({
      category: "Xcel Domain Links",
      action: "Clicked Xcel Domain Link",
      label: "Clicked Home Wiring Rebate Link",
    });
  };

  return (
    <>
      <section className="container works-elible-enroll">
        <div className="row">
          <GrandTitleParas
            classNames="how-works"
            customID="how-works"
            colonHeader={
              <FormattedMessage
                id="howItWorks"
                defaultMessage="How It Works"
                description="How It Works"
              />
            }
          >
            <HowProgramWorks
              stateName={stateName}
              text={getHowHomeWiringWorks(stateName).text}
              images={getHowHomeWiringWorks(stateName).images}
              smoothScroll={getHowHomeWiringWorks(stateName).scrollTo}
            />
          </GrandTitleParas>
          <GrandTitleParas
            classNames="eligible-if"
            customID="eligible-if"
            colonHeader={
              <FormattedMessage
                id="youAreEligible"
                defaultMessage="You're eligible if you:"
                description="You're eligible if you:"
              />
            }
          >
            <EligibleChecklist stateName={stateName} savePlus={true} />
          </GrandTitleParas>
        </div>
      </section>
      <HR />
      <section className="container home-wiring-costs" id="home-wiring-costs">
        <div className="row">
          <GrandTitleParas
            classNames="home-wiring-costs"
            customID="home-wiring-costs"
            colonHeader={
              <FormattedMessage
                id="homeWiringCosts"
                defaultMessage="Home Wiring Costs"
                description="Home Wiring Costs"
              />
            }
          >
            <p>
              <FormattedMessage
                id="hwrCOWiringCosts"
                defaultMessage="Level 2 chargers need a dedicated 240-volt circuit (like an electric clothes dryer). The cost for wiring a 240-volt circuit depends on your home’s unique electrical setup and charging site. To understand potential costs, use our {link}."
                description="Home Wiring Costs 1st Paragraph"
                values={{
                  link: (
                    <a href="/home-charging-advisor">
                      <FormattedMessage
                        id="homeWiringEstimatorTool"
                        defaultMessage="Home Wiring Estimator tool"
                        description="Home Wiring Estimator tool"
                      />
                    </a>
                  ),
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="hwrCOWiringCostsTwo"
                defaultMessage="Even easier, enroll in our {link}, and one of our electricians can help you take advantage of this rebate up front while installing a Level 2 charger. Our qualified electricians can help you understand the actual costs of wiring work unique to your home."
                description="Home Wiring Costs 2nd Paragraph"
                values={{
                  link: (
                    <RenderByState
                      stateName={stateName}
                      options={{
                        default: (
                          <a href="/ev-accelerate-at-home-co">
                            <FormattedMessage
                              id="evAccelerateAtHomeProgram"
                              defaultMessage="EV Accelerate At Home program"
                              description="EV Accelerate At Home program"
                            />
                          </a>
                        ),
                        [NEW_MEXICO]: (
                          <a href="/ev-accelerate-at-home-nm">
                            <FormattedMessage
                              id="evAccelerateAtHomeProgram"
                              defaultMessage="EV Accelerate At Home program"
                              description="EV Accelerate At Home program"
                            />
                          </a>
                        ),
                      }}
                    />
                  ),
                }}
              />
            </p>
            <div class="colon-header">
              <FormattedMessage
                id="howToApply"
                defaultMessage="How to apply for the EV Charger and Wiring Rebate"
                description="How to apply for the EV Charger and Wiring Rebate"
              />
            </div>
            <p>
              <RenderByState
                stateName={stateName}
                options={{
                  default: (
                    <FormattedMessage
                      id="hwrStepOne"
                      defaultMessage="Step 1. {link}"
                      description="Step 1. Apply for Optimize Your Charge"
                      values={{
                        link: (
                          <a href="/optimize-your-charge">
                            <FormattedMessage
                              id="applyForOptimizeYourCharge"
                              defaultMessage="Apply for Optimize Your Charge"
                              description="Apply for Optimize Your Charge"
                            />
                          </a>
                        ),
                      }}
                    />
                  ),
                  [NEW_MEXICO]: (
                    <FormattedMessage
                      id={`hwrStepOne-nm`}
                      defaultMessage="Step 1. {link} if not currently enrolled in the Whole-Home Time of Use Rate."
                      description="Step 1. Apply for Optimize Your Charge"
                      values={{
                        link: (
                          <a href="/optimize-your-charge-nm">
                            <FormattedMessage
                              id="applyForOptimizeYourCharge"
                              defaultMessage="Apply for Optimize Your Charge"
                              description="Apply for Optimize Your Charge"
                            />
                          </a>
                        ),
                      }}
                    />
                  ),
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="hwrStepTwo"
                defaultMessage="Step 2. {link}"
                description="Step 2. Apply for the Home Wiring Rebate"
                values={{
                  link: (
                    <RenderByState
                      stateName={stateName}
                      options={{
                        default: (
                          <a
                            href="https://my.xcelenergy.com/MyAccount/s/savings/ev-charger-and-wiring-rebate-details"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleHomeWiringClick}
                          >
                            <FormattedMessage
                              id="applyForHomeWiringRebate"
                              defaultMessage="Apply for the Home Wiring Rebate"
                              description="Apply for the Home Wiring Rebate"
                            />
                          </a>
                        ),
                        [NEW_MEXICO]: (
                          <a
                            href="https://my.xcelenergy.com/MyAccount/s/savings/ev-charger-and-wiring-rebate-details"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleHomeWiringClick}
                          >
                            <FormattedMessage
                              id="applyForHomeWiringRebate"
                              defaultMessage="Apply for the Home Wiring Rebate"
                              description="Apply for the Home Wiring Rebate"
                            />
                          </a>
                        ),
                      }}
                    />
                  ),
                }}
              />
            </p>
          </GrandTitleParas>
        </div>
      </section>
    </>
  );
};

const HomeWiringRebateCo = ({ stateName, enrollURL }) => {
  return (
    <div className="easy-charging-home">
      <section className="overview">
        {renderEnrollmentDetails(stateName, enrollURL)}
      </section>
    </div>
  );
};

export default HomeWiringRebateCo;

HomeWiringRebateCo.propTypes = {
  stateName: PropTypes.string,
  enrollURL: PropTypes.string,
};
