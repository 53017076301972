import "react-app-polyfill/ie11";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Root from "./client_customizations/containers/Root/Root";
import { BrowserRouter as Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import * as serviceWorker from "./serviceWorker";
import "./utils/polyfills";
import messages_en from "./client_customizations/translations/en.js";
import messages_esp from "./client_customizations/translations/esp.js";
import { gql, GraphQLClient } from "graphql-request";

const App = () => {
  const [language, setLanguage] = useState("EN");
  const [messages, setMessages] = useState({
    EN: messages_en,
    ES: messages_esp,
  });

  const changeLanguage = (language) => {
    localStorage.setItem("language", language);
    setLanguage(language);
  };

  useEffect(() => {
    let key = localStorage.getItem("language");

    if (key) {
      setLanguage(key);
    }
  }, []);

  useEffect(() => {
    const fetchTranslations = async (token) => {
      let endpoint = process.env.REACT_APP_AMPLIFY_STAGING_ENDPOINT;
      let apiKey = process.env.REACT_APP_AMPLIFY_STAGING_API_KEY;

      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        endpoint = process.env.REACT_APP_AMPLIFY_PRODUCTION_ENDPOINT;
        apiKey = process.env.REACT_APP_AMPLIFY_PRODUCTION_API_KEY;
      }

      const graphQLClient = new GraphQLClient(endpoint, {
        headers: {
          "X-Api-Key": apiKey,
        },
      });

      let query = gql`
        {
          listEVShopperTranslations {
            items {
              web_id
              english
              spanish
            }
            nextToken
          }
        }
      `;

      if (token) {
        query = gql`
          {
            listEVShopperTranslations(nextToken: "${token}") {
              items {
              web_id
              english
              spanish
              }
              nextToken
            }
          }
        `;
      }

      const data = await graphQLClient.request(query);
      return data;
    };

    const setTranslations = async () => {
      const engTranslations = {};
      const esTranslations = {};
      let token;

      while (token !== null) {
        let data = await fetchTranslations(token);
        data.listEVShopperTranslations.items.forEach((translation) => {
          engTranslations[translation.web_id] = translation.english;
          esTranslations[translation.web_id] = translation.spanish;
        });
        token = data.listEVShopperTranslations.nextToken;
      }

      setMessages((prevState) => {
        return {
          ...prevState,
          EN: { ...prevState["EN"], ...engTranslations },
          ES: { ...prevState["ES"], ...esTranslations },
        };
      });
    };

    setTranslations();
  }, []);

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Router>
        <Root language={language} changeLanguage={changeLanguage} />
      </Router>
    </IntlProvider>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

// ReactDOM.render(
//   <IntlProvider locale={language} messages={messages[language]}>
//     <Router>
//         <Root changeLanguage={changeLanguage}/>
//     </Router>
//   </IntlProvider>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
