import React from 'react';
import ChargerCostTooltip from './ChargerCostTooltip';
import { FormatAsDollars } from '../../../utils/Helpers/Format';

const MobileRateTotals = ({ rateTotals, selectedEnergyCostIds }) => {
  const minUpfrontChargerCost = Math.min(
    ...rateTotals
    .filter(t => t.canDetermineUpfrontCosts)
    .map(t => t.upfront.chargerPlusInstall)
  );

  return (
    <div className="MobileRateTotals">
      {rateTotals.map((rateTotal, idx) => {
        return (
          <div className="MobileRateTotal" key={rateTotal.id}>
            <div className="h2">{rateTotal.title}</div>
            {idx === 0 && (
              <div className="h3">Current Rate, Without EV</div>
            )}
            <div className="row mb-4">
              <div className="col-12 mb-3">
                <div className="total-container">
                  <div className="title">
                    MONTHLY
                  </div>
                  <div className="h2">
                    {FormatAsDollars(rateTotal.monthly)}
                  </div>
                </div>
              </div>
              {rateTotal.canDetermineUpfrontCosts && (
                <>
                  <div className="col-12">
                    <div className="total-container pb-0">
                      <div className="title">
                        CHARGER + INSTALL
                        {!rateTotal.isBundled && (
                          <ChargerCostTooltip id={`mobile-charger-tooltip-${rateTotal.id}`}  meterCost={rateTotal.upfront.meter} />
                        )}
                      </div>
                      <div className={`h2  mb-0${rateTotal.upfront.chargerPlusInstall === minUpfrontChargerCost ? " lowest" : ""}`}>
                        {FormatAsDollars(rateTotal.upfront.chargerPlusInstall)}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="col-12">
                <div className="total-container">
                  <div className="title">OVER 10 YEARS</div>
                  <div className="h2">{FormatAsDollars(rateTotal.tenYear)}</div>
                </div>
              </div>
            </div>
            {idx !== rateTotals.length - 1 && <hr />}
          </div>
        );
      })}
    </div>
  );
};

export default MobileRateTotals;
