import useSupercluster from "use-supercluster";

const useMappedClusters = ({
  data,
  bounds,
  zoom,
  options = {
    radius: 250,
    maxZoom: 12
  }
}) => {
  const points = data.map(thing => {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(thing.longitude),
          parseFloat(thing.latitude)
        ]
      },
      properties: {
        cluster: false,
        data: thing
      },
    }
  });

  // Supercluster expects bounds in a different format than Google Maps uses
  const superclusterBounds = bounds && bounds.nw ? [
    bounds.nw.lng,
    bounds.se.lat,
    bounds.se.lng,
    bounds.nw.lat
  ] : [];


  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: superclusterBounds,
    zoom,
    options
  });

  const clusteredPoints = clusters.filter(c => c.properties.cluster);
  const individualPoints = clusters.filter(c => !c.properties.cluster)

  return {
    clusteredPoints,
    individualPoints,
    supercluster
  };
};

export default useMappedClusters;
