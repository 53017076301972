import React from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";

import {
  ALL_STATIONS_TAB_ID,
  DEALER_CATALOG_TAB_ID,
  ELECTRICIANS_TAB_ID,
  ROUTE_MAP_TAB_ID,
} from "../../../constants/mapTabs";
import ChargingMap from "../LocationMap/ChargingMap/ChargingMap";
import DealerMap from "../LocationMap/DealerMap";
import ElectriciansMap from "../../../components/LocationMap/ElectriciansMap";
import TravelRouteMap from "../../../components/LocationMap/TravelRouteMap/TravelRouteMap";
import { FormattedMessage } from "react-intl";

import "./TabbedMaps.scss";

const TabbedMaps = ({
  chargingStationsFilterFn,
  electricVehicles,
  usedElectricVehicles,
  oems,
  activeTabId,
  toggle,
  canIgnoreLowPowerStations = true,
  userLocation,
  dealerLocations,
  zipcode,
}) => {
  const MAP_HEADERS = {
    "tabbed-maps-all-stations-tab": {
      header: (
        <FormattedMessage
          id="findCharging"
          defaultMessage="Find Charging"
          description="Find Charging"
        />
      ),
      subHeading: (
        <FormattedMessage
          id="findChargingSub"
          defaultMessage="While over 80% of EV charging happens at home, there are a growing number of public charging options for those times you may need to charge on the go. Use the map below to find public charging near you."
          description="Find Charging Sub Header"
        />
      ),
    },
    "tabbed-maps-dealer-catalog-tab": {
      header: (
        <FormattedMessage
          id="findEvDealers"
          defaultMessage="Find EV Dealers"
          description="Find EV Dealers"
        />
      ),
      subHeading: (
        <FormattedMessage
          id="dealersSubHeader"
          defaultMessage="We work closely with several local auto dealerships that are knowledgeable about electric vehicles and can help you make a smooth transition to driving electric and charging at home."
          description="Find EV Dealers Sub Header"
        />
      ),
      button: (
        <a type="button" className="browse-inventory-button" href="/inventory">
          <FormattedMessage
            id="dealersButtonText"
            defaultMessage="Browse Inventory from our Gold Dealerships Near You"
            description="Browse Inventory from our Gold Dealerships Near You"
          />
        </a>
      ),
    },
  };

  return (
    <div className="TabbedMaps">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h2>{MAP_HEADERS[activeTabId].header}</h2>
          <p className="lead">{MAP_HEADERS[activeTabId].subHeading}</p>
        </div>
        {MAP_HEADERS[activeTabId].button && (
          <div className="col-sm-12 text-center mb-5">
            {MAP_HEADERS[activeTabId].button}
          </div>
        )}
      </div>
      <div className="tabbed-maps-panel">
        <TabContent activeTab={activeTabId}>
          {process.env.REACT_APP_PAGES_CHARGING_ENABLED && (
            <TabPane tabId={ALL_STATIONS_TAB_ID}>
              <ChargingMap
                userLocation={userLocation}
                isVisible={activeTabId === ALL_STATIONS_TAB_ID}
                zipcode={zipcode}
              />
            </TabPane>
          )}
          {process.env.REACT_APP_PAGES_MAP_ROUTE_ENABLED && (
            <TabPane tabId={ROUTE_MAP_TAB_ID}>
              <TravelRouteMap isVisible={activeTabId === ROUTE_MAP_TAB_ID} />
            </TabPane>
          )}
          {process.env.REACT_APP_PAGES_DEALERS_ENABLED && (
            <TabPane tabId={DEALER_CATALOG_TAB_ID}>
              <DealerMap
                userLocation={userLocation}
                dealerLocations={dealerLocations}
                isVisible={activeTabId === DEALER_CATALOG_TAB_ID}
                zipcode={zipcode}
                electricVehicles={electricVehicles}
                usedElectricVehicles={usedElectricVehicles}
              />
            </TabPane>
          )}
          {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED && (
            <TabPane tabId={ELECTRICIANS_TAB_ID}>
              <ElectriciansMap
                userLocation={userLocation}
                zipcode={zipcode}
                isVisible={activeTabId === ELECTRICIANS_TAB_ID}
              />
            </TabPane>
          )}
        </TabContent>
      </div>
    </div>
  );
};

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool,
};

export default TabbedMaps;
