import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";

import {
  MN_ID,
  BUNDLED_RATE_TYPE_ID,
  DIY_RATE_TYPE_ID,
} from "../../constants/";

// Components
import InputPanel from "../../components/InputPanel/InputPanel";
import RateComparisonChart from "../../components/RateComparisonChart/RateComparisonChart";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import SlideMilesDrivenAnnually from "../../../components/InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually";
import SlideGasolinePrice from "../../../components/InputComponents/SlideGasolinePrice/SlideGasolinePrice";
import SlideElectricMilesPortionForPhev from "../../../components/InputComponents/SlideElectricMilesPortionForPhev/SlideElectricMilesPortionForPhev";
import RateTotals from "./RateTotals";
import MobileRateTotals from "./MobileRateTotals";
import RateTypeCheckboxes from "../../components/InputComponents/RateTypeCheckboxes/RateTypeCheckboxes";
import EnergyCostTypeCheckboxes from "../../components/InputComponents/EnergyCostTypeCheckboxes/EnergyCostTypeCheckboxes";
import SelectRateComparisonVehicle from "../../components/InputComponents/SelectRateComparisonVehicle/SelectRateComparisonVehicle";
import SelectChargingPattern from "../../components/InputComponents/SelectChargingPattern/SelectChargingPattern";
import ChargerPaymentOptionsRadio from "../../components/InputComponents/ChargerPaymentOptionsRadio/ChargerPaymentOptionsRadio";
import SelectChargingLocation from "../../components/InputComponents/SelectChargingLocation/SelectChargingLocation";
import ElectricPanelLocationRadio from "../../components/InputComponents/ElectricPanelLocationRadio/ElectricPanelLocationRadio";
import ElectricPanelLocationRoomTypeRadio from "../../components/InputComponents/ElectricPanelLocationRoomTypeRadio/ElectricPanelLocationRoomTypeRadio";
import SlideElectricPanelDistance from "../../components/InputComponents/SlideElectricPanelDistance/SlideElectricPanelDistance";
import SelectCurrentRate from "../../components/InputComponents/SelectCurrentRate/SelectCurrentRate";
import SlideCurrentMonthlyBill from "../../components/InputComponents/SlideCurrentMonthlyBill/SlideCurrentMonthlyBill";
import SlidePublicChargingPercentage from "../../components/InputComponents/SlidePublicChargingPercentage/SlidePublicChargingPercentage";
import SlidePublicChargingCost from "../../components/InputComponents/SlidePublicChargingCost/SlidePublicChargingCost";

// Data
import chargingLocations from "../../data/chargingLocations";
import electricPanelLocations from "../../data/electricPanelLocations";
import electricPanelLocationRoomTypes from "../../data/electricPanelLocationRoomTypes";
import states from "../../data/states";

// Calculations & utilities
import isPHEV from "../../../functions/vehicle/isPHEV";
import totalElectricVehicleKwhPerYear from "../../../functions/totalElectricVehicleKwh";
import useSelectedVehicle from "../../../hooks/useSelectedVehicle";
import rateComparisonChartData from "../../../functions/rateComparisonChartData";
import RateCostCalculator from "../../../functions/RateCostCalculator";
import GasolineCostCalculator from "../../../functions/GasolineCostCalculator";
import UpfrontChargerCostCalculator from "../../../functions/UpfrontChargerCostCalculator";
import calcRateTotals from "../../../functions/rateTotals";
import RateComparisonNewOrUsedRadio from "../../components/InputComponents/RateComparisonNewOrUsedRadio/RateComparisonNewOrUsedRadio";
import {
  NEW_CONDITION_ID,
  USED_CONTIDION_ID,
} from "../../constants/vehicleConditions";
import { FormattedMessage, useIntl } from "react-intl";

import "./RateComparison.scss";

const getCurrentState = (region) =>
  states.find((state) => state.id === region) ||
  states.find((state) => state.id === MN_ID);

const RateComparison = ({
  history,
  electricVehicles,
  usedElectricVehicles,
  userLocation,
}) => {
  const intl = useIntl();
  useEffect(() => {
    // Not using a process.env variable since this page is not currently generic
    document.title = "Home Charging Advisor";
  });
  const userPrefs = useContext(UserPrefsContext);

  const region = userLocation ? userLocation.region : null;
  const contextSetter = userPrefs.set;
  useEffect(() => {
    const newState = getCurrentState(region);
    contextSetter({
      currentRateId: newState.rates.find((r) => r.isDefault).id,
      currentChargingPattern: newState.chargingPatterns[0].id,
    });
  }, [region, contextSetter]);

  const selectedElectricVehicle = useSelectedVehicle(
    electricVehicles,
    userPrefs.get("vehicleIdForRateComparison")
  );
  const selectedUsedVehicle = useSelectedVehicle(
    usedElectricVehicles,
    userPrefs.get("usedVehicleIdForRateComparison")
  );
  const isViewingNewVehicles =
    userPrefs.get("rateComparisonVehicleCondition") === NEW_CONDITION_ID;
  const selectedVehicle = isViewingNewVehicles
    ? selectedElectricVehicle
    : selectedUsedVehicle;

  const currentState = getCurrentState(region);
  const currentRate =
    currentState.rates.find(
      (rate) => rate.id === userPrefs.get("currentRateId")
    ) || currentState.rates[0];
  const currentChargingPattern =
    currentState.chargingPatterns.find(
      (p) => p.id === userPrefs.get("chargingPatternId")
    ) || currentState.chargingPatterns[0];
  const currentChargingLocation = chargingLocations.find(
    (l) => l.id === userPrefs.get("chargingLocation")
  );
  const currentElectricPanelLocationRoomType =
    electricPanelLocationRoomTypes.find(
      (roomType) =>
        roomType.id === userPrefs.get("electricPanelLocationRoomType")
    );
  const currentElectricPanelLocation = electricPanelLocations.find(
    (l) => l.id === userPrefs.get("electricPanelLocation")
  );

  const stateHasBundles = currentState.rates.some((r) => r.data.isBundled);
  const displayHomeWiringEstimator = false;

  const stateHasOptimizeChargeProgram = currentState.rates.some(
    (r) => r.data.monthlyDiscountForOptimizingCharge
  );
  const isOptimizingCharge =
    stateHasOptimizeChargeProgram && currentChargingPattern.isOptimizingCharge;

  /* Begin calculating chart data */
  const filteredRates = currentState.rates.filter((rate) => {
    if (!stateHasBundles) return true;

    const {
      data: { isBundled },
    } = rate;
    const selectedRateTypeIds = userPrefs.get("selectedRateTypeIds");

    return (
      (isBundled && selectedRateTypeIds.includes(BUNDLED_RATE_TYPE_ID)) ||
      (!isBundled && selectedRateTypeIds.includes(DIY_RATE_TYPE_ID))
    );
  });

  const rates = [currentRate, ...filteredRates];

  const totalElectricVehicleKwh = totalElectricVehicleKwhPerYear({
    kwhPer100Miles:
      selectedVehicle && selectedVehicle.electric_efficiency
        ? selectedVehicle.electric_efficiency
        : 0,
    milesDrivenAnnually: userPrefs.get("milesDrivenAnnually"),
    percentageDrivenElectric: isPHEV(selectedVehicle)
      ? userPrefs.get("electricMilesPortionForPhev")
      : 100,
  });
  const homeChargingKwh =
    totalElectricVehicleKwh *
    ((100 - userPrefs.get("publicChargingPercentage")) / 100);

  const publicChargingElectricCostPerMonth =
    (totalElectricVehicleKwh *
      (userPrefs.get("publicChargingCostInCents") / 100) *
      (userPrefs.get("publicChargingPercentage") / 100)) /
    12;

  const rateCostCalculator = new RateCostCalculator({
    typicalHomeLoadProfile: currentState.loadProfile,
    currentRate,
    currentMonthlyBill: userPrefs.get("currentMonthlyBill"),
    homeChargingKwh,
    chargingPatternLoadProfile: currentChargingPattern.loadProfile,
  });

  const gasolineCostCalculator = new GasolineCostCalculator({
    selectedVehicle,
    gasolinePriceInCentsPerGal: userPrefs.get("gasolinePriceInCentsPerGal"),
    milesDrivenAnnually: userPrefs.get("milesDrivenAnnually"),
    electricMilesPortionForPhev: userPrefs.get("electricMilesPortionForPhev"),
  });

  const upfrontChargerCostCalculator = new UpfrontChargerCostCalculator({
    chargingLocation: currentChargingLocation,
    electricPanelLocationRoomType: currentElectricPanelLocationRoomType,
    electricPanelLocation: currentElectricPanelLocation,
    panelDistanceToChargerInFeet: userPrefs.get("panelDistanceToChargerInFeet"),
    chargerPaymentType: userPrefs.get("chargerPaymentType"),
  });

  const rateTotals = calcRateTotals({
    rates,
    currentRate,
    rateCostCalculator,
    gasolineCostCalculator,
    upfrontChargerCostCalculator,
    publicChargingElectricCostPerMonth,
    chargerPaymentType: userPrefs.get("chargerPaymentType"),
    selectedEnergyCostIds: userPrefs.get("selectedEnergyCostIds"),
    isOptimizingCharge,
  });

  const chartLabels = {
    electricHome: intl.formatMessage({
      id: "electricityForHome",
      defaultMessage: "Electricity for Home",
    }),
    electricVehicle: intl.formatMessage({
      id: "electricVehicle",
      defaultMessage: "Electric Vehicle",
    }),
    gasoline: intl.formatMessage({
      id: "gasoline",
      defaultMessage: "Gasoline",
    }),
    currentCosts: intl.formatMessage({
      id: "currentCosts",
      defaultMessage: "Current Costs",
    }),
  };

  let rateTitles = rates.map((rate) => {
    const title = rate.data.title;

    if (title === "Regular Residential") {
      rate.data.translated = intl.formatMessage({
        id: "regularResidential",
        defaultMessage: "Regular Residential",
      });

      let i = rate.data.translated.indexOf(" ");
      rate.data.translated = [
        rate.data.translated.slice(0, i),
        rate.data.translated.slice(i + 1),
      ];
    }

    if (title === "EV Accelerate At Home") {
      rate.data.translated = intl.formatMessage({
        id: "evAccelerateAtHomeProgram",
        defaultMessage: "EV Accelerate At Home",
      });

      let i = rate.data.translated.indexOf(" ");
      rate.data.translated = [
        rate.data.translated.slice(0, i),
        rate.data.translated.slice(i + 1),
      ];
    }

    return rate;
  });

  const chartData = rateComparisonChartData({
    rates: rateTitles,
    rateTotals,
    selectedEnergyCostIds: userPrefs.get("selectedEnergyCostIds"),
    chartLabels,
  });
  /* End calculating chart data */

  return (
    <section
      className="container-fluid RateComparison"
      id="home-charging-advisor"
    >
      <div className="container">
        <div className="row mb-5">
          <div className="col-sm-12 text-center">
            <h2>
              <FormattedMessage
                id="homeChargingAdvisor"
                defaultMessage="Home Charging Advisor"
                description="Home Charging Advisor"
              />
            </h2>
            <p className="lead">
              <FormattedMessage
                id="homeChargingAdvisorSubHeader"
                defaultMessage="Explore our home charging options to find the one that best fits your lifestyle."
                description="Home Charging Advisor Sub Header"
              />
            </p>
            <button
              className="btn btn-xcel"
              type="button"
              onClick={() => history.push("/ev-charging-programs")}
            >
              <FormattedMessage
                id="homeChargingAdvisorLearnMore"
                defaultMessage="Learn more about EV Charging Programs"
                description="Home Charging Advisor Learn More"
              />
            </button>
          </div>
        </div>
        {!(electricVehicles && usedElectricVehicles) && (
          <div className="text-center">
            <LoadingSpinner />
          </div>
        )}
        {electricVehicles && usedElectricVehicles && (
          <div className="row">
            <div className="col-md-3">
              <InputPanel
                title={
                  <FormattedMessage
                    id="hcaCustomize"
                    defaultMessage="Customize"
                    description="Customize"
                  />
                }
                className="mb-4"
              >
                {stateHasBundles && <RateTypeCheckboxes className="mt-4" />}

                <div className="mb-4">
                  <EnergyCostTypeCheckboxes />
                </div>

                <RateComparisonNewOrUsedRadio />

                {isViewingNewVehicles && (
                  <SelectRateComparisonVehicle
                    electricVehicles={electricVehicles}
                    selectedVehicle={selectedElectricVehicle}
                    vehicleConditionType={NEW_CONDITION_ID}
                  />
                )}

                {!isViewingNewVehicles && (
                  <SelectRateComparisonVehicle
                    electricVehicles={usedElectricVehicles}
                    selectedVehicle={selectedUsedVehicle}
                    vehicleConditionType={USED_CONTIDION_ID}
                  />
                )}

                <SlideMilesDrivenAnnually />

                <SelectChargingPattern
                  chargingPatterns={currentState.chargingPatterns}
                />

                {stateHasOptimizeChargeProgram && (
                  <p>
                    {isOptimizingCharge && (
                      <span className="text-success">
                        <FormattedMessage
                          id="hcaPatternSuccess"
                          defaultMessage="This pattern qualifies for a discount."
                          description="HCA Pattern Success"
                        />
                      </span>
                    )}
                    {!isOptimizingCharge && (
                      <span className="text-danger">
                        <FormattedMessage
                          id="hcaPatternFail"
                          defaultMessage="This pattern does not qualify for a discount."
                          description="HCA Pattern Fail"
                        />
                      </span>
                    )}
                    <br />
                    <a
                      href="/optimize-your-charge"
                      className="charging-program-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage
                        id="hcaLearnOptimizingCharge"
                        defaultMessage="Learn about Optimizing Your Charge"
                        description="HCA Learn Optimizing Charge"
                      />
                      &nbsp;&gt;
                    </a>
                  </p>
                )}
              </InputPanel>

              {stateHasBundles && (
                <InputPanel
                  title="Home Charger"
                  defaultIsCollapsed={false}
                  className="mb-4"
                >
                  <ChargerPaymentOptionsRadio />
                </InputPanel>
              )}

              {displayHomeWiringEstimator && (
                <>
                  <InputPanel
                    title={
                      <FormattedMessage
                        id="homeWiringEstimator"
                        defaultMessage="Home Wiring Estimator"
                        description="Home Wiring Estimator"
                      />
                    }
                    defaultIsCollapsed={false}
                    className="mb-4"
                  >
                    <SelectChargingLocation />

                    <ElectricPanelLocationRadio />

                    <ElectricPanelLocationRoomTypeRadio />

                    <SlideElectricPanelDistance />
                  </InputPanel>
                </>
              )}

              <InputPanel
                title={
                  <FormattedMessage
                    id="advanced"
                    defaultMessage="Advanced"
                    description="Advanced"
                  />
                }
                defaultIsCollapsed={true}
                className="mb-4"
              >
                <SelectCurrentRate
                  rates={currentState.rates.filter(
                    (rate) => !rate.data.separatelyMetered
                  )}
                />

                <SlideCurrentMonthlyBill />

                <SlideGasolinePrice />

                <SlidePublicChargingPercentage />

                <SlidePublicChargingCost />

                <SlideElectricMilesPortionForPhev />
              </InputPanel>
            </div>
            <div className="col-md-9">
              <RateComparisonChart
                title={
                  <FormattedMessage
                    id="hcaCompareRates"
                    defaultMessage="Compare Rates"
                    description="Compare Rates"
                  />
                }
                chartData={chartData}
                className="d-none d-md-block"
              >
                <div className="mt-2 mb-4 text-center">
                  <button
                    className="btn btn-xcel"
                    type="button"
                    onClick={() => history.push("/ev-charging-programs")}
                  >
                    <FormattedMessage
                      id="homeChargingAdvisorLearnMore"
                      defaultMessage="Learn more about EV Charging Programs"
                      description="Home Charging Advisor Learn More"
                    />
                  </button>
                </div>

                <RateTotals
                  rateTotals={rateTotals}
                  selectedEnergyCostIds={userPrefs.get("selectedEnergyCostIds")}
                />

                <p className="text-center mt-4">
                  <FormattedMessage
                    id="hcaCostsAreEstimatesDisclaimer"
                    defaultMessage="Costs are estimated to help you find the rate that may be best for your lifestyle. Additional panel upgrades may also be necessary."
                    description="Home Charging Advisor Costs are Estimates Disclaimer"
                  />
                  <br />
                  <FormattedMessage
                    id="hcaFinalCostsVary"
                    defaultMessage="Final costs will vary depending on your unique home and options selected."
                    description="Home Charging Advisor Final Costs"
                  />
                  <br/>
                  <FormattedMessage
                    id="hcaWorkingWithElectrician"
                    defaultMessage="Working with an electrician will give you the most accurate estimate of wiring costs."
                    description="Home Charging Advisor Electrican Disclaimer"
                  />
                </p>
              </RateComparisonChart>

              <div className="d-block d-md-none">
                <MobileRateTotals
                  rateTotals={rateTotals}
                  selectedEnergyCostIds={userPrefs.get("selectedEnergyCostIds")}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default RateComparison;

RateComparison.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
