import {
  EMISSIONS_PER_TREE_IN_LBS_OF_CO2,
  ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH,
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL
} from "../../../client_customizations/data/assumptions/ASSUMPTIONS"
import isBEV from "../../../functions/vehicle/isBEV";
import isPHEV from "../../../functions/vehicle/isPHEV";

const calcEmissions = {
  electricEmissionsInLbs: function(kwhPer100Mi, miles) {
    return (
      (ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH.value * kwhPer100Mi * miles) /
      1000 /
      100
    );
  },

  gasolineEmissionsInLbs: function(mpg, miles) {
    return (GASOLINE_EMISSIONS_IN_LBS_PER_GAL.value * miles) / mpg;
  },

  inLbs: function(vehicle, milesDrivenAnnually, electricMilesPortionForPhev) {
    if (!vehicle) return 0;
    let emissions = 0;

    if (!isBEV(vehicle) && !isPHEV(vehicle)) {
      emissions = this.gasolineEmissionsInLbs(
        vehicle.fossil_fuel_efficiency,
        milesDrivenAnnually
      );
    }

    if (isBEV(vehicle)) {
      emissions = this.electricEmissionsInLbs(
        vehicle.electric_efficiency,
        milesDrivenAnnually
      );
    }

    if (isPHEV(vehicle)) {
      emissions =
        this.electricEmissionsInLbs(
          vehicle.electric_efficiency,
          milesDrivenAnnually * (electricMilesPortionForPhev / 100)
        ) +
        this.gasolineEmissionsInLbs(
          vehicle.fossil_fuel_efficiency,
          milesDrivenAnnually * (1 - electricMilesPortionForPhev / 100)
        );
    }
    return emissions;
  },

  reductionInLbs: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    return (
      this.inLbs(
        vehicle.equivalent_gas_vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      ) - this.inLbs(vehicle, milesDrivenAnnually, electricMilesPortionForPhev)
    );
  },
  treesPlanted: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    return (
      this.reductionInLbs(
        vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      ) / EMISSIONS_PER_TREE_IN_LBS_OF_CO2.value
    );
  },
  gallonsUsed: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    if (!vehicle) return 0;

    // All-electric --> no gasoline used
    if (isBEV(vehicle)) {
      return 0;
    }

    // Not electric --> all miles on gasoline
    if (!isBEV(vehicle) && !isPHEV(vehicle)) {
      return milesDrivenAnnually / vehicle.fossil_fuel_efficiency;
    }

    return (
      ((1 - electricMilesPortionForPhev / 100) * milesDrivenAnnually) /
      vehicle.fossil_fuel_efficiency
    );
  },
  gallonsSaved: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    return (
      this.gallonsUsed(
        vehicle.equivalent_gas_vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      ) -
      this.gallonsUsed(
        vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      )
    );
  }
};

export default calcEmissions;
